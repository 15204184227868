// @flow
import config from '@datatheorem/config';

import loadScript from './loadScript';

/**
 * Google Tag Manager
 * We create this global right away so that anyone can use it. If there's no trackingId, then
 * nothing will actually be tracked. But the code wanting to track things shouldn't have to
 * worry about that.
 *
 * NOTE: DO NOT USE THIS GLOBAL. It's presence is useful for other scripts or dependencies that
 * interact well with it. To track something, use the exported function.
 */
global.dataLayer = global.dataLayer || [];

if (
  !config.analytics ||
  typeof config.analytics.id !== 'string' ||
  typeof config.analytics.name !== 'string'
) {
  throw new Error(
    'analytics.id and analytics.name required but not set in config',
  );
}

const trackingId = config.analytics.id;

// ESLint: We need `...args: $ReadOnlyArray<mixed>` for Flow, but we nede to use the raw `arguments` object for Analytics)
// eslint-disable-next-line no-unused-vars
const track = function(...args: $ReadOnlyArray<mixed>) {
  global.dataLayer.push(arguments);
};

track('js', new Date());

if (trackingId) {
  track('config', trackingId, {
    send_page_view: false,
    custom_map: { dimension1: 'role', dimension2: 'customer_name' },
  });
  loadScript(`https://www.googletagmanager.com/gtag/js?id=${trackingId}`);
}

type SimpleMap = mixed;

export default function(data: SimpleMap) {
  if (!data || typeof data !== 'object') {
    throw new Error('analytics expected a non-null object type');
  }
  const { type, ...other } = data;
  track('event', type, other);
}
