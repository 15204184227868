//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import removeFromList from 'rezz/removeFromList';
import { userDeleteSuccess, usersReceived } from '../actions';
import type { User } from '@datatheorem/user-api/users';

export type UserState = $ReadOnlyArray<User>;

export default createReducerCreator<UserState, User, void>({
  initialState: ([]: UserState),
  equalityCheck: (a, b) => a.id === b.id,
})(
  updateInList(usersReceived.toString()),
  removeFromList(userDeleteSuccess.toString()),
);
