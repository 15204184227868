//@flow
const blacklistReason = {
  SPYWARE: 'SPYWARE',
  MALWARE: 'MALWARE',
  HIJACKED_DOMAIN: 'HIJACKED_DOMAIN',
  TOR_NODE: 'TOR_NODE',
  PROXY: 'PROXY',
  VPN: 'VPN',
  SPIDER: 'SPIDER',
  EXPLOIT_BOT: 'EXPLOIT_BOT',
  SPAM_BOT: 'SPAM_BOT',
  BOT: 'BOT',
  PHISHING: 'PHISHING',
  UNKNOWN: 'UNKNOWN',
};

export default blacklistReason;

export type BlacklistReasonEnum = $Keys<typeof blacklistReason>;
