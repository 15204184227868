//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import { palette } from '@datatheorem/theme';
import { fromNowTime } from '@datatheorem/date';
import Avatar from '@material-ui/core/Avatar';

const styles = {
  Comment: {
    marginBottom: 20,
    display: 'flex',
  },
  text: {
    backgroundColor: palette.white,
    borderRadius: 5,
    padding: 10,
    whiteSpace: 'pre-wrap',
    fontSize: 14,
    lineHeight: '1.65',
    color: palette.gray20,
  },
  metaContainer: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    fontSize: 14,
    marginBottom: 10,
  },
  author: {
    color: palette.gray30,
  },
  date: {
    color: palette.gray35,
  },
  avatar: {
    display: 'inline-block',
    marginRight: 10,
  },
};

type Props = {
  author?: ?string,
  date?: ?string,
  children: ?Node,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class Comment extends PureComponent<Props> {
    render() {
      const { classes, author, date, children } = this.props;

      return (
        <div className={classes.Comment}>
          <div className={classes.avatar}>
            <Avatar
              size={30}
              style={{
                backgroundColor:
                  author === 'Data Theorem' ? palette.brand40 : palette.gray30,
                color:
                  author === 'Data Theorem' ? palette.brand50 : palette.gray45,
              }}
            >
              {author ? author.charAt(0).toUpperCase() : '?'}
            </Avatar>
          </div>
          <div style={{ width: '100%' }}>
            <div className={classes.metaContainer}>
              {author && (
                <div className={classes.author}>
                  <b>{author}</b> commented
                </div>
              )}
              <div className={classes.date}>{date && fromNowTime(date)}</div>
            </div>
            <div className={classes.text}>{children}</div>
          </div>
        </div>
      );
    }
  },
);
