//@flow
import React, { PureComponent, type Node } from 'react';
import FlatButton from 'material-ui/FlatButton';

import { fontFamily, fontSizes, palette } from '@datatheorem/theme';

const ellipsis = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const getStyles = () => ({
  container: {
    color: palette.gray20,
    marginTop: 1,
    marginBottom: 3,
  },
  itemWrapper: {
    marginLeft: 19,
  },
  label: {
    marginLeft: 19,
  },
  chevron: {
    color: '#a3a3a3',
    width: 11,
    float: 'left',
    marginLeft: 18,
    fontSize: fontSizes.tiny,
  },
  badge: {
    float: 'right',
    marginRight: 8,
  },
  button: {
    width: '90%',
    fontFamily: fontFamily,
    fontSize: fontSizes.medium,
    fontWeight: 'lighter',
    textAlign: 'left',
    height: 28,
    lineHeight: '28px',
    borderLeft: '4px solid transparent',
  },
  childContainer: {
    marginBottom: ({ noChildMargin }) => (!noChildMargin ? 28 : undefined),
  },
  span: {
    ...ellipsis,
    marginLeft: 10,
    display: 'block',
    color: palette.gray20,
  },
  strong: {
    ...ellipsis,
    fontFamily: fontFamily,
    display: 'block',
    color: palette.gray20,
    letterSpacing: 0.4,
  },
  active: {
    borderLeft: `4px solid ${palette.secondary}`,
    backgroundColor: palette.accent,
  },
});

type Props = {
  children?: Node,
  noChildMargin?: boolean,
  noChevron?: boolean,
  active?: boolean,
  label: Node,
  heading?: boolean,
  badge?: ?string,
  onClick?: () => mixed,
  open?: boolean,
  disabled?: boolean,
};

export default class MenuItem extends PureComponent<Props> {
  render() {
    const { children, active, open, onClick, disabled } = this.props;
    const styles = getStyles();

    return (
      <div style={styles.container}>
        <div>
          <FlatButton
            disabled={disabled}
            onClick={disabled ? null : onClick}
            style={
              active ? { ...styles.button, ...styles.active } : styles.button
            }
          >
            {this.renderChevron()}
            {this.renderBadge()}
            {this.renderLabel()}
          </FlatButton>
        </div>
        {open &&
          children && <div style={styles.childContainer}>{children}</div>}
      </div>
    );
  }

  renderChevron = () => {
    const { children, noChevron, open } = this.props;

    if (!children || noChevron) {
      return null;
    }

    const styles = getStyles();

    return <div style={styles.chevron}>{open ? '▾' : '▸'}</div>;
  };

  renderLabel = () => {
    const { label, heading } = this.props;
    const styles = getStyles();
    return (
      <div style={styles.label}>
        {heading ? (
          <strong style={styles.strong}>{label}</strong>
        ) : (
          <span style={styles.span}>{label}</span>
        )}
      </div>
    );
  };

  renderBadge = () => {
    const { badge } = this.props;
    const styles = getStyles();

    if (!badge) {
      return null;
    }

    return <div style={styles.badge}>{badge}</div>;
  };
}
