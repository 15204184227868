//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

import { type Integrations } from '@datatheorem/user-api/mobile_apps';

import {
  IntegrationStats,
  IntegrationBadgeType,
} from '@datatheorem/components';
import HeaderTemplate from './HeaderTemplate';

const headerStyles = {
  overview: {
    flexGrow: 2,
    flexShrink: 2,
  },

  title: {
    textAlign: 'center',
    marginBottom: 5,
  },
};
type HeaderProps = {
  percentageOfStoreAppsIntegratedWithCiCd: number,
} & InjectedJSS<typeof headerStyles>;

export const Header = jss(headerStyles)(
  class Header extends PureComponent<HeaderProps> {
    render() {
      const { classes, percentageOfStoreAppsIntegratedWithCiCd } = this.props;

      return (
        <HeaderTemplate>
          <div className={classes.overview}>
            <h5 className={classes.title}>Overview</h5>

            <div>
              <IntegrationStats
                badgeTypes={[
                  [
                    IntegrationBadgeType.CI_CD,
                    percentageOfStoreAppsIntegratedWithCiCd,
                  ],
                  [IntegrationBadgeType.TRUSTKIT, false],
                  [IntegrationBadgeType.ISSUE_TRACKER, false],
                ]}
              />
            </div>
          </div>
        </HeaderTemplate>
      );
    }
  },
);

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },

  integrationsContainer: {},

  warning: {
    backgroundColor: '',
  },

  success: {
    backgroundColor: '#16bf13',
  },
};

type Props = {
  integrations: Integrations,
} & InjectedJSS<typeof styles>;

class IntegrationView extends PureComponent<Props> {
  render() {
    const { classes, integrations } = this.props;

    return (
      <div className={classes.container}>
        <IntegrationStats
          badgeTypes={[
            [IntegrationBadgeType.CI_CD, integrations.ci_cd],
            [IntegrationBadgeType.TRUSTKIT, integrations.trust_kit],
            [IntegrationBadgeType.ISSUE_TRACKER, integrations.issue_tracker],
          ]}
        />
      </div>
    );
  }
}

export default jss(styles)(IntegrationView);
