// @flow
import React from 'react';
import TextField from '@material-ui/core/TextField';

import type { MinAge, SetMinAgeFn } from '../../actions/filterActions';

type Props = {
  onChange: SetMinAgeFn,
  value: MinAge,
};

const AgeInputField = (props: Props) => (
  <div
    style={{
      display: 'flex',
    }}
  >
    <TextField
      id="filter-age"
      margin="normal"
      label="Minimum Age (days)"
      value={props.value}
      placeholder="Any Age (default)"
      onChange={e => {
        const value = e.target.value;
        if (!value) props.onChange('');
        else props.onChange(Math.max(0, Number(value)));
      }}
      type="number"
    />
  </div>
);

export default AgeInputField;
