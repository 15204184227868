// @flow
import { createSelector } from 'reselect';
import { type Application } from '@datatheorem/user-api/mobile_apps';
import { GLOBAL_JIRA_CONFIG_ID } from '@datatheorem/user-api/jira_config';
import { type JiraIntegrationConfig } from '@datatheorem/user-api/jira_config';
import type { State } from '../ReduxStateType';
import { appFromParam, type AppIdParam, apps } from './apps';
import type { ConfigurationList } from '../reducers/jiraIntegration';

export const jiraIntegrationConfigsSelector = (
  state: State,
): ConfigurationList => state.jiraIntegration.configurations;

/*
 * Grab the Jira integration config for that is mapped to the given app
 */
export const jiraIntegrationConfigFromApp = createSelector<
  State,
  AppIdParam,
  ?JiraIntegrationConfig,
  _,
  _,
>(
  jiraIntegrationConfigsSelector,
  appFromParam,
  (configs: ConfigurationList, app: ?Application): ?JiraIntegrationConfig => {
    // Return app specific config if there is one
    if (app) {
      const appSpecificConfig = configs.find(
        config => config.fields && config.fields.id.toString() === app.id,
      );

      if (appSpecificConfig) return appSpecificConfig.fields;
    }

    // Fall back to returning global config for everything else
    const globalConfig = configs.find(
      config =>
        config.fields && config.fields.id.toString() === GLOBAL_JIRA_CONFIG_ID,
    );

    if (globalConfig) {
      return globalConfig.fields;
    } else {
      return null;
    }
  },
);

/*
 * Grab the global jira config from state if present.
 * Otherwise return null
 */
export const jiraIntegrationConfigForGlobal = (
  state: State,
): ?JiraIntegrationConfig => {
  const config = state.jiraIntegration.configurations.find(
    config => config.fields.id === GLOBAL_JIRA_CONFIG_ID.toString(),
  );

  if (config) {
    return config.fields;
  } else {
    return config;
  }
};

/*
 * Return an object that has all the jira configs mapped to their mobile app.
 * If an mobile app has no app-specific jira config, then map to the global configuration
 *
 * Eg.
 * {
 *   global: jiraConfigGlobal,
 *   appId: {
 *     '1': appSpecificJiraConfig,
 *     '90': anotherAppSpecificJiraConfig,
 *     '101': jiraConfigGlobal // no app specific config, so fallback to global config
 *   }
 * }
 *
 * // When only Global Config exists
 * {
 *  global: jiraConfigGlobal,
 *  appId: {}
 * }
 */
export const mapJiraConfigsToMobileAppsSelector = createSelector<
  State,
  {},
  MappedJiraConfigsToApp,
  ?JiraIntegrationConfig,
  ConfigurationList,
  $ReadOnlyArray<Application>,
>(
  jiraIntegrationConfigForGlobal,
  jiraIntegrationConfigsSelector,
  apps,

  (
    jiraGlobal: ?JiraIntegrationConfig,
    configs: ConfigurationList,
    apps: $ReadOnlyArray<Application>,
  ) => {
    const appAndJiraPairs: MappedJiraConfigsToApp = {
      global: jiraGlobal,
      appId: {},
    };

    if (!jiraGlobal) {
      return appAndJiraPairs;
    }

    for (const app of apps) {
      const config = configs.find(config => config.fields.id === app.id);

      appAndJiraPairs.appId[app.id] = [
        app,
        config ? config.fields : jiraGlobal,
      ];
    }

    return appAndJiraPairs;
  },
);

export type MappedJiraConfigsToApp = {
  global: ?JiraIntegrationConfig,
  appId: {
    [?string]: [Application, JiraIntegrationConfig],
  },
};
