//@flow
import React, { PureComponent } from 'react';
import type { ActivityLogEvent } from '@datatheorem/user-api/activity';
import { ListItem, ListItemText } from '@material-ui/core';
import { fromNowTime } from '@datatheorem/date';

type Props = {
  activity: ActivityLogEvent,
};

class Activity extends PureComponent<Props> {
  render() {
    const { activity } = this.props;
    const { date_created, description } = activity;

    if (!date_created) {
      return null;
    }

    return (
      <ListItem>
        <ListItemText
          primary={description}
          secondary={fromNowTime(date_created)}
        />
      </ListItem>
    );
  }
}

export default Activity;
