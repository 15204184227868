// @flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

import { type BadgeTypeEnum } from './BadgeTypeEnum';
import { getBadgeImageFromBadgeType } from './util';

const SIZE = 48;

const styles = {
  container: {
    width: '100%',
    height: '100%',
    opacity: ({ enabled }: Props) => (enabled ? 1 : 0.7),
    filter: ({ enabled }: Props) =>
      'drop-shadow( 0px 0px 2px #000 ) ' + (enabled ? '' : 'grayscale(1)'),
  },
};

type Props = {
  type: BadgeTypeEnum,
  enabled?: ?boolean,
} & InjectedJSS<typeof styles>;

/*
 * Badge icon representing difficult to achieve protection metrics.
 */
class ProtectionBadge extends PureComponent<Props> {
  render() {
    const { props } = this;
    const { classes, type, enabled } = props;

    const source = getBadgeImageFromBadgeType(type);

    return (
      <div className={classes.container}>
        <svg viewBox={`0 0 ${SIZE} ${SIZE}`}>
          {/* NOTE: SVG filter color matrix used to keep IE11 happy. */}
          <filter id="grayscale">
            <feColorMatrix
              type="matrix"
              values="0.3333 0.3333 0.3333 0   0
                      0.3333 0.3333 0.3333 0   0
                      0.3333 0.3333 0.3333 0   0
                      0      0      0      0.8   0"
            />
          </filter>
          <image
            width={SIZE}
            height={SIZE}
            xlinkHref={source}
            alt={type}
            style={{ width: '100%', height: '100%' }}
            filter={!enabled ? 'url(#grayscale)' : null}
          />
        </svg>
      </div>
    );
  }
}

export default jss(styles)(ProtectionBadge);
