//@flow
import React, { PureComponent, type Node } from 'react';
import type { InjectedJSS } from 'react-jss';
import jss from 'react-jss';

const styles = {
  SDKSection: {
    marginBottom: 30,
  },
  h2: {
    fontSize: 18,
    color: '#777',
    marginLeft: 83,
  },
};

type Props = {
  title: string,
  children?: Node,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class SDKSection extends PureComponent<Props> {
    render() {
      const { classes, title, children } = this.props;
      return (
        <div className={classes.SDKSection}>
          <h2 className={classes.h2}>{title}</h2>
          {children}
        </div>
      );
    }
  },
);
