//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

const styles = {
  Page: {
    width: 600,
    marginTop: 5,
    marginLeft: 30,
    marginBottom: 20,
  },
};

type OwnProps = {|
  children: Node,
|};

type Props = OwnProps & InjectedJSS<typeof styles>;

export default jss(styles)(
  class Page extends PureComponent<Props> {
    render() {
      const { children, classes } = this.props;
      return <div className={classes.Page}>{children}</div>;
    }
  },
);
