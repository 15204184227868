//@flow
import React, { PureComponent } from 'react';
import { type Application } from '@datatheorem/user-api/mobile_apps';
import AppProtectionDetails from '../protection/AppProtectionDetails';
import { browserHistory } from '../../clients/history';
import { compose } from 'recompose';
import SecurityFindingDialogManager from './SecurityFindingDialogManager';
import { connect } from 'react-redux';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { type State } from '../../ReduxStateType';
import { appFromParam, tasksForAppFromParam } from '../../selectors/apps';
import { loadingTargetStatusIdsForAppFromParam } from '../../selectors/loading';
import replaceInPath from '../util/replaceInPath';
import { type TopApp } from '@datatheorem/user-api/app_protection_top_ten_apps';
import { Paginate } from '@datatheorem/pagination';
import { SecurityFindingEndpoint } from '../../endpoints';
import SecurityFindingsFilterEnum from '@datatheorem/enums/SecurityFindingsFilterEnum';
import { LoadingFiller } from '@datatheorem/components';
import {
  appProtectionMounted,
  appProtectionInquireClicked,
  appProtectionHideTaskClicked,
} from '../../actions';
import type { AppProtectionTask } from '@datatheorem/user-api/mobile_apps';
import { type MobileApplicationChildProps } from '../../types/MobileApplicationChildProps';
import type { AppProtectionTaskCategories } from '../../reducers/appProtectionTaskCategories';
import { type DispatchProps } from 'redux';
import { appProtectionCategoriesFromParam } from '../../selectors/protection';
import type { BadgeTypeEnum } from '@datatheorem/components/src/app-protection-badges';
import { appBadges } from '../../selectors/badges';

function navigateToFindingId(findingId, match) {
  if (!match || !match.path.indexOf(':findingId') === -1) {
    throw new Error('Did not expect to not have a match');
  }

  browserHistory.push(
    replaceInPath(match.path, { findingId: findingId }, match.params),
  );
}

type OwnProps = MobileApplicationChildProps;

type StateProps = {|
  app: ?Application,
  topApps: ?$ReadOnlyArray<TopApp>,
  topAppsByCategory: ?$ReadOnlyArray<TopApp>,
  loadingTargetStatusIds: ?$ReadOnlyArray<string>,
  taskCategories: ?AppProtectionTaskCategories,
  paginationParams: { +[string]: void | string },
  applicationBadges: $ReadOnlyArray<[BadgeTypeEnum, boolean]>,
  tasksForApp: $ReadOnlyArray<AppProtectionTask>,
|};

type Props = {|
  ...OwnProps,
  ...TrackingProps,
  ...StateProps,
  ...DispatchProps<>,
|};

type IntermediateProps = {|
  ...OwnProps,
  ...TrackingProps,
|};

export default compose(
  tracking(dataCreators.component(AnalyticsScreenEnum.MOBILE_APP_PROTECTION)),
  connect<Props, IntermediateProps, _, _, _, _>(
    (state: State, props: IntermediateProps): StateProps => {
      const app = appFromParam(state, props);
      const applicationBadges = appBadges(state, props);

      return {
        app,
        applicationBadges,
        topApps:
          state.topApps && app && app.platform
            ? state.topApps[
                app.platform === 'ANDROID'
                  ? 'android_top_ten_app_protection_apps'
                  : 'ios_top_ten_app_protection_apps'
              ]
            : null,
        topAppsByCategory:
          state.topAppsByCategory && app
            ? state.topAppsByCategory['top_ten_apps_by_category']
            : null,
        loadingTargetStatusIds: loadingTargetStatusIdsForAppFromParam(
          state,
          props,
        ),
        taskCategories: appProtectionCategoriesFromParam(state, props),
        paginationParams: {
          mobile_app_id: props.match.params.appId,
          filter: SecurityFindingsFilterEnum.DEFENSE_IN_DEPTH,
        },
        tasksForApp: tasksForAppFromParam(state, props),
      };
    },
  ),
)(
  class MobileApplicationProtection extends PureComponent<Props> {
    componentDidMount() {
      if (this.props.app) {
        this.props.dispatch(appProtectionMounted(this.props.app));
      } else {
        throw new Error('App not specified for app protection');
      }
    }
    render() {
      const {
        app,
        topApps,
        topAppsByCategory,
        paginationParams,
        loadingTargetStatusIds,
        taskCategories,
        applicationBadges,
        tasksForApp,
        currentUser,
      } = this.props;

      if (!topApps || !app || !taskCategories) {
        return <LoadingFiller />;
      }

      return (
        <SecurityFindingDialogManager
          instructionalMode
          onCloseDialog={this.handleCloseFindingDialog}
          currentUser={currentUser}
        >
          {() => (
            <Paginate type={SecurityFindingEndpoint} params={paginationParams}>
              {({ loadedInitial }) =>
                loadedInitial ? (
                  <AppProtectionDetails
                    app={app}
                    applicationBadges={applicationBadges}
                    taskCategories={taskCategories}
                    topApps={topApps}
                    topAppsByCategory={topAppsByCategory}
                    loadingTargetStatusIds={loadingTargetStatusIds}
                    tasksForApp={tasksForApp}
                    onClickTaskDetails={this.handleClickTaskDetails}
                    onClickInquire={this.handleClickAppProtectionInquire}
                    onClickHideTask={this.handleClickHideTask}
                  />
                ) : (
                  <LoadingFiller />
                )
              }
            </Paginate>
          )}
        </SecurityFindingDialogManager>
      );
    }

    handleClickAppProtectionInquire = (params: { app: Application }) => {
      const { dispatch } = this.props;

      dispatch(appProtectionInquireClicked(params));
    };

    handleClickTaskDetails = (task: AppProtectionTask) => {
      navigateToFindingId(task.security_finding_id, this.props.match);
    };

    handleCloseFindingDialog = () => {
      navigateToFindingId(null, this.props.match);
    };

    handleClickHideTask = (task: AppProtectionTask) => {
      this.props.dispatch(appProtectionHideTaskClicked(task));
    };
  },
);
