//@flow
import { request } from './gapi';
import { result } from './util';

type CreateMobileAppScanResponse = {};

export type MobileAppScanCredentialsParams = {
  mobile_app_id: string,
  comments: string,
  password: string,
  username: string,
};

export async function create(
  params: MobileAppScanCredentialsParams,
): Promise<CreateMobileAppScanResponse> {
  return request({
    path: 'userapi/v2/mobile_app_scan_credentials',
    method: 'POST',
    body: params,
  }).then(result);
}
