// @flow
import React, { type Node } from 'react';

import Tooltip from 'material-ui/internal/Tooltip';

type Props = {
  children: Node,
  label: null | string,
  horizontalPosition?: 'left' | 'right' | 'center',
  verticalPosition?: 'top' | 'bottom',
};

type State = {
  show: boolean,
};

export default class extends React.Component<Props, State> {
  state = {
    show: false,
  };

  onHover = () => {
    this.setState({
      show: true,
    });
  };

  onBlur = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    const { children, ...props } = this.props;

    // if label is null, don't render tooltip
    if (props.label === null) return children;

    return (
      <span
        style={{
          position: 'relative',
          display: 'inline-block',
        }}
      >
        <Tooltip show={this.state.show} {...props} />
        <span
          onMouseEnter={this.onHover}
          onMouseLeave={this.onBlur}
          onFocus={this.onHover}
          onBlur={this.onBlur}
        >
          {children}
        </span>
      </span>
    );
  }
}
