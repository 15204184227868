//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { sdksReceived } from '../actions';
import type { SDK } from '@datatheorem/user-api/sdks';

export type SDKsState = $ReadOnlyArray<SDK>;

// $FlowFixMe FlowUpgrade
export default createReducerCreator({
  initialState: ([]: SDKsState),
  equalityCheck: (a, b) =>
    `${a.title}${a.description}` === `${b.title}${b.description}`,
})(updateInList(sdksReceived.toString()));
