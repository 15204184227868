//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import DropDownIcon from 'material-ui/svg-icons/navigation/arrow-drop-down';

import { palette } from '@datatheorem/theme';

const styles = {
  container: {
    cursor: ({ onClick }: Props) => (onClick ? 'pointer' : null),
    width: ({ width }: Props) => width || 'auto',
    justifyContent: 'space-between',
  },

  icon: {
    position: 'relative',
    marginRight: -8,
  },

  dropdownContainer: {
    height: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    padding: '10px 15px',
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: 'bold',
    width: 'auto',

    '& > *': {
      'flex-shrink': 0,
      'flex-grow': 0,
    },
  },
};

type Props = {
  label: Node,
  onClick?: ?(e: SyntheticEvent<HTMLElement>) => void,
  radius?: number,
  excludeLeftRadius?: boolean,
  excludeRightRadius?: boolean,
  backgroundColor?: string,
  color?: string,
  width?: number,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class Indicator extends PureComponent<Props> {
    static defaultProps = {
      radius: 2,
    };

    render() {
      const {
        classes,
        label,
        onClick,
        backgroundColor,
        color,
        radius,
      } = this.props;

      return (
        <div className={classes.container} onClick={onClick}>
          <div
            className={classes.dropdownContainer}
            style={{
              backgroundColor: backgroundColor || palette.navColor,
              color: color || palette.white,
              borderRadius: radius,
            }}
          >
            <span>{label}</span>
            {onClick && (
              <DropDownIcon
                color={color || palette.white}
                className={classes.icon}
                style={{
                  height: 20,
                  width: 18,
                }}
              />
            )}
          </div>
        </div>
      );
    }
  },
);
