//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

import MaterialBadge from 'material-ui/Badge';

import { palette } from '@datatheorem/theme';

const styles = {
  container: {
    // region fill
    width: ({ fill }: Props) => (fill ? '100%' : ''),
    height: ({ fill }: Props) => (fill ? '100%' : ''),
    // endregion fill

    display: 'flex',
  },

  badge: {
    // region fill
    width: ({ fill }: Props) => (fill ? '100%' : ''),
    height: ({ fill }: Props) => (fill ? '100%' : ''),
    // endregion fill

    backgroundColor: ({ backgroundColor }: Props) =>
      backgroundColor || palette.brand,

    // region borderRadius
    borderTopLeftRadius: ({ excludeLeftRadius, radius }: Props) =>
      excludeLeftRadius ? 0 : radius,
    borderBottomLeftRadius: ({ excludeLeftRadius, radius }: Props) =>
      excludeLeftRadius ? 0 : radius,
    borderTopRightRadius: ({ excludeRightRadius, radius }: Props) =>
      excludeRightRadius ? 0 : radius,
    borderBottomRightRadius: ({ excludeRightRadius, radius }: Props) =>
      excludeRightRadius ? 0 : radius,
    // endregion borderRadius

    color: ({ color }: Props) => color || '#fff',
    // region size
    fontSize: ({ size }: Props) => (size === 'large' ? '11px' : '9px'),
    lineHeight: ({ size }: Props) => (size === 'large' ? '11px' : '9px'),
    padding: ({ size }: Props) =>
      size === 'large' ? '3px 6px 3px 6px' : '2px 4px 2px 4px',
    // endregion size

    // region uppercase
    textTransform: ({ uppercase }: Props) => (uppercase ? 'uppercase' : 'none'),
    // endregion uppercase

    whiteSpace: 'nowrap',
  },
};

type Props = {
  backgroundColor?: string,
  color?: string,
  radius?: number,
  uppercase?: boolean,
  excludeLeftRadius?: boolean,
  excludeRightRadius?: boolean,

  inline?: boolean,
  fill?: boolean,
  size?: 'large' | 'small',
  label: Node,
  children?: Node,
} & InjectedJSS<typeof styles>;

class Badge extends PureComponent<Props> {
  static defaultProps = {
    inline: false,
    fill: false,
    size: 'large',
    radius: 3,
    uppercase: true,
  };

  render() {
    const { children, label, inline, classes } = this.props;

    return (
      <div className={classes.container}>
        <MaterialBadge
          style={
            inline
              ? {
                  position: 'relative',
                  padding: 0,
                  width: '100%',
                  height: '100%',
                }
              : {}
          }
          badgeStyle={
            inline
              ? {
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                }
              : {}
          }
          badgeContent={<div className={classes.badge}>{label}</div>}
        >
          {children}
        </MaterialBadge>
      </div>
    );
  }
}

export default jss(styles)(Badge);
