// @flow
import { call, put, spawn, take, takeEvery, all } from 'redux-saga/effects';
import { Raven } from '@datatheorem/global';
import { Actions as SessionActions, callPromise } from '@datatheorem/session';

import { updateApp } from '../reducers/apps';
import { list, type Applications } from '@datatheorem/user-api/mobile_apps';
import { withProgressIndicator } from '@datatheorem/progress-indicator';
import { callSaga } from '@datatheorem/redux-saga-wrapped-effects';
import paginate, { paginateToEnd } from './util/paginate';
import {
  appFileSubmitted,
  uploadButtonClicked,
  appFileRejected,
  appFileAccepted,
  appXCUITestFileAccepted,
  appXCUITestFileRejected,
  startedLoadingApps,
  finishedLoadingApps,
  receivedAppsError,
  appXCUITestFileSubmit,
} from '../actions';
import { browserHistory } from '../clients/history';
import * as uploadAppService from '@datatheorem/user-api/app_uploads';
import * as uploadXCUITestService from '@datatheorem/user-api/xcuitest_uploads';
import type { ListParams } from '@datatheorem/user-api/mobile_apps';
import { select } from '@datatheorem/redux-saga-wrapped-effects';
import { type Saga } from 'redux-saga';
import { Selectors as SessionSelectors } from '@datatheorem/session';
import { type ReleaseType } from '@datatheorem/enums/MobileAppReleaseTypeEnum';
import type { State } from '../ReduxStateType';

const TYPE = 'apps';

export function* appsFlow(): Saga<void> {
  yield spawn(loadAppsAtLogin);
  yield spawn(watchForUploadButtonClicked);
  yield spawn(watchForFileSubmitted);
  yield spawn(watchForXCUITestFileSubmitted);
}

export function* loadApps(params: ListParams): Saga<void | Applications> {
  const apps = yield* callSaga(paginate, TYPE, params, function*(
    params: ?ListParams,
  ) {
    return yield* callPromise(list, params);
  });

  if (apps && apps.mobile_apps && apps.mobile_apps.length) {
    yield put(updateApp(apps.mobile_apps));
    return apps.mobile_apps;
  }
}

export function* loadAllApps(
  params: ListParams = {},
): Saga<void | Applications> {
  return yield* withProgressIndicator(function*() {
    yield put(startedLoadingApps());
    try {
      return yield* callSaga(paginateToEnd, loadApps, TYPE, params, params);
    } catch (err) {
      yield put(receivedAppsError());
      throw err;
    } finally {
      yield put(finishedLoadingApps());
    }
  }, TYPE);
}

export function* loadAppsAtLogin(): Saga<void> {
  yield all([
    take(SessionActions.accountInfoReceived.toString()),
    take(SessionActions.currentUserReceived.toString()),
  ]);

  const { apps } = yield* select((state: State) => state);

  if (!(yield* select(SessionSelectors.hasScanAndSecure, {}))) {
    return;
  }

  if (!apps || apps.length < 1) {
    yield call(loadAllApps, { filter: 'SCAN_AND_SECURE_APPS' });
  }
}

export function* watchForUploadButtonClicked(): Saga<void> {
  yield takeEvery(uploadButtonClicked.toString(), function*(): Saga<void> {
    yield call(browserHistory.push, '/upload');
  });
}

export function* watchForFileSubmitted(): Saga<void> {
  yield takeEvery(appFileSubmitted.toString(), function*(action: {
    payload: {
      file: File,
      username: string,
      password: string,
      comments: string,
    },
  }): Saga<void> {
    const { file, username, password, comments } = action.payload;
    yield* uploadFile(file, username, password, comments);
  });
}

export type UploadAppResponse = {
  bundle_id: string, //"net.tolberts.android.roboninja.android"
  is_app_new: boolean,
  name: string, //"Robo-Ninja"
  platform: string,
  session_id: string, //?
  status: string, //"ok"
};

export function* uploadFile(
  file: File,
  username: ?string,
  password: ?string,
  comments: ?string,
): Saga<void> {
  try {
    const url = yield* callPromise(uploadAppService.create, {
      username,
      password,
      comments,
    });

    if (typeof url !== 'string') {
      throw new Error('POST file upload didnt return a response');
    }

    const form = new FormData();
    form.append('file', file);
    form.append('source', 'DT_UPLOAD_FORM');

    if (username) {
      form.append('username', username);
    }
    if (password) {
      form.append('password', password);
    }
    if (comments) {
      form.append('comments', comments);
    }

    const response = yield* callPromise(fetch, url, {
      method: 'POST',
      body: form,
    });

    const body = yield* callPromise(async () => response.json());

    if (body.error) {
      yield put(appFileRejected(body.error));
      return;
    }

    yield put(appFileAccepted((body: UploadAppResponse)));
  } catch (err) {
    if (err instanceof Error) {
      yield put(appFileRejected(err.message));
      Raven.captureException(err);
      return;
    }
    throw err;
  }
}

export function* watchForXCUITestFileSubmitted(): Saga<void> {
  yield takeEvery(appXCUITestFileSubmit.toString(), function*(action: {
    payload: {
      file: File,
      bundle_id: string,
      release_type: ReleaseType,
      version: string,
    },
  }): Saga<void> {
    const { file, bundle_id, release_type, version } = action.payload;
    yield* uploadXCUITestFile(file, bundle_id, release_type, version);
  });
}

export function* uploadXCUITestFile(
  file: File,
  bundle_id: string,
  release_type: ReleaseType,
  version: string,
): Saga<void> {
  try {
    const url = yield* callPromise(uploadXCUITestService.create, {});

    if (typeof url !== 'string') {
      throw new Error("POST file upload didn't return a response");
    }

    const form = new FormData();
    form.append('file', file);
    form.append('source', 'DT_APP_XCUITEST_FORM');
    form.append('bundle_id', bundle_id);
    form.append('release_type', release_type);
    form.append('version', version);

    const response: Response = yield call(fetch, url, {
      method: 'POST',
      body: form,
    });

    // fetch don't throw unless is network error
    if (!response.ok) {
      // Better response? AppUploader actually returns a nice explaination why it failed
      throw new Error('Upload failed.');
    }

    yield put(appXCUITestFileAccepted(response));
  } catch (err) {
    // $FlowFixMe Flow85
    yield put(appXCUITestFileRejected(err));
  }
}
