//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import LinkIcon from 'material-ui/svg-icons/action/launch';
import Paper from 'material-ui/Paper';
import groupBy from 'lodash/groupBy';
import { compose } from 'recompose';
import tracking, { type TrackingProps } from 'react-tracking';

import { palette } from '@datatheorem/theme';
import { type CompliancePolicyReference } from '@datatheorem/findings/types';
import {
  CompliancePolicyLogo,
  CompliancePolicyDetails,
  Markdown,
  ViewMoreButton,
} from '@datatheorem/components';

const NUMBER_OF_VISIBLE_COMPLIANCE_POLICIES_WHILE_COLLAPSED = 3;

const styles = {
  paper: {
    marginTop: '10px !important',
    marginBottom: '10px !important',
    position: 'relative !important',
    minHeight: '82px !important',
  },

  title: {
    marginBottom: 0,
    marginTop: 0,
  },

  link: {
    textDecoration: 'none',
    lineHeight: '16px',
  },

  metaContainer: {},
  innerContainer: {
    marginLeft: 90,
    paddingTop: 5,
  },

  date: {
    color: '#777',
    fontSize: 12,
    position: 'absolute',
    right: 5,
    bottom: 5,
    textTransform: 'uppercase',
  },

  source: {
    fontSize: 18,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 0,
    width: 82,
    height: 82,
  },

  text: {
    color: '#777',
    '& p': {
      margin: 0,
      marginTop: 5,
    },
    '& .markdown': {
      display: 'inline-block',
    },
  },

  linkIcon: {
    width: '1em',
    height: '1em',
  },

  attribution: {
    fontSize: 10,
    color: '#4099FF',
    textDecoration: 'none',
  },
};

type Props = {
  references?: $ReadOnlyArray<CompliancePolicyReference>,
} & TrackingProps &
  InjectedJSS<typeof styles>;

type State = {
  expanded: boolean,
};

export default compose(
  jss(styles),
  tracking({}),
)(
  class CompliancePolicyReferencesCardList extends PureComponent<Props, State> {
    state = {
      expanded: false,
    };

    render() {
      const { references, classes } = this.props;
      const { expanded } = this.state;

      if (!references || !references.length) {
        return null;
      }
      const groupedReferences = groupBy(references, 'compliance_policy');
      const compliancePolicies = Object.keys(groupedReferences);

      return (
        <div>
          {compliancePolicies
            .slice(
              0,
              expanded
                ? compliancePolicies.length
                : NUMBER_OF_VISIBLE_COMPLIANCE_POLICIES_WHILE_COLLAPSED,
            )
            .map((compliancePolicy: string, i) => {
              const referencesForPolicy = groupedReferences[compliancePolicy];
              if (!Array.isArray(referencesForPolicy)) {
                throw new Error(
                  `expected referencesForPolicy to contain key '${compliancePolicy}'`,
                );
              }

              return (
                <Paper
                  id={`compliance-card-${i}`}
                  key={compliancePolicy}
                  className={classes.paper}
                >
                  <div className={classes.metaContainer}>
                    <div className={classes.innerContainer}>
                      <h4 className={classes.title}>
                        {CompliancePolicyDetails[compliancePolicy] &&
                          CompliancePolicyDetails[compliancePolicy].title}
                      </h4>
                      <div>
                        {referencesForPolicy.map(({ link, markdown }, i) => (
                          <div key={i} className={classes.text}>
                            <Markdown text={markdown} />
                            {link && (
                              <a
                                href={link}
                                target="_blank"
                                style={styles.link}
                                rel="noopener noreferrer"
                              >
                                <LinkIcon
                                  style={styles.linkIcon}
                                  color={palette.navColor}
                                />
                              </a>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className={classes.source}>
                      <CompliancePolicyLogo
                        policy={compliancePolicy}
                        size="large"
                      />
                    </div>
                  </div>
                </Paper>
              );
            })}
          {!expanded &&
            compliancePolicies.length >
              NUMBER_OF_VISIBLE_COMPLIANCE_POLICIES_WHILE_COLLAPSED && (
              <Paper id="compliance-view-more-card">
                <ViewMoreButton
                  label="compliance concerns"
                  count={
                    compliancePolicies.length -
                    NUMBER_OF_VISIBLE_COMPLIANCE_POLICIES_WHILE_COLLAPSED
                  }
                  onRequestViewMore={this.handleRequestViewMore}
                />
              </Paper>
            )}
          <a
            className={classes.attribution}
            href="http://clearbit.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Logos provided by Clearbit
          </a>
        </div>
      );
    }

    handleRequestViewMore = () => {
      this.setState({ expanded: true });
    };
  },
);
