//@flow
import { call, put, spawn, takeEvery } from 'redux-saga/effects';
import {
  mobileAppTrustKit,
  trustkitAnalysisReceived,
  trustKitCardClicked,
  trustKitDialogCloseRequest,
  trustkitReportsReceived,
} from '../actions';
import { tryAndParseErrorMessage } from '@datatheorem/user-api/util';
import { trustkit } from '@datatheorem/user-api/mobile_apps';
import { callPromise } from '@datatheorem/session';
import { browserHistory } from '../clients/history';
import type {
  Application,
  TrustKitReport,
} from '@datatheorem/user-api/mobile_apps';
import { type Saga } from 'redux-saga';

export function* trustkitFlow(): Saga<void> {
  yield spawn(watchForMobileAppTrustKitTabLoaded);
  yield spawn(watchForTrustKitNavigtions);
}

export function* watchForMobileAppTrustKitTabLoaded(): Saga<void> {
  yield takeEvery(mobileAppTrustKit.TRIGGER, function*(action: {
    type: string,
    payload?: { mobile_app_id?: string },
  }): Saga<void> {
    if (!action || !action.payload || !action.payload.mobile_app_id) {
      throw new Error('Invalid mobile app id');
    }

    const { mobile_app_id } = action.payload;

    try {
      yield put(mobileAppTrustKit.request(mobile_app_id));
      yield call(loadTrustKitReportsForApp, mobile_app_id);
      yield call(loadTrustKitAnalysisForApp, mobile_app_id);
      yield put(mobileAppTrustKit.success(mobile_app_id));
    } catch (error) {
      yield put(mobileAppTrustKit.failure(tryAndParseErrorMessage(error)));
    } finally {
      yield put(mobileAppTrustKit.fulfill(mobile_app_id));
    }
  });
}

export function* loadTrustKitReportsForApp(mobile_app_id: string): Saga<void> {
  const reports = yield* callPromise(trustkit.reports.list, mobile_app_id);
  if (reports && reports.length) {
    yield put(trustkitReportsReceived(reports));
  }
}

export function* loadTrustKitAnalysisForApp(mobile_app_id: string): Saga<void> {
  const analysis = yield* callPromise(trustkit.analysis.get, mobile_app_id);
  if (analysis && analysis.mobile_app_id) {
    yield put(trustkitAnalysisReceived(analysis));
  }
}

export function* watchForTrustKitNavigtions(): Saga<void> {
  yield takeEvery(
    trustKitCardClicked.toString(),
    navigateToTrustKitReportDetails,
  );
  yield takeEvery(
    trustKitDialogCloseRequest.toString(),
    navigateToTrustKitAppTabForCurrentApp,
  );
}

export function* navigateToTrustKitReportDetails({
  payload,
}: {
  type: string,
  payload: { report: TrustKitReport, app: Application },
}): Saga<void> {
  const { report, app } = payload;
  yield call(navigate, app.id, report.id);
}

export function* navigateToTrustKitAppTabForCurrentApp({
  payload,
}: {
  type: string,
  payload: { appId: string },
}): Saga<void> {
  yield call(navigate, payload.appId);
}

export function* navigate(
  appId: string = '',
  reportId: string = '',
): Saga<void> {
  yield call(browserHistory.push, `/app/${appId}/trustkit/report/${reportId}`);
}
