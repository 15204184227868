//@flow
import React, {
  PureComponent,
  type Node,
  type ElementRef,
  createRef,
} from 'react';
import numeral from 'numeral';

import BigList from '../theme/BigList';
import type { rowRenderProps } from '../theme/BigList';
import ListCardSpacer from '../theme/ListCardSpacer';
import {
  subscriptionToFriendlyString,
  getHumanReadableScanStatusFromScan,
} from './../../util/appUtil';
import { browserHistory } from './../../clients/history';

import { type InquireShadowScanFindingsActionParams } from './../../actions/shadowScanFindings';
import {
  type CategoryMetadataCount,
  type ApplicationWithMetadataCount,
  type Integrations,
} from '@datatheorem/user-api/mobile_apps';

import {
  AppSummaryCard,
  AppSummaryCardClass,
  ShadowScanSummaryCard,
  ShadowScanSummaryCardClass,
  MeasureableHeightDiv,
} from '@datatheorem/components';

type Props = {
  storeApps: $ReadOnlyArray<ApplicationWithMetadataCount>,
  totalMetadataCount: CategoryMetadataCount,
  detailsRenderer: (props: {
    id: string,
    integrations?: Integrations,
    metadataCount: CategoryMetadataCount,
  }) => Node,
  onShadowScanInquireClick: InquireShadowScanFindingsActionParams => mixed,
  onShadowScanPreviewClick: (id: string) => void,
};

class AppList extends PureComponent<Props> {
  _shadowCard: {
    current: null | ElementRef<typeof MeasureableHeightDiv>,
  } = createRef();

  loadMoreRows = () => {};

  getRowHeight = ({ index }: { index: number }) => {
    const { storeApps } = this.props;

    if (index >= storeApps.length) {
      const shadowCard = this._shadowCard.current;
      if (shadowCard && typeof shadowCard.measure === 'function') {
        return shadowCard.measure().height;
      }

      return ShadowScanSummaryCardClass.getHeight();
    }

    const { metadataCount } = storeApps[index];
    return (
      AppSummaryCardClass.getHeight(metadataCount.SHADOW) +
      15 /* ListCardSpacer */
    );
  };

  isRowLoaded = (codaCount: number, { index }: { index: number }) => {
    const { storeApps } = this.props;

    return index < storeApps.length + codaCount;
  };

  render() {
    const {
      storeApps,
      detailsRenderer,
      totalMetadataCount,
      onShadowScanInquireClick,
      onShadowScanPreviewClick,
    } = this.props;

    const codaCount = ShadowScanSummaryCardClass.shouldShowFor(
      'all',
      totalMetadataCount.SHADOW,
    )
      ? 1
      : 0;

    if (!storeApps || storeApps.length + codaCount === 0) {
      return <p style={{ textAlign: 'center' }}>No applications found.</p>;
    }

    return (
      <BigList
        loadMoreRows={this.loadMoreRows}
        isRowLoaded={rowData => this.isRowLoaded(codaCount, rowData)}
        totalCount={storeApps.length + codaCount + 1}
        currentCount={storeApps.length + codaCount}
        rowHeight={this.getRowHeight}
      >
        {({ index, style, key }: rowRenderProps) => {
          const {
            id,
            app_protection_score_ratio,
            blocked_scan_reason,
            icon_url,
            integrations,
            most_recent_scan,
            name,
            platform,
            release_type,
            subscription,
            metadataCount,
          } =
            storeApps[index] || {};

          return (
            <div key={key} style={style}>
              {index >= storeApps.length ? (
                <MeasureableHeightDiv ref={this._shadowCard}>
                  <ListCardSpacer>
                    <ShadowScanSummaryCard
                      type={'all'}
                      shadowMetadataCount={totalMetadataCount.SHADOW}
                      onClickInquire={onShadowScanInquireClick}
                    />
                  </ListCardSpacer>
                </MeasureableHeightDiv>
              ) : (
                <ListCardSpacer>
                  <AppSummaryCard
                    id={id}
                    app_protection_percent={
                      app_protection_score_ratio
                        ? numeral(app_protection_score_ratio).format('0%')
                        : null
                    }
                    blocked_scan_reason={blocked_scan_reason}
                    icon_url={icon_url}
                    integrations={integrations}
                    most_recent_scan={most_recent_scan}
                    name={name}
                    platform={platform}
                    release_type={release_type}
                    subscription={subscription}
                    metadataCount={metadataCount}
                    subscriptionFormat={subscriptionToFriendlyString}
                    scanBlockedReasonFormat={getHumanReadableScanStatusFromScan}
                    detailsRenderer={detailsRenderer}
                    onIconClick={id => browserHistory.push(`/app/${id}`)}
                    onScoreClick={id =>
                      browserHistory.push(`/app/${id}/protection`)
                    }
                    onShadowScanPreviewClick={onShadowScanPreviewClick}
                  />
                </ListCardSpacer>
              )}
            </div>
          );
        }}
      </BigList>
    );
  }
}

export default AppList;
