//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { activitiesReceived } from '../actions';
import type { ActivityLogEvent } from '@datatheorem/user-api/activity';

export type ActivitiesState = $ReadOnlyArray<ActivityLogEvent>;

export default createReducerCreator<ActivitiesState, ActivityLogEvent, void>({
  initialState: [],
})(updateInList(activitiesReceived.toString()));
