//@flow
import React, { PureComponent } from 'react';
//$FlowFixMe Flow Upgrade
import { Field, Formik, FieldArray } from 'formik';

import { commonProps, JiraStaticArrayFieldSchema } from './JiraSchemas';
import { Button } from '@datatheorem/components';
import Page from '../../theme/Page';
import PanelSubmitField from '../PanelSubmitField';

import { type JiraIntegrationConfig } from '@datatheorem/user-api/jira_config';

type Props = {
  submitLabel: string,
  onSubmit: () => void,
  handleBackSubmit: () => void,
  initialValues: ?JiraIntegrationConfig,
};

export default class JiraStaticFieldForm extends PureComponent<Props> {
  render() {
    const {
      submitLabel,
      onSubmit,
      handleBackSubmit,
      initialValues,
    } = this.props;

    const formValues = JiraStaticArrayFieldSchema.isValidSync(initialValues)
      ? JiraStaticArrayFieldSchema.cast(initialValues, { stripUnknown: true })
      : JiraStaticArrayFieldSchema.default();

    return (
      <Formik
        initialValues={formValues}
        onSubmit={onSubmit}
        validationSchema={JiraStaticArrayFieldSchema}
        render={({ values, handleSubmit, setFieldValue }) => (
          <Page>
            <FieldArray
              name="static_fields"
              render={({ form, remove, push }) => {
                return (
                  <div>
                    <h2>Optional</h2>
                    <p>
                      Static fields are a set of <b>optional</b> Jira attributes
                      that will always be set when ticket creation occurs.
                      Provide the field ID and the associated value that should
                      be used in order for this to happen.
                    </p>
                    <p>
                      For example, if you would like to always have a label of
                      value `Data Theorem` when creating tickets. You would
                      provide the field ID of that label, along with it&#39;s
                      value which in this case is `Data Theorem`
                    </p>
                    {values &&
                      values.static_fields &&
                      values.static_fields.map((field, index) => (
                        <div key={index}>
                          <Field
                            label={`Static Field ID #${index + 1}`}
                            {...commonProps(
                              `static_fields[${index}].field_id`,
                              setFieldValue,
                            )}
                            errorText={
                              form.touched.static_fields &&
                              form.touched.static_fields[index] &&
                              form.errors.static_fields &&
                              typeof form.errors.static_fields !== 'string' &&
                              form.errors.static_fields[index] &&
                              typeof form.errors.static_fields[index] !==
                                'string' &&
                              form.errors.static_fields[index].field_id
                            }
                          />
                          <Field
                            label={`Static Field Value #${index + 1}`}
                            {...commonProps(
                              `static_fields[${index}].field_value`,
                              setFieldValue,
                            )}
                            errorText={
                              form.touched.static_fields &&
                              form.touched.static_fields[index] &&
                              form.errors.static_fields &&
                              typeof form.errors.static_fields !== 'string' &&
                              form.errors.static_fields[index] &&
                              typeof form.errors.static_fields[index] !==
                                'string' &&
                              form.errors.static_fields[index].field_value
                            }
                          />
                          <PanelSubmitField
                            submitLabel={'Remove'}
                            onSubmit={() => {
                              remove(index);
                            }}
                            loading={false}
                          />
                        </div>
                      ))}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingBottom: 10,
                        paddingTop: 10,
                        paddingRight: 10,
                        paddingLeft: 10,
                      }}
                    >
                      <Button
                        variant="text"
                        hoverColor={'none'}
                        rippleColor={'none'}
                        onClick={() => push({ field_id: '', field_value: '' })}
                        ariaLabel="Add Static Field"
                      >
                        Add a Static Field
                      </Button>
                      <Button
                        variant="text"
                        hoverColor={'none'}
                        rippleColor={'none'}
                        onClick={handleBackSubmit}
                        ariaLabel="Back"
                      >
                        Back
                      </Button>
                      <span style={{ width: 10 }} />
                      <Button onClick={handleSubmit} ariaLabel={submitLabel}>
                        {submitLabel}
                      </Button>
                    </div>
                  </div>
                );
              }}
            />
          </Page>
        )}
      />
    );
  }
}
