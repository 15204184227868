//@flow
import React, { type ComponentType } from 'react';
import {
  Redirect,
  Route,
  Switch,
  type ContextRouter,
  withRouter,
} from 'react-router-dom';
import { palette, fontSizes } from '@datatheorem/theme';
import { PageHeading, Tab, IconLabel } from '@datatheorem/components';
import MobileApplicationIssues from '../main/MobileApplicationIssues';
import MobileApplicationInfo from '../main/MobileApplicationInfo';
import MobileApplicationSDKRisks from '../main/MobileApplicationSDKRisks';
import MobileApplicationTrustKitContainer from '../main/MobileApplicationTrustKitContainer';
import MobileApplicationProtection from '../main/MobileApplicationProtection';

import {
  getHumanReadableScanStatus,
  subscriptionToFriendlyString,
  isScanBlocked,
} from '../../util/appUtil';
import type { Application } from '@datatheorem/user-api/mobile_apps';
import { type MobileApplicationChildProps } from '../../types/MobileApplicationChildProps';
import { type User } from '@datatheorem/user-api/users';
import AppScanStatus from '../theme/AppScanStatus';
import { Badge } from '@datatheorem/components';

type OwnProps = {|
  app: Application,
  appProtectionPercent: number | null,
  currentUser: User,
|};

type Props = {| ...OwnProps, ...ContextRouter |};

const buildPath = (appId: string, to: string) => {
  return `/app/${appId}/${to}`;
};

export default withRouter(function MobileAppDetails({
  app,
  match,
  location,
  history,
  appProtectionPercent,
  currentUser,
}: Props) {
  const { id } = app;

  const { pathname } = location;

  const tabs = [
    Tab({
      key: 'issues',
      label: 'App Issues',
      locationPathname: pathname,
      path: buildPath(id, 'issues'),
      onClick: () => history.push(buildPath(id, 'issues')),
    }),
    Tab({
      key: 'protection',
      label:
        appProtectionPercent === null ? (
          'App Protection'
        ) : (
          <Badge label={`${appProtectionPercent}% Protected`}>
            App Protection
          </Badge>
        ),
      locationPathname: pathname,
      path: buildPath(id, 'protection'),
      onClick: () => history.push(buildPath(id, 'protection')),
    }),
    Tab({
      key: 'sdks',
      label: 'OSS/SDKs',
      locationPathname: pathname,
      path: buildPath(id, 'sdks'),
      onClick: () => history.push(buildPath(id, 'sdks')),
    }),
    Tab({
      key: 'trustkit',
      label: 'TrustKit',
      locationPathname: pathname,
      path: buildPath(id, 'trustkit'),
      onClick: () => history.push(buildPath(id, 'trustkit')),
    }),
    Tab({
      key: 'insights',
      label: 'Insights',
      locationPathname: pathname,
      path: buildPath(id, 'info'),
      onClick: () => history.push(buildPath(id, 'info')),
    }),
  ];

  const withExtraProps = (
    Component: ComponentType<MobileApplicationChildProps>,
  ) => (props: ContextRouter) => (
    <Component {...props} app={app} currentUser={currentUser} />
  );

  return (
    <div>
      <PageHeading
        title={<IconLabel platform={app.platform}>{app.name}</IconLabel>}
        tabs={tabs}
        forMenu
        linkTo={app.store_url}
        iconUrl={app.icon_url}
        locationPathnameForTabs={location.pathname}
        afterTitle={
          <div>
            {app.most_recent_scan &&
              app.most_recent_scan.app_version && (
                <span style={{ color: palette.navColor, marginLeft: 5 }}>
                  v{app.most_recent_scan.app_version}
                </span>
              )}
            {app.bundle_id && (
              <div
                style={{
                  fontSize: fontSizes.small,
                  color: palette.navColor,
                }}
              >
                {app.bundle_id}
              </div>
            )}
          </div>
        }
        rightSide={
          <div>
            {app.subscription && (
              <div>{subscriptionToFriendlyString(app.subscription)}</div>
            )}
            <AppScanStatus
              scanStatus={getHumanReadableScanStatus(app)}
              mobile_app_id={app.id}
              isAppScanBlocked={isScanBlocked(app.blocked_scan_reason)}
              blockedScanReason={app.blocked_scan_reason}
            />
          </div>
        }
      >
        <Switch>
          <Route
            path={`${match.path}/issues/:findingId?`}
            render={withExtraProps(MobileApplicationIssues)}
          />
          <Route
            path={`${match.path}/protection/:findingId?`}
            render={withExtraProps(MobileApplicationProtection)}
          />
          <Route
            path={`${match.path}/sdks`}
            render={withExtraProps(MobileApplicationSDKRisks)}
          />
          <Route
            path={`${match.path}/trustkit`}
            render={withExtraProps(MobileApplicationTrustKitContainer)}
          />
          <Route
            path={`${match.path}/info`}
            render={withExtraProps(MobileApplicationInfo)}
          />
          <Route render={() => <Redirect to={`${match.url}/issues`} />} />
        </Switch>
      </PageHeading>
    </div>
  );
});
