//@flow
import { type Saga } from 'redux-saga';
import { call } from 'redux-saga/effects';

import handleAuthError from './handleAuthError';
//import { type Saga, type CallEffect } from 'redux-saga';

/**
 * It may look as though this is unnecessary. Why not simply directly yield `call`? Well, that works, but due to the
 * nature of generators, and Flow, it makes them difficult to type. Though a saga typically only yields `Effects`, the
 * 'next' values provided back to the saga can be anything, making typing a saga a complex proposition.
 *
 * Since it's a common pattern to yield a call effect to a Promise (to perform a REST request), this generator simply
 * typifies that relationship in Flow -- that the type T in Promise<T> will be the type of the "next" value given to the
 * saga when the Promise resolves.
 * @param fn
 * @param args
 * @return {*}
 */
export default function* callPromise<A, Args: $ReadOnlyArray<A>, T>(
  fn: (...args: Args) => Promise<T>,
  ...args: Args
): Saga<T> {
  try {
    return yield call(Array.isArray(fn) ? [fn[0], fn[1]] : fn, ...args);
  } catch (e) {
    yield call(handleAuthError, e); // eslint-disable-line redux-saga/no-unhandled-errors
    throw e;
  }
}
