//@flow
import { put, spawn, takeEvery, call } from 'redux-saga/effects';
import { callPromise } from '@datatheorem/session';
import { notes } from '@datatheorem/user-api/security_findings';
import type { SecurityFinding } from '@datatheorem/findings/types';
import { withProgressIndicator } from '@datatheorem/progress-indicator';
import { type Saga } from 'redux-saga';
import {
  addCommentClicked,
  addCommentRequestFailed,
  addCommentRequestSucceeded,
} from '../actions';
import tracking, { dataCreators } from '@datatheorem/analytics';

export function* commentWatchers(): Saga<void> {
  yield spawn(watchForAddComment);
}

export function* watchForAddComment(): Saga<void> {
  yield takeEvery(addCommentClicked.toString(), function*(action: {
    payload: { comment: string, finding: SecurityFinding },
  }): Saga<void> {
    yield* withProgressIndicator(function*() {
      try {
        const { comment, finding } = action.payload;
        const newComment = yield* callPromise(notes.create, finding.id, {
          text: comment,
        });
        if (newComment) {
          yield put(addCommentRequestSucceeded(newComment, finding));
          yield call(tracking, dataCreators.findingsCommented());
        } else {
          yield put(
            // $FlowFixMe Flow85
            addCommentRequestFailed(new Error('Could not save comment')),
          );
        }
      } catch (err) {
        // $FlowFixMe Flow85
        yield put(addCommentRequestFailed(err));
      }
    });
  });
}
