//@flow
import React, { PureComponent } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { type FieldProps } from 'formik';

type Props = {
  label: string,
  items: $ReadOnlyArray<{ value: string, label: string }>,
  setFieldValue: (value: mixed) => mixed,
} & FieldProps;

export default class PanelItemMobileApps extends PureComponent<Props> {
  input: ?HTMLElement;

  render() {
    const { items, field } = this.props;
    const value = field.value || [];

    return (
      <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
        {items.map(item => (
          <FormControlLabel
            key={item.value}
            control={
              <Checkbox
                checked={value.includes(item.value)}
                color="primary"
                onChange={e =>
                  this.handleChangeApp(item.value, !e.target.checked)
                }
              />
            }
            label={item.label}
          />
        ))}
      </div>
    );
  }

  handleChangeApp = (appId: string, isRemoving: boolean) => {
    const { field, setFieldValue } = this.props;
    const value = field.value || [];

    if (isRemoving) {
      setFieldValue(value.filter(item => item !== appId));
    } else {
      setFieldValue(value.concat(appId));
    }
  };
}
