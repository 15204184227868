//@flow
import React, { PureComponent } from 'react';

import type { ShadowscanSecurityFinding } from '@datatheorem/user-api/shadow_security_findings';

import ShadowScanPreviewFindingCard from './Component';

export default class ShadowScanPreviewFindings extends PureComponent<{
  findings: $ReadOnlyArray<ShadowscanSecurityFinding>,
}> {
  render() {
    const { findings } = this.props;

    return (
      <div>
        {findings &&
          findings.map(finding => (
            <div key={finding.id} style={{ marginTop: 10, marginBottom: 10 }}>
              <ShadowScanPreviewFindingCard finding={finding} />
            </div>
          ))}

        {findings.length === 0 && <p>No Preview Available</p>}
      </div>
    );
  }
}
