//@flow

export function enumToString(
  value: string,
  map: { [key: string]: string },
): string {
  if (Object.prototype.hasOwnProperty.call(map, value)) {
    return map[value];
  } else if (map.default) {
    return map.default;
  } else {
    return '???';
  }
}
