//@flow
import React, { PureComponent, type Node } from 'react';

type Props = {
  style?: Object,
  children: Node,
  innerProps?: Object,
};

export default class CenteredContainer extends PureComponent<Props> {
  render() {
    const { style, children, innerProps, ...props } = this.props;

    return (
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          textAlign: 'center',
          ...style,
        }}
        {...props}
      >
        <div {...innerProps}>{children}</div>
      </div>
    );
  }
}
