//@flow
import React, { PureComponent } from 'react';
import LinearProgress from 'material-ui/LinearProgress';

type Props = {
  inProgress?: number,
};

export default class ProgressIndicator extends PureComponent<Props> {
  render() {
    const inProgress = this.props.inProgress || 0;

    return inProgress > 0 ? <LinearProgress mode="indeterminate" /> : null;
  }
}
