//@flow
import React, { PureComponent, type Node } from 'react';
import { fontFamily, fontSizes, palette } from '@datatheorem/theme';
import { Tabs as MUITabs } from 'material-ui/Tabs';

const styles = {
  tabsWrapper: {
    marginLeft: 115,
  },

  tabItemContainer: {
    backgroundColor: 'transparent',
  },

  inkBar: {
    backgroundColor: palette.brand,
  },
};

export const tabStyle = {
  color: palette.navColor,
  fontFamily: fontFamily,
  fontSize: fontSizes.medium,
  textTransform: 'none',
};

export const activeTabStyle = {
  ...tabStyle,
  color: palette.gray20,
};

type Props = {
  children: Node,
  value: mixed,
  marginLeft?: number,
  marginRight?: number,
};

export default class Tabs extends PureComponent<Props> {
  render() {
    const { children, marginLeft, marginRight, value, ...props } = this.props;

    return (
      <MUITabs
        style={{ marginLeft: marginLeft || 0, marginRight: marginRight || 0 }}
        tabItemContainerStyle={styles.tabItemContainer}
        inkBarStyle={styles.inkBar}
        value={value}
        {...props}
      >
        {children}
      </MUITabs>
    );
  }
}
