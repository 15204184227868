//@flow
import { createSelector } from 'reselect';
import type { Insights } from '@datatheorem/user-api/insights';
import type { State } from '../ReduxStateType';
import type { AppIdParam } from './apps';

export const insights = (state: State) => state.insights;

export const insightsByAppIdParam = createSelector<
  State,
  AppIdParam,
  void | Insights,
  _,
  _,
>(
  insights,
  (state, props) => props.match.params.appId,
  (insights, appId) =>
    insights && insights.mobile_app_id === appId ? insights : undefined,
);
