// @flow
import React from 'react';
import TextField from '@material-ui/core/TextField';
import format from 'date-fns/format';

type Props = {|
  value: void | Date,
  onChange: (value: Date) => mixed,
  label: string,
  max?: Date,
  min?: Date,
|};

const FilterDatePicker = ({ value, onChange, label, max, min }: Props) => (
  <TextField
    style={{ marginRight: '10px' }}
    id={label}
    label={label}
    type="date"
    value={format(value || new Date(), 'YYYY-MM-DD')}
    inputProps={{
      max: max ? format(max, 'YYYY-MM-DD') : null,
      min: min ? format(min, 'YYYY-MM-DD') : null,
    }}
    onChange={(e: SyntheticEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      if (!value) return;

      onChange(new Date(value));
    }}
    InputLabelProps={{
      shrink: true,
    }}
  />
);

export default FilterDatePicker;
