//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

const styles = {
  Screenshot: {},
  image: {
    maxWidth: '100%',
  },
};

type Props = {
  src: ?string,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class Screenshot extends PureComponent<Props> {
    render() {
      const { classes, src } = this.props;

      if (!src) {
        return null;
      }

      return (
        <div className={classes.Screenshot}>
          {src && <img src={src} className={classes.image} />}
        </div>
      );
    }
  },
);
