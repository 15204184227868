//@flow
import React, { PureComponent, type Node } from 'react';
import ExpansionPanelItem from '../theme/ExpansionPanelItem';
import Select from '@material-ui/core/Select';
import { type FieldProps } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

type Props = {|
  label: string,
  items: Node,
  setFieldValue: (value: mixed) => mixed,
  ...FieldProps,
|};

export default class PanelItemSelect extends PureComponent<Props> {
  input: ?HTMLElement;

  render() {
    const { label } = this.props;
    return (
      <ExpansionPanelItem
        disabled
        primaryText={label}
        value={this.renderValue(this.props)}
      />
    );
  }

  renderValue = ({ field, form, items, setFieldValue }: Props) => (
    <FormControl
      fullWidth
      error={Boolean(form.errors && form.errors[field.name])}
    >
      <FormHelperText>
        {field.name &&
          form.touched[field.name] &&
          form.errors &&
          form.errors[field.name]}
      </FormHelperText>
      <Select
        {...field}
        value={field.value ? field.value : ''}
        fullWidth
        onChange={event => {
          const value = event.target.value;
          setFieldValue(value);
        }}
      >
        {items}
      </Select>
    </FormControl>
  );
}
