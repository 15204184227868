//@flow
import React, { PureComponent } from 'react';
import ListItem from 'material-ui/List/ListItem';
import Divider from 'material-ui/Divider';
import ActionInfo from 'material-ui/svg-icons/action/info';
import startCase from 'lodash/fp/startCase';

import { palette } from '@datatheorem/theme';

import { type BlacklistedCommunicationType } from '@datatheorem/user-api/insights';

import InfoCard from './../InfoCard';
import ViewMoreButton from './../ViewMoreButton';
import InsightsCardWrapper from './InsightsCardWrapper';

type Props = {|
  communicates_with_blacklisted_ip_addresses: BlacklistedCommunicationType,
  expanded: boolean,
  maxVisibleCount: number,
  onClickExpand: () => mixed,
|};

const COMMUNICATES_WITH_BLACKLISTED_IP_ADDRESSES =
  'Malware/Malicious Connections';

export default class BlackListedIPAddressesCard extends PureComponent<Props> {
  render() {
    const { props } = this;
    const {
      communicates_with_blacklisted_ip_addresses,
      expanded,
      maxVisibleCount,
      onClickExpand,
    } = props;

    // Indicate to the user that there are no blacklisted ip addresses.
    if (
      !communicates_with_blacklisted_ip_addresses ||
      communicates_with_blacklisted_ip_addresses.length <= 0
    ) {
      return (
        <InfoCard
          key="communicates_with_zero_blacklisted_ip_addresses"
          heading={COMMUNICATES_WITH_BLACKLISTED_IP_ADDRESSES}
          primaryText="None"
          tooltip="This app does not communicate with any currently tracked malicious IP addresses."
        />
      );
    }

    // Indicate to the user that there are blacklisted ip addresses.
    return (
      <InsightsCardWrapper header={COMMUNICATES_WITH_BLACKLISTED_IP_ADDRESSES}>
        {communicates_with_blacklisted_ip_addresses.map(
          (blacklisted_ip_address, i) => {
            if (!expanded && i >= maxVisibleCount) {
              return null;
            }

            return (
              <div key={blacklisted_ip_address.ip_address}>
                <Divider />
                <ListItem
                  rightIcon={<ActionInfo color={palette.yellow30} />}
                  primaryText={blacklisted_ip_address.ip_address}
                  secondaryText={startCase(blacklisted_ip_address.reason)}
                />
              </div>
            );
          },
        )}
        {!expanded &&
        communicates_with_blacklisted_ip_addresses.length > maxVisibleCount ? (
          <>
            <Divider />
            <ViewMoreButton
              label="ip addresses"
              count={
                communicates_with_blacklisted_ip_addresses.length -
                maxVisibleCount
              }
              onRequestViewMore={onClickExpand}
            />
          </>
        ) : null}
      </InsightsCardWrapper>
    );
  }
}
