//@flow
import { put, spawn, all, takeEvery, select, call } from 'redux-saga/effects';
import {
  notification_preferences,
  type NotificationPreferencesPatch,
} from '@datatheorem/user-api/current_user';
import { callPromise } from '@datatheorem/session';
import { createAction, type ActionType } from 'redux-actions';
import { withProgressIndicator } from '@datatheorem/progress-indicator';
import { anErrorOccurred } from '../actions';
import { type Saga } from 'redux-saga';
import { setNotificationPreferences } from '../reducers/preferences';
import { Raven } from '@datatheorem/global';

import tracking, { dataCreators } from '@datatheorem/analytics';

import merge from 'lodash/fp/merge';

export function* getNotificationPreferencesSaga(): Saga<void> {
  yield* withProgressIndicator(function*() {
    try {
      const notificationpreferences = yield* callPromise(
        notification_preferences.get,
      );

      // @remove: once https://datatheorem.atlassian.net/browse/SASB-783 is completed, we don't need to support old schema anymore
      if (notificationpreferences) {
        yield put(setNotificationPreferences(notificationpreferences));
      }
    } catch (e) {
      // @sourcetheorem.com emails will return 404... we should keep the train moving regardless
    }
  });
}

export function* patchNotificationPreferencesSaga(
  action: ActionType<typeof patchNotificationPreferences>,
): Saga<void> {
  yield* withProgressIndicator(function*() {
    const currentValue = yield select(state => state.preferences.notification);

    try {
      yield put(
        setNotificationPreferences(merge(currentValue, action.payload)),
      );
      yield* callPromise(notification_preferences.patch, action.payload);

      yield call(tracking, dataCreators.preferencesNotificationEdited());
    } catch (e) {
      // Raven sees it all
      Raven.captureException(e);
      // Revert back optimistic ui because it failed
      yield put(setNotificationPreferences(currentValue));

      yield put(
        anErrorOccurred(
          `An error occurred while trying to update your notifications: ${
            e.message
          }`,
        ),
      );
    }
  });
}

export function* watchForNotificationPreferences(): Saga<void> {
  yield all([
    takeEvery(
      getNotificationPreferences.toString(),
      getNotificationPreferencesSaga,
    ),
    takeEvery(
      patchNotificationPreferences.toString(),
      patchNotificationPreferencesSaga,
    ),
  ]);
}

export function* preferencesFlow(): Saga<void> {
  yield spawn(watchForNotificationPreferences);
}

export const getNotificationPreferences = createAction<
  'GET_CURRENT_NOTIFICATION_PREFERENCES',
>('GET_CURRENT_NOTIFICATION_PREFERENCES');

export const patchNotificationPreferences = createAction<
  'PATCH_CURRENT_NOTIFICATION_PREFERENCES',
  [NotificationPreferencesPatch],
  NotificationPreferencesPatch,
>(
  'PATCH_CURRENT_NOTIFICATION_PREFERENCES',
  notification_preferences => notification_preferences,
);
