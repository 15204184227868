//@flow
import React, { PureComponent } from 'react';
import BrandedProductIcon from './BrandedProductIcon';
import BrandProtectInactiveIcon from './icon-brand-protect-inactive.png';
import BrandProtectActiveIcon from './icon-brand-protect-active.png';

type Props = {
  active: boolean,
};

export default class BrandProtectIcon extends PureComponent<Props> {
  render() {
    return (
      <BrandedProductIcon
        src={
          this.props.active ? BrandProtectActiveIcon : BrandProtectInactiveIcon
        }
      />
    );
  }
}
