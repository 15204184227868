//@flow
import React, { PureComponent, type Node } from 'react';
import Drawer from 'material-ui/Drawer';
import { palette, spacing } from '@datatheorem/theme';

const getStyles = () => ({
  drawer: {
    marginTop: spacing.headerHeight,
    paddingTop: 12,
    paddingBottom: 65,
    borderRight: `1px solid ${palette.accent}`,
    boxShadow: 'none',
    top: 0,
    bottom: 0,
    height: 'unset',
    // Turning off this CSS property is necessary for position=fixed elements to work properly inside the Drawer. If
    // we ever need to actually hide the Drawer, this will prevent the animation, making `open=false` pointless. We
    // might want to look into only enabling this CSS prop "on demand" when the drawer is being closed, being
    // opened, or is completely closed, leaving it disabled for when the drawer is open. Or maybe MUI will have
    // fixed this issue by the time we need to do that.
    transform: 'none',
  },
});

type Props = {
  menu: Node,
  children: Node,
};

export default class LHSMenu extends PureComponent<Props> {
  render() {
    const { menu, children } = this.props;
    const styles = getStyles();
    return (
      <div>
        <Drawer open width={spacing.menuWidth} containerStyle={styles.drawer}>
          {menu}
        </Drawer>
        <nav style={{ marginLeft: 230 }}>{children}</nav>
      </div>
    );
  }
}
