//@flow
import { object, string, array, mixed } from 'yup';

import MobileAppPlatform, {
  type MobileAppPlatformEnum,
} from '@datatheorem/enums/MobileAppPlatformEnum';
import MobileAppCategory, {
  type MobileAppCategoryEnum,
} from '@datatheorem/enums/MobileAppCategoryEnum';

import { request } from './gapi';
import { result } from './util';

export type ListAppProtectionCategoryParams = {
  mobile_app_id: string,
};

export type TopApp = {
  id: string, // number,
  app_protection_score: string, // number
  app_protection_score_ratio: number,
  name: string,
  platform: MobileAppPlatformEnum,
  publisher_name?: string,
  icon_url?: string,
  category?: MobileAppCategoryEnum,
};

export type TopTenResponse = {
  android_top_ten_app_protection_apps?: $ReadOnlyArray<TopApp>,
  ios_top_ten_app_protection_apps?: $ReadOnlyArray<TopApp>,
};

export type TopTenByCategoryResponse = {
  top_ten_apps_by_category?: $ReadOnlyArray<TopApp>,
};

const TopAppSchema = object().shape({
  id: string().required(),
  app_protection_score: string().required(),
  name: string().required(),
  publisher_name: string(),
  icon_url: string(),
  platform: mixed()
    .label('Platform')
    .oneOf(Object.values(MobileAppPlatform))
    .required(),
});

const TopAppByCategorySchema = object().shape({
  id: string().required(),
  app_protection_score: string().required(),
  name: string().required(),
  publisher_name: string(),
  icon_url: string(),
  platform: mixed()
    .label('Platform')
    .oneOf(Object.values(MobileAppPlatform))
    .required(),
  category: string()
    .required()
    .oneOf(Object.keys(MobileAppCategory)),
});

async function validateResponse(
  response: TopTenResponse,
): Promise<TopTenResponse> {
  return object()
    .shape({
      android_top_ten_app_protection_apps: array().of(TopAppSchema),
      ios_top_ten_app_protection_apps: array().of(TopAppSchema),
    })
    .validate(response);
}

async function validateCategoryResponse(
  response: TopTenByCategoryResponse,
): Promise<TopTenByCategoryResponse> {
  return object()
    .shape({
      top_ten_apps_by_category: array().of(TopAppByCategorySchema),
    })
    .validate(response);
}

export async function list(): Promise<TopTenResponse> {
  return request({ path: 'userapi/v2/app_protection_top_ten_apps' })
    .then(result)
    .then(validateResponse);
}

export async function categoryList(
  params: ListAppProtectionCategoryParams,
): Promise<TopTenByCategoryResponse> {
  return request({
    path: 'userapi/v2/app_protection_top_ten_apps/category',
    params,
    method: 'GET',
  })
    .then(result)
    .then(validateCategoryResponse);
}
