// @flow
import React, { PureComponent } from 'react';
import Page from '../../theme/Page';
import APIKeysPanels from '../../users/APIKeysPanels';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { ErrorMessageBlock } from '@datatheorem/components';
import type { State as ReduxState } from '../../../ReduxStateType';
import UserRole, { type UserRoleEnum } from '@datatheorem/enums/UserRoleEnum';
import type { APIKey } from '@datatheorem/user-api/api_keys';
import { type DispatchProps } from 'redux';
import { apiKeyClicked, createAPIKeyButtonClicked } from '../../../actions';
import { PageHeading } from '@datatheorem/components';
import { browserHistory } from '../../../clients/history';
import { apiKeyPageMounted } from '../../../actions';
import tracking, { type TrackingProps } from 'react-tracking';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { dataCreators } from '@datatheorem/analytics';

import {
  API_KEYS_TYPE,
  API_KEYS_ERROR_STRING_KEY,
  UPLOAD_API_STRING_KEY,
} from '../../../strings';

type Props = {
  ...StateProps,
  ...DispatchProps<>,
  ...TrackingProps,
};

type IntermidiateProps = {
  ...DispatchProps<>,
  ...TrackingProps,
};

export class APIAccess extends PureComponent<Props> {
  componentDidMount() {
    this.props.dispatch(apiKeyPageMounted());
  }

  render() {
    const {
      apiError,
      apiKeys,
      userRole,
      upload_api_key,
      apiKeysInitialLoading,
    } = this.props;

    return (
      <PageHeading
        backButton
        onClickBack={browserHistory.goBack}
        title="API Access"
      >
        <div
          style={{
            paddingTop: 10,
            paddingBottom: 50,
          }}
        >
          <Page>
            {apiError && <ErrorMessageBlock>{apiError}</ErrorMessageBlock>}
            {apiKeysInitialLoading || !upload_api_key ? (
              <p>loading...</p>
            ) : (
              <APIKeysPanels
                userHasManagerRole={userRole === UserRole.MANAGER}
                upload_api_key={upload_api_key}
                apiKeys={apiKeys}
                onClickAPIKey={this.handleAPIKeyClicked}
                onClickCreateAPIKeyButton={this.handleClickCreateAPIKeyButton}
              />
            )}
          </Page>
        </div>
      </PageHeading>
    );
  }

  handleAPIKeyClicked = (apiKey: APIKey) => {
    this.props.dispatch(apiKeyClicked(apiKey));
  };

  handleClickCreateAPIKeyButton = () => {
    this.props.dispatch(createAPIKeyButtonClicked());
  };
}

type StateProps = {|
  upload_api_key: ?string,
  apiKeys: $ReadOnlyArray<APIKey>,
  apiError: ?string,
  userRole: ?UserRoleEnum,
  apiKeysInitialLoading: boolean,
|};

const mapStateToProps = (state: ReduxState): StateProps => ({
  userRole: state.currentUser && state.currentUser.role,
  upload_api_key: state.strings[UPLOAD_API_STRING_KEY],
  apiKeys: state.apiKeys,
  apiError: state.strings[API_KEYS_ERROR_STRING_KEY],
  apiKeysInitialLoading: !state.firstRequest[API_KEYS_TYPE],
});

export default compose(
  tracking(dataCreators.component(AnalyticsScreenEnum.SDLC_API_KEYS)),
  connect<Props, IntermidiateProps, _, _, _>(mapStateToProps),
)(APIAccess);
