//@flow
import React, { PureComponent } from 'react';
import { PageHeading } from '@datatheorem/components';
import { connect } from 'react-redux';
import ActivityList from '../misc/ActivityList';
import type { ActivityLogEvent } from '@datatheorem/user-api/activity';
import { activitiesByDate } from '../../selectors/activities';
import { LoadingFiller } from '@datatheorem/components';
import { compose } from 'recompose';
import { Paginate } from '@datatheorem/pagination';
import { ActivityEndpoint } from '../../endpoints';
import { stringFromParametricRequest } from '@datatheorem/string';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { type DispatchProps } from 'redux';
import { browserHistory } from './../../clients/history';

type StateProps = {|
  activities: $ReadOnlyArray<ActivityLogEvent>,
  totalCount: number,
|};

type Props = {|
  ...StateProps,
  ...DispatchProps<>,
  ...TrackingProps,
|};

export default compose(
  tracking(dataCreators.component(AnalyticsScreenEnum.ACTIVITY)),
  connect<Props, TrackingProps, _, _, _, _>((state, props): StateProps => ({
    activities: activitiesByDate(state, props),
    totalCount: state.rowCount[stringFromParametricRequest(ActivityEndpoint)],
  })),
)(
  class Activity extends PureComponent<Props> {
    render() {
      const { activities, totalCount } = this.props;

      return (
        <PageHeading
          backButton
          onClickBack={browserHistory.goBack}
          title="Activity"
        >
          <Paginate type={ActivityEndpoint}>
            {({ loadedInitial, loading, loadMore }) =>
              loadedInitial ? (
                <ActivityList
                  activities={activities}
                  loadMoreRows={loadMore}
                  loading={loading}
                  totalCount={totalCount}
                />
              ) : (
                <LoadingFiller />
              )
            }
          </Paginate>
        </PageHeading>
      );
    }
  },
);
