//@flow
import React, { PureComponent } from 'react';
import BrandedProductIcon from './BrandedProductIcon';
import AppSearchInactiveIcon from './icon-app-search-inactive.png';
import AppSearchActiveIcon from './icon-app-search-active.png';

type Props = {
  active: boolean,
};

export default class AppSearchIcon extends PureComponent<Props> {
  render() {
    return (
      <BrandedProductIcon
        src={this.props.active ? AppSearchActiveIcon : AppSearchInactiveIcon}
      />
    );
  }
}
