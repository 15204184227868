//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

import { palette } from '@datatheorem/theme';
import { type ShadowMetadataCountType } from '@datatheorem/user-api/mobile_apps';

import PriorityBadge from './../PriorityBadge';
import Card from './../Card';

import { getShadowScanTitle, getMetadataOr } from './Categories';
import InquireButton from './InquireButton';
import PreviewButton from './PreviewButton';
import PreviewDialog from './PreviewDialog';

const styles = {
  container: {
    paddingTop: 10,
    paddingBottom: 5,
    paddingLeft: 55,
    paddingRight: 10,
  },

  header: {
    color: palette.faded,
    display: 'flex',
    justifyContent: 'space-between',
  },

  content: {
    paddingTop: 10,
  },
};

type Props = {
  type: string, // TODO: 'enum'.
  // TODO: should not be optional - make optional when overrideCount is removed.
  shadowMetadataCount?: ?ShadowMetadataCountType,
  // TODO: REMOVE overrideCount - shadow scan cases should be handled here.
  //       See MobileApplicationIssues for the use case that requires this atm.
  overrideCount: ?number,
  onClickInquire: (params: {|
    title?: ?string,
    count?: ?number,
    breakdown?: ?ShadowMetadataCountType,
  |}) => mixed,
  children?: ?Node,
} & InjectedJSS<typeof styles>;

type State = {
  open: boolean,
};

export class ShadowScanSummaryCard extends PureComponent<Props, State> {
  static defaultProps = {
    type: 'all',
    overrideCount: null,
  };

  state = {
    open: false,
  };

  render() {
    const {
      children,
      classes,

      type,
      shadowMetadataCount,
      overrideCount,
      onClickInquire,
    } = this.props;

    const title = getShadowScanTitle(type);
    const description = getMetadataOr(type, 'description');
    const categoryType = getMetadataOr(type, 'categoryType', 'ALL');
    const breakdownType = getMetadataOr(type, 'breakdownType');
    const count =
      overrideCount !== null || !shadowMetadataCount
        ? overrideCount
        : shadowMetadataCount[categoryType || 'ALL'];

    return (
      <Card status={`5px solid ${palette.brand}`}>
        <div className={classes.container}>
          {/* Header */}
          <div className={classes.header}>
            {/* Left */}
            <div>
              <strong>
                <PriorityBadge type={count} />
                <span style={{ marginLeft: 10 }}>{title}</span>
              </strong>
            </div>

            {/* Right */}
            <div>
              {children ? (
                <PreviewButton
                  onPreviewClick={() => this.setState({ open: true })}
                />
              ) : (
                <InquireButton
                  onClickInquire={() => {
                    return onClickInquire({
                      title: title,
                      count: count,
                      breakdown: shadowMetadataCount,
                    });
                  }}
                />
              )}
            </div>
          </div>

          {/* Content */}
          <div className={classes.content}>
            {description}
            {(breakdownType === 'FULL' || breakdownType === 'PARTIAL') &&
              shadowMetadataCount && (
                <ul>
                  <li>{shadowMetadataCount.P1} Security P1 Issues</li>
                  <li>
                    {shadowMetadataCount.APPLE_BLOCKER} App Store Blockers
                  </li>
                  <li>
                    {shadowMetadataCount.GOOGLE_BLOCKER} Google Play Blockers
                  </li>
                  <li>
                    {shadowMetadataCount.COMPLIANCE} Regulatory Compliance
                  </li>
                  {breakdownType === 'FULL' && (
                    <li>{shadowMetadataCount.HIGH} High Severity</li>
                  )}
                  {breakdownType === 'FULL' && (
                    <li>{shadowMetadataCount.MEDIUM} Medium Severity</li>
                  )}
                  {breakdownType === 'FULL' && (
                    <li>{shadowMetadataCount.LOW} Low Severity</li>
                  )}
                </ul>
              )}

            <PreviewDialog
              open={this.state.open}
              onClickInquire={() => {
                return onClickInquire({
                  title: title,
                  count: count,
                  breakdown: shadowMetadataCount,
                });
              }}
              onRequestClose={() => this.setState({ open: false })}
            >
              {children}
            </PreviewDialog>
          </div>
        </div>
      </Card>
    );
  }

  static getHeight = () => {
    return 600;
  };

  static shouldShowFor = (
    type: string, // TODO: enum...?
    shadowMetadataCount: ?ShadowMetadataCountType,
  ): boolean => {
    // If we weren't provided any shadow scan metadata.
    if (!shadowMetadataCount) return false;

    const categoryType = getMetadataOr(type, 'categoryType', 'ALL');
    const count = shadowMetadataCount[categoryType || 'ALL'];

    // If we don't have any shadow scan findings for this category.
    if (!count) return false;

    return true;
  };
}

export default jss(styles)(ShadowScanSummaryCard);
