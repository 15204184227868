//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { appConfigurationFindingsByAppIdParam } from '../../selectors/insightFindings';
import { insightsByAppIdParam } from '../../selectors/insights';
import { getMetadataCount } from '@datatheorem/user-api/util/metadataCount';
import { getAppInsights } from '../../actions';
import type { InsightFinding, Insights } from '@datatheorem/user-api/insights';
import { compose } from 'recompose';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { type MobileApplicationChildProps } from '../../types/MobileApplicationChildProps';
import AppInsights from '../apps/AppInsights';
import { type DispatchProps } from 'redux';

type OwnProps = {|
  ...MobileApplicationChildProps,
|};

type StateProps = {|
  insights: void | Insights,
  findings: $ReadOnlyArray<InsightFinding>,
|};

type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps<>,
  ...TrackingProps,
|};

type IntermediateProps = {| ...OwnProps, ...TrackingProps |};

export class MobileApplicationInfo extends PureComponent<Props> {
  render() {
    const { findings, app, insights } = this.props;

    return (
      <AppInsights
        insights={insights}
        findings={findings}
        metadataCount={app && getMetadataCount(app.metadata)}
      />
    );
  }

  componentDidMount() {
    const { dispatch, match } = this.props;

    if (!match.params) {
      throw new Error(
        `Expected MobileApplicationInfo to only be rendered when there is an app param`,
      );
    }

    dispatch(getAppInsights(match.params.appId));
  }
}

export default compose(
  tracking(dataCreators.component(AnalyticsScreenEnum.MOBILE_APP_INFO)),
  connect<Props, IntermediateProps, _, _, _, _>(
    (state, props): StateProps => ({
      findings: appConfigurationFindingsByAppIdParam(state, props),
      insights: insightsByAppIdParam(state, props),
    }),
  ),
)(MobileApplicationInfo);
