//@flow
import { createSelector } from 'reselect';
import { appFromParam, type AppIdParam } from './apps';
import type { State } from '../ReduxStateType';
import type {
  AppProtectionTaskCategoriesState,
  AppProtectionTaskCategories,
} from '../reducers/appProtectionTaskCategories';

export const appProtectionTaskCategories = (
  state: State,
): AppProtectionTaskCategoriesState => state.appProtectionTaskCategories;

export const appProtectionCategoriesFromParam = createSelector<
  State,
  AppIdParam,
  ?AppProtectionTaskCategories,
  _,
  _,
>(
  appProtectionTaskCategories,
  appFromParam,
  (categories, app) => (app ? categories[app.id] : null),
);

export const appProtectionPercentForAppFromParam = createSelector<
  State,
  AppIdParam,
  number | null,
  _,
>(
  appProtectionCategoriesFromParam,

  categories => (categories ? categories.COMPLETED.percent : null),
);
