//@flow
import numeral from 'numeral';

function localeStringSort(a, b) {
  if (!a || typeof a.localeCompare !== 'function') {
    return 1;
  }
  return a.localeCompare(b);
}

export function humanReadable(item: string) {
  if (item === 'IOS') {
    return 'iOS';
  }
  return item
    .toLowerCase()
    .replace(/_/g, ' ')
    .trim()
    .replace(/\b\w/g, letter => letter.toUpperCase());
}

export function stringFromParametricRequest(
  endpoint: string,
  params?: { +[key: string]: ?string } = {},
) {
  const keys = Object.keys(params)
    .filter(Boolean)
    .sort(localeStringSort);
  const values = keys
    .map(k => params[k])
    .filter(Boolean)
    .sort(localeStringSort);
  return `${endpoint}-${keys.join('-')}-${values.join('-')}`;
}

export function numberFrom(string: ?string) {
  return numeral(string).value();
}

export function encodeURIFormComponent(string: string) {
  // Encode spaces in params as +
  // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent

  return encodeURIComponent(string).replace(/%20/g, '+');
}

// From: https://stackoverflow.com/a/13627586
export function ordinalize(target: string | number) {
  const i = parseInt(target, 10);
  const j = i % 10;
  const k = i % 100;

  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
}
