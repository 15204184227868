//@flow
import { type APIResponse } from './gapi';

export async function result<T>(response: APIResponse<T>): Promise<T> {
  await check(response);
  if (!response.result) {
    throw new Error(response.statusText || 'An error occurred.');
  }

  return response.result;
}

export async function noResult(response: APIResponse<void>): Promise<void> {
  await check(response);
}

export function byProperty(key: string) {
  return async function<T, U>(response: APIResponse<T>): Promise<U> {
    // Once flow supports it: Promise<$PropertyType<T, typeof key>>
    if (!response) {
      throw new Error('An unexpected error occurred');
    }
    return response[key];
  };
}

export async function check<T>(response: APIResponse<T>): Promise<void> {
  if (response.status < 200 || response.status > 399) {
    throw new Error(response.statusText || 'An error occurred.');
  }
}

export function fixParamArrays(params: { [key: string]: any }) {
  return Object.keys(params).reduce((collector, key) => {
    if (Array.isArray(params[key])) {
      return { ...collector, [key]: { data: params[key] } };
    } else {
      return collector;
    }
  }, params);
}

type mysteryObjT = mixed;

/**
 * This looks pretty ugly but basically you give it an object that represents an error and this abomination will try a
 * couple different techniques for extracting that error to a string.
 * @param mysteryObj
 * @param defaultError
 * @return {*}
 */
export function tryAndParseErrorMessage(
  mysteryObj: mysteryObjT,
  defaultError: string = 'An error occurred',
): string {
  if (!mysteryObj) {
    return defaultError;
  } else if (typeof mysteryObj === 'string') {
    return mysteryObj;
  } else if (mysteryObj.result) {
    if (typeof mysteryObj.result === 'string') {
      return mysteryObj.result;
    } else if (mysteryObj.result.error) {
      if (typeof mysteryObj.result.error.message === 'string') {
        return mysteryObj.result.error.message;
      } else if (typeof mysteryObj.result.error === 'string') {
        return mysteryObj.result.error;
      } else {
        return tryAndParseErrorMessage(mysteryObj.result.error, defaultError);
      }
    } else {
      return tryAndParseErrorMessage(mysteryObj.result, defaultError);
    }
  } else {
    if (typeof mysteryObj === 'object') {
      for (let key in mysteryObj) {
        if (typeof mysteryObj[key] === 'string') {
          return mysteryObj[key];
        }
      }
    }
    if (typeof mysteryObj.toString === 'function') {
      // This function is such garbage but we basically can't change it because
      // we can't predict what types of error messages the server might be
      // returning that this function is handling without doing a lot of work.
      //$FlowFixMe see above
      return mysteryObj.toString();
    } else {
      return defaultError;
    }
  }
}
