//@flow
import { request } from './gapi';
import type { APIResponse } from './gapi';

export type CreateSupportRequestParams = {
  body: string,
  subject: string,
};

export async function create(
  params: CreateSupportRequestParams,
): Promise<APIResponse<void>> {
  if (!params || !params.body || !params.subject) {
    throw new Error('Invalid params supplied to create support request');
  }

  return request({
    path: 'userapi/v2/support_requests',
    method: 'POST',
    body: params,
  });
}
