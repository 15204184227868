// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PageHeading, Markdown, Button } from '@datatheorem/components';
import { browserHistory } from '../../clients/history';
import Page from '../theme/Page';
import JiraWizardForm from '../jira/JiraWizardForm';
import type { DispatchProps } from 'redux';
import type { ContextRouter } from 'react-router-dom';
import { jiraIntegrationConfigListViewPageMounted } from '../../actions';
import jiraIntegrationInstructions from '../../util/md/jira-integration-instructions';
import { type JiraIntegrationConfig } from '@datatheorem/user-api/jira_config';
import { GLOBAL_JIRA_CONFIG_ID } from '@datatheorem/user-api/jira_config';
import { appFromParam } from '../../selectors/apps';
import { type Application } from '@datatheorem/user-api/mobile_apps';
import { humanReadable } from '@datatheorem/string';
import { JIRA_CONFIGS_LOADED_KEY } from '../../strings';
import PageLoader from '../theme/PageLoader';
import UserRole, { type UserRoleEnum } from '@datatheorem/enums/UserRoleEnum';
import { jiraIntegrationConfigFromApp } from '../../selectors/jiraIntegrationConfigs';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

const NoAppFound = (props: { appId: string }) => (
  <PageHeading
    backButton
    title={'No App Found'}
    onClickBack={browserHistory.goBack}
  >
    <Page>
      <h4>There is no App with ID of {props.appId}</h4>
    </Page>
  </PageHeading>
);

type State = {
  showingJiraInstructions: boolean,
};
type Props = {|
  finishedLoadingJiraConfig: boolean,
  finishedLoadingApps: boolean,
  jiraConfiguration: ?JiraIntegrationConfig,
  app: ?Application,
  userRole: ?UserRoleEnum,
  ...ownProps,
|};

type ownProps = {|
  ...DispatchProps<>,
  ...ContextRouter,
|};

/*
 * When the users decides to edit a jira config, we should show
 * them a dedicated page of where to edit that config.
 */
export class JiraEditPage extends Component<Props, State> {
  state = {
    showingJiraInstructions: false,
  };

  toggleDialog = () =>
    this.setState({
      showingJiraInstructions: !this.state.showingJiraInstructions,
    });

  componentDidMount() {
    this.props.dispatch(jiraIntegrationConfigListViewPageMounted());
  }

  getText() {
    const { app, jiraConfiguration, userRole } = this.props;

    const isReadOnly = userRole !== UserRole.MANAGER;

    if (app && jiraConfiguration && jiraConfiguration.is_global)
      return (
        <>
          <p style={{ marginLeft: '30px' }}>
            This app currently does not have an App Specific Jira Configuration.
            It is using the global configuration which can be found in the Jira
            Integration Dashboard.
          </p>
          <p style={{ marginLeft: '30px' }}>
            {isReadOnly
              ? 'Please contact your Manager to create a Jira configuration for this app, if desired.'
              : 'If you would like to create a custom configuration for this app, please fill out the form'}
          </p>
        </>
      );

    if (app && jiraConfiguration)
      return (
        <p style={{ marginLeft: '30px' }}>
          This app has an app-specific Jira configuration.
        </p>
      );

    if (!app && !jiraConfiguration)
      return (
        <>
          <p>
            There currently is no global jira configuration. In order to have
            Jira Integration setup,{' '}
            {isReadOnly
              ? 'please contact your manager to setup it up.'
              : 'please fill out the form below.'}
          </p>
          <p>
            Please note that all Apps will inherit this Jira configuration as
            their own, unless provided an app specific configuration.
          </p>
        </>
      );

    if (!app && jiraConfiguration && jiraConfiguration.is_global)
      return (
        <p style={{ padding: '20px', display: 'block' }}>
          All apps that <b>don&apos;t</b> have an app specific Jira
          configuration will default to using this Global one.
        </p>
      );
  }

  render() {
    const { showingJiraInstructions } = this.state;
    const {
      match,
      app,
      finishedLoadingJiraConfig,
      finishedLoadingApps,
      jiraConfiguration,
      userRole,
    } = this.props;

    if (!finishedLoadingJiraConfig || !finishedLoadingApps)
      return <PageLoader />;

    const isReadOnly = userRole !== UserRole.MANAGER;

    // User entered an url where the app doesn't exist
    if (
      !app &&
      match.params.appId &&
      jiraConfiguration &&
      jiraConfiguration.is_global
    )
      return <NoAppFound appId={match.params.appId} />;

    const pageTitle = app
      ? `Jira Configuration: ${app.name} - ${humanReadable(app.platform)}`
      : 'Global Jira Configuration';

    // By default we pick the jira configuration that was passed
    let jiraConfigurationForWizardForm = jiraConfiguration;
    // If we are looking at an app page. We will either
    // 1. Show the configuration for that app
    // 2. Show *no* configuration, allowing the user to create an app specific one
    if (app) {
      jiraConfigurationForWizardForm =
        jiraConfiguration && app.id === jiraConfiguration.id
          ? jiraConfiguration
          : null;
    }

    return (
      <PageHeading
        backButton
        title={pageTitle}
        onClickBack={browserHistory.goBack}
      >
        <Page>
          <div style={{ display: 'flex', padding: 20 }}>
            <Button
              ariaLabel="Show Jira Integration Instructions"
              onClick={this.toggleDialog}
            >
              Show Jira Integration Instructions
            </Button>
          </div>

          {this.getText()}

          <JiraWizardForm
            jiraIntegrationConfig={jiraConfigurationForWizardForm}
            idToMapJiraConfig={match.params.appId || GLOBAL_JIRA_CONFIG_ID}
            dispatch={this.props.dispatch}
            isReadOnly={isReadOnly}
          />

          <Dialog
            open={showingJiraInstructions}
            onClose={this.toggleDialog}
            aria-labelledby="dialog-title"
            maxWidth="md"
            fullWidth
          >
            <DialogTitle id="dialog-title">
              Jira Integration Instructions
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Markdown text={jiraIntegrationInstructions} />
                <video width="450" height="350" controls>
                  <source
                    src="/dist/videos/jira-integration.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                ariaLabel="Close Jira Integration Instructions"
                primary
                onClick={this.toggleDialog}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Page>
      </PageHeading>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    finishedLoadingJiraConfig: !!state.firstRequest[JIRA_CONFIGS_LOADED_KEY],
    finishedLoadingApps: !!state.firstRequest.apps,
    jiraConfiguration: jiraIntegrationConfigFromApp(state, props),
    app: appFromParam(state, props),
    userRole: state.currentUser && state.currentUser.role,
  };
};

export default connect<Props, ownProps, _, _, _, _>(mapStateToProps)(
  JiraEditPage,
);
