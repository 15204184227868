//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import { palette } from '@datatheorem/theme';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import { compose } from 'recompose';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';

const styles = {
  BroadcastMessage: {
    position: 'fixed',
    top: 17,
    backgroundColor: 'white',
    padding: 20,
    paddingRight: 40,
    zIndex: 1302,
    fontSize: 14,
    marginLeft: '20%',
    marginRight: '20%',
    marginTop: 20,
    boxShadow: '1px 1px 10px',
  },
  CloseButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
};

type Props = {
  children: Node,
  href?: string,
} & InjectedJSS<typeof styles> &
  TrackingProps;

type State = {
  dismissed: boolean,
};

export default compose(
  jss(styles),
  tracking({}),
)(
  class BroadcastMessage extends PureComponent<Props, State> {
    state = {
      dismissed: false,
    };

    render() {
      const { children, classes, href } = this.props;

      if (this.state.dismissed) {
        return null;
      }

      if (!children) {
        return null;
      }

      return (
        <div className={classes.BroadcastMessage}>
          <div className={classes.CloseButton}>
            <IconButton
              tooltip="Dismiss"
              onClick={this.handleDismissBroadcastMessage}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {href ? (
            <a
              style={{ textDecoration: 'none', color: palette.brand }}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              onClick={this._handleBroadcastMessageLinkClick(href)}
            >
              {children}
            </a>
          ) : (
            children
          )}
        </div>
      );
    }

    _handleBroadcastMessageLinkClick = href => {
      const { tracking } = this.props;

      if (!tracking) {
        throw new Error(
          'Expected this component to have been decorated with tracking HoC',
        );
      }

      tracking.trackEvent(dataCreators.broadCastMessageLinkClicked(href));
    };

    handleDismissBroadcastMessage = () => {
      const { tracking } = this.props;

      if (!tracking) {
        throw new Error(
          'Expected this component to have been decorated with tracking HoC',
        );
      }

      this.setState({ dismissed: true });
      tracking.trackEvent(dataCreators.broadCastMessageDismissed());
    };
  },
);
