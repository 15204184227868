//@flow
import type { Application } from '@datatheorem/user-api/mobile_apps';
import type { JiraIntegrationConfig } from '@datatheorem/user-api/jira_config';
import type { ActionType } from 'redux-actions';
import { jiraIntegrationConfigurationsReceived } from '../actions';

export type JiraIntegrationState = {|
  +userMustCreateGlobalConfig: null | boolean,
  +configurations: ConfigurationList,
|};

export type Configuration = {|
  app: Application,
  fields: JiraIntegrationConfig,
  isGlobal: boolean,
|};

export type ConfigurationList = Array<Configuration>;

const initialState = {
  userMustCreateGlobalConfig: null,
  configurations: [],
};

export default (
  state: JiraIntegrationState = initialState,
  action: ActionType<*>,
) => {
  if (action.type === jiraIntegrationConfigurationsReceived.toString()) {
    const shouldCreateGlobalConfig = action.payload.length === 0 ? true : false;
    return {
      userMustCreateGlobalConfig: shouldCreateGlobalConfig,
      configurations: action.payload,
    };
  }

  return state;
};
