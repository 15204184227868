//@flow
import React, { PureComponent } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Chip from 'material-ui/Chip';

import CompliancePolicyDetails from './CompliancePolicyDetails';
import CompliancePolicyLogo from './CompliancePolicyLogo';

type Props = {
  policies: $ReadOnlyArray<string>,
  onChangeComplianceSearchFilter: (
    compliancePolicies: $ReadOnlyArray<string>,
  ) => void,
};

export default class CompliancePolicyFilter extends PureComponent<Props> {
  changeCompliancePolicySearchFilter = (values: $ReadOnlyArray<string>) => {
    this.props.onChangeComplianceSearchFilter(values);
  };

  render() {
    const {
      props,

      changeCompliancePolicySearchFilter,
    } = this;

    const { policies } = props;

    return (
      <div>
        <SelectField
          multiple={true}
          hintText="Select policy"
          value={policies}
          onChange={(event, index, values) =>
            changeCompliancePolicySearchFilter(values)
          }
          selectionRenderer={values => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  paddingTop: 5,
                }}
              >
                {values.slice(0, 2).map(value => (
                  <Chip
                    className="compliance-chip"
                    id={'compliance-chip-' + value}
                    key={value}
                    onRequestDelete={() =>
                      this.changeCompliancePolicySearchFilter(
                        values.filter(v => v !== value),
                      )
                    }
                  >
                    {CompliancePolicyDetails[value].label}
                  </Chip>
                ))}

                {values.length > 2 ? (
                  <Chip
                    className="compliance-chip"
                    id={'compliance-chip-extra'}
                    key="chip-extra"
                  >
                    {values.length - 2} more
                  </Chip>
                ) : null}
              </div>
            );
          }}
        >
          {Object.keys(CompliancePolicyDetails).map(type => {
            const policyDetails = CompliancePolicyDetails[type];

            return (
              <MenuItem
                key={type}
                insetChildren={true}
                checked={policies.indexOf(type) > -1}
                value={type}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                    left: 60,
                    top: 6,
                    height: '1.2em',
                  }}
                >
                  <CompliancePolicyLogo policy={type} size="small" />
                </div>
                <span style={{ position: 'absolute', left: 110 }}>
                  {policyDetails.label}
                </span>
              </MenuItem>
            );
          })}
        </SelectField>
      </div>
    );
  }
}
