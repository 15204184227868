//@flow
import React, { Component } from 'react';
import {
  getAppScanBlockedReasonMapping,
  isCorruptedAppPackageReason,
  isNoCredentialsReason,
} from '../../util/appUtil';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  DialogActions,
} from '@material-ui/core';
import { appScanCredentialsUpdateRequest } from '../../actions';
import { uploadBinaryClicked } from '../../actions';
import { connect } from 'react-redux';
import { type DispatchProps } from 'redux';
import { type AppScanBlockedReasonEnum } from '@datatheorem/enums/AppScanBlockedReasonEnum';

const actionStyles = {
  fontWeight: 'bold',
};

type OwnProps = {
  mobile_app_id: string,
  blockedScanReason: ?AppScanBlockedReasonEnum,
  showAppScanBlockedDialog: boolean,
  onCloseDialog: () => void,
};

type Props = OwnProps & DispatchProps<>;

type State = {
  username: string,
  password: string,
  comments: string,
  submitted: boolean,
};

export class AppScanBlockedDialog extends Component<Props, State> {
  state = {
    username: '',
    password: '',
    comments: '',
    submitted: false,
  };

  render() {
    const { username, password, comments, submitted } = this.state;
    const {
      blockedScanReason,
      showAppScanBlockedDialog,
      onCloseDialog,
    } = this.props;

    const blockedScanReasonMap = getAppScanBlockedReasonMapping(
      blockedScanReason,
    );
    let dialogTitle = blockedScanReasonMap
      ? blockedScanReasonMap.dialogTitle
      : '';
    dialogTitle = submitted ? 'Credentials Submitted' : dialogTitle;

    const usernameField = (
      <TextField
        id="Username"
        fullWidth
        label="Username"
        placeholder="Enter username"
        variant="outlined"
        value={username}
        onChange={this.onChangeAppUsername}
      />
    );

    const passwordField = (
      <TextField
        id="Password"
        fullWidth
        label="Password"
        value={password}
        placeholder="Enter password"
        variant="outlined"
        type="password"
        onChange={this.onChangeAppPassword}
        style={{ paddingBottom: 16 }}
      />
    );

    const commentsField = (
      <TextField
        fullWidth
        multiLine
        label="Comments"
        placeholder="Additional information about steps needed to use test credentials"
        variant="outlined"
        value={comments}
        onChange={this.onChangeAppComments}
        style={{ paddingBottom: 16 }}
      />
    );

    return (
      <div id="app-scan-blocked-dialog-div">
        <Dialog modal open={showAppScanBlockedDialog} onClose={onCloseDialog}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            {isCorruptedAppPackageReason(blockedScanReason) && (
              <span id="corrupted-app-package-span">
                <p>
                  Scans are blocked because the received build is not operating
                  correctly and/or crashes often.
                </p>

                <p style={actionStyles}>
                  Please upload a more stable binary in order to re-enable the
                  scans.
                </p>
              </span>
            )}
            {isNoCredentialsReason(blockedScanReason) && !submitted && (
              <span id="no-credentials-span">
                <p>
                  The scans are currently blocked since the test credentials are
                  absent or are no longer working.
                </p>
                <p style={actionStyles}>
                  Please provide valid username/password in order to re-enable
                  the scans.
                </p>
                {usernameField}
                {passwordField}
                {commentsField}
              </span>
            )}
            {isNoCredentialsReason(blockedScanReason) && submitted && (
              <span id="submit-confirm-message">
                <p>
                  The scan will be retried later today using the new
                  credentials.
                </p>
              </span>
            )}
          </DialogContent>
          <DialogActions>{this.getActionButtons()}</DialogActions>
        </Dialog>
      </div>
    );
  }

  getActionButtons() {
    const { blockedScanReason, onCloseDialog } = this.props;
    const { submitted } = this.state;

    let actionButtons = [];
    if (isNoCredentialsReason(blockedScanReason)) {
      const cancelButton = (
        <Button color="primary" onClick={onCloseDialog} variant="text">
          Cancel
        </Button>
      );

      const submitButton = (
        <Button
          color="primary"
          variant="contained"
          keyboardFocused
          onClick={this.onClickSubmitAppScanCredentials}
        >
          Submit
        </Button>
      );

      const okButton = (
        <Button
          color="primary"
          keyboardFocused
          onClick={onCloseDialog}
          variant="contained"
        >
          Ok
        </Button>
      );

      actionButtons = submitted ? [okButton] : [cancelButton, submitButton];
    } else {
      const cancelButton = (
        <Button
          color="primary"
          keyboardFocused
          variant="text"
          onClick={onCloseDialog}
          className="blocked-scan-dialog-cancel-button"
        >
          Cancel
        </Button>
      );

      const uploadButton = (
        <Button
          color="primary"
          variant="contained"
          keyboardFocused
          onClick={this.onClickUploadButton}
          className="blocked-scan-dialog-upload-button"
        >
          Upload Binary
        </Button>
      );

      actionButtons = [cancelButton, uploadButton];
    }

    return actionButtons;
  }

  onClickUploadButton = this.onClickUploadButton.bind(this);
  onClickUploadButton() {
    this.props.dispatch(uploadBinaryClicked());
  }

  onChangeAppUsername = this.onChangeAppUsername.bind(this);
  onChangeAppUsername(e: SyntheticEvent<HTMLInputElement>) {
    this.setState({ username: e.currentTarget.value });
  }

  onChangeAppPassword = this.onChangeAppPassword.bind(this);
  onChangeAppPassword(e: SyntheticEvent<HTMLInputElement>) {
    this.setState({ password: e.currentTarget.value });
  }

  onChangeAppComments = this.onChangeAppComments.bind(this);
  onChangeAppComments(e: SyntheticEvent<HTMLInputElement>) {
    this.setState({ comments: e.currentTarget.value });
  }

  onClickSubmitAppScanCredentials = this.onClickSubmitAppScanCredentials.bind(
    this,
  );
  onClickSubmitAppScanCredentials() {
    const { mobile_app_id } = this.props;
    const { username, password, comments } = this.state;

    const params = {
      mobile_app_id,
      username,
      password,
      comments,
    };

    this.props.dispatch(appScanCredentialsUpdateRequest(params));
    this.setState({ submitted: true });
  }
}

// $FlowFixMe Flow85
export default connect()(AppScanBlockedDialog);
