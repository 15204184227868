// @flow
import { type Saga } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { clearSessionId } from '@datatheorem/cache';
import Actions from './../actions';

/**
 * Looks for 401 responses and logs the user out if found.
 * @param response
 */
export default function* handleAuthError<T>(
  response: APIResponse<T>,
): Saga<void> {
  if (response && response.status === 401) {
    // We want errors to propogate
    /* eslint-disable redux-saga/no-unhandled-errors */
    yield call(clearSessionId);
    yield put(Actions.response401Received());
    yield put(Actions.sessionExpired());
    /* eslint-enable redux-saga/no-unhandled-errors */
  } else {
    throw response;
  }
}

export type APIResponseBody<T> = T & {
  etag: string,
  kind: string,
  error?: {
    message: string,
  },
};

export type APIResponse<T> = {
  result?: APIResponseBody<T>, // The parsed result returned by GAPI
  body: string, // The raw response body
  headers: { [key: string]: string }, // HTTP response headers
  status: number, // HTTP status code
  statusText?: string, // HTTP status text
};
