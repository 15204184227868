//@flow
import React, { PureComponent, type Node, type ElementRef } from 'react';
import {
  AutoSizer,
  InfiniteLoader,
  List,
  WindowScroller,
} from 'react-virtualized';
import defaultsDeep from 'lodash/defaultsDeep';
import { fontFamily, fontSizes } from '@datatheorem/theme';

const getStyles = (style = {}) => {
  const utilHeight = style.utilHeight || 0;

  return defaultsDeep({}, style, {
    container: {
      marginBottom: 30,
    },
    list: {
      backgroundColor: 'transparent',
      fontFamily: fontFamily,
      fontSize: fontSizes.large,
      fontWeight: 'lighter',
      outline: 0,
    },
    scrollContainer: {
      paddingTop: utilHeight,
    },
  });
};

export type rowRenderProps = {
  index: number,
  isScrolling: boolean,
  isVisible: boolean,
  key: string,
  parent?: Node,
  style: { [key: string]: mixed },
  width: number,
};

type Props = {
  style?: { [string]: mixed },
  children: (props: rowRenderProps) => Node,
  totalCount?: number,
  onSelectItem?: () => void,
  onScroll?: ({ scrollTop: number }) => mixed,
  isRowLoaded: boolean | (({ index: number }) => boolean),
  loadMoreRows?: ?() => mixed,
  rowHeight: number | ((props: { index: number }) => number),
  currentCount: number,
  util?: Node,
};

export default class BigList extends PureComponent<Props> {
  list: ?ElementRef<typeof List> = null;

  render() {
    const { style, util } = this.props;

    const styles = getStyles(style);

    return (
      <div style={styles.container}>
        {util}
        <div style={styles.scrollContainer}>{this.renderList(styles)}</div>
      </div>
    );
  }

  renderList = (styles: { list: { [string]: mixed } }) => {
    const {
      totalCount,
      children,
      rowHeight,
      currentCount,
      isRowLoaded,
      style,
      ...other
    } = this.props;
    return (
      <WindowScroller onScroll={this.onScroll}>
        {({ scrollTop, height }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={this.loadMoreRows.bind(this)}
                rowCount={totalCount}
              >
                {({ onRowsRendered, registerChild }) => (
                  <List
                    height={height}
                    rowCount={currentCount}
                    scrollTop={scrollTop}
                    autoHeight
                    onRowsRendered={onRowsRendered}
                    rowHeight={rowHeight}
                    width={width || 1000}
                    style={styles.list}
                    ref={node => registerChild((this.list = node))}
                    rowRenderer={data => children({ ...data, width })}
                    {...other}
                  />
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    );
  };

  onScroll = () => {
    if (
      !global ||
      !global.document ||
      typeof this.props.onScroll !== 'function'
    ) {
      return;
    }

    const scrollTop =
      global.pageYOffset || global.document.documentElement.scrollTop;

    this.props.onScroll({ scrollTop });
  };

  loadMoreRows = function() {
    const { loadMoreRows } = this.props;
    if (typeof loadMoreRows === 'function') {
      loadMoreRows.apply(this, arguments);
    }
  };

  recomputeRowHeights = () => {
    if (this.list) {
      this.list.recomputeRowHeights(0);
    }
  };
}
