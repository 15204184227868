//@flow
import React, { PureComponent } from 'react';
import BigList from '../theme/BigList';
import { type Application } from '@datatheorem/user-api/mobile_apps';
import SecurityFindingsUtil from '../theme/SecurityFindingsUtil';
import { LoadingFiller } from '@datatheorem/components';
import type { SDKIssueGroup } from './SDKForAppsCard';
import SDKForAppsCard from './SDKForAppsCard';
import ListCardSpacer from '../theme/ListCardSpacer';
import type { rowRenderProps } from '../theme/BigList';

import {
  type CategoryMetadataCount,
  type ShadowMetadataCountType,
} from '@datatheorem/user-api/mobile_apps';
import {
  ShadowScanSummaryCard,
  ShadowScanSummaryCardClass,
  MeasureableHeightDiv,
} from '@datatheorem/components';

const styles = {
  util: {
    container: {
      position: 'fixed',
      zIndex: 2,
      height: 72,
      right: 46,
      width: 130,
    },
  },
};

type Props = {
  apps: $ReadOnlyArray<Application>,
  totalMetadataCount: CategoryMetadataCount,
  sdkIssueGroups: $ReadOnlyArray<SDKIssueGroup>,
  onSelectSDK: (sdkGroup: SDKIssueGroup) => void,
  filler: boolean,
  loadMoreRows: ?() => mixed,
  onChangeShowHideClosed: (value: boolean) => void,
  filterValue: boolean,
  onClickShadowScanInquire: (params: {|
    title?: ?string,
    count?: ?number,
    breakdown?: ?ShadowMetadataCountType,
  |}) => mixed,
};

export default class SDKIssueList extends PureComponent<Props> {
  list = null;
  _shadowscanCard = null;

  render() {
    const {
      totalMetadataCount,
      sdkIssueGroups,
      loadMoreRows,
      onChangeShowHideClosed,
      filterValue,
      filler,
    } = this.props;

    if (filler) {
      return this.renderFiller();
    }

    const codaCount = ShadowScanSummaryCardClass.shouldShowFor(
      'insight',
      totalMetadataCount.SHADOW,
    )
      ? 1
      : 0;

    if (!sdkIssueGroups || sdkIssueGroups.length + codaCount === 0) {
      return this.renderEmpty();
    }

    return (
      <BigList
        ref={node => (this.list = node)}
        style={{ utilHeight: styles.util.container.height }}
        loadMoreRows={loadMoreRows}
        totalCount={sdkIssueGroups.length + codaCount + 1}
        rowHeight={this.getRowHeight}
        currentCount={sdkIssueGroups.length + codaCount}
        isRowLoaded={rowData => this.isRowLoaded(codaCount, rowData)}
        util={
          <SecurityFindingsUtil
            style={styles.util}
            onChangeShowHideClosed={onChangeShowHideClosed}
            filterValue={filterValue}
            compliancePolicyFilter={[]}
            onChangeComplianceSearchFilter={() => {}}
            showCompliancePolicyFilter={false}
            showDoMore={true}
          />
        }
      >
        {({ index, style, key }: rowRenderProps) => {
          const { sdkIssueGroups, apps, onClickShadowScanInquire } = this.props;

          return (
            <div
              key={key}
              style={style}
              onClick={this.onClick.bind(this, index)}
            >
              {index >= sdkIssueGroups.length ? (
                <MeasureableHeightDiv
                  ref={node => (this._shadowscanCard = node)}
                >
                  <ListCardSpacer>
                    <ShadowScanSummaryCard
                      type={'insight'}
                      shadowMetadataCount={totalMetadataCount.SHADOW}
                      onClickInquire={onClickShadowScanInquire}
                    />
                  </ListCardSpacer>
                </MeasureableHeightDiv>
              ) : (
                <ListCardSpacer>
                  <SDKForAppsCard
                    sdkIssueGroup={sdkIssueGroups[index]}
                    apps={apps}
                  />
                </ListCardSpacer>
              )}
            </div>
          );
        }}
      </BigList>
    );
  }

  renderFiller = () => {
    return <LoadingFiller headings={10} descriptionLines={0} />;
  };

  renderEmpty = () => {
    return (
      <p style={{ textAlign: 'center' }}>
        No issues match the current criteria
      </p>
    );
  };

  componentDidUpdate(prevProps: Props) {
    if (this.list && prevProps.sdkIssueGroups !== this.props.sdkIssueGroups) {
      this.list.recomputeRowHeights();
    }
  }

  onClick = (index: number) => {
    const { onSelectSDK, sdkIssueGroups } = this.props;
    onSelectSDK(sdkIssueGroups[index]);
  };

  getRowHeight = ({ index }: { index: number }) => {
    const { sdkIssueGroups } = this.props;

    if (index >= sdkIssueGroups.length) {
      if (
        this._shadowscanCard &&
        typeof this._shadowscanCard.measure === 'function'
      ) {
        return this._shadowscanCard.measure().height;
      } else {
        return 600;
      }
    }

    return SDKForAppsCard.getRowHeight({
      sdkIssueGroup: sdkIssueGroups[index],
    });
  };

  isRowLoaded = (codaCount: number, { index }: { index: number }) => {
    const { sdkIssueGroups } = this.props;
    return index < sdkIssueGroups.length + codaCount;
  };
}
