//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

import PriorityBadge from './../../PriorityBadge';

import type { FindingsBadgeTypeEnum } from './FindingsBadgeTypeEnum';
import FindingsBadgeType from './FindingsBadgeTypeEnum';

type Props = {
  noLabel: boolean,
  type: FindingsBadgeTypeEnum,
  count: number,
} & InjectedJSS<typeof styles>;

const styles = {
  container: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  label: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textAlign: 'center',
    color: 'rgb(119, 119, 119)',
  },

  count: {
    marginLeft: 5,
    marginRight: 5,
    fontWeight: 'bold',
    color: ({ type }: Props) => {
      if (type === FindingsBadgeType.P1) {
        return '#f00';
      }

      return '#000';
    },
  },
};

class FindingsBadge extends PureComponent<Props> {
  _getBadgeElement(noLabel, type, classes) {
    switch (type) {
      case FindingsBadgeType.P1:
        return (
          <div className={classes.label}>
            <PriorityBadge type={'P1'} />
          </div>
        );

      case FindingsBadgeType.COMPLIANCE:
        return (
          <div className={classes.label}>
            <span>Compliance Issues</span>
          </div>
        );

      case FindingsBadgeType.INSIGHT:
        return (
          <div className={classes.label}>
            <span>Insights</span>
          </div>
        );

      case FindingsBadgeType.BLOCKER:
        return (
          <div className={classes.label}>
            <span>App/Play Store Blockers</span>
          </div>
        );
    }

    return <div />;
  }

  render() {
    const { type, count, noLabel, classes } = this.props;

    const _getBadgeElement = this._getBadgeElement;

    return (
      <div className={classes.container}>
        {!noLabel ? _getBadgeElement(noLabel, type, classes) : null}

        <span className={classes.count}>{count}</span>
      </div>
    );
  }
}

export default jss(styles)(FindingsBadge);
