//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { updateInsightFindings } from '../actions';
import type { InsightFinding } from '@datatheorem/user-api/insight_findings';

export type InsightFindingsState = $ReadOnlyArray<InsightFinding>;

// $FlowFixMe FlowUpgrade
export default createReducerCreator({
  initialState: ([]: $ReadOnlyArray<InsightFinding>),
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList(updateInsightFindings.toString()));
