//@flow
import React, { Component } from 'react';
import flatten from 'lodash/flatten';
import { MenuItem, MenuDivider } from '@datatheorem/components';

import MenuFilter from '../theme/MenuFilter';
import { LoadingFiller, MenuLinkItem } from '@datatheorem/components';
import { hasSubscription } from '../../util/appUtil';
import type { AppsByPlatform } from '../../selectors/apps';

import { IconLabel } from '@datatheorem/components';

const PLATFORMS = {
  IOS: 'iOS Apps',
  ANDROID: 'Android Apps',
  WINDOWS_PHONE: 'Windows Apps',
  ANDROID_AMAZON: 'Amazon Apps',
  ANDROID_OCULUS: 'Oculus Apps',
};

const SHADOWSCAN = 'shadowscan';

const defaultOpenMenus: $ReadOnlyArray<string> = Object.keys(PLATFORMS).concat(
  SHADOWSCAN,
);

type Props = {
  appsByPlatform: AppsByPlatform,
  priorityCount?: number,
  filler?: boolean,
};

type State = {
  filter: string,
  openMenus: $ReadOnlyArray<string>,
};

export default class Menu extends Component<Props, State> {
  state = {
    filter: '',
    openMenus: defaultOpenMenus,
  };

  render() {
    const { appsByPlatform, priorityCount } = this.props;
    const { filter, openMenus } = this.state;

    const appsWithNoSub = flatten(
      Object.keys(PLATFORMS)
        .map(
          platform =>
            appsByPlatform[platform] &&
            appsByPlatform[platform].filter(app => !hasSubscription(app)),
        )
        .filter(Boolean),
    ).filter(app => app && app.id);

    return (
      <div>
        <MenuLinkItem heading label="All Issues" path="/issues/all" />
        <MenuLinkItem
          heading
          label="All OSS/SDK Issues"
          path="/sdk-issues/all"
        />
        <MenuLinkItem
          heading
          label="Regulatory Compliance"
          path="/issues/compliance"
        />
        <MenuDivider />
        <MenuLinkItem
          heading
          label="Priority Alerts"
          badge={priorityCount ? priorityCount.toString() : null}
          highlightBadge={priorityCount}
          path="/issues/priority"
        />
        <MenuLinkItem label="Security P1s" path="/issues/security" />
        <MenuLinkItem label="Google Play Blockers" path="/issues/google" />
        <MenuLinkItem label="App Store Blockers" path="/issues/apple" />

        <MenuDivider space={true} />
        <MenuLinkItem heading label="All Applications" path="/apps" />
        {this.renderPreApps()}

        {Object.keys(PLATFORMS).map(platform => {
          const appsForPlatform =
            appsByPlatform &&
            appsByPlatform[platform] &&
            appsByPlatform[platform].filter(hasSubscription);

          if (!appsForPlatform || !appsForPlatform.length) {
            return null;
          }

          const retval = (
            <div key={platform}>
              <MenuItem
                heading
                label={
                  <IconLabel platform={platform}>
                    {PLATFORMS[platform]}
                  </IconLabel>
                }
                open={openMenus.includes(platform) || filter.length > 0}
                onClick={() => this.handleClickMenuHeading(platform)}
              >
                {appsForPlatform
                  .filter(Boolean)
                  .filter(
                    app =>
                      !filter.length ||
                      app.name.toLowerCase().indexOf(filter.toLowerCase()) !==
                        -1,
                  )
                  .map((app, i) => (
                    <MenuLinkItem
                      key={i}
                      label={app.name}
                      path={`/app/${app.id}`}
                    />
                  ))}
              </MenuItem>
            </div>
          );
          return retval;
        })}

        {appsWithNoSub &&
          appsWithNoSub.length > 0 && (
            <div>
              <MenuDivider />
              <MenuItem
                heading
                label="Unsubscribed Apps"
                open={openMenus.includes(SHADOWSCAN) || filter.length > 0}
                onClick={() => this.handleClickMenuHeading(SHADOWSCAN)}
              >
                {appsWithNoSub
                  .filter(
                    app =>
                      !filter.length ||
                      app.name.toLowerCase().indexOf(filter.toLowerCase()) !==
                        -1,
                  )
                  .map(app => (
                    <MenuLinkItem
                      key={app.id}
                      label={
                        <IconLabel platform={app.platform}>
                          {app.name}
                        </IconLabel>
                      }
                      path={`/app/${app.id}`}
                    />
                  ))}
              </MenuItem>
            </div>
          )}
        <div style={{ marginTop: 50 }} />
        <MenuFilter value={filter} onChange={this.onChangeFilter} />
      </div>
    );
  }

  renderPreApps = () => {
    if (!this.props.filler) {
      return;
    }

    return <LoadingFiller />;
  };

  onChangeFilter = (value: string) => {
    this.setState({
      filter: value,
    });
  };

  handleClickMenuHeading = (platform: string) => {
    const { openMenus } = this.state;

    const isOpened = openMenus.includes(platform);

    this.setState({
      openMenus: isOpened
        ? openMenus.filter(p => p !== platform)
        : openMenus.concat(platform),
    });
  };
}
