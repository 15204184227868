//@flow
import React from 'react';
import Paper from '@material-ui/core/Paper';

import { Button } from '@datatheorem/components';

type Props = {
  submitLabel: string,
  onSubmit: (e: SyntheticMouseEvent<HTMLElement>) => mixed,
  loading: boolean,
  disabled?: boolean,
};

export default function PanelSubmitField(props: Props) {
  const { submitLabel, onSubmit, loading, disabled = false } = props;
  return (
    <Paper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingBottom: 10,
          paddingTop: 10,
          paddingRight: 10,
        }}
      >
        <Button
          onClick={onSubmit}
          isLoading={loading}
          disabled={disabled}
          ariaLabel={submitLabel}
        >
          {submitLabel}
        </Button>
      </div>
    </Paper>
  );
}
