//@flow
import React, { Component, type Node } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { palette } from '@datatheorem/theme';
import {
  Card as MaterialCard,
  CardContent,
  Typography,
} from '@material-ui/core';
import { Button } from '@datatheorem/components';

type State = {};
type Props = {
  title: string,
  avatar?: ?Node,
  description: Node,
  buttonLabel: string,
  onClick: () => void,
};

/*
 * A card to display the Jira configuration for the app.
 * When the user clicks on it, should direct them to the
 * page where they can edit the configuration, *unless*
 * it's a global config, in which case they should be
 * able to edit it in place
 */
export default class JiraCard extends Component<Props, State> {
  render() {
    const { title, avatar, description, buttonLabel, onClick } = this.props;
    const avatarTarget =
      typeof avatar === 'string' ? (
        <Avatar src={avatar} style={{ backgroundColor: palette.white }} />
      ) : (
        avatar
      );
    return (
      <MaterialCard>
        <CardContent>
          <div style={{ display: 'flex' }}>
            <div style={{ paddingRight: 15 }}>{avatarTarget}</div>
            <div>
              <Typography variant="h6">{title}</Typography>
              <Typography variant="body2">{description}</Typography>
              <div style={{ marginLeft: -6 }}>
                <Button
                  variant="contained"
                  onClick={onClick}
                  ariaLabel={buttonLabel}
                >
                  {buttonLabel}
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
      </MaterialCard>
    );
  }
}
