//@flow
import React, {
  PureComponent,
  type ElementProps,
  type ComponentType,
} from 'react';
import SvgIcon from 'material-ui/SvgIcon';
import AndroidLogo from 'material-ui/svg-icons/action/android';
import GenericSmartphoneIcon from 'material-ui/svg-icons/hardware/smartphone';

type IconComponent = ComponentType<ElementProps<typeof SvgIcon>>;

const AppleLogo: IconComponent = props => (
  <SvgIcon {...props}>
    <path d="M18.71,19.5C17.88,20.74 17,21.95 15.66,21.97C14.32,22 13.89,21.18 12.37,21.18C10.84,21.18 10.37,21.95 9.1,22C7.79,22.05 6.8,20.68 5.96,19.47C4.25,17 2.94,12.45 4.7,9.39C5.57,7.87 7.13,6.91 8.82,6.88C10.1,6.86 11.32,7.75 12.11,7.75C12.89,7.75 14.37,6.68 15.92,6.84C16.57,6.87 18.39,7.1 19.56,8.82C19.47,8.88 17.39,10.1 17.41,12.63C17.44,15.65 20.06,16.66 20.09,16.67C20.06,16.74 19.67,18.11 18.71,19.5M13,3.5C13.73,2.67 14.94,2.04 15.94,2C16.07,3.17 15.6,4.35 14.9,5.19C14.21,6.04 13.07,6.7 11.95,6.61C11.8,5.46 12.36,4.26 13,3.5Z" />
  </SvgIcon>
);

const WindowsLogo: IconComponent = props => (
  <SvgIcon {...props}>
    <path d="M3,12V6.75L9,5.43V11.91L3,12M20,3V11.75L10,11.9V5.21L20,3M3,13L9,13.09V19.9L3,18.75V13M20,13.25V22L10,20.09V13.1L20,13.25Z" />
  </SvgIcon>
);

const OculusLogo: IconComponent = props => (
  <SvgIcon {...props} viewBox="0 0 2400 2400">
    <path d="m 1830,1200 c -32.8,-22.56 -69.1,-36.13 -108.1,-42.47 -39,-6.33 -77.8,-5.09 -116.83,-5.09 -268.22,0 -536.44,0 -804.67,0 -39.08,0 -77.89,-1.24 -116.92,5.11 -39.05,6.35 -75.35,19.95 -108.13,42.55 -65.65,45.26 -105.07,119.42 -105.03,199.34 0.05,79.88 39.51,153.98 105.17,199.19 32.77,22.55 69.05,36.13 108.08,42.46 39,6.33 77.78,5.09 116.83,5.09 268.23,0 536.45,0 804.67,0 39.13,0 77.93,1.24 116.93,-5.1 39,-6.35 75.3,-19.96 108.1,-42.55 65.7,-45.27 105.1,-119.43 105,-199.34 0,-79.89 -39.5,-153.99 -105.1,-199.19 z m 324.4,772.25 c -86.4,69.35 -185.3,117.25 -292.8,143.12 -61.7,14.81 -123.3,21.39 -186.4,23.46 -46.7,1.52 -93.43,1.1 -140.15,1.1 -221.54,0 -443.08,0 -664.63,0 -46.74,0 -93.47,0.42 -140.21,-1.1 -63.14,-2.07 -124.8,-8.66 -186.45,-23.5 -107.56,-25.89 -206.39,-73.8 -292.77,-143.15 -173.74,-139.49 -274.91,-350.06 -274.89,-572.94 0.02,-222.86 101.22,-433.42 274.98,-572.87 86.41,-69.35 185.25,-117.24 292.84,-143.11 61.61,-14.82 123.24,-21.4 186.34,-23.46 46.73,-1.53 93.44,-1.1 140.16,-1.1 221.55,0 443.09,0 664.63,0 46.74,0 93.45,-0.43 140.25,1.1 63.1,2.06 124.8,8.66 186.4,23.49 107.6,25.89 206.4,73.81 292.8,143.16 173.7,139.48 274.9,350.06 274.9,572.93 0,222.87 -101.2,433.42 -275,572.87" />
  </SvgIcon>
);

const AmazonLogo: IconComponent = props => (
  <SvgIcon {...props}>
    <path d="M13.958 10.09c0 1.232.029 2.256-.591 3.351-.502.891-1.301 1.438-2.186 1.438-1.214 0-1.922-.924-1.922-2.292 0-2.692 2.415-3.182 4.7-3.182v.685zm3.186 7.705c-.209.189-.512.201-.745.074-1.052-.872-1.238-1.276-1.814-2.106-1.734 1.767-2.962 2.297-5.209 2.297-2.66 0-4.731-1.641-4.731-4.925 0-2.565 1.391-4.309 3.37-5.164 1.715-.754 4.11-.891 5.942-1.095v-.41c0-.753.06-1.642-.383-2.294-.385-.579-1.124-.82-1.775-.82-1.205 0-2.277.618-2.54 1.897-.054.285-.261.567-.549.582l-3.061-.333c-.259-.056-.548-.266-.472-.66.704-3.716 4.06-4.838 7.066-4.838 1.537 0 3.547.41 4.758 1.574 1.538 1.436 1.392 3.352 1.392 5.438v4.923c0 1.481.616 2.13 1.192 2.929.204.287.247.63-.01.839-.647.541-1.794 1.537-2.423 2.099l-.008-.007zm3.559 1.988c-2.748 1.472-5.735 2.181-8.453 2.181-4.027 0-7.927-1.393-11.081-3.706-.277-.202-.481.154-.251.416 2.925 3.326 6.786 5.326 11.076 5.326 3.061 0 6.614-1.214 9.066-3.494.406-.377.058-.945-.357-.723zm.67 2.216c-.091.227.104.32.31.147 1.339-1.12 1.685-3.466 1.411-3.804-.272-.336-2.612-.626-4.04.377-.22.154-.182.367.062.337.805-.096 2.595-.312 2.913.098.319.41-.355 2.094-.656 2.845z" />
  </SvgIcon>
);

const PLATFORM_TO_COMPONENT = {
  IOS: AppleLogo,
  ANDROID: AndroidLogo,
  WINDOWS_PHONE: WindowsLogo,
  ANDROID_OCULUS: OculusLogo,
  ANDROID_AMAZON: AmazonLogo,
  CROSS_PLATFORM: GenericSmartphoneIcon,
  SERVER_SIDE: GenericSmartphoneIcon,
  UNKNOWN: GenericSmartphoneIcon,
};

export type PlatformIconTypes = $Keys<typeof PLATFORM_TO_COMPONENT>;

type Props = {
  platform: PlatformIconTypes,
};

export function isSupportedPlatform(platform: string): boolean %checks {
  return (
    platform === 'ANDROID' ||
    platform === 'IOS' ||
    platform === 'WINDOWS_PHONE' ||
    platform === 'ANDROID_OCULUS' ||
    platform === 'ANDROID_AMAZON'
  );
}

export default class PlatformIcon extends PureComponent<Props> {
  render() {
    const { platform, ...props } = this.props;

    const Component = PLATFORM_TO_COMPONENT[platform];

    return <Component {...props} />;
  }
}
