//@flow
import React, { PureComponent, type Node, isValidElement } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import Paper from 'material-ui/Paper';
import PriorityBadge from './PriorityBadge';
import { palette } from '@datatheorem/theme';
import ChatBubbleOutline from 'material-ui/svg-icons/communication/chat-bubble-outline';
import SvgIcon from 'material-ui/SvgIcon';
import Image from './Image';
import MediawatchPresentIcon from 'material-ui/svg-icons/social/whatshot';
import chunk from 'lodash/fp/chunk';
import classnames from 'classnames';

import HardwareSecurity from 'material-ui/svg-icons/hardware/security';
import CompliancePolicyBanner from './compliance/CompliancePolicyBanner';
import { type CompliancePolicyReference } from '@datatheorem/findings/types';

const visibleTargetLimit = 3;

const styles = {
  CardWithTargets: {
    position: 'relative',
    cursor: ({ noPointer }: Props) => !noPointer && 'pointer',
  },

  contentContainer: {
    marginLeft: ({ logo }: Props) => (logo ? 100 : 55),
    marginRight: 10,
    paddingTop: 7,
  },

  row: {
    paddingTop: 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },

  priority: {
    position: 'absolute',
    left: 20,
    top: 10,
  },

  statusIndicator: {
    position: 'absolute',
    width: 7,
    top: 0,
    left: 0,
    bottom: 0,
    borderRadius: '2px 0px 0px 2px',
    backgroundColor: ({ indicator }: Props) => {
      if (indicator === 'good') {
        return palette.green;
      } else if (indicator === 'bad') {
        return palette.red;
      } else if (indicator === 'neutral') {
        return palette.gray35;
      } else if (indicator === 'attention') {
        return palette.red;
      } else if (indicator === 'warning') {
        return palette.yellow;
      } else {
        return 'transparent';
      }
    },
  },

  left: {
    color: palette.gray,
    fontSize: 12,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  right: {
    color: palette.gray,
    fontSize: 12,
    flexShrink: 1,
    whiteSpace: 'nowrap',
  },

  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 16,
    color: palette.gray20,
  },

  childrenContainer: {
    paddingTop: 10,
    overflow: 'hidden',
    color: palette.gray,
  },

  iconContainer: {
    color: palette.gray,
    fontSize: 12,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
    flexGrow: 2,
    flexShrink: 0,
    justifyContent: 'flex-end',
  },

  notesBubble: {
    width: '12px !important',
    height: '12px !important',
    marginRight: '4px !important',
    marginLeft: '4px',
    color: palette.gray,
    verticalAlign: '-2px !important',
  },

  mediawatchIcon: {
    width: '16px !important',
    height: '16px !important',
    marginRight: '4px !important',
    color: palette.gray,
    verticalAlign: '-2px !important',
  },

  appProtectionIcon: {
    width: '24px !important',
    height: '24px !important',
    marginRight: '4px !important',
    color: palette.green20,
    verticalAlign: '-2px !important',
  },

  logo: {
    position: 'absolute',
    top: 0,
    width: 100,
    padding: 20,
  },

  childAndIconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },

  badge: {
    backgroundColor: palette.gray50,
    borderColor: palette.gray50,
    borderStyle: 'solid',
    borderRadius: 2,
    padding: '0 2px',
    marginRight: 4,
  },

  appProtectionBadgeIcon: {
    width: '5px !important',
    height: '5px !important',
    verticalAlign: '-10px !important',
  },
};

type OwnProps = {|
  text?: string | $ReadOnlyArray<?string | ?Node>,
  indicator?: 'neutral' | 'good' | 'bad' | 'attention' | 'warning' | Node,
  priority?: ?string,
  commentCount?: number,
  compliance_policy_references?: $ReadOnlyArray<CompliancePolicyReference>,
  logo?: string,
  mediawatchPresent?: boolean,
  children?: ?Node,
  noPointer?: boolean,
  badge?: ?string,
  appProtectionBadgeTitle?: string | null,
  appProtectionBadgeAchieved?: boolean,
|};

type Props = {| ...OwnProps, ...InjectedJSS<typeof styles> |};

export default jss(styles)(
  class CardWithTargets extends PureComponent<Props> {
    render() {
      const {
        text,
        priority,
        commentCount,
        compliance_policy_references,
        logo,
        mediawatchPresent,
        children,
        classes,
        indicator,
        badge,
        appProtectionBadgeTitle,
        appProtectionBadgeAchieved,
      } = this.props;

      const texts = Array.isArray(text) ? text : [text];

      const hasComplianceImplications =
        compliance_policy_references && compliance_policy_references.length > 0;

      return (
        <Paper className={classes.CardWithTargets}>
          <div className={classes.contentContainer}>
            {chunk(2)(texts).map((pair, i) => (
              <div className={classes.row} key={i}>
                <span
                  className={classnames(classes.left, i === 0 && classes.title)}
                >
                  {pair[0]}
                </span>
                <div className={classes.right}>{pair[1]}</div>
              </div>
            ))}
            <div className={classes.childAndIconContainer}>
              <div className={classes.childrenContainer}>{children}</div>
              <div className={classes.iconContainer}>
                {badge && (
                  <div key="badge" className={classes.badge}>
                    {badge}
                  </div>
                )}
                {appProtectionBadgeTitle && (
                  <SvgIcon
                    className={classes.appProtectionIcon}
                    color={
                      appProtectionBadgeAchieved
                        ? palette.green20
                        : palette.gray
                    }
                  >
                    <title>{appProtectionBadgeTitle}</title>
                    <HardwareSecurity
                      color={
                        appProtectionBadgeAchieved
                          ? palette.green20
                          : palette.gray
                      }
                    />
                  </SvgIcon>
                )}
                {/* Mediawatch Indicator */}
                {mediawatchPresent ? (
                  <SvgIcon
                    className={classes.mediawatchIcon}
                    color={styles.mediawatchIcon.color}
                  >
                    <title>Media Watch</title>
                    <MediawatchPresentIcon />
                  </SvgIcon>
                ) : null}

                {/* Compliance Policy Indicators */}
                {hasComplianceImplications ? (
                  <CompliancePolicyBanner
                    compliance_policy_references={
                      compliance_policy_references || []
                    }
                  />
                ) : null}

                {/* Comment Count Indicator */}
                {commentCount ? (
                  <span>
                    <SvgIcon
                      className={classes.notesBubble}
                      color={styles.notesBubble.color}
                    >
                      <title>Comments</title>
                      <ChatBubbleOutline />
                    </SvgIcon>
                    {commentCount}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          {isValidElement(indicator) ? (
            indicator
          ) : (
            <div className={classes.statusIndicator} />
          )}
          <div className={classes.priority}>
            <PriorityBadge type={priority} />
          </div>
          {logo && (
            <div className={classes.logo}>
              <Image src={logo} />
            </div>
          )}
        </Paper>
      );
    }
  },
);

export function getRowHeight(
  targetsCount: ?number,
  findingsCount: number = 1,
  sdkMode: boolean = false,
) {
  const baseHeight = 70;

  if (!targetsCount) {
    return baseHeight;
  }

  if (sdkMode) {
    return baseHeight + targetsCount * 25 + findingsCount * 25;
  } else {
    return (
      baseHeight +
      (targetsCount > visibleTargetLimit ? visibleTargetLimit : targetsCount) *
        25
    );
  }
}
