//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import { Link } from 'react-router-dom';

import { CenteredContainer, Button } from '@datatheorem/components';

import BigLogo from '../BigLogo';

const styles = {
  LogoWithInfoPage: {},
};

type Props = {
  heading?: ?string,
  children?: ?Node,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class LogoWithInfoPage extends PureComponent<Props> {
    render() {
      const { classes, heading, children } = this.props;

      return (
        <div className={classes.LogoWithInfoPage}>
          <CenteredContainer>
            <BigLogo />
            <h1>{heading}</h1>
            {children}
            <Link to="/">
              <Button ariaLabel="Ok">Ok</Button>
            </Link>
          </CenteredContainer>
        </div>
      );
    }
  },
);
