//@flow
import { object, array, string, boolean, mixed } from 'yup';

import { type FindingTargetStatusEnum } from '@datatheorem/enums/FindingTargetStatusEnum';
import type { Target } from '@datatheorem/findings/types';

import { request } from './gapi';
import { result, byProperty } from './util';
import BlacklistReason, {
  type BlacklistReasonEnum,
} from '@datatheorem/enums/BlacklistReasonEnum';

export type BlacklistedIPAddress = {
  ip_address: string,
  blacklist: BlacklistReasonEnum,
  reason: string,
};

export type CommunicationTypes = $ReadOnlyArray<string> | void;
export type BlacklistedCommunicationType = $ReadOnlyArray<BlacklistedIPAddress> | void;
export type CommunicationInsights = {|
  communicates_with_countries: CommunicationTypes,
  communicates_with_hostnames: CommunicationTypes,
  communicates_with_ip_addresses: CommunicationTypes,
  communicates_with_blacklisted_ip_addresses: BlacklistedCommunicationType,
  communicates_with_first_party_origins: CommunicationTypes,
  communicates_with_third_party_origins: CommunicationTypes,
|};

export type AndroidInsights = {
  android_minimum_sdk_version: string,
  android_permissions: $ReadOnlyArray<string>,
  android_sdk_target_version: string,
};

export type IOSInsights = {
  ios_base_sdk: string | void,
  ios_minimum_sdk: string | void,
  ios_supports_face_id: boolean,
  ios_supports_imessage: boolean,
  ios_supports_apple_watch: boolean,
  ios_permissions: $ReadOnlyArray<string> | void,
};

export type Insights = {
  mobile_app_id: string,
  ios_insights: IOSInsights | void,
  android_insights: AndroidInsights | void,
  communication_insights: CommunicationInsights | void,
};

const BlacklistedIPAddressSchema = object().shape({
  ip_address: string().required(),
  blacklist: mixed()
    .label('Blacklist')
    .oneOf(Object.values(BlacklistReason))
    .required(),
  reason: string().required(),
});

const InsightSchema = object().shape({
  mobile_app_id: string().required(),
  ios_insights: object().shape({
    ios_base_sdk: string().notRequired(),
    ios_minimum_sdk: string().notRequired(),
    ios_supports_face_id: boolean().notRequired(),
    ios_supports_imessage: boolean().notRequired(),
    ios_supports_apple_watch: boolean().notRequired(),
    ios_permissions: array()
      .of(string())
      .notRequired(),
  }),
  android_insights: object().shape({
    android_minimum_sdk_version: string().notRequired(),
    android_permissions: array()
      .of(string())
      .notRequired(),
    android_sdk_target_version: string().notRequired(),
  }),
  communication_insights: object().shape({
    communicates_with_countries: array()
      .of(string())
      .notRequired(),
    communicates_with_hostnames: array()
      .of(string())
      .notRequired(),
    communicates_with_ip_addresses: array()
      .of(string())
      .notRequired(),
    communicates_with_blacklisted_ip_addresses: array()
      .of(BlacklistedIPAddressSchema)
      .notRequired(),
  }),
});

async function validateInsights(insights: Insights): Promise<Insights> {
  return InsightSchema.validate(insights);
}

export async function getInsights(id: string): Promise<Insights> {
  if (!id || !id.length) {
    throw new Error('Invalid mobile app ID provided.');
  }

  return request({ path: `userapi/v2/mobile_app_insights/${id}` })
    .then(result)
    .then(validateInsights);
}

export type InsightFinding = {
  description?: string,
  title?: string,
  date_updated?: string, // Date ISO 8601: "2017-01-31T14:09:07.313240"
  mobile_app_id?: string,
  aggregated_status_date?: string, // Date ISO 8601: "2017-01-31T14:09:07.313240"
  targets?: $ReadOnlyArray<Target>,
  aggregated_status?: FindingTargetStatusEnum,
  date_created?: string, // Date ISO 8601: "2017-01-31T14:09:07.313240"
  id: string,
};

const InsightFindingSchema = object().shape({
  id: string().required(),
});

async function validate(finding: InsightFinding): Promise<InsightFinding> {
  return InsightFindingSchema.validate(finding);
}

async function validateList(
  findings: $ReadOnlyArray<InsightFinding>,
): Promise<$ReadOnlyArray<InsightFinding>> {
  return array()
    .of(InsightFindingSchema)
    .validate(findings);
}

export async function get(id: string): Promise<InsightFinding> {
  if (!id || !id.length) {
    throw new Error('Invalid Insight finding ID provided');
  }
  return request({ path: `userapi/v2/insight_findings/${id}` })
    .then(result)
    .then(validate);
}

type ListParams = {
  cursor?: string,
  mobile_app_id?: string,
  status_group?: string,
  template_id?: string,
  updated_since?: string,
};

export async function list(
  params?: ListParams,
): Promise<$ReadOnlyArray<InsightFinding>> {
  return request({ path: 'userapi/v2/insight_findings', params: params })
    .then(result)
    .then(byProperty('insight_findings'))
    .then(validateList);
}
