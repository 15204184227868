//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import AuthenticatedApp from './AuthenticatedApp';
import { Route, Switch, type Location } from 'react-router-dom';
import { type User } from '@datatheorem/user-api/users';
import LogoWithInfoPage from '../theme/LogoWithInfoPage';
import {
  Notifier,
  Actions as NotificationsActions,
} from '@datatheorem/notifications';
import type { Notification } from '@datatheorem/notifications';
import {
  DTAuthenticated,
  CenteredCircularProgress,
} from '@datatheorem/components';
import {
  Actions as SessionActions,
  type CurrentSessionState,
} from '@datatheorem/session';
import { ScrollToTop, ErrorPage } from '@datatheorem/components';
import { dismissError } from '../../actions';
import type { AccountInfo } from '@datatheorem/user-api/account_info';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from '@material-ui/core';

function redirectFromHashPathsToRegularPaths({ location }) {
  if (location.hash && location.hash.length > 1) {
    global.location.href = location.hash.replace('#', '');
  }

  return null;
}

function redirectDeactivatedProducts({ openscan, scan_and_secure }) {
  return function({ location }) {
    const isOpenscanPath = location.pathname.indexOf('/openscan') === 0;
    const isScanAndSecurePath = location.pathname.indexOf('/issues') === 0;

    if (isOpenscanPath && !openscan) {
      global.location.href = '/';
      throw new Error('Redirecting from bad path');
    }

    if (isScanAndSecurePath && !scan_and_secure) {
      global.location.href = '/openscan/';
      throw new Error('Redirecting from bad path');
    }

    return null;
  };
}

const staticPageRouteMap = {
  '/status/success': {
    heading: 'Subscription Confirmed',
    children: (
      <p>
        The App&apos;s subscription has been updated. Scans will start within
        the hour.
      </p>
    ),
  },
  '/status/monitoring': {
    heading: 'Subscription Confirmed',
    children: (
      <p>The App&apos;s subscription has been updated (monitoring only).</p>
    ),
  },
  '/status/retired': {
    heading: 'Error',
    children: (
      <p>
        The App is no longer available. Please contact{' '}
        <a href="mailto:info@datatheorem.com">info@datatheorem.com</a>
      </p>
    ),
  },
  '/status/expired': {
    heading: 'Error',
    children: (
      <p>
        The link has expired. Please contact{' '}
        <a href="mailto:info@datatheorem.com">info@datatheorem.com</a>
      </p>
    ),
  },
};

type OwnProps = {|
  location: Location,
|};

type StateProps = {|
  currentUser: User | null,
  accountInfo: ?AccountInfo,
  appAuthState: CurrentSessionState,
  unknownErrorCode: ?string,
  notifications: $ReadOnlyArray<Notification>,
|};

type DispatchProps = {|
  dispatchReceiveSessionCookie: (sessionCookie: string) => mixed,
  dispatchDismissNotificationClicked: (notification: Notification) => mixed,
  dispatchDismissError: () => mixed,
|};

type Props = {| ...OwnProps, ...StateProps, ...DispatchProps |};

export default connect<Props, OwnProps, _, _, _>(
  ({ currentUser, accountInfo, strings, auth, notifications }): StateProps => ({
    currentUser,
    accountInfo,
    appAuthState: auth,
    unknownErrorCode: strings.unknownErrorCode,
    notifications,
  }),
  {
    dispatchReceiveSessionCookie: SessionActions.receivedCookie,
    dispatchDismissNotificationClicked:
      NotificationsActions.dismissNotificationClicked,
    dispatchDismissError: dismissError,
  },
)(
  class App extends PureComponent<Props> {
    render() {
      const {
        currentUser,
        location,
        accountInfo,
        unknownErrorCode,
        dispatchDismissError,
        appAuthState,
        notifications,

        dispatchReceiveSessionCookie,
        dispatchDismissNotificationClicked,
      } = this.props;

      const { pathname, hash, search } = location;

      return (
        <div>
          <ScrollToTop pathname={pathname} hash={hash} search={search} />
          <Route>{redirectFromHashPathsToRegularPaths}</Route>
          <Route>
            {accountInfo &&
              redirectDeactivatedProducts(accountInfo.toggles || {})}
          </Route>

          <Switch>
            <Route path="/error" component={ErrorPage} />
            {Object.keys(staticPageRouteMap).map(path => (
              <Route
                key={path}
                path={path}
                render={() => (
                  <LogoWithInfoPage {...staticPageRouteMap[path]} />
                )}
              />
            ))}
            <DTAuthenticated
              onReceiveSessionCookie={dispatchReceiveSessionCookie}
              appAuthState={appAuthState}
              waitingDisplay={<CenteredCircularProgress />}
            >
              <Route
                render={props =>
                  accountInfo && currentUser ? (
                    <AuthenticatedApp
                      {...props}
                      currentUser={currentUser}
                      accountInfo={accountInfo}
                    />
                  ) : (
                    <CenteredCircularProgress />
                  )
                }
              />
            </DTAuthenticated>
          </Switch>

          <Dialog
            open={Boolean(unknownErrorCode)}
            onClose={dispatchDismissError}
          >
            <DialogTitle>An Error Occurred</DialogTitle>
            <DialogContent>
              <Typography>
                An error occurred. Whatever you were just doing may not have
                been saved. Please contact support@datatheorem.com and paste
                this error code: <code>{unknownErrorCode}</code>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                keyboardFocused={true}
                onClick={dispatchDismissError}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Notifier
            notifications={notifications}
            onDismissClick={dispatchDismissNotificationClicked}
          />
        </div>
      );
    }
  },
);
