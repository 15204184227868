//@flow
import React, { PureComponent, type Node } from 'react';
import Page from '../theme/Page';
import ExpansionPanelItem from '../theme/ExpansionPanelItem';
import { LoadingFiller } from '@datatheorem/components';
import type { User } from '@datatheorem/user-api/users';

type Props = {
  profile: User,
  children?: Node,
};

export default class ReadOnlySummary extends PureComponent<Props> {
  render() {
    const { profile, children } = this.props;
    return (
      <div style={{ padding: 40 }}>
        <div style={{ display: 'flex' }}>
          <p style={{ paddingRight: 24, paddingLeft: 24 }}>
            Please contact your manager to change any of this information.
          </p>
          {profile ? null : <LoadingFiller />}
          {profile && (
            <Page>
              <ExpansionPanelItem
                disabled
                primaryText="First Name"
                value={profile.first_name}
              />
              <ExpansionPanelItem
                disabled
                primaryText="Last Name"
                value={profile.last_name}
              />
              <ExpansionPanelItem
                disabled
                primaryText="Email"
                value={profile.login_email}
              />
              <ExpansionPanelItem
                disabled
                primaryText="Role"
                value={profile.role}
              />
              {children}
            </Page>
          )}
        </div>
      </div>
    );
  }
}
