//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { ActionType } from 'redux-actions';
import { PageHeading } from '@datatheorem/components';
import { userDeleteClicked, userEditFormSubmitted } from '../../actions';
import EditForm from '../users/EditForm';
import { userFromParam } from '../../selectors/users';
import { sortedApps } from '../../selectors/apps';
import type { User } from '@datatheorem/user-api/users';
import type { Application } from '@datatheorem/user-api/mobile_apps';
import { compose } from 'recompose';
import { withFormik, type FormikProps } from 'formik';
import {
  getSimplestRole,
  hasAllApps,
  metaFieldNameForAllApps,
  UserSchema,
} from '@datatheorem/session';
import { palette } from '@datatheorem/theme';

import DeleteIcon from '@material-ui/icons/DeleteForever';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { type DispatchProps } from 'redux';
import { browserHistory } from '../../clients/history';
import type { ContextRouter } from 'react-router-dom';
import type { State as ReduxState } from '../../ReduxStateType';

type OwnProps = ContextRouter;

type StateProps = {|
  user: ?User,
  apps: $ReadOnlyArray<Application>,
  loading: mixed,
  formError: mixed,
  deletionLoading: mixed,
|};

type DP = DispatchProps<
  | ActionType<typeof userDeleteClicked>
  | ActionType<typeof userEditFormSubmitted>,
>;

type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DP,
  ...FormikProps<*>,
  ...TrackingProps,
|};

type IntermediatePropsFirst = {|
  ...OwnProps,
  ...FormikProps<*>,
  ...TrackingProps,
|};

type State = {|
  deleteConfirmationOpen: boolean,
|};

export default compose(
  tracking(dataCreators.component(AnalyticsScreenEnum.USERS_EDIT)),
  connect<Props, IntermediatePropsFirst, _, _, _, _>(
    (state: ReduxState, props): StateProps => ({
      user: userFromParam(state, props),
      apps: sortedApps(state, props),
      loading: state.loading.userEdit,
      formError: state.errors.userEdit,
      deletionLoading: state.loading.userDelete,
    }),
  ),

  //$FlowFixMe: https://github.com/facebook/flow/pull/7298
  withFormik({
    handleSubmit(values, { props }) {
      // $FlowFixMe
      props.dispatch(userEditFormSubmitted({ ...values, id: props.user.id }));
    },
    mapPropsToValues({ user }) {
      if (!user) {
        // This solves a bug that exists when you load this page by going to
        // the URL directly or reloading the page. The users are still loading
        // so they can't be mapped to formik. A better solution is to have a
        // loading state of some kind but this is just a quick patch.
        //$FlowFixMe
        return {};
      }
      return {
        ...user,
        role: getSimplestRole(user.role),
        [metaFieldNameForAllApps]: hasAllApps(user.role),
      };
    },
    validationSchema: UserSchema,
  }),
)(
  class UserEdit extends PureComponent<Props, State> {
    state = {
      deleteConfirmationOpen: false,
    };

    componentDidUpdate(prevProps) {
      if (
        this.props.user !== prevProps.user &&
        typeof this.props.resetForm === 'function'
      ) {
        return this.props.resetForm();
      }
      if (
        this.props.setSubmitting &&
        this.props.loading !== prevProps.loading
      ) {
        this.props.setSubmitting(!!this.props.loading);
      }
    }

    render() {
      const {
        user,
        apps,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        loading,
        formError,
        deletionLoading,
      } = this.props;

      if (!handleSubmit) {
        throw new Error('Expected this component to be wrapped with formik');
      }

      const { deleteConfirmationOpen } = this.state;

      if (!user) {
        return null;
      }

      const removeUserAction = {
        label: !deletionLoading ? 'Remove User' : 'Removing User...',
        icon: <DeleteIcon color={palette.brand} />,
        style: { color: palette.brand },
        onClick: this.handleClickDelete,
        disabled: !!deletionLoading,
      };

      return (
        <PageHeading
          backButton
          onClickBack={browserHistory.goBack}
          title="People"
          primaryAction={removeUserAction}
        >
          <EditForm
            onSubmit={handleSubmit}
            apps={apps}
            formError={
              formError && typeof formError._error === 'string'
                ? formError._error
                : null
            }
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            loading={!!loading}
            submitLabel="Save"
          />
          <Dialog open={deleteConfirmationOpen}>
            <DialogTitle>Confirm User Removal </DialogTitle>
            <DialogContent>
              If you continue, {user.first_name} {user.last_name} (
              {user.login_email}) will no longer have access to the Data Theorem
              portal.
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClickCancelDelete}> Cancel</Button>
              <Button
                color="primary"
                variant="contained"
                keyboardFocused={true}
                onClick={this.handleConfirmDelete}
              >
                Remove This User
              </Button>
            </DialogActions>
          </Dialog>
        </PageHeading>
      );
    }

    handleClickDelete = () => {
      this.setState({
        deleteConfirmationOpen: true,
      });
    };

    handleClickCancelDelete = () => {
      this.setState({
        deleteConfirmationOpen: false,
      });
    };

    handleConfirmDelete = () => {
      const { user, dispatch } = this.props;

      if (!user) {
        throw new Error(
          'This component should be refactored to always have a User',
        );
      }

      this.handleClickCancelDelete();
      dispatch(userDeleteClicked(user));
    };
  },
);
