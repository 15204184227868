//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import DialogSection from '@datatheorem/components/src/security-finding-dialog/DialogSection';
import { fontSizes } from '@datatheorem/theme';
import { humanReadable } from '@datatheorem/string';
import DialogForDetails from '@datatheorem/components/src/security-finding-dialog/DialogForDetails';
import type { SDK } from '@datatheorem/user-api/sdks';

const styles = {
  logo: {
    width: 100,
  },
  image: {
    maxWidth: '100%',
  },
  name: {
    fontSize: fontSizes.huge,
    textAlign: 'center',
    paddingTop: 20,
    marginBottom: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

type Props = {
  sdk: SDK,
  onClose: () => void,
  children: Node,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class SDKIssueDetail extends PureComponent<Props> {
    render() {
      const { sdk, onClose, children, classes } = this.props;
      if (!sdk) {
        return null;
      }

      const { title, description, logo_url, category } = sdk;

      return (
        <DialogForDetails
          header={
            <div>{category && `Category: ${humanReadable(category)}`}</div>
          }
          onClose={onClose}
        >
          <h2 className={classes.name}>
            <div className={classes.logo}>
              <img src={logo_url} className={classes.image} />
            </div>
            {title || 'Issue'}
          </h2>

          <DialogSection title="Description" markdown={description} />

          {children}
        </DialogForDetails>
      );
    }
  },
);
