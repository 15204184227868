//@flow
import imageAppDashboard from './images/2018-9-10-applications-dashboard.png';
import imageAPIKeys from './images/2018-07-20-api-key-management-update.gif';
import imageCompliance from './images/2018-07-17-regulatory-compliance.png';
import imageDeleteUser from './images/2018-01-25-delete-user.gif';
import imageAdvancedSearch from './images/2018-02-01-advanced-search.png';
import imageNewSearch from './images/2018-11-07-new-search.gif';
import imageAppSearch from './images/2018-11-08-app-search.gif';
import imageNotificationPreferences from './images/2019-05-09-notification-preferences.gif';

export default [
  {
    date: {
      year: 2019,
      month: 6,
      day: 17,
    },
    markdown: `The [MITRE ATT&CK](https://attack.mitre.org/) categories are now cross linked with existing Data Theorem security issues!
Please click on Regulatory Compliance -> MITRE ATT&CK for more details.`,
    title: 'App Secure Update',
  },
  {
    date: {
      year: 2019,
      month: 5,
      day: 9,
    },
    markdown: `It is now possible to customize your alerts via our new preferences page. Check it out by clicking on the gear icon in the upper right corner of the portal and selecting Preferences from the menu, or by [clicking here](https://www.securetheorem.com/preferences).`,
    title: 'Alerts preferences',
    image: imageNotificationPreferences,
  },
  {
    date: {
      year: 2018,
      month: 11,
      day: 8,
    },
    markdown: `App Search gets a new look with protection metrics, a categorized watchlist, and an easier to use Search!

See how mobile development teams are proactively protecting their applications and see which protections an application currently has.
With the new categorized watchlist you can also now watch applications with a designated group in mind.

Get alerts on the application teams working to keep the mobile applications you use secure.`,
    title: 'NEW App Search',
    image: imageAppSearch,
  },
  {
    date: {
      year: 2018,
      month: 11,
      day: 7,
    },
    markdown: `The Search feature has been [rebuilt from the ground up](https://www.securetheorem.com/search) to be easier to use.

Click the Search button at the top of any page (including this one) to get started. You can search and filter security findings, and save your searches as a Report. You can view your [Reports](https://www.securetheorem.com/reports) by clicking "View Reports" on the Search page.`,
    title: 'Search & Reports',
    image: imageNewSearch,
  },
  {
    date: {
      year: 2018,
      month: 10,
      day: 9,
    },
    markdown: `DataTheorem's [PreProd upload API](https://datatheorem.github.io/PortalApi/upload_api.html) now supports iOS Bitcode submissions.

Bitcode is a format used by Apple to optimize or retarget an app's machine code after the app has been submitted to the App Store.
DataTheorem will automatically compile the App like the App Store does so it is suitable for analysis.`,
    title: 'Support for iOS Bitcode App Uploads',
  },
  {
    date: {
      year: 2018,
      month: 9,
      day: 10,
    },
    markdown: `A new [Applications Dashboard](https://www.securetheorem.com/apps) is now available to users.

Here you can view all of the applications you have access to along with their security profile.`,
    title: 'Applications Dashboard',
    image: imageAppDashboard,
  },
  {
    date: {
      year: 2018,
      month: 7,
      day: 20,
    },
    markdown: `Multiple API keys can now be created to access Data Theorem APIs. Each API key can be configured to access specific APIs.

Depending on the API, each API key can be restricted to data from a subset of the apps. The API keys can be managed from the [DevSecOps](https://www.securetheorem.com/sdlc) page of the portal.
**Note, if you have an existing Results API key it is still active and is named All Apps Access (Results API)**.`,
    title: 'API Key Management Update',
    image: imageAPIKeys,
  },
  {
    date: {
      year: 2018,
      month: 7,
      day: 17,
    },
    markdown: `Users now have the ability to view security findings related to specific compliance policies.

Available compliance policies include: GDPR, PCI, HIPAA, FTC, OWASP, CalOPPA, COPPA, VPPA, and CCPA`,
    title: `Regulatory Compliance`,
    image: imageCompliance,
  },
  {
    date: {
      year: 2018,
      month: 5,
      day: 23,
    },
    markdown: `A new IOS check was added to detect App code vulnerable to a new remote code execution exploit

The [Zipperdown exploit](https://zipperdown.org/) allows an attacker to inject malicious data into applications. Code vulnerable to this is flagged.`,
    title: `New check for specific code vulnerable to Zipperdown`,
  },

  {
    date: {
      year: 2018,
      month: 2,
      day: 20,
    },
    markdown: `The Android check, "App not targeting the latest version of Android API" was upgraded to a P1 priority because of recent Google Play announcements.

As of [August 2018](https://android-developers.googleblog.com/2017/12/improving-app-security-and-performance.html), Play will require that new apps and app updates target a recent Android API level.`,
    title: `App not targeting the latest version of Android API`,
  },

  {
    date: {
      year: 2018,
      month: 2,
      day: 15,
    },
    markdown: `A new IOS check was added to detect when an application has been built using a deprecated SDK version.

As of [April 2018](https://developer.apple.com/ios/submit/), Apple will no longer allow app submissions for apps compiled with an SDK older than iOS 11.0.`,
    title: `New check for apps built against a deprecated SDK version`,
  },
  {
    date: {
      year: 2018,
      month: 2,
      day: 9,
    },
    markdown: `A new IOS check for detecting calls to private iOS APIs was added. This new check will be run on any iOS application that has a baseline subscription.

Classes and symbols in iOS frameworks that are not exposed via the public iOS SDK are considered private, and accessing these APIs may result in automatic rejection from the App Store. It should be noted that this issue has no security impact.`,
    title: `New check for apps accessing private iOS APIs`,
  },
  {
    date: {
      year: 2018,
      month: 1,
      day: 1,
    },
    markdown: `Removing users is now possible if your role is **Manager**.`,
    image: imageDeleteUser,
    title: 'Remove Users',
  },
  {
    date: {
      year: 2018,
      month: 1,
      day: 31,
    },
    title: `What's New`,
    markdown: `You're looking at it!`,
  },
  {
    date: {
      year: 2018,
      month: 1,
      day: 1,
    },
    markdown: `Several areas now show better feedback when a network request is ongoing.`,
  },
  {
    date: {
      year: 2017,
      month: 12,
      day: 12,
    },
    markdown: `A new check for server endpoints was added, in order to detect the ROBOT vulnerability. This new check will be run on mobile endpoints, for any application that has a dynamic subscription.

The ROBOT vulnerability was made public on December 12, 2017, and allows an attacker to extract the private session key used to create an otherwise secure TLS session. More information is available on the vulnerability's website at https://robotattack.org/ .`,
    title: `ROBOT Vulnerability Check`,
  },
  {
    date: {
      year: 2018,
      month: 2,
      day: 1,
    },
    title: 'Advanced Search',
    markdown: `Users now have the ability to search, sort, and filter all findings using a powerful set of tools.`,
    image: imageAdvancedSearch,
  },
];
