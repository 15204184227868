//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import theme from '@datatheorem/theme';

const styles = {
  ErrorMessageBlock: {
    color: 'white',
    backgroundColor: theme().dataTheorem.palette.attention,
    padding: 10,
    borderRadius: 3,
    margin: 20,
  },
};

type Props = {
  children: Node,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class ErrorMessageBlock extends PureComponent<Props> {
    render() {
      const { classes, children } = this.props;
      return (
        <div className={classes.ErrorMessageBlock}>
          <pre>{children}</pre>
        </div>
      );
    }
  },
);
