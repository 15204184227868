//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

import CompliancePolicyDetails from './CompliancePolicyDetails';
import Image from './../Image';

import questionMarkImage from './images/question-mark.svg';

const styles = {
  textLogo: {
    borderStyle: 'solid',
    border: '2px solid #000000',
    borderRadius: 50,
    padding: '5px',
  },
};

type Props = {
  policy: string,
  size: 'small' | 'large',
} & InjectedJSS<typeof styles>;

class CompliancePolicyLogo extends PureComponent<Props> {
  static defaultProps = {
    size: 'small',
  };

  render() {
    const {
      classes,

      policy,
      size,
    } = this.props;

    if (CompliancePolicyDetails[policy]) {
      return (
        <Image
          src={CompliancePolicyDetails[policy].src}
          style={{
            width:
              size === 'small'
                ? CompliancePolicyDetails[policy].smallWidth
                : CompliancePolicyDetails[policy].largeWidth,
            height:
              size === 'small'
                ? CompliancePolicyDetails[policy].smallHeight
                : CompliancePolicyDetails[policy].largeHeight,
          }}
          onFailSrc={questionMarkImage}
          alt={CompliancePolicyDetails[policy].label}
          title={CompliancePolicyDetails[policy].label}
        />
      );
    }

    return <span className={classes.textLogo}>{policy}</span>;
  }
}

export default jss(styles)(CompliancePolicyLogo);
