//@flow
import store from 'store';

const PREFIX = 'datatheorem_';
const WHATSNEW_KEY = 'whatsnew';
const FILTERS = 'filters';
const FILTERS_BACKUP = 'filters-backup';

// Types
import type { SavedFilters } from '../actions/filterActions';

function set(key, val) {
  store.set(`${PREFIX}${key}`, val);
}

function get(key) {
  return store.get(`${PREFIX}${key}`);
}

export function setWhatsNewSeen(date: Date): void {
  set(WHATSNEW_KEY, date.getTime());
}

export function getWhatsNewSeen(): ?Date {
  const time = get(WHATSNEW_KEY);
  if (typeof time === 'number') {
    return new Date(time);
  } else {
    return null;
  }
}

export function clearWhatsNewSeen(): void {
  set(WHATSNEW_KEY, null);
}

export function getFilters(): SavedFilters {
  //$FlowFixMe: It's just easier to force cast this to SavedFilters, even though there's no actual guarantee that the store will return the correct object.
  return get(FILTERS) || {};
}

export function setBackedUp() {
  return set(FILTERS_BACKUP, 'BACKEDUP');
}

export function getBackedUp() {
  return get(FILTERS_BACKUP);
}
