//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { mediawatchRoutine } from '../actions/mediawatch';
import type { MediawatchArticle } from '@datatheorem/user-api/mediawatch';

export type MediawatchArticlesState = $ReadOnlyArray<MediawatchArticle>;

// $FlowFixMe FlowUpgrade
export default createReducerCreator({
  initialState: ([]: MediawatchArticlesState),
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList(mediawatchRoutine.SUCCESS.toString()));
