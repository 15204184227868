//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import type { AppProtectionTask } from '@datatheorem/user-api/mobile_apps';
import Header from '@datatheorem/components/src/Header';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { fontFamily, fontSizes, palette } from '@datatheorem/theme';
import AppProtectionTaskCard from './AppProtectionTaskCard';
import type { BadgeTypeEnum } from '@datatheorem/components';
import ProtectionBadge from '@datatheorem/components/src/app-protection-badges/Badge';
import { getBadgeDescriptionFromBadgeType } from '@datatheorem/components';

const styles = {
  header: {
    wrapper: {
      backgroundColor: palette.darkBg,
      color: palette.inacitveColor,
      justifyContent: 'space-between',
      paddingLeft: 10,
    },
  },
  content: {
    marginTop: 50,
    padding: '10px 80px',
    backgroundColor: palette.tableDark,
    fontFamily: fontFamily,
    paddingTop: 50,
    paddingBottom: 50,
  },
  title: {
    textAlign: 'center',
  },
  badge: {
    width: 83,
    height: 83,
    margin: '0 auto',
  },
  description: {
    marginTop: 10,
    marginBottom: 40,
    textAlign: 'center',
  },
  dialogSectionTitle: {
    display: 'inline-block',
    margin: 0,
    marginBottom: 10,
    fontSize: fontSizes.medium,
  },
  dialogSectionSubtitle: {
    display: 'inline-block',
    fontSize: fontSizes.medium,
    float: 'right',
  },
  buttonIcon: {
    marginLeft: 4,
  },
};

type Props = {
  enabled: boolean,
  onClose: () => mixed,
  badgeType: BadgeTypeEnum,
  tasksForBadge: $ReadOnlyArray<AppProtectionTask>,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class AppProtectionBadgeTasksDialog extends PureComponent<Props> {
    render() {
      const {
        tasksForBadge,
        onClose,
        badgeType,
        enabled,
        classes,
      } = this.props;
      const description =
        badgeType && getBadgeDescriptionFromBadgeType(badgeType);

      return (
        <>
          <Header
            style={styles.header}
            secondaryNavigationItems={
              <Button
                variant="outlined"
                onClick={onClose}
                style={{ color: palette.white }}
              >
                Close <CloseIcon className={classes.buttonIcon} />
              </Button>
            }
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>{}</div>
          </Header>
          <div className={classes.content}>
            <h2 className={classes.title}>
              {tasksForBadge.length > 1 ? 'Tasks ' : 'Task '}
              {'For Badge'}
            </h2>

            <div>
              <div className={classes.badge}>
                <ProtectionBadge type={badgeType} enabled={enabled} />
              </div>

              <div className={classes.description}>{description}</div>
            </div>

            {tasksForBadge.map((task, i) => (
              <div key={task.issue_type_id} style={{ marginBottom: 10 }}>
                {i === 0 && enabled && (
                  <div>
                    <span className={classes.dialogSectionTitle}>
                      Completed
                    </span>
                    <span className={classes.dialogSectionSubtitle}>
                      Included in score
                    </span>
                  </div>
                )}
                {i === 0 && !enabled && task.security_finding_id && (
                  <div>
                    <span className={classes.dialogSectionTitle}>
                      Incomplete
                    </span>
                    <span className={classes.dialogSectionSubtitle}>
                      Complete to earn badge
                    </span>
                  </div>
                )}
                {i === 0 && !enabled && !task.security_finding_id && (
                  <div>
                    <span className={classes.dialogSectionTitle}>
                      Not Applicable
                    </span>
                  </div>
                )}
                <AppProtectionTaskCard key={task.issue_type_id} task={task} />
              </div>
            ))}
          </div>
        </>
      );
    }
  },
);
