//@flow
import * as React from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import Paper from 'material-ui/Paper';

const styles = {
  paper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 8,
    paddingRight: 8,
    height: ({ height }: Props) => {
      return height;
    },
    marginLeft: ({ marginLeft }: Props) => {
      return marginLeft;
    },
  },
};

type Props = {
  children?: React.Node,
  height?: number,
  marginLeft?: number,
  backgroundColor?: string,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class ChildCard extends React.PureComponent<Props> {
    static defaultProps = {
      marginLeft: 0,
      height: 0,
    };

    render() {
      const { children, classes, backgroundColor } = this.props;
      return (
        <Paper
          className={classes.paper}
          style={{
            backgroundColor: backgroundColor,
            boxShadow:
              'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0) 0px 2px 4px',
            borderRadiusTopRight: 'none',
            borderRadiusTopLeft: 'none',
          }}
        >
          {children}
        </Paper>
      );
    }
  },
);
