// @flow
import createReducerCreator from 'rezz/createReducerCreator';
import assign from 'rezz/assign';
import type { AccountInfo } from '@datatheorem/user-api/account_info';
import type { User } from '@datatheorem/user-api/users';

import Actions from './actions';
import type { CurrentSessionState } from './';

const auth = createReducerCreator<CurrentSessionState, _, _>()(
  assign([
    Actions.cancelledSession.toString(),
    Actions.sessionExpired.toString(),
    Actions.sessionValidated.toString(),
    Actions.sessionValidationRequested.toString(),
  ]),
);

const accountInfo = createReducerCreator<?AccountInfo, _, _>()(
  assign([
    Actions.accountInfoReceived.toString(),
    Actions.response401Received.toString(),
    Actions.signOutButtonClicked.toString(),
  ]),
);

const currentUser = createReducerCreator<?User, _, _>()(
  assign([
    Actions.currentUserReceived.toString(),
    Actions.response401Received.toString(),
    Actions.signOutButtonClicked.toString(),
  ]),
);

export default {
  auth: auth,
  accountInfo: accountInfo,
  currentUser: currentUser,
};
