//@flow
import React, { PureComponent } from 'react';

type Props = {
  src: string,
};

export default class BrandedProductIcon extends PureComponent<Props> {
  render() {
    return <img src={this.props.src} style={{ marginRight: 10, height: 24 }} />;
  }
}
