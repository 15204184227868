//@flow
import React, { PureComponent } from 'react';

import type { FindingTargetStatusEnum } from '@datatheorem/enums/FindingTargetStatusEnum';
import CircularProgress from 'material-ui/CircularProgress';
import { palette } from '@datatheorem/theme';

import {
  statusToFriendlyString,
  isClosedStatus,
  isNeutralStatus,
} from '@datatheorem/findings/targets/status';

import Indicator from './Indicator';

type Props = {
  status: FindingTargetStatusEnum,
  onClick?: ?(SyntheticEvent<HTMLElement>) => void,
  loading?: ?boolean,
};

export default class TargetStatus extends PureComponent<Props> {
  render() {
    const { status, onClick, loading } = this.props;

    return (
      <Indicator
        label={
          loading ? (
            <CircularProgress
              size={14}
              thickness={2}
              style={{ marginTop: 2 }}
            />
          ) : (
            statusToFriendlyString(status)
          )
        }
        onClick={onClick}
        backgroundColor={
          palette[
            isNeutralStatus(status)
              ? 'gray45'
              : isClosedStatus(status)
              ? 'green50'
              : 'red50'
          ]
        }
        color={
          palette[
            isNeutralStatus(status)
              ? 'gray10'
              : isClosedStatus(status)
              ? 'green10'
              : 'red10'
          ]
        }
      />
    );
  }
}
