//@flow
import { enumToString } from '@datatheorem/enums';

const AuthStrategy = {
  GOOGLE_ACCOUNT: 'Google SSO',
  OTP: 'OTP',
  SAML: 'SAML SSO',
  default: 'Uknown',
};

const UserRole = {
  DEVELOPER: 'Developer',
  MANAGER: 'Manager',
  SECURITY: 'Security',
  DEVELOPER_ALL_APPS: 'Developer (All Apps)',
  SECURITY_ALL_APPS: 'Security (All Apps)',
  default: 'Unknown',
};

export function authStrategy(strategy: string): string {
  return enumToString(strategy, AuthStrategy);
}

export function userRole(role: string): string {
  return enumToString(role, UserRole);
}
