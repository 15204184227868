//@flow

/**
 * NOTE: This file should be the first file included by entry points. This file's responsibility is to set up "globals"
 * and import libraries that affect the global namespace. Try to limit what you import here!
 */

import Raven from 'raven-js';
import './polyfill';
import config from '@datatheorem/config';

import '@datatheorem/theme/global';

if (
  config.sentry &&
  typeof config.sentry.public_key === 'string' &&
  typeof config.sentry.project_id === 'number' &&
  typeof config.sentry.host === 'string'
) {
  const sentryKey = config.sentry.public_key;
  const sentryProjecId = config.sentry.project_id;
  const sentryHost = config.sentry.host;

  Raven.config(`https://${sentryKey}@${sentryHost}/${sentryProjecId}`, {
    release: process.env.BITBUCKET_COMMIT || '',
  }).install();

  Raven.setTagsContext({
    environment: process.env.NODE_ENV,
    version: process.env.BITBUCKET_BUILD_NUMBER,
  });

  /* eslint-disable no-console */
  if (console && console.info) {
    console.log('Acheron Sentry Initialized.');
  }
  /* eslint-disable no-console */
}

export { Raven };

/* eslint-disable no-console */
if (
  console &&
  console.info &&
  process &&
  process.env &&
  process.env.BITBUCKET_BUILD_NUMBER
) {
  console.info(
    'Acheron Bitbucket Pipeline',
    'https://bitbucket.org/datatheorem/obol/addon/pipelines/home#!/results/' +
      process.env.BITBUCKET_BUILD_NUMBER,
  );
}
/* eslint-enable no-console */
