// @flow
import { createAction } from 'redux-actions';
import type { User } from '@datatheorem/user-api/users';
import type { AccountInfo } from '@datatheorem/user-api/account_info';

const response401Received = createAction<'401_RESPONSE_RECEIVED'>(
  '401_RESPONSE_RECEIVED',
);

const receivedCookie = createAction<
  'RECEIVED_COOKIE_FROM_AUTH_HOST',
  [string],
  string,
>('RECEIVED_COOKIE_FROM_AUTH_HOST', sessionCookie => sessionCookie);

const signOutButtonClicked = createAction<'SIGN_OUT_BUTTON_CLICKED'>(
  'SIGN_OUT_BUTTON_CLICKED',
);

const currentUserReceived = createAction<'CURRENT_USER_RECEIVED', [User], User>(
  'CURRENT_USER_RECEIVED',
  user => user,
);

const cancelledSession = createAction<'CANCELLED_SESSION', [], 'CANCELING'>(
  'CANCELLED_SESSION',
  () => 'CANCELING',
);

const sessionExpired = createAction<'SESSION_EXPIRED', [], 'SESSION_EXPIRED'>(
  'SESSION_EXPIRED',
  () => 'SESSION_EXPIRED',
);

const sessionValidated = createAction<'SESSION_VALIDATED', [], 'LOGGED_IN'>(
  'SESSION_VALIDATED',
  () => 'LOGGED_IN',
);

const sessionValidationRequested = createAction<
  'SESSION_VALIDATING',
  [],
  'VALIDATING',
>('SESSION_VALIDATING', () => 'VALIDATING');

const accountInfoReceived = createAction<
  'ACCOUNT_INFO_RECEIVED',
  [AccountInfo],
  AccountInfo,
>('ACCOUNT_INFO_RECEIVED', info => info);

export default {
  response401Received: response401Received,
  accountInfoReceived: accountInfoReceived,
  receivedCookie: receivedCookie,
  signOutButtonClicked: signOutButtonClicked,
  currentUserReceived: currentUserReceived,
  cancelledSession: cancelledSession,
  sessionExpired: sessionExpired,
  sessionValidated: sessionValidated,
  sessionValidationRequested: sessionValidationRequested,
};
