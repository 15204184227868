//@flow
import React, { PureComponent } from 'react';
import { type DispatchProps } from 'redux';
import { connect } from 'react-redux';
import type { ActionType } from 'redux-actions';
import { browserHistory } from '../../clients/history';
import {
  getShowHideClosedFilter,
  setShowHideClosedFilter,
} from '@datatheorem/findings/findingsFilterCreator';
import { Dialog, DialogContent } from '@material-ui/core';
import { PageHeading, SecurityFindingCard } from '@datatheorem/components';
import SDKIssueList from '../findings/SDKIssueList';
import { apps, totalMetadataCount } from '../../selectors/apps';
import { type CategoryMetadataCount } from '@datatheorem/user-api/mobile_apps';
import { createFindingsFromCategorySelector as createShadowscanFindingsFromCategorySelector } from '@datatheorem/findings/shadow-scan/selectors';
import {
  sdkIssueGroupForParam,
  sdkIssueGroups,
} from '../../selectors/sdkFindings';
import replaceInPath from '../util/replaceInPath';
import type { SecurityFinding } from '@datatheorem/findings/types';
import { type Application } from '@datatheorem/user-api/mobile_apps';
import { Paginate } from '@datatheorem/pagination';
import SDKIssueDetail from '../theme/SDKIssueDetail';
import type { SDKIssueGroup } from '../findings/SDKForAppsCard';
import flatten from 'lodash/flatten';
import { SDKEndpointType } from '../../endpoints';
import {
  inquireShadowScanFindings,
  type InquireShadowScanFindingsActionParams,
} from './../../actions/shadowScanFindings';
import type { DashboardInjectedProps } from '../../types/DashboardInjectedProps';
import { compose } from 'recompose';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { type ShadowscanSecurityFinding } from '@datatheorem/user-api/shadow_security_findings';
import type { State as ReduxState } from '../../ReduxStateType';

type OwnProps = DashboardInjectedProps;

type StateProps = {|
  apps: $ReadOnlyArray<Application>,
  totalMetadataCount: CategoryMetadataCount,
  shadowScanFindings: $ReadOnlyArray<ShadowscanSecurityFinding>,
  selectedSdkIssueGroup: ?SDKIssueGroup,
  sdkIssueGroups: $ReadOnlyArray<SDKIssueGroup>,
  filterValue: boolean,
|};

type DP = DispatchProps<ActionType<typeof inquireShadowScanFindings>>;

type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DP,
  ...TrackingProps,
|};

type IntermediateProps = {|
  ...OwnProps,
  ...TrackingProps,
|};

export default compose(
  tracking(dataCreators.component(AnalyticsScreenEnum.ALL_SDKS)),
  connect<Props, IntermediateProps, _, _, _, _>(
    (state: ReduxState, props: IntermediateProps): StateProps => ({
      apps: apps(state),
      totalMetadataCount: totalMetadataCount(state, props),
      shadowScanFindings: createShadowscanFindingsFromCategorySelector(
        browserHistory,
      )(state, props),
      selectedSdkIssueGroup: sdkIssueGroupForParam(state, props),
      sdkIssueGroups: sdkIssueGroups(state, props),
      filterValue: getShowHideClosedFilter(browserHistory),
    }),
  ),
)(
  class SDKIssues extends PureComponent<Props> {
    handleShadowScanInquireClick = (
      params: InquireShadowScanFindingsActionParams,
    ) => {
      this.props.dispatch(inquireShadowScanFindings(params));
    };

    render() {
      const { props, handleShadowScanInquireClick } = this;
      const {
        apps,
        totalMetadataCount,
        shadowScanFindings,
        selectedSdkIssueGroup,
        filterValue,
        sdkIssueGroups,
      } = props;

      return (
        <div>
          <PageHeading title="OSS/SDK Issues" forMenu>
            <Paginate type={SDKEndpointType}>
              {({ loadedInitial, loadMore }) => (
                <SDKIssueList
                  apps={apps}
                  totalMetadataCount={totalMetadataCount}
                  sdkIssueGroups={sdkIssueGroups}
                  onSelectSDK={this.handleSelectSDK}
                  filler={!loadedInitial}
                  loadMoreRows={loadMore}
                  onChangeShowHideClosed={this.handleChangeShowHideClosedFilter}
                  filterValue={filterValue}
                  onClickShadowScanInquire={handleShadowScanInquireClick}
                  shadowScanFindings={shadowScanFindings}
                />
              )}
            </Paginate>
          </PageHeading>

          {selectedSdkIssueGroup && (
            <Dialog open={true} onClose={this.onCloseDialog}>
              <DialogContent style={{ padding: 0 }}>
                <SDKIssueDetail
                  sdk={selectedSdkIssueGroup.sdk}
                  onClose={this.onCloseDialog}
                >
                  {flatten(
                    Object.values(selectedSdkIssueGroup.issuesByTitle),
                  ).map(({ security_finding }: any) => {
                    // https://github.com/facebook/flow/issues/2221
                    (security_finding: SecurityFinding);
                    return (
                      <div
                        key={security_finding.id}
                        onClick={this.onClickSecurityFinding.bind(
                          this,
                          security_finding,
                        )}
                        style={{
                          paddingTop: 5,
                          paddingBottom: 10,
                        }}
                      >
                        <SecurityFindingCard
                          finding={security_finding}
                          apps={apps}
                        />
                      </div>
                    );
                  })}
                </SDKIssueDetail>
              </DialogContent>
            </Dialog>
          )}
        </div>
      );
    }

    handleSelectSDK = ({ sdk }: SDKIssueGroup) => {
      if (!sdk) {
        return;
      }
      this.navigate({ sdk: sdk.title });
    };

    onCloseDialog = () => {
      this.navigate({ sdk: null });
    };

    onClickSecurityFinding = (finding: SecurityFinding) => {
      browserHistory.push(`/issues/all/${finding.id}`);
    };

    navigate = (params: { sdk: string | null }) => {
      browserHistory.push(
        replaceInPath(
          '/sdk-issues/:category/:sdk',
          params,
          this.props.match.params,
        ),
      );
    };

    handleChangeShowHideClosedFilter = (value: boolean) => {
      const { tracking } = this.props;

      if (!tracking) {
        throw new Error(
          'Expected this component to have been decorated with tracking HoC',
        );
      }

      setShowHideClosedFilter(browserHistory, value);
      tracking.trackEvent(dataCreators.findingsViewToggled(value));
    };
  },
);
