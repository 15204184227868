// @flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

import {
  Card as ProtectionBadgeCard,
  type BadgeTypeEnum,
} from '@datatheorem/components/src/app-protection-badges';
import type { AppProtectionTask } from '@datatheorem/user-api/mobile_apps';
import { default as AppProtectionBadgeTasksDialog } from '@datatheorem/acheron/src/components/protection/AppProtectionBadgeTasksDialog';
import { badgeToIssueTypeMap } from '@datatheorem/components';

const LARGE_SIZE = 72;

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },

  card: {
    minWidth: 120,
    width: '18%',
    margin: 2,
  },
};

function getTasksForBadge(badge, tasksForApp, badgeToIssueTypeMapping) {
  const issueTypeIds = [];
  badgeToIssueTypeMapping
    .filter(mapping => mapping.protectionBadge === badge)
    .forEach(issueType => issueTypeIds.push(issueType.taskIssueTypeId));

  return tasksForApp.filter(task => issueTypeIds.includes(task.issue_type_id));
}

function tasksDialog(
  badgeType: BadgeTypeEnum,
  enabled: boolean,
  tasksForBadge: $ReadOnlyArray<AppProtectionTask>,
  onClose: () => mixed,
) {
  return (
    <AppProtectionBadgeTasksDialog
      onClose={onClose}
      badgeType={badgeType}
      enabled={enabled}
      tasksForBadge={tasksForBadge}
    />
  );
}

type OwnProps = {|
  tasksDialog?: Node,
  tasksForApp: $ReadOnlyArray<AppProtectionTask>,
  badges: $ReadOnlyArray<[BadgeTypeEnum, boolean]>,
|};

type Props = {| ...OwnProps, ...InjectedJSS<typeof styles> |};

class ProtectionBadgeList extends PureComponent<Props> {
  render() {
    const { props } = this;

    const { classes, badges, tasksForApp } = props;

    return (
      <div className={classes.container}>
        {badges.map(([badge, enabled], i) => (
          <div key={i} className={classes.card}>
            <ProtectionBadgeCard
              type={badge}
              enabled={enabled}
              width={LARGE_SIZE}
              height={LARGE_SIZE}
              tasksForBadge={
                tasksForApp
                  ? getTasksForBadge(badge, tasksForApp, badgeToIssueTypeMap)
                  : []
              }
              tasksDialog={tasksForApp ? tasksDialog : () => {}}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default jss(styles)(ProtectionBadgeList);
