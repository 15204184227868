//@flow
import { request } from './gapi';
import { byProperty, result } from './util';

export type CreateAppResponse = {
  upload_url?: string,
};

type CreateParams = {};

export async function create(params: CreateParams): Promise<CreateAppResponse> {
  return request({
    path: 'userapi/v2/xcuitest_uploads',
    method: 'POST',
    body: params,
  })
    .then(result)
    .then(byProperty('upload_url'));
}
