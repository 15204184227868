// @flow
import React, { Component, PureComponent, type AbstractComponent } from 'react';
import injectSheet from 'react-jss';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getSavedFilterSel } from '../../selectors/filterSelectors';
import CSVExporter from './CSVExporter';
import { findingsToCSV } from '../../util/filtersUtil';
import { flow, filter, keys, map } from 'lodash/fp';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';

// Components
import { Link } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircularProgress from '@material-ui/core/CircularProgress';
import { palette } from '@datatheorem/theme';

// Types
import type { InjectedJSS, Classes } from 'react-jss';
import type { FindingWithApp } from '../../util/filtersUtil';
import type {
  RemoveFilterFn,
  SavedFilters,
  FilterValue,
} from '../../actions/filterActions';
import type { DispatchProps } from 'redux';

const styles = {
  row: {
    padding: '5px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
  },

  titleCol: {
    display: 'flex',
    flexGrow: '3',
    flexShrink: '3',
    flexBasis: 250,
    marginRight: '5px',
    alignItems: 'center',
  },

  sharedCol: {
    display: 'flex',
    flexGrow: '1',
    flexShrink: '1',
    flexBasis: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },

  countCol: {
    display: 'flex',
    flexGrow: '1',
    flexShrink: '1',
    flexBasis: 15,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  actionCol: {
    display: 'flex',
    marginRight: '5px',
    marginLeft: '5px',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    minWidth: 144,
    alignItems: 'center',
    width: '100px',
    height: '100%',
  },

  actionsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

const loader = (
  <CircularProgress
    left={4}
    top={4}
    size={20}
    style={{
      display: 'inline-block',
      position: 'relative',
    }}
  />
);

type RowStateProps = {| findings: $ReadOnlyArray<FindingWithApp> |};

type RowOwnProps = {|
  name: string,
  value: FilterValue,
  id: string,
  classes: Classes<typeof styles>,
  findingsLoaded: boolean,

  onRemove: ?RemoveFilterFn,
  onLoad: FilterValue => mixed,
  onSharedCheck: ?(id: string, value: boolean) => mixed,
|};

type RowProps = {|
  ...RowOwnProps,
  ...RowStateProps,
  ...DispatchProps<>,
  ...TrackingProps,
|};

class Row extends PureComponent<RowProps> {
  render() {
    const {
      classes,
      id,
      name,
      value,
      findings,
      findingsLoaded,
      tracking,

      onRemove,
      onLoad,
      onSharedCheck,
    } = this.props;

    return (
      <div className={classes.row} key={id}>
        <div className={classes.titleCol}>
          <Typography>{name}</Typography>
        </div>

        <div className={classes.countCol}>
          <Typography> {findingsLoaded ? findings.length : loader}</Typography>
        </div>

        <div className={classes.sharedCol}>
          <Tooltip
            title={
              onSharedCheck
                ? value.isPrivate
                  ? 'This report is private. Only you are able to access it'
                  : 'This report is shared within your organization.'
                : 'This is a preset report that is by default globally available within your organization.'
            }
          >
            <div>
              {onSharedCheck ? (
                <Checkbox
                  style={{ padding: 0 }}
                  color="primary"
                  checked={!value.isPrivate}
                  onChange={() =>
                    onSharedCheck(value.id || '', !value.isPrivate)
                  }
                />
              ) : (
                <Checkbox
                  style={{ padding: 0 }}
                  color="primary"
                  checked={true}
                  disabled
                />
              )}
            </div>
          </Tooltip>
        </div>

        <div className={classes.actionCol}>
          <div className={classes.actionsContainer}>
            {onRemove ? (
              <Tooltip title="Delete this report">
                <IconButton onClick={() => onRemove(id)}>
                  <DeleteIcon
                    style={{
                      color: palette.gray30,
                    }}
                  />
                </IconButton>
              </Tooltip>
            ) : null}

            <CSVExporter>
              {handleCSVExport => (
                <Tooltip title="Export to CSV">
                  <IconButton
                    tooltip="Export to CSV"
                    onClick={() => {
                      if (!tracking) {
                        throw new Error(
                          'Expected this component to have been decorated with tracking HoC',
                        );
                      }

                      tracking.trackEvent(
                        dataCreators.appSecureSavedReportExported(name),
                      );

                      handleCSVExport(findingsToCSV(findings), name);
                    }}
                  >
                    <DownloadIcon style={{ color: palette.gray30 }} />
                  </IconButton>
                </Tooltip>
              )}
            </CSVExporter>

            <Link to="/search">
              <Tooltip title="Load">
                <IconButton onClick={() => onLoad(value)}>
                  <ArrowForwardIcon style={{ color: palette.gray30 }} />
                </IconButton>
              </Tooltip>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const withStore = (store, props) => ({
  findings: getSavedFilterSel()(store, props),
});

export const FilterRow: AbstractComponent<RowOwnProps> = compose(
  connect<
    {| ...RowOwnProps, ...RowStateProps, ...DispatchProps<> |},
    RowOwnProps,
    _,
    _,
    _,
    _,
  >(withStore),
  tracking({}),
)(Row);

type SavedFilterTableProps = {
  canEditPublicFilters: boolean,
  savedFilters: SavedFilters,
  findingsLoaded: boolean,
  onRemove: RemoveFilterFn,
  onLoad: FilterValue => mixed,
  onSharedCheck: (id: string, value: boolean) => mixed,
} & InjectedJSS<typeof styles>;

class SavedFilterTable extends Component<SavedFilterTableProps> {
  render() {
    const {
      classes,
      savedFilters,
      findingsLoaded,
      canEditPublicFilters,

      onRemove,
      onLoad,
      onSharedCheck,
    } = this.props;

    const filterRow = ({ key, value, canModify }) => {
      return (
        <FilterRow
          key={key}
          id={key}
          classes={classes}
          findingsLoaded={findingsLoaded}
          onRemove={canModify ? onRemove : null}
          onLoad={onLoad}
          onSharedCheck={canModify ? onSharedCheck : null}
          {...value}
        />
      );
    };

    const filters = flow(
      keys,
      map(k => ({ key: k, value: savedFilters[k] })),
      map(({ key, value }) => ({
        key,
        value,
        /* Shared - Not modifyable

        TODO: Push DataTheorem filters to backend instead of `isPrivate && !isOwner` condition. */
        isBuiltin: value.value.isPrivate && !value.value.isOwner,
      })),
      map(({ key, value, isBuiltin }) => ({
        key,
        value,
        isBuiltin,
        canModify: !isBuiltin && (value.value.isOwner || canEditPublicFilters),
      })),
    )(savedFilters);

    return (
      <div>
        {/* Header  */}
        <div className={classes.row}>
          <div className={classes.titleCol}>
            <FormLabel>Name</FormLabel>
          </div>
          <div className={classes.countCol}>
            <FormLabel>Issues Count</FormLabel>
          </div>
          <div className={classes.sharedCol}>
            <FormLabel>Shared</FormLabel>
          </div>
          <div className={classes.actionCol} />
        </div>

        {/* Public - Non-Modifyable  */}
        {flow(
          filter(
            ({ value, isBuiltin, canModify }) =>
              isBuiltin || (!value.value.isOwner && !canModify),
          ),
          map(filterRow),
        )(filters)}

        {/* Shared - Modifyable */}
        {flow(
          filter(({ canModify }) => canModify),
          map(filterRow),
        )(filters)}
      </div>
    );
  }
}

export default injectSheet(styles)(SavedFilterTable);
