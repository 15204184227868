//@flow
import React, { PureComponent, type Node } from 'react';
import lowerCase from 'lodash/fp/lowerCase';
import jss, { type InjectedJSS } from 'react-jss';
import numeral from 'numeral';

import LeaderboardApp from '../theme/LeaderboardApp';
import type { AppProtectionTaskCategories } from '../../reducers/appProtectionTaskCategories';
import AppProtectionTasksList from './AppProtectionTasksList';

import { type Application } from '@datatheorem/user-api/mobile_apps';
import { type TopApp } from '@datatheorem/user-api/app_protection_top_ten_apps';
import type { AppProtectionTask } from '@datatheorem/user-api/mobile_apps';
import ReleaseTypeEnum from '@datatheorem/enums/MobileAppReleaseTypeEnum';
import TemplateScanTypeEnum from '@datatheorem/enums/TemplateScanTypeEnum';
import { default as ProtectionBadgeList } from '@datatheorem/atlas/src/components/ProtectionBadgeList';
import type { BadgeTypeEnum } from '@datatheorem/components/src/app-protection-badges';

const WrapBadge = ({ children }: { children: Node }) => (
  <div style={{ margin: 20 }}>{children}</div>
);

const styles = {
  upNextContainer: {
    marginTop: 30,
    marginBottom: 30,
    marginLeft: 100,
    marginRight: 100,
  },
  subheading: {
    textAlign: 'center',
    marginTop: 50,
    marginBottom: 20,
  },
  leaderboardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  ratingDistanceContainer: {
    marginTop: 50,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  badges: {
    marginTop: 50,
    paddingBottom: 5,
  },
};

type Props = {
  app: Application,
  taskCategories: AppProtectionTaskCategories,
  topApps: $ReadOnlyArray<TopApp>,
  topAppsByCategory: ?$ReadOnlyArray<TopApp>,
  loadingTargetStatusIds: ?$ReadOnlyArray<string>,
  onClickTaskDetails: (task: AppProtectionTask) => mixed,
  onClickInquire: ({ app: Application }) => mixed,
  onClickHideTask: AppProtectionTask => mixed,
  applicationBadges: $ReadOnlyArray<[BadgeTypeEnum, boolean]>,
  tasksForApp: $ReadOnlyArray<AppProtectionTask>,
} & InjectedJSS<typeof styles>;

function getMetadataFromCategory(
  topApps: $ReadOnlyArray<TopApp>,
  app: Application,
): {|
  globalTopAppsOrTopAppsByCategory: $ReadOnlyArray<TopApp>,
  thisAppOnLeaderboard: TopApp | void,
  appWithCategory: TopApp | void,
|} {
  const thisAppOnLeaderboard = topApps.find(topApp => topApp.name === app.name);

  return {
    globalTopAppsOrTopAppsByCategory: topApps,
    thisAppOnLeaderboard,
    appWithCategory: topApps.find(topApp => topApp.category),
  };
}

export default jss(styles)(
  class AppProtectionDetails extends PureComponent<Props> {
    render() {
      const { props } = this;

      const {
        app,
        classes,
        topApps,
        topAppsByCategory,
        onClickTaskDetails,
        onClickInquire,
        onClickHideTask,
        loadingTargetStatusIds,
        taskCategories,
        applicationBadges,
        tasksForApp,
      } = props;

      const {
        name,
        app_protection_score_ratio,
        subscription,
        app_protection_score,
        max_app_protection_score,
      } = app;

      if (!app_protection_score_ratio && app_protection_score_ratio !== 0) {
        return <div style={{ margin: '40 auto' }}>No data</div>;
      }

      const {
        globalTopAppsOrTopAppsByCategory,
        thisAppOnLeaderboard,
        appWithCategory,
      } = getMetadataFromCategory(topAppsByCategory || topApps, app);

      const percentRatioPerPoint = max_app_protection_score
        ? 1 / parseInt(max_app_protection_score, 10)
        : null;
      const percentToTopTen =
        app_protection_score && typeof percentRatioPerPoint === 'number'
          ? numeral(
              globalTopAppsOrTopAppsByCategory[
                globalTopAppsOrTopAppsByCategory.length - 1
              ].app_protection_score,
            )
              .subtract(app_protection_score)
              .multiply(percentRatioPerPoint)
              .value()
          : null;

      // NOTE: (rr) PRE_PROD apps don't have a corresponding OpenScan app. This
      // means that they will never appear in the top 10 response and therefore
      // the above algorithm is buggy as it results in negative values for
      // PRE_PROD apps with sufficient points to be included in the top 10.
      // This is a quick fix while App Protection is being reworked.
      const skipPercentToTopSection =
        app.release_type === ReleaseTypeEnum.PRE_PROD ||
        !percentToTopTen ||
        percentToTopTen < 0 ||
        percentToTopTen + app_protection_score_ratio >= 1 ||
        thisAppOnLeaderboard;

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0 100px',
          }}
        >
          <AppProtectionTasksList
            taskCategories={taskCategories}
            onClickTaskDetails={onClickTaskDetails}
            onClickHideTask={onClickHideTask}
            onClickInquire={() => onClickInquire({ app })}
            showLoadingIndicator={Boolean(
              loadingTargetStatusIds && loadingTargetStatusIds.length,
            )}
            showSubscriptionWarning={
              subscription === TemplateScanTypeEnum.NO_SUBSCRIPTION
            }
          />
          <div>
            <div className={classes.badges}>
              <ProtectionBadgeList
                tasksForApp={tasksForApp}
                badges={applicationBadges}
              />
            </div>
          </div>
          <div>
            {percentToTopTen !== null &&
              !skipPercentToTopSection && (
                <div
                  className={classes.subheading + ' distanceRatingContainer'}
                >
                  <strong>{numeral(percentToTopTen).format('0%')}</strong>{' '}
                  protections needed to reach the leaderboard
                </div>
              )}
            <div className={classes.subheading}>
              How does <strong>{name}</strong> compare with the most protected
              apps{' '}
              {topAppsByCategory &&
              appWithCategory &&
              appWithCategory.category ? (
                <span>
                  in the <strong>{lowerCase(appWithCategory.category)}</strong>{' '}
                  category
                </span>
              ) : (
                <span>out there</span>
              )}?
            </div>
            <div className={classes.leaderboardContainer}>
              {globalTopAppsOrTopAppsByCategory.map((topApp, i) => (
                <WrapBadge key={topApp.id}>
                  <LeaderboardApp
                    appName={topApp.name}
                    publisherName={
                      topApp === thisAppOnLeaderboard
                        ? 'You'
                        : topApp.publisher_name
                    }
                    rank={i + 1}
                    highlight={topApp === thisAppOnLeaderboard}
                  />
                </WrapBadge>
              ))}
            </div>
          </div>
        </div>
      );
    }
  },
);
