//@flow
export default (
  metadata_file_url: string,
  entity_id: string,
  assertion_consumer_service_url: string,
) => `
Data Theorem supports SAML 2.0 to provide Single Sign On authentication for your organization. If you would like to use SAML for authenticating users to Data Theorem, please follow the steps below.

1. [Download the SAML metadata file](${metadata_file_url}) for Data Theorem and provide it to your SSO administrator to configure your SAML Identity Provider
2. If your SAML Identity Provider does not support uploading SAML metadata files, then you can use the following configuration values:
    1. **EntityID**: \`${entity_id}\`
    2. **Assertion Consumer Service URL**: \`${assertion_consumer_service_url}\`
    3. We require the use of RSA-SHA256 for signing SAML message
    4. We require users' subject/nameID format to be their email address
    5. We do not currently require any other attributes to be included the response (eg, the user's name)
    6. We support the Web Browser SSO Profile.
    7. We support the HTTP POST Binding for incoming authentication assertions.
3. After you have configured your SAML Identity Provider, please email support@datatheorem.com with the following information:
    1. The SAML metadata file for Data Theorem within your SSO provider. This file should describe how Data Theorem can redirect your users to to your SSO provider, as well as the signing certificate for your SAML Identity Provider.
    2. The name of the SSO product that you use (eg, Okta, Ping, Azure Active Directory, etc.)
    3. Contact information for a support contact within your organization for SAML/SSO deployment
4. We will then work with you to ensure that SAML is working for a subset of your users before it becomes required for all of your users.

Notes about our SAML implementation:

* Users are not auto-provisioned within Data Theorem — a user must first be created within User Management and configured to use SAML before they can login with SAML.
* Different users can use different login methods. Once SAML is configured for your account, new users will default to using SAML, but you can configure your individual users to use our other authentication mechanisms, such as a Google account or OTP.
`;
