// @flow
import { Raven } from '@datatheorem/global';
import { Actions as NotificationsActions } from '@datatheorem/notifications';
import { withProgressIndicator } from '@datatheorem/progress-indicator';
import { callPromise } from '@datatheorem/session';
import { list, patch } from '@datatheorem/user-api/search_queries';
import { call, put, take, takeEvery } from 'redux-saga/effects';
import { ActionEnum, filtersLoadedFromServer } from '../actions/filterActions';
import { callSaga } from '@datatheorem/redux-saga-wrapped-effects';
import { update } from '../reducers/searchQueries';
import paginate, { paginateToEnd } from './util/paginate';
import type { Saga } from 'redux-saga';

import type {
  ListParams,
  SearchQueries,
} from '@datatheorem/user-api/search_queries';

const TYPE = 'search_queries';

const onError = function*(err, title, description) {
  const errorMessage =
    title && description ? `${title} - ${description}` : err && err.message;
  Raven.captureException(err, {
    extra: {
      msg: errorMessage,
    },
  });
  yield put(
    NotificationsActions.requestNotifyUser({
      text: errorMessage,
    }),
  );
  console.log(errorMessage); // eslint-disable-line no-console
  console.error(err);
};

const getSearchQueries = function*(params?: ListParams): Saga<*> {
  return yield* callPromise(list, params || {});
};

export function* requestSearchQueries(params: ListParams): Saga<SearchQueries> {
  return yield* withProgressIndicator(function*() {
    try {
      const response = yield* callSaga(
        paginate,
        TYPE,
        params,
        getSearchQueries,
      );

      if (
        response &&
        response.search_queries &&
        response.search_queries.length
      ) {
        yield put(update(response.search_queries));
        return response.search_queries;
      }

      return [];
    } catch (err) {
      yield call(
        onError,
        err,
        'loadSearchQueries',
        'There was an error loading reports.',
      );
      throw err;
    }
  }, TYPE);
}

export function* loadAllSearchQueries(): Saga<void> {
  // Wait for Advanced Search Query backup.
  // TODO@nw: Remove when user backups are completely backed up.
  //          Target Date: 11/01/2018
  yield take(ActionEnum.FINISHED_FILTERS_BACKUP);

  const requestedSearchQueries = yield* callSaga(
    paginateToEnd,
    requestSearchQueries,
    TYPE,
    {},
    {},
  );
  if (!requestedSearchQueries) {
    // Unable to paginate no results - No search queries are setup server side.
    return;
  }

  const toDateRange = date_range =>
    date_range.type === 'CUSTOM'
      ? {
          type: date_range.type,
          from: date_range.from_date
            ? new Date(date_range.from_date)
            : new Date(),
          to: date_range.to_date ? new Date(date_range.to_date) : new Date(),
        }
      : {
          type: date_range.type,
        };

  yield put(
    filtersLoadedFromServer(
      requestedSearchQueries
        .filter(Boolean)
        .reduce((allQueries, searchQuery) => {
          allQueries[searchQuery.id] = {
            name: searchQuery.description,
            value: {
              id: searchQuery.id,
              isOwner: searchQuery.is_owner,
              isPrivate: searchQuery.is_private,
              name: searchQuery.title,
              description: searchQuery.description,
              selectedPriorities: searchQuery.filter_query.priorities || [],
              selectedSeverities: searchQuery.filter_query.severities || [],
              selectedCompliancePolicies:
                searchQuery.filter_query.compliance_policies || [],
              selectedStatuses: searchQuery.filter_query.statuses.date_range
                .type
                ? {
                    dateRange: toDateRange(
                      searchQuery.filter_query.statuses.date_range,
                    ),
                    statuses: searchQuery.filter_query.statuses.statuses || [],
                  }
                : null,
              selectedCurrentStatuses:
                searchQuery.filter_query.current_statuses || [],
              selectedReleaseTypes:
                searchQuery.filter_query.release_types || [],
              minAge: searchQuery.filter_query.min_age || '',
              sortBy: searchQuery.filter_query.sort_by || '',
            },
          };

          return allQueries;
        }, {}),
    ),
  );
}

function* setGlobalFilter({ payload: { id, value } }): Saga<void> {
  try {
    const response = yield* callPromise(patch, id, {
      is_private: value,
    });

    if (!response) {
      return;
    }

    yield put(update([response]));
  } catch (err) {
    yield call(
      onError,
      err,
      'setGlobalFilter',
      'There was an error making a report public.',
    );
  }
}

export const watcher = function*(): Saga<void> {
  try {
    yield takeEvery(
      ActionEnum.REQUEST_FILTERS_FROM_SERVER,
      loadAllSearchQueries,
    );

    yield takeEvery(ActionEnum.SET_GLOBAL_FILTER, setGlobalFilter);
  } catch (err) {
    yield call(onError, err);
  }
};
