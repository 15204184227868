//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import { fontSizes } from '@datatheorem/theme';

const styles = {
  DialogTitle: {
    fontSize: fontSizes.huge,
    textAlign: 'center',
    paddingTop: 50,
    marginBottom: 70,
  },
};

type Props = {
  children: Node,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class DialogTitle extends PureComponent<Props> {
    render() {
      const { classes, children } = this.props;

      return <h2 className={classes.DialogTitle}>{children}</h2>;
    }
  },
);
