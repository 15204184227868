// @flow
export { getSessionId, clearSessionId } from '@datatheorem/cache';
import { type User } from '@datatheorem/user-api/users';
import UserRoleEnum from '@datatheorem/enums/UserRoleEnum';
import AuthStrategyEnum from '@datatheorem/enums/AuthStrategyEnum';

import { flow, startCase, lowerCase, mapValues, omitBy } from 'lodash/fp';
import { array, mixed, object, string, type YupObject } from 'yup';

export { saga } from './saga';
export { default as Actions } from './actions';
export { default as Reducers } from './reducers';
export { default as Selectors } from './selectors';
export { default as callPromise } from './saga/callPromise';
export { default as handleAuthError } from './saga/handleAuthError';

export type CurrentSessionState =
  | 'VALIDATING'
  | 'LOGGED_IN'
  | 'SESSION_EXPIRED'
  | 'CANCELING'
  | null;

const ALL_APPS_SUFFIX = '_ALL_APPS';

export function getName(first_name?: ?string, last_name?: ?string): string {
  if (!first_name) {
    return last_name || '';
  } else if (!last_name) {
    return first_name;
  }

  return `${last_name}, ${first_name}`;
}

export function hasAllApps(role: ?string): boolean {
  if (!role) {
    throw new Error('Invalid role object');
  }

  return role.indexOf(ALL_APPS_SUFFIX) !== -1;
}

export function hasActivityAccess(user: User): boolean {
  return user && user.role === UserRoleEnum.MANAGER;
}

export function hasUserAccess(user: User): boolean {
  return user && user.role === UserRoleEnum.MANAGER;
}

export const RoleSimplestHumanReadable = flow(
  omitBy(v => v.indexOf(ALL_APPS_SUFFIX) !== -1),
  mapValues<string, string, _>(lowerCase),
  mapValues<string, string, _>(startCase),
)(UserRoleEnum);

export function getRoleEnumInferredFromSimplest(
  role: string,
  hasAllApps: boolean,
): string {
  const uppercaseRole = role.toUpperCase();

  if (hasAllApps && ['DEVELOPER', 'SECURITY'].includes(uppercaseRole)) {
    return `${uppercaseRole}${ALL_APPS_SUFFIX}`;
  } else if (!hasAllApps && uppercaseRole.includes(ALL_APPS_SUFFIX)) {
    return uppercaseRole.replace(ALL_APPS_SUFFIX, '');
  }

  return uppercaseRole;
}

export function getSimplestRole(role: string) {
  if (typeof role !== 'string') {
    return role;
  }

  return role.replace(ALL_APPS_SUFFIX, '');
}

export const UserSchema: YupObject<User> = object().shape({
  first_name: string()
    .label('First Name')
    .required(),
  last_name: string()
    .label('Last Name')
    .required(),
  login_email: string()
    .label('Email')
    .email()
    .required(),
  notification_email: string()
    .label('Notification Email')
    .email()
    .nullable(true)
    .test({
      name: 'defined-and-not-empty',
      test: v =>
        v === null ||
        typeof v === 'undefined' ||
        !!(v && typeof v === 'string' && v.length),
      message: '${path} cannot be empty',
    }),
  role: mixed()
    .label('Role')
    .oneOf(Object.values(UserRoleEnum))
    .required(),
  auth_strategy: mixed()
    .label('Autentication')
    .oneOf(Object.values(AuthStrategyEnum))
    .required(),
  allowed_app_ids: array().of(string().matches(/^\d+$/)),
});

export const metaFieldNameForAllApps = '_hasAllApps';
