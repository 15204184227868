// @flow
import { default as BadgeType, type BadgeTypeEnum } from './BadgeTypeEnum';
import appProtectionBadgeImages from './images';
import type {
  Application,
  AppProtectionTask,
} from '@datatheorem/user-api/mobile_apps';
import { Raven } from '@datatheorem/global';

export function getCompletedBadgesForApp(
  flags: $ReadOnlyArray<string>,
  app?: Application,
) {
  return flags.reduce(
    (acc, badge_key) =>
      app && app[badge_key] ? { ...acc, [badge_key]: app[badge_key] } : acc,
    {},
  );
}

export function getIncompleteBadgesForApp(
  flags: $ReadOnlyArray<string>,
  app?: Application,
) {
  return flags.reduce(
    (acc, badge_key) =>
      app && app[badge_key] === false
        ? { ...acc, [badge_key]: !app[badge_key] }
        : acc,
    {},
  );
}

export function getAppProtectionBadgeKeyByFindingTitle(
  title: string,
  badgesMap: $ReadOnlyArray<{ [string]: string }>,
): BadgeTypeEnum | null {
  const item = badgesMap.find(badgeMap => badgeMap.securityTaskTitle === title);

  // $FlowFixMe
  return item ? item.protectionBadgeKey : null;
}

export function getCompletedAppProtectionBadgeKeyByBadgeTitle(
  title: string,
  app?: Application,
): BadgeTypeEnum | null {
  const appProtectionBadgeKeys = securityTaskToProtectionBadgeMap.map(
    badgeMap => badgeMap.protectionBadgeKey,
  );
  const completedBadges = getCompletedBadgesForApp(appProtectionBadgeKeys, app);

  const completedBadgesMapping = securityTaskToProtectionBadgeMap.filter(
    // $FlowFixMe
    badgeMap => completedBadges[badgeMap.protectionBadgeKey],
  );

  return getAppProtectionBadgeKeyByFindingTitle(title, completedBadgesMapping);
}

export function getIncompleteAppProtectionBadgeKeyByBadgeTitle(
  title: string,
  app?: Application,
): BadgeTypeEnum | null {
  const appProtectionBadgeKeys = securityTaskToProtectionBadgeMap.map(
    badgeMap => badgeMap.protectionBadgeKey,
  );
  const incompleteBadges = getIncompleteBadgesForApp(
    appProtectionBadgeKeys,
    app,
  );

  const incompleteBadgesMapping = securityTaskToProtectionBadgeMap.filter(
    // $FlowFixMe
    badgeMap => incompleteBadges[badgeMap.protectionBadgeKey],
  );

  return getAppProtectionBadgeKeyByFindingTitle(title, incompleteBadgesMapping);
}

export function hasApplicableTasksForBadge(
  tasksForBadge: $ReadOnlyArray<AppProtectionTask>,
): boolean {
  return (
    typeof tasksForBadge.find(
      task => typeof task.security_finding_id !== 'undefined',
    ) !== 'undefined'
  );
}

export const securityTaskToProtectionBadgeMap = [
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable Data Protection Entitlement',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning – NSURLSession',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning – AFNetworking',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning – UIWebView',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning – WKWebView',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning – SSLSocket',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning - HttpsUrlConnection',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning - OkHttp',
  },
  {
    protectionBadgeKey: 'full_protection_on_untrusted_networks',
    securityTaskTitle: 'Enable SSL Pinning - Ion',
  },
  {
    protectionBadgeKey: 'removes_data_from_shared_device_locations',
    securityTaskTitle: 'Set Expiration on iOS Pasteboard Data',
  },
  {
    protectionBadgeKey: 'protects_the_keyboard',
    securityTaskTitle: 'Disable Third-Party Keyboards',
  },
  {
    protectionBadgeKey: 'all_communication_encrypted',
    securityTaskTitle: 'App Store Blocker: Dangerous ATS Exemptions',
  },
  {
    protectionBadgeKey: 'all_communication_encrypted',
    securityTaskTitle: 'Enable Android NSC to Prevent Cleartext Traffic',
  },
  {
    protectionBadgeKey: 'supports_two_factor_authentication',
    securityTaskTitle: 'Enable Two-Factor Authentication Input Field',
  },
  {
    protectionBadgeKey: 'leverages_secure_system_sdk',
    securityTaskTitle: 'App Store Blocker: App Built with Deprecated iOS SDK',
  },
  {
    protectionBadgeKey: 'leverages_secure_system_sdk',
    securityTaskTitle: 'Enable SafeBrowsing on WebViews',
  },
  {
    protectionBadgeKey: 'leverages_secure_browser_settings',
    securityTaskTitle: 'Switch from Deprecated UIWebView API to WKWebView',
  },
  {
    protectionBadgeKey: 'leverages_secure_browser_settings',
    securityTaskTitle: 'Enable SafeBrowsing on WebViews',
  },
  {
    protectionBadgeKey: 'leverages_secure_system_libraries',
    securityTaskTitle: 'Switch from Unsafe Serialization API to NSSecureCoding',
  },
  {
    protectionBadgeKey: 'leverages_secure_system_settings',
    securityTaskTitle: 'Enable Verification on Android Security Provider',
  },
  {
    protectionBadgeKey: 'enhances_password_security',
    securityTaskTitle: 'Enable System-Managed Login Input Fields',
  },
  {
    protectionBadgeKey: 'does_not_offload_data_to_third_parties',
    securityTaskTitle: 'App Private Data Exposed Through Backups',
  },
  {
    protectionBadgeKey: 'protects_data_on_screens',
    securityTaskTitle: 'Protect App Screens from 3rd Party Apps',
  },
  {
    protectionBadgeKey: 'requires_android_malware_protection',
    securityTaskTitle: 'Enable Android Verify Apps',
  },
  {
    protectionBadgeKey: 'requires_android_device_security',
    securityTaskTitle: 'Enable SafetyNet Attestation API',
  },
  {
    protectionBadgeKey: 'server_enforces_https',
    securityTaskTitle: 'Server Lacks OCSP Stapling',
  },
  {
    protectionBadgeKey: 'server_enforces_https',
    securityTaskTitle: 'Server Lacks HTTP Strict Transport Security Header',
  },
];

export const getBadgeTypeFromSecurityTaskTitle = (
  taskTitle: string,
): BadgeTypeEnum | null => {
  const badgeType = securityTaskToProtectionBadgeMap.find(badgeMap => {
    return badgeMap.securityTaskTitle === taskTitle;
  });
  return badgeType ? BadgeType[badgeType.protectionBadgeKey] : null;
};

export const getBadgeDescriptionFromBadgeType = (
  type: BadgeTypeEnum | null,
): string => {
  switch (type) {
    case BadgeType.all_communication_encrypted:
      return '100% Encrypted Communication';

    case BadgeType.supports_two_factor_authentication:
      return 'Supports IOS built-in 2-Factor Authentication';

    case BadgeType.full_protection_on_untrusted_networks:
      return 'Full Protection on Hostile/Untrusted Networks';

    case BadgeType.protects_data_on_screens:
      return 'Protects Data on Screens';

    case BadgeType.requires_android_device_security:
      return 'Requires Android Device Security';

    case BadgeType.requires_android_malware_protection:
      return 'Requires Android Malware Protection';

    case BadgeType.server_enforces_https:
      return 'Enforces Secure Domains';

    case BadgeType.removes_data_from_shared_device_locations:
      return 'Removes Data from Shared Device Locations';

    case BadgeType.protects_the_keyboard:
      return 'Protects The Keyboard';

    case BadgeType.leverages_secure_system_sdk:
      return 'Leverages updated/most secure system software';

    case BadgeType.leverages_secure_browser_settings:
      return 'Leverages updated/most secure Web Browser settings';

    case BadgeType.leverages_secure_system_libraries:
      return 'Leverages updated/most secure system libraries';

    case BadgeType.enhances_password_security:
      return 'Enhances the security of the Username/Password';

    case BadgeType.does_not_offload_data_to_third_parties:
      return 'Does not offload data to 3rd party locations';

    case BadgeType.leverages_secure_system_settings:
      return 'Leverages updated/most secure system settings';

    default:
      Raven.captureException(new Error('Badge not currently handled'), {
        extra: {
          badge_type_tried: type,
        },
      });
      return '';
  }
};

export const getBadgeImageFromBadgeType = (type: BadgeTypeEnum | null) => {
  switch (type) {
    case BadgeType.all_communication_encrypted:
      return appProtectionBadgeImages.all_communication_encrypted;

    case BadgeType.supports_two_factor_authentication:
      return appProtectionBadgeImages.supports_two_factor_authentication;

    case BadgeType.full_protection_on_untrusted_networks:
      return appProtectionBadgeImages.full_protection_on_untrusted_networks;

    case BadgeType.protects_data_on_screens:
      return appProtectionBadgeImages.protects_data_on_screens;

    case BadgeType.requires_android_device_security:
      return appProtectionBadgeImages.requires_android_device_security;

    case BadgeType.requires_android_malware_protection:
      return appProtectionBadgeImages.requires_android_malware_protection;

    case BadgeType.server_enforces_https:
      return appProtectionBadgeImages.server_enforces_https;

    case BadgeType.removes_data_from_shared_device_locations:
      return appProtectionBadgeImages.removes_data_from_shared_device_locations;

    case BadgeType.protects_the_keyboard:
      return appProtectionBadgeImages.protects_the_keyboard;

    case BadgeType.leverages_secure_system_sdk:
      return appProtectionBadgeImages.leverages_secure_system_sdk;

    case BadgeType.leverages_secure_browser_settings:
      return appProtectionBadgeImages.leverages_secure_browser_settings;

    case BadgeType.leverages_secure_system_libraries:
      return appProtectionBadgeImages.leverages_secure_system_libraries;

    case BadgeType.enhances_password_security:
      return appProtectionBadgeImages.enhances_password_security;

    case BadgeType.does_not_offload_data_to_third_parties:
      return appProtectionBadgeImages.does_not_offload_data_to_third_parties;

    case BadgeType.leverages_secure_system_settings:
      return appProtectionBadgeImages.leverages_secure_system_settings;

    default:
      return appProtectionBadgeImages.fallback;
  }
};

export const badgeToIssueTypeMap = [
  {
    taskIssueTypeId: 'ff1dedb9-8f4b-55be-a946-42944f1941c5',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: '303492e3-bd3c-5007-b39b-2a041cd14cbe',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: 'f52f2afe-e7f1-51c5-8afe-30c9e372bb4c',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: '87508aa4-0afc-513d-86fa-5e975b581511',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: '52704271-43fe-5bb0-b70b-9c0ff852cd7d',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: '13c2faae-1714-5d0e-b39e-a9f5c9b857c4',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: '9acecc0f-c1b2-5ea2-b697-82e3b75c686c',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: '0231f9a7-916a-5928-9f9a-f7f3cecfeb2d',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: 'b3847058-3a13-5978-8250-e81abf6cbc5f',
    protectionBadge: BadgeType.full_protection_on_untrusted_networks,
  },
  {
    taskIssueTypeId: '51b5bf86-1958-542d-a825-acc36a332e12',
    protectionBadge: BadgeType.removes_data_from_shared_device_locations,
  },
  {
    taskIssueTypeId: 'b22cdce3-89af-5b8e-b091-70831c92a1f5',
    protectionBadge: BadgeType.protects_the_keyboard,
  },
  {
    taskIssueTypeId: '518bdd4c-0248-5854-beaf-beafac805179',
    protectionBadge: BadgeType.all_communication_encrypted,
  },
  {
    taskIssueTypeId: 'bd60dd06-f493-58e7-95af-588dd35c4cae',
    protectionBadge: BadgeType.all_communication_encrypted,
  },
  {
    taskIssueTypeId: 'dfebf7a4-96a7-5173-af52-7387a14e9c09',
    protectionBadge: BadgeType.supports_two_factor_authentication,
  },
  {
    taskIssueTypeId: 'd2aaee79-73fe-5bf6-9ef2-a5ac9a6f294f',
    protectionBadge: BadgeType.leverages_secure_system_sdk,
  },
  {
    taskIssueTypeId: 'c16c425f-4df4-5a5d-b442-a231eb1af745',
    protectionBadge: BadgeType.leverages_secure_system_sdk,
  },
  {
    taskIssueTypeId: '5023703e-a80c-53c2-97d2-50a9ab11b890',
    protectionBadge: BadgeType.leverages_secure_browser_settings,
  },
  {
    taskIssueTypeId: '3f01a452-fc36-5685-ba49-ffeae167884d',
    protectionBadge: BadgeType.leverages_secure_browser_settings,
  },
  {
    taskIssueTypeId: '16ba3a4d-9a70-579b-904f-3963687a917c',
    protectionBadge: BadgeType.leverages_secure_system_libraries,
  },
  {
    taskIssueTypeId: '60ae412c-2375-5a2c-8a8d-bf2e8e59f502',
    protectionBadge: BadgeType.leverages_secure_system_libraries,
  },
  {
    taskIssueTypeId: '5ccb3f15-10dc-58a6-bb95-a79fb3bd0508',
    protectionBadge: BadgeType.enhances_password_security,
  },
  {
    taskIssueTypeId: '359b17e9-db71-51db-92cb-9b258117cc62',
    protectionBadge: BadgeType.does_not_offload_data_to_third_parties,
  },
  {
    taskIssueTypeId: 'b151ba5d-e6bc-51d3-b409-38ba42c584d2',
    protectionBadge: BadgeType.protects_data_on_screens,
  },
  {
    taskIssueTypeId: 'c7ea3f43-5109-5e33-b962-de7075879da5',
    protectionBadge: BadgeType.requires_android_malware_protection,
  },
  {
    taskIssueTypeId: 'c9dea6ad-18b7-5574-aa02-687721730964',
    protectionBadge: BadgeType.requires_android_device_security,
  },
  {
    taskIssueTypeId: '7114c293-93e2-5f24-842f-4752429322c3',
    protectionBadge: BadgeType.server_enforces_https,
  },
  {
    taskIssueTypeId: 'a0939c09-951c-5058-b3c5-58ecaecb55ea',
    protectionBadge: BadgeType.server_enforces_https,
  },
];

export function taskStatusToIndicatorMap(taskStatus: string): string {
  switch (taskStatus) {
    case 'COMPLETED':
      return 'good';
    case 'NOT_COMPLETED':
      return 'bad';
    case 'BEYOND_SUBSCRIPTION':
      return 'orange';
    case 'NOT_COMPLETED_HIDDEN':
      return 'neutral';
    case 'NOT_COMPLETED_VISIBLE':
      return 'bad';
    case 'UNKNOWN':
      return 'neutral';
    default:
      return 'neutral';
  }
}
