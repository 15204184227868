//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PageHeading } from '@datatheorem/components';
import Users from '../users/Users';
import { hasUserAccess } from '@datatheorem/session';
import InviteIcon from '@material-ui/icons/PersonAdd';
import { inviteUserClicked } from '../../actions';
import type { User } from '@datatheorem/user-api/users';
import { compose } from 'recompose';
import tracking from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { type DispatchProps } from 'redux';
import { browserHistory } from '../../clients/history';
import type { ContextRouter } from 'react-router-dom';

type StateProps = {|
  users: $ReadOnlyArray<User>,
  canInvite: boolean,
|};

type Props = {| ...StateProps, ...DispatchProps<>, ...ContextRouter |};

export default compose(
  connect<Props, ContextRouter, _, _, _, _>((state): StateProps => ({
    users: state.users,
    canInvite: hasUserAccess(state.currentUser),
  })),
  tracking(dataCreators.component(AnalyticsScreenEnum.USERS)),
)(
  class UserDashboard extends PureComponent<Props> {
    render() {
      const { users, canInvite } = this.props;
      const primaryAction = canInvite && {
        label: 'Invite User',
        icon: <InviteIcon />,
        style: { color: 'primary' },
        onClick: this.handleClickInvite,
      };

      return (
        <PageHeading
          backButton
          onClickBack={browserHistory.goBack}
          title="Users"
          primaryAction={primaryAction}
        >
          <Users users={users} />
        </PageHeading>
      );
    }

    handleClickInvite = () => {
      this.props.dispatch(inviteUserClicked());
    };
  },
);
