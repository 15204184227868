//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import { fontFamily, fontSizes, palette } from '@datatheorem/theme';
import classnames from 'classnames';

const styles: Object = {
  PriorityBadge: {
    height: fontSizes.small * 2,
    width: fontSizes.small * 2,
    fontSize: fontSizes.small,
    fontFamily: fontFamily,
    color: '#ccc',
    fontWeight: 'normal',
    borderRadius: 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ccc',
    textAlign: 'center',
    lineHeight: '24px',
    display: 'inline-block',
  },
  p1: {
    borderColor: palette.darkestBg,
    color: palette.darkestBg,
  },
  invertedColors: {
    color: palette.inacitveColor,
    borderColor: palette.inacitveColor,
    verticalAlign: 'super',
  },
  nonPriority: {
    backgroundColor: palette.brand,
    color: palette.brightBg,
    borderColor: palette.brand,
    width: 30,
    height: 30,
    lineHeight: '30px',
    fontSize: 16,
  },
};

type Props = {
  type: ?string | ?number,
  invertedColors?: ?boolean,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class PriorityBadge extends PureComponent<Props> {
    render() {
      const { type, classes, invertedColors } = this.props;

      if (!type && type !== 0) {
        return null;
      }

      return (
        <div
          className={classnames({
            [classes.PriorityBadge]: true,
            [classes.p1]: type === 'P1' || type === 'P0',
            [classes.invertedColors]: invertedColors,
            [classes.nonPriority]:
              typeof type !== 'string'
                ? true
                : type[0] !== 'P' && type.length !== 2,
          })}
        >
          {type}
        </div>
      );
    }
  },
);
