//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import assign from 'rezz/assign';
import { type TopTenByCategoryResponse } from '@datatheorem/user-api/app_protection_top_ten_apps';
import { topAppsByCategoryReceived } from '../actions';

export type TopAppsByCategoryState = ?TopTenByCategoryResponse;

export default createReducerCreator<TopAppsByCategoryState, void, void>()(
  assign(topAppsByCategoryReceived.toString()),
);
