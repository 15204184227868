//@flow
import React, { PureComponent } from 'react';
import { CircularProgress } from '@material-ui/core';

function getStyles() {
  return {
    wrapper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
    },
  };
}

export default class PageLoader extends PureComponent<{}> {
  render() {
    const styles = getStyles();
    return (
      <div style={styles.wrapper}>
        <CircularProgress size={100} />
      </div>
    );
  }
}
