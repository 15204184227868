//@flow
import { type FindingTargetStatusEnum } from '@datatheorem/enums/FindingTargetStatusEnum';
import { type SecurityTemplateCategoryEnum } from '@datatheorem/enums/SecurityTemplateCategoryEnum';
import { type SecurityTemplateSeverityEnum } from '@datatheorem/enums/SecurityTemplateSeverityEnum';
import { type TemplateScanTypeEnum } from '@datatheorem/enums/TemplateScanTypeEnum';
import { type FindingPriorityEnum } from '@datatheorem/enums/FindingPriorityEnum';

import { request } from './gapi';
import type { PaginatedResponse } from './_common';
import { result } from './util';
import type { CompliancePolicyReference } from '@datatheorem/findings/types';

export type ShadowscanSecurityFinding = $ReadOnly<{|
  aggregated_status?: FindingTargetStatusEnum,
  aggregated_status_date?: string, //2017-09-01T12:07:58.850550
  category?: SecurityTemplateCategoryEnum,
  date_created?: string, //2017-09-01T12:07:58.681120
  date_updated?: string, //2017-09-01T12:07:59.761620
  exploitability?: string,
  id: string,
  importance_tags?: $ReadOnlyArray<string>,
  mobile_app_id: string,
  required_subscription?: TemplateScanTypeEnum,
  severity?: SecurityTemplateSeverityEnum,
  priority?: FindingPriorityEnum,
  compliance_policy_references?: $ReadOnlyArray<CompliancePolicyReference>,
|}>;

export type ShadowscanSecurityFindings = $ReadOnlyArray<
  ShadowscanSecurityFinding,
>;

export type ListParams = {
  +cursor?: ?string,
  +filter?: ?string,
  +mobile_app_id?: ?string,
  +priority?: ?string,
  +status_group?: ?string,
  +template_id?: ?string,
  +updated_since?: ?string,
};

type ListResponse = PaginatedResponse<{
  security_findings: ShadowscanSecurityFindings,
}>;

export async function list(params: ?ListParams): Promise<ListResponse> {
  return request({
    path: 'userapi/v2/shadow_security_findings',
    params: params,
  }).then(result);
}
