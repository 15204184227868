//@flow strict-local
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PageHeading } from '@datatheorem/components';
import type { ContextRouter } from 'react-router-dom';
import {
  apiKeyDeleteButtonClicked,
  apiKeyPageMounted,
  apiKeyEditFormSubmitted,
} from '../../actions';
import { FormikdEditForm } from '../api-keys/EditForm';
import { apiKeyFromParam } from '../../selectors/apiKeys';
import { sortedApps } from '../../selectors/apps';
import type { APIKey } from '@datatheorem/user-api/api_keys';
import type { Application } from '@datatheorem/user-api/mobile_apps';
import PageLoader from '../theme/PageLoader';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';

import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { API_KEYS_TYPE } from '../../strings';
import { browserHistory } from './../../clients/history';
import { type DispatchProps } from 'redux';
import type { State as ReduxState } from '../../ReduxStateType';
import type { ActionType } from 'redux-actions';

type OwnProps = ContextRouter;

type StateProps = {|
  apps: $ReadOnlyArray<Application>,
  apiKey: ?APIKey,
  initialLoaded: mixed,
  loading: mixed,
  formError: mixed,
  deletionLoading: mixed,
|};

type DP = DispatchProps<
  | ActionType<typeof apiKeyDeleteButtonClicked>
  | ActionType<typeof apiKeyPageMounted>
  | ActionType<typeof apiKeyEditFormSubmitted>,
>;

export type Props = {| ...OwnProps, ...TrackingProps, ...StateProps, ...DP |};

type IntermediateProps = {| ...OwnProps, ...TrackingProps |};

type State = { deleteConfirmationOpen: boolean };

export class APIKeyEdit extends PureComponent<Props, State> {
  state = { deleteConfirmationOpen: false };

  componentDidMount() {
    if (!this.props.initialLoaded) {
      this.props.dispatch(apiKeyPageMounted());
    }
  }

  render() {
    const {
      apiKey,
      loading,
      formError,
      deletionLoading,
      initialLoaded,
    } = this.props;

    if (!apiKey) {
      return null; // TODO: We should do something better here
    }

    const removeAPIKeyAction = {
      label: deletionLoading ? `Removing ${apiKey.name}` : 'Remove API Key',
      icon: <DeleteIcon />,
      style: { color: 'primary' },
      onClick: this.handleClickDelete,
      disabled: !!deletionLoading,
    };

    const { deleteConfirmationOpen } = this.state;

    return !initialLoaded ? (
      <PageLoader />
    ) : (
      <PageHeading
        backButton
        onClickBack={browserHistory.goBack}
        title="API Key Details"
        primaryAction={removeAPIKeyAction}
      >
        {/* $FlowFixMe */}
        <FormikdEditForm
          {...this.props}
          formError={
            formError && typeof formError._error === 'string'
              ? formError._error
              : null
          }
          loading={!!loading}
          submitLabel="Save"
        />
        <Dialog open={deleteConfirmationOpen}>
          <DialogTitle> Confirm API Key Removal</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Removing the key &quot;{apiKey.name}&quot; will disable API access
              for clients that are using it. Please update all clients that
              depend on this API Key before proceding.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              key={1}
              variant="outlined"
              onClick={this.handleClickCancelDelete}
            >
              Cancel
            </Button>
            <Button
              key={2}
              color="primary"
              variant="contained"
              keyboardFocused={true}
              onClick={this.handleConfirmDelete}
            >
              Remove API Key
            </Button>
          </DialogActions>
        </Dialog>
      </PageHeading>
    );
  }

  handleClickDelete = () => {
    this.setState({
      deleteConfirmationOpen: true,
    });
  };

  handleClickCancelDelete = () => {
    this.setState({
      deleteConfirmationOpen: false,
    });
  };

  handleConfirmDelete = () => {
    const { apiKey, dispatch } = this.props;

    if (!apiKey) {
      throw new Error('No API Key to delete');
    }

    this.handleClickCancelDelete();
    dispatch(apiKeyDeleteButtonClicked(apiKey));
  };
}

export default tracking(
  dataCreators.component(AnalyticsScreenEnum.API_KEY_EDIT),
)(
  connect<Props, IntermediateProps, _, _, _, _>(
    (state: ReduxState, props: IntermediateProps): StateProps => ({
      apps: sortedApps(state, {}),
      apiKey: apiKeyFromParam(state, props),
      initialLoaded: !!state.firstRequest[API_KEYS_TYPE],
      loading: state.loading.apiKeyEdit,
      formError: state.errors.apiKeyEdit,
      deletionLoading: state.loading.apiKeyDelete,
    }),
  )(APIKeyEdit),
);
