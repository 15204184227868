//@flow
import { createSelector } from 'reselect';
import type { AccountInfo } from '@datatheorem/user-api/account_info';

type AccountInfoStateInexact = {
  accountInfo: ?AccountInfo,
};

const accountInfo = (state: AccountInfoStateInexact) => state.accountInfo;

const hasScanAndSecure = createSelector<
  AccountInfoStateInexact,
  {},
  ?boolean,
  _,
>(
  accountInfo,

  info => info && info.toggles && info.toggles.scan_and_secure,
);

const hasOpenscan = createSelector<AccountInfoStateInexact, {}, ?boolean, _>(
  accountInfo,

  info => info && info.toggles && info.toggles.openscan,
);

const appSearchSubscription = createSelector<
  AccountInfoStateInexact,
  {},
  ?string,
  _,
>(
  accountInfo,

  info => (info && info.openscan_subscription) || 'NO_SUBSCRIPTION',
);

const brandProtectSubscription = createSelector<
  AccountInfoStateInexact,
  {},
  ?string,
  _,
>(
  accountInfo,

  info => (info && info.clonewatch_subscription) || 'NO_SUBSCRIPTION',
);

export default {
  accountInfo: accountInfo,
  hasScanAndSecure: hasScanAndSecure,
  hasOpenscan: hasOpenscan,

  appSearchSubscription: appSearchSubscription,
  brandProtectSubscription: brandProtectSubscription,
};
