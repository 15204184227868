//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import { palette } from '@datatheorem/theme';
import { Paper } from '@material-ui/core';

const styles = {
  AppPublisherBadge: {
    marginTop: 30,
  },
  Paper: {
    display: 'flex !important',
    flexDirection: 'column !important',
    alignItems: 'center !important',
    width: '200px !important',
    paddingBottom: '30px !important',
    paddingLeft: '20px !important',
    paddingRight: '20px !important',
    backgroundColor: ({ highlight }: Props) =>
      highlight ? 'gold !important' : null,
  },
  PaperScore: {
    position: 'relative !important',
    top: '-1.5em !important',
    width: '3em !important',
    height: '3em !important',
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    fontSize: '22px !important',
    backgroundColor: `${palette.brand} !important`,
    color: 'white !important',
    marginBottom: '-1em !important',
    borderRadius: 40,
  },
  appName: {
    margin: 0,
    fontWeight: 'normal',
    fontSize: 20,
    textAlign: 'center',
  },
  publisher: {
    margin: 0,
    fontWeight: 'normal',
    color: '#aaa',
    textAlign: 'center',
    fontSize: 16,
  },
};

type Props = {
  appName: string,
  publisherName?: string,
  rank?: number,
  highlight?: boolean,
} & InjectedJSS<typeof styles>;

// https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
function ordinal_suffix_of(i: number) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export default jss(styles)(
  class LeaderboardApp extends PureComponent<Props> {
    render() {
      const { classes, appName, publisherName, rank } = this.props;

      return (
        <div className={classes.AppPublisherBadge}>
          <Paper className={classes.Paper}>
            <Paper className={classes.PaperScore}>
              {typeof rank === 'number' && (
                <span className="rank">{ordinal_suffix_of(rank)}</span>
              )}
            </Paper>
            <div>
              <h3 className={classes.appName}>{appName}</h3>
              {publisherName && (
                <h4 className={classes.publisher}>by {publisherName}</h4>
              )}
            </div>
          </Paper>
        </div>
      );
    }
  },
);
