//@flow
import React, { PureComponent } from 'react';
import { type CommunicationTypes } from '@datatheorem/user-api/insights';
import ListItem from 'material-ui/List/ListItem';
import Divider from 'material-ui/Divider';

import ViewMoreButton from './../ViewMoreButton';

import { default as CountryFlag, getCountryName } from './../CountryFlag';
import InsightsCard from './InsightsCardWrapper';

type Props = {|
  communicates_with_countries: CommunicationTypes,
  expanded: boolean,
  maxVisibleCount: number,
  onClickExpand: () => mixed,
|};

export default class CountriesCard extends PureComponent<Props> {
  render() {
    const { props } = this;
    const {
      communicates_with_countries,
      expanded,
      maxVisibleCount,
      onClickExpand,
    } = props;

    if (!communicates_with_countries) {
      return null;
    }

    return (
      <InsightsCard header={'Connects To Following Countries'}>
        {communicates_with_countries.map((country, i) => {
          if (!expanded && i >= maxVisibleCount) {
            return null;
          }

          const countryName = getCountryName(country);

          return (
            <div key={country}>
              <Divider />
              <ListItem rightIcon={null}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>{countryName}</span>
                  <span style={{ width: '3em' }}>
                    <CountryFlag code={country} />
                  </span>
                </div>
              </ListItem>
            </div>
          );
        })}
        {!expanded && communicates_with_countries.length > maxVisibleCount ? (
          <>
            <Divider />
            <ViewMoreButton
              label="countries"
              count={communicates_with_countries.length - maxVisibleCount}
              onRequestViewMore={onClickExpand}
            />
          </>
        ) : null}
      </InsightsCard>
    );
  }
}
