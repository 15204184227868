// @flow
import React, { PureComponent } from 'react';
import jss from 'react-jss';

import CheckIcon from 'material-ui/svg-icons/navigation/check';
import TrackChangesIcon from 'material-ui/svg-icons/action/track-changes';

import { palette } from '@datatheorem/theme';

import type { InjectedJSS } from 'react-jss';

type blockType = 'tracked' | 'ok' | 'warning';

function getClassFromCount(count: number | null): blockType {
  return count === null ? 'tracked' : count === 0 ? 'ok' : 'warning';
}

const styles = {
  box: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    padding: '4px 0px',
  },

  col: {
    padding: '4px 12px',
  },

  card: {
    border: `1px solid ${palette.gray50}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    minHeight: 28,
    minWidth: 196,
    maxWidth: 196,
    borderRadius: 3,
    padding: 0,
    fontSize: '0.86rem',
    margin: '8px 4px',
    overflow: 'hidden',
    '&.tracked': {
      backgroundColor: palette.blue50,
      border: `1px solid ${palette.blue20}`,
    },
    '&.ok': {
      backgroundColor: palette.green50,
      border: `1px solid ${palette.green20}`,
    },
    '&.warning': {
      backgroundColor: palette.red50,
      border: `1px solid ${palette.red30}`,
    },

    '&.legend': {
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '132px',
      maxWidth: '132px',
    },
  },

  title: {
    padding: '0px 6px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },

  icon: {
    color: palette.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 28,
    maxWidth: 28,
    '&.tracked': {
      backgroundColor: palette.blue20,
    },
    '&.ok': {
      backgroundColor: palette.green20,
    },
    '&.warning': {
      backgroundColor: palette.red30,
    },
  },

  truncatedText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  legendBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
};

type Props = {|
  title: string,
  // Count == 0 => OK, Count > 0 => Warning, Count == null => Tracked
  count: number | null,
|} & InjectedJSS<typeof styles>;

class CoverageBlock extends PureComponent<Props> {
  render() {
    const { title, count, classes } = this.props;
    const blockType = getClassFromCount(count);
    return (
      <div className={`${classes.card} ${blockType}`}>
        <div className={`${classes.icon} ${blockType}`}>
          {blockType === 'ok' && <CheckIcon style={{ color: 'current' }} />}
          {blockType === 'tracked' && (
            <TrackChangesIcon style={{ color: 'current' }} />
          )}
          {blockType === 'warning' && (
            <div className={classes.truncatedText}>{count || 0}</div>
          )}
        </div>
        <div className={classes.title}>
          <div className={classes.truncatedText}>{title}</div>
        </div>
      </div>
    );
  }
}

export default jss(styles)(CoverageBlock);

export const CoverageLegend = jss(styles)(
  class CoverageLegend extends PureComponent<InjectedJSS<typeof styles>> {
    render() {
      const { classes } = this.props;
      return (
        <div className={classes.legendBox}>
          <div className={`${classes.card} legend ok`}>No Issues</div>
          <div className={`${classes.card} legend warning`}>
            Has Open Issues
          </div>
          <div className={`${classes.card} legend tracked`}>
            Tracked Category
          </div>
        </div>
      );
    }
  },
);
