//@flow
import React, { PureComponent, Fragment } from 'react';
import jss from 'react-jss';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  sortTargets,
  isTargetOpen,
} from '@datatheorem/findings/targets/status';

import { InfoCard } from '@datatheorem/components';
import InsightsMasonry from '../theme/InsightsMasonry';
import ContentAppContainerInfo from '../theme/ContentAppContainerInfo';
import ScanCoverageReport from '@datatheorem/components/src/ScanCoverageReport';

import type { InjectedJSS } from 'react-jss';
import type { InsightFinding } from '@datatheorem/user-api/insight_findings';
import type { CategoryMetadataCount } from '@datatheorem/user-api/mobile_apps';
import { type Insights } from '@datatheorem/user-api/insights';
import CommunicationInsightCard from '@datatheorem/components/src/insights/CommunicationInsightCard';

const ANDROID_TARGET_SDK_VERSION_TITLE = 'Android Target SDK Version';
const ANDROID_MINIMUM_SDK_VERSION_TITLE = 'Android Minimum SDK Version';
const IOS_BASE_SDK_TITLE = 'iOS Base SDK';
const IOS_MINIMUM_SDK = 'iOS Minimum SDK';
const CONTENT_APP_CONTAINER_TITLE = 'Content of the App Container';
const SERVER_ENDPOINTS_THIRD_PARTY_TITLE = 'Server Endpoints - Third-Party';
const SERVER_ENDPOINTS_MAIN_TITLE = 'Server Endpoints - Main';

const renderWithAndroid = function(renderer) {
  return function({ finding }) {
    return renderer({ finding: finding, android: true });
  };
};

const renderWithIOS = function(renderer) {
  return function({ finding }) {
    return renderer({ finding: finding, ios: true });
  };
};

const renderSingleDataPoint = function(args) {
  const { finding, ...options } = args;
  if (!finding) {
    return null;
  }

  const sortedTargets = finding.targets
    ? finding.targets.slice().sort(sortTargets)
    : null;

  return (
    <InfoCard
      {...options}
      key={finding.id}
      heading={finding.title}
      primaryText={sortedTargets ? sortedTargets[0].formatted_text : null}
      tooltip={finding.description}
    />
  );
};

const renderListData = function(args) {
  const { finding, alertOn } = args;

  if (!finding || !finding.title || !finding.targets) {
    return null;
  }
  return (
    <InfoCard
      key={finding.id}
      heading={finding.title}
      list={finding.targets
        .filter(isTargetOpen)
        .map(target => target.formatted_text)}
      tooltip={finding.description}
      alertOn={alertOn}
    />
  );
};

const styles = {
  loadingBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
  },

  contentBox: {
    margin: 20,
    padding: '0px 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
  },

  insightWrapper: {
    margin: 20,
    padding: '0px 32px',
  },
};

type OwnProps = {|
  insights: ?Insights,
  findings: $ReadOnlyArray<InsightFinding>,
  metadataCount: ?CategoryMetadataCount,
  alertOn?: ?$ReadOnlyArray<*>,
|};

type Props = {|
  ...OwnProps,
  ...InjectedJSS<typeof styles>,
|};

type State = {
  expandedFindings: { [key: string]: boolean },
  expandedInsights: { [key: string]: boolean },
};

class AppInsights extends PureComponent<Props, State> {
  masonryIteration = 1;

  state = {
    expandedFindings: {},
    expandedInsights: {},
  };

  render() {
    const {
      classes,

      findings,
      insights,
      alertOn,
      metadataCount,
    } = this.props;

    if (!metadataCount || !insights) {
      return (
        <div className={classes.loadingBox}>
          <CircularProgress />
        </div>
      );
    }

    const specializedRenders = {
      [SERVER_ENDPOINTS_THIRD_PARTY_TITLE]: () => null,
      [SERVER_ENDPOINTS_MAIN_TITLE]: () => null,
      [ANDROID_TARGET_SDK_VERSION_TITLE]: renderWithAndroid(
        renderSingleDataPoint,
      ),
      [ANDROID_MINIMUM_SDK_VERSION_TITLE]: renderWithAndroid(
        renderSingleDataPoint,
      ),
      [IOS_BASE_SDK_TITLE]: renderWithIOS(renderSingleDataPoint),
      [IOS_MINIMUM_SDK]: renderWithIOS(renderSingleDataPoint),
      [CONTENT_APP_CONTAINER_TITLE]: ({ finding }) => (
        <ContentAppContainerInfo key={finding.id} finding={finding} />
      ),
    };

    return (
      <Fragment>
        {/* Scan Coverage Report */}
        <div className={classes.contentBox}>
          {metadataCount && insights && (
            <ScanCoverageReport
              insights={insights}
              metadataCount={metadataCount}
            />
          )}
        </div>

        {/* Insight Cards */}
        <div className={classes.insightWrapper}>
          <InsightsMasonry key={this.masonryIteration}>
            {/* Specialized Insight Cards - Blueprint based findings with insightful data.*/}
            {findings
              .map(finding => {
                // Custom rendering for a specific type of an insight finding based on it's title.
                if (
                  finding.title &&
                  Object.keys(specializedRenders).includes(finding.title)
                ) {
                  return specializedRenders[finding.title]({ finding });
                }

                // Generic rendering for an insight finding based on it's title.
                // - List data.
                if (
                  finding.targets &&
                  finding.targets.filter(isTargetOpen).length > 1
                ) {
                  return renderListData({ finding, alertOn });
                }

                // - Single data.
                return renderSingleDataPoint({
                  finding,
                  alertOn,
                });
              })
              .filter(node => node)}

            {/* Communication Cards */}
            {insights.communication_insights &&
              Object.keys(insights.communication_insights).map(
                communication_insight_key => {
                  const communication_insights =
                    insights && insights.communication_insights;

                  if (!communication_insights) {
                    return null;
                  }

                  return (
                    <CommunicationInsightCard
                      key={communication_insight_key}
                      communication_insights={communication_insights}
                      communication_insight_key={communication_insight_key}
                      onClickExpand={this.onExpandedInsights.bind(
                        this,
                        communication_insight_key,
                      )}
                      expanded={
                        this.state.expandedInsights[communication_insight_key]
                      }
                    />
                  );
                },
              )}
          </InsightsMasonry>
        </div>
      </Fragment>
    );
  }

  onExpandedInsights = (communication_insights_key: string) => {
    this.masonryIteration++;
    this.setState({
      expandedInsights: {
        ...this.state.expandedInsights,
        [communication_insights_key]: true,
      },
    });
  };
}

export default jss(styles)(AppInsights);
