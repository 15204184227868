//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

import { type BadgeTypeEnum } from './BadgeTypeEnum';

import Dialog from 'material-ui/Dialog';
import ProtectionBadge from './Badge';
import {
  getBadgeDescriptionFromBadgeType,
  hasApplicableTasksForBadge,
} from './util';
import type { AppProtectionTask } from '@datatheorem/user-api/mobile_apps';
import { palette } from '@datatheorem/theme';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    cursor: 'pointer',
    padding: '5px',
    borderRadius: '3px',
    '&:hover': {
      boxShadow: '1px 1px 5px rgba(0,0,0,0.2)',
    },
  },

  badge: {
    width: ({ width }) => width,
    height: ({ height }) => height,
  },

  description: {
    wordBreak: 'break-word',
    textAlign: 'center',

    overflow: 'hidden',
    fontSize: 12,

    margin: 10,
  },

  taskStatus: {
    fontSize: 11,
  },

  completed: {
    color: palette.green30,
  },
  notCompleted: {
    color: palette.gray35,
  },
};

type StateProps = {|
  showTasksForBadge: boolean,
|};

type OwnProps = {|
  type: BadgeTypeEnum,
  enabled: boolean,
  height: number,
  width: number,
  tasksDialog?: Function | null,
  tasksForBadge: $ReadOnlyArray<AppProtectionTask>,
|};

type Props = {| ...OwnProps, ...InjectedJSS<typeof styles> |};

class ProtectionBadgeCard extends PureComponent<Props, StateProps> {
  state = { showTasksForBadge: false };

  render() {
    const { props, state } = this;

    const { classes, type, enabled, tasksForBadge, tasksDialog } = props;
    const { showTasksForBadge } = state;

    const description = getBadgeDescriptionFromBadgeType(type);

    const hasApplicableTasks = hasApplicableTasksForBadge(tasksForBadge);
    const applicableTasksMessage = hasApplicableTasks
      ? `${tasksForBadge.length} Incomplete Task${
          tasksForBadge.length > 1 ? 's' : ''
        }`
      : 'Not Applicable';

    return (
      <>
        <div
          className={classes.container}
          onClick={
            tasksForBadge
              ? () => {
                  this.setState({ showTasksForBadge: true });
                }
              : null
          }
        >
          <div className={classes.badge}>
            <ProtectionBadge type={type} enabled={enabled} />
          </div>

          <div className={classes.description}>{description}</div>
          {tasksForBadge.length > 0 && (
            <div className={classes.taskStatus}>
              {enabled ? (
                <span className={classes.completed}>Completed</span>
              ) : (
                <span className={classes.notCompleted}>
                  {applicableTasksMessage}
                </span>
              )}
            </div>
          )}
        </div>
        {tasksForBadge &&
          tasksForBadge.length > 0 && (
            <Dialog
              open={showTasksForBadge}
              onRequestClose={this.handleRequestClose}
              autoScrollBodyContent
              bodyStyle={{ padding: 0 }}
            >
              {showTasksForBadge &&
                tasksDialog &&
                tasksDialog(
                  type,
                  enabled,
                  tasksForBadge,
                  this.handleRequestClose,
                )}
            </Dialog>
          )}
      </>
    );
  }

  handleRequestClose = () => {
    this.setState({ showTasksForBadge: false });
  };
}

export default jss(styles)(ProtectionBadgeCard);
