// @flow
import { palette } from './index';
const globalStyle = `
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

:root {
${Object.entries(palette)
  .map(([name, value]) => {
    if (typeof value !== 'string') return;
    return `--${name}: ${value};`;
  })
  .filter(Boolean)
  .join('\n')}
}
* {
  box-sizing: border-box;
}

html {
  overflow-y: scroll;
  padding-right: 0 !important; /* Material-ui removes the scrollbar and tries to make up for it by adding some padding */
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  height: 100vh;
}

/* For @reach/router */
a {
  text-decoration: none;
}

.test {
  font-size: 15px;
}
`;

if (typeof window !== 'undefined' && !window.__dt_globalCss) {
  window.__dt_globalCss = true;
  const tag = document.createElement('style');
  tag.setAttribute('data-global', 'true');
  tag.appendChild(document.createTextNode(globalStyle));
  if (document.head) document.head.appendChild(tag);
}
