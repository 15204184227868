//@flow
import React, { PureComponent } from 'react';
import CircularProgress from 'material-ui/CircularProgress';

import CenteredContainer from './CenteredContainer';

export default class CenteredCircularProgress extends PureComponent<{}> {
  render() {
    return (
      <CenteredContainer>
        <CircularProgress />
      </CenteredContainer>
    );
  }
}
