//@flow
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import {
  ShadowScanSummaryCard,
  PageHeading,
  ShadowScanPreviewFindingCardList,
  MeasureableHeightDiv,
} from '@datatheorem/components';

import {
  getShowHideClosedFilter,
  getComplianceSearchFilter,
} from '@datatheorem/findings/findingsFilterCreator';

import { browserHistory } from '../../clients/history';
import SecurityFindings from '../findings/SecurityFindings';
import {
  apps as appsSelector,
  totalMetadataCount as totalMetadataCountSelector,
} from '../../selectors/apps';
import {
  paramsForCategory as paramsForCategorySelector,
  findingsCount as findingsCountSelector,
} from '../../selectors/securityFindings';
import replaceInPath from '../util/replaceInPath';
import { type CategoryMetadataCount } from '@datatheorem/user-api/mobile_apps';
import { type DashboardInjectedProps } from '../../types/DashboardInjectedProps';
import AppCard from '../advanced-filter/common-ui/AppCard';
import { Paginate } from '@datatheorem/pagination';
import SecurityFindingDialogManager from './SecurityFindingDialogManager';
import { complianceFilterClicked } from '../../actions';
import {
  inquireShadowScanFindings,
  type InquireShadowScanFindingsActionParams,
} from './../../actions/shadowScanFindings';
import { SecurityFindingEndpoint } from '../../endpoints';
import ListCardSpacer from '../theme/ListCardSpacer';
import { useTracking } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { createFindingsFromCategorySelector as createShadowscanFindingsFromCategorySelector } from '@datatheorem/findings/shadow-scan/selectors';
import type { rowRenderProps } from '../theme/BigList';

import AdvancedSearch from '../advanced-filter/AdvancedSearch';
import CSVExporter from '../advanced-filter/CSVExporter';
import compliancePolicy from '@datatheorem/enums/CompliancePolicyEnum';
import findingPriority from '@datatheorem/enums/FindingPriorityEnum';

import { searchStartedAction } from '../../actions/searchActions';

import {
  setCompliancePolicyFilter,
  setCurrentStatusFilter,
  setPriorityFilter,
  setStoreBlockersFilter,
  storeBlockers,
  resetFilter,
} from '../../actions/filterActions';

import {
  getCurrentFilteredFindings,
  appsWithScoreAndCountSel,
  sortedFindingsSel,
} from '../../selectors/filterSelectors';
import debounce from 'lodash/fp/debounce';

type Props = {| ...DashboardInjectedProps |};

const getAnalytics = (category: void | string) => {
  if (!category) return AnalyticsScreenEnum.SEARCH_RESULTS;
  return category
    ? {
        priority: AnalyticsScreenEnum.PRIORITY_ALERTS,
        security: AnalyticsScreenEnum.SECURITY_P1S,
        google: AnalyticsScreenEnum.GOOGLE_BLOCKERS,
        apple: AnalyticsScreenEnum.APPLE_BLOCKERS,
        compliance: AnalyticsScreenEnum.REGULATORY_COMPLIANCE,
        all: AnalyticsScreenEnum.ALL_ISSUES,
      }[category]
    : AnalyticsScreenEnum.ALL_ISSUES;
};

const getTitle = (category: ?string) => {
  if (!category) {
    return 'Search Results';
  }

  return {
    priority: 'Priority Alerts',
    security: 'Security P1s',
    google: 'Google Play Blockers',
    apple: 'App Store Blockers',
    compliance: 'Regulatory Compliance',
    all: 'All Issues',
  }[category];
};

function Issues(props: Props) {
  const [searchText, setSearch] = useState<string>(() => {
    const { searchTerm } = qs.parse(window.location.search);
    return searchTerm && Array.isArray(searchTerm)
      ? searchTerm[0] || ''
      : searchTerm || '';
  });
  const shadowscanCardRef = useRef(null);
  const tracking = useTracking();
  const dispatch = useDispatch();

  const category = props.match && props.match.params.category;

  const changeSearchText = debounce(200, (value: string) => {
    setSearch(value);
    props.history.push({ search: `?searchTerm=${value}` });
  });

  const selectorCallback = useCallback(
    state => ({
      apps: appsSelector(state),
      totalMetadataCount: totalMetadataCountSelector(state, props),
      filterValue: getShowHideClosedFilter(browserHistory),
      compliancePolicyFilter: getComplianceSearchFilter(browserHistory),
      shadowScanFindings: createShadowscanFindingsFromCategorySelector(
        browserHistory,
      )(state, props),
      paramsForCategory: paramsForCategorySelector(state, props),
      allFindingsCount: state.securityFindings.length,
      currentFindings: getCurrentFilteredFindings(state, {}),
      advancedFilter: state.advancedFilter,
      sortedFindings: sortedFindingsSel(state, props),
      appsFiltered: appsWithScoreAndCountSel(state, props),
      findingsCount: findingsCountSelector(state, props),
    }),
    [props],
  );

  const {
    apps,
    appsFiltered,
    totalMetadataCount,
    allFindingsCount,
    filterValue,
    shadowScanFindings,
    compliancePolicyFilter,
    paramsForCategory,
    advancedFilter,
    currentFindings,
    sortedFindings,
    findingsCount,
  } = useSelector(selectorCallback);

  useEffect(() => {
    dispatch(searchStartedAction());
  }, [dispatch]);

  // Set default filters based on category
  useEffect(() => {
    dispatch(resetFilter());
    dispatch(setCurrentStatusFilter(['NEW', 'OPEN']));

    if (category === 'compliance') {
      dispatch(setCompliancePolicyFilter(Object.keys(compliancePolicy)));
    }

    if (category === 'priority') {
      dispatch(setPriorityFilter([findingPriority.P0, findingPriority.P1]));
    }
    if (category === 'google') {
      dispatch(setStoreBlockersFilter([storeBlockers.google]));
    }
    if (category === 'apple') {
      dispatch(setStoreBlockersFilter([storeBlockers.apple]));
    }
    if (category === 'security') {
      dispatch(setPriorityFilter([findingPriority.P1]));
    }
  }, [category, dispatch]);

  // Tracking
  useEffect(() => {
    tracking.trackEvent({
      ...dataCreators.component(AnalyticsScreenEnum.SEARCH_RESULTS),
      screen_name: getAnalytics(category),
    });
  }, [tracking, category]);

  const handleShadowScanInquireClick = useCallback(
    (params: InquireShadowScanFindingsActionParams) => {
      dispatch(inquireShadowScanFindings(params));
    },
    [dispatch],
  );
  const renderCoda = useCallback(
    (
      totalMetadataCount: CategoryMetadataCount,
      { key, style }: rowRenderProps,
    ) => {
      return (
        <div style={style} key={key}>
          <MeasureableHeightDiv ref={shadowscanCardRef}>
            <ListCardSpacer>
              <ShadowScanSummaryCard
                type={category}
                shadowMetadataCount={totalMetadataCount.SHADOW}
                onClickInquire={handleShadowScanInquireClick}
              >
                {shadowScanFindings && shadowScanFindings.length > 0 ? (
                  <ShadowScanPreviewFindingCardList
                    findings={shadowScanFindings}
                  />
                ) : (
                  false
                )}
              </ShadowScanSummaryCard>
            </ListCardSpacer>
          </MeasureableHeightDiv>
        </div>
      );
    },
    [category, shadowScanFindings, handleShadowScanInquireClick],
  );

  const shadowscanHeight = useCallback(() => {
    if (
      shadowscanCardRef.current &&
      typeof shadowscanCardRef.current.measure === 'function'
    ) {
      return shadowscanCardRef.current.measure().height;
    } else {
      return 600;
    }
  }, []);

  const onChangeComplianceSearchFilter = useCallback(
    (compliancePolicies: $ReadOnlyArray<string>) => {
      dispatch(complianceFilterClicked(compliancePolicies));
    },
    [dispatch],
  );

  const match = props.match;
  const navigate = useCallback(
    (params: { [string]: ?string }) => {
      browserHistory.push(
        replaceInPath(
          `/issues/:category/:findingId${window.location.search}`,
          params,
          match.params,
        ),
      );
    },
    [match],
  );

  const onSelectFinding = useCallback(
    (finding: { +id: string }) => {
      navigate({ category, findingId: finding.id });
    },
    [navigate, category],
  );

  const handleCloseDialog = useCallback(() => {
    browserHistory.push(
      replaceInPath(
        `/issues/:category/:findingId${window.location.search}`,
        { findingId: null },
        match && match.params,
      ),
    );
  }, [match]);

  // We aren't checking if the ShadowSummaryCard.shouldShowFor here.
  // Assuming this is by design.
  const codaCount = 1;

  return (
    <SecurityFindingDialogManager
      onCloseDialog={handleCloseDialog}
      currentUser={props.currentUser}
    >
      {() => (
        <div>
          <PageHeading title={getTitle(category)} forMenu>
            <div style={{ paddingLeft: '80px', paddingRight: '55px' }}>
              <CSVExporter>
                {handleCSVExport => (
                  <AdvancedSearch
                    mode="minimal"
                    advancedFilter={advancedFilter}
                    onSearchChange={value => changeSearchText(value)}
                    searchBoxValue={searchText}
                    currentFindings={currentFindings}
                    allFindingsLength={allFindingsCount}
                    currentFindingsLength={sortedFindings.length}
                    handleCSVExport={handleCSVExport}
                  />
                )}
              </CSVExporter>
            </div>
            <Paginate type={SecurityFindingEndpoint} params={paramsForCategory}>
              {({ loading, loadedInitial, loadMore }) =>
                advancedFilter.showing === 'apps' ? (
                  <div style={{ paddingLeft: '80px', paddingRight: '55px' }}>
                    {appsFiltered.map(a => (
                      <AppCard key={a.id} app={a} />
                    ))}
                  </div>
                ) : (
                  <SecurityFindings
                    findings={sortedFindings}
                    findingsCount={findingsCount}
                    apps={apps}
                    onSelectFinding={onSelectFinding}
                    onChangeComplianceSearchFilter={
                      onChangeComplianceSearchFilter
                    }
                    compliancePolicyFilter={compliancePolicyFilter}
                    filler={!loadedInitial}
                    loadMoreRows={() => {
                      loadMore();
                    }}
                    onChangeShowHideClosed={() => null} // no-op
                    filterValue={filterValue}
                    loading={loading}
                    coda={codaRow => renderCoda(totalMetadataCount, codaRow)}
                    codaCount={codaCount}
                    codaRowHeight={shadowscanHeight}
                    showCompliancePolicyFilter={false}
                    showDoMore={false}
                    reRenderBasedOn={shadowScanFindings}
                  />
                )
              }
            </Paginate>
          </PageHeading>
        </div>
      )}
    </SecurityFindingDialogManager>
  );
}

export default React.memo<Props>(Issues);
