//@flow
import React, { PureComponent, type Node } from 'react';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import IconButton from 'material-ui/IconButton';
import { default as MUICircularProgress } from 'material-ui/CircularProgress';

import { fontSizes, palette } from '@datatheorem/theme';

const CircularProgress = ({
  size = 'medium',
  color,
}: {
  size?: string,
  color?: string,
}) => (
  <MUICircularProgress
    size={
      {
        small: fontSizes.small + 9,
        medium: fontSizes.medium + 10,
        large: fontSizes.large + 12,
      }[size || 'medium']
    }
    color={color || palette.brand40}
    thickness={2}
    // There is no way of giving a role or title to this SVG.
    // In the future, it material-ui should allow us to pass <title></title> tags to svg
    // so it can be accessible in our tests (and a11y)
    data-testid="__loading_button__"
  />
);

type Size = 'small' | 'medium' | 'large';
type Variant = 'contained' | 'outlined' | 'icon' | 'text';

type Props = {
  children?: ?Node,
  variant?: Variant,
  primaryColor?: string,
  secondaryColor?: string,
  hoverColor?: string,
  rippleColor?: string,
  progressColor?: string,
  size?: Size,
  ariaLabel: string,

  disabled?: ?boolean,
  isLoading?: ?boolean,

  onClick?: (e: SyntheticMouseEvent<HTMLElement>) => mixed,
  onMouseDown?: (e: SyntheticMouseEvent<HTMLElement>) => mixed,
  onMouseEnter?: (e: SyntheticMouseEvent<HTMLElement>) => mixed,
  onMouseLeave?: (e: SyntheticMouseEvent<HTMLElement>) => mixed,
  onMouseUp?: (e: SyntheticMouseEvent<HTMLElement>) => mixed,
  onTouchEnd?: (e: SyntheticTouchEvent<HTMLElement>) => mixed,
  onTouchStart?: (e: SyntheticTouchEvent<HTMLElement>) => mixed,

  gutterMargin?: boolean,
};

type Styles = {
  button: {
    fontSize: number,
    padding: string,
    margin: number,
    borderStyle?: string,
    borderColor?: string,
    borderWidth?: number | string,
  },
};

const getStyles = (size: Size) => ({
  button: {
    fontSize: {
      small: fontSizes.small,
      medium: fontSizes.medium,
      large: fontSizes.large,
    }[size],
    padding: {
      small: '4px 8px 4px 8px',
      medium: '6px 16px 6px 16px',
      large: '8px 24px 8px 24px',
    }[size],
    margin: 8,
    borderStyle: 'unset',
    borderWidth: 'unset',
    borderColor: 'unset',
    whiteSpace: 'nowrap',
  },
});

class Button extends PureComponent<Props> {
  static defaultProps = {
    primaryColor: palette.brand30,
    secondaryColor: palette.white,
    hoverColor: palette.brand50,
    rippleColor: palette.white,
    progressColor: palette.brand40,
  };

  renderIcon = () => {
    const { props } = this;

    const {
      children,
      ariaLabel,

      size,
      primaryColor,
      progressColor,

      disabled,
      isLoading,

      onClick,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
      onTouchEnd,
      onTouchStart,
    } = props;

    const colorState = !disabled && !isLoading ? primaryColor : palette.gray30;
    const content = !isLoading ? (
      children
    ) : (
      <CircularProgress size={size} color={progressColor} />
    );

    return (
      <IconButton
        aria-label={ariaLabel}
        disabled={disabled || isLoading}
        iconStyle={{
          color: colorState,
          fill: colorState,
        }}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        onTouchStart={onTouchStart}
      >
        {content}
      </IconButton>
    );
  };

  renderText = (styles: Styles) => {
    const {
      children,
      ariaLabel,

      size,
      primaryColor,
      hoverColor,
      rippleColor,
      progressColor,

      disabled,
      isLoading,

      onClick,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
      onTouchEnd,
      onTouchStart,
      gutterMargin,
    } = this.props;

    const primaryColorState =
      disabled || isLoading ? palette.gray30 : primaryColor;
    const content = !isLoading ? (
      children
    ) : (
      <CircularProgress size={size} color={progressColor} />
    );

    return (
      <FlatButton
        disabled={disabled || isLoading}
        label={content}
        style={{
          padding: 0,
          margin: gutterMargin ? 0 : styles.button.margin,
          height: 'unset',
          minWidth: 64,
          lineHeight: 1.8,
          borderRadius: 4,
          borderStyle: styles.button.borderStyle,
          borderColor: styles.button.borderColor,
          borderWidth: styles.button.borderWidth,
        }}
        aria-label={ariaLabel}
        labelStyle={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: styles.button.fontSize,
          fontWeight: 500,
          padding: styles.button.padding,
          color: primaryColorState,
          fill: primaryColorState,
          whiteSpace: 'nowrap',
        }}
        hoverColor={hoverColor}
        rippleColor={rippleColor}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        onTouchStart={onTouchStart}
      />
    );
  };

  renderContained = (styles: Styles) => {
    const {
      children,
      ariaLabel,

      size,
      primaryColor,
      secondaryColor,
      progressColor,
      rippleColor,
      gutterMargin,

      disabled,
      isLoading,

      onClick,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      onMouseUp,
      onTouchEnd,
      onTouchStart,
    } = this.props;

    const primaryColorState =
      disabled || isLoading ? palette.gray30 : primaryColor;
    const secondaryColorState =
      disabled || isLoading ? palette.gray30 : secondaryColor;
    const content = !isLoading ? (
      children
    ) : (
      <CircularProgress size={size} color={progressColor} />
    );

    return (
      <RaisedButton
        aria-label={ariaLabel}
        disabled={disabled || isLoading}
        label={content}
        labelStyle={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'unset',
          padding: 'unset',
          lineHeight: 1.8,
          fontSize: styles.button.fontSize,
          fill: secondaryColorState,
          whiteSpace: 'nowrap',
        }}
        overlayStyle={{
          height: 'unset',
          padding: styles.button.padding,
          borderRadius: 4,
        }}
        buttonStyle={{
          height: 'unset',
          lineHeight: 'unset',
          padding: 0,
          borderRadius: 4,
          border: '1px solid transparent',
        }}
        style={{
          margin: gutterMargin ? 0 : styles.button.margin,
          minWidth: 64,
          borderRadius: 4,
          cursor: 'pointer',
        }}
        backgroundColor={primaryColorState}
        labelColor={secondaryColor}
        // NOTE: hoverColor is not supported in material-ui 0.20.x - keep your sanity.
        rippleStyle={{
          color: rippleColor,
        }}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        onTouchStart={onTouchStart}
      />
    );
  };

  renderOutlined = (styles: Styles) => {
    const { renderText, props } = this;

    const {
      primaryColor,

      disabled,
      isLoading,
    } = props;

    const colorState = !disabled && !isLoading ? primaryColor : palette.gray30;

    return renderText({
      button: {
        ...styles.button,
        borderStyle: 'solid',
        borderColor: colorState,
        borderWidth: 1,
      },
    });
  };

  renderVariant = (variant: Variant) => {
    const {
      renderContained,
      renderOutlined,
      renderIcon,
      renderText,

      props,
    } = this;

    const { size } = props;

    const styles = getStyles(size || 'medium');

    switch (variant) {
      case 'contained':
        return renderContained(styles);

      case 'outlined':
        return renderOutlined(styles);

      case 'icon':
        return renderIcon();
    }

    return renderText(styles);
  };

  render() {
    const {
      props,

      renderVariant,
    } = this;

    const { variant } = props;

    return renderVariant(variant || 'contained');
  }
}

export default Button;
