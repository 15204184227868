//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import removeFromList from 'rezz/removeFromList';
import { apiKeysReceived, apiKeyDeleteResponseReceived } from '../actions';
import type { APIKey } from '@datatheorem/user-api/api_keys';

export type APIKeysType = $ReadOnlyArray<APIKey>;

// $FlowFixMe FlowUpgrade
export default createReducerCreator({
  initialState: ([]: APIKeysType),
  equalityCheck: (a: APIKey, b: APIKey) => a.id === b.id,
})(
  updateInList(apiKeysReceived.toString()),
  removeFromList(apiKeyDeleteResponseReceived.toString()),
);
