//@flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { appFromParam } from '../../selectors/apps';
import type { DashboardInjectedProps } from '../../types/DashboardInjectedProps';
import type { Application } from '@datatheorem/user-api/mobile_apps';
import { type DispatchProps } from 'redux';
import type { State as ReduxState } from '../../ReduxStateType';
import { appProtectionPercentForAppFromParam } from '../../selectors/protection';
import { mobileApplicationViewed } from '../../actions';
import { Raven } from '@datatheorem/global';
import MobileAppDetails from '../apps/MobileAppDetails';
import { CenteredCircularProgress } from '@datatheorem/components';

type OwnProps = DashboardInjectedProps;

type StateProps = {|
  app: ?Application,
  appProtectionPercent: number | null,
  firstRequestDone: boolean,
|};

type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps<>,
|};

export default connect<Props, OwnProps, _, _, _, _>(
  (state: ReduxState, props: OwnProps): StateProps => ({
    firstRequestDone: state.firstRequest.apps,
    app: appFromParam(state, props),
    appProtectionPercent: appProtectionPercentForAppFromParam(state, props),
  }),
)(function MobileApplication({
  app,
  dispatch,
  appProtectionPercent,
  firstRequestDone,
  currentUser,
}: Props) {
  useEffect(
    () => {
      if (app) {
        dispatch(mobileApplicationViewed(app));
      }
    },
    [app, dispatch],
  );

  if (!firstRequestDone) {
    return <CenteredCircularProgress />;
  }

  if (!app) {
    try {
      throw new Error('MobileApplication component rendered without app/appId');
    } catch (e) {
      Raven.captureException(e);
      console.error('Exception Sent To Raven:', e);
    }
    return (
      <div style={{ margin: 50, fontSize: 20 }}>
        Could not find this application.
      </div>
    );
  }

  return (
    <MobileAppDetails
      app={app}
      appProtectionPercent={appProtectionPercent}
      currentUser={currentUser}
    />
  );
});
