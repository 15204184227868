// @flow
import React, { Fragment } from 'react';
import injectSheet from 'react-jss';
// Components
import FilterToggle from '../common-ui/FilterToggle';
import FilterSelectField from '../common-ui/FilterSelectField';
import SortIcon from '@material-ui/icons/Sort';
import Typography from '@material-ui/core/Typography';
import { palette } from '@datatheorem/theme';

// Types
import type { InjectedJSS } from 'react-jss';
import type { FilterStore } from '../../../reducers/filters';
import type {
  SetFindingsSortFn,
  ToggleShowingFn,
} from '../../../actions/filterActions';
import type { Options } from '../../../actions/filterActions';
import type { FindingSortChoiceEnum } from '@datatheorem/user-api/search_queries';

const sortByOptions: Options<FindingSortChoiceEnum> = [
  { label: 'Priority', value: 'PRIORITY' },
  { label: 'Severity', value: 'SEVERITY' },
  { label: 'Status', value: 'STATUS' },
  { label: 'Date', value: 'DATE_CREATED' },
  { label: 'Age', value: 'AGE' },
];

const styles = {
  filterSelect: {
    margin: {
      right: 10,
    },
  },
  filterSelectClose: {
    margin: {
      right: 4,
    },
  },
  flexWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
};

type Props = {|
  allFindingsLength: number,
  currentFindingsLength: number,

  findingsCriteria: $ElementType<FilterStore, 'findingsCriteria'>,
  showing: $ElementType<FilterStore, 'showing'>,
  setFindingsSort: SetFindingsSortFn,
  toggleShowing: ToggleShowingFn,
  ...InjectedJSS<typeof styles>,
|};

const FindingsMetadata = ({
  allFindingsLength,
  currentFindingsLength,

  findingsCriteria,
  showing,
  classes,
  setFindingsSort,
  toggleShowing,
}: Props) => {
  return (
    <Fragment>
      {/* Metadata/sort actions */}
      <div
        className={classes.flexWrapper}
        style={{
          alignItems: 'baseline',
          justifyContent: 'space-between',
          marginTop: '20px',
          marginBottom: '10px',
        }}
      >
        {/* Total count */}

        <Typography className={classes.flexWrapper} variant="body2">
          Showing {currentFindingsLength || 0} of {allFindingsLength || 0} total
          findings
        </Typography>

        {/* View by Apps / By Issues  */}
        <div
          className={classes.flexWrapper}
          style={{
            minWidth: '200px',
            justifyContent: 'center',
          }}
        >
          <FilterToggle
            label="Show affected Apps"
            value={showing === 'apps'}
            onChange={checked =>
              !checked ? toggleShowing('findings') : toggleShowing('apps')
            }
          />
        </div>

        {/* Sorting */}
        <div
          className={classes.flexWrapper}
          style={{
            alignItems: 'center',
          }}
        >
          <SortIcon style={{ color: palette.gray30 }} />
          <Typography variant="body2" style={{ marginRight: 10 }}>
            Sort by{' '}
          </Typography>
          <FilterSelectField
            options={sortByOptions}
            onChange={setFindingsSort}
            value={
              findingsCriteria.sortBy === ''
                ? 'STATUS'
                : findingsCriteria.sortBy
            }
          />
          {/* <DropDownMenu
            autoWidth
            style={{ marginRight: '-22px' }}
            value={
              findingsCriteria.sortBy === ''
                ? 'STATUS'
                : findingsCriteria.sortBy
            }
            onChange={(e, index, values) => setFindingsSort(values)}
          >
            {sortByOptions.map(o => (
              <MenuItem
                key={o.value}
                insetChildren={true}
                checked={findingsCriteria.sortBy === o.value}
                value={o.value}
                primaryText={o.label}
              />
            ))}
          </DropDownMenu> */}
        </div>
      </div>
    </Fragment>
  );
};

export default injectSheet(styles)(FindingsMetadata);
