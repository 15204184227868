//@flow
import React, { PureComponent } from 'react';

import { type CommunicationInsights } from '@datatheorem/user-api/insights';

import BlackListedIPAddressesCard from './BlackListedIPAddressesCard';
import IPAddressesCard from './IPAddressesCard';
import CountriesCard from './CountriesCard';
import EndpointsCard from './EndpointsCard';

type OwnProps = {|
  expanded: boolean,
  maxVisibleCount: number,
  communication_insight_key: string,
  communication_insights: CommunicationInsights,
  onClickExpand: () => mixed,
|};

type Props = {|
  ...OwnProps,
|};

class CommunicationInsightCard extends PureComponent<Props> {
  static defaultProps = {
    maxVisibleCount: 3,
  };

  render() {
    const {
      expanded,
      maxVisibleCount,
      communication_insight_key,
      communication_insights,
      onClickExpand,
    } = this.props;

    if (
      communication_insight_key === 'communicates_with_blacklisted_ip_addresses'
    ) {
      return (
        <BlackListedIPAddressesCard
          communicates_with_blacklisted_ip_addresses={
            communication_insights[communication_insight_key]
          }
          expanded={expanded}
          maxVisibleCount={maxVisibleCount}
          onClickExpand={onClickExpand}
        />
      );
    }

    if (communication_insight_key === 'communicates_with_ip_addresses') {
      return (
        <IPAddressesCard
          communicates_with_ip_addresses={
            communication_insights[communication_insight_key]
          }
          expanded={expanded}
          maxVisibleCount={maxVisibleCount}
          onClickExpand={onClickExpand}
        />
      );
    }

    if (communication_insight_key === 'communicates_with_countries') {
      return (
        <CountriesCard
          communicates_with_countries={
            communication_insights[communication_insight_key]
          }
          expanded={expanded}
          maxVisibleCount={maxVisibleCount}
          onClickExpand={onClickExpand}
        />
      );
    }

    if (communication_insight_key === 'communicates_with_hostnames') {
      return (
        <EndpointsCard
          header={'Connected Domains'}
          hostnames={communication_insights[communication_insight_key]}
          expanded={expanded}
          maxVisibleCount={maxVisibleCount}
          onClickExpand={onClickExpand}
        />
      );
    }

    if (communication_insight_key === 'communicates_with_first_party_origins') {
      return (
        <EndpointsCard
          header={'Connected APIs & Domains - Main'}
          hostnames={communication_insights[communication_insight_key]}
          expanded={expanded}
          maxVisibleCount={maxVisibleCount}
          onClickExpand={onClickExpand}
        />
      );
    }

    if (communication_insight_key === 'communicates_with_third_party_origins') {
      return (
        <EndpointsCard
          header={'Connected APIs & Domains - Third Party'}
          hostnames={communication_insights[communication_insight_key]}
          expanded={expanded}
          maxVisibleCount={maxVisibleCount}
          onClickExpand={onClickExpand}
        />
      );
    }

    throw new Error('"communication_insight_key" not a valid key.');
  }
}

export default CommunicationInsightCard;
