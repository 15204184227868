//@flow
import type { Application } from '@datatheorem/user-api/mobile_apps';
import startCase from 'lodash/startCase';
import TemplateScanTypeEnum from '@datatheorem/enums/TemplateScanTypeEnum';
import AppScanBlockedReasonEnum, {
  type AppScanBlockedReasonEnum as AppScanBlockedReasonEnumType,
} from '@datatheorem/enums/AppScanBlockedReasonEnum';
import { fromNow } from '@datatheorem/date';

const SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE = {
  NO_SUBSCRIPTION: 5,
  P1_ALERTS: 10,
  STATIC: 20,
  APP_LOGIC: 50,
  DYNAMIC: 40,
};

const SUBSCRIPTION_LEVEL: $ReadOnlyArray<string> = Object.keys(
  SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE,
);

export function subscriptionToFriendlyString(sub: string): string {
  switch (sub.toLowerCase()) {
    case 'p1_alerts':
      return 'Security P1';
    case 'static':
      return 'Baseline';
    case 'dynamic':
      return 'Enterprise';
    case 'app_logic':
      return 'Prime';
    case 'no_subscription':
      return 'None';
    default:
      return startCase(sub.toLowerCase());
  }
}

export function hasSubscription(app: Application): boolean {
  return app
    ? app.subscription !== TemplateScanTypeEnum.NO_SUBSCRIPTION
    : false;
}

export function isCorruptedAppPackageReason(
  appScanBlockedReason: ?AppScanBlockedReasonEnumType,
): boolean {
  return (
    appScanBlockedReason === AppScanBlockedReasonEnum.CORRUPTED_APP_PACKAGE
  );
}

export function isNoCredentialsReason(
  appScanBlockedReason: ?AppScanBlockedReasonEnumType,
): boolean {
  return appScanBlockedReason === AppScanBlockedReasonEnum.NO_CREDENTIALS;
}

export function isScanBlocked(
  blockedScanReason: ?AppScanBlockedReasonEnumType,
): boolean {
  return (
    blockedScanReason === AppScanBlockedReasonEnum.CORRUPTED_APP_PACKAGE ||
    blockedScanReason === AppScanBlockedReasonEnum.NO_CREDENTIALS
  );
}

export function getHumanReadableScanStatus(app: Application): ?string {
  if (!app || !app.most_recent_scan) {
    return null;
  }

  return getHumanReadableScanStatusFromScan(app.most_recent_scan);
}

export function getHumanReadableScanStatusFromScan(
  scan: ?{ +start_date?: string, +end_date?: string },
  blockedScanReason: ?AppScanBlockedReasonEnumType,
): string {
  if (!scan) {
    return 'Never Scanned';
  }

  const { start_date, end_date } = scan;

  if (!start_date) {
    return 'Never Scanned';
  }

  if (!end_date && isScanBlocked(blockedScanReason)) {
    return 'Scan Blocked';
  }

  if (!end_date) {
    return 'Scan Ongoing';
  }

  return `Scanned ${fromNow(end_date)}`;
}

type AppScanBlockedReasonMapping = {
  title: string,
  dialogTitle: string,
  type: AppScanBlockedReasonEnumType,
};

export function getAppScanBlockedReasonMapping(
  blockedScanReason: ?AppScanBlockedReasonEnumType,
): ?AppScanBlockedReasonMapping {
  if (blockedScanReason === AppScanBlockedReasonEnum.CORRUPTED_APP_PACKAGE) {
    return {
      title: 'Corrupted App Package',
      dialogTitle: 'Unstable Binary',
      type: AppScanBlockedReasonEnum.CORRUPTED_APP_PACKAGE,
    };
  } else if (blockedScanReason === AppScanBlockedReasonEnum.NO_CREDENTIALS) {
    return {
      title: 'Absent Credentials',
      dialogTitle: 'Missing Test Credentials',
      type: AppScanBlockedReasonEnum.NO_CREDENTIALS,
    };
  }
}

export function getListOfHigherSubscriptions(
  subscription: string,
): ?$ReadOnlyArray<string> {
  const currentSubscriptionPower =
    SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE[subscription];
  if (!currentSubscriptionPower) {
    return null;
  }

  return SUBSCRIPTION_LEVEL.reduce(
    (final, sub) =>
      SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE[sub] > currentSubscriptionPower
        ? final.concat(sub)
        : final,
    [],
  ).sort(
    (a, b) =>
      SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE[a] -
      SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE[b],
  );
}
