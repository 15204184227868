//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { sdkFindingsByAppIdParam } from '../../selectors/sdkFindings';
import { type SDKFinding } from '@datatheorem/user-api/sdk_findings';
import partition from 'lodash/partition';
import SDKSection from '../theme/SDKSection';
import SDKCards from '../theme/SDKCards';
import { Paginate } from '@datatheorem/pagination';
import SDKFindingCard from '../findings/SDKFindingCard';
import { LoadingFiller } from '@datatheorem/components';
import ListCardSpacer from '../theme/ListCardSpacer';
import { SDKFindingEndpointType } from '../../endpoints';
import {
  appFromParam,
  metadataCountForAppFromParam,
} from '../../selectors/apps';
import {
  inquireShadowScanFindings,
  type InquireShadowScanFindingsActionParams,
} from './../../actions/shadowScanFindings';
import type { CategoryMetadataCount } from '@datatheorem/user-api/mobile_apps';
import type { Application } from '@datatheorem/user-api/mobile_apps';
import { compose } from 'recompose';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import type { ShadowscanSecurityFinding } from '@datatheorem/user-api/shadow_security_findings';
import { createShadowscanFindingsByAppIdParamSelector } from '@datatheorem/findings/shadow-scan/selectors';
import { type State } from '../../ReduxStateType';
import {
  ShadowScanSummaryCard,
  ShadowScanPreviewFindingCardList,
} from '@datatheorem/components';
import { browserHistory } from '../../clients/history';
import { type MobileApplicationChildProps } from '../../types/MobileApplicationChildProps';

type OwnProps = MobileApplicationChildProps;

type DispatchProps = {|
  dispatchInquireShadowScanFindings: typeof inquireShadowScanFindings,
|};

type StateProps = {|
  findings: $ReadOnlyArray<SDKFinding>,
  shadowScanFindings: $ReadOnlyArray<ShadowscanSecurityFinding>,
  app: ?Application,
  metadataCount: ?CategoryMetadataCount,
  paginationParams: { +[string]: void | string },
|};

type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
  ...TrackingProps,
|};

type IntermediateProps = {|
  ...OwnProps,
  ...TrackingProps,
|};

/**
 * TODO: The plan is to switch this to using `react-virtualized` at some point so that we can use the `paginate` hoc.
 * For this to work we need to make sure the network response is ordered the same way we're ordering them here so that
 * pagination is actually effective.
 */
export default compose(
  tracking(dataCreators.component(AnalyticsScreenEnum.MOBILE_APP_SDKS)),
  connect<Props, IntermediateProps, _, _, _>(
    (state: State, props): StateProps => ({
      findings: sdkFindingsByAppIdParam(state, props),
      shadowScanFindings: createShadowscanFindingsByAppIdParamSelector(
        browserHistory,
      )(state, props),
      app: appFromParam(state, props),
      metadataCount: metadataCountForAppFromParam(state, props),
      paginationParams: {
        mobile_app_id: props.match.params.appId,
      },
    }),
    {
      dispatchInquireShadowScanFindings: inquireShadowScanFindings,
    },
  ),
)(
  class MobileApplicationSDKRisks extends PureComponent<Props> {
    handleShadowScanInquireClick = (
      params: InquireShadowScanFindingsActionParams,
    ) => {
      const { dispatchInquireShadowScanFindings, app } = this.props;

      return dispatchInquireShadowScanFindings({
        ...params,
        app,
      });
    };

    render() {
      const { props, handleShadowScanInquireClick } = this;
      const {
        metadataCount,
        findings,
        paginationParams,
        shadowScanFindings,
      } = props;

      if (!metadataCount) {
        throw new Error(`Expected metadata to be loaded by this point`);
      }

      const [sdksWithIssues, sdks] = partition(
        findings,
        finding => finding.sdk_issues && finding.sdk_issues.length,
      );

      const renderEmpty =
        sdksWithIssues.length === 0 &&
        sdks.length === 0 &&
        metadataCount.SHADOW &&
        metadataCount.SHADOW.INSIGHT === 0;

      return (
        <Paginate type={SDKFindingEndpointType} params={paginationParams}>
          {({ loadedInitial }) =>
            loadedInitial ? (
              <div>
                {(sdksWithIssues.length > 0 ||
                  (!!metadataCount.SHADOW &&
                    metadataCount.SHADOW.INSIGHT > 0)) && (
                  <SDKSection title="SDK Issues">
                    {sdksWithIssues.map(finding => (
                      <ListCardSpacer key={finding.id}>
                        <SDKFindingCard finding={finding} />
                      </ListCardSpacer>
                    ))}
                    {!!metadataCount.SHADOW &&
                      metadataCount.SHADOW.INSIGHT > 0 && (
                        <ListCardSpacer>
                          <ShadowScanSummaryCard
                            type={'insight'}
                            shadowMetadataCount={metadataCount.SHADOW}
                            onClickInquire={handleShadowScanInquireClick}
                          >
                            {shadowScanFindings &&
                            shadowScanFindings.length > 0 ? (
                              <ShadowScanPreviewFindingCardList
                                findings={shadowScanFindings}
                              />
                            ) : (
                              false
                            )}
                          </ShadowScanSummaryCard>
                        </ListCardSpacer>
                      )}
                  </SDKSection>
                )}
                {sdks.length > 0 && (
                  <SDKSection title="SDKs">
                    <SDKCards findings={sdks} />
                  </SDKSection>
                )}
                {renderEmpty && <p>No SDKs found.</p>}
              </div>
            ) : (
              <LoadingFiller headings={3} />
            )
          }
        </Paginate>
      );
    }
  },
);
