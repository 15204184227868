// @flow
import React, { PureComponent } from 'react';
import { Field } from 'formik';
import { withFormik, type FormikProps } from 'formik';
import { APIKeySchema } from '@datatheorem/user-api/api_keys';
import { IconLabel, ErrorMessageBlock } from '@datatheorem/components';
import type { APIKey } from '@datatheorem/user-api/api_keys';
import type { Applications } from '@datatheorem/user-api/mobile_apps';

import { apiKeyEditFormSubmitted } from '../../actions';
import Page from '../theme/Page';
import PanelItemMobileApps from '../form/PanelItemMobileApps';
import PanelItemTextField from '../form/PanelItemTextField';
import PanelItemCopyableField from '../form/PanelItemCopyableField';
import PanelItemToggle from '../form/PanelItemToggle';
import PanelSubmitField from '../form/PanelSubmitField';
import type { Props as APIKeyFormProps } from '../main/APIKeyEdit';

type OwnProps = {|
  ...APIKeyFormProps,
  apps: Applications,
  formError: ?string,
  loading: boolean,
  submitLabel: string,
  apiKey?: ?APIKey,
|};

type Props = {| ...OwnProps, ...FormikProps<FormikValues> |};

type FormikValues = {|
  +id: string,
  +key?: string,
  +name?: string,
  +results_api?: {
    +access: boolean,
    +can_access_all_apps: boolean,
    +can_access_app_ids: $ReadOnlyArray<string>,
  },
  +management_api?: {
    +access: boolean,
  },
|};

// used to modify the behavior of setFieldValue to link fields together
export function setFieldValueHelper(name: string, value: mixed, props: Props) {
  if (!props.setFieldValue) return;
  if (name === 'results_api.access') {
    // force on/off can_access_all_apps depending on whether access is on/off
    //$FlowFixMe Flow 86 upgrade -- this is basically impossible to type
    props.setFieldValue('results_api.can_access_all_apps', value);
  }
  if (
    name === 'results_api.can_access_all_apps' &&
    props.values &&
    props.values.results_api &&
    !props.values.results_api.access
  ) {
    // if can_access_all_apps is toggled but access is not, force access to be on (doesn't do anything if access is already on)
    //$FlowFixMe Flow 86 upgrade -- this is basically impossible to type
    props.setFieldValue('results_api.access', !!value);
  }
  //$FlowFixMe Flow 86 upgrade
  props.setFieldValue(name, value);
}

export function extractResultsAPISubObjectFromValues(
  values: FormikValues,
): { access: boolean, can_access_all_apps: boolean } {
  let resultsAPISubObject = {};
  if (values && values.results_api && typeof values.results_api === 'object') {
    resultsAPISubObject.access = !!values.results_api.access;
    resultsAPISubObject.can_access_all_apps = !!values.results_api
      .can_access_all_apps;
    return resultsAPISubObject;
  }
  return { access: false, can_access_all_apps: false };
}

export default class EditForm extends PureComponent<Props> {
  render() {
    const {
      formError,
      submitLabel,
      handleSubmit,
      loading,
      values,
      apps,
      dirty,
    } = this.props;

    if (!handleSubmit) {
      throw new Error('Expected this component to be wrapped with formik');
    }

    const fieldPropFactoryFromName = name => ({
      name,
      setFieldValue: value => setFieldValueHelper(name, value, this.props),
    });

    const results_api = extractResultsAPISubObjectFromValues(values);

    return (
      <Page>
        {formError && <ErrorMessageBlock>{formError}</ErrorMessageBlock>}
        <Field
          label="Name"
          {...fieldPropFactoryFromName('name')}
          component={PanelItemTextField}
        />
        <PanelItemCopyableField
          disabled
          label="Key"
          value={values && typeof values.key === 'string' ? values.key : ''}
        />
        <Field
          {...fieldPropFactoryFromName('results_api.access')}
          toggled={
            results_api &&
            (results_api.access || results_api.can_access_all_apps)
          }
          label="Results API"
          toggleLabel="Enabled"
          component={PanelItemToggle}
        >
          <Field
            {...fieldPropFactoryFromName('results_api.can_access_all_apps')}
            label="Results API Apps"
            toggleLabel="All Apps Access"
            component={PanelItemToggle}
          />
          {results_api &&
            (results_api.access && !results_api.can_access_all_apps) && (
              <div style={{ margin: 20, maxHeight: 210, overflow: 'auto' }}>
                <Field
                  {...fieldPropFactoryFromName(
                    'results_api.can_access_app_ids',
                  )}
                  component={PanelItemMobileApps}
                  items={
                    apps &&
                    apps.map(app => ({
                      label: (
                        <IconLabel platform={app.platform}>
                          {app.name}
                        </IconLabel>
                      ),
                      value: app.id,
                    }))
                  }
                />
              </div>
            )}
        </Field>
        <Field
          {...fieldPropFactoryFromName('management_api.access')}
          label="Management API"
          toggleLabel="Enabled"
          component={PanelItemToggle}
        />
        <PanelSubmitField
          disabled={!dirty || loading}
          onSubmit={handleSubmit}
          submitLabel={submitLabel}
          loading={loading}
        />
      </Page>
    );
  }
}

//$FlowFixMe
export const FormikdEditForm = withFormik<Props, FormikValues>({
  handleSubmit(values, { props, resetForm, setSubmitting }) {
    //$FlowFixMe Flow86 upgrade
    props.dispatch(apiKeyEditFormSubmitted({ ...values, id: props.apiKey.id }));
    resetForm(values);
    setSubmitting(false);
  },
  //$FlowFixMe -- Flow upgrade
  mapPropsToValues({ apiKey }) {
    if (!apiKey) {
      return null;
    }

    return {
      results_api: {
        access: false,
        can_access_all_apps: false,
        can_access_app_ids: ([]: void | $ReadOnlyArray<string>),
      },
      management_api: {
        access: false,
      },
      ...apiKey,
    };
  },
  validationSchema: APIKeySchema,
})(EditForm);
