//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import Snackbar from 'material-ui/Snackbar';

import type { Notification } from './reducer';

const styles = {
  Notifier: {},
};

type OwnProps = {|
  notifications: $ReadOnlyArray<Notification>,
  onDismissClick: (notification: Notification) => mixed,
|};

type Props = {|
  ...OwnProps,
  ...InjectedJSS<typeof styles>,
|};

class Notifier extends PureComponent<Props> {
  render() {
    const { notifications } = this.props;
    const notification = notifications.length
      ? notifications[notifications.length - 1]
      : null;

    if (!notification) {
      return null;
    }

    return (
      <Snackbar
        open={true}
        message={notification.text}
        action={'Dismiss'}
        autoHideDuration={5000}
        onActionClick={() => this.handleClickDismiss(notification)}
        onRequestClose={() => null}
        bodyStyle={{ height: 'auto' }}
      />
    );
  }

  handleClickDismiss = (notification: ?Notification) => {
    if (!notification) {
      throw new Error('Tried to dismiss nonexistant notification');
    }

    const { onDismissClick } = this.props;
    if (onDismissClick) {
      onDismissClick(notification);
    }
  };
}

export default jss(styles)(Notifier);
