// @flow
import React from 'react';
import { Link } from 'react-router-dom';

// Components
import SearchIcon from 'material-ui/svg-icons/action/search';
import FlatButton from 'material-ui/FlatButton';
import { palette } from '@datatheorem/theme';

const searchButtonStyle = {
  lineHeight: 'normal',
  height: '30px',
  color: palette.gray40,
};

const SearchButton = () => (
  <Link to="/search">
    <FlatButton
      icon={<SearchIcon />}
      backgroundColor={palette.brand30}
      label="Search"
      style={searchButtonStyle}
    />
  </Link>
);

export default SearchButton;
