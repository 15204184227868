//@flow
import React, { useState } from 'react';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import ChangePriorityIcon from '@material-ui/icons/ArrowDropDown';
import FindingPriorityEnum, {
  type FindingPriorityEnum as FindingPriorityEnumType,
} from '@datatheorem/enums/FindingPriorityEnum';

type Props = {|
  color: ?string,
  useSecurityP1PriorityOptions: ?boolean,
  onChangePriority: (priority: FindingPriorityEnumType) => void,
|};

const ChangePriority = ({
  color,
  useSecurityP1PriorityOptions,
  onChangePriority,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const toggleMenuOpen = (event: SyntheticEvent<HTMLElement>) => {
    if (menuOpen) {
      setAnchorEl(null);
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const priorityText = (priority: FindingPriorityEnumType): string => {
    switch (priority) {
      case FindingPriorityEnum.P1:
        if (useSecurityP1PriorityOptions) {
          return 'P1 (Default)';
        } else {
          return 'P1 (Data Theorem only)';
        }
      case FindingPriorityEnum.NO_PRIORITY:
        return 'No Priority (Default)';
      default:
        return priority;
    }
  };

  const priorityDisabled = (priority: FindingPriorityEnumType): boolean => {
    switch (priority) {
      case FindingPriorityEnum.P1:
        return !useSecurityP1PriorityOptions;
      default:
        return false;
    }
  };

  const onItemClick = (
    event: SyntheticEvent<HTMLElement>,
    priority: FindingPriorityEnumType,
  ) => {
    event.preventDefault();
    if (priority) {
      onChangePriority(priority);
    } else {
      throw new Error('priority does not have a value to set');
    }
    toggleMenuOpen(event);
  };

  return (
    <>
      <IconButton
        style={{ right: 10 }}
        aria-label="Change Priority"
        aria-owns={open ? 'priority-menu' : undefined}
        aria-haspopup="true"
        onClick={e => toggleMenuOpen(e)}
      >
        <ChangePriorityIcon style={{ color: color }} />
      </IconButton>
      <Menu
        open={menuOpen}
        onClose={e => toggleMenuOpen(e)}
        anchorEl={anchorEl}
        id="priority-menu"
      >
        {Object.keys(FindingPriorityEnum)
          .filter(
            priority =>
              priority !== FindingPriorityEnum.NO_PRIORITY ||
              !useSecurityP1PriorityOptions,
          )
          .map(priority => (
            <MenuItem
              key={priority}
              value={priority}
              disabled={priorityDisabled(priority)}
              onClick={e => onItemClick(e, priority)}
            >
              {priorityText(priority)}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default React.memo<Props>(ChangePriority);
