// @flow
import React, { PureComponent } from 'react';
import FlatButton from 'material-ui/FlatButton';
import LaunchIcon from 'material-ui/svg-icons/action/launch';

import { type ShadowMetadataCountType } from '@datatheorem/user-api/mobile_apps';

import ChildCard from './../ChildCard';
import { palette } from '@datatheorem/theme';

const SHADOW_SCAN_CHILD_CARD_HEIGHT = 25;

type Props = {
  shadowMetadataCount: ?ShadowMetadataCountType,
  onPreviewClick: (id: string) => mixed,
};

class ShadowScanChildCard extends PureComponent<Props> {
  render() {
    const { shadowMetadataCount, onPreviewClick } = this.props;

    if (!shadowMetadataCount || !shadowMetadataCount.ALL) return null;

    return (
      <ChildCard
        height={SHADOW_SCAN_CHILD_CARD_HEIGHT}
        marginLeft={5}
        backgroundColor={palette.yellow50}
      >
        <p style={{ fontSize: '12px' }}>
          <span>
            You are unsubscribed to {shadowMetadataCount.ALL} security issues.
          </span>
          {(shadowMetadataCount.P1 || 0) > 0 ? (
            <span>This includes {shadowMetadataCount.P1} P1 issues.</span>
          ) : (
            <span />
          )}
        </p>

        {/* TODO: normalize into common component? */}
        <FlatButton
          labelStyle={{
            fontSize: '12px',
          }}
          style={{
            paddingLeft: 4,
            paddingRight: 4,
            lineHeight: '',
            height: '24px',
            display: 'flex',
            jusityContent: 'center',
          }}
          label="preview"
          labelPosition="before"
          icon={<LaunchIcon style={{ width: '14px' }} />}
          onClick={onPreviewClick}
        />
      </ChildCard>
    );
  }

  static getHeight = (shadowMetadataCount: ?ShadowMetadataCountType) => {
    if (!shadowMetadataCount || !shadowMetadataCount.ALL) return 0;
    return SHADOW_SCAN_CHILD_CARD_HEIGHT;
  };
}

export default ShadowScanChildCard;
