//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import { fontSizes, palette } from '@datatheorem/theme';
import { Markdown } from '@datatheorem/components';

const styles = {
  DialogSection: {
    marginTop: 50,
    position: 'relative',
  },
  title: {
    display: 'inline-block',
    margin: 0,
    marginBottom: 10,
    fontSize: fontSizes.medium,
  },
  text: {
    backgroundColor: ({ backgroundColor }: Props) =>
      backgroundColor || palette.white,
    padding: '1px 10px',
  },
  subtitle: {
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    right: 5,
    margin: 0,
    fontSize: fontSizes.medium,
  },
};

type Props = {
  title?: ?Node,
  subtitle?: ?string,
  markdown?: ?string,
  text?: ?Node,
  backgroundColor?: ?string,
  children?: Node,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class DialogSection extends PureComponent<Props> {
    render() {
      const { classes, title, subtitle, markdown, text, children } = this.props;

      return (
        <div className={classes.DialogSection}>
          {title && <span className={classes.title}>{title}</span>}
          {subtitle && <span className={classes.subtitle}>{subtitle}</span>}
          {(text || markdown) && (
            <div className={classes.text}>
              {text || <Markdown text={markdown} />}
            </div>
          )}
          {children}
        </div>
      );
    }
  },
);
