// @flow
import { put, takeEvery, call } from 'redux-saga/effects';
import { select } from '@datatheorem/redux-saga-wrapped-effects';

import { create } from '@datatheorem/user-api/support_requests';
import { Raven } from '@datatheorem/global';
import { callPromise } from '@datatheorem/session';
import {
  appProtectionInquireClicked,
  appIntegrationInquireClicked,
} from '../actions';

import { Actions as NotificationsActions } from '@datatheorem/notifications';
import { subscriptionToFriendlyString } from '../util/appUtil';
import { type Saga } from 'redux-saga';
import { type Application } from '@datatheorem/user-api/mobile_apps';
import { dataCreators } from '@datatheorem/analytics';
import tracking from '@datatheorem/analytics';
import type { State } from '../ReduxStateType';

export type InquireParams = {
  app: ?Application,
  subject: string,
  information?: string,
};

// Only reason inquire is a saga is because it needs the store to dispatch notification calls
export function* inquireEmail(payload: InquireParams): Saga<void> {
  try {
    const { subject, information } = payload;

    const state = yield* select((state: State) => state);

    const user = {
      first_name: 'N/A',
      last_name: 'N/A',
      login_email: 'N/A',
      role: 'N/A',
      ...state.currentUser,
    };

    const app = {
      name: 'N/A',
      platform: 'N/A',
      bundle_id: 'N/A',
      ...payload.app,
    };

    const body = `
    First Name: ${user.first_name}
    Last Name: ${user.last_name}
    Notification Email: ${user.notification_email || user.login_email}
    Role: ${user.role}
    App Name: ${app.name} (${app.platform})
    Bundle ID: ${app.bundle_id}
    App Subscription: ${
      app && app.subscription
        ? subscriptionToFriendlyString(app.subscription)
        : 'N/A'
    }
    ${information ? information : ''}
  `;

    yield* callPromise(create, {
      subject,
      body,
    });

    yield put(
      // $FlowFixMe Flow85
      NotificationsActions.requestNotifyUser({
        text:
          'A representative from Data Theorem will be contacting you shortly.',
        id: app && app.id,
      }),
    );
  } catch (err) {
    // Log the error...
    Raven.captureException(err, {
      extra: { msg: 'makeSupportRequest in inquire saga' },
    });

    // ... and notify the user
    yield put(
      NotificationsActions.requestNotifyUser({
        text: 'There was an error. Please contact support@datatheorem.com',
      }),
    );

    // Throw again so the saga called from is aware
    throw err;
  }
}

type AppProtectionInquireParams = {
  app: Application,
};
export function* appProtectionInquire(action: {
  payload: AppProtectionInquireParams,
}): Saga<void> {
  const { app } = action.payload;

  const information = `
      App Protection Score: ${
        app && app.app_protection_score ? app.app_protection_score : 0
      }
    `;

  yield call(inquireEmail, {
    app,
    information,
    subject: 'App Protection Inquiry',
  });

  yield call(tracking, dataCreators.appProtectionInquired());
}

type AppIntegrationInquireParams = {
  app: Application,
  panel: string,
};
export function* appIntegrationInquire(action: {
  payload: AppIntegrationInquireParams,
}): Saga<void> {
  const { app, panel } = action.payload;

  const information = `
      Integration Panel: ${panel}
    `;

  yield call(inquireEmail, {
    app,
    information,
    subject: `App Integration Inquiry - ${panel}`,
  });

  yield call(tracking, dataCreators.appIntegrationInquired());
}

export const appInquireSaga = function*(): Saga<void> {
  yield takeEvery(appProtectionInquireClicked.toString(), appProtectionInquire);
  yield takeEvery(
    appIntegrationInquireClicked.toString(),
    appIntegrationInquire,
  );
};
