//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import { groupBy } from 'lodash/fp';

import CompliancePolicyLogo from './CompliancePolicyLogo';
import { type CompliancePolicyReference } from '@datatheorem/findings/types';
import { palette } from '@datatheorem/theme';

const styles = {
  container: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  badge: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px',
    marginLeft: '.05em',
    marginRight: '.05em',
    color: palette.black,
    userSelect: 'none',
  },
};

type OwnProps = {|
  compliance_policy_references: $ReadOnlyArray<CompliancePolicyReference>,
|};

type Props = {|
  ...OwnProps,
  ...InjectedJSS<typeof styles>,
|};

class CompliancePolicyBanner extends PureComponent<Props> {
  render() {
    const {
      classes,

      compliance_policy_references,
    } = this.props;

    // Get all the possible compliance types.
    const compliancePolicyTypes = Object.keys(
      groupBy(ref => ref.compliance_policy)(compliance_policy_references || []),
    );

    return (
      <div className={classes.container}>
        {compliancePolicyTypes.map(compliancePolicy => (
          <div key={compliancePolicy} className={classes.badge}>
            <CompliancePolicyLogo policy={compliancePolicy} size="small" />
          </div>
        ))}
      </div>
    );
  }
}

export default jss(styles)(CompliancePolicyBanner);
