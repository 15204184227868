//@flow
import type { Middleware } from 'redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { type State } from './ReduxStateType';
import { JIRA_CONFIGS_LOADED_KEY } from './strings';
import {
  mobileAppTrustKit,
  finishedLoadingApps,
  jiraIntegrationConfigurationsReceived,
} from './actions';

function setFor(type, suffixCreator) {
  return (state, action) => {
    const suffix = suffixCreator ? suffixCreator(action) : '';
    const key = type + suffix;
    if (state[key]) {
      return state;
    }

    return {
      ...state,
      [key]: true,
    };
  };
}

const composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      serialize: true,
    })
  : compose;

/**
 * We do this using require() instead of import because this allows us to hot-replace them
 * @return {*}
 */
export function buildReducer() {
  // $FlowFixMe FlowUpgrade
  return combineReducers({
    accountInfo: require('@datatheorem/session').Reducers.accountInfo,
    activities: require('./reducers/activities').default,
    advancedFilter: require('./reducers/filters').default,
    searchQueries: require('./reducers/searchQueries').default,
    apiKeys: require('./reducers/apiKeys').default,
    apps: require('./reducers/apps').default,
    auth: require('@datatheorem/session').Reducers.auth,
    currentUser: require('@datatheorem/session').Reducers.currentUser,
    errors: require('./reducers/errors').default,
    insights: require('./reducers/insights').default,
    insightFindings: require('./reducers/insightFindings').default,
    loading: require('./reducers/loading').default,
    mediawatchArticles: require('./reducers/mediawatchArticles').default,
    notifications: require('@datatheorem/notifications').reducer,
    pagination: require('@datatheorem/pagination').PaginationReducer,
    profile: require('./reducers/profile').default,
    progressIndicator: require('@datatheorem/progress-indicator').Reducers
      .progressIndicatorReducer,
    firstRequest: require('@datatheorem/progress-indicator').Reducers.createFirstRequestReducer(
      {
        [mobileAppTrustKit.FULFILL]: setFor(
          'trustkit',
          action => action.payload.mobile_app_id,
        ),
        [finishedLoadingApps.toString()]: setFor('apps'),
        [jiraIntegrationConfigurationsReceived.toString()]: setFor(
          JIRA_CONFIGS_LOADED_KEY,
        ),
      },
    ),
    rowCount: require('./reducers/rowCount').default,
    sdkFindings: require('./reducers/sdkFindings').default,
    sdks: require('./reducers/sdks').default,
    securityFindings: require('./reducers/securityFindings').default,
    shadowscan: require('@datatheorem/findings/shadow-scan/reducers').default,
    strings: require('./reducers/strings').default,
    tasks: require('./reducers/tasks').default,
    topApps: require('./reducers/topApps').default,
    topAppsByCategory: require('./reducers/topAppsByCategory').default,
    trustkitAnalyses: require('./reducers/trustkitAnalyses').default,
    trustkitReports: require('./reducers/trustkitReports').default,
    users: require('./reducers/users').default,
    jiraIntegration: require('./reducers/jiraIntegration').default,
    appProtectionTaskCategories: require('./reducers/appProtectionTaskCategories')
      .default,
    preferences: require('./reducers/preferences').default,
  });
}

export function getStore(
  middleware: ?$ReadOnlyArray<Middleware<*, *>>,
  initialState?: State,
) {
  const middlewareFinal = middleware
    ? composeEnhancers(applyMiddleware(...middleware))
    : undefined;

  if (initialState) {
    // $FlowFixMe Flow85
    return createStore(buildReducer(), initialState, middlewareFinal);
  } else {
    // $FlowFixMe FlowUpgrade
    return createStore(buildReducer(), middlewareFinal);
  }
}

export type Action<T: string, D, M> = {
  type: T,
  payload?: D,
  meta?: M,
};

export type Store = $Call<typeof getStore, $ReadOnlyArray<Middleware<*, *>>>;
