//@flow
import React, { PureComponent } from 'react';

import logo from '@datatheorem/brand/logo-white.png';

const getStyles = (style = {}) => ({
  wrapper: {
    display: 'flex',
    ...style.wrapper,
  },

  logo: {
    height: 24,
    ...style.logo,
  },

  img: {
    width: 194,
    ...style.img,
  },
});

type Props = {
  style?: Object,
  onClick?: () => mixed,
};

export default class Logo extends PureComponent<Props> {
  render() {
    const { style, onClick } = this.props;
    const styles = getStyles(style);
    return (
      <div style={styles.wrapper} onClick={onClick}>
        <div style={styles.logo}>
          <img style={styles.img} src={logo} />
        </div>
      </div>
    );
  }
}
