//@flow
import createKeyable from '@datatheorem/keyable';

import {
  addCommentClicked,
  apiKeyCreateFormSubmitted,
  apiKeyCreateResponseReceived,
  apiKeyDeleteButtonClicked,
  apiKeyEditFormSubmitted,
  apiKeyEditResponseReceived,
  apiKeyDeleteResponseReceived,
  apiKeyErrorReceived,
  addCommentRequestFailed,
  addCommentRequestSucceeded,
  userDeleteClicked,
  userDeleteError,
  userDeleteSuccess,
  userEditError,
  userEditFormSubmitted,
  userEditReceived,
  userInvitedError,
  userInvitedReceived,
  userInviteFormSubmitted,
} from '../actions';
import {
  updateStatusStarted,
  updateStatusSuccess,
  updateStatusFailure,
  updateExternalIdStarted,
  updateExternalIdSuccess,
  updateExternalIdFailure,
} from '../actions/securityFindings';
import { mediawatchRoutine } from '../actions/mediawatch';

const targetStatusReducerCreator = isLoading => (state, { payload }) => {
  const { findingId, targetId } = payload;
  const arrayState = state || [];
  const currentIndex = arrayState.findIndex(
    ({ targetId: id }) => id === targetId,
  );
  if (isLoading) {
    return currentIndex !== -1
      ? arrayState
      : [...arrayState, { targetId, findingId }];
  } else {
    return currentIndex !== -1
      ? arrayState.filter((_, i) => i !== currentIndex)
      : arrayState;
  }
};

export default createKeyable({
  keys: {
    apiKeyEdit: {
      [apiKeyEditFormSubmitted.toString()]: true,
      [apiKeyErrorReceived.toString()]: false,
      [apiKeyEditResponseReceived.toString()]: false,
    },
    apiKeyDelete: {
      [apiKeyDeleteButtonClicked.toString()]: true,
      [apiKeyErrorReceived.toString()]: false,
      [apiKeyDeleteResponseReceived.toString()]: false,
    },
    apiKeyCreate: {
      [apiKeyCreateFormSubmitted.toString()]: true,
      [apiKeyErrorReceived.toString()]: false,
      [apiKeyCreateResponseReceived.toString()]: false,
    },
    userInvite: {
      [userInviteFormSubmitted.toString()]: true,
      [userInvitedError.toString()]: false,
      [userInvitedReceived.toString()]: false,
    },
    userEdit: {
      [userEditFormSubmitted.toString()]: true,
      [userEditReceived.toString()]: false,
      [userEditError.toString()]: false,
    },
    userDelete: {
      [userDeleteClicked.toString()]: true,
      [userDeleteSuccess.toString()]: false,
      [userDeleteError.toString()]: false,
    },
    addComment: {
      [addCommentClicked.toString()]: true,
      [addCommentRequestSucceeded.toString()]: false,
      [addCommentRequestFailed.toString()]: false,
    },
    targetStatus: {
      [updateStatusStarted.toString()]: targetStatusReducerCreator(true),
      [updateStatusSuccess.toString()]: targetStatusReducerCreator(false),
      [updateStatusFailure.toString()]: targetStatusReducerCreator(false),
    },
    targetExternalId: {
      [updateExternalIdStarted.toString()]: targetStatusReducerCreator(true),
      [updateExternalIdSuccess.toString()]: targetStatusReducerCreator(false),
      [updateExternalIdFailure.toString()]: targetStatusReducerCreator(false),
    },
    mediawatchArticles: {
      [mediawatchRoutine.REQUEST.toString()]: true,
      [mediawatchRoutine.FULFILL.toString()]: false,
    },
  },
});
