//@flow
import React, { PureComponent } from 'react';

import type { Target } from '@datatheorem/findings/types';

import Markdown from './../Markdown';
import PreviewInline from './../PreviewInline';

import CardStatusLine from './CardStatusLine';
import { getLatestStatusObject } from '@datatheorem/findings/targets/status';

type Props = {
  target: Target,
  preview?: boolean,
};

export default class CardStatusLineForTarget extends PureComponent<Props> {
  render() {
    const { target, preview } = this.props;

    const statusobj = getLatestStatusObject(target);

    if (!statusobj) return null;
    const { status, date } = statusobj;

    const content = (
      <Markdown
        inline
        nowrap
        text={target.formatted_text || 'The Mobile Application'}
      />
    );

    return (
      <CardStatusLine status={status} date={date}>
        {preview ? <PreviewInline>{content}</PreviewInline> : content}
      </CardStatusLine>
    );
  }
}
