// @flow
import { CompliancePolicyDetails } from '@datatheorem/components';
import React, { Fragment } from 'react';
import injectSheet from 'react-jss';
// Components
import AgeInputField from '../AgeInputField';
import FilterMultiSelectField from '../common-ui/FilterMultiSelectField';
import FilterSelectField from '../common-ui/FilterSelectField';
import FilterDatePicker from '../common-ui/FilterDatePicker';

// Types
import type { InjectedJSS } from 'react-jss';
import type { FilterStore } from '../../../reducers/filters';
import type {
  SetPriorityFilterFn,
  SetStoreBlockersFilterFn,
  SetSeverityFilterFn,
  SetCompliancePolicyFilterFn,
  SetStatusFilterFn,
  SetDateFilterFn,
  SetDateFromFilterFn,
  SetDateToFilterFn,
  SetCurrentStatusFilterFn,
  SetReleaseTypeFilterFn,
  SetMinAgeFn,
  StoreBlockersEnum,
} from '../../../actions/filterActions';
import type { FindingPriorityEnum as Priority } from '@datatheorem/enums/FindingPriorityEnum';
import type { SecurityTemplateSeverityEnum as Severity } from '@datatheorem/enums/SecurityTemplateSeverityEnum';
import type { FindingTargetStatusEnum as Status } from '@datatheorem/enums/FindingTargetStatusEnum';
import type { ReleaseType } from '@datatheorem/enums/MobileAppReleaseTypeEnum';
import type { Options, DateType } from '../../../actions/filterActions';
import type { CompliancePolicyEnum } from '@datatheorem/enums/CompliancePolicyEnum';

const priorityOptions: Options<'ANY' | Priority> = [
  { label: 'Any Priority', value: 'ANY' },
  { label: 'P0', value: 'P0' },
  { label: 'P1', value: 'P1' },
  { label: 'P2', value: 'P2' },
  { label: 'P3', value: 'P3' },
  { label: 'P4', value: 'P4' },
];

const storeBlockersOptions: Options<'ANY' | StoreBlockersEnum> = [
  { label: 'All Issues', value: 'ANY' },
  { label: 'Google Play Blockers', value: 'google' },
  { label: 'App Store Blockers', value: 'apple' },
];

const severityOptions: Options<'ANY' | Severity> = [
  { label: 'Any Severity', value: 'ANY' },
  { label: 'HIGH', value: 'HIGH' },
  { label: 'MEDIUM', value: 'MEDIUM' },
  { label: 'LOW', value: 'LOW' },
];

const compliancePolicyOptions: Options<'ANY' | CompliancePolicyEnum> = [
  { label: 'Any Compliance Policy', value: 'ANY' },
].concat(
  Object.keys(CompliancePolicyDetails).map(key => ({
    label: CompliancePolicyDetails[key].label,
    value: key,
  })),
);

const statusOptions: Options<'ANY' | Status> = [
  { label: 'Any Status', value: 'ANY' },
  { label: 'Open', value: 'OPEN' },
  { label: 'New', value: 'NEW' },
  { label: 'Closed fixed', value: 'CLOSED_FIXED' },
  { label: 'Closed risk accepted', value: 'CLOSED_RISK_ACCEPTED' },
  {
    label: 'Closed compensating control',
    value: 'CLOSED_COMPENSATING_CONTROL',
  },
  { label: 'Closed item not found', value: 'CLOSED_ITEM_NOT_FOUND' },
];

const dateRangeOptions: Options<DateType> = [
  { label: 'Any Time', value: 'ANY_TIME' },
  { label: 'Last 7 days', value: 'LAST_7_DAYS' },
  { label: 'Last 30 days', value: 'LAST_30_DAYS' },
  { label: 'Last 90 days', value: 'LAST_90_DAYS' },
  { label: 'Last quarter', value: 'LAST_QUARTER' },
  { label: 'Last year', value: 'LAST_YEAR' },
  { label: 'Custom', value: 'CUSTOM' },
];

const releaseTypeOptions: Options<ReleaseType | 'ANY'> = [
  { label: 'Any Release Type', value: 'ANY' },
  { label: 'Pre Prod', value: 'PRE_PROD' },
  { label: 'Prod', value: 'APP_STORE' },
  { label: 'Third Party', value: 'APP_STORE_THIRD_PARTY' },
];

const styles = {
  filterSelect: {
    margin: {
      right: 10,
    },
  },
  filterSelectClose: {
    margin: {
      right: 4,
    },
  },
  flexWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
};

type Props = {|
  findingsCriteria: $ElementType<FilterStore, 'findingsCriteria'>,
  setPriorityFilter: SetPriorityFilterFn,
  setStoreBlockersFilter: SetStoreBlockersFilterFn,
  setSeverityFilter: SetSeverityFilterFn,
  setCompliancePolicyFilter: SetCompliancePolicyFilterFn,
  setStatusFilter: SetStatusFilterFn,
  setDateFilter: SetDateFilterFn,
  setDateFromFilter: SetDateFromFilterFn,
  setDateToFilter: SetDateToFilterFn,
  setCurrentStatusFilter: SetCurrentStatusFilterFn,
  setReleaseType: SetReleaseTypeFilterFn,
  setMinAge: SetMinAgeFn,

  // Display filters
  displayed: {|
    currentStatusFilter: boolean,
    priorityFilter: boolean,
    storeBlockersFilter: boolean,
    severityFilter: boolean,
    releaseTypeFilter: boolean,
    compliancePolicyFilter: boolean,
    ageFilter: boolean,
    statusDuringDateRangeFilter: boolean,
  |},
  ...InjectedJSS<typeof styles>,
|};

const FindingsFilterFields = ({
  findingsCriteria,
  classes,

  setPriorityFilter,
  setStoreBlockersFilter,
  setSeverityFilter,
  setCompliancePolicyFilter,
  setStatusFilter,
  setDateFilter,
  setDateFromFilter,
  setDateToFilter,
  setCurrentStatusFilter,
  setReleaseType,
  setMinAge,

  displayed,
}: Props) => {
  return (
    <Fragment>
      <div className={classes.flexWrapper}>
        {/* Current Status */}
        {displayed.currentStatusFilter && (
          <FilterMultiSelectField
            className={classes.filterSelect}
            options={statusOptions}
            placeholder="Any Status (default)"
            label="Current Status"
            onChange={setCurrentStatusFilter}
            values={findingsCriteria.selectedCurrentStatuses}
          />
        )}

        {/* Priority */}
        {displayed.priorityFilter && (
          <FilterMultiSelectField
            className={classes.filterSelect}
            options={priorityOptions}
            placeholder="Any Priority (default)"
            label="Priority"
            onChange={setPriorityFilter}
            values={findingsCriteria.selectedPriorities}
          />
        )}

        {/* Store Blockers */}
        {displayed.storeBlockersFilter && (
          <FilterMultiSelectField
            className={classes.filterSelect}
            options={storeBlockersOptions}
            placeholder="All issues (default)"
            label="Store Blockers"
            onChange={setStoreBlockersFilter}
            values={findingsCriteria.selectedStoreBlockers}
          />
        )}

        {/* Severity */}
        {displayed.severityFilter && (
          <FilterMultiSelectField
            className={classes.filterSelect}
            options={severityOptions}
            placeholder="Any Severity (default)"
            label="Severity"
            onChange={setSeverityFilter}
            values={findingsCriteria.selectedSeverities}
          />
        )}

        {/* Compliance Policy */}
        {displayed.compliancePolicyFilter && (
          <FilterMultiSelectField
            className={classes.filterSelect}
            options={compliancePolicyOptions}
            placeholder="Any Compliance Policy (default)"
            label="Compliance Policy"
            onChange={setCompliancePolicyFilter}
            values={findingsCriteria.selectedCompliancePolicies}
          />
        )}

        {/* Release Type */}
        {displayed.releaseTypeFilter && (
          <FilterMultiSelectField
            className={classes.filterSelect}
            options={releaseTypeOptions}
            placeholder="Any Release Type (default)"
            label="Release Type"
            onChange={setReleaseType}
            values={findingsCriteria.selectedReleaseTypes}
          />
        )}

        {/* Age */}
        {displayed.ageFilter && (
          <AgeInputField
            value={findingsCriteria.minAge}
            onChange={setMinAge}
            className={classes.filterSelect}
          />
        )}

        {/* Previous Status */}
        {displayed.statusDuringDateRangeFilter && (
          <Fragment>
            <FilterMultiSelectField
              className={classes.filterSelectClose}
              options={statusOptions}
              placeholder="Any Status (default)"
              label="Status During Date Range"
              onChange={setStatusFilter}
              values={findingsCriteria.selectedStatuses.statuses}
            />

            {/* Status Date Range */}
            {findingsCriteria.selectedStatuses.dateRange.type !== 'CUSTOM' && (
              <FilterSelectField
                className={classes.filterSelect}
                options={dateRangeOptions}
                placeholder="Any Time (default)"
                label="Date Range"
                onChange={setDateFilter}
                value={findingsCriteria.selectedStatuses.dateRange.type}
              />
            )}
            {/* Status Date Range From */}
            {findingsCriteria.selectedStatuses.dateRange.type === 'CUSTOM' && (
              <FilterDatePicker
                label="Date Range From:"
                value={findingsCriteria.selectedStatuses.dateRange.from}
                max={findingsCriteria.selectedStatuses.dateRange.to}
                onChange={setDateFromFilter}
              />
            )}
            {/* Status Date Range To */}
            {findingsCriteria.selectedStatuses.dateRange.type === 'CUSTOM' && (
              <FilterDatePicker
                label="Date Range To:"
                value={findingsCriteria.selectedStatuses.dateRange.to}
                min={findingsCriteria.selectedStatuses.dateRange.from}
                onChange={setDateToFilter}
              />
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default injectSheet(styles)(FindingsFilterFields);
