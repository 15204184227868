//@flow
import { callPromise } from '@datatheorem/session';
import { call, put, spawn, takeEvery } from 'redux-saga/effects';
import {
  create,
  type MobileAppScanCredentialsParams,
} from '@datatheorem/user-api/mobile_app_scan_credentials';
import {
  appScanCredentialsUpdated,
  appScanCredentialsUpdateRequest,
  anErrorOccurred,
  uploadBinaryClicked,
} from '../actions';
import { browserHistory } from '../clients/history';
import { type Saga } from 'redux-saga';

export function* createAppScanCredentials(action: {
  payload: MobileAppScanCredentialsParams,
}): Saga<void> {
  try {
    yield* callPromise(create, action.payload);
    yield put(appScanCredentialsUpdated);
  } catch (e) {
    const { status } = e;
    const message = e.statusText ? e.statusText : e.result.error.message;
    yield put(anErrorOccurred(`An error occurred: ${status} ${message}`));
  }
}

export function* watchForCreateAppScanCredentials(): Saga<void> {
  yield takeEvery(
    appScanCredentialsUpdateRequest.toString(),
    createAppScanCredentials,
  );
}

export function* goToUploadPage(): Saga<void> {
  yield call(browserHistory.push, '/upload');
}

export function* watchForUploadBinaryClicked(): Saga<void> {
  yield takeEvery(uploadBinaryClicked.toString(), goToUploadPage);
}

export function* createAppScanCredentialsWatcher(): Saga<void> {
  yield spawn(watchForCreateAppScanCredentials);
  yield spawn(watchForUploadBinaryClicked);
}
