//@flow
import React, { PureComponent } from 'react';
import { Field, Formik } from 'formik';

import { mixed, lazy } from 'yup';
import { commonProps, JiraSeverityFieldConfigSchema } from './JiraSchemas';
import { Button } from '@datatheorem/components';
import Page from '../../theme/Page';

import { type JiraIntegrationConfig } from '@datatheorem/user-api/jira_config';

type Props = {
  handleBackSubmit: () => void,
  onSubmit: (?mixed) => void,
  submitLabel: string,
  initialValues: ?JiraIntegrationConfig,
};

// TODO: figure out why it's not showing error when validating fields
export default class JiraSeverityConfigForm extends PureComponent<Props> {
  render() {
    const {
      handleBackSubmit,
      onSubmit,
      submitLabel,
      initialValues,
    } = this.props;

    const formValues = JiraSeverityFieldConfigSchema.isValidSync(initialValues)
      ? JiraSeverityFieldConfigSchema.cast(initialValues, {
          stripUnknown: true,
        })
      : JiraSeverityFieldConfigSchema.default();

    const innerSubmit = values => {
      const valuesToPass =
        values.severity_field_config &&
        values.severity_field_config.field_id === ''
          ? { severity_field_config: null }
          : values;
      onSubmit(valuesToPass);
    };

    return (
      <Formik
        initialValues={formValues}
        onSubmit={innerSubmit}
        validationSchema={lazy(value => {
          // When the user does not want to input anything, we give them
          // the option to leave it blank
          if (
            value.severity_field_config.field_id === '' &&
            value.severity_field_config.high_severity_value_id === '' &&
            value.severity_field_config.medium_severity_value_id === '' &&
            value.severity_field_config.low_severity_value_id === ''
          )
            return mixed().notRequired();
          return JiraSeverityFieldConfigSchema;
        })}
        render={props => {
          const { handleSubmit, setFieldValue } = props;

          return (
            <Page>
              <h2>Optional</h2>
              <p>
                The Severity Config allows Data Theorem to tag security tickets
                as <i>Low</i>, <i>Medium</i>, or <i>High</i>. If you would like
                these details provided with each ticket created, then please
                provide the following fields.
              </p>
              <Field
                label="Field ID"
                {...commonProps(
                  'severity_field_config.field_id',
                  setFieldValue,
                )}
              />
              <Field
                label="High Severity Field ID"
                {...commonProps(
                  'severity_field_config.high_severity_value_id',
                  setFieldValue,
                )}
              />
              <Field
                label="Medium Severity Field ID"
                {...commonProps(
                  'severity_field_config.medium_severity_value_id',
                  setFieldValue,
                )}
              />
              <Field
                label="Low Severity Field ID"
                {...commonProps(
                  'severity_field_config.low_severity_value_id',
                  setFieldValue,
                )}
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingBottom: 10,
                  paddingTop: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                <Button
                  variant="text"
                  hoverColor={'none'}
                  rippleColor={'none'}
                  onClick={handleBackSubmit}
                  ariaLabel="Button-Back"
                >
                  Back
                </Button>
                <span style={{ width: 10 }} />
                <Button onClick={handleSubmit} ariaLabel="Button-Submit">
                  {submitLabel}
                </Button>
              </div>
            </Page>
          );
        }}
      />
    );
  }
}
