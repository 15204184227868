//@flow
import { call, put, spawn, takeEvery } from 'redux-saga/effects';
import { callPromise } from '@datatheorem/session';
import { list, targets, patch } from '@datatheorem/user-api/security_findings';
import type { SecurityFinding } from '@datatheorem/findings/types';
import { withProgressIndicator } from '@datatheorem/progress-indicator';
import { stringFromParametricRequest } from '@datatheorem/string';
import type { ActionType } from 'redux-actions';
import paginate, { paginateToEnd } from './util/paginate';
import {
  paginationMountedAction,
  paginationLoadMoreAction,
  paginationLoadedInitialAction,
  paginationBeginRequest,
  paginationEndRequest,
} from '@datatheorem/pagination';
import { searchStartedAction } from '../actions/searchActions';
import { reportsPageLoadedAction } from '../actions/reportsActions';

import {
  updateFindings,
  exportButtonClicked,
  sortUtilButtonClicked,
  complianceFilterClicked,
} from '../actions';
import {
  updateStatusSuccess,
  updateStatus as updateStatusAction,
  changePriority,
  updateStatusStarted,
  updateStatusFailure,
  exportTargetJiraClicked,
  updateExternalIdSuccess,
  updateExternalIdStarted,
  updateExternalIdFailure,
} from '../actions/securityFindings';
import { downloadZip } from '../services/reporting/reportwriter-server';
import { setString } from '../reducers/strings';
import { SecurityFindingEndpoint } from '../endpoints';
import { apps as appsSelector } from '../selectors/apps';
import type { FindingTargetStatusEnum } from '@datatheorem/enums/FindingTargetStatusEnum';
import { makeFindingsByAppIdParamSelector } from '../selectors/securityFindings';
import { type Saga } from 'redux-saga';
import { select } from '@datatheorem/redux-saga-wrapped-effects';
import tracking, { dataCreators } from '@datatheorem/analytics';
import { setComplianceSearchFilter } from '@datatheorem/findings/findingsFilterCreator';
import { browserHistory } from './../clients/history';
import type { State } from '../ReduxStateType';
import { callSaga } from '@datatheorem/redux-saga-wrapped-effects';

export function* loadFindings(params: { +[key: string]: ?string }): Saga<void> {
  yield* withProgressIndicator(function*() {
    try {
      const response = yield* callSaga(
        paginate,
        SecurityFindingEndpoint,
        params,
        params => callPromise(list, params),
      );
      if (response.security_findings) {
        yield put(updateFindings(response.security_findings));
        return response;
      }
    } catch (err) {
      console.error(err.stack);
    }
  }, stringFromParametricRequest(SecurityFindingEndpoint, params));
}

export function* loadAllFindings(params: {} = {}): Saga<void> {
  yield* paginateToEnd(loadFindings, SecurityFindingEndpoint, params, params);
}

export function* watchForPaginationLoadingRequests(): Saga<void> {
  yield takeEvery(
    [paginationMountedAction.toString(), paginationLoadMoreAction.toString()],
    function*(
      action:
        | ActionType<typeof paginationMountedAction>
        | ActionType<typeof paginationLoadMoreAction>,
    ): Saga<void> {
      if (action.meta && action.meta.type === SecurityFindingEndpoint) {
        yield put(
          paginationBeginRequest(SecurityFindingEndpoint, action.payload),
        );
        const response = yield call(loadFindings, action.payload);
        yield put(
          paginationEndRequest(
            SecurityFindingEndpoint,
            action.payload,
            response && response.pagination_information,
          ),
        );
        yield put(
          paginationLoadedInitialAction(
            SecurityFindingEndpoint,
            action.payload,
          ),
        );
      }
    },
  );
}

export function* watchForSearch(): Saga<void> {
  yield takeEvery(searchStartedAction.toString(), function*(): Saga<void> {
    yield call(loadAllFindings);
  });
}

export function* watchForReportsPageView(): Saga<void> {
  yield takeEvery(reportsPageLoadedAction.toString(), function*(): Saga<void> {
    yield call(loadAllFindings);
  });
}

export function* securityFindingsWatchers(): Saga<void> {
  yield spawn(watchForPaginationLoadingRequests);
  yield spawn(watchForSearch);
  yield spawn(watchForComplianceFilterClicked);
  yield spawn(watchForStatusUpdate);
  yield spawn(watchForPriorityChange);
  yield spawn(watchForExportRequests);
  yield spawn(watchForSortUtilButtonClicked);
  yield spawn(watchForReportsPageView);
  yield spawn(watchForExternalIdUpdate);
}

export function* updateExternalId(
  findingId: string,
  targetId: string,
): Saga<void> {
  yield put(updateExternalIdStarted(findingId, targetId));
  try {
    // This endpoint returns the entire finding
    const returnedFinding = yield* callPromise(
      targets.jira_ticket.create,
      findingId,
      targetId,
    );
    yield put(updateExternalIdSuccess(findingId, targetId, returnedFinding));
  } catch (e) {
    if (e.result && e.result.error) {
      const { message } = e.result.error;
      yield put(updateExternalIdFailure(findingId, targetId, message));
      return;
    } else throw e;
  }
}

export function* watchForExternalIdUpdate(): Saga<void> {
  yield takeEvery(exportTargetJiraClicked.toString(), function*(action: {
    payload: {
      securityFindingId: string,
      targetId: string,
    },
  }): Saga<void> {
    const { securityFindingId, targetId } = action.payload;
    yield call(updateExternalId, securityFindingId, targetId);
  });
}

export function* updateStatus(
  findingId: string,
  targetId: string,
  newStatus: FindingTargetStatusEnum,
): Saga<void> {
  yield put(updateStatusStarted(findingId, targetId));
  try {
    const status = yield* callPromise(
      targets.statuses.create,
      findingId,
      targetId,
      { status: newStatus },
    );

    yield put(updateStatusSuccess(findingId, targetId, status));
  } catch (e) {
    if (e.result && e.result.error && e.result.error.code === 409) {
      // conflict, probably last call timed out so we had to revert back but server did the action and the user tried again
      // so now we need to fix the client
      yield put(
        updateStatusSuccess(findingId, targetId, {
          status: newStatus,
          date: new Date().toString(),
        }),
      );
      return;
    } else if (e.result && e.result.error && e.result.error.code) {
      // some other network error
      yield put(updateStatusFailure(findingId, targetId));
    } else {
      throw e;
    }
  }

  yield call(tracking, dataCreators.targetClosed(newStatus));
}

export function* watchForStatusUpdate(): Saga<void> {
  yield takeEvery(updateStatusAction.toString(), function*(action: {
    payload: {
      securityFindingId: string,
      targetId: string,
      newStatus: FindingTargetStatusEnum,
    },
  }): Saga<void> {
    const { securityFindingId, targetId, newStatus } = action.payload;
    yield call(updateStatus, securityFindingId, targetId, newStatus);
  });
}

export function* watchForPriorityChange(): Saga<void> {
  yield takeEvery(changePriority.toString(), function*(action: {
    type: string,
    payload: { priority: string, finding: SecurityFinding },
  }): Saga<void> {
    const { priority, finding } = action.payload;
    yield* withProgressIndicator(function*(): Saga<void> {
      // $FlowFixMe FlowUpgrade
      const newFinding = yield* callPromise(patch, finding.id, {
        priority,
      });
      if (newFinding && newFinding.id === finding.id) {
        yield put(updateFindings([newFinding]));
      }

      yield call(
        tracking,
        dataCreators.priorityChange(finding.priority, priority),
      );
    });
  });
}

export function* watchForExportRequests(): Saga<void> {
  yield takeEvery(exportButtonClicked.toString(), performExport);
}

export function* performExport(action: {
  type: string,
  payload: string,
}): Saga<void> {
  const apps = yield* select(appsSelector);
  const app = apps.find(app => app.id === action.payload);

  if (!app) {
    throw new Error('Could not find app');
  }

  yield call(loadAllFindings, { mobile_app_id: app.id });

  const selectorFindingsByAppIdParam = makeFindingsByAppIdParamSelector();

  const findings = yield* select(selectorFindingsByAppIdParam, {
    match: { params: { appId: app.id } },
  });

  const { currentUser, accountInfo } = yield* select((state: State) => state);

  if (!currentUser) {
    throw new Error('expected currentuser email');
  }

  const email = currentUser.login_email;

  yield* callPromise(downloadZip, {
    ...app,
    security_finding_list: findings,
    email: email,
    customer_name: accountInfo && accountInfo.name,
  });

  yield call(tracking, dataCreators.exportPerformed());
}

export function* watchForSortUtilButtonClicked(): Saga<void> {
  yield takeEvery(sortUtilButtonClicked.toString(), setSortOrder);
}

export function* watchForComplianceFilterClicked(): Saga<void> {
  yield takeEvery(complianceFilterClicked.toString(), function*(action: {
    type: string,
    payload: { compliancePolicies: $ReadOnlyArray<string> },
  }): Saga<void> {
    const { compliancePolicies } = action.payload;
    setComplianceSearchFilter(browserHistory, compliancePolicies);
    yield put(
      setString('compliancePolicyFilter', compliancePolicies.join(',')),
    );
    yield call(loadAllFindings, { compliance_policy: compliancePolicies });
  });
}

export function* setSortOrder(action: {
  type: string,
  payload: { sortString: string, params: {} },
}): Saga<void> {
  const { params, sortString } = action.payload;
  yield call(loadAllFindings, params);
  yield put(setString('sortString', sortString));
  yield call(
    tracking,
    dataCreators.findingsSorted(sortString, sortString.indexOf('-') === -1),
  );
}
