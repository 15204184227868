//@flow
import React, { Component, type Node } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { default as MuiThemeProviderOld } from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import theme, { materialTheme } from '@datatheorem/theme';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { browserHistory } from '../clients/history';
import App from './main/App';
import type { Store } from '../redux';
import { TrackingProvider } from '@datatheorem/analytics';

const muiTheme = createMuiTheme(materialTheme());
type Props = {|
  store: Store,
|};

export const Providers = function Providers(props: {
  store: Store,
  children: Node,
}) {
  const { store } = props;
  return (
    <Provider store={store}>
      {/* for material-ui@0.20.0 */}
      <MuiThemeProviderOld muiTheme={getMuiTheme(theme())}>
        {/* for material-ui@1.0.0> */}
        <MuiThemeProvider theme={muiTheme}>
          <TrackingProvider>{props.children}</TrackingProvider>
        </MuiThemeProvider>
      </MuiThemeProviderOld>
    </Provider>
  );
};

export default class Container extends Component<Props> {
  render() {
    return (
      <Providers store={this.props.store}>
        <Router history={browserHistory}>
          <Switch>
            <Route component={App} />
          </Switch>
        </Router>
      </Providers>
    );
  }
}
