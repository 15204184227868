//@flow
import { createSelector } from 'reselect';
import UserRoleEnum from '@datatheorem/enums/UserRoleEnum';
import type { User } from '@datatheorem/user-api/users';
import type { State } from '../ReduxStateType';

type UserIdParam = { +match: { +params: { +userId: string | void } } };

const userId = (state, props) => props.match.params.userId;
const users = state => state.users;

export const profile = (state: State) => state.profile;

export const userFromParam = createSelector<State, UserIdParam, ?User, _, _>(
  users,
  userId,

  (users, userId) => (users ? users.find(user => user.id === userId) : null),
);

export const canUpdateStatus = createSelector<State, {}, ?boolean, _>(
  profile,

  profile =>
    profile &&
    [
      UserRoleEnum.SECURITY,
      UserRoleEnum.SECURITY_ALL_APPS,
      UserRoleEnum.MANAGER,
    ].includes(profile.role),
);

export const canReopenStatus = createSelector<State, {}, ?boolean, _>(
  profile,

  profile =>
    profile &&
    [
      UserRoleEnum.SECURITY,
      UserRoleEnum.SECURITY_ALL_APPS,
      UserRoleEnum.MANAGER,
    ].includes(profile.role),
);
