//@flow
import createKeyable from '@datatheorem/keyable';

import {
  apiKeyEditResponseReceived,
  apiKeyErrorReceived,
  userEditError,
  userEditReceived,
  userInvitedError,
  userInvitedReceived,
  apiKeyCreateResponseReceived,
} from '../actions';
import { mediawatchRoutine } from '../actions/mediawatch';
import {
  updateExternalIdStarted,
  updateExternalIdFailure,
  securityFindingOpened,
} from '../actions/securityFindings';

const payload = (_, { payload }) => payload;

const externalIdFailureHandler = (state, { payload }) => {
  const { message } = payload;
  return message;
};
export default createKeyable({
  keys: {
    apiKeyEdit: {
      [apiKeyErrorReceived.toString()]: payload,
      [apiKeyEditResponseReceived.toString()]: null,
    },
    apiKeyCreate: {
      [apiKeyErrorReceived.toString()]: payload,
      [apiKeyCreateResponseReceived.toString()]: null,
    },
    userInvite: {
      [userInvitedError.toString()]: payload,
      [userInvitedReceived.toString()]: null,
    },
    userEdit: {
      [userEditError.toString()]: payload,
      [userEditReceived.toString()]: null,
    },
    externalIdCreation: {
      [securityFindingOpened.toString()]: null,
      [updateExternalIdStarted.toString()]: null,
      [updateExternalIdFailure.toString()]: externalIdFailureHandler,
    },
    mediawatch: {
      [mediawatchRoutine.FULFILL.toString()]: null,
      [mediawatchRoutine.FAILURE.toString()]: payload,
    },
  },
});
