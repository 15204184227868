//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

import Badge from './../../Badge';

import IntegrationBadgeType from './IntegrationBadgeTypeEnum';
import type { IntegrationBadgeTypeEnum } from './IntegrationBadgeTypeEnum';

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'space-around',
    paddingRight: 10,
    paddingLeft: 10,
  },

  badge: {
    marginLeft: 5,
    marginRight: 5,
  },
};

type Props = {
  badgeTypes: Array<[IntegrationBadgeTypeEnum, ?boolean | number]>,
} & InjectedJSS<typeof styles>;

class IntegrationStats extends PureComponent<Props> {
  static defaultProps = {
    badgeTypes: [
      [IntegrationBadgeType.CI_CD, false],
      [IntegrationBadgeType.TRUSTKIT, false],
      [IntegrationBadgeType.ISSUE_TRACKER, false],
    ],
  };

  IntegrationBadgeTypeLabel = {
    [IntegrationBadgeType.CI_CD]: 'CI | CD',
    [IntegrationBadgeType.TRUSTKIT]: 'Trustkit',
    [IntegrationBadgeType.ISSUE_TRACKER]: 'Issue Tracker',
  };

  _getEnabled = value => {
    if (typeof value === 'number') {
      return value === 100;
    }

    return value;
  };

  render() {
    const {
      props,

      IntegrationBadgeTypeLabel,
      _getEnabled,
    } = this;
    const { classes, badgeTypes } = props;

    return (
      <div className={classes.container}>
        {/* TODO@nw: Badge values should return a percentage badge of sorts for a number value. */}
        {badgeTypes.map(([type, value], i) => (
          <div key={i} className={classes.badge}>
            <Badge
              inline
              label={IntegrationBadgeTypeLabel[type]}
              backgroundColor={!_getEnabled(value) ? 'lightgray' : '#2AAF5A'}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default jss(styles)(IntegrationStats);
