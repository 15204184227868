//@flow
import React, { PureComponent } from 'react';
import merge from 'lodash/merge';
import { MenuItem } from '@material-ui/core';
import { MenuButton, CompliancePolicyFilter } from '@datatheorem/components';
import { palette } from '@datatheorem/theme';

const utilInputContainer = {
  flexBasis: 0,
  flexGrow: 1,
};

function getStyles(style = {}) {
  return merge(
    {
      container: {
        display: 'inline-flex',
        backgroundColor: palette.bgColor,
      },
      complianceFilter: {},
      utilSearch: {
        ...utilInputContainer,
        flexGrow: 2,
      },
      utilSearchInput: {
        container: {},
      },
      utilExport: {
        ...utilInputContainer,
      },
      utilClosed: {
        ...utilInputContainer,
      },
      searchIcon: {
        height: 12,
        verticalAlign: 'text-top',
      },
      downloadIcon: {
        width: 16,
        marginLeft: 5,
      },
    },
    style,
  );
}

type Props = {
  style?: any,
  onChangeShowHideClosed: (value: boolean) => mixed,
  onChangeComplianceSearchFilter: (
    compliancePolicies: $ReadOnlyArray<string>,
  ) => void,
  filterValue: boolean,
  compliancePolicyFilter: $ReadOnlyArray<string>,
  showCompliancePolicyFilter: boolean,
  onClickExport?: ?() => void,
  onClickSort?: (field: string) => mixed,
  showDoMore: boolean,
};

class SecurityFindingsUtil extends PureComponent<Props> {
  render() {
    const {
      style,
      filterValue,
      compliancePolicyFilter,
      showCompliancePolicyFilter,
      showDoMore,

      onClickExport,
      onClickSort,
      onChangeShowHideClosed,
      onChangeComplianceSearchFilter,
    } = this.props;

    const styles = getStyles(style);

    return (
      <div>
        <div style={styles.container}>
          <div style={styles.complianceFilter}>
            {/*
               NOTE: compliance policy filters should potentially be on all findings views.

               Executes on '/issues/compliance' view only.
            */}
            {showCompliancePolicyFilter && (
              <CompliancePolicyFilter
                onChangeComplianceSearchFilter={onChangeComplianceSearchFilter}
                policies={compliancePolicyFilter}
              />
            )}
          </div>

          {showDoMore && (
            <div style={styles.utilClosed}>
              <MenuButton label={<span>Do More</span>}>
                {({ onClick }) => [
                  <MenuItem
                    key={0}
                    onClick={() => {
                      onClick();
                      onChangeShowHideClosed(!filterValue);
                    }}
                  >
                    {filterValue ? 'Hide' : 'Show'} closed issues
                  </MenuItem>,
                  onClickExport && (
                    <MenuItem
                      key={1}
                      onClick={() => {
                        onClick();
                        onClickExport();
                      }}
                    >
                      Export
                    </MenuItem>
                  ),
                  onClickSort && (
                    <MenuItem
                      key={2}
                      onClick={() => {
                        onClick();
                        onClickSort('-computed_aging');
                      }}
                    >
                      Sort by oldest
                    </MenuItem>
                  ),
                  onClickSort && (
                    <MenuItem
                      key={3}
                      onClick={() => {
                        onClick();
                        onClickSort('+computed_aging');
                      }}
                    >
                      Sort by newest
                    </MenuItem>
                  ),
                  onClickSort && (
                    <MenuItem
                      key={4}
                      onClick={() => {
                        onClick();
                        onClickSort('-severity');
                      }}
                    >
                      Sort by severity
                    </MenuItem>
                  ),
                  onClickSort && (
                    <MenuItem
                      key={5}
                      onClick={() => {
                        onClick();
                        onClickSort('+priority');
                      }}
                    >
                      Sort by priority
                    </MenuItem>
                  ),
                ]}
              </MenuButton>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SecurityFindingsUtil;
