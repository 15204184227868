//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

const styles = {
  PreviewInline: { filter: 'blur(5px)' },
};

type Props = {
  children: Node,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class PreviewInline extends PureComponent<Props> {
    render() {
      const { classes, children } = this.props;

      return <span className={classes.PreviewInline}>{children}</span>;
    }
  },
);
