//@flow
import React, { PureComponent, type Node } from 'react';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import HelpIcon from 'material-ui/svg-icons/action/help-outline';
import { type Location } from 'history/createBrowserHistory';
import {
  AppSecureIcon,
  AppSearchIcon,
  BrandProtectIcon,
  APIDiscoverIcon,
} from '@datatheorem/brand';
import config from '@datatheorem/config';
import Header from './Header';
import PrimaryNavItem from './PrimaryNavigationItem';
import Logo from './Logo';
import { palette } from '@datatheorem/theme';
import Badge from 'material-ui/Badge';
import BroadcastMessage from './BroadcastMessage';
import SearchButton from './SearchButton';
import DocumentMeta from 'react-document-meta';

if (typeof config.clonewatchURL !== 'string') {
  throw new Error('Expected config to contain a clonewatchURL string');
}

// You can ENABLE  the broadcast message by changing nulls to strings
// You can DISABLE the broadcast message by changing strings to nulls
const BROADCAST = {
  message: null,
  href: null,
};

const clonewatchURL = config.clonewatchURL;

const getStyles = () => ({
  container: {
    backgroundColor: palette.bgColor,
    paddingTop: 50,
  },
  header: {
    wrapper: {
      position: 'fixed',
      paddingLeft: 24,
      backgroundColor: palette.brand30,
    },
    primaryNavigationWrapper: {
      marginLeft: 40,
    },
  },
  logo: {
    wrapper: {
      cursor: 'pointer',
    },
  },
  navlink: {
    active: { color: palette.white },
    container: { color: palette.gray40 },
  },
});

type Props = {
  onClickSignOut: () => mixed,
  showActivity: boolean,
  showSearch?: boolean,
  children: Node,
  whatsNewCount: number,
  onNavigationRequested: (path: string | Location) => mixed,
  returnLocation: string | Location,
  activeProduct: 'app-secure' | 'app-search' | 'brand-protect' | 'api',
};

export default class Application extends PureComponent<Props> {
  container: ?HTMLElement;

  render() {
    const {
      showActivity,
      children,
      showSearch,
      whatsNewCount,
      onNavigationRequested,
      onClickSignOut,
      activeProduct,
    } = this.props;

    const styles = getStyles();

    const settingsMenu = (
      <IconMenu
        key={35}
        iconButtonElement={
          <IconButton>
            <SettingsIcon color={palette.white} />
          </IconButton>
        }
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        targetOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <MenuItem
          primaryText={
            <Badge
              badgeContent={whatsNewCount}
              style={{ padding: 0 }}
              badgeStyle={{ top: 10, right: -30 }}
              secondary
            >
              What&apos;s New
            </Badge>
          }
          onClick={() => onNavigationRequested('/changelog')}
        />
        {showActivity && (
          <MenuItem
            primaryText="Activity"
            onClick={() => onNavigationRequested('/activity')}
          />
        )}
        <MenuItem
          primaryText="Manage Users"
          onClick={() => onNavigationRequested('/users')}
        />
        <MenuItem
          primaryText="Profile"
          onClick={() => onNavigationRequested('/profile')}
        />
        <MenuItem
          primaryText="Alerts"
          onClick={() => onNavigationRequested('/preferences')}
        />
        <MenuItem
          primaryText="DevSecOps"
          onClick={() => onNavigationRequested('/sdlc')}
        />
        <MenuItem
          primaryText="Help &amp; Support"
          rightIcon={<HelpIcon />}
          onClick={() =>
            window.open('https://support.securetheorem.com/', '_blank')
          }
        />
        <MenuItem primaryText="Sign out" onClick={onClickSignOut} />
      </IconMenu>
    );

    const currBroadCastMessage = BROADCAST.message && (
      <BroadcastMessage href={BROADCAST.href}>
        {BROADCAST.message}
      </BroadcastMessage>
    );

    return (
      <div style={styles.container} ref={node => (this.container = node)}>
        {currBroadCastMessage}
        <Header
          style={styles.header}
          primaryNavigationItems={[
            <PrimaryNavItem
              key={0}
              to="/"
              style={styles.navlink}
              active={activeProduct === 'app-secure'}
            >
              <AppSecureIcon active={activeProduct === 'app-secure'} />
              App<br />Secure
            </PrimaryNavItem>,
            <PrimaryNavItem
              key={1}
              to="/openscan/"
              style={styles.navlink}
              active={activeProduct === 'app-search'}
            >
              <AppSearchIcon active={activeProduct === 'app-search'} />
              App<br />Search
            </PrimaryNavItem>,
            <PrimaryNavItem
              key={2}
              to={clonewatchURL}
              style={styles.navlink}
              active={activeProduct === 'brand-protect'}
            >
              <BrandProtectIcon active={activeProduct === 'brand-protect'} />
              Brand<br />Protect
            </PrimaryNavItem>,
            <PrimaryNavItem
              key={3}
              to="/api/"
              style={styles.navlink}
              active={activeProduct === 'api'}
            >
              <APIDiscoverIcon active={activeProduct === 'api'} />
              API&nbsp;Discover<br />API&nbsp;Inspect
            </PrimaryNavItem>,
          ]}
          secondaryNavigationItems={[
            showSearch && <SearchButton key={2213912321396} />,
            whatsNewCount > 0 ? (
              <Badge
                key={30}
                badgeContent={whatsNewCount}
                style={{ padding: 0 }}
                secondary
              >
                {settingsMenu}
              </Badge>
            ) : (
              settingsMenu
            ),
          ]}
        >
          <DocumentMeta
            title={`${
              {
                'app-secure': 'App Secure',
                'app-search': 'App Search',
                'brand-protect': 'Brand Protect',
                api: 'API Inspect & Discover',
              }[activeProduct]
            } | DataTheorem`}
          />

          <Logo
            style={styles.logo}
            onClick={() => onNavigationRequested('/issues')}
          />
        </Header>
        {children}
      </div>
    );
  }
}
