//@flow
import { request } from './gapi';
import { result } from './util';
import { type PaginatedResponse } from './_common';

export type AffectedMobileAppsInfo = {
  moible_app_id?: string,
  security_finding_ids?: $ReadOnlyArray<?string>,
};

export type MediawatchArticle = {
  affected_mobile_apps_info?: $ReadOnlyArray<AffectedMobileAppsInfo>,
  description?: string,
  id?: string,
  marked_as_read: boolean,
  title?: string,
  published_time?: string, // Date ISO 8601: "2017-01-31T14:09:07.313240"
  site_name?: string,
  url?: string,
};

type ListParams = {|
  +cursor?: string,
  +impacted_mobile_app_id?: string,
  +impacted_security_finding_id?: string,
  +impacts_at_least_one_mobile_app?: string,
|};

type ListResponse = PaginatedResponse<{
  mediawatch_articles: $ReadOnlyArray<MediawatchArticle>,
}>;

type PatchArticleParams = {
  marked_as_read: boolean,
};

export const articles = {
  async list(params: ListParams): Promise<ListResponse> {
    return request({
      path: `userapi/v2/mediawatch/articles`,
      params: params,
    }).then(result);
  },

  async get(id: string): Promise<MediawatchArticle> {
    if (!id || !id.length) {
      throw new Error('Invalid media watch article ID provided');
    }
    return request({ path: `userapi/v2/mediawatch/articles/${id}` }).then(
      result,
    );
  },

  async patch(
    id: string,
    params: PatchArticleParams,
  ): Promise<MediawatchArticle> {
    if (!id || !id.length) {
      throw new Error('Invalid media watch article ID provided');
    }
    if (!params) {
      throw new Error('marked_as_read param is required');
    }

    return request({
      path: `userapi/v2/mediawatch/articles/${id}`,
      method: 'PATCH',
      params: params,
    }).then(result);
  },
};
