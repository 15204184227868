//@flow
import { call, put, spawn, takeEvery, all, take } from 'redux-saga/effects';

import { Actions as SessionActions } from '@datatheorem/session';

import paginate, { paginateToEnd } from './util/paginate';
import { callPromise } from '@datatheorem/session';
import type { ListParams } from '@datatheorem/user-api/shadow_security_findings';
import { list } from '@datatheorem/user-api/shadow_security_findings';
import { lastPageReceived } from '../actions';
import { shadowscanFindingsReceived } from '@datatheorem/findings/shadow-scan/actions';
import { inquireShadowScanFindings } from './../actions/shadowScanFindings';
import { previewShadowScanFindingsForApp } from './../actions/shadowScanFindings';
import type { Application } from '@datatheorem/user-api/mobile_apps';
import { SecurityFindingEndpoint, ShadowScanEndpoint } from '../endpoints';
import { Selectors as SessionSelectors } from '@datatheorem/session';
import { type Saga } from 'redux-saga';
import { select } from '@datatheorem/redux-saga-wrapped-effects';
import tracking, { dataCreators } from '@datatheorem/analytics';
import { inquireEmail } from './inquire';
import { callSaga } from '@datatheorem/redux-saga-wrapped-effects';

const lastPageActionForSecurityFindingsWithParams = action =>
  action.type === lastPageReceived.toString() &&
  !!action.payload &&
  action.payload.type === SecurityFindingEndpoint &&
  !!action.payload.params &&
  typeof action.payload.params === 'object';

export function* shadowscanFlow(): Saga<void> {
  yield spawn(watchForPreviewShadowScanFindingsForApp);
  yield spawn(watchForSecurityFindingPaginationReachedLastPage);
  yield spawn(watchForInquireShadowScanFindings);
}

export function* watchForPreviewShadowScanFindingsForApp(): Saga<void> {
  yield take(previewShadowScanFindingsForApp.toString());
  yield call(loadAllFindings, {});
}

export function* watchForSecurityFindingPaginationReachedLastPage(): Saga<void> {
  let [lastPageAction] = yield all([
    take(lastPageActionForSecurityFindingsWithParams),
    take(SessionActions.accountInfoReceived.toString()),
  ]);

  if (
    !(yield* select(SessionSelectors.hasScanAndSecure, {})) &&
    (yield* select(SessionSelectors.hasOpenscan, {}))
  ) {
    return;
  }

  do {
    yield call(loadAllFindings, lastPageAction.payload.params);
  } while (
    (lastPageAction = yield take(lastPageActionForSecurityFindingsWithParams))
  );
}

export function* loadAllFindings(params: ListParams): Saga<void> {
  yield* paginateToEnd(loadFindings, ShadowScanEndpoint, params, params);
}

export function* loadFindings(params: ListParams): Saga<void> {
  try {
    const response = yield* callSaga(
      paginate,
      ShadowScanEndpoint,
      params,
      params => callPromise(list, params),
    );
    if (response && response.security_findings) {
      yield put(shadowscanFindingsReceived(response.security_findings));
    }
  } catch (err) {
    // TODO
  }
}

function* watchForInquireShadowScanFindings(): Saga<void> {
  yield takeEvery(inquireShadowScanFindings.toString(), makeSupportRequest);
}

type ShadowScanInquiryParams = {
  title?: ?string,
  count?: ?number,
  breakdown?: ?Object,
  app?: ?Application,
};

export function* makeSupportRequest(action: {
  payload: ShadowScanInquiryParams,
}): Saga<void> {
  const { app, title, count, breakdown } = action.payload;

  let bdown = {
    security: 'N/A',
    apple: 'N/A',
    google: 'N/A',
    high: 'N/A',
    medium: 'N/A',
    low: 'N/A',
    ...breakdown,
  };

  const { security, google, apple, high, medium, low } = bdown;

  const information = `
      Clicked Card: ${title || 'N/A'}
      Number of Shadowscan Findings For That Card: ${count || 'N/A'}
      Security P1: ${security}
      App Store: ${apple}
      Play Store: ${google}
      High: ${high}
      Medium: ${medium}
      Low: ${low}
    `;

  yield call(inquireEmail, {
    app,
    information,
    subject: 'ShadowScan Summary Card',
  });

  yield call(tracking, dataCreators.shadowscanInquired());
}
