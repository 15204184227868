//@flow
import { put, spawn, takeEvery } from 'redux-saga/effects';
import { callPromise } from '@datatheorem/session';
import { list, getInsights } from '@datatheorem/user-api/insights';
import { withProgressIndicator } from '@datatheorem/progress-indicator';
import { type Saga } from 'redux-saga';
import {
  appInsightsReceived,
  getAppInsights,
  updateInsightFindings,
} from '../actions';

export function* insightsWatchers(): Saga<void> {
  yield spawn(getMobileAppInsights);
}

export function* getMobileAppInsights(): Saga<void> {
  yield takeEvery(getAppInsights.toString(), function*(action: {
    type: string,
    payload: string,
  }): Saga<void> {
    yield* withProgressIndicator(function*() {
      // TODO: (rr) To be deprecated in favor of the get insights endpoint below
      const appInsightFindingsResponse = yield* callPromise(list, {
        mobile_app_id: action.payload,
      });

      // (rr) Supercedes the above call for insights
      const appInsightsResponse = yield* callPromise(
        getInsights,
        action.payload,
      );

      if (appInsightFindingsResponse) {
        yield put(updateInsightFindings(appInsightFindingsResponse));
      }

      if (appInsightsResponse) {
        yield put(appInsightsReceived(appInsightsResponse));
      }
    });
  });
}
