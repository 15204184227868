// @flow
import React, { PureComponent } from 'react';
import { compose } from 'recompose';
import tracking from 'react-tracking';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { size } from 'lodash/fp';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';

import UserRoleEnum from '@datatheorem/enums/UserRoleEnum';

// Components
import { PageHeading } from '@datatheorem/components';
import { Button } from '@material-ui/core';
import SavedFilterTable from '../advanced-filter/SavedFilterTable';

// Icons
import AddIcon from '@material-ui/icons/Add';

// Actions
import {
  addFilter,
  removeFilter,
  setAsCurrentFilter,
  requestFiltersFromServer,
  requestFiltersFromStorage,
  setGlobalFilter,
} from '../../actions/filterActions';
import { reportsPageLoadedAction } from '../../actions/reportsActions';

// Types
import type { DashboardInjectedProps } from '../../types/DashboardInjectedProps';
import type {
  AddFilterFn,
  RemoveFilterFn,
  SetAsCurrentFilterFn,
  RequestFiltersFromServerFn,
  RequestFiltersFromStorageFn,
  SetGlobalFilterFn,
} from '../../actions/filterActions';
import type { FilterStore } from '../../reducers/filters';
import type { SecurityFinding } from '@datatheorem/findings/types';
import type { State as ReduxState } from '../../ReduxStateType';

type OwnProps = DashboardInjectedProps;

type DispatchProps = {|
  dispatchAddFilter: AddFilterFn,
  dispatchRemoveFilter: RemoveFilterFn,
  dispatchSetAsCurrentFilter: SetAsCurrentFilterFn,
  dispatchRequestFiltersFromServer: RequestFiltersFromServerFn,
  dispatchRequestFiltersFromStorage: RequestFiltersFromStorageFn,
  dispatchReportsPageLoadedAction: () => { type: 'REPORTS_PAGE_LOADED' },
  dispatchSetSharedFilter: SetGlobalFilterFn,
|};

type StateProps = {|
  securityFindings: $ReadOnlyArray<SecurityFinding>,
  advancedFilter: FilterStore,
  canEditPublicFilters: boolean,
|};

type Props = {| ...OwnProps, ...StateProps, ...DispatchProps |};

type State = {|
  findingsLoaded: boolean,
|};

export class Reports extends PureComponent<Props, State> {
  state = { findingsLoaded: false };

  componentDidMount() {
    this.props.dispatchReportsPageLoadedAction();
    this.props.dispatchRequestFiltersFromServer();
    this.props.dispatchRequestFiltersFromStorage();
    this.updateStateIfFindingsAreLoaded();
  }

  componentDidUpdate() {
    this.updateStateIfFindingsAreLoaded();
  }

  updateStateIfFindingsAreLoaded() {
    if (!this.state.findingsLoaded && this.props.securityFindings.length > 0) {
      this.setState({ findingsLoaded: true });
    }
  }

  render() {
    const { advancedFilter, canEditPublicFilters } = this.props;
    const { savedFilters } = advancedFilter;
    const { findingsLoaded } = this.state;

    return (
      <PageHeading title="Reports" forMenu>
        <div
          style={{
            paddingLeft: '80px',
            paddingRight: '60px',
            paddingBottom: '40px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '20px',
              }}
            >
              {/* Number of saved filters */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                You have {size(savedFilters) || 0} saved report(s)
              </div>
              {/* New Report button */}
              <Link to="/search">
                <Button variant="outlined" color="primary">
                  <AddIcon />
                  Create Report
                </Button>
              </Link>
            </div>

            <SavedFilterTable
              canEditPublicFilters={canEditPublicFilters}
              savedFilters={savedFilters}
              findingsLoaded={findingsLoaded}
              onRemove={this.props.dispatchRemoveFilter}
              onLoad={(...args) => {
                this.props.dispatchSetAsCurrentFilter(...args);
              }}
              onSharedCheck={this.props.dispatchSetSharedFilter}
            />
          </div>
        </div>
      </PageHeading>
    );
  }
}

const withState = ({
  currentUser,
  securityFindings,
  advancedFilter,
}: ReduxState) => ({
  securityFindings,
  advancedFilter,
  canEditPublicFilters:
    currentUser && currentUser.role === UserRoleEnum.MANAGER,
});

const actions = {
  dispatchAddFilter: addFilter,
  dispatchRemoveFilter: removeFilter,
  dispatchSetAsCurrentFilter: setAsCurrentFilter,
  dispatchRequestFiltersFromStorage: requestFiltersFromStorage,
  dispatchRequestFiltersFromServer: requestFiltersFromServer,
  dispatchReportsPageLoadedAction: reportsPageLoadedAction,
  dispatchSetSharedFilter: setGlobalFilter,
};

export default compose(
  // $FlowFixMe FlowUpgrade
  connect(
    withState,
    actions,
  ),
  tracking(dataCreators.component(AnalyticsScreenEnum.APP_SECURE_REPORTS)),
)(Reports);
