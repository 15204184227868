//@flow
import React, { PureComponent, Fragment } from 'react';
import { ordinalize } from '@datatheorem/string';
import type { AppProtectionTask } from '@datatheorem/user-api/mobile_apps';
import { palette } from '@datatheorem/theme';

import type {
  AppProtectionTaskCategories,
  TaskCategoryPointsAndTasks,
} from '../../reducers/appProtectionTaskCategories';
import AppProtectionPieChart from './AppProtectionPieChart';

const styles = {
  tabHeader: {
    textAlign: 'center',
    padding: 20,
  },
  appName: {
    marginBottom: 10,
    fontSize: '24px',
  },
  appPercent: { fontSize: 32 },
  chartContainer: { display: 'flex', justifyContent: 'center' },
  chartTitle: {
    textAlign: 'center',
    marginBottom: 10,
    fontSize: '18px',
  },
  legendLine: {
    display: 'flex',
    justifyItems: 'center',
  },
  legend: {
    marginLeft: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  swatch: {
    display: 'inline-block',
    width: 20,
    height: 20,
    marginRight: 10,
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    margin: 'auto',
    paddingLeft: 38,
  },
};

type Props = {|
  taskCategories: AppProtectionTaskCategories,
  rank?: number,
  onClickTaskDetails: AppProtectionTask => mixed,
  onClickHideTask: AppProtectionTask => mixed,
  onClickInquire: () => mixed,
  showLoadingIndicator: boolean,
  showSubscriptionWarning: boolean,
|};

type CategoryRenderInfo = {|
  title: string,
  subtitle: string,
  color: string,
  getSummaryMessage: number => string | null, // when this returns null, don't render summary
  neverRenderList?: boolean,
  isBeyondSubscription?: true,
  showInPieChart?: boolean,
  hideWhenNoSubscription?: true,
|};

export type TaskCategoryData = {|
  ...CategoryRenderInfo,
  ...TaskCategoryPointsAndTasks,
|};

type TaskCategoriesData = {|
  NOT_COMPLETED_VISIBLE: TaskCategoryData,
  NOT_COMPLETED_HIDDEN: TaskCategoryData,
  BEYOND_SUBSCRIPTION: TaskCategoryData,
  COMPLETED: TaskCategoryData,
  UNKNOWN: TaskCategoryData,
|};

export default class AppProtectionTasksList extends PureComponent<Props> {
  render() {
    const { taskCategories, rank } = this.props;

    const taskCategoriesDecorated: TaskCategoriesData = {
      NOT_COMPLETED_VISIBLE: {
        ...taskCategories.NOT_COMPLETED_VISIBLE,
        title: 'Incomplete Tasks',
        subtitle: 'Complete to earn points',
        color: palette.gray30,
        getSummaryMessage: (tasksCount: number) =>
          tasksCount === 0
            ? 'No Incomplete Tasks'
            : `${tasksCount} Incomplete Task${tasksCount > 1 ? 's' : ''}`,
        showInPieChart: true,
      },
      NOT_COMPLETED_HIDDEN: {
        ...taskCategories.NOT_COMPLETED_HIDDEN,
        title: 'Risk Accepted Tasks',
        subtitle: 'Not included in score',
        color: palette.yellow30,
        getSummaryMessage: (tasksCount: number) =>
          tasksCount === 0
            ? null
            : `${tasksCount} Risk Accepted Task${tasksCount > 1 ? 's' : ''}`,
        neverRenderList: true,
        showInPieChart: true,
      },
      BEYOND_SUBSCRIPTION: {
        ...taskCategories.BEYOND_SUBSCRIPTION,
        title: 'Enterprise Scan Required',
        subtitle: '',
        color: palette.brand30,
        getSummaryMessage: (tasksCount: number) =>
          tasksCount === 0
            ? null
            : `${tasksCount} Task${
                tasksCount > 1 ? 's' : ''
              } Beyond Your Subscription`,
        isBeyondSubscription: true,
        hideWhenNoSubscription: true,
        showInPieChart: taskCategories.BEYOND_SUBSCRIPTION.count > 0,
      },
      COMPLETED: {
        ...taskCategories.COMPLETED,
        title: 'Completed Tasks',
        subtitle: 'Points included in score',
        color: palette.green30,
        getSummaryMessage: (tasksCount: number) =>
          tasksCount === 0
            ? 'No Completed Tasks'
            : `${tasksCount} Completed Task${tasksCount > 1 ? 's' : ''}`,
        neverRenderList: true,
        showInPieChart: true,
      },
      UNKNOWN: {
        ...taskCategories.UNKNOWN,
        title: 'Not Applicable',
        subtitle: '',
        color: palette.gray20,
        getSummaryMessage: (tasksCount: number) =>
          tasksCount > 0
            ? `${tasksCount} Task${
                tasksCount > 1 ? 's' : ''
              } Not Applicable To This App`
            : null,
        neverRenderList: true,
        hideWhenNoSubscription: true,
      },
    };

    const pieChartData = Object.keys(taskCategoriesDecorated)
      .filter(category => taskCategoriesDecorated[category].showInPieChart)
      .map(category => ({
        label: taskCategoriesDecorated[category].title,
        color: taskCategoriesDecorated[category].color,
        percent: taskCategoriesDecorated[category].percent,
        points: taskCategoriesDecorated[category].points,
      }));

    // In some rare cases (for example, when an app has no scan results), none
    // of the categories we want to chart are present. In this case, we don't
    // want to render the pie chart at all. This isn't just a typical programmer
    // shorthand for "I didn't feel like handling the error case".
    const shouldRenderPieChart = pieChartData.find(({ points }) => points > 0);

    return (
      <Fragment>
        <div style={styles.tabHeader}>
          <div style={styles.appPercent}>
            {taskCategories.COMPLETED.percent}% Protected
          </div>
          {rank && <div>{ordinalize(rank)} Global Rank</div>}
        </div>
        {shouldRenderPieChart && (
          <Fragment>
            <div style={styles.chartTitle}>Score Breakdown</div>
            <div style={styles.chartContainer}>
              <AppProtectionPieChart data={pieChartData} />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
