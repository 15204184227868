//@flow
import config from 'config';
import { type SecurityFinding } from '@datatheorem/findings/types';
import { type Application } from '@datatheorem/user-api/mobile_apps';

const REPORTING_URL =
  config && typeof config.reportingUrl === 'string'
    ? config.reportingUrl
    : null;

type Data = Application & {
  security_finding_list: $ReadOnlyArray<SecurityFinding>,
};

export async function downloadZip(data: Data) {
  return new Promise((resolve, reject) => {
    try {
      // adopted from a stackoverflow answer: https://stackoverflow.com/a/180999
      const form = global.document.createElement('form');
      form.setAttribute('method', 'POST');
      form.setAttribute('action', REPORTING_URL);
      form.setAttribute('target', '_blank');

      const input = document.createElement('input');
      input.setAttribute('name', 'data');
      input.setAttribute('value', JSON.stringify(data));
      form.appendChild(input);
      global.document.body.appendChild(form);

      form.submit();

      resolve();
    } catch (err) {
      reject(err);
    }
  });
}
