//@flow
import { saga as sessionSaga } from '@datatheorem/session';
import createSagaMiddleware, { type SagaMiddleware, Task } from 'redux-saga';
import { routinesWatcherSaga } from 'redux-saga-routines';
import { Raven } from '@datatheorem/global';
import { activityFlow } from './sagas/activity';
import { apiKeysFlow } from './sagas/apiKeys';
import { appsFlow } from './sagas/apps';
import { createAppScanCredentialsWatcher } from './sagas/app_scans';
import { commentWatchers } from './sagas/comments';
import { filtersSaga } from './sagas/filters';
import { insightsWatchers } from './sagas/insights';
import { jiraIntegrationConfigFlow } from './sagas/jiraIntegrationConfig';
import { profileFlow } from './sagas/profile';
import { sdksFlow } from './sagas/sdks';
import { watcher as searchQueriesWatcher } from './sagas/searchQueries';
import { securityFindingsWatchers } from './sagas/securityFindings';
import { shadowscanFlow } from './sagas/shadowscan';
import { trustkitFlow } from './sagas/trustkit';
import { loadUsersAtLogin } from './sagas/users';
import { whatsnewFlow } from './sagas/whatsnew';
import { appInquireSaga } from './sagas/inquire';
import { appProtectionSaga } from './sagas/appProtection';
import { preferencesFlow } from './sagas/preferences';
import mediawatchSaga from './sagas/mediawatch';

export function getMiddleware(): SagaMiddleware<{||}> {
  return createSagaMiddleware({
    onError: (e, { sagaStack }) => {
      console.error(e, sagaStack);
      Raven.captureException(e, { extra: { sagaStack } });
    },
  });
}

export function runSagas(
  withMiddleware: SagaMiddleware<{||}>,
): $ReadOnlyArray<Task<void>> {
  return [
    sessionSaga,
    appsFlow,
    securityFindingsWatchers,
    loadUsersAtLogin,
    apiKeysFlow,
    sdksFlow,
    profileFlow,
    insightsWatchers,
    commentWatchers,
    activityFlow,
    shadowscanFlow,
    routinesWatcherSaga,
    trustkitFlow,
    createAppScanCredentialsWatcher,
    whatsnewFlow,
    filtersSaga,
    appInquireSaga,
    searchQueriesWatcher,
    jiraIntegrationConfigFlow,
    appProtectionSaga,
    preferencesFlow,
    mediawatchSaga,
  ].map(saga => withMiddleware.run(saga));
}
