// @flow
import { createSelector } from 'reselect';
import {
  findingFromParam,
  type FindingIdOrLocationParam,
} from './securityFindings';
import { type State } from '../ReduxStateType';
import { tasksForAppFromParam, type AppIdParam } from './apps';

const loading = (state: State) => state.loading;

export const loadingTargetStatusIdsForFindingFromParam = createSelector<
  State,
  FindingIdOrLocationParam,
  $ReadOnlyArray<string>,
  _,
  _,
>(
  loading,
  findingFromParam,

  (loading, finding) => {
    const { targetStatus } = loading;

    if (!finding || !targetStatus || !Array.isArray(targetStatus)) {
      return [];
    }

    return targetStatus
      .filter(
        entry => entry && entry.findingId && entry.findingId === finding.id,
      )
      .map(
        entry =>
          entry && typeof entry.targetId === 'string' ? entry.targetId : null,
      )
      .filter(Boolean);
  },
);

export const loadingTargetStatusIdsForAppFromParam = createSelector<
  State,
  AppIdParam,
  $ReadOnlyArray<string>,
  _,
  _,
>(
  loading,
  tasksForAppFromParam,

  (loading, tasksForAppFromParam) => {
    const { targetStatus } = loading;

    if (
      !tasksForAppFromParam ||
      !targetStatus ||
      !Array.isArray(targetStatus)
    ) {
      return [];
    }

    return targetStatus
      .filter(entry =>
        tasksForAppFromParam.some(
          task =>
            entry &&
            entry.findingId &&
            task.security_finding_id === entry.findingId,
        ),
      )
      .map(
        entry =>
          entry && typeof entry.targetId === 'string' ? entry.targetId : null,
      )
      .filter(Boolean);
  },
);

export const loadingTargetExternalIdsForFindingFromParam = createSelector<
  State,
  FindingIdOrLocationParam,
  $ReadOnlyArray<string>,
  _,
  _,
>(
  loading,
  findingFromParam,

  (loading, finding) => {
    const { targetExternalId } = loading;

    if (!finding || !targetExternalId || !Array.isArray(targetExternalId)) {
      return [];
    }

    return targetExternalId
      .filter(
        entry => entry && entry.findingId && entry.findingId === finding.id,
      )
      .map(
        entry =>
          entry && typeof entry.targetId === 'string' ? entry.targetId : null,
      )
      .filter(Boolean);
  },
);

export const loadingTargetExternalIdsForAppFromParam = createSelector<
  State,
  AppIdParam,
  $ReadOnlyArray<string>,
  _,
  _,
>(
  loading,
  tasksForAppFromParam,

  (loading, tasksForAppFromParam) => {
    const { targetExternalId } = loading;

    if (
      !tasksForAppFromParam ||
      !targetExternalId ||
      !Array.isArray(targetExternalId)
    ) {
      return [];
    }

    return targetExternalId
      .filter(entry =>
        tasksForAppFromParam.some(
          task =>
            entry &&
            entry.findingId &&
            task.security_finding_id === entry.findingId,
        ),
      )
      .map(
        entry =>
          entry && typeof entry.targetId === 'string' ? entry.targetId : null,
      )
      .filter(Boolean);
  },
);
