//@flow
import defaults from 'lodash/defaults';
import isNil from 'lodash/isNil';

export default function replaceInPath(
  path: string,
  ...params: $ReadOnlyArray<?Object>
): string {
  const paramsMixed = defaults({}, ...params.filter(Boolean));
  return path
    .replace(/:([a-zA-Z_$][a-zA-Z0-9_$]*)/g, match => {
      const param = paramsMixed[match.substring(1)];
      if (!isNil(param)) {
        return param;
      } else {
        return '';
      }
    })
    .replace('//', '/');
}
