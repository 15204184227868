//@flow
import { createSelector } from 'reselect';
import format from 'date-fns/format';
import isThisYear from 'date-fns/is_this_year';
import { publicChangelog } from '@datatheorem/public-changelog';
import isAfter from 'date-fns/is_after';
import setYear from 'date-fns/set_year';
import setMonth from 'date-fns/set_month';
import setDate from 'date-fns/set_date';
import flatMap from 'lodash/fp/flatMap';
import type { State } from '../ReduxStateType';

type Change = {
  date: {|
    year: 2019 | 2018 | 2017,
    month: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    day:
      | 1
      | 2
      | 3
      | 4
      | 5
      | 6
      | 7
      | 8
      | 9
      | 10
      | 11
      | 12
      | 13
      | 14
      | 15
      | 16
      | 17
      | 18
      | 19
      | 20
      | 21
      | 22
      | 23
      | 24
      | 25
      | 26
      | 27
      | 28
      | 29
      | 30
      | 31,
  |},
  markdown?: ?string,
  title?: ?string,
  image?: ?string,
};

type ChangeArray = $ReadOnlyArray<Change>;

export type ChangeSection = {
  time: number,
  heading: string,
  items: ChangeArray,
};

export type ChangeSections = $ReadOnlyArray<ChangeSection>;

const toSections = function(changelog: ChangeArray): ChangeSections {
  return changelog
    .reduce((result, change) => {
      try {
        const { year, month, day } = change.date;
        const date = setYear(
          setMonth(setDate(new Date(), day), month - 1),
          year,
        );

        const outgoing = {
          time: date.getTime(),
          heading: format(date, `MMMM${isThisYear(date) ? '' : ''}`),
          items: [change],
        };

        const existing = result.find(c => c.heading === outgoing.heading);
        if (existing && existing.items) {
          outgoing.items = existing.items.concat(outgoing.items);
        }
        return result.concat(outgoing).filter(s => s !== existing);
      } catch (err) {
        // We don't want to crash this thread because it's possible that it's the main thread, and
        // since the source of this data is the `publicChangelog.js` file that might be rapidly edited
        // by developers, we want to try and recover as gracefully as possible from errors. Throwing an error
        // in a new thread prevents a main thread crash but still reports the error via other means (such as
        // ravenjs).

        setTimeout(() => {
          throw err;
        }, 0);

        return result;
      }
    }, [])
    .sort((a, b) => b.time - a.time);
};

export const allChanges = createSelector<State, {}, ChangeSections, _>(
  () => publicChangelog,

  changelog => toSections(changelog),
);

export const newChanges = createSelector<State, {}, ChangeSections, _, _>(
  allChanges,
  state => state.strings.whatsNewSeenAt,

  (sectionList, afterDate) =>
    afterDate instanceof Date ||
    typeof afterDate === 'string' ||
    typeof afterDate === 'number'
      ? sectionList.filter(section => isAfter(section.time, afterDate))
      : sectionList,
);

// only shows changes that occurred AFTER the last time changes were seen. However, ALL changes are shown if either of the following is true:
// 1) There is no "last seen date" because this is the first time the user is viewing this page
// 2) This date is recent enough that filtering according to the rule above would result in nothing shown
export const changes = createSelector<State, {}, ChangeSections, _, _>(
  allChanges,
  newChanges,

  (allChanges, newChanges) =>
    newChanges.length === 0 ? allChanges : newChanges,
);

export const hasNewChanges = createSelector<State, {}, boolean, _, _>(
  allChanges,
  changes,

  (allChanges, changes) => allChanges.length !== changes.length,
);

export const whatsNewCount = createSelector<State, {}, number, _, _>(
  newChanges,
  state => state.strings.whatsNewSeen,

  (newChanges, whatsNewSeen) =>
    whatsNewSeen ? 0 : flatMap(change => change.items)(newChanges).length,
);
