//@flow
import React, { PureComponent, type Node } from 'react';
import HamburgerIcon from 'material-ui/svg-icons/navigation/menu';
import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';

import Button from './../Button';

type Props = {
  label: Node,
  children: ({ onClick: () => mixed }) => mixed,
};

type State = {
  isOpen: boolean,
  anchorEl: ?HTMLElement,
};

export default class MenuButton extends PureComponent<Props, State> {
  state = {
    isOpen: false,
    anchorEl: null,
  };

  closeMenu = () => {
    this.setState({ isOpen: false });
  };

  openMenu = (e: SyntheticEvent<HTMLElement>) => {
    this.setState({ isOpen: true, anchorEl: e.currentTarget });
  };

  render() {
    const { label, children } = this.props;

    return (
      <Button
        ariaLabel={'Open Menu'}
        variant="outlined"
        onClick={this.openMenu}
      >
        {label}
        <HamburgerIcon
          color={'inherit'}
          style={{
            height: 16,
            width: 16,
            marginLeft: 5,
          }}
        />

        <Popover
          open={this.state.isOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          targetOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          onRequestClose={this.closeMenu}
        >
          <Menu>
            {children({
              onClick: () => {
                this.setState({ isOpen: false });
              },
            })}
          </Menu>
        </Popover>
      </Button>
    );
  }
}
