//@flow
import React, { PureComponent } from 'react';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { type FieldProps } from 'formik';

type Props = {
  compareTo: string,
  value: string,
  expansionPanelItemIsOpen: boolean,
  forceOpen: boolean,
  setFieldValue: (value: mixed) => mixed,
} & FieldProps;

type State = {
  toggled: boolean,
  value: string,
};

export default class NotificationEmail extends PureComponent<Props, State> {
  state = {
    toggled:
      this.props.compareTo === this.props.field.value ||
      this.props.compareTo === '',
    value: '',
  };

  input = null;

  render() {
    const {
      expansionPanelItemIsOpen,
      forceOpen,
      compareTo,
      field,
    } = this.props;
    const { toggled } = this.state;

    if (expansionPanelItemIsOpen || forceOpen) {
      return (
        <div style={{ margin: 10, marginLeft: 220 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={toggled}
                onChange={this.onToggle}
                color="primary"
              />
            }
            label="Use same e-mail address for alerts"
          />

          {!toggled && this.renderField()}
        </div>
      );
    } else if (compareTo !== field.value && compareTo !== '') {
      return (
        <div
          style={{
            margin: 10,
            marginLeft: 220,
            position: 'absolute',
            bottom: -4,
            fontSize: 12,
            color: '#777',
          }}
        >
          Alerts will be sent to {this.props.field.value}
        </div>
      );
    } else {
      return null;
    }
  }

  renderField = () => {
    const { field } = this.props;
    return (
      <TextField {...field} fullWidth margin="normal" label="Alert Email" />
    );
  };

  onToggle = () => {
    const { setFieldValue, compareTo } = this.props;
    this.setState({ toggled: !this.state.toggled }, () =>
      setFieldValue(compareTo),
    );
  };
}
