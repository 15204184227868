// @flow

import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import type { Options, FilterActions } from '../../../actions/filterActions';

type Props = {
  options: Options<*>,
  value: string,
  placeholder?: string,
  label?: string,
  onChange: (value: any) => FilterActions,
};

const FilterSelectField = (props: Props) => {
  return (
    <FormControl>
      {props.label && (
        <InputLabel htmlFor={props.label.replace(/ /, '_')} shrink={true}>
          {props.label}
        </InputLabel>
      )}
      <Select
        id={props.label ? props.label.replace(/ /, '_') : null}
        value={props.value}
        displayEmpty
        renderValue={selected => {
          if (!selected) {
            return <em>{props.placeholder || 'None'}</em>;
          }

          const option = props.options.find(l => l.value === selected);
          if (!option) return selected;
          return option.label;
        }}
        onChange={e => {
          const value = e.target.value;
          props.onChange(value);
        }}
      >
        {props.options.map(o => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterSelectField;
