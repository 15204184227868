//@flow
import React, { PureComponent } from 'react';
import Page from '../theme/Page';
import NotificationEmail from '../form/NotificationEmail';
import PanelItemTextField from '../form/PanelItemTextField';
import PanelItemSelect from '../form/PanelItemSelect';
import PanelItemMobileApps from '../form/PanelItemMobileApps';
import PanelSubmitField from '../form/PanelSubmitField';
import MenuItem from '@material-ui/core/MenuItem';
import AuthStrategyEnum from '@datatheorem/enums/AuthStrategyEnum';
import PanelItemToggle from '../form/PanelItemToggle';
import type { Application } from '@datatheorem/user-api/mobile_apps';
import { Field } from 'formik';
import {
  RoleSimplestHumanReadable,
  metaFieldNameForAllApps,
} from '@datatheorem/session';
import { IconLabel, ErrorMessageBlock } from '@datatheorem/components';
import ExpansionPanelItem from '../theme/ExpansionPanelItem';
import type { User } from '../main/UserInvite';

const AUTH_STRATEGY_NAMES: { [string]: string } = {
  GOOGLE_ACCOUNT: 'Google SSO',
};

const formatAuth = value => AUTH_STRATEGY_NAMES[value] || value;

type Props = {
  apps: $ReadOnlyArray<Application>,
  formError: ?string,
  setFieldValue: ?(name: string, value: mixed) => mixed,
  loading: boolean,
  onSubmit: (e: SyntheticMouseEvent<HTMLElement>) => mixed,
  values: ?User,
  submitLabel: string,
};

export default class EditForm extends PureComponent<Props> {
  render() {
    const {
      values,
      onSubmit,
      formError,
      apps,
      setFieldValue,
      submitLabel,
      loading,
    } = this.props;

    if (!values) {
      return null;
    }

    const commonProps = name => ({
      name,
      component: PanelItemTextField,
      setFieldValue: value => setFieldValue && setFieldValue(name, value),
    });

    return (
      <Page>
        {formError && <ErrorMessageBlock>{formError}</ErrorMessageBlock>}
        <Field label="First Name" {...commonProps('first_name')} />
        <Field label="Last Name" {...commonProps('last_name')} />
        <Field label="Email" {...commonProps('login_email')}>
          <Field
            compareTo={values.login_email}
            forceOpen
            {...commonProps('notification_email')}
            component={NotificationEmail}
          />
        </Field>
        <Field
          label="Role"
          {...commonProps('role')}
          component={PanelItemSelect}
          items={Object.keys(RoleSimplestHumanReadable).map(role => (
            <MenuItem key={role} value={role}>
              {RoleSimplestHumanReadable[role]}
            </MenuItem>
          ))}
        />
        <Field
          label="Authentication"
          {...commonProps('auth_strategy')}
          component={PanelItemSelect}
          items={Object.keys(AuthStrategyEnum).map(strategy => (
            <MenuItem key={strategy} value={strategy}>
              {formatAuth(strategy)}
            </MenuItem>
          ))}
        />
        <ExpansionPanelItem
          disabled
          primaryText="App Search"
          value="Has Access"
        />
        <ExpansionPanelItem
          disabled
          primaryText="Brand Protect"
          value="Has Access"
        />
        <ExpansionPanelItem
          disabled
          primaryText="API Discover"
          value="Has Access"
        />
        <ExpansionPanelItem
          disabled
          primaryText="API Inspect"
          value="Has Access"
        />
        {values.role !== 'MANAGER' ? (
          <Field
            label="App Secure"
            toggleLabel="All Apps Access"
            {...commonProps(metaFieldNameForAllApps)}
            component={PanelItemToggle}
          >
            {!values[metaFieldNameForAllApps] && (
              <div
                style={{
                  margin: 20,
                  maxHeight: 200,
                  overflowX: 'hidden',
                  overflowY: 'auto',
                }}
              >
                <Field
                  name="allowed_app_ids"
                  {...commonProps('allowed_app_ids')}
                  component={PanelItemMobileApps}
                  items={
                    apps &&
                    apps.map(app => ({
                      label: (
                        <IconLabel platform={app.platform}>
                          <span>{app.name}</span>
                          {app.most_recent_scan &&
                            app.most_recent_scan.app_version && (
                              <span> v{app.most_recent_scan.app_version}</span>
                            )}
                          {app.bundle_id && <span> • {app.bundle_id}</span>}
                        </IconLabel>
                      ),
                      value: app.id,
                    }))
                  }
                />
              </div>
            )}
          </Field>
        ) : (
          <ExpansionPanelItem
            disabled
            primaryText="App Secure"
            value="Has Access"
          />
        )}
        <PanelSubmitField
          submitLabel={submitLabel}
          onSubmit={onSubmit}
          loading={loading}
        />
      </Page>
    );
  }
}
