//@flow
import React, {
  Children,
  PureComponent,
  type Node,
  type ElementRef,
} from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Masonry,
  WindowScroller,
  type Positioner,
} from 'react-virtualized';

const gutterSize = 40;
const columnWidth = 300;

type Props = {
  children: Node,
};

export default class InsightsMasonry extends PureComponent<Props> {
  _cellPositioner: ?Positioner = null;
  _width: ?number = null;
  _masonry: ?ElementRef<typeof Masonry> = null;

  _cache = new CellMeasurerCache({
    defaultHeight: 250,
    minHeight: 80,
    defaultWidth: 300,
    fixedWidth: true,
  });

  render() {
    return (
      <WindowScroller>
        {({ height, scrollTop }) => (
          <AutoSizer
            disableHeight
            onResize={this._onResize}
            scrollTop={scrollTop}
          >
            {({ width }) => {
              this._width = width;

              this._initCellPositioner();

              return (
                <Masonry
                  autoHeight
                  cellCount={Children.count(this.props.children)}
                  cellMeasurerCache={this._cache}
                  cellPositioner={this._getCellPositioner()}
                  cellRenderer={this._cellRenderer}
                  height={height || 0}
                  ref={this._setMasonryRef}
                  scrollTop={scrollTop}
                  width={width}
                  style={{ outline: 0 }}
                />
              );
            }}
          </AutoSizer>
        )}
      </WindowScroller>
    );
  }

  _onResize = ({ width }: { width: number } = { width: columnWidth }) => {
    this._width = width;
    this._resetCellPositioner();
    if (this._masonry) {
      this._masonry.recomputeCellPositions();
    }
  };

  _getColumnCount = () => {
    if (!this._width) {
      return 0;
    }
    return Math.floor(this._width / (columnWidth + gutterSize));
  };

  _cellRenderer = ({
    index,
    key,
    parent,
    style,
  }: {
    index: number,
    key: string,
    parent: any, // No time to figure this puzzle out
    style: mixed,
  }) => {
    const { children } = this.props;
    const child = Children.toArray(children)[index];

    if (!child) {
      return null;
    }

    return (
      <CellMeasurer cache={this._cache} index={index} key={key} parent={parent}>
        <div style={style}>
          <div style={{ padding: 5 }}>{child}</div>
        </div>
      </CellMeasurer>
    );
  };

  _initCellPositioner = () => {
    if (!this._cellPositioner) {
      this._cellPositioner = createCellPositioner({
        cellMeasurerCache: this._cache,
        columnCount: this._getColumnCount(),
        columnWidth,
        spacer: gutterSize,
      });
    }
  };

  _resetCellPositioner = () => {
    if (this._cellPositioner) {
      this._cellPositioner.reset({
        columnCount: this._getColumnCount(),
        columnWidth,
        spacer: gutterSize,
      });
    }
  };

  _setMasonryRef = (ref: ?ElementRef<typeof Masonry>) => {
    this._masonry = ref;
  };

  _getCellPositioner = () => {
    if (!this._cellPositioner) {
      throw new Error('expected cell positioner to exist');
    }

    return this._cellPositioner;
  };
}

type createCellPositionerParams = {
  cellMeasurerCache: CellMeasurerCache,
  columnCount: number,
  columnWidth: number,
  spacer?: number,
};

type resetParams = {
  columnCount: number,
  columnWidth: number,
  spacer?: number,
};

function createCellPositioner({
  cellMeasurerCache,
  columnCount,
  columnWidth,
  spacer = 0,
}: createCellPositionerParams): Positioner {
  let columnHeights;

  initOrResetDerivedValues();

  function cellPositioner(index) {
    // Find the shortest column and use it.
    let columnIndex = 0;
    for (let i = 1; i < columnHeights.length; i++) {
      if (columnHeights[i] < columnHeights[columnIndex]) {
        columnIndex = i;
      }
    }

    const left = columnIndex * (columnWidth + spacer);
    const top = columnHeights[columnIndex] || 0;

    columnHeights[columnIndex] =
      top + cellMeasurerCache.getHeight(index) + spacer;

    return {
      left,
      top,
    };
  }

  function initOrResetDerivedValues(): void {
    // Track the height of each column.
    // Layout algorithm below always inserts into the shortest column.
    columnHeights = [];
    for (let i = 0; i < columnCount; i++) {
      columnHeights[i] = 0;
    }
  }

  function reset(params: resetParams): void {
    //$FlowFixMe
    columnCount = params.columnCount;
    //$FlowFixMe
    columnWidth = params.columnWidth;
    //$FlowFixMe
    spacer = params.spacer || 0;

    initOrResetDerivedValues();
  }

  cellPositioner.reset = reset;

  return cellPositioner;
}
