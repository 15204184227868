//@flow
import { put, spawn, call, takeEvery } from 'redux-saga/effects';
import { callPromise } from '@datatheorem/session';
import { list } from '@datatheorem/user-api/sdk_findings';
import type { ActionType } from 'redux-actions';
import { updateFindings } from '../reducers/sdkFindings';
import {
  paginationMountedAction,
  paginationLoadMoreAction,
  paginationLoadedInitialAction,
} from '@datatheorem/pagination';
import { paginateToEnd } from './util/paginate';
import { list as listSDKs } from '@datatheorem/user-api/sdks';
import { sdksReceived } from '../actions';
import { SDKEndpointType, SDKFindingEndpointType } from '../endpoints';
import { type Saga } from 'redux-saga';

export function* loadFindings(params: { +[key: string]: ?string }): Saga<void> {
  const findings = yield* callPromise(list, params);

  if (findings && findings.length) {
    yield put(updateFindings(findings));
  }
}

export function* loadSDKs(params: { +[key: string]: ?string }): Saga<void> {
  const response = yield* callPromise(listSDKs, params);

  if (response && response.sdks && response.sdks.length) {
    yield put(sdksReceived(response.sdks));
  }

  if (response && response.sdk_findings && response.sdk_findings.length) {
    yield put(updateFindings(response.sdk_findings));
  }
}

function* watchForSDKIssuesLoadRequests(): Saga<void> {
  yield takeEvery(
    [paginationMountedAction.toString(), paginationLoadMoreAction.toString()],
    function*(
      action:
        | ActionType<typeof paginationMountedAction>
        | ActionType<typeof paginationLoadMoreAction>,
    ): Saga<void> {
      if (action.meta && action.meta.type === SDKFindingEndpointType) {
        yield* paginateToEnd(
          loadFindings,
          action.meta.type,
          action.payload,
          action.payload,
        );
        yield put(
          paginationLoadedInitialAction(SDKFindingEndpointType, action.payload),
        );
      }
    },
  );
}

export function* sdksFlow(): Saga<void> {
  yield spawn(watchForSDKIssuesLoadRequests);
  yield spawn(watchForSDKsLoadRequests);
}

function* watchForSDKsLoadRequests(): Saga<void> {
  yield takeEvery(
    [paginationMountedAction.toString(), paginationLoadMoreAction.toString()],
    function*(
      action:
        | ActionType<typeof paginationMountedAction>
        | ActionType<typeof paginationLoadMoreAction>,
    ): Saga<void> {
      if (action.meta && action.meta.type === SDKEndpointType) {
        yield call(loadSDKs, action.payload);
        yield put(paginationLoadedInitialAction(SDKEndpointType, {}));
      }
    },
  );
}
