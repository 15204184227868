//@flow
import React, { PureComponent } from 'react';
import injectSheet from 'react-jss';
import MarkdownIt from 'markdown-it';

import type { InjectedJSS } from 'react-jss';
import { palette } from '@datatheorem/theme';

const markdown = new MarkdownIt();

const defaultRender =
  markdown.renderer.rules.link_open ||
  function(tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
  };

markdown.renderer.rules.link_open = function(tokens, idx, options, env, self) {
  let aIndex = tokens[idx].attrIndex('target');

  if (aIndex < 0) {
    tokens[idx].attrPush(['target', '_blank']);
  } else {
    tokens[idx].attrs[aIndex][1] = '_blank';
  }

  return defaultRender(tokens, idx, options, env, self);
};

type Props = {
  text?: ?string,
  inline?: ?boolean,
  nowrap?: ?boolean,
} & InjectedJSS<typeof styles>;

const styles = {
  markdown: {
    position: 'relative',
    lineHeight: '1.625',
    display: ({ nowrap }: Props) => (nowrap ? 'inline-block !important' : null),
    textOverflow: ({ nowrap }: Props) => (nowrap ? 'ellipsis' : null),
    whiteSpace: ({ nowrap }: Props) => (nowrap ? 'nowrap' : null),
    width: ({ nowrap }: Props) => (nowrap ? '100%' : null),

    '&.markdown-inline': {
      display: 'inline',
    },

    '& p': {
      wordWrap: 'break-word',
    },

    '& code': {
      color: palette.gray30,
      backgroundColor: palette.gray50,
      borderRadius: '4px',
      fontFamily: 'Menlo, monospace',
      padding: '3px',
      fontSize: '85%',

      wordWrap: 'break-word',
      wordBreak: 'break-all',
      hyphens: 'auto',
      overflowX: 'auto',
      overflowY: 'visible',
    },

    '& a': {
      color: palette.brand40,
      textDecoration: 'underline',
    },

    '& pre': {
      padding: '16px',
      overflow: 'auto',
      fontSize: '85%',
      lineHeight: '1.45',
      backgroundColor: palette.gray50,
      borderRadius: '3px',
      '& > code': {
        backgroundColor: 'transparent',
        color: palette.gray10,
      },
    },
    '& blockquote': {
      margin: 0,
      paddingLeft: 15,
      paddingTop: 3,
      paddingBottom: 3,
      position: 'relative',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 0,
        top: 0,
        width: 3,
        backgroundColor: palette.gray45,
        borderRadius: 5,
        height: '100%',
      },
    },

    '& img': {
      maxWidth: '100%',
      objectFit: 'cover',
    },
  },
};

export class Markdown extends PureComponent<Props> {
  render() {
    const { text, inline, classes, nowrap, ...other } = this.props;

    if (!text) {
      return null;
    }

    return (
      <div
        {...other}
        className={`${classes.markdown} ${inline ? 'markdown-inline' : ''}`}
        dangerouslySetInnerHTML={{
          __html: inline ? markdown.renderInline(text) : markdown.render(text),
        }}
      />
    );
  }
}

export default injectSheet(styles)(Markdown);
