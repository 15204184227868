//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type Classes, type Sheet } from 'react-jss';

const styles = {
  ListCardSpacer: {
    paddingLeft: 83,
    paddingRight: 58,
    paddingTop: 5,
    paddingBottom: 10,
  },
};

type Props = {
  classes: Classes<typeof styles>,
  children: Node,
  sheet: Sheet<typeof styles>,
};

export default jss(styles)(
  class ListCardSpacer extends PureComponent<Props> {
    render() {
      const { children, classes, sheet, ...props } = this.props;

      return (
        <div {...props} className={classes.ListCardSpacer}>
          {children}
        </div>
      );
    }
  },
);
