//@flow
import React, { PureComponent } from 'react';
import { PageHeading } from '@datatheorem/components';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import tracking, { type TrackingProps } from 'react-tracking';
import type { ActionType } from 'redux-actions';
import Card from '../jira/JiraCard';

import jss, { type InjectedJSS } from 'react-jss';

import { MANAGER_GET_REQUEST_NAME } from '../../strings';
import PageLoader from '../theme/PageLoader';
import { palette } from '@datatheorem/theme';

import logo from '@datatheorem/brand/logo.png';
import JiraLogo from '../jira/images/jira_logo.jpg';
import SamlIcon from '@material-ui/icons/PermIdentity';
import UploadIcon from '@material-ui/icons/CloudUpload';

import {
  apiKeyClicked,
  createAPIKeyButtonClicked,
  sdlcInfoMounted,
  uploadButtonClicked,
  sdlcAPIKeysPanelClicked,
  jiraIntegrationConfigListViewClicked,
  samlSetupPageClicked,
  apiAccessPageClicked,
} from '../../actions';
import type { ContextRouter } from 'react-router-dom';

import { dataCreators } from '@datatheorem/analytics';
import { type DispatchProps } from 'redux';
import { browserHistory } from '../../clients/history';
import type { State as ReduxState } from '../../ReduxStateType';

type OwnProps = ContextRouter;

type StateProps = {|
  loading: boolean,
|};

type DP = DispatchProps<
  | ActionType<typeof sdlcInfoMounted>
  | ActionType<typeof apiKeyClicked>
  | ActionType<typeof createAPIKeyButtonClicked>
  | ActionType<typeof uploadButtonClicked>
  | ActionType<typeof sdlcAPIKeysPanelClicked>
  | ActionType<typeof jiraIntegrationConfigListViewClicked>
  | ActionType<typeof samlSetupPageClicked>
  | ActionType<typeof apiAccessPageClicked>,
>;

type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DP,
  ...TrackingProps,
  ...InjectedJSS<typeof styles>,
|};

type IntermediateProps = {|
  ...OwnProps,
  ...StateProps,
  ...DP,
|};

const styles = {
  container: {
    marginTop: 20,
    padding: 20,
  },

  apps: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    margin: '16px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  card: {
    width: 300,
    margin: 16,
    alignSelf: 'stretch',
    display: 'flex',
  },
};

export default compose(
  connect<IntermediateProps, OwnProps, _, _, _, _>(
    (state: ReduxState): StateProps => ({
      loading: !state.firstRequest[MANAGER_GET_REQUEST_NAME],
    }),
  ),
  tracking(dataCreators.component(AnalyticsScreenEnum.SDLC_INFO)),
  jss(styles),
)(
  class SAMLSetupInstructions extends PureComponent<Props> {
    componentDidMount() {
      this.props.dispatch(sdlcInfoMounted());
    }

    render() {
      const { loading, classes } = this.props;

      return loading ? (
        <PageLoader />
      ) : (
        <PageHeading
          backButton
          onClickBack={browserHistory.goBack}
          title="DevSecOps"
        >
          <map name="map">
            <area
              shape="rect"
              coords="200,0,400,270"
              href="https://datatheorem.atlassian.net/servicedesk/customer/kb/view/98486"
              target="_blank"
            />
            <area
              shape="rect"
              coords="400,0,600,270"
              href="https://datatheorem.github.io/PortalApi/"
              target="_blank"
            />
          </map>
          <img
            useMap="#map"
            style={{ width: 550, margin: 20 }}
            src="/dist/sdlc.png"
          />

          <div className={classes.container}>
            <div className={classes.apps}>
              <div className={classes.card}>
                <Card
                  title="Jira Integration"
                  avatar={JiraLogo}
                  description="Create global and app-specific Jira Integration Configurations to export Data Theorem findings directly into Jira."
                  buttonLabel="Configure"
                  onClick={() => {
                    this.props.dispatch(jiraIntegrationConfigListViewClicked());
                  }}
                />
              </div>

              <div className={classes.card}>
                <Card
                  title="SAML Setup"
                  avatar={
                    <SamlIcon
                      style={{ color: palette.brand, height: 32, width: 32 }}
                    />
                  }
                  description="Documentation and instructions for SAML integration with Data Theorem."
                  buttonLabel="Read More"
                  onClick={() => {
                    this.props.dispatch(samlSetupPageClicked());
                  }}
                />
              </div>

              <div className={classes.card}>
                <Card
                  title="API Access"
                  avatar={logo}
                  description="Instructions for uploading mobile applications and accessing scan results via Data Theorem's APIs."
                  buttonLabel="Read More"
                  onClick={() => {
                    this.props.dispatch(apiAccessPageClicked());
                  }}
                />
              </div>

              <div className={classes.card}>
                <Card
                  title="Manual App Upload (Legacy)"
                  avatar={
                    <UploadIcon
                      style={{ color: palette.brand, height: 32, width: 32 }}
                    />
                  }
                  description="Upload app binaries via the Data Theorem portal"
                  buttonLabel="Upload"
                  onClick={() => {
                    this.props.dispatch(uploadButtonClicked());
                  }}
                />
              </div>
            </div>
          </div>
        </PageHeading>
      );
    }
  },
);
