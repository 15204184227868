//@flow
import React, { PureComponent } from 'react';

const SIZE = 32;

export type Datum = {
  value: number,
  color: string,
};

type Props = {
  style?: {},
  data: $ReadOnlyArray<Datum>,
};

export default class PieChart extends PureComponent<Props> {
  render() {
    const { data, style } = this.props;
    const cleanData = data.filter(({ value }) => Math.round(value) > 0);

    if (!cleanData.length) {
      throw new Error(
        'You cannot create a PieChart without at least one value with a non-zero data!',
      );
    }

    let offset = 0;

    const missing =
      100 -
      cleanData.reduce((total, datum) => total + Math.round(datum.value), 0);

    return (
      <svg
        viewBox={`0 0 ${SIZE} ${SIZE}`}
        style={{
          transform: 'rotate(-90deg)',
          background: cleanData[0].color,
          borderRadius: '50%',
          ...style,
        }}
      >
        {cleanData.map(({ value, color }, i) => {
          const node = (
            <circle
              key={color}
              r={SIZE / 2}
              cx={SIZE / 2}
              cy={SIZE / 2}
              style={{
                fill: 'transparent',
                stroke: color,
                strokeWidth: SIZE,
                strokeDasharray: `${Math.round(value) +
                  (i === cleanData.length - 1 ? missing : 0)} 200`,
                strokeDashoffset: offset,
              }}
            />
          );
          offset -= Math.round(value);
          return node;
        })}
      </svg>
    );
  }
}
