// @flow

export type PreferencesEmailDecoration = {|
  +title: string,
  +managerOnly?: boolean,
  +description: string,
|};

const notificationTypeToHumanHelp: Map<
  string,
  PreferencesEmailDecoration,
> = new Map([
  [
    'security_p1s',
    {
      title: 'Security P1',
      description: 'An App has a new Security P1 Issue.',
    },
  ],
  [
    'app_store_blockers',
    {
      title: 'App Store Blockers',
      description:
        'An issue has been identified that does not meet Apple’s App Store security requirements.',
    },
  ],
  [
    'google_play_blockers',
    {
      title: 'Google Play Blockers',
      description:
        'An issue has been identified that does not meet Google’s Play Store security requirements.',
    },
  ],
  [
    'sdk_issues',
    {
      title: 'Issues due to OSS/SDKs',
      description:
        'Third party code, either Open Source or Commercial SDK, has introduced a new security or privacy issue.',
    },
  ],
  [
    'prioritized_issues',
    {
      title: 'Prioritized Issues',
      description: 'A manager has upgraded an issue to P0 (Urgent).',
    },
  ],
  [
    'app_protection_tasks',
    {
      title: 'App Protection Tasks',
      description:
        'A developer has implemented defensive protections in an app.',
    },
  ],
  [
    'new_mobile_apps',
    {
      title: 'New Mobile Apps',
      description:
        'A new app has been published on the App Store or Google Play.',
    },
  ],
  [
    'completed_scans',
    {
      title: 'Completed Scans',
      description: 'A ongoing scan has completed.',
    },
  ],
  [
    'monthly_stats',
    {
      title: 'Monthly Metrics',
      description: 'Summary of all mobile or API security metrics.',
    },
  ],
  [
    'note_added',
    {
      title: 'New Comments',
      description:
        'A security architect/developer has commented on an issue that you have commented.',
    },
  ],
  [
    'scan_started',
    {
      title: 'Started Scans',
      description: 'A new scan has started.',
    },
  ],
  [
    'new_app_choose_subscription',
    {
      managerOnly: true,
      title: 'Choose App Subscription',
      description: 'An app needs a subscription to be approved.',
    },
  ],
  [
    'weekly_user_report',
    {
      managerOnly: true,
      title: 'Weekly User Report',
      description: 'A weekly user report is ready.',
    },
  ],
]);

export default notificationTypeToHumanHelp;
