//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

import AppScanBlockedReasonEnum from '@datatheorem/enums/AppScanBlockedReasonEnum';
import { type AppScanBlockedReasonEnum as AppScanBlockedReasonEnumType } from '@datatheorem/enums/AppScanBlockedReasonEnum';
import { type ApplicationScan } from '@datatheorem/user-api/mobile_apps';
import { palette } from '@datatheorem/theme';
import ProgressIcon from 'material-ui/svg-icons/image/timelapse';
import ExclamationPointIcon from 'material-ui/svg-icons/alert/error';
import CheckMarkIcon from 'material-ui/svg-icons/action/check-circle';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  label: {
    color: palette.navColor,
    paddingLeft: ({ includeImage }: Props) => (includeImage ? 5 : 'unset'),
  },
};

type Props = {
  scan?: ApplicationScan,
  type?: AppScanBlockedReasonEnumType,
  overrideLabel?: string,

  format: (
    { +start_date?: string, +end_date?: string } | void,
    AppScanBlockedReasonEnumType,
  ) => string,
  includeImage: boolean,
  includeLabel: boolean,
} & InjectedJSS<typeof styles>;

class ScanBadge extends PureComponent<Props> {
  static defaultProps = {
    format: (
      scan: void | { +start_date?: string, +end_date?: string },
      reason: AppScanBlockedReasonEnumType,
    ) => reason,
    includeLabel: true,
    includeImage: true,
  };

  _getBadgeImage = scanDetails => {
    const { scan, type } = this.props;
    const style = {
      width: 16,
      height: 16,
      margin: 0,
    };

    const exclamationPoint = (
      <ExclamationPointIcon
        color={palette.red}
        style={style}
        title={scanDetails}
      />
    );

    // No scan.
    if (!scan) {
      return exclamationPoint;
    }

    // No scan started.
    if (!scan.start_date) {
      return exclamationPoint;
    }

    // Scan blocked.
    if (
      type === AppScanBlockedReasonEnum.CORRUPTED_APP_PACKAGE ||
      type === AppScanBlockedReasonEnum.NO_CREDENTIALS
    ) {
      return exclamationPoint;
    }

    // Scan ongoing.
    if (!scan.end_date) {
      return (
        <ProgressIcon
          color={palette.yellow}
          style={style}
          title={scanDetails}
        />
      );
    }

    // Scan completed.
    return (
      <CheckMarkIcon color={palette.green} style={style} title={scanDetails} />
    );
  };

  render() {
    const {
      classes,
      scan,
      includeImage,
      includeLabel,
      overrideLabel,
      format,
      type,
    } = this.props;

    const scanDetails =
      !overrideLabel && type ? format(scan, type) : overrideLabel;
    const image = this._getBadgeImage(scanDetails);

    return (
      <div className={classes.container}>
        {includeImage ? image : null}
        {includeLabel ? (
          <span className={classes.label}>{scanDetails}</span>
        ) : null}
      </div>
    );
  }
}

export default jss(styles)(ScanBadge);
