//@flow
import { type UserRoleEnum } from '@datatheorem/enums/UserRoleEnum';
import { type AuthStrategyEnum } from '@datatheorem/enums/AuthStrategyEnum';

import { request } from './gapi';
import { result, check, byProperty, fixParamArrays } from './util';

export type User = {
  first_name?: string,
  last_name?: string,
  date_updated?: string, // Date ISO 8601: "2017-01-31T14:09:07.313240"
  login_email?: string,
  can_download_reports?: boolean,
  role: UserRoleEnum,
  allowed_app_ids?: $ReadOnlyArray<string>,
  date_created?: string, // Date ISO 8601: "2017-01-31T14:09:07.313240"
  notification_email?: string,
  id: string,
  auth_strategy?: AuthStrategyEnum,
  last_login_date?: string, // Date ISO 8601: "2017-01-31T14:09:07.313240"
};

type ListParams = {
  cursor?: string,
};

export async function list(params?: ListParams): Promise<$ReadOnlyArray<User>> {
  return request({ path: `userapi/v2/users`, params: params })
    .then(result)
    .then(byProperty('users'));
}

export async function get(id: string): Promise<User> {
  if (!id || !id.length) {
    throw new Error('Invalid user id');
  }

  return request({ path: `userapi/v2/users/${id}` }).then(result);
}

export type CreateUserParams = {
  first_name: string,
  last_name: string,
  login_email: string,
  role: UserRoleEnum,
  allowed_app_ids: $ReadOnlyArray<string>,
  notification_email: string,
  auth_strategy: AuthStrategyEnum,
};

export async function create(params: CreateUserParams): Promise<User> {
  if (
    !params ||
    !params.first_name ||
    !params.last_name ||
    !params.login_email ||
    !params.role ||
    !params.notification_email ||
    !params.auth_strategy
  ) {
    throw new Error('Invalid user provided');
  }
  return request({
    path: `userapi/v2/users`,
    method: 'POST',
    body: params,
  }).then(result);
}

type PatchUserParams = {
  first_name?: string,
  last_name?: string,
  login_email?: string,
  role?: UserRoleEnum,
  allowed_app_ids?: $ReadOnlyArray<string>,
  notification_email?: string,
  auth_strategy?: AuthStrategyEnum,
};

export async function patch(
  id: string,
  params: PatchUserParams,
): Promise<User> {
  if (!id || !id.length) {
    throw new Error('Invalid user id');
  }

  return request({
    path: `userapi/v2/users/${id}`,
    method: 'PATCH',
    body: fixParamArrays(params),
  }).then(result);
}

export async function del(id: string): Promise<void> {
  if (!id || !id.length) {
    throw new Error('Invalid user id');
  }

  return request({ path: `userapi/v2/users/${id}`, method: 'DELETE' }).then(
    check,
  );
}

type ReplaceAllowedAppIds = {
  allowed_app_ids: $ReadOnlyArray<string>,
};

export const allowed_app_ids = {
  async add(user_id: string, id: string): Promise<void> {
    if (!user_id || !user_id.length) {
      throw new Error('Invalid user id');
    }
    if (!id || !id.length) {
      throw new Error('Invalid app id');
    }
    return request({
      path: `userapi/v2/users/${user_id}/allowed_app_ids/${id}`,
      method: 'PUT',
    }).then(check);
  },

  async remove(user_id: string, id: string): Promise<void> {
    if (!user_id || !user_id.length) {
      throw new Error('Invalid user id');
    }
    if (!id || !id.length) {
      throw new Error('Invalid app id');
    }
    return request({
      path: `userapi/v2/users/${user_id}/allowed_app_ids/${id}`,
      method: 'DELETE',
    }).then(check);
  },

  async replace(user_id: string, params: ReplaceAllowedAppIds): Promise<void> {
    if (!user_id || !user_id.length) {
      throw new Error('Invalid user id');
    }
    if (!params) {
      throw new Error('A list of allowed app ids is required');
    }
    return request({
      path: `userapi/v2/users/${user_id}/allowed_app_ids`,
      method: 'PUT',
      params: params,
    }).then(check);
  },
};
