//@flow
import React, { type Node } from 'react';
import { Tab as MaterialTab } from 'material-ui/Tabs';
import { activeTabStyle, tabStyle } from './Tabs';

type Props = {
  locationPathname: string,
  label: Node,
  path: string,
  key: string,
  onClick: (path: string) => mixed,
  children?: ?Node,
};

/**
 * IMPORTANT: This must be called as a function, not as a React component due to how
 * material-ui 0.x works. It expects material-ui's own Tab component to be direct
 * children of Tabs. We can fix this when (if?) we switch to 1.x. So like this:
 *
 * <PageHeading
 *   ...
 *   tabs={[
 *     Tab({
 *       key: 0,
 *       label: 'My Tab',
 *       path: 'mytab',
 *       locationPathname: currentPath,
 *       onClick: ()=>somethingToCauseCurrentPathToBePath(),
 *     }),
 *     ...repeat...
 *   ]}
 * >...</PageHeading>
 */
export default function Tab(props: Props) {
  const { locationPathname, label, path, onClick, key, children } = props;
  return (
    <MaterialTab
      key={key}
      label={label}
      onActive={() => onClick(path)}
      style={locationPathname.indexOf(path) !== -1 ? activeTabStyle : tabStyle}
      value={path}
    >
      {children}
    </MaterialTab>
  );
}
