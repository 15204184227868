//@flow
const screen = Object.freeze({
  ACTIVITY: 'Activity',
  ALL_ISSUES: 'All Issues',
  ALL_SDKS: 'All OSS/SDK Issues',
  API_KEY_CREATE: 'API Key Create',
  API_KEY_EDIT: 'API Key Edit',
  APPLE_BLOCKERS: 'App Store Blockers',
  APPS: 'Manage Apps',
  COMPLIANCE_CONCERNS: 'Compliance Concerns',
  GOOGLE_BLOCKERS: 'Google Play Blockers',
  LOGIN: 'Login',
  MOBILE_APP_INFO: 'App Insights',
  MOBILE_APP_ISSUES: 'App Issues',
  MOBILE_APP_PROTECTION: 'App Protection',
  MOBILE_APP_SDKS: 'App OSS/SDKs',
  MOBILE_APP_TRUSTKIT: 'App TrustKit',
  MOBILE_APP_INTEGRATION: 'App Integration',
  MOBILE_APP_JIRA_INTEGRATION: 'App Jira Integration',
  OPENSCAN_APP_DETAILS: 'Openscan App Details',
  OPENSCAN_CONFIGURATION: 'Openscan Configuration',
  OPENSCAN_SEARCH: 'Openscan Search',
  PRIORITY_ALERTS: 'Priority Alerts',
  PROFILE: 'Profile',
  REGULATORY_COMPLIANCE: 'Regulatory Compliance',
  SDLC_API_KEYS: 'API Key',
  SDLC_APP_UPLOAD: 'Upload App',
  SDLC_INFO: 'SDLC Automation',
  SDLC_JIRA_LIST: 'JIRA Integration List',
  SDLC_SAML_INSTRUCTIONS: 'SAML Setup',
  SEARCH_RESULTS: 'Search Results',
  SECURITY_FINDING_DIALOG: 'Security Finding Details',
  SECURITY_P1S: 'Security P1s',
  UPLOAD_API_KEY: 'Upload API Key Page',
  USERS: 'Manage Users',
  USERS_EDIT: 'Edit User',
  USERS_INVITE: 'Invite User',
  WHATS_NEW: "What's New",
  API_DISCOVER_DASHBOARD: 'API Discover Dashboard',
  API_INSPECT_DASHBOARD: 'API Inspect Dashboard',
  API_RESTFUL_APIS_LIST: 'API RESTful APIs List',
  API_NETWORK_SERVICES_LIST: 'API Network Services List',
  API_CLOUD_RESOURCES_LIST: 'API Cloud Resources List',
  API_RESTFUL_API_DETAILS: 'API RESTful API Details',
  API_NETWORK_SERVICE_DETAILS: 'API Network Service Details',
  API_CLOUD_RESOURCE_DETAILS: 'API Cloud Resource Details',
  API_SECURITY_DASHBOARD: 'API Security Dashboard',
  API_POLICY_VIOLATION_DETAILS: 'API Policy Violation Details',
  API_ACTIVITY_DASHBOARD: 'API Activity Dashboard',
  API_OVERVIEW_DASHBOARD: 'API Overview Dashboard',
  API_POLICY_CONFIG: 'API Policy Config',
  SHADOW_POLICY_DEMO_MESSAGE: 'Shadow Policy Demo Message',
  SHADOW_POLICY_DEMO_SETUP: 'Shadow Policy Demo Setup',
  APP_SECURE_REPORTS: 'App Secure Reports Page',
  APP_SECURE_ADVANCED_SEARCH: 'App Secure Advanced Search',
  PREFERENCES: 'Preferences',
});

export default screen;

export type AnalyticsScreenEnum = $Values<typeof screen>;
