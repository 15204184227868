//@flow
import * as React from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import Paper from 'material-ui/Paper';

const styles = {
  paper: {
    borderLeft: ({ status }) => {
      return typeof status !== 'function' ? status : status();
    },
  },
};

export type OwnProps = {|
  status?: void | string | (() => string),
  zDepth?: number,
  children?: React.Node,
|};

type Props = {| ...OwnProps, ...InjectedJSS<typeof styles> |};

export default jss(styles)(
  class Card extends React.PureComponent<Props> {
    static defaultProps = {
      status: 'none',
    };

    render() {
      const { children, classes, zDepth, ...paperProps } = this.props;

      return (
        <Paper className={classes.paper} zDepth={zDepth} {...paperProps}>
          {children}
        </Paper>
      );
    }
  },
);
