//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import PlatformIcon, { type PlatformIconTypes } from './icons/PlatformIcon';

const styles = {
  IconLabel: {},

  icon: {
    width: 16,
    height: 16,
    verticalAlign: 'middle',
  },

  label: {
    verticalAlign: 'middle',
    marginLeft: 5,
  },
};

type Props = {|
  platform: PlatformIconTypes,
  children?: Node | void,
  ...InjectedJSS<typeof styles>,
|};

export default jss(styles)(
  class IconLabel extends PureComponent<Props> {
    render() {
      const { classes, children, platform } = this.props;

      return (
        <span className={classes.IconLabel}>
          <PlatformIcon
            platform={platform}
            className={classes.icon}
            style={styles.icon}
          />
          <span className={classes.label}>{children}</span>
        </span>
      );
    }
  },
);
