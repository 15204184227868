//@flow
import { object, array, string, type YupObject } from 'yup';

import { type AuthStrategyEnum } from '@datatheorem/enums/AuthStrategyEnum';
import { type FindingTargetStatusEnum } from '@datatheorem/enums/FindingTargetStatusEnum';
import { type FindingPriorityEnum } from '@datatheorem/enums/FindingPriorityEnum';

import { request } from './gapi';
import { result } from './util';
import type { PaginatedResponse } from './_common';

export type ActivityLogEventBase = {|
  event_type: string,
  date_created: string, // Date ISO 8601: "2017-01-31T14:09:07.313240"
  description?: string,
|};

type UserActivityLogEvent = {|
  ...ActivityLogEventBase,
  user_email?: string,
|};

export type UserLoginEvent = {|
  ...UserActivityLogEvent,
  auth_strategy?: AuthStrategyEnum,
  ip_address?: string,
  event_type?: 'USER_LOGGED_IN',
|};

export type UserLoginFailedEvent = {|
  ...UserLoginEvent,
  failure_reason?: string,
  event_type?: 'USER_LOGIN_FAILED',
|};

type UserModifiedEvent = {|
  ...UserActivityLogEvent,
  target_user_email?: string,
|};

export type UserAddedEvent = {|
  ...UserModifiedEvent,
  event_type?: 'USER_ADDED',
|};

export type UserDeletedEvent = {|
  ...UserModifiedEvent,
  event_type?: 'USER_DELETED',
|};

export type UserOTPResetEvent = {|
  ...UserModifiedEvent,
  event_type?: 'USER_OTP_RESET',
|};

type MobileAppEvent = {|
  ...ActivityLogEventBase,
  mobile_app_name?: string,
  mobile_app_platform?: string,
  mobile_app_id?: string,
|};

type FindingModifiedEvent = {|
  ...ActivityLogEventBase,
  ...MobileAppEvent,
  finding_id?: string,
  finding_title?: string,
|};

type FindingNoteModifiedEvent = {|
  ...UserActivityLogEvent,
  ...FindingModifiedEvent,
  note_content?: string,
|};

export type FindingNoteAddedEvent = {|
  ...FindingNoteModifiedEvent,
  event_type?: 'NOTE_ADDED',
|};

export type FindingNoteDeletedEvent = {|
  ...FindingNoteModifiedEvent,
  event_type?: 'NOTE_DELETED',
|};

export type FindingPriorityUpdatedEvent = {|
  ...UserActivityLogEvent,
  ...FindingModifiedEvent,
  finding_priority?: FindingPriorityEnum,
  event_type?: 'FINDING_PRIORITY_UPDATED',
|};

export type FindingTargetStatusUpdatedEvent = {|
  ...UserActivityLogEvent,
  ...FindingModifiedEvent,
  finding_target_status?: FindingTargetStatusEnum,
  finding_target_value?: string,
  event_type?: 'FINDING_TARGET_STATUS_UPDATED',
|};

export type P1IssueAddedEvent = {|
  ...FindingModifiedEvent,
  event_type?: 'P1_ISSUE_ADDED',
|};

export type AppAddedEvent = {|
  ...MobileAppEvent,
  mobile_app_subscription?: string,
  event_type?: 'APP_ADDED',
|};

type ScanEvent = {|
  ...MobileAppEvent,
  mobile_app_version?: string,
|};

export type ScanStartEvent = {|
  ...ScanEvent,
  event_type?: 'SCAN_STARTED',
|};

export type ScanCompletedEvent = {|
  ...ScanEvent,
  event_type?: 'SCAN_COMPLETED',
|};

export type ActivityLogEvent =
  | UserLoginEvent
  | UserAddedEvent
  | UserDeletedEvent
  | FindingNoteAddedEvent
  | FindingNoteDeletedEvent
  | FindingPriorityUpdatedEvent
  | FindingTargetStatusUpdatedEvent
  | P1IssueAddedEvent
  | AppAddedEvent
  | ScanStartEvent
  | ScanCompletedEvent
  | UserLoginFailedEvent
  | UserOTPResetEvent;

export const ActivityLogEventSchema: YupObject<ActivityLogEvent> = object().shape(
  {
    date_created: string().required(),
    event_type: string().required(),
  },
);

async function validate(
  result,
): Promise<PaginatedResponse<ActivityListResponse>> {
  return object()
    .shape({
      activity_log_events: array().of(ActivityLogEventSchema),
    })
    .validate(result);
}

type ListParams = {
  +cursor?: ?string,
};

type ActivityListResponse = {|
  +activity_log_events: $ReadOnlyArray<ActivityLogEvent>,
|};

export async function list(
  params?: ListParams,
): Promise<PaginatedResponse<ActivityListResponse>> {
  return request({ path: `userapi/v2/activity_log_events`, params: params })
    .then(result)
    .then(validate);
}
