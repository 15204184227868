//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import { Header } from '@datatheorem/components';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import IconButton from 'material-ui/IconButton';
import { palette, fontFamily } from '@datatheorem/theme';

const styles = {
  DialogForDetails: {},
  header: {
    wrapper: {
      backgroundColor: palette.darkBg,
      color: palette.inacitveColor,
      justifyContent: 'space-between',
      paddingLeft: 10,
    },
  },
  content: {
    marginTop: 50,
    padding: '10px 80px',
    backgroundColor: palette.tableDark,
    fontFamily: fontFamily,
    paddingTop: 0,
  },
};

type Props = {
  header?: Node,
  children: Node,
  onClose: () => *,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class DialogForDetails extends PureComponent<Props> {
    render() {
      const { header, onClose, children, classes } = this.props;

      return (
        <div>
          <Header
            style={styles.header}
            secondaryNavigationItems={
              <IconButton onClick={onClose} aria-label="Close Dialog">
                <CloseIcon color={palette.inacitveColor} />
              </IconButton>
            }
          >
            {header || <div> </div>}
          </Header>
          <div className={classes.content}>{children}</div>
        </div>
      );
    }
  },
);
