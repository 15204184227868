//@flow
import React, { PureComponent } from 'react';
import { JiraBasicInfoSchema, commonProps } from './JiraSchemas';
import { Field, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import Page from '../../theme/Page';
import MenuItem from '@material-ui/core/MenuItem';
import PanelItemToggle from '../PanelItemToggle';
import PanelItemSelect from '../PanelItemSelect';
import JiraExportFilterEnum from '@datatheorem/enums/JiraExportFilterEnum';
import { Button } from '@datatheorem/components';
import { type JiraIntegrationConfig } from '@datatheorem/user-api/jira_config';

const ENUM_FORMATS = {
  ALL_ISSUES: 'All Issues',
  P1_ISSUES_AND_BLOCKERS: 'Security P1 Issues & App/Play Store Blockers',
  DISABLED: 'None (Disabled)',
};

const formatEnum = jiraEnum => ENUM_FORMATS[jiraEnum] || jiraEnum;

type Props = {
  loading: boolean,
  onSubmit: () => void,
  initialValues: ?JiraIntegrationConfig,
  submitLabel: string,
  isNew: boolean,
};

type FormValues = {
  [value: string]: mixed,
};

export default class JiraBasicInfoForm extends PureComponent<Props> {
  render() {
    const { loading, onSubmit, submitLabel, initialValues, isNew } = this.props;

    const options = {
      stripUnknown: true,
      context: { isNew: isNew },
    };

    const formValues = JiraBasicInfoSchema.isValidSync(initialValues, options)
      ? JiraBasicInfoSchema.cast(initialValues, options)
      : JiraBasicInfoSchema.default();

    return (
      <Formik
        initialValues={formValues}
        onSubmit={onSubmit}
        validate={(values: FormValues) => {
          // We don't use validateSchema since we have to this method in order to
          // pass the context to yup
          try {
            validateYupSchema(values, JiraBasicInfoSchema, true, {
              isNew: isNew,
            });
          } catch (err) {
            return yupToFormErrors(err);
          }
        }}
        render={({ setFieldValue, handleSubmit }) => {
          return (
            <Page>
              <Field
                label="Base URL"
                {...commonProps('base_url', setFieldValue)}
                placeholder="Ex: https://yourdomain.atlassian.net"
              />
              <Field
                label="Username"
                {...commonProps('username', setFieldValue)}
              />
              <Field
                label="API Token"
                {...commonProps('password', setFieldValue)}
                fieldType="password"
                placeholder="Password hidden"
              />
              <Field
                label="Type Of Issue Name"
                {...commonProps('type_of_issue_name', setFieldValue)}
                placeholder="'Story', 'Bug', 'task' or any custom defined value"
              />
              <Field
                label="Project Key"
                {...commonProps('project_key_or_id', setFieldValue)}
                placeholder="Unique key that identifies project"
              />
              <Field
                label="Filter Options"
                {...commonProps('export_filter', setFieldValue)}
                component={PanelItemSelect}
                items={Object.keys(JiraExportFilterEnum).map(jiraEnum => (
                  <MenuItem
                    key={jiraEnum}
                    value={jiraEnum}
                    disabled={
                      jiraEnum === JiraExportFilterEnum.ALL_ISSUES
                        ? true
                        : false
                    }
                  >
                    {formatEnum(jiraEnum)}
                  </MenuItem>
                ))}
              />
              <Field
                label="Export Prod Issues"
                {...commonProps('export_prod', setFieldValue)}
                component={PanelItemToggle}
              />
              <Field
                label="Export Pre-Prod Issues"
                {...commonProps('export_pre_prod', setFieldValue)}
                component={PanelItemToggle}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  paddingBottom: 10,
                  paddingTop: 10,
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                <Button
                  variant="contained"
                  ariaLabel="Submit Form"
                  onClick={handleSubmit}
                  isLoading={loading}
                  disabled={false}
                >
                  {submitLabel}
                </Button>
              </div>
            </Page>
          );
        }}
      />
    );
  }
}
