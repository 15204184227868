//@flow
import React, { PureComponent } from 'react';

import { Stepper, Step, StepLabel } from '@material-ui/core';

import { type JiraIntegrationConfig } from '@datatheorem/user-api/jira_config';

import JiraBasicInfoForm from '../form/jira/JiraBasicInfoForm';
import JiraStaticFieldForm from '../form/jira/JiraStaticFieldForm';
import JiraDynamicFieldForm from '../form/jira/JiraDynamicFieldForm';
import JiraSeverityConfigForm from '../form/jira/JiraSeverityConfigForm';
import JiraReviewForm from '../form/jira/JiraReviewForm';
import JiraConfigOverview from '../form/jira/JiraConfigOverview';
import {
  jiraIntegrationConfigCreateFormSubmitted,
  jiraIntegrationConfigPatchFormSubmitted,
} from '../../actions';

import { type DispatchProps } from 'redux';

type JiraStepperProp = {
  step: number,
  labels: string[],
};

class JiraStepper extends PureComponent<JiraStepperProp> {
  render() {
    const { step } = this.props;
    const { labels } = this.props;

    return (
      <Stepper activeStep={step} alternativeLabel>
        {labels.map((value, index) => (
          <Step key={index}>
            <StepLabel>{value}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }
}

type JiraWizardFormState = {
  step: number,
  changes: $Shape<JiraIntegrationConfig>[],
};

type JiraWizardFormProp = {
  idToMapJiraConfig: string,
  jiraIntegrationConfig: ?JiraIntegrationConfig,
  isReadOnly: boolean,
  ...DispatchProps<>,
};

export default class JiraWizardForm extends React.Component<
  JiraWizardFormProp,
  JiraWizardFormState,
> {
  state = {
    step: 0,
    changes: [],
  };

  onSubmit = (
    values: mixed,
    hasCompletedForm: boolean,
    isNew: boolean,
    step: number,
    maxSteps: number,
  ) => {
    // User has completed form, next determine if we're updating or creating a new config
    if (hasCompletedForm) {
      const formAction = isNew
        ? jiraIntegrationConfigCreateFormSubmitted
        : jiraIntegrationConfigPatchFormSubmitted;

      const action = formAction({
        jiraIntegrationConfigChanges: [...this.state.changes],
        id: this.props.idToMapJiraConfig,
      });

      this.props.dispatch(action);
    }

    // Only save to state once we have some data, otherwise random stuff
    // will be saved which will cause problems when calling the api
    if (step > 0) {
      // $FlowFixMe
      this.setState(prevState => {
        const copy = [...prevState.changes];
        copy[step] = values;

        return {
          changes: copy,
        };
      });
    }

    this.setState(prevState => {
      // Use modulus to make sure the step is never greater than `component.length`
      // Also helps with resetting the form once the user reaches the end
      const nextStep: number = (prevState.step + 1) % (maxSteps + 1);

      return { step: nextStep };
    });
  };

  handleBackSubmit = () =>
    this.setState(prevState => {
      return { step: prevState.step - 1 };
    });

  render() {
    const { step } = this.state;
    const { jiraIntegrationConfig, isReadOnly } = this.props;
    const isNew = !(jiraIntegrationConfig && jiraIntegrationConfig.id);

    const components = [
      JiraConfigOverview,
      JiraBasicInfoForm,
      JiraStaticFieldForm,
      JiraDynamicFieldForm,
      JiraSeverityConfigForm,
    ];
    const JiraComponent = components[step];

    // We show the intial jira config that was passed for a step we haven't visited before.
    // Otherwise, we show the info that user has filled out (Occurs when user presses back btn)
    const initialValues = this.state.changes[step] || jiraIntegrationConfig;

    const hasCompletedForm: boolean = this.state.step === components.length;
    return (
      <>
        {this.state.step >= 1 && (
          <JiraStepper
            step={this.state.step - 1}
            labels={[
              'Basic Info',
              'Static Fields',
              'Dynamic Fields',
              'Severity Config',
              'Review',
            ]}
          />
        )}
        {!hasCompletedForm ? (
          <JiraComponent
            isNew={isNew}
            submitLabel={'Next'}
            isReadOnly={isReadOnly}
            loading={false}
            initialValues={initialValues}
            handleBackSubmit={this.handleBackSubmit}
            onSubmit={values =>
              this.onSubmit(
                values,
                hasCompletedForm,
                isNew,
                step,
                components.length,
              )
            }
          />
        ) : (
          <JiraReviewForm
            isNew={isNew}
            submitLabel={'Next'}
            isReadOnly={isReadOnly}
            loading={false}
            initialValues={Object.assign({}, ...this.state.changes)}
            handleBackSubmit={this.handleBackSubmit}
            onSubmit={values =>
              this.onSubmit(
                values,
                hasCompletedForm,
                isNew,
                step,
                components.length,
              )
            }
          />
        )}
      </>
    );
  }
}
