// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { dataCreators } from '@datatheorem/analytics';
import { PageHeading, SearchBox } from '@datatheorem/components';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import type { State as ReduxState } from '../../../ReduxStateType';
import tracking, { type TrackingProps } from 'react-tracking';
import JiraCard from '../../../components/jira/JiraCard';
import {
  jiraIntegrationConfigListViewPageMounted,
  jiraIntegrationConfigureGlobalClick,
} from '../../../actions';
import type { DispatchProps } from 'redux';
import { browserHistory } from '../../../clients/history';
import jss, { type InjectedJSS } from 'react-jss';
import { type UserRoleEnum } from '@datatheorem/enums/UserRoleEnum';
import JiraLogo from '../../jira/images/jira_logo.jpg';
import Table from './details/Table';
import NoGlobalJiraIntegrationConfigFound from './details/NoGlobalJiraIntegrationConfigFound';
import { JIRA_CONFIGS_LOADED_KEY } from '../../../strings';
import PageLoader from '../../theme/PageLoader';
import type {
  JiraIntegrationState,
  ConfigurationList,
} from '../../../reducers/jiraIntegration';

export const styles = {
  container: {
    marginTop: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    paddingRight: 20,
  },

  apps: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  card: {
    width: 300,
    marginLeft: 14,
    marginRight: 14,
    marginBottom: 24,
    alignSelf: 'stretch',
  },
};

type StateProps = {|
  finishedLoadingJiraConfigs: boolean,
  jiraIntegration: JiraIntegrationState,
  userRole: ?UserRoleEnum,
|};

type Props = {|
  ...StateProps,
  ...TrackingProps,
  ...DispatchProps<>,
  ...InjectedJSS<typeof styles>,
|};

type State = {
  filter: string,
};

function filterOnName(
  name: string,
  configurations: ConfigurationList,
): ConfigurationList {
  return configurations.filter(({ app }) =>
    app.name.toLowerCase().includes(name.toLowerCase()),
  );
}

/*
 * Display all the jira integrations that customer has available in Read mode.
 * Only allow to edit the global jira configuratio, otherwise we send user to
 * the local jira config where they can edit for their specific app
 */
export class JiraIntegrationDashboard extends Component<Props, State> {
  state = {
    filter: '',
  };

  componentDidMount() {
    this.props.dispatch(jiraIntegrationConfigListViewPageMounted());
  }

  render() {
    const {
      jiraIntegration,
      classes,
      finishedLoadingJiraConfigs,
      dispatch,
    } = this.props;

    // Let's not show anything until we have data
    if (!finishedLoadingJiraConfigs) return <PageLoader />;

    // No global config set, lets tell the user he needs to fill this out first before anything else
    if (jiraIntegration.userMustCreateGlobalConfig) {
      return (
        <NoGlobalJiraIntegrationConfigFound
          onClickBack={browserHistory.goBack}
          dispatch={dispatch}
        />
      );
    }

    return (
      <PageHeading
        backButton
        onClickBack={browserHistory.goBack}
        title={'Jira Integration Dashboard'}
      >
        <div style={{ width: '80%', margin: '24px auto' }}>
          <div className={classes.container}>
            <p>Manage your global and app-specific Jira configurations.</p>
            <h3 style={{ padding: 'inherit' }}>Global Jira Configuration</h3>
            <div className={classes.apps}>
              <div className={classes.card}>
                <JiraCard
                  avatar={JiraLogo}
                  title={'Global Jira Configuration'}
                  description={
                    'All Apps will default to this configuration if they do not have an app-specific configuration of their own'
                  }
                  buttonLabel={'Configure'}
                  onClick={() => {
                    dispatch(jiraIntegrationConfigureGlobalClick());
                  }}
                />
              </div>
            </div>

            <h3>App Specific Configurations</h3>
            <SearchBox
              value=""
              hintText={'Enter App Name'}
              onChange={filter => this.setState({ filter })}
            />

            <Table
              configurations={filterOnName(
                this.state.filter,
                jiraIntegration.configurations,
              )}
              dispatch={dispatch}
            />
          </div>
        </div>
      </PageHeading>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    finishedLoadingJiraConfigs: !!state.firstRequest[JIRA_CONFIGS_LOADED_KEY],
    jiraIntegration: state.jiraIntegration,
    userRole: state.currentUser && state.currentUser.role,
  };
};

type OwnProps = {
  ...TrackingProps,
  ...DispatchProps<>,
  ...InjectedJSS<typeof styles>,
};
export default compose(
  jss(styles),
  tracking(dataCreators.component(AnalyticsScreenEnum.SDLC_JIRA_LIST)),
  connect<Props, OwnProps, _, _, _, _>(mapStateToProps),
)(JiraIntegrationDashboard);
