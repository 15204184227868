//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

import SubscriptionEnum from '@datatheorem/enums/SubscriptionEnum';
import AppScanBlockedReasonEnum from '@datatheorem/enums/AppScanBlockedReasonEnum';
import { type AppScanBlockedReasonEnum as AppScanBlockedReasonEnumType } from '@datatheorem/enums/AppScanBlockedReasonEnum';
import type { ReleaseType as MobileAppReleaseEnumType } from '@datatheorem/enums/MobileAppReleaseTypeEnum';
import {
  type ShadowMetadataCountType,
  type ApplicationScan,
} from '@datatheorem/user-api/mobile_apps';
import MobileAppReleaseEnum from '@datatheorem/enums/MobileAppReleaseTypeEnum';
import { palette } from '@datatheorem/theme';

import Card from './../Card';
import Badge from './../Badge';
import ScanBadge from './../ScanBadge';
import IconLabel from './../IconLabel';
import Image from './../Image';

import ShadowScanChildCard from './ShadowScanChildCard';
import SubscriptionBadge from './SubscriptionBadge';
import { type CategoryMetadataCount } from '@datatheorem/user-api/mobile_apps';
import { type Integrations } from '@datatheorem/user-api/mobile_apps';
import { type TemplateScanTypeEnum } from '@datatheorem/enums/TemplateScanTypeEnum';
import { type TemplatePlatformEnum } from '@datatheorem/enums/TemplatePlatformEnum';

const PRE_PROD_ICON_URL = '/dist/images/pre-prod.svg';
const MISSING_ICON_IMAGE_URL = '/dist/images/question-mark.svg';
const ERROR_ICON_IMAGE_URL = '/dist/images/question-mark.svg';
const ICON_SIZE = 64;

const styles = {
  container: {
    minWidth: 500,
  },

  card: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  badgeHolder: {
    display: 'flex',
  },

  iconUrl: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    '& img': {
      width: ICON_SIZE,
      height: ICON_SIZE,
    },
    '& a': {
      width: ICON_SIZE,
      height: ICON_SIZE,
      display: 'block',
    },
  },

  iconSubscriptionBadge: {
    marginTop: -13,
    width: '100%',
  },

  // region Left
  left: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 250,
    overflow: 'hidden',
  },

  title: {
    paddingRight: 10,
    paddingLeft: 10,
    overflow: 'hidden',
  },

  nameHeader: {
    margin: 0,
    marginBottom: 5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  subTitle: {
    display: 'flex',
    maxWidth: 170,
  },

  scanStatus: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontSize: 12,
  },

  version: { fontSize: 12 },
  // endregion

  // region Right
  right: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 4,
    flexShrink: 4,
    flexBasis: 50,
    marginRight: 5,
  },
  // endregion
};

type State = {
  iconSource: string,
};

export type CardProps = {
  app_protection_percent?: ?string,
  blocked_scan_reason?: AppScanBlockedReasonEnumType,
  icon_url?: string,
  id: string,
  integrations?: Integrations,
  most_recent_scan?: ApplicationScan,
  name: string,
  platform: TemplatePlatformEnum,
  release_type?: MobileAppReleaseEnumType,
  subscription: TemplateScanTypeEnum,

  metadataCount: CategoryMetadataCount,

  subscriptionFormat: string => string,
  scanBlockedReasonFormat: (
    ?{ +start_date?: string, +end_date?: string },
    AppScanBlockedReasonEnumType,
  ) => string,

  detailsRenderer: (props: {
    id: string,
    metadataCount: CategoryMetadataCount,
    integrations?: Integrations,
  }) => Node,
  onIconClick: (id: string) => mixed,
  onScoreClick: (id: string) => mixed,
  onShadowScanPreviewClick: (id: string) => mixed,
} & InjectedJSS<typeof styles>;

export class AppSummaryCard extends PureComponent<CardProps, State> {
  state = {
    iconSource:
      this.props.release_type &&
      this.props.release_type === MobileAppReleaseEnum.PRE_PROD
        ? PRE_PROD_ICON_URL
        : this.props.icon_url || MISSING_ICON_IMAGE_URL,
  };

  render() {
    const {
      classes,

      id,
      name,
      platform,
      app_protection_percent,
      subscription,
      most_recent_scan,
      blocked_scan_reason,
      integrations,

      metadataCount,

      subscriptionFormat,
      scanBlockedReasonFormat,

      detailsRenderer,
      onIconClick,
      onScoreClick,
      onShadowScanPreviewClick,
    } = this.props;

    const { iconSource } = this.state;

    return (
      <div className={classes.container}>
        <Card status={`5px solid ${palette.brand}`}>
          <div className={classes.card}>
            {/* Left */}
            <div className={classes.left}>
              {/* Icon */}
              <div
                className={classes.iconUrl}
                style={onIconClick ? { cursor: 'pointer' } : {}}
                onClick={() => onIconClick(id)}
              >
                <div style={{ height: '100%', width: '100%' }}>
                  <Image
                    style={{ maxWidth: undefined, maxHeight: undefined }}
                    onFailSrc={ERROR_ICON_IMAGE_URL}
                    src={iconSource}
                    alt={name + ' icon'}
                  />
                </div>

                {/* Subscription */}
                <div className={classes.iconSubscriptionBadge}>
                  <SubscriptionBadge
                    size={'small'}
                    fill={true}
                    type={subscription || SubscriptionEnum.NO_SUBSCRIPTION}
                    format={subscriptionFormat}
                    radius={0}
                  />
                </div>
              </div>

              <div className={classes.title}>
                {/* Name */}
                <h4 className={classes.nameHeader}>
                  {/* Platform + Name */}
                  <IconLabel platform={platform}>
                    <span>
                      {name}
                      <sup className={classes.version}>
                        {most_recent_scan &&
                          ' ' + (most_recent_scan.app_version || '').toString()}
                      </sup>
                    </span>
                  </IconLabel>
                </h4>

                <div className={classes.subTitle}>
                  {/* Scan Status */}
                  <div className={classes.scanStatus}>
                    <ScanBadge
                      scan={most_recent_scan}
                      type={
                        blocked_scan_reason || AppScanBlockedReasonEnum.OTHER
                      }
                      format={scanBlockedReasonFormat}
                      includeLabel
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Right */}
            <div className={classes.right}>
              {/* Score */}
              <div style={{ flexGrow: 0, flexShrink: 1, flexBasis: 125 }}>
                {app_protection_percent && (
                  <Badge
                    inline
                    label={
                      <div
                        style={onScoreClick ? { cursor: 'pointer' } : {}}
                        onClick={() => onScoreClick(id)}
                      >
                        {app_protection_percent} Protected
                      </div>
                    }
                  />
                )}
              </div>

              <div style={{ flexGrow: 2, flexShrink: 2 }}>
                {detailsRenderer({
                  id: id,
                  integrations: integrations,
                  metadataCount: metadataCount,
                })}
              </div>
            </div>
          </div>
        </Card>

        {/* Shadow Scan */}
        <ShadowScanChildCard
          shadowMetadataCount={metadataCount ? metadataCount.SHADOW : null}
          onPreviewClick={() => onShadowScanPreviewClick(id)}
        />
      </div>
    );
  }

  static getHeight(shadowMetadataCount: ?ShadowMetadataCountType) {
    return ICON_SIZE + ShadowScanChildCard.getHeight(shadowMetadataCount);
  }
}

export default jss(styles)(AppSummaryCard);
