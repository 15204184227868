// @flow
import startCase from 'lodash/startCase';
import memoize from 'memoizee';

import FindingTargetStatus, {
  type FindingTargetStatusEnum,
} from '@datatheorem/enums/FindingTargetStatusEnum';
import { sortObjectsByDateProperty } from '@datatheorem/date';

import type { Finding, Target, TargetStatus } from './../types';

type Targetlike = { +statuses: $ReadOnlyArray<TargetStatus> };

export function statusToFriendlyString(
  status?: FindingTargetStatusEnum,
): string {
  switch (status) {
    case FindingTargetStatus.CLOSED_FIXED:
      return 'Fixed';
    case FindingTargetStatus.CLOSED_RISK_ACCEPTED:
      return 'Risk Accepted';
    case FindingTargetStatus.CLOSED_COMPENSATING_CONTROL:
      return 'Compensating Control';
    case FindingTargetStatus.CLOSED_ITEM_NOT_FOUND:
      return 'Not Found';
    case FindingTargetStatus.OPEN_NO_SUBSCRIPTION:
      return 'Open';
    case FindingTargetStatus.OPEN_READY_TO_RESCAN:
      return 'Open';
    case null:
    case undefined:
      return '';
    default:
      return startCase(status.toLowerCase()); // e.g., OPEN_NO_SUBSCRIPTION => Open No Subscription
  }
}

export const getLatestStatusObject = memoize((target: Targetlike) => {
  if (!target || !target.statuses) {
    return null;
  }

  return target.statuses.slice().sort(sortStatuses)[0];
});

export const getLatestStatus = memoize((target: Targetlike) => {
  const statusObject = getLatestStatusObject(target);

  return statusObject ? statusObject.status : null;
});

export function isTargetClosed(target: Target): boolean {
  return isClosedStatus(getLatestStatus(target));
}

export function isClosedStatus(status?: ?string): boolean {
  return typeof status === 'string' && status.indexOf('CLOSED_') === 0;
}

export function isNeutralStatus(status: ?string): boolean {
  return (
    status === FindingTargetStatus.CLOSED_RISK_ACCEPTED ||
    status === FindingTargetStatus.CLOSED_COMPENSATING_CONTROL
  );
}

export function isTargetOpen(target: Target): boolean {
  return isOpenStatus(getLatestStatus(target));
}

export function isOpenStatus(status?: ?string): boolean {
  return typeof status === 'string' && !isClosedStatus(status);
}

export function isFixedStatus(status?: ?string): boolean {
  return isClosedStatus(status) && !isNeutralStatus(status);
}

export const getCalculatedStatus = memoize(
  (finding: Finding): ?string => {
    if (
      !finding.targets ||
      !finding.targets.length ||
      typeof finding.targets.slice !== 'function'
    ) {
      if (typeof finding.aggregated_status === 'string') {
        return finding.aggregated_status;
      } else {
        return null;
      }
    }

    const statuses = finding.targets.slice().sort(sortTargets)[0].statuses;
    if (!statuses || !statuses.length) {
      return null;
    }

    return statuses.slice().sort(sortStatuses)[0].status;
  },
);

export const getTargetStatus = (target: Target) => {
  return target.statuses.slice().sort(sortStatuses)[0].status;
};

export function sortStatuses(a: TargetStatus, b: TargetStatus) {
  return sortObjectsByDateProperty('date', a, b);
}

const Groups = {
  OPEN: 'OPEN',
  NEUTRAL: 'NEUTRAL',
  CLOSED: 'CLOSED',
};

const StatusGroupValues = {
  [Groups.OPEN]: 1,
  [Groups.NEUTRAL]: 2,
  [Groups.CLOSED]: 3,
};

const getGroup = memoize(status => {
  if (isClosedStatus(status)) {
    if (isNeutralStatus(status)) {
      return Groups.NEUTRAL;
    } else {
      return Groups.CLOSED;
    }
  } else {
    return Groups.OPEN;
  }
});

export function sortTargets(a: Target, b: Target) {
  if (!a || !a.statuses || !a.statuses[0] || !a.statuses[0].status) {
    return 0;
  }

  const aGroup = getGroup(getLatestStatus(a));

  if (!b || !b.statuses || !b.statuses[0] || !b.statuses[0].status) {
    return 0;
  }

  const bGroup = getGroup(getLatestStatus(b));

  const initialStatusSortValue =
    StatusGroupValues[aGroup] - StatusGroupValues[bGroup];

  if (initialStatusSortValue !== 0) {
    return initialStatusSortValue;
  }

  // second priority is date: for open targets, oldest first, for closed targets newest first
  if (aGroup === Groups.OPEN) {
    return sortByDateCreated(a, b) * -1;
  } else {
    return sortByDateCreated(a, b);
  }
}

function sortByDateCreated(a, b) {
  return sortObjectsByDateProperty('date_created', a, b);
}
