//@flow
import React, { type Element, PureComponent } from 'react';

import logo from '@datatheorem/brand/logo.png';

const TPX = 10;
const LOGO_WIDTH = 170;

const styles = {
  wrapper: {
    textAlign: 'center',
    maxWidth: LOGO_WIDTH,
    maxHeight: 200,
    margin: '1em auto',
  },
  title: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 3 * TPX,
    marginBottom: 3 * TPX,
  },
  img: {
    maxWidth: '100%',
    marginBottom: 2 * TPX,
  },
};

type Props = {
  title?: string,
  children?: Element<*>,
};

export default class BigLogo extends PureComponent<Props> {
  render() {
    const { title, children } = this.props;

    return (
      <div style={styles.wrapper}>
        <img src={logo} alt="Data Theorem" style={styles.img} />
        {title && <div style={styles.title}>{title}</div>}
        {children}
      </div>
    );
  }
}
