//@flow
import React, { PureComponent } from 'react';
import ListItem from 'material-ui/List/ListItem';
import Divider from 'material-ui/Divider';

import { type CommunicationTypes } from '@datatheorem/user-api/insights';

import InsightsCardWrapper from './InsightsCardWrapper';
import ViewMoreButton from './../ViewMoreButton';

type Props = {|
  communicates_with_ip_addresses: CommunicationTypes,
  expanded: boolean,
  maxVisibleCount: number,
  onClickExpand: () => mixed,
|};

export default class IPAddressesCard extends PureComponent<Props> {
  render() {
    const { props } = this;
    const {
      communicates_with_ip_addresses,
      expanded,
      maxVisibleCount,
      onClickExpand,
    } = props;

    if (!communicates_with_ip_addresses) {
      return null;
    }

    return (
      <InsightsCardWrapper header={'Connects To Following IPs'}>
        {communicates_with_ip_addresses.map((ip_address, i) => {
          if (!expanded && i >= maxVisibleCount) {
            return null;
          }

          return (
            <div key={ip_address}>
              <Divider />
              <ListItem rightIcon={null} primaryText={ip_address} />
            </div>
          );
        })}
        {!expanded &&
        communicates_with_ip_addresses.length > maxVisibleCount ? (
          <>
            <Divider />
            <ViewMoreButton
              label="ip addresses"
              count={communicates_with_ip_addresses.length - maxVisibleCount}
              onRequestViewMore={onClickExpand}
            />
          </>
        ) : null}
      </InsightsCardWrapper>
    );
  }
}
