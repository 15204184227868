// @flow
import React, { PureComponent } from 'react';
import { type FormikProps } from 'formik';

import { IconLabel, ErrorMessageBlock } from '@datatheorem/components';
import type { APIKey } from '@datatheorem/user-api/api_keys';

import Page from '../theme/Page';
import PanelItemMobileApps from '../form/PanelItemMobileApps';
import PanelItemTextField from '../form/PanelItemTextField';
import PanelItemToggle from '../form/PanelItemToggle';
import PanelSubmitField from '../form/PanelSubmitField';
import { Field } from 'formik';
import { type Application } from '@datatheorem/user-api/mobile_apps';
import {
  setFieldValueHelper,
  extractResultsAPISubObjectFromValues,
} from './EditForm';

type OwnProps = {|
  apps: $ReadOnlyArray<Application>,
  formError: ?string,
  loading: boolean,
  dirty: boolean,
  submitLabel: string,
  onSubmit: (e: SyntheticMouseEvent<HTMLElement>) => mixed,
  apiKey?: ?APIKey,
|};

type Props = {| ...OwnProps, ...FormikProps<*> |};

export default class EditForm extends PureComponent<Props> {
  render() {
    const {
      formError,
      submitLabel,
      onSubmit,
      loading,
      values,
      apps,
      dirty,
    } = this.props;
    const fieldPropFactoryFromName = name => ({
      name,
      //$FlowFixMe Flow 0.86 upgrade
      setFieldValue: value => setFieldValueHelper(name, value, this.props),
    });
    const results_api = extractResultsAPISubObjectFromValues(values);
    return (
      <Page>
        {formError && <ErrorMessageBlock>{formError}</ErrorMessageBlock>}
        <Field
          label="Name"
          {...fieldPropFactoryFromName('name')}
          component={PanelItemTextField}
        />
        <Field
          {...fieldPropFactoryFromName('results_api.access')}
          toggled={
            results_api &&
            (results_api.access || results_api.can_access_all_apps)
          }
          label="Results API"
          toggleLabel="Enabled"
          component={PanelItemToggle}
        >
          <Field
            {...fieldPropFactoryFromName('results_api.can_access_all_apps')}
            label="Results API Apps"
            toggleLabel="All Apps Access"
            component={PanelItemToggle}
          />
          {results_api &&
            (results_api.access && !results_api.can_access_all_apps) && (
              <div style={{ margin: 20, maxHeight: 210, overflow: 'auto' }}>
                <Field
                  {...fieldPropFactoryFromName(
                    'results_api.can_access_app_ids',
                  )}
                  component={PanelItemMobileApps}
                  items={
                    apps &&
                    apps.map(app => ({
                      label: (
                        <IconLabel platform={app.platform}>
                          {app.name}
                        </IconLabel>
                      ),
                      value: app.id,
                    }))
                  }
                />
              </div>
            )}
        </Field>
        <Field
          {...fieldPropFactoryFromName('management_api.access')}
          label="Management API"
          toggleLabel="Enabled"
          component={PanelItemToggle}
        />
        <PanelSubmitField
          submitLabel={submitLabel}
          loading={loading}
          disabled={!dirty || loading}
          onSubmit={onSubmit}
        />
      </Page>
    );
  }
}
