//@flow
import { createSelector } from 'reselect';
import { groupBy, sumBy } from 'lodash/fp';
import {
  findingFromParam,
  findingsFromCategory,
  type SearchAndCategoryParam,
  type FindingIdOrLocationParam,
} from './securityFindings';
import numeral from 'numeral';
import { getListOfHigherSubscriptions } from '../util/appUtil';
import type { State } from '../ReduxStateType';
import {
  getMetadataCount,
  getTotalMetadataCount,
} from '@datatheorem/user-api/util/metadataCount';
import type {
  Application,
  Applications,
  ApplicationWithMetadataCount,
  ApplicationsWithMetadataCount,
  CategoryMetadataCount,
  AppProtectionTask,
} from '@datatheorem/user-api/mobile_apps';
import { default as MobileAppReleaseTypeEnum } from '@datatheorem/enums/MobileAppReleaseTypeEnum';

export const apps = (state: State) => state.apps;

export const sortedApps = createSelector<State, {}, Applications, _>(
  apps,

  apps =>
    apps
      .slice()
      .sort((a, b) =>
        a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()),
      ),
);

export const storeApps = createSelector<State, {}, Applications, _>(
  sortedApps,
  apps =>
    apps.filter(app => app.release_type === MobileAppReleaseTypeEnum.APP_STORE),
);

// region MetadataCount
// MetadataCount should be transformed from the API or pushed back into the server.
export const appsWithMetadataCount = createSelector<
  State,
  {},
  ApplicationsWithMetadataCount,
  _,
>(sortedApps, apps =>
  apps.map(app => ({
    metadataCount: getMetadataCount(app.metadata),
    ...app,
  })),
);

export const totalMetadataCount = createSelector<
  State,
  {},
  CategoryMetadataCount,
  _,
>(appsWithMetadataCount, apps =>
  getTotalMetadataCount(
    apps
      .map<ApplicationWithMetadataCount>(app => ({
        metadataCount: getMetadataCount(app.metadata),
        ...app,
      }))
      .map(app => app.metadataCount),
  ),
);

export const storeAppsWithMetadataCount = createSelector<
  State,
  {},
  ApplicationsWithMetadataCount,
  _,
>(storeApps, storeApps =>
  storeApps.map(storeApp => ({
    metadataCount: getMetadataCount(storeApp.metadata),
    ...storeApp,
  })),
);

export const totalStoreAppsMetadataCount = createSelector<
  State,
  {},
  CategoryMetadataCount,
  _,
>(storeAppsWithMetadataCount, storeApps =>
  getTotalMetadataCount(
    storeApps
      .map(storeApp => ({
        metadataCount: getMetadataCount(storeApp.metadata),
        ...storeApp,
      }))
      .map(app => app.metadataCount),
  ),
);

// endregion MetadataCount
const getPercentageOfCompletedIntegrationOfCiCd = ciCdIntegrations => {
  return (
    (sumBy(ci_cd => (ci_cd ? 1 : 0))(ciCdIntegrations) /
      ciCdIntegrations.length) *
    100
  );
};

export const percentageOfStoreAppsIntegratedWithCiCd = createSelector<
  State,
  {},
  number,
  _,
>(storeApps, storeApps =>
  getPercentageOfCompletedIntegrationOfCiCd(
    storeApps.map(
      storeApp =>
        // integrations should alway be defined here.
        storeApp.integrations ? storeApp.integrations.ci_cd : false,
    ),
  ),
);

export type AppsByPlatform = {
  IOS?: ?$ReadOnlyArray<Application>,
  ANDROID?: ?$ReadOnlyArray<Application>,
  WINDOWS_PHONE?: ?$ReadOnlyArray<Application>,
  ANDROID_AMAZON?: ?$ReadOnlyArray<Application>,
  ANDROID_OCULUS?: ?$ReadOnlyArray<Application>,
};

export const appsByPlatform = createSelector<State, {}, AppsByPlatform, _>(
  sortedApps,
  groupBy(app => app.platform),
);

export type AppIdParam = { +match: { +params: { +appId: void | string } } };

export const appFromParam = createSelector<
  State,
  AppIdParam,
  ?Application,
  _,
  _,
>(
  apps,
  (state, props) =>
    typeof props.match.params.appId === 'string'
      ? props.match.params.appId
      : null,

  (apps, appId) =>
    appId
      ? apps.find(
          app =>
            app && app.id && app.id.toString() === (appId && appId.toString()),
        )
      : null,
);

export const appsFromCategory = createSelector<
  State,
  SearchAndCategoryParam,
  Applications,
  _,
  _,
>(
  sortedApps,
  findingsFromCategory,

  (apps, findingsFromCategory) =>
    apps.filter(app =>
      findingsFromCategory.find(finding => finding.mobile_app_id === app.id),
    ),
);

export const metadataCountForAppFromParam = createSelector<
  State,
  AppIdParam,
  null | CategoryMetadataCount,
  _,
>(
  appFromParam,

  app => {
    if (!app) {
      return null;
    }

    return getMetadataCount(app.metadata);
  },
);

export const appFromFindingParam = createSelector<
  State,
  FindingIdOrLocationParam,
  ?Application,
  _,
  _,
>(
  apps,
  findingFromParam,

  (apps, finding) =>
    apps && finding && typeof finding.mobile_app_id === 'string'
      ? apps.find(app => finding && app.id === finding.mobile_app_id)
      : null,
);

export const priorityAlertsCount = createSelector<State, {}, number, _>(
  apps,

  apps =>
    apps.reduce(
      (count, app) =>
        count +
        numeral(
          app.metadata &&
            app.metadata.open_security_finding_priority_alerts_count,
        ).value(),
      0,
    ),
);

export const subscriptionsBeyondAppFromParam = createSelector<
  State,
  AppIdParam,
  null | $ReadOnlyArray<string>,
  _,
>(
  appFromParam,

  app => {
    if (!app) {
      return null;
    }

    const subs = getListOfHigherSubscriptions(app.subscription);

    if (!subs) {
      return null;
    }

    return subs.filter(sub => sub !== 'APP_LOGIC');
  },
);

export const areAppsLoading = (state: State) => state.progressIndicator === 1;

export const tasks = (state: State) => state.tasks;

export const tasksForAppFromParam = createSelector<
  State,
  AppIdParam,
  $ReadOnlyArray<AppProtectionTask>,
  _,
  _,
>(
  tasks,
  appFromParam,

  (tasks, app) => tasks.filter(task => app && task.mobile_app_id === app.id),
);
