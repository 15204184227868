//@flow
import { createAction } from 'redux-actions';
import {
  type SecurityFinding,
  type TargetStatus,
} from '@datatheorem/findings/types'; //eslint-disable-line no-unused-vars

import { type FindingPriorityEnum as FindingPriorityEnumType } from '@datatheorem/enums/FindingPriorityEnum'; //eslint-disable-line no-unused-vars
import type { FindingTargetStatusEnum } from '@datatheorem/enums/FindingTargetStatusEnum';

export const updateStatusStarted = createAction<
  'UPDATE_STATUS_STARTED',
  [string, string],
  { findingId: string, targetId: string },
>('UPDATE_STATUS_STARTED', (findingId, targetId) => ({ findingId, targetId }));

export const updateStatusFailure = createAction<
  'UPDATE_STATUS_FAILURE',
  [string, string],
  { findingId: string, targetId: string },
>('UPDATE_STATUS_FAILURE', (findingId, targetId) => ({ findingId, targetId }));

export const updateStatusSuccess = createAction<
  'UPDATE_STATUS_SUCCESS',
  [string, string, TargetStatus],
  { findingId: string, status: TargetStatus, targetId: string },
>('UPDATE_STATUS_SUCCESS', (findingId, targetId, status) => ({
  findingId,
  status,
  targetId,
}));

export const updateStatus = createAction<
  'UPDATE_STATUS',
  [string, string, FindingTargetStatusEnum],
  {
    securityFindingId: string,
    targetId: string,
    newStatus: FindingTargetStatusEnum,
  },
>('UPDATE_STATUS', (securityFindingId, targetId, newStatus) => ({
  securityFindingId,
  targetId,
  newStatus,
}));

export const updateExternalIdStarted = createAction<
  'UPDATE_EXTERNAL_ID_STARTED',
  [string, string],
  { findingId: string, targetId: string },
>('UPDATE_EXTERNAL_ID_STARTED', (findingId, targetId) => ({
  findingId,
  targetId,
}));

export const updateExternalIdFailure = createAction<
  'UPDATE_EXTERNAL_ID_FAILURE',
  [string, string, string],
  {
    findingId: string,
    targetId: string,
    message: string,
  },
>('UPDATE_EXTERNAL_ID_FAILURE', (findingId, targetId, message) => ({
  findingId,
  targetId,
  message,
}));

export const updateExternalIdSuccess = createAction<
  'UPDATE_EXTERNAL_ID_SUCCESS',
  [string, string, SecurityFinding],
  { findingId: string, targetId: string, returnedFinding: SecurityFinding },
>('UPDATE_EXTERNAL_ID_SUCCESS', (findingId, targetId, returnedFinding) => ({
  findingId,
  targetId,
  returnedFinding,
}));

export const exportTargetJiraClicked = createAction<
  'finding/target/jira_export/clicked',
  [string, string],
  {
    securityFindingId: string,
    targetId: string,
  },
>('finding/target/jira_export/clicked', (securityFindingId, targetId) => ({
  securityFindingId,
  targetId,
}));

export const changePriority = createAction<
  'CHANGE_PRIORITY',
  [FindingPriorityEnumType, ?SecurityFinding],
  { priority: FindingPriorityEnumType, finding: ?SecurityFinding },
>('CHANGE_PRIORITY', (priority, finding) => ({
  priority,
  finding,
}));

export const securityFindingOpened = createAction<
  'SECURITY_FINDING_OPENED',
  [string],
  { id: string },
>('SECURITY_FINDING_OPENED', id => ({ id }));
