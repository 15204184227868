//@flow
import React, { PureComponent } from 'react';
import BigList from '../theme/BigList';
import Activity from '../theme/Activity';
import type { ActivityLogEvent } from '@datatheorem/user-api/activity';
import List from '@material-ui/core/List';

type Props = {
  activities?: $ReadOnlyArray<ActivityLogEvent>,
  loadMoreRows: ?() => mixed,
  loading: ?boolean,
  totalCount: number,
};

export default class ActivityList extends PureComponent<Props> {
  render() {
    const { activities, loadMoreRows, loading, totalCount } = this.props;

    if (!activities || !activities.length) {
      return <div>No activity yet!</div>;
    }

    return (
      <List>
        <BigList
          totalCount={totalCount}
          rowHeight={72}
          currentCount={activities.length}
          isRowLoaded={({ index }) => !!activities[index]}
          loadMoreRows={loadMoreRows}
          loading={loading}
        >
          {({ index, style, key }) => (
            <div key={key} style={style}>
              <Activity activity={activities[index]} />
            </div>
          )}
        </BigList>
      </List>
    );
  }
}
