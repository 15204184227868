//@flow
import { createAction } from 'redux-actions';
import type { Application } from '@datatheorem/user-api/mobile_apps';

export const previewShadowScanFindingsForApp = createAction<
  'PREVIEW_SHADOW_SCAN_FINDINGS_FOR_APP',
  [string],
  { id: string },
>('PREVIEW_SHADOW_SCAN_FINDINGS_FOR_APP', (id: string) => ({ id }));

export type InquireShadowScanFindingsActionParams = $ReadOnly<{|
  title?: ?string,
  count?: ?number,
  breakdown?: ?{},
  app?: ?Application,
|}>;

export const inquireShadowScanFindings = createAction<
  'INQUIRE_SHADOW_SCAN_FINDINGS',
  [InquireShadowScanFindingsActionParams],
  InquireShadowScanFindingsActionParams,
>(
  'INQUIRE_SHADOW_SCAN_FINDINGS',
  (shadowscanParams: InquireShadowScanFindingsActionParams) => shadowscanParams,
);
