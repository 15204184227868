//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import {
  updateStatusSuccess,
  updateExternalIdSuccess,
} from '../actions/securityFindings';
import flatMap from 'lodash/fp/flatMap';
import { addCommentRequestSucceeded, updateFindings } from '../actions';
import type { SecurityFinding } from '@datatheorem/findings/types';
import { Raven } from '@datatheorem/global';

export type SecurityFindingsState = $ReadOnlyArray<SecurityFinding>;

// $FlowFixMe Flow85
export default createReducerCreator({
  initialState: [],
  equalityCheck: (a, b) => a.id === b.id,
})(
  updateInList(updateFindings.toString()),
  (
    state: $ReadOnlyArray<SecurityFinding>,
    action,
  ): $ReadOnlyArray<SecurityFinding> => {
    if (!action || !action.payload || typeof action.payload !== 'object') {
      return state;
    }

    const { status, targetId } = action.payload;
    if (
      action.type === updateStatusSuccess.toString() &&
      status &&
      typeof status === 'object' &&
      targetId
    ) {
      const target = flatMap(finding => finding.targets)(state).find(
        target => target.id === targetId,
      );

      if (!target) {
        return state;
      }

      const finding = state.find(
        finding => finding.id === target.security_finding_id,
      );

      if (!finding) {
        return state;
      }

      const newFinding = {
        ...finding,
        aggregated_status: status.status,
        targets: finding.targets
          .filter(currentTarget => currentTarget.id !== target.id)
          .concat({
            ...target,
            statuses: [...target.statuses, status],
          }),
      };

      return state
        .filter(finding => finding.id !== newFinding.id)
        .concat(newFinding);
    }

    return state;
  },

  (state = [], action) => {
    if (!action || !action.payload || typeof action.payload !== 'object') {
      return state;
    }
    const { comment } = action.payload;
    if (
      action.type === addCommentRequestSucceeded.toString() &&
      comment &&
      typeof comment === 'object' &&
      comment.security_finding_id
    ) {
      const finding = state.find(
        finding => finding.id === comment.security_finding_id,
      );

      if (!finding) {
        return state;
      }

      const newFinding = {
        ...finding,
        notes: finding.notes && finding.notes.slice().concat(comment),
      };

      return [
        ...state.filter(finding => finding.id !== newFinding.id),
        newFinding,
      ];
    }

    return state;
  },

  (
    state: $ReadOnlyArray<SecurityFinding>,
    action,
  ): $ReadOnlyArray<SecurityFinding> => {
    if (!action || !action.payload || typeof action.payload !== 'object') {
      return state;
    }

    const { targetId, returnedFinding } = action.payload;
    if (action.type === updateExternalIdSuccess.toString() && targetId) {
      const target = flatMap(finding => finding.targets)(state).find(
        target => target.id === targetId,
      );

      if (!target) {
        return state;
      }

      const finding = state.find(
        finding => finding.id === target.security_finding_id,
      );

      if (!finding) {
        return state;
      }

      const externalId = returnedFinding.targets.find(
        currentTarget => currentTarget.id === target.id,
      ).external_id;

      if (!externalId) {
        Raven.captureException(
          new Error(
            'Got an updateExternalId success action back without an external ID!',
          ),
          {
            extra: {
              action_recieved: action,
            },
          },
        );
        return state;
      }

      const newFinding = {
        ...finding,
        targets: finding.targets
          .filter(currentTarget => currentTarget.id !== target.id)
          .concat({
            ...target,
            external_id: externalId,
          }),
      };

      return state
        .filter(finding => finding.id !== newFinding.id)
        .concat(newFinding);
    }

    return state;
  },
);
