//@flow
import React, { PureComponent } from 'react';
import Page from '../../theme/Page';
import { Button } from '@datatheorem/components';

import { type JiraIntegrationConfig } from '@datatheorem/user-api/jira_config';

type Props = {
  submitLabel: string,
  onSubmit: () => void,
  loading: boolean,
  handleBackSubmit: () => void,
  initialValues: $Shape<JiraIntegrationConfig>,
};

export default class JiraReviewForm extends PureComponent<Props> {
  render() {
    const { onSubmit, loading, handleBackSubmit, initialValues } = this.props;

    return (
      <Page>
        <h2>Review</h2>
        <p>
          <strong>Base URL:</strong> {initialValues.base_url}
        </p>

        <p>
          <strong>Username:</strong> {initialValues.username}
        </p>

        <p>
          <strong>API Token:</strong> {'Securely Stored'}
        </p>

        <p>
          <strong>Type Of Issue Name:</strong>{' '}
          {initialValues.type_of_issue_name}
        </p>

        <p>
          <strong>Export Filter:</strong> {initialValues.export_filter}
        </p>

        <p>
          <strong>Export Pre Prod:</strong>{' '}
          {initialValues.export_pre_prod ? (
            <span>True</span>
          ) : (
            <span>False</span>
          )}
        </p>

        <p>
          <strong>Export Prod:</strong>{' '}
          {initialValues.export_prod ? <span>True</span> : <span>False</span>}
        </p>

        <p>
          <strong>Project Key:</strong> {initialValues.project_key_or_id}
        </p>

        {initialValues.severity_field_config && (
          <p>
            <strong>Severity Field Config:</strong> <br />
            <li>
              <strong>Field ID: </strong>{' '}
              {initialValues.severity_field_config.field_id}
            </li>
            <li>
              <strong>High Severity Field ID:</strong>{' '}
              {initialValues.severity_field_config.high_severity_value_id}
            </li>
            <li>
              <strong>Medium Severity Field ID: </strong>{' '}
              {initialValues.severity_field_config.medium_severity_value_id}
            </li>
            <li>
              <strong>Low Severity Field ID</strong>{' '}
              {initialValues.severity_field_config.low_severity_value_id}
            </li>
          </p>
        )}

        {initialValues.static_fields &&
          initialValues.static_fields.map((field, index) => (
            <p key={index}>
              <strong>Static Field ID #{index + 1}</strong> {field.field_id}
              <br />
              <strong>Static Field Value #{index + 1}</strong>{' '}
              {field.field_value}
            </p>
          ))}

        {initialValues.dynamic_fields &&
          initialValues.dynamic_fields.map((field, index) => (
            <p key={index}>
              <strong>Dynamic Field ID #{index + 1}</strong> {field.field_id}
              <br />
              <strong>Dynamic Field Value #{index + 1}</strong> {field.value}
            </p>
          ))}

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: 10,
            paddingTop: 10,
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          <Button
            onClick={handleBackSubmit}
            isLoading={loading}
            variant="text"
            hoverColor={'none'}
            rippleColor={'none'}
            ariaLabel="Back"
          >
            Back
          </Button>
          <span style={{ width: 10 }} />
          <Button onClick={onSubmit} isLoading={loading} ariaLabel="Submit">
            Submit
          </Button>
        </div>
      </Page>
    );
  }
}
