//@flow
import React, { PureComponent, type Node } from 'react';
import Dropzone from 'react-dropzone';
import UploadIcon from '@material-ui/icons/CloudUpload';

type OwnProps = {|
  onDropFile: (file: File) => void,
  ariaLabel?: string,
  children: Node,
|};

type ForwardRefProps = {|
  innerRef: { current: null | Dropzone } | ((null | Dropzone) => mixed),
|};

type Props = {
  ...OwnProps,
  ...ForwardRefProps,
};

type State = {
  dragging: boolean,
};

class AppDropzone extends PureComponent<Props, State> {
  render() {
    const { children, ariaLabel } = this.props;

    const styles = {
      zIndex: 10000,
      boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,0.6)',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
    };

    return (
      <Dropzone
        ref={this.props.innerRef}
        multiple={false}
        name="file"
        onDrop={this.handleDrop}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div style={{ width: '100%', height: '100%' }} {...getRootProps()}>
            <input aria-label={ariaLabel} {...getInputProps()} />
            {isDragActive && (
              <div style={styles}>
                <div
                  style={{
                    width: '40%',
                    height: '40%',
                    margin: 'auto',
                  }}
                >
                  <div
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: 30,
                      color: '#fff',
                    }}
                  >
                    <UploadIcon
                      style={{
                        width: '100px !important',
                        height: '100px !important',
                        color: '#fff !important',
                        verticalAlign: 'middle',
                        marginRight: 10,
                      }}
                    />
                    Drop your app to upload it
                  </div>
                </div>
              </div>
            )}
            {children}
          </div>
        )}
      </Dropzone>
    );
  }

  handleDrop = (acceptedFiles: $ReadOnlyArray<File>) => {
    this.props.onDropFile(acceptedFiles[0]);
  };
}

export default React.forwardRef<OwnProps, Dropzone>((props, ref) => (
  <AppDropzone innerRef={ref} {...props} />
));
