//@flow
import CompliancePolicyEnum from '@datatheorem/enums/CompliancePolicyEnum';

import caloppaImage from './images/caloppa.jpg';
import coppaImage from './images/coppa.svg';
import gdprImage from './images/gdpr.svg';
import hipaaImage from './images/hipaa.svg';
import vppaImage from './images/vppa.svg';
import owaspImage from './images/owasp.png';
import ftcImage from './images/ftc.png';
import pciImage from './images/pci.png';
import mitreImage from './images/mitre.png';
import caprivacyImage from './images/caprivacy.png';

export default {
  [CompliancePolicyEnum.CALOPPA]: {
    src: caloppaImage,
    label: 'CalOPPA',
    title: 'California Online Privacy Protection Act',
    smallWidth: 20,
    smallHeight: 20,
    largeWidth: 82,
    largeHeight: 82,
  },
  [CompliancePolicyEnum.GDPR]: {
    src: gdprImage,
    label: 'GDPR',
    title: 'General Data Protection Regulation',
    smallWidth: 24,
    smallHeight: 24,
    largeWidth: 82,
    largeHeight: 82,
  },
  [CompliancePolicyEnum.PCI]: {
    src: pciImage,
    label: 'PCI',
    title: 'Payment Card Industry',
    smallWidth: 20,
    smallHeight: 20,
    largeWidth: 82,
    largeHeight: 82,
  },
  [CompliancePolicyEnum.HIPAA]: {
    src: hipaaImage,
    label: 'HIPAA',
    title: 'Health Insurance Portability and Accountability Act',
    smallWidth: 24,
    smallHeight: 20,
    largeWidth: 82,
    largeHeight: 82,
  },
  [CompliancePolicyEnum.FTC]: {
    src: ftcImage,
    label: 'FTC',
    title: 'Federal Trade Commission',
    smallWidth: 20,
    smallHeight: 20,
    largeWidth: 82,
    largeHeight: 82,
  },
  [CompliancePolicyEnum.OWASP]: {
    src: owaspImage,
    label: 'OWASP',
    title: 'OWASP Mobile Security',
    smallWidth: 20,
    smallHeight: 20,
    largeWidth: 82,
    largeHeight: 82,
  },
  [CompliancePolicyEnum.COPPA]: {
    src: coppaImage,
    label: 'COPPA',
    title: "Children's Online Privacy Protection Act",
    smallWidth: 24,
    smallHeight: 24,
    largeWidth: 82,
    largeHeight: 82,
  },
  [CompliancePolicyEnum.VPPA]: {
    src: vppaImage,
    label: 'VPPA',
    title: 'Video Privacy Protection Act',
    smallWidth: 24,
    smallHeight: 20,
    largeWidth: 82,
    largeHeight: 82,
  },
  [CompliancePolicyEnum.CCPA]: {
    src: caprivacyImage,
    label: 'CCPA',
    title: 'California Consumer Privacy Act',
    smallWidth: 20,
    smallHeight: 20,
    largeWidth: 82,
    largeHeight: 82,
  },
  [CompliancePolicyEnum.MITRE]: {
    src: mitreImage,
    label: 'MITRE ATT&CK',
    title: 'MITRE ATT&CK',
    smallWidth: 36,
    smallHeight: 7,
    largeWidth: 82,
    largeHeight: 16,
  },
};
