// @flow
import { createSelector } from 'reselect';
import {
  assoc,
  filter,
  find,
  get,
  getOr,
  groupBy,
  map,
  pipe,
  size,
  sumBy,
} from 'lodash/fp';

import {
  filterFindings,
  sortFindings,
  searchFindings,
  calcScore,
  type FindingsCriteria,
  type FindingWithApp,
} from '../util/filtersUtil';
import { search, type SearchParam } from '@datatheorem/findings/search';

import type { FilterStore } from '../reducers/filters';

import type { State } from '../ReduxStateType';

import { type Application } from '@datatheorem/user-api/mobile_apps';

type FindingsWithApp = $ReadOnlyArray<FindingWithApp>;

const findings = ({ securityFindings }: State) => securityFindings || [];
const apps = ({ apps }: State) => apps || [];

/** Adds Finding's App to the finding */
export const findingsWithApp = createSelector<State, {}, FindingsWithApp, _, _>(
  findings,
  apps,
  (findings, apps) =>
    map(f => assoc(['app'], find({ id: f.mobile_app_id }, apps), f), findings),
);

const advancedFilterSel = ({ advancedFilter }: State) => advancedFilter;

/** Select Findings by filtering with current selected criteria */
export const filteredFindingsSel = createSelector<
  State,
  {},
  FindingsWithApp,
  _,
  _,
>(
  findingsWithApp,
  advancedFilterSel,
  (findings, advancedFilter) =>
    filterFindings(advancedFilter.findingsCriteria, findings),
);

// Get search text from query strings
export const getSearchTextFinding = createSelector<
  State,
  SearchParam,
  string,
  _,
  _,
>(
  search,
  searchParams => {
    return (
      (searchParams
        ? Array.isArray(searchParams.searchTerm)
          ? searchParams.searchTerm[0]
          : searchParams.searchTerm
        : '') || ''
    );
  },
);

/** Select Findings by filtering with search text */
export const searchedFindingsSel = createSelector<
  State,
  SearchParam,
  $ReadOnlyArray<FindingWithApp>,
  _,
  _,
  _,
>(
  getSearchTextFinding,
  filteredFindingsSel,
  searchFindings,
);

export const sortedFindingsSel = createSelector<
  State,
  SearchParam,
  FindingsWithApp,
  _,
  _,
  _,
  _,
  _,
>(
  searchedFindingsSel,
  advancedFilterSel,
  (filteredFindings, advancedFilter) =>
    sortFindings(advancedFilter.findingsCriteria.sortBy, filteredFindings),
);

const savedFilterSel = (_, props) => props.value;

export const getSavedFilterSel = () =>
  createSelector<
    State,
    { value: $ElementType<FilterStore, 'findingsCriteria'> },
    $ReadOnlyArray<FindingWithApp>,
    _,
    _,
    _,
  >(
    findingsWithApp,
    savedFilterSel,
    (findings, filter) => {
      let filtered = filterFindings(filter, findings);
      return sortFindings(filter.sortBy, filtered);
    },
  );

const currentFilterSel = state => state.advancedFilter.findingsCriteria;

export const getCurrentFilteredFindings = createSelector<
  State,
  {},
  $ReadOnlyArray<FindingWithApp>,
  _,
  _,
  _,
>(
  findingsWithApp,
  currentFilterSel,
  (findings, filter: FindingsCriteria) => {
    let filtered = filterFindings(filter, findings);
    return sortFindings(filter.sortBy, filtered);
  },
);

export const findingsGroupByAppSel = createSelector<
  State,
  SearchParam,
  { [string]: ?$ReadOnlyArray<FindingWithApp> },
  _,
  _,
  _,
>(
  searchedFindingsSel,
  groupBy(get(['mobile_app_id'])),
);

export const appsWithScoreAndCountSel = createSelector<
  State,
  SearchParam,
  $ReadOnlyArray<Application & { count: number, score: number }>,
  _,
  _,
  _,
>(
  apps,
  findingsGroupByAppSel,
  (apps, findingsGroupByApp) =>
    pipe(
      filter(a => getOr(false, a.id, findingsGroupByApp)),
      map(a => ({
        ...a,
        count: size(findingsGroupByApp[a.id]),
        score: findingsGroupByApp[a.id]
          ? sumBy(calcScore, findingsGroupByApp[a.id])
          : 0,
      })),
    )(apps),
);

export const getSavedFiltersSel = getOr({}, ['advancedFilter', 'savedFilters']);
