//@flow
import React, { Component, type Node } from 'react';
import { Link, Route } from 'react-router-dom';
import MenuItem from './Item';

type Props = {
  path?: string,
  exact?: boolean,
  strict?: boolean,
  replace?: boolean,

  // For MenuItem
  children?: Node,
  active?: boolean,
  label: Node,
  heading?: boolean,
  badge?: ?string,
  onClick?: () => void,
  open?: boolean,
};

export default class MenuLinkItem extends Component<Props> {
  render() {
    const { path, exact, strict, replace, ...appMenuItemProps } = this.props;
    return (
      <Route path={path} exact={exact} strict={strict}>
        {({ match }) => {
          if (path) {
            return (
              <Link to={path} replace={replace} style={{ display: 'block' }}>
                <MenuItem active={!!match} {...appMenuItemProps} />
              </Link>
            );
          }

          return <MenuItem {...appMenuItemProps} />;
        }}
      </Route>
    );
  }
}
