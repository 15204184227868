//@flow
import React, { PureComponent } from 'react';

import Image from './Image';

import countries from 'svg-country-flags/countries.json';
import flag_ad from 'svg-country-flags/svg/ad.svg';
import flag_ae from 'svg-country-flags/svg/ae.svg';
import flag_af from 'svg-country-flags/svg/af.svg';
import flag_ag from 'svg-country-flags/svg/ag.svg';
import flag_ai from 'svg-country-flags/svg/ai.svg';
import flag_al from 'svg-country-flags/svg/al.svg';
import flag_am from 'svg-country-flags/svg/am.svg';
import flag_an from 'svg-country-flags/svg/an.svg';
import flag_ao from 'svg-country-flags/svg/ao.svg';
import flag_aq from 'svg-country-flags/svg/aq.svg';
import flag_ar from 'svg-country-flags/svg/ar.svg';
import flag_as from 'svg-country-flags/svg/as.svg';
import flag_at from 'svg-country-flags/svg/at.svg';
import flag_au from 'svg-country-flags/svg/au.svg';
import flag_aw from 'svg-country-flags/svg/aw.svg';
import flag_ax from 'svg-country-flags/svg/ax.svg';
import flag_az from 'svg-country-flags/svg/az.svg';
import flag_ba from 'svg-country-flags/svg/ba.svg';
import flag_bb from 'svg-country-flags/svg/bb.svg';
import flag_bd from 'svg-country-flags/svg/bd.svg';
import flag_be from 'svg-country-flags/svg/be.svg';
import flag_bf from 'svg-country-flags/svg/bf.svg';
import flag_bg from 'svg-country-flags/svg/bg.svg';
import flag_bh from 'svg-country-flags/svg/bh.svg';
import flag_bi from 'svg-country-flags/svg/bi.svg';
import flag_bj from 'svg-country-flags/svg/bj.svg';
import flag_bl from 'svg-country-flags/svg/bl.svg';
import flag_bm from 'svg-country-flags/svg/bm.svg';
import flag_bn from 'svg-country-flags/svg/bn.svg';
import flag_bo from 'svg-country-flags/svg/bo.svg';
import flag_bq from 'svg-country-flags/svg/bq.svg';
import flag_br from 'svg-country-flags/svg/br.svg';
import flag_bs from 'svg-country-flags/svg/bs.svg';
import flag_bt from 'svg-country-flags/svg/bt.svg';
import flag_bv from 'svg-country-flags/svg/bv.svg';
import flag_bw from 'svg-country-flags/svg/bw.svg';
import flag_by from 'svg-country-flags/svg/by.svg';
import flag_bz from 'svg-country-flags/svg/bz.svg';
import flag_ca from 'svg-country-flags/svg/ca.svg';
import flag_cc from 'svg-country-flags/svg/cc.svg';
import flag_cd from 'svg-country-flags/svg/cd.svg';
import flag_cf from 'svg-country-flags/svg/cf.svg';
import flag_cg from 'svg-country-flags/svg/cg.svg';
import flag_ch from 'svg-country-flags/svg/ch.svg';
import flag_ci from 'svg-country-flags/svg/ci.svg';
import flag_ck from 'svg-country-flags/svg/ck.svg';
import flag_cl from 'svg-country-flags/svg/cl.svg';
import flag_cm from 'svg-country-flags/svg/cm.svg';
import flag_cn from 'svg-country-flags/svg/cn.svg';
import flag_co from 'svg-country-flags/svg/co.svg';
import flag_cr from 'svg-country-flags/svg/cr.svg';
import flag_cu from 'svg-country-flags/svg/cu.svg';
import flag_cv from 'svg-country-flags/svg/cv.svg';
import flag_cw from 'svg-country-flags/svg/cw.svg';
import flag_cx from 'svg-country-flags/svg/cx.svg';
import flag_cy from 'svg-country-flags/svg/cy.svg';
import flag_cz from 'svg-country-flags/svg/cz.svg';
import flag_de from 'svg-country-flags/svg/de.svg';
import flag_dj from 'svg-country-flags/svg/dj.svg';
import flag_dk from 'svg-country-flags/svg/dk.svg';
import flag_dm from 'svg-country-flags/svg/dm.svg';
import flag_do from 'svg-country-flags/svg/do.svg';
import flag_dz from 'svg-country-flags/svg/dz.svg';
import flag_ec from 'svg-country-flags/svg/ec.svg';
import flag_ee from 'svg-country-flags/svg/ee.svg';
import flag_eg from 'svg-country-flags/svg/eg.svg';
import flag_eh from 'svg-country-flags/svg/eh.svg';
import flag_er from 'svg-country-flags/svg/er.svg';
import flag_es from 'svg-country-flags/svg/es.svg';
import flag_et from 'svg-country-flags/svg/et.svg';
import flag_eu from 'svg-country-flags/svg/eu.svg';
import flag_fi from 'svg-country-flags/svg/fi.svg';
import flag_fj from 'svg-country-flags/svg/fj.svg';
import flag_fk from 'svg-country-flags/svg/fk.svg';
import flag_fm from 'svg-country-flags/svg/fm.svg';
import flag_fo from 'svg-country-flags/svg/fo.svg';
import flag_fr from 'svg-country-flags/svg/fr.svg';
import flag_ga from 'svg-country-flags/svg/ga.svg';
import flag_gb_eng from 'svg-country-flags/svg/gb-eng.svg';
import flag_gb_nir from 'svg-country-flags/svg/gb-nir.svg';
import flag_gb_sct from 'svg-country-flags/svg/gb-sct.svg';
import flag_gb_wls from 'svg-country-flags/svg/gb-wls.svg';
import flag_gb from 'svg-country-flags/svg/gb.svg';
import flag_gd from 'svg-country-flags/svg/gd.svg';
import flag_ge from 'svg-country-flags/svg/ge.svg';
import flag_gf from 'svg-country-flags/svg/gf.svg';
import flag_gg from 'svg-country-flags/svg/gg.svg';
import flag_gh from 'svg-country-flags/svg/gh.svg';
import flag_gi from 'svg-country-flags/svg/gi.svg';
import flag_gl from 'svg-country-flags/svg/gl.svg';
import flag_gm from 'svg-country-flags/svg/gm.svg';
import flag_gn from 'svg-country-flags/svg/gn.svg';
import flag_gp from 'svg-country-flags/svg/gp.svg';
import flag_gq from 'svg-country-flags/svg/gq.svg';
import flag_gr from 'svg-country-flags/svg/gr.svg';
import flag_gs from 'svg-country-flags/svg/gs.svg';
import flag_gt from 'svg-country-flags/svg/gt.svg';
import flag_gu from 'svg-country-flags/svg/gu.svg';
import flag_gw from 'svg-country-flags/svg/gw.svg';
import flag_gy from 'svg-country-flags/svg/gy.svg';
import flag_hk from 'svg-country-flags/svg/hk.svg';
import flag_hm from 'svg-country-flags/svg/hm.svg';
import flag_hn from 'svg-country-flags/svg/hn.svg';
import flag_hr from 'svg-country-flags/svg/hr.svg';
import flag_ht from 'svg-country-flags/svg/ht.svg';
import flag_hu from 'svg-country-flags/svg/hu.svg';
import flag_id from 'svg-country-flags/svg/id.svg';
import flag_ie from 'svg-country-flags/svg/ie.svg';
import flag_il from 'svg-country-flags/svg/il.svg';
import flag_im from 'svg-country-flags/svg/im.svg';
import flag_in from 'svg-country-flags/svg/in.svg';
import flag_io from 'svg-country-flags/svg/io.svg';
import flag_iq from 'svg-country-flags/svg/iq.svg';
import flag_ir from 'svg-country-flags/svg/ir.svg';
import flag_is from 'svg-country-flags/svg/is.svg';
import flag_it from 'svg-country-flags/svg/it.svg';
import flag_je from 'svg-country-flags/svg/je.svg';
import flag_jm from 'svg-country-flags/svg/jm.svg';
import flag_jo from 'svg-country-flags/svg/jo.svg';
import flag_jp from 'svg-country-flags/svg/jp.svg';
import flag_ke from 'svg-country-flags/svg/ke.svg';
import flag_kg from 'svg-country-flags/svg/kg.svg';
import flag_kh from 'svg-country-flags/svg/kh.svg';
import flag_ki from 'svg-country-flags/svg/ki.svg';
import flag_km from 'svg-country-flags/svg/km.svg';
import flag_kn from 'svg-country-flags/svg/kn.svg';
import flag_kp from 'svg-country-flags/svg/kp.svg';
import flag_kr from 'svg-country-flags/svg/kr.svg';
import flag_kw from 'svg-country-flags/svg/kw.svg';
import flag_ky from 'svg-country-flags/svg/ky.svg';
import flag_kz from 'svg-country-flags/svg/kz.svg';
import flag_la from 'svg-country-flags/svg/la.svg';
import flag_lb from 'svg-country-flags/svg/lb.svg';
import flag_lc from 'svg-country-flags/svg/lc.svg';
import flag_li from 'svg-country-flags/svg/li.svg';
import flag_lk from 'svg-country-flags/svg/lk.svg';
import flag_lr from 'svg-country-flags/svg/lr.svg';
import flag_ls from 'svg-country-flags/svg/ls.svg';
import flag_lt from 'svg-country-flags/svg/lt.svg';
import flag_lu from 'svg-country-flags/svg/lu.svg';
import flag_lv from 'svg-country-flags/svg/lv.svg';
import flag_ly from 'svg-country-flags/svg/ly.svg';
import flag_ma from 'svg-country-flags/svg/ma.svg';
import flag_mc from 'svg-country-flags/svg/mc.svg';
import flag_md from 'svg-country-flags/svg/md.svg';
import flag_me from 'svg-country-flags/svg/me.svg';
import flag_mf from 'svg-country-flags/svg/mf.svg';
import flag_mg from 'svg-country-flags/svg/mg.svg';
import flag_mh from 'svg-country-flags/svg/mh.svg';
import flag_mk from 'svg-country-flags/svg/mk.svg';
import flag_ml from 'svg-country-flags/svg/ml.svg';
import flag_mm from 'svg-country-flags/svg/mm.svg';
import flag_mn from 'svg-country-flags/svg/mn.svg';
import flag_mo from 'svg-country-flags/svg/mo.svg';
import flag_mp from 'svg-country-flags/svg/mp.svg';
import flag_mq from 'svg-country-flags/svg/mq.svg';
import flag_mr from 'svg-country-flags/svg/mr.svg';
import flag_ms from 'svg-country-flags/svg/ms.svg';
import flag_mt from 'svg-country-flags/svg/mt.svg';
import flag_mu from 'svg-country-flags/svg/mu.svg';
import flag_mv from 'svg-country-flags/svg/mv.svg';
import flag_mw from 'svg-country-flags/svg/mw.svg';
import flag_mx from 'svg-country-flags/svg/mx.svg';
import flag_my from 'svg-country-flags/svg/my.svg';
import flag_mz from 'svg-country-flags/svg/mz.svg';
import flag_na from 'svg-country-flags/svg/na.svg';
import flag_nc from 'svg-country-flags/svg/nc.svg';
import flag_ne from 'svg-country-flags/svg/ne.svg';
import flag_nf from 'svg-country-flags/svg/nf.svg';
import flag_ng from 'svg-country-flags/svg/ng.svg';
import flag_ni from 'svg-country-flags/svg/ni.svg';
import flag_nl from 'svg-country-flags/svg/nl.svg';
import flag_no from 'svg-country-flags/svg/no.svg';
import flag_np from 'svg-country-flags/svg/np.svg';
import flag_nr from 'svg-country-flags/svg/nr.svg';
import flag_nu from 'svg-country-flags/svg/nu.svg';
import flag_nz from 'svg-country-flags/svg/nz.svg';
import flag_om from 'svg-country-flags/svg/om.svg';
import flag_pa from 'svg-country-flags/svg/pa.svg';
import flag_pe from 'svg-country-flags/svg/pe.svg';
import flag_pf from 'svg-country-flags/svg/pf.svg';
import flag_pg from 'svg-country-flags/svg/pg.svg';
import flag_ph from 'svg-country-flags/svg/ph.svg';
import flag_pk from 'svg-country-flags/svg/pk.svg';
import flag_pl from 'svg-country-flags/svg/pl.svg';
import flag_pm from 'svg-country-flags/svg/pm.svg';
import flag_pn from 'svg-country-flags/svg/pn.svg';
import flag_pr from 'svg-country-flags/svg/pr.svg';
import flag_ps from 'svg-country-flags/svg/ps.svg';
import flag_pt from 'svg-country-flags/svg/pt.svg';
import flag_pw from 'svg-country-flags/svg/pw.svg';
import flag_py from 'svg-country-flags/svg/py.svg';
import flag_qa from 'svg-country-flags/svg/qa.svg';
import flag_re from 'svg-country-flags/svg/re.svg';
import flag_ro from 'svg-country-flags/svg/ro.svg';
import flag_rs from 'svg-country-flags/svg/rs.svg';
import flag_ru from 'svg-country-flags/svg/ru.svg';
import flag_rw from 'svg-country-flags/svg/rw.svg';
import flag_sa from 'svg-country-flags/svg/sa.svg';
import flag_sb from 'svg-country-flags/svg/sb.svg';
import flag_sc from 'svg-country-flags/svg/sc.svg';
import flag_sd from 'svg-country-flags/svg/sd.svg';
import flag_se from 'svg-country-flags/svg/se.svg';
import flag_sg from 'svg-country-flags/svg/sg.svg';
import flag_sh from 'svg-country-flags/svg/sh.svg';
import flag_si from 'svg-country-flags/svg/si.svg';
import flag_sj from 'svg-country-flags/svg/sj.svg';
import flag_sk from 'svg-country-flags/svg/sk.svg';
import flag_sl from 'svg-country-flags/svg/sl.svg';
import flag_sm from 'svg-country-flags/svg/sm.svg';
import flag_sn from 'svg-country-flags/svg/sn.svg';
import flag_so from 'svg-country-flags/svg/so.svg';
import flag_sr from 'svg-country-flags/svg/sr.svg';
import flag_ss from 'svg-country-flags/svg/ss.svg';
import flag_st from 'svg-country-flags/svg/st.svg';
import flag_sv from 'svg-country-flags/svg/sv.svg';
import flag_sx from 'svg-country-flags/svg/sx.svg';
import flag_sy from 'svg-country-flags/svg/sy.svg';
import flag_sz from 'svg-country-flags/svg/sz.svg';
import flag_tc from 'svg-country-flags/svg/tc.svg';
import flag_td from 'svg-country-flags/svg/td.svg';
import flag_tf from 'svg-country-flags/svg/tf.svg';
import flag_tg from 'svg-country-flags/svg/tg.svg';
import flag_th from 'svg-country-flags/svg/th.svg';
import flag_tj from 'svg-country-flags/svg/tj.svg';
import flag_tk from 'svg-country-flags/svg/tk.svg';
import flag_tl from 'svg-country-flags/svg/tl.svg';
import flag_tm from 'svg-country-flags/svg/tm.svg';
import flag_tn from 'svg-country-flags/svg/tn.svg';
import flag_to from 'svg-country-flags/svg/to.svg';
import flag_tr from 'svg-country-flags/svg/tr.svg';
import flag_tt from 'svg-country-flags/svg/tt.svg';
import flag_tv from 'svg-country-flags/svg/tv.svg';
import flag_tw from 'svg-country-flags/svg/tw.svg';
import flag_tz from 'svg-country-flags/svg/tz.svg';
import flag_ua from 'svg-country-flags/svg/ua.svg';
import flag_ug from 'svg-country-flags/svg/ug.svg';
import flag_um from 'svg-country-flags/svg/um.svg';
import flag_us from 'svg-country-flags/svg/us.svg';
import flag_uy from 'svg-country-flags/svg/uy.svg';
import flag_uz from 'svg-country-flags/svg/uz.svg';
import flag_va from 'svg-country-flags/svg/va.svg';
import flag_vc from 'svg-country-flags/svg/vc.svg';
import flag_ve from 'svg-country-flags/svg/ve.svg';
import flag_vg from 'svg-country-flags/svg/vg.svg';
import flag_vi from 'svg-country-flags/svg/vi.svg';
import flag_vn from 'svg-country-flags/svg/vn.svg';
import flag_vu from 'svg-country-flags/svg/vu.svg';
import flag_wf from 'svg-country-flags/svg/wf.svg';
import flag_ws from 'svg-country-flags/svg/ws.svg';
import flag_xk from 'svg-country-flags/svg/xk.svg';
import flag_ye from 'svg-country-flags/svg/ye.svg';
import flag_yt from 'svg-country-flags/svg/yt.svg';
import flag_za from 'svg-country-flags/svg/za.svg';
import flag_zm from 'svg-country-flags/svg/zm.svg';
import flag_zw from 'svg-country-flags/svg/zw.svg';

type Props = {
  code: string,
};

export const getCountryName = (countryCode: string): string => {
  return countries[countryCode] || countryCode;
};

export const getCountryFlag = (countryCode: string): string => {
  const code = countryCode.toLowerCase();

  if (code === 'ad') {
    return flag_ad;
  }

  if (code === 'ad') {
    return flag_ad;
  }
  if (code === 'ae') {
    return flag_ae;
  }

  if (code === 'af') {
    return flag_af;
  }

  if (code === 'ag') {
    return flag_ag;
  }

  if (code === 'ai') {
    return flag_ai;
  }

  if (code === 'al') {
    return flag_al;
  }

  if (code === 'am') {
    return flag_am;
  }

  if (code === 'an') {
    return flag_an;
  }

  if (code === 'ao') {
    return flag_ao;
  }

  if (code === 'aq') {
    return flag_aq;
  }

  if (code === 'ar') {
    return flag_ar;
  }

  if (code === 'as') {
    return flag_as;
  }

  if (code === 'at') {
    return flag_at;
  }

  if (code === 'au') {
    return flag_au;
  }

  if (code === 'aw') {
    return flag_aw;
  }

  if (code === 'ax') {
    return flag_ax;
  }

  if (code === 'az') {
    return flag_az;
  }

  if (code === 'ba') {
    return flag_ba;
  }

  if (code === 'bb') {
    return flag_bb;
  }

  if (code === 'bd') {
    return flag_bd;
  }

  if (code === 'be') {
    return flag_be;
  }

  if (code === 'bf') {
    return flag_bf;
  }

  if (code === 'bg') {
    return flag_bg;
  }

  if (code === 'bh') {
    return flag_bh;
  }

  if (code === 'bi') {
    return flag_bi;
  }

  if (code === 'bj') {
    return flag_bj;
  }

  if (code === 'bl') {
    return flag_bl;
  }

  if (code === 'bm') {
    return flag_bm;
  }

  if (code === 'bn') {
    return flag_bn;
  }

  if (code === 'bo') {
    return flag_bo;
  }

  if (code === 'bq') {
    return flag_bq;
  }

  if (code === 'br') {
    return flag_br;
  }

  if (code === 'bs') {
    return flag_bs;
  }

  if (code === 'bt') {
    return flag_bt;
  }

  if (code === 'bv') {
    return flag_bv;
  }

  if (code === 'bw') {
    return flag_bw;
  }

  if (code === 'by') {
    return flag_by;
  }

  if (code === 'bz') {
    return flag_bz;
  }

  if (code === 'ca') {
    return flag_ca;
  }

  if (code === 'cc') {
    return flag_cc;
  }

  if (code === 'cd') {
    return flag_cd;
  }

  if (code === 'cf') {
    return flag_cf;
  }

  if (code === 'cg') {
    return flag_cg;
  }

  if (code === 'ch') {
    return flag_ch;
  }

  if (code === 'ci') {
    return flag_ci;
  }

  if (code === 'ck') {
    return flag_ck;
  }

  if (code === 'cl') {
    return flag_cl;
  }

  if (code === 'cm') {
    return flag_cm;
  }

  if (code === 'cn') {
    return flag_cn;
  }

  if (code === 'co') {
    return flag_co;
  }

  if (code === 'cr') {
    return flag_cr;
  }

  if (code === 'cu') {
    return flag_cu;
  }

  if (code === 'cv') {
    return flag_cv;
  }

  if (code === 'cw') {
    return flag_cw;
  }

  if (code === 'cx') {
    return flag_cx;
  }

  if (code === 'cy') {
    return flag_cy;
  }

  if (code === 'cz') {
    return flag_cz;
  }

  if (code === 'de') {
    return flag_de;
  }

  if (code === 'dj') {
    return flag_dj;
  }

  if (code === 'dk') {
    return flag_dk;
  }

  if (code === 'dm') {
    return flag_dm;
  }

  if (code === 'do') {
    return flag_do;
  }

  if (code === 'dz') {
    return flag_dz;
  }

  if (code === 'ec') {
    return flag_ec;
  }

  if (code === 'ee') {
    return flag_ee;
  }

  if (code === 'eg') {
    return flag_eg;
  }

  if (code === 'eh') {
    return flag_eh;
  }

  if (code === 'er') {
    return flag_er;
  }

  if (code === 'es') {
    return flag_es;
  }

  if (code === 'et') {
    return flag_et;
  }

  if (code === 'eu') {
    return flag_eu;
  }

  if (code === 'fi') {
    return flag_fi;
  }

  if (code === 'fj') {
    return flag_fj;
  }

  if (code === 'fk') {
    return flag_fk;
  }

  if (code === 'fm') {
    return flag_fm;
  }

  if (code === 'fo') {
    return flag_fo;
  }

  if (code === 'fr') {
    return flag_fr;
  }

  if (code === 'ga') {
    return flag_ga;
  }

  if (code === 'gb-eng') {
    return flag_gb_eng;
  }

  if (code === 'gb-nir') {
    return flag_gb_nir;
  }

  if (code === 'gb-sct') {
    return flag_gb_sct;
  }

  if (code === 'gb-wls') {
    return flag_gb_wls;
  }

  if (code === 'gb') {
    return flag_gb;
  }

  if (code === 'gd') {
    return flag_gd;
  }

  if (code === 'ge') {
    return flag_ge;
  }

  if (code === 'gf') {
    return flag_gf;
  }

  if (code === 'gg') {
    return flag_gg;
  }

  if (code === 'gh') {
    return flag_gh;
  }

  if (code === 'gi') {
    return flag_gi;
  }

  if (code === 'gl') {
    return flag_gl;
  }

  if (code === 'gm') {
    return flag_gm;
  }

  if (code === 'gn') {
    return flag_gn;
  }

  if (code === 'gp') {
    return flag_gp;
  }

  if (code === 'gq') {
    return flag_gq;
  }

  if (code === 'gr') {
    return flag_gr;
  }

  if (code === 'gs') {
    return flag_gs;
  }

  if (code === 'gt') {
    return flag_gt;
  }

  if (code === 'gu') {
    return flag_gu;
  }

  if (code === 'gw') {
    return flag_gw;
  }

  if (code === 'gy') {
    return flag_gy;
  }

  if (code === 'hk') {
    return flag_hk;
  }

  if (code === 'hm') {
    return flag_hm;
  }

  if (code === 'hn') {
    return flag_hn;
  }

  if (code === 'hr') {
    return flag_hr;
  }

  if (code === 'ht') {
    return flag_ht;
  }

  if (code === 'hu') {
    return flag_hu;
  }

  if (code === 'id') {
    return flag_id;
  }

  if (code === 'ie') {
    return flag_ie;
  }

  if (code === 'il') {
    return flag_il;
  }

  if (code === 'im') {
    return flag_im;
  }

  if (code === 'in') {
    return flag_in;
  }

  if (code === 'io') {
    return flag_io;
  }

  if (code === 'iq') {
    return flag_iq;
  }

  if (code === 'ir') {
    return flag_ir;
  }

  if (code === 'is') {
    return flag_is;
  }

  if (code === 'it') {
    return flag_it;
  }

  if (code === 'je') {
    return flag_je;
  }

  if (code === 'jm') {
    return flag_jm;
  }

  if (code === 'jo') {
    return flag_jo;
  }

  if (code === 'jp') {
    return flag_jp;
  }

  if (code === 'ke') {
    return flag_ke;
  }

  if (code === 'kg') {
    return flag_kg;
  }

  if (code === 'kh') {
    return flag_kh;
  }

  if (code === 'ki') {
    return flag_ki;
  }

  if (code === 'km') {
    return flag_km;
  }

  if (code === 'kn') {
    return flag_kn;
  }

  if (code === 'kp') {
    return flag_kp;
  }

  if (code === 'kr') {
    return flag_kr;
  }

  if (code === 'kw') {
    return flag_kw;
  }

  if (code === 'ky') {
    return flag_ky;
  }

  if (code === 'kz') {
    return flag_kz;
  }

  if (code === 'la') {
    return flag_la;
  }

  if (code === 'lb') {
    return flag_lb;
  }

  if (code === 'lc') {
    return flag_lc;
  }

  if (code === 'li') {
    return flag_li;
  }

  if (code === 'lk') {
    return flag_lk;
  }

  if (code === 'lr') {
    return flag_lr;
  }

  if (code === 'ls') {
    return flag_ls;
  }

  if (code === 'lt') {
    return flag_lt;
  }

  if (code === 'lu') {
    return flag_lu;
  }

  if (code === 'lv') {
    return flag_lv;
  }

  if (code === 'ly') {
    return flag_ly;
  }

  if (code === 'ma') {
    return flag_ma;
  }

  if (code === 'mc') {
    return flag_mc;
  }

  if (code === 'md') {
    return flag_md;
  }

  if (code === 'me') {
    return flag_me;
  }

  if (code === 'mf') {
    return flag_mf;
  }

  if (code === 'mg') {
    return flag_mg;
  }

  if (code === 'mh') {
    return flag_mh;
  }

  if (code === 'mk') {
    return flag_mk;
  }

  if (code === 'ml') {
    return flag_ml;
  }

  if (code === 'mm') {
    return flag_mm;
  }

  if (code === 'mn') {
    return flag_mn;
  }

  if (code === 'mo') {
    return flag_mo;
  }

  if (code === 'mp') {
    return flag_mp;
  }

  if (code === 'mq') {
    return flag_mq;
  }

  if (code === 'mr') {
    return flag_mr;
  }

  if (code === 'ms') {
    return flag_ms;
  }

  if (code === 'mt') {
    return flag_mt;
  }

  if (code === 'mu') {
    return flag_mu;
  }

  if (code === 'mv') {
    return flag_mv;
  }

  if (code === 'mw') {
    return flag_mw;
  }

  if (code === 'mx') {
    return flag_mx;
  }

  if (code === 'my') {
    return flag_my;
  }

  if (code === 'mz') {
    return flag_mz;
  }

  if (code === 'na') {
    return flag_na;
  }

  if (code === 'nc') {
    return flag_nc;
  }

  if (code === 'ne') {
    return flag_ne;
  }

  if (code === 'nf') {
    return flag_nf;
  }

  if (code === 'ng') {
    return flag_ng;
  }

  if (code === 'ni') {
    return flag_ni;
  }

  if (code === 'nl') {
    return flag_nl;
  }

  if (code === 'no') {
    return flag_no;
  }

  if (code === 'np') {
    return flag_np;
  }

  if (code === 'nr') {
    return flag_nr;
  }

  if (code === 'nu') {
    return flag_nu;
  }

  if (code === 'nz') {
    return flag_nz;
  }

  if (code === 'om') {
    return flag_om;
  }

  if (code === 'pa') {
    return flag_pa;
  }

  if (code === 'pe') {
    return flag_pe;
  }

  if (code === 'pf') {
    return flag_pf;
  }

  if (code === 'pg') {
    return flag_pg;
  }

  if (code === 'ph') {
    return flag_ph;
  }

  if (code === 'pk') {
    return flag_pk;
  }

  if (code === 'pl') {
    return flag_pl;
  }

  if (code === 'pm') {
    return flag_pm;
  }

  if (code === 'pn') {
    return flag_pn;
  }

  if (code === 'pr') {
    return flag_pr;
  }

  if (code === 'ps') {
    return flag_ps;
  }

  if (code === 'pt') {
    return flag_pt;
  }

  if (code === 'pw') {
    return flag_pw;
  }

  if (code === 'py') {
    return flag_py;
  }

  if (code === 'qa') {
    return flag_qa;
  }

  if (code === 're') {
    return flag_re;
  }

  if (code === 'ro') {
    return flag_ro;
  }

  if (code === 'rs') {
    return flag_rs;
  }

  if (code === 'ru') {
    return flag_ru;
  }

  if (code === 'rw') {
    return flag_rw;
  }

  if (code === 'sa') {
    return flag_sa;
  }

  if (code === 'sb') {
    return flag_sb;
  }

  if (code === 'sc') {
    return flag_sc;
  }

  if (code === 'sd') {
    return flag_sd;
  }

  if (code === 'se') {
    return flag_se;
  }

  if (code === 'sg') {
    return flag_sg;
  }

  if (code === 'sh') {
    return flag_sh;
  }

  if (code === 'si') {
    return flag_si;
  }

  if (code === 'sj') {
    return flag_sj;
  }

  if (code === 'sk') {
    return flag_sk;
  }

  if (code === 'sl') {
    return flag_sl;
  }

  if (code === 'sm') {
    return flag_sm;
  }

  if (code === 'sn') {
    return flag_sn;
  }

  if (code === 'so') {
    return flag_so;
  }

  if (code === 'sr') {
    return flag_sr;
  }

  if (code === 'ss') {
    return flag_ss;
  }

  if (code === 'st') {
    return flag_st;
  }

  if (code === 'sv') {
    return flag_sv;
  }

  if (code === 'sx') {
    return flag_sx;
  }

  if (code === 'sy') {
    return flag_sy;
  }

  if (code === 'sz') {
    return flag_sz;
  }

  if (code === 'tc') {
    return flag_tc;
  }

  if (code === 'td') {
    return flag_td;
  }

  if (code === 'tf') {
    return flag_tf;
  }

  if (code === 'tg') {
    return flag_tg;
  }

  if (code === 'th') {
    return flag_th;
  }

  if (code === 'tj') {
    return flag_tj;
  }

  if (code === 'tk') {
    return flag_tk;
  }

  if (code === 'tl') {
    return flag_tl;
  }

  if (code === 'tm') {
    return flag_tm;
  }

  if (code === 'tn') {
    return flag_tn;
  }

  if (code === 'to') {
    return flag_to;
  }

  if (code === 'tr') {
    return flag_tr;
  }

  if (code === 'tt') {
    return flag_tt;
  }

  if (code === 'tv') {
    return flag_tv;
  }

  if (code === 'tw') {
    return flag_tw;
  }

  if (code === 'tz') {
    return flag_tz;
  }

  if (code === 'ua') {
    return flag_ua;
  }

  if (code === 'ug') {
    return flag_ug;
  }

  if (code === 'um') {
    return flag_um;
  }

  if (code === 'us') {
    return flag_us;
  }

  if (code === 'uy') {
    return flag_uy;
  }

  if (code === 'uz') {
    return flag_uz;
  }

  if (code === 'va') {
    return flag_va;
  }

  if (code === 'vc') {
    return flag_vc;
  }

  if (code === 've') {
    return flag_ve;
  }

  if (code === 'vg') {
    return flag_vg;
  }

  if (code === 'vi') {
    return flag_vi;
  }

  if (code === 'vn') {
    return flag_vn;
  }

  if (code === 'vu') {
    return flag_vu;
  }

  if (code === 'wf') {
    return flag_wf;
  }

  if (code === 'ws') {
    return flag_ws;
  }

  if (code === 'xk') {
    return flag_xk;
  }

  if (code === 'ye') {
    return flag_ye;
  }

  if (code === 'yt') {
    return flag_yt;
  }

  if (code === 'za') {
    return flag_za;
  }

  if (code === 'zm') {
    return flag_zm;
  }

  if (code === 'zw') {
    return flag_zw;
  }

  throw new Error(`No country flag found for country code: ${code}`);
};

export default class CountryFlag extends PureComponent<Props> {
  render() {
    const { props } = this;
    const { code } = props;

    const countryFlag = getCountryFlag(code);

    return (
      <>
        <Image src={countryFlag} alt={code} />
      </>
    );
  }
}
