//@flow
import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import qs from 'query-string';
import memoize from 'memoizee';
import SecurityFindingsFilterEnum from '@datatheorem/enums/SecurityFindingsFilterEnum';

import type { State } from '../ReduxStateType';
import { getSortFn, SORT_DIRECTION } from '@datatheorem/findings/sort';
import type { SortOrder } from '@datatheorem/findings/sort';
import { search, type SearchParam } from '@datatheorem/findings/search';
import {
  getShowHideClosedFilter,
  getComplianceSearchFilter,
} from '@datatheorem/findings/findingsFilterCreator';
import type { SecurityFinding } from '@datatheorem/findings/types';
import findingsFilterCreator from '@datatheorem/findings/findingsFilterCreator';
import { stringFromParametricRequest } from '@datatheorem/string';
import { browserHistory } from '../clients/history';
import type { AppIdParam } from './apps';
import { SecurityFindingEndpoint } from '../endpoints';

const findingsSelector = (state: State) => state.securityFindings;

type FindingsType = $ReadOnlyArray<SecurityFinding>;

function mapSortStringToSortOrder(sortString: string): SortOrder {
  const order =
    sortString[0] === '-'
      ? SORT_DIRECTION.DESCENDING
      : SORT_DIRECTION.ASCENDING;
  const field = sortString.replace('+', '').replace('-', '');
  return [{ field, order }];
}

const sortString = (state: State) => state.strings['sortString'];

const findingsSorted = () =>
  createSelector(
    findingsSelector,
    sortString,

    (findings, sortString: mixed) =>
      getSortFn(
        typeof sortString === 'string'
          ? mapSortStringToSortOrder(sortString)
          : null,
      )(findings),
  );

const findingsByApp = () =>
  createSelector(
    findingsSorted(),

    findings => groupBy(findings, finding => finding.mobile_app_id),
  );

export const makeFindingsByAppIdParamSelector = () =>
  createSelector<State, AppIdParam & SearchParam, FindingsType, _, _, _>(
    findingsByApp(),
    (state, props) => props.match && props.match.params.appId,
    search,

    (findings, appId, search) =>
      findings && appId && findings[appId]
        ? findings[appId].filter(
            findingsFilterCreator({
              ...search,
              showClosed: getShowHideClosedFilter(browserHistory),
              compliancePolicy: getComplianceSearchFilter(browserHistory),
            }),
          )
        : [],
  );

type CategoryParam = { +match: { +params: { +category: void | string } } };

export type SearchAndCategoryParam = SearchParam & CategoryParam;

export const findingsFromCategory = createSelector<
  State,
  SearchAndCategoryParam,
  FindingsType,
  _,
  _,
  _,
>(
  findingsSorted(),
  (state, props) => props.match && props.match.params.category,
  search,

  (findings, category, search) =>
    findings
      ? findings.filter(
          findingsFilterCreator({
            ...search,
            ...(category ? { category } : {}),
            showClosed: getShowHideClosedFilter(browserHistory),
            compliancePolicy: getComplianceSearchFilter(browserHistory),
          }),
        )
      : [],
);

export type FindingIdOrLocationParam = SearchParam & {
  +match: { +params: { +findingId: void | string } },
};

export const findingFromParam = createSelector<
  State,
  FindingIdOrLocationParam,
  ?SecurityFinding,
  _,
  _,
>(
  findingsSorted(),
  (state, props) => {
    const { match, location } = props;
    if (match && match.params.findingId) {
      return match.params.findingId;
    } else if (location && location.search) {
      return qs.parse(location.search).findingId;
    }
  },

  (findings, findingId) =>
    findings ? findings.find(finding => finding.id === findingId) : null,
);

export const paramsForCategory = createSelector<
  State,
  CategoryParam,
  { filter?: void | string },
  _,
>(
  (state, props) => props.match && props.match.params.category,

  category => {
    const request = {};

    if (category === 'all' || category === 'compliance') {
      return request;
    }

    request['filter'] = SecurityFindingsFilterEnum.PRIORITY_ALERTS;
    return request;
  },
);

const simpleSearch = memoize((finding, search) => {
  if (!finding || !search) {
    return null;
  }

  const searchTarget = JSON.stringify(finding).toLowerCase();
  return searchTarget.indexOf(search.toLowerCase()) !== -1;
});

export const getSearchTextFinding = createSelector<
  State,
  SearchParam,
  string,
  _,
  _,
>(
  search,
  searchParams => {
    return (
      (searchParams
        ? Array.isArray(searchParams.searchText)
          ? searchParams.searchText[0]
          : searchParams.searchText
        : '') || ''
    );
  },
);

export const findingsFromSearch = createSelector<
  State,
  SearchParam,
  FindingsType,
  _,
  _,
  _,
>(
  findingsSorted(),
  getSearchTextFinding,

  (findings, searchText) =>
    findings
      .filter(finding => {
        return simpleSearch(finding, searchText);
      })
      .filter(
        findingsFilterCreator({
          showClosed: getShowHideClosedFilter(browserHistory),
          compliancePolicy: getComplianceSearchFilter(browserHistory),
        }),
      ),
);

export const findingsCount = createSelector<
  State,
  AppIdParam & CategoryParam,
  void | number,
  _,
  _,
  _,
  _,
>(
  findingsSelector,

  paramsForCategory,

  state => state.rowCount,

  (state, props) => props.match && props.match.params.appId,

  (findings, categoryParams, rowCount, appId) => {
    const appParams = appId ? { mobile_app_id: appId } : {};

    const count =
      rowCount[
        stringFromParametricRequest(
          SecurityFindingEndpoint,
          appId ? appParams : categoryParams,
        )
      ];

    if (count === -1) {
      return findings.length;
    } else {
      return count;
    }
  },
);
