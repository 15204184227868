//@flow
import React, { PureComponent } from 'react';
import { TextField } from '@material-ui/core';

import { palette, spacing } from '@datatheorem/theme';

const styles = {
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: spacing.menuWidth - 1,
    backgroundColor: palette.tableLight,
    padding: '0 18px 2px 6px',
    zIndex: 1,
  },
};

type Props = {
  onChange: (value: string) => void,
};

export default class MenuFilter extends PureComponent<Props> {
  input: ?HTMLInputElement = null;

  render() {
    const { onChange } = this.props;

    return (
      <div style={styles.container} onClick={this.onClick}>
        <TextField
          fullWidth
          label="Filter Menu"
          onChange={({ target: { value } }) => onChange(value)}
          margin="none"
          variant="outlined"
        />
      </div>
    );
  }

  onClick = () => {
    this.input && this.input.focus();
  };
}
