// @flow
import store from 'store';

const PREFIX = 'datatheorem_';
const SESSION_ID = 'sessionId';
const AUTH_STATE = 'GSTATE';

function set(key, val) {
  store.set(`${PREFIX}${key}`, val);
}

function get(key) {
  return store.get(`${PREFIX}${key}`);
}

export function enabled() {
  return store.enabled;
}

export function setSessionId(sessionId: string) {
  set(SESSION_ID, sessionId);
}

export function getSessionId() {
  return get(SESSION_ID);
}

export function clearSessionId() {
  set(SESSION_ID, null);
}

export function getAuthState(): ?string {
  const state = get(AUTH_STATE);
  return typeof state === 'string' ? state : null;
}

export function setAuthState(state: string): void {
  set(AUTH_STATE, state);
}
