//@flow
import React, { PureComponent } from 'react';
import Page from '../../theme/Page';
import { Button } from '@datatheorem/components';
import { isEmpty } from 'lodash/fp';
import { type JiraIntegrationConfig } from '@datatheorem/user-api/jira_config';

type BaseProps = {
  onSubmit: () => void,
  loading: boolean,
  isReadOnly: boolean,
};

class EmptyJiraConfigOverview extends PureComponent<BaseProps> {
  render() {
    const { isReadOnly } = this.props;
    return (
      <Page>
        <h2>No Current Jira Configuration Available</h2>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingBottom: 10,
            paddingTop: 10,
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          {isReadOnly ? null : (
            <Button
              ariaLabel="Create Jira Configuration"
              onClick={this.props.onSubmit}
              isLoading={this.props.loading}
            >
              Create
            </Button>
          )}
        </div>
      </Page>
    );
  }
}

type Props = {
  initialValues: ?JiraIntegrationConfig,
} & BaseProps;

export default class JiraConfigOverview extends PureComponent<Props> {
  render() {
    const { initialValues, onSubmit, loading, isReadOnly } = this.props;

    return isEmpty(initialValues) || !initialValues ? (
      <EmptyJiraConfigOverview
        onSubmit={onSubmit}
        loading={loading}
        isReadOnly={isReadOnly}
      />
    ) : (
      <Page>
        {isReadOnly ? (
          <h4 style={{ padding: '20px' }}>
            Only Managers can edit the Jira configuration.
          </h4>
        ) : (
          <Button
            onClick={onSubmit}
            isLoading={loading}
            ariaLabel="Update Jira Configuration"
          >
            Update
          </Button>
        )}
        <h2>Overview</h2>
        <p>
          <strong>Base URL:</strong> {initialValues.base_url}
        </p>

        <p>
          <strong>Username:</strong> {initialValues.username}
        </p>

        <p>
          <strong>API Token:</strong> {'Securely Stored'}
        </p>

        <p>
          <strong>Type Of Issue Name:</strong>{' '}
          {initialValues.type_of_issue_name}
        </p>

        <p>
          <strong>Export Filter:</strong> {initialValues.export_filter}
        </p>

        <p>
          <strong>Export Pre Prod:</strong>{' '}
          <span>{initialValues.export_pre_prod ? 'True' : 'False'}</span>
        </p>

        <p>
          <strong>Export Prod:</strong>{' '}
          <span>{initialValues.export_prod ? 'True' : 'False'}</span>
        </p>

        <p>
          <strong>Project Key:</strong> {initialValues.project_key_or_id}
        </p>
        {initialValues.severity_field_config && (
          <div>
            <p>
              <strong>Severity Field Config:</strong> <br />
            </p>
            <ul>
              <li>
                <strong>Field ID: </strong>{' '}
                {initialValues.severity_field_config.field_id}
              </li>

              <li>
                <strong>High Severity Field ID:</strong>{' '}
                {initialValues.severity_field_config.high_severity_value_id}
              </li>

              <li>
                <strong>Medium Severity Field ID: </strong>{' '}
                {initialValues.severity_field_config.medium_severity_value_id}
              </li>

              <li>
                <strong>Low Severity Field ID</strong>{' '}
                {initialValues.severity_field_config.low_severity_value_id}
              </li>
            </ul>
          </div>
        )}

        {initialValues.static_fields &&
          initialValues.static_fields.map((field, index) => (
            <p key={index}>
              <strong>Static Field ID #{index + 1}</strong> {field.field_id}
              <br />
              <strong>Static Field Value #{index + 1}</strong>{' '}
              {field.field_value}
            </p>
          ))}

        {initialValues.dynamic_fields &&
          initialValues.dynamic_fields.map((field, index) => (
            <p key={index}>
              <strong>Dynamic Field ID #{index + 1}</strong> {field.field_id}
              <br />
              <strong>Dynamic Field Value #{index + 1}</strong> {field.value}
            </p>
          ))}

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: 10,
            paddingTop: 10,
            paddingRight: 10,
            paddingLeft: 10,
          }}
        />
      </Page>
    );
  }
}
