//@flow
import React, { PureComponent } from 'react';
import Target from './Target';
import { type Target as TargetType } from '@datatheorem/findings/types';
import ViewMoreButton from '@datatheorem/components/src/ViewMoreButton';

function getStyles() {
  return {
    container: {
      margin: 0,
      paddingLeft: 0,
      paddingTop: 1,
    },
  };
}

type Props = {
  targets: $ReadOnlyArray<TargetType>,
  onClickStatus?: ?(target: TargetType, e: SyntheticEvent<HTMLElement>) => void,
  loadingTargetStatusIds?: ?$ReadOnlyArray<string>,
  loadingTargetExternalIds?: ?$ReadOnlyArray<string>,
  onClickExportTarget?: ?(target: TargetType, e: MouseEvent) => void,
};

type State = {
  open: boolean,
};

export default class Targets extends PureComponent<Props, State> {
  state = {
    open: false,
  };

  render() {
    const {
      targets,
      onClickStatus,
      loadingTargetStatusIds,
      loadingTargetExternalIds,
      onClickExportTarget,
    } = this.props;
    const { open } = this.state;
    const { length } = targets;
    const styles = getStyles();

    return (
      <ul style={styles.container}>
        {targets.slice(0, open ? length : 3).map(target => (
          <Target
            key={target.id}
            target={target}
            onClickStatus={onClickStatus}
            targetStatusLoading={
              loadingTargetStatusIds &&
              loadingTargetStatusIds.includes(target.id)
            }
            targetExternalIdLoading={
              loadingTargetExternalIds &&
              loadingTargetExternalIds.includes(target.id)
            }
            onClickExportTarget={onClickExportTarget}
          />
        ))}
        {!open && length > 3 && (
          <ViewMoreButton
            label="components"
            count={length - 3}
            onRequestViewMore={this.onClickExpand}
          />
        )}
      </ul>
    );
  }

  onClickExpand = () => {
    this.setState({ open: true });
  };
}
