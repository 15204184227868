//@flow
import { request } from './gapi';
import { byProperty, result } from './util';

export type CreateAppResponse = {
  upload_url?: string,
};

// These params are not actually used for anything
// See https://docs.google.com/document/d/107iJa7BM3rOJIMZwqL38F0yBq5A5wZm8pZ9sprmwJYU/edit#heading=h.yx5ereepbdxn
type CreateParams = {
  comments?: ?string,
  password?: ?string,
  username?: ?string,
};

export async function create(params: CreateParams): Promise<CreateAppResponse> {
  return request({
    path: 'userapi/v2/app_uploads',
    method: 'POST',
    body: params,
  })
    .then(result)
    .then(byProperty('upload_url'));
}
