//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import assign from 'rezz/assign';
import { type Insights } from '@datatheorem/user-api/insights';
import { appInsightsReceived } from '../actions';

export type InsightsState = Insights;

// $FlowFixMe FlowUpgrade
export default createReducerCreator()(assign(appInsightsReceived.toString()));
