//@flow
import { request } from './gapi';
import { result } from './util';

export type AccountInfo = {
  name?: string,
  clonewatch_subscription: string,
  openscan_subscription: string,
  toggles?: {
    mediawatch?: boolean,
    openscan?: boolean,
    scan_and_secure?: boolean,
    app_protection?: boolean,
  },
};

export type ManagerInfo = {
  has_saml_idp?: boolean,
  saml_sp_metadata?: {
    entity_id?: string, //e.g.: "https://<APP>.appspot.com/saml/<TOKEN>",
    metadata_file_url?: string, //e.g.: "https://<APP>.appspot.com/saml/<TOKEN>/metadata"
    assertion_consumer_service_url?: string, //e.g.: "https://<APP>.appspot.com/saml/<TOKEN>"
  },
};

export async function get(): Promise<AccountInfo> {
  return request({ path: 'userapi/v2/account_info' }).then(result);
}

export const manager = {
  async get(): Promise<ManagerInfo> {
    return request({ path: 'userapi/v2/account_info/manager' }).then(result);
  },
};
