//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import assign from 'rezz/assign';
import { createAction } from 'redux-actions';
import { type User } from '@datatheorem/user-api/users';

export type ProfileState = ?User;

export const setProfile = createAction<'SET_PROFILE', [User], User>(
  'SET_PROFILE',
  (profile: User) => profile,
);

// $FlowFixMe FlowUpgrade
export default createReducerCreator()(assign(setProfile.toString()));
