//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import Paper from 'material-ui/Paper';
import type { CategoryMetadataCount } from '@datatheorem/user-api/mobile_apps';
import { type Insights } from '@datatheorem/user-api/insights';

import CoverageBlock, { CoverageLegend } from './CoverageBlock';

const getMaliciousConnectionsCount = function(insights: Insights): number {
  const communicationInsights = insights.communication_insights;
  return communicationInsights &&
    typeof communicationInsights.communicates_with_blacklisted_ip_addresses !==
      'undefined'
    ? communicationInsights.communicates_with_blacklisted_ip_addresses.length
    : 0;
};

const styles = {
  coverageBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    padding: '4px 0px',
  },

  coverageColumn: {
    padding: '4px 12px',
  },
};

type OwnProps = {|
  insights: Insights,
  metadataCount: CategoryMetadataCount,
|};

type Props = {|
  ...OwnProps,
  ...InjectedJSS<typeof styles>,
|};

class ScanCoverageReport extends PureComponent<Props> {
  render() {
    const { props } = this;
    const {
      classes,

      insights,
      metadataCount,
    } = props;

    if (!metadataCount.OPEN) {
      return null;
    }
    const metadataOpenCount = metadataCount.OPEN;
    const maliciousConnectionsCount = getMaliciousConnectionsCount(insights);

    return (
      <Paper style={{ padding: 10, marginBottom: 48, maxWidth: 972 }}>
        <div>Scan Coverage</div>
        <div className={classes.coverageBox}>
          <div className={classes.coverageColumn}>
            <CoverageBlock title="Objective C" count={null} key="obj-c" />
            <CoverageBlock title="Swift" count={null} key="swift" />
            <CoverageBlock title="Java" count={null} key="java" />
            <CoverageBlock title="Kotlin" count={null} key="kotlin" />
          </div>

          <div className={classes.coverageColumn}>
            <CoverageBlock
              title="P1 Security"
              count={metadataOpenCount.P1}
              key="p1"
            />
            <CoverageBlock
              title="App Store Blockers"
              count={metadataOpenCount.APPLE_BLOCKER}
              key="app-store-blocker"
            />

            <CoverageBlock
              title="Google Play Blockers"
              count={metadataOpenCount.GOOGLE_BLOCKER}
              key="google-play-blocker"
            />
            <CoverageBlock
              title="Open Source Libraries"
              count={metadataOpenCount.SDK}
              key="sdk"
            />

            <CoverageBlock
              title="Commercial SDKs"
              count={null}
              key="commercial-sdk"
            />
          </div>
          <div className={classes.coverageColumn}>
            <CoverageBlock
              title="REST API Authentication"
              count={null}
              key="rest-api-auth"
            />
            <CoverageBlock
              title="REST API Encryption"
              count={null}
              key="rest-api-encryption"
            />
            <CoverageBlock
              title="Data In-Transit Exposure"
              count={null}
              key="data-in-transit"
            />
            <CoverageBlock
              title="Data At-Rest Exposure"
              count={null}
              key="data-at-rest"
            />
            <CoverageBlock
              title="Malicious Connections"
              count={maliciousConnectionsCount}
              key="malicious-communications"
            />
          </div>

          <div className={classes.coverageColumn}>
            <CoverageBlock
              title="Compliance Issues"
              count={metadataOpenCount.COMPLIANCE}
              key="compliance"
            />
            <CoverageBlock
              title="App Protection Features"
              count={null}
              key="app-protection"
            />
            <CoverageBlock
              title="App Clone Protection"
              count={null}
              key="app-clone-protection"
            />
            <CoverageBlock
              title="App Brand Abuse"
              count={null}
              key="app-brand-abuse"
            />
          </div>
        </div>
        <CoverageLegend />
      </Paper>
    );
  }
}

export default jss(styles)(ScanCoverageReport);
