//@flow
import { request } from './gapi';
import { result, check } from './util';
import { type User } from './users';

export function get(): Promise<User> {
  return request({ path: 'userapi/v2/current_user' }, true).then(result);
}

type PatchParams = {
  notification_email: string,
};

export function patch(params: PatchParams): Promise<User> {
  return request({
    path: 'userapi/v2/current_user',
    method: 'PATCH',
    params: params,
  }).then(result);
}

type CreatePushTokensParams = {
  push_token: string,
};

export const push_tokens = {
  create(params: CreatePushTokensParams): Promise<void> {
    return request({
      path: 'userapi/v2/current_user/push_tokens',
      method: 'POST',
      params: params,
    }).then(check);
  },
};

export type NotificationPreferencesEmailSchema = {|
  email:
    | boolean
    | {|
        daily: boolean,
        real_time: boolean,
        weekly: boolean,
      |},
|};
export type NotificationPreferences = {|
  app_secure: {|
    security_p1s: NotificationPreferencesEmailSchema,
    app_store_blockers: NotificationPreferencesEmailSchema,
    google_play_blockers: NotificationPreferencesEmailSchema,
    prioritized_issues: NotificationPreferencesEmailSchema,
    completed_scans: NotificationPreferencesEmailSchema,
    app_protection_tasks: NotificationPreferencesEmailSchema,
    new_mobile_apps: NotificationPreferencesEmailSchema,
    sdk_issues: NotificationPreferencesEmailSchema,
    monthly_stats: NotificationPreferencesEmailSchema,
    note_added: NotificationPreferencesEmailSchema,
    scan_started: NotificationPreferencesEmailSchema,
    new_app_choose_subscription: NotificationPreferencesEmailSchema,
    weekly_user_report: NotificationPreferencesEmailSchema,
  |},
  date_created: string,
  date_updated: string,
  id: string,
  user_id: string,
|};
export type NotificationPreferencesEmailSchemaPatch = {|
  email: void | {|
    daily: void | boolean,
    real_time: void | boolean,
    weekly: void | boolean,
  |},
|};
export type NotificationPreferencesPatch = {|
  app_secure?: {|
    security_p1s?: NotificationPreferencesEmailSchemaPatch,
    app_store_blockers?: NotificationPreferencesEmailSchemaPatch,
    google_play_blockers?: NotificationPreferencesEmailSchemaPatch,
    prioritized_issues?: NotificationPreferencesEmailSchemaPatch,
    completed_scans?: NotificationPreferencesEmailSchemaPatch,
    app_protection_tasks?: NotificationPreferencesEmailSchemaPatch,
    new_mobile_apps?: NotificationPreferencesEmailSchemaPatch,
    sdk_issues?: NotificationPreferencesEmailSchemaPatch,
    monthly_stats?: { email?: boolean },
    note_added?: { email?: boolean },
    scan_started?: { email?: boolean },
    new_app_choose_subscription?: { email?: boolean },
    weekly_user_report?: { email?: boolean },
  |},
|};

export const notification_preferences = {
  get: (): Promise<NotificationPreferences> => {
    return request({
      path: 'userapi/v2/current_user/notification_preferences',
      method: 'GET',
    }).then(result);
  },
  patch: (
    params: NotificationPreferencesPatch,
  ): Promise<NotificationPreferences> => {
    return request({
      path: 'userapi/v2/current_user/notification_preferences',
      method: 'PATCH',
      body: JSON.stringify(params),
    }).then(result);
  },
};
