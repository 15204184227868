//@flow
import React, { PureComponent } from 'react';
import { PageHeading } from '@datatheorem/components';
import ReadOnlySummary from '../users/ReadOnlySummary';
import { connect } from 'react-redux';
import { getCurrentProfile } from '../../sagas/profile';
import { type User } from '@datatheorem/user-api/users';
import { compose } from 'recompose';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { type DispatchProps } from 'redux';
import type { ContextRouter } from 'react-router-dom';
import { browserHistory } from './../../clients/history';
import { type State as ReduxStateType } from '../../ReduxStateType';
import { type ActionType } from 'redux-actions';

type DP = DispatchProps<ActionType<typeof getCurrentProfile>>;

type OwnProps = ContextRouter;

type StateProps = {|
  profile: ?User,
|};

type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DP,
  ...TrackingProps,
|};

type IntermediateProps = {| ...OwnProps, ...StateProps, ...DP |};

export default compose(
  connect<IntermediateProps, OwnProps, _, _, _, _>(
    (state: ReduxStateType): StateProps => ({
      profile: state.profile,
    }),
  ),
  tracking(dataCreators.component(AnalyticsScreenEnum.PROFILE)),
)(
  class Profile extends PureComponent<Props> {
    componentDidMount() {
      this.props.dispatch(getCurrentProfile());
    }

    render() {
      const { profile } = this.props;

      if (!profile) {
        return null; // TODO: show loading or error or something
      }

      return (
        <PageHeading
          backButton
          onClickBack={browserHistory.goBack}
          title="Profile"
        >
          <ReadOnlySummary profile={profile} />
        </PageHeading>
      );
    }
  },
);
