// @flow
import React, {
  PureComponent,
  Fragment,
  createRef,
  type Node,
  type ElementRef,
} from 'react';
import { getCSVBlob } from '../../util/filtersUtil';

type Props = {
  children: ((data: string, name: string) => mixed) => Node,
};

export default class CSVExporter extends PureComponent<Props> {
  hiddenlink = createRef<ElementRef<'a'>>();

  handleCSVExport = (data: string, name: string) => {
    const fileName = `DataTheorem - ${name}.csv`;
    const contentBlob = getCSVBlob(data);
    // Shamelessly copied from https://github.com/mholt/PapaParse/issues/175#issuecomment-201308792
    //IE11 & Edge
    // $FlowFixMe -- Flow is missing this in Navigator
    if (window.navigator.msSaveBlob) {
      // $FlowFixMe -- Flow is missing this in Navigator
      window.navigator.msSaveBlob(contentBlob, fileName);
    } else {
      //In FF link must be added to DOM to be clicked
      const { current } = this.hiddenlink;
      if (current) {
        current.href = window.URL.createObjectURL(contentBlob);
        current.download = fileName;
        current.click();
      } else throw new Error('assumed ref would be set here!');
    }
  };

  render() {
    const { children } = this.props;
    return (
      <Fragment>
        <a style={{ display: 'none' }} ref={this.hiddenlink} />
        {children(this.handleCSVExport)}
      </Fragment>
    );
  }
}
