// @flow
import React, { PureComponent, Fragment } from 'react';
import { PieChart } from '@datatheorem/components';

export type PieChartDatum = {|
  label: string,
  points: number,
  percent: number,
  color: string,
|};

type Props = {
  data: $ReadOnlyArray<PieChartDatum>,
};

const styles = {
  pieChart: {
    width: 200,
    height: 200,
  },
  legendLine: {
    display: 'flex',
    justifyItems: 'center',
  },
  legend: {
    marginLeft: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  swatch: {
    display: 'inline-block',
    width: 20,
    height: 20,
    marginRight: 10,
  },
};

export default class AppProtectionPieChart extends PureComponent<Props> {
  render() {
    const { data } = this.props;

    return (
      <Fragment>
        <PieChart
          style={styles.pieChart}
          data={data.map(({ color, percent }) => ({
            color,
            value: percent,
          }))}
        />
        <div style={styles.legend}>
          {data.map(({ label, color, percent }) => (
            <div
              key={color}
              style={styles.legendLine}
              className="pieChartLegend"
            >
              <span
                style={{
                  ...styles.swatch,
                  backgroundColor: color,
                }}
              />
              {label}: {percent}%
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}
