//@flow
import React, { Component } from 'react';
import { Tooltip } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { fontSizes, palette } from '@datatheorem/theme';
import { type AppScanBlockedReasonEnum } from '@datatheorem/enums/AppScanBlockedReasonEnum';
import { getAppScanBlockedReasonMapping } from '../../util/appUtil';
import AppScanBlockedDialog from './AppScanBlockedDialog';

type Props = {
  mobile_app_id: string,
  scanStatus: ?string,
  isAppScanBlocked: boolean,
  blockedScanReason: ?AppScanBlockedReasonEnum,
};

type State = {
  hovered: boolean,
  showAppScanBlockedDialog: boolean,
};

export default class AppScanStatus extends Component<Props, State> {
  state = {
    hovered: false,
    showAppScanBlockedDialog: false,
  };

  render() {
    const styles = {
      scanStatus: {
        fontSize: fontSizes.small,
        float: 'right',
        color: palette.navColor,
        display: 'inline-block',
        height: 30,
        lineHeight: '28px',
      },
      errorIcon: {
        color: palette.error,
        float: 'left',
        height: 15,
        marginTop: 6,
        cursor: 'pointer',
      },
      errorText: {
        color: palette.error,
        cursor: 'pointer',
      },
    };

    const { showAppScanBlockedDialog } = this.state;
    const {
      blockedScanReason,
      isAppScanBlocked,
      scanStatus,
      mobile_app_id,
    } = this.props;

    const blockedScanReasonMap = getAppScanBlockedReasonMapping(
      blockedScanReason,
    );
    const tooltipLabel = blockedScanReasonMap ? blockedScanReasonMap.title : '';

    return (
      <div
        id="scan-status-div"
        style={styles.scanStatus}
        onClick={this.handleScanStatusClick}
        onMouseEnter={this.handleScanStatusMouseEnter}
        onMouseLeave={this.handleScanStatusMouseLeave}
      >
        {isAppScanBlocked && (
          <AppScanBlockedDialog
            mobile_app_id={mobile_app_id}
            onCloseDialog={this.onCloseDialog}
            blockedScanReason={blockedScanReason}
            showAppScanBlockedDialog={showAppScanBlockedDialog}
          />
        )}

        {isAppScanBlocked && (
          <Tooltip title={tooltipLabel}>
            <span id="scan-status-blocked-span" style={styles.errorText}>
              <ErrorOutline aria-label="Error" style={styles.errorIcon} />
              {scanStatus}
            </span>
          </Tooltip>
        )}
        {!isAppScanBlocked && scanStatus}
      </div>
    );
  }

  onCloseDialog = this.onCloseDialog.bind(this);
  onCloseDialog() {
    this.setState({ showAppScanBlockedDialog: false });
  }

  handleScanStatusClick = this.handleScanStatusClick.bind(this);
  handleScanStatusClick() {
    const { isAppScanBlocked } = this.props;

    if (isAppScanBlocked) {
      this.setState({ showAppScanBlockedDialog: true });
    }
  }

  handleScanStatusMouseEnter = this.handleScanStatusMouseEnter.bind(this);
  handleScanStatusMouseEnter() {
    const { isAppScanBlocked } = this.props;

    if (isAppScanBlocked) {
      this.setState({ hovered: true });
    }
  }

  handleScanStatusMouseLeave = this.handleScanStatusMouseLeave.bind(this);
  handleScanStatusMouseLeave() {
    const { isAppScanBlocked } = this.props;

    if (isAppScanBlocked) {
      this.setState({ hovered: false });
    }
  }
}
