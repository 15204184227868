// @flow
import React, { PureComponent } from 'react';
import TextField from 'material-ui/TextField';

const wStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'baseline',
};

type Props = {
  value: string,
  onChange: string => mixed,
  hintText?: string,
};

const defaultHintTex =
  'Enter a keyword to narrow results (default: all findings)';

export default class SearchBox extends PureComponent<Props> {
  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) =>
    this.props.onChange(e.target.value);

  render() {
    const { value, hintText } = this.props;

    return (
      <div style={wStyle}>
        <div style={{ display: 'inline-block', marginRight: '10px' }}>
          Keyword:{' '}
        </div>
        <TextField
          style={{
            flex: 1,
          }}
          hintText={hintText || defaultHintTex}
          onChange={this.handleChange}
          defaultValue={value}
        />
      </div>
    );
  }
}
