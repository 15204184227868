//@flow
import type { SearchQuery } from '@datatheorem/user-api/search_queries';
import { createAction } from 'redux-actions';
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';

export type SearchQueriesState = $ReadOnlyArray<SearchQuery>;

// $FlowFixMe FlowUpgrade
export const update = createAction<'SEARCH_QUERIES_UPDATE', _, _>(
  'SEARCH_QUERIES_UPDATE',
  (searchQuery: $ReadOnlyArray<SearchQuery>): $ReadOnlyArray<SearchQuery> =>
    searchQuery,
);

export default createReducerCreator<
  $ReadOnlyArray<SearchQuery>,
  SearchQuery,
  _,
>({
  initialState: ([]: SearchQueriesState),
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList(update.toString()));
