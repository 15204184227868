//@flow
import React, { PureComponent } from 'react';
import { Markdown, TargetStatus as Status } from '@datatheorem/components';
import { palette } from '@datatheorem/theme';
import { type Target as TargetType } from '@datatheorem/findings/types';
import LinkIcon from '@material-ui/icons/Launch';
import {
  getLatestStatus,
  getLatestStatusObject,
} from '@datatheorem/findings/targets/status';
import FindingTargetStatus from '@datatheorem/enums/FindingTargetStatusEnum';
import { CircularProgress } from '@material-ui/core';
import injectSheet from 'react-jss';
import type { InjectedJSS } from 'react-jss';

/**
 * Check if string is a url.
 * Credit to https://stackoverflow.com/a/34695026/2178312
 */
function isValidURL(targetStr: string) {
  const a = document.createElement('a');
  a.href = targetStr;
  return a.host && a.host != window.location.host;
}

const styles = {
  container: {
    listStyle: 'none',
    display: 'flex',
    alignItems: 'flex-start',
    padding: 10,
    marginBottom: 1,
    backgroundColor: palette.brightBg,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    flexGrow: 0,
    '& > *': {
      flexGrow: 0,
      flexShrink: 0,
    },
  },
  markdown: {
    marginLeft: 10,
    wordBreak: 'break-word',
  },
  external_id: {
    fontSize: 12,
    marginTop: 4,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  external_id_label: {
    padding: '1px 5px',
    border: `1px solid ${palette.brand}`,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    backgroundColor: palette.brand,
    color: palette.brightBg,
  },
  external_id_value: {
    padding: '2px 5px',
    border: `1px solid ${palette.brand}`,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    wordBreak: 'break-all',
    width: '100%',
    display: 'block',
    color: palette.blue,
  },
  link: {
    textDecoration: 'none',
    border: 0,
    marging: 0,
    width: '100%',
  },
  linkIcon: {
    fontSize: 16,
    lineHeight: '16px',
    verticalAlign: '-2px',
    paddingTop: '2px',
  },
  date: {
    paddingTop: 4,
    color: palette.faded,
    fontSize: 12,
    textAlign: 'center',
  },
};

type Props = {
  onClickStatus?: ?(
    target: TargetType,
    event: SyntheticEvent<HTMLElement>,
  ) => void,
  onClickExportTarget?: ?(target: TargetType, event: MouseEvent) => void,
  target: TargetType,
  targetStatusLoading?: ?boolean,
  targetExternalIdLoading?: ?boolean,
} & InjectedJSS<typeof styles>;

export class Target extends PureComponent<Props> {
  render() {
    const {
      target,
      onClickStatus,
      targetStatusLoading,
      targetExternalIdLoading,
      classes,
      onClickExportTarget,
    } = this.props;
    const { external_id, formatted_text, external_bug_tracker_url } = target;

    const latestStatusObject = getLatestStatusObject(target);

    // NOTE (Gopar): Some customers (Like Infor) have used the submitted URL values to
    // the external_id field. We should recognize when it's a url and hyperlink it as well.
    const externalIdToDisplay = (
      <span className={`${classes.external_id} test-use-external_id`}>
        <span className={classes.external_id_label}>Issue Tracking</span>
        {external_id ? (
          external_bug_tracker_url ||
          (external_id && isValidURL(external_id)) ? (
            <a
              href={external_bug_tracker_url || external_id}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              <span className={classes.external_id_value}>
                {external_id}
                <LinkIcon
                  className={classes.linkIcon}
                  style={{ width: 16, height: 16 }}
                />
              </span>
            </a>
          ) : (
            <span className={classes.external_id_value}>{external_id}</span>
          )
        ) : (
          <span
            onClick={
              onClickExportTarget && !targetExternalIdLoading
                ? this.onClickExportTarget
                : undefined
            }
            style={{ cursor: targetExternalIdLoading ? null : 'pointer' }}
            className={classes.external_id_value}
          >
            {targetExternalIdLoading ? (
              <CircularProgress
                size={12}
                thickness={2}
                style={{ margin: '4 auto' }}
              />
            ) : (
              'Export Target'
            )}
          </span>
        )}
      </span>
    );

    return (
      <li className={classes.container}>
        <div className={classes.infoContainer}>
          <Status
            status={
              (target && getLatestStatus(target)) || FindingTargetStatus.OPEN
            }
            onClick={onClickStatus && this.onClickStatus}
            loading={targetStatusLoading}
          />
          {latestStatusObject && (
            <span className={classes.date}>
              {'since ' +
                new Date(latestStatusObject.date).toLocaleDateString()}
            </span>
          )}
          {externalIdToDisplay}
        </div>
        <span className={classes.markdown}>
          <Markdown inline text={formatted_text || 'The Mobile Application'} />
        </span>
      </li>
    );
  }

  onClickStatus = (e: SyntheticEvent<HTMLElement>) => {
    const { target, onClickStatus } = this.props;
    if (onClickStatus) {
      onClickStatus(target, e);
    }
  };

  onClickExportTarget = (e: MouseEvent) => {
    const { target, onClickExportTarget } = this.props;
    if (onClickExportTarget) {
      onClickExportTarget(target, e);
    }
  };
}

export default injectSheet(styles)(Target);
