//@flow
import React, { PureComponent, type Node } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

import { Paper, Divider, Button } from '@material-ui/core';
import { palette } from '@datatheorem/theme';

function getStyles({ open }): any {
  return {
    container: { marginTop: open ? 20 : 0, marginBottom: open ? 20 : 0 },
    wrapper: { display: 'flex', minHeight: 60, alignItems: 'center' },
    textContainer: { width: 180, marginLeft: 24, marginRight: 16 },
    primaryText: { color: palette.gray10 },
    secondaryText: { fontSize: 12, color: palette.gray20 },
    valueContainer: { flexGrow: 3, color: palette.gray20 },
    valueInnerContainer: { display: 'flex' },
    secondaryValue: { fontSize: 12, color: palette.gray20 },
    iconContainer: { marginRight: 24, marginLeft: 16 },
    bodyWrapper: {},
  };
}

type Props = {
  open?: boolean,
  primaryText: Node,
  secondaryText?: Node,
  value: ?Node,
  secondaryValue?: Node,
  disabled?: boolean,
  children?: Node,
  onClick?: () => void,
  onRequestSave?: () => void,
  onRequestCancel?: () => void,
};

export default class ExpansionPanelItem extends PureComponent<Props> {
  render() {
    const {
      primaryText,
      secondaryText,
      value,
      secondaryValue,
      open,
    } = this.props;

    const styles = getStyles({ open });

    return (
      <Paper style={styles.container}>
        <div style={styles.wrapper} onClick={this.onClick}>
          <div style={styles.textContainer}>
            <div style={styles.primaryText}>{primaryText}</div>
            <div style={styles.secondaryText}>{secondaryText}</div>
          </div>
          <div style={styles.valueContainer}>
            <div style={styles.valueInnerContainer}>{value}</div>
            {secondaryValue && (
              <div style={styles.secondaryValue}>{secondaryValue}</div>
            )}
          </div>
          <div style={styles.iconContainer}>{this.renderIcon()}</div>
        </div>
        {this.renderBody(styles)}
        {!open && <Divider />}
      </Paper>
    );
  }

  renderIcon = () => {
    const { open, disabled } = this.props;

    if (disabled) {
      return null;
    }

    if (open) {
      return <ExpandLess color={palette.gray20} />;
    } else {
      return <ExpandMore color={palette.gray20} />;
    }
  };

  renderBody = (styles: { bodyWrapper: ?{} }) => {
    const { children, open } = this.props;

    return (
      <div style={styles.bodyWrapper}>
        {children && <Divider />}
        <div>{children}</div>
        {open && (
          <div>
            <Divider />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingBottom: 10,
                paddingTop: 10,
              }}
            >
              <Button
                color="primary"
                variant="text"
                onClick={this.onClickCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.onClickSave}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  onClick = <T>(...args: $ReadOnlyArray<T>) => {
    this.callback(this.props.onClick, args);
  };

  onClickSave = () => {
    this.callback(this.props.onRequestSave);
  };

  onClickCancel = () => {
    this.callback(this.props.onRequestCancel);
  };

  callback = <T, R>(
    callback: ?(...args: $ReadOnlyArray<T>) => R,
    args?: $ReadOnlyArray<T> = [],
  ) => {
    if (typeof callback === 'function') {
      callback.apply(this, args);
    }
  };
}
