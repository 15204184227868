// @flow
import { PureComponent } from 'react';

/**
 * This looks like react-router's location, but it's not exactly the same thing.
 * This is just a discrete set of props that compose everything in a URL that
 * might change resulting in a need to scroll to top.
 *
 * You can use this by passing these three properties of react-router's Location,
 * or you can do whatever you want to trigger the scroll by changing one of the
 * props from what it was before.
 */
type Props = {
  pathname?: ?string,
  search?: ?string,
  hash?: ?string,
};

export default class ScrollToTop extends PureComponent<Props> {
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}
