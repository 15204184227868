//@flow

import { call, put, spawn, takeEvery } from 'redux-saga/effects';
import { api_keys } from '@datatheorem/user-api/api_keys';
import { callPromise } from '@datatheorem/session';
import { browserHistory } from '../clients/history';
import { tryAndParseErrorMessage } from '@datatheorem/user-api/util';
import { Actions as NotificationsActions } from '@datatheorem/notifications';
import {
  apiKeyClicked,
  apiKeyCreateFormSubmitted,
  apiKeyCreateResponseReceived,
  apiKeyDeleteButtonClicked,
  apiKeyDeleteResponseReceived,
  apiKeyEditFormSubmitted,
  apiKeyEditResponseReceived,
  apiKeyErrorReceived,
  apiKeyPageMounted,
  apiKeysReceived,
  createAPIKeyButtonClicked,
  apiAccessPageClicked,
} from '../actions';
import type {
  APIKey,
  PatchAPIKeyParams,
  CreateAPIKeyParams,
} from '@datatheorem/user-api/api_keys';
import paginate, { paginateToEnd } from './util/paginate';
import { setString } from '../reducers/strings';
import { type Saga } from 'redux-saga';
import tracking, { dataCreators } from '@datatheorem/analytics';
import {
  API_KEYS_ERROR_STRING_KEY,
  UPLOAD_API_STRING_KEY,
  API_KEYS_TYPE,
} from '../strings';
import { withProgressIndicator } from '@datatheorem/progress-indicator';
import { callSaga } from '@datatheorem/redux-saga-wrapped-effects';

const apiKeysPageGetter = function*(params?: { +cursor?: ?string }) {
  return yield* callPromise(api_keys.list, params || { cursor: '' });
};
function* getAPIKeys(): Saga<void | $ReadOnlyArray<APIKey>> {
  /* get the api keys and dispatch new action for APIKeysReceived */
  try {
    const results = yield* callSaga(
      paginate,
      API_KEYS_TYPE,
      {},
      apiKeysPageGetter,
    );

    if (results) {
      yield put(apiKeysReceived(results.api_keys || []));
      yield put(setString(UPLOAD_API_STRING_KEY, results.upload_api_key || ''));
      return results.api_keys;
    } else {
      yield put(
        setString(
          API_KEYS_ERROR_STRING_KEY,
          'There was a problem fetching the API keys.',
        ),
      );
    }
  } catch (err) {
    yield put(
      setString(
        API_KEYS_ERROR_STRING_KEY,
        tryAndParseErrorMessage(
          err,
          'There was a problem fetching the API keys.',
        ),
      ),
    );
  }
}

export function* loadAllAPIKeys(): Saga<void> {
  return yield* withProgressIndicator(function*() {
    yield* callSaga(paginateToEnd, getAPIKeys, API_KEYS_TYPE, {}, {});
  }, API_KEYS_TYPE);
}

export function* apiKeyUpdateSaga(action: {
  type: string,
  payload: PatchAPIKeyParams & { id: string },
}): Saga<void> {
  const { id, name, results_api, management_api } = action.payload;
  const params: PatchAPIKeyParams = {
    name,
    results_api,
    management_api,
  };
  try {
    const updatedAPIKey = yield* callPromise(api_keys.patch, id, params);
    if (updatedAPIKey) {
      yield put(apiKeysReceived([updatedAPIKey]));
      yield put(apiKeyEditResponseReceived(updatedAPIKey));
      yield put(
        NotificationsActions.requestNotifyUser({
          text: `${updatedAPIKey.name} has been updated.`,
        }),
      );
      yield call(tracking, dataCreators.apiKeyEdited());
    } else {
      yield put(
        // $FlowFixMe Flow85
        apiKeyErrorReceived({
          _error: 'A problem occurred when trying to edit this api key',
        }),
      );
    }
  } catch (err) {
    yield put(
      // $FlowFixMe Flow85
      apiKeyErrorReceived({
        _error: tryAndParseErrorMessage(err, 'A problem occurred'),
      }),
    );
  }
}

export function* apiKeyDeleteSaga(action: {
  type: string,
  payload: APIKey,
}): Saga<void> {
  const { id, name } = action.payload;
  try {
    yield* callPromise(api_keys.delete, id);
    yield put(apiKeyDeleteResponseReceived(action.payload));
    yield put(
      NotificationsActions.requestNotifyUser({
        text: `${name} has been removed.`,
      }),
    );
    yield call(browserHistory.push, '/sdlc');
    yield call(tracking, dataCreators.apiKeyDeleted());
  } catch (err) {
    // $FlowFixMe Flow85
    yield put(apiKeyErrorReceived({ _error: tryAndParseErrorMessage(err) }));
    yield put(
      NotificationsActions.requestNotifyUser({
        text: `Could not remove ${name}`,
      }),
    );
  }
}

export function* apiKeyCreateSaga(action: {
  type: string,
  payload: CreateAPIKeyParams,
}): Saga<void> {
  try {
    const apiKey = yield* callPromise(api_keys.create, action.payload);
    if (apiKey) {
      yield put(apiKeysReceived([apiKey]));
      yield put(apiKeyCreateResponseReceived(apiKey));
      yield put(
        NotificationsActions.requestNotifyUser({
          text: `${apiKey.name} has been created`,
        }),
      );
      yield call(browserHistory.push, `/api_keys/${apiKey.id}`);
      yield call(tracking, dataCreators.apiKeyCreated());
    } else {
      yield put(
        NotificationsActions.requestNotifyUser({
          text: 'A problem occurred when trying to create the API Key',
        }),
      );
    }
  } catch (err) {
    // $FlowFixMe Flow85
    yield put(apiKeyErrorReceived({ _error: tryAndParseErrorMessage(err) }));
    yield put(
      NotificationsActions.requestNotifyUser({
        text: 'Could not create API Key',
      }),
    );
  }
}

export function* watchForAPIKeyUpdates(): Saga<void> {
  yield takeEvery(apiKeyEditFormSubmitted.toString(), apiKeyUpdateSaga);
}

export function* watchForAPIKeyDelete(): Saga<void> {
  yield takeEvery(apiKeyDeleteButtonClicked.toString(), apiKeyDeleteSaga);
}

export function* apiKeyClickedSaga(action: {
  type: string,
  payload: APIKey,
}): Saga<void> {
  const apiKey = action.payload;

  yield call(
    browserHistory.push,
    `/api_keys/${apiKey.id === '0' ? 'upload-api-key' : apiKey.id}`,
  );
}

export function* watchForAPIKeyClicked(): Saga<void> {
  yield takeEvery(apiKeyClicked.toString(), apiKeyClickedSaga);
}

export function* createAPIKeyButtonClickedSaga(): Saga<void> {
  yield call(browserHistory.push, '/api_keys/create-form');
}

export function* watchForCreateAPIKeyButtonClicked(): Saga<void> {
  yield takeEvery(
    createAPIKeyButtonClicked.toString(),
    createAPIKeyButtonClickedSaga,
  );
}

export function* watchForAPIKeyCreate(): Saga<void> {
  yield takeEvery(apiKeyCreateFormSubmitted.toString(), apiKeyCreateSaga);
}

export function* watchForAPIKeyPageClicked(): Saga<void> {
  yield takeEvery(apiAccessPageClicked.toString(), function*(): Saga<void> {
    yield call(browserHistory.push, '/sdlc/api_access');
  });
}

export function* watchForAPIKeyPageMounted(): Saga<void> {
  yield takeEvery(apiKeyPageMounted.toString(), loadAllAPIKeys);
}

export function* apiKeysFlow(): Saga<void> {
  yield spawn(watchForAPIKeyPageClicked);
  yield spawn(watchForAPIKeyClicked);
  yield spawn(watchForCreateAPIKeyButtonClicked);
  yield spawn(watchForAPIKeyPageMounted);
  yield spawn(watchForAPIKeyUpdates);
  yield spawn(watchForAPIKeyDelete);
  yield spawn(watchForAPIKeyCreate);
}
