// @flow
import React from 'react';
import UserRole, { type UserRoleEnum } from '@datatheorem/enums/UserRoleEnum';
import InfoIcon from '@material-ui/icons/Info';
import { palette } from '@datatheorem/theme';

const EXTERNAL_ID_CREATION_ERROR_TYPES = {
  NO_CONFIG: 'No Jira Configuration',
  INVALID_CONFIG: 'Invalid Config',
  INVALID_CREDENTIALS: 'Credentials Invalid',
  JIRA_SERVER_COMMUNICATION: 'General Failure',
  PERMISSION: 'Insufficient Permissions',
};

type Props = {|
  externalIdCreationError: string | null,
  userRole: UserRoleEnum | null,
|};

function ExternalIdCreationErrorMessage({
  externalIdCreationError,
  userRole,
}: Props) {
  if (externalIdCreationError === null) return externalIdCreationError;
  else {
    let errorTitle, errorDescription;

    switch (externalIdCreationError) {
      case EXTERNAL_ID_CREATION_ERROR_TYPES.NO_CONFIG:
        errorTitle = 'Jira integration is not set up';
        errorDescription =
          userRole === UserRole.MANAGER ? (
            <span>
              {'Please '}
              <a href="/sdlc/jira" style={{ textDecoration: 'underline' }}>
                set up Jira Integration
              </a>
              {' to export targets to your Jira instance'}
            </span>
          ) : (
            'Please ask your admin or manager to set up Jira Integration in order to export targets to your Jira instance'
          );
        break;

      case EXTERNAL_ID_CREATION_ERROR_TYPES.INVALID_CONFIG:
        errorTitle = 'Jira configuration is invalid';
        errorDescription = (
          <span>
            {
              'Unable to export target due to invalid Jira configuration. Please check your '
            }
            <a href="./sdlc/jira" style={{ textDecoration: 'underline' }}>
              Jira Integration Configuration
            </a>
            {' for typos and contact support@datatheorem.com for assistance.'}
          </span>
        );
        break;

      case EXTERNAL_ID_CREATION_ERROR_TYPES.INVALID_CREDENTIALS:
        errorTitle = 'Jira credentials are Invalid';
        errorDescription =
          userRole === UserRole.MANAGER ? (
            <span>
              {'Please verify that the username and password provided in your '}
              <a href="/sdlc/jira" style={{ textDecoration: 'underline' }}>
                Jira Integration Configuration
              </a>
              {' are correct and up-to-date'}
            </span>
          ) : (
            <span>
              {
                'Please ask your admin or manager to verify that the username and password provided in your '
              }

              <a href="/sdlc/jira" style={{ textDecoration: 'underline' }}>
                Jira Integration Configuration
              </a>
              {' are correct and up-to-date'}
            </span>
          );
        break;

      case EXTERNAL_ID_CREATION_ERROR_TYPES.JIRA_SERVER_COMMUNICATION:
        errorTitle = 'Error received while communicating with the Jira server';
        errorDescription =
          'Please contact support@datatheorem.com for assistance.';
        break;

      case EXTERNAL_ID_CREATION_ERROR_TYPES.PERMISSION:
        /*
         * NOTE: This should never happen...
         * users are prevented from viewing issues they don't have acccess to
         */
        errorTitle = 'Invalid permissions';
        errorDescription =
          "You don't seem to have access to this security finding... Please contact support@datatheorem.com for assistance.";
        console.error(
          "User viewed and tried to export a target for a security finding they didn't have acces to",
        );
        break;

      default:
        errorTitle = 'Unknown Error';
        errorDescription =
          'An unknown error occurred. Please reload the page and try again. Contact support@datatheorem.com if this problem persists';
    }

    return (
      <div
        style={{
          marginTop: 16,
          borderRadius: 4,
          padding: 16,
          backgroundColor: palette.red50,
          color: palette.red10,
          fontSize: 16,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <InfoIcon
          style={{
            color: palette.red10,
            marginRight: 8,
          }}
        />
        <div style={{ display: 'inline-block' }}>
          {errorTitle}
          <div style={{ fontSize: 12 }}>{errorDescription}</div>
        </div>
      </div>
    );
  }
}

export default ExternalIdCreationErrorMessage;
