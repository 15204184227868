//@flow
import React, { PureComponent, type Node } from 'react';
import { withRouter, type ContextRouter } from 'react-router-dom';

import SecurityFindingDialog from '../findings/SecurityFindingDialog';
import { getLatestStatus } from '@datatheorem/findings/targets/status';
import { type SecurityFinding as SecurityFindingType } from '@datatheorem/findings/types';
import { type Application } from '@datatheorem/user-api/mobile_apps';
import { connect } from 'react-redux';
import type { ActionType } from 'redux-actions';
import { findingFromParam } from '../../selectors/securityFindings';
import { appFromFindingParam } from '../../selectors/apps';
import { canUpdateStatus, canReopenStatus } from '../../selectors/users';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Menu,
  MenuItem,
} from '@material-ui/core';
import FindingTargetStatusEnum, {
  type FindingTargetStatusEnum as StatusType,
} from '@datatheorem/enums/FindingTargetStatusEnum';
import {
  changePriority,
  securityFindingOpened,
  updateStatus,
  exportTargetJiraClicked,
} from '../../actions/securityFindings';
import { type Target } from '@datatheorem/findings/types';
import { securityFindingLightboxOpened } from '../../sagas/profile';
import { mediawatchArticlesForFindingParam } from '../../selectors/mediawatch';
import { type MediawatchArticle } from '@datatheorem/user-api/mediawatch';
import type { FindingPriorityEnum as FindingPriorityEnumType } from '@datatheorem/enums/FindingPriorityEnum';
import { addCommentClicked } from '../../actions';
import { type User } from '@datatheorem/user-api/users';

import { type DispatchProps } from 'redux';
import { type State as ReduxState } from '../../ReduxStateType';
import {
  loadingTargetStatusIdsForFindingFromParam,
  loadingTargetExternalIdsForFindingFromParam,
} from '../../selectors/loading';
import { statusToFriendlyString } from '@datatheorem/findings/targets/status';

import { Button } from '@datatheorem/components';
import { palette } from '@datatheorem/theme';

type OwnProps = {|
  children: () => Node,
  onCloseDialog: () => void,
  instructionalMode?: boolean,
  currentUser: User,
|};

type StateProps = {|
  finding: ?SecurityFindingType,
  app: ?Application,
  canUpdateStatus: ?boolean,
  canReopenStatus: ?boolean,
  newCommentIsLoading: ?string | ?boolean | ?Date,
  articles: $ReadOnlyArray<MediawatchArticle>,
  loadingTargetStatusIds: $ReadOnlyArray<string>,
  loadingTargetExternalIds: $ReadOnlyArray<string>,
  externalIdCreationError: string | null,
  mediawatchError: string | null,
  mediawatchIsLoading: mixed,
|};

type Actions =
  | ActionType<typeof securityFindingLightboxOpened>
  | ActionType<typeof updateStatus>
  | ActionType<typeof addCommentClicked>
  | ActionType<typeof changePriority>
  | ActionType<typeof securityFindingOpened>
  | ActionType<typeof exportTargetJiraClicked>;

type Props = {|
  ...OwnProps,
  ...ContextRouter,
  ...StateProps,
  ...DispatchProps<Actions>,
|};

type IntermediateProps = {| ...OwnProps, ...ContextRouter |};

type State = {|
  statusMenuOpen: boolean,
  anchorEl: ?HTMLElement,
  selectedTarget: ?Target,
  loading: boolean,
  statusChangeReason: string,
  showRequiredError: boolean,
  statusChangeReasonDialogOpen: boolean,
  requestedTargetStatusUpdate: ?StatusType,
|};

export class SecurityFindingDialogManager extends PureComponent<Props, State> {
  state = {
    statusMenuOpen: false,
    anchorEl: null,
    selectedTarget: null,
    statusChangeReason: '',
    loading: false,
    showRequiredError: false,
    statusChangeReasonDialogOpen: false,
    requestedTargetStatusUpdate: null,
  };

  render() {
    const {
      children,
      finding,
      app,
      canUpdateStatus,
      canReopenStatus,
      articles,
      newCommentIsLoading,
      onCloseDialog,
      instructionalMode,
      loadingTargetStatusIds,
      loadingTargetExternalIds,
      currentUser,
      externalIdCreationError,
      mediawatchError,
      mediawatchIsLoading,
    } = this.props;

    const {
      statusMenuOpen,
      statusChangeReasonDialogOpen,
      anchorEl,
      selectedTarget,
      requestedTargetStatusUpdate,
    } = this.state;

    const shouldAllowUserToReopen: boolean =
      !!canReopenStatus &&
      !!selectedTarget &&
      [
        FindingTargetStatusEnum.CLOSED_RISK_ACCEPTED,
        FindingTargetStatusEnum.CLOSED_COMPENSATING_CONTROL,
      ].includes(getLatestStatus(selectedTarget));

    return (
      <div>
        {children()}
        <Dialog
          fullWidth
          maxWidth="md"
          // TODO: SJ : After Material UI Refactor, remove this key and use refs to scroll to top instead
          key={externalIdCreationError} // This is used to make the dialog scroll to the top when there's an externalIdCreation error.
          open={!!finding}
          onClose={this.onRequestCloseDialog}
        >
          <DialogContent style={{ padding: 0 }}>
            {/* TODO: Improve this component so it shows an error when this short cirtcuit test fails */}
            {!statusChangeReasonDialogOpen && finding && app && (
              <SecurityFindingDialog
                finding={finding}
                app={app}
                articles={articles}
                onClose={onCloseDialog}
                onClickStatus={canUpdateStatus ? this.onClickStatus : null}
                onAddNewNote={this.onAddNewNote}
                onChangePriority={this.onChangePriority}
                newCommentIsLoading={!!newCommentIsLoading}
                instructionalMode={instructionalMode}
                loadingTargetStatusIds={loadingTargetStatusIds}
                loadingTargetExternalIds={loadingTargetExternalIds}
                onClickExportTarget={this.onClickExportTarget}
                userRole={currentUser.role}
                externalIdCreationError={externalIdCreationError}
                mediawatchError={mediawatchError}
                mediawatchIsLoading={!!mediawatchIsLoading}
              />
            )}
          </DialogContent>
          {statusChangeReasonDialogOpen &&
            finding &&
            app &&
            requestedTargetStatusUpdate && (
              <>
                <DialogTitle style={{ fontWeight: 'normal !important' }}>
                  Updating target status to{' '}
                  {statusToFriendlyString(requestedTargetStatusUpdate)}
                </DialogTitle>
                <DialogContent>
                  <TextField
                    error={Boolean(this.state.showRequiredError)}
                    helperText={
                      this.state.showRequiredError && 'This field is required'
                    }
                    onChange={this.changeNote}
                    value={this.state.statusChangeReason}
                    rows={3}
                    fullWidth
                    autoFocus
                    margin="dense"
                    id="explanation"
                    label={`Please provide a brief explanation on your update for finding #${finding.id}`}
                    multiline={true}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant={'text'}
                    primaryColor={palette.gray30}
                    hoverColor={palette.gray50}
                    key={1}
                    onClick={this.onClickCancel}
                    ariaLabel="Cancel"
                  >
                    Cancel
                  </Button>
                  ,
                  <Button
                    ariaLabel="Confirm"
                    key={2}
                    isLoading={this.state.loading}
                    onClick={() =>
                      this.onCreateNote(this.state.statusChangeReason)
                    }
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </>
            )}
        </Dialog>

        <Menu
          open={statusMenuOpen}
          anchorEl={anchorEl}
          onClose={this.onRequestClosePopover}
        >
          {shouldAllowUserToReopen && (
            <MenuItem
              onClick={this.onRequestChangeStatus.bind(
                this,
                FindingTargetStatusEnum.OPEN,
              )}
            >
              Open
            </MenuItem>
          )}
          <MenuItem
            onClick={this.onRequestChangeStatus.bind(
              this,
              FindingTargetStatusEnum.CLOSED_RISK_ACCEPTED,
            )}
          >
            Close - Risk Accepted
          </MenuItem>
          <MenuItem
            onClick={this.onRequestChangeStatus.bind(
              this,
              FindingTargetStatusEnum.CLOSED_COMPENSATING_CONTROL,
            )}
          >
            Close - Compensating Control
          </MenuItem>
        </Menu>
      </div>
    );
  }

  componentDidMount() {
    this.onFindingOpened();
    this.onOpen();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.finding !== this.props.finding) {
      this.onFindingOpened();
      this.onOpen();
    }
  }

  onOpen = () => {
    const { finding, dispatch } = this.props;
    if (finding) {
      dispatch(securityFindingLightboxOpened());
    }
  };

  onClickStatus = (target: Target, e: SyntheticEvent<HTMLElement>) => {
    const eventTarget = e.currentTarget;
    this.setState({
      statusMenuOpen: true,
      anchorEl: eventTarget,
      selectedTarget: target,
    });
  };

  onClickExportTarget = (target: Target) => {
    const { finding } = this.props;

    if (!finding) {
      throw new Error(`Expected Finding to not be falsey`);
    }

    this.props.dispatch(exportTargetJiraClicked(finding.id, target.id));
  };

  onRequestClosePopover = () => {
    this.setState({ statusMenuOpen: false });
  };

  onRequestChangeStatus = (newStatus: StatusType) => {
    const { finding } = this.props;
    const { selectedTarget } = this.state;

    if (!finding) {
      throw new Error(`Expected Finding to not be falsey`);
    }

    if (!selectedTarget) {
      throw new Error(`Expected selectTarget to always be set by this point`);
    }

    // Request reason only while updating to CCC or CRA
    if (
      newStatus === FindingTargetStatusEnum.CLOSED_COMPENSATING_CONTROL ||
      newStatus === FindingTargetStatusEnum.CLOSED_RISK_ACCEPTED
    ) {
      this.setState({
        statusMenuOpen: false,
        statusChangeReasonDialogOpen: true,
        requestedTargetStatusUpdate: newStatus,
      });
    } else {
      this.props.dispatch(
        updateStatus(finding.id, selectedTarget.id, newStatus),
      );
      this.onRequestClosePopover();
    }
  };

  onCreateNote = (reason: string) => {
    this.setState({ loading: true });

    if (typeof reason === 'undefined' || reason.length < 1) {
      this.setState({
        loading: false,
        showRequiredError: true,
      });
      return;
    }

    const { finding } = this.props;
    const { selectedTarget, requestedTargetStatusUpdate } = this.state;

    if (!finding) {
      throw new Error(
        'A security finding is required for a target status update.',
      );
    }

    if (!selectedTarget) {
      throw new Error('A target is required for a target status update.');
    }

    if (!requestedTargetStatusUpdate) {
      throw new Error(
        'An updated status is required for a target status update.',
      );
    }

    const reasonWithPrefix = `Status updated to "${statusToFriendlyString(
      requestedTargetStatusUpdate,
    )}" using the following explanation: ${reason}`;

    const { dispatch } = this.props;
    dispatch(
      updateStatus(finding.id, selectedTarget.id, requestedTargetStatusUpdate),
    );
    dispatch(addCommentClicked(reasonWithPrefix, finding));

    this.setState({ loading: false, statusChangeReasonDialogOpen: false });
  };

  onClickCancel = () => {
    this.setState({
      statusChangeReasonDialogOpen: false,
      statusChangeReason: undefined,
      loading: false,
    });
  };

  onAddNewNote = (note: string, securityFinding: SecurityFindingType) => {
    this.props.dispatch(addCommentClicked(note, securityFinding));
  };

  onChangePriority = (priority: FindingPriorityEnumType) => {
    const { finding, dispatch } = this.props;
    dispatch(changePriority(priority, finding));
  };

  onFindingOpened = () => {
    const { finding, dispatch } = this.props;
    if (finding) {
      dispatch(securityFindingOpened(finding.id));
    }
  };

  changeNote = (e: SyntheticInputEvent<HTMLInputElement>) => {
    let value = (e.target: HTMLInputElement).value;
    this.setState({
      statusChangeReason: value,
      showRequiredError: false,
    });
  };

  onRequestCloseDialog = () => {
    const { onCloseDialog } = this.props;

    this.onClickCancel();
    onCloseDialog();
  };
}

// Compose breaks flow here for some reason???
export default withRouter(
  connect<
    Props,
    IntermediateProps,
    StateProps,
    DispatchProps<Actions>,
    ReduxState,
    _,
  >((state: ReduxState, props: IntermediateProps): StateProps => ({
    finding: findingFromParam(state, props),
    app: appFromFindingParam(state, props),
    canUpdateStatus: canUpdateStatus(state, props),
    canReopenStatus: canReopenStatus(state, props),
    articles: mediawatchArticlesForFindingParam(state, props),
    newCommentIsLoading: state.strings.commentBeingAdded,
    loadingTargetStatusIds: loadingTargetStatusIdsForFindingFromParam(
      state,
      props,
    ),
    loadingTargetExternalIds: loadingTargetExternalIdsForFindingFromParam(
      state,
      props,
    ),
    externalIdCreationError: state.errors.externalIdCreation,
    mediawatchError: state.errors.mediawatch,
    mediawatchIsLoading: state.loading.mediawatchArticles,
  }))(SecurityFindingDialogManager),
);
