//@flow
import React, { PureComponent } from 'react';
import type { Application } from '@datatheorem/user-api/mobile_apps';
import CardGroupTitle from '../theme/CardGroupTitle';
import { CardStatusLine } from '@datatheorem/components';
import {
  IconLabel,
  CardWithTargets,
  getRowHeight,
} from '@datatheorem/components';
import flatten from 'lodash/flatten';
import type { SDK } from '@datatheorem/user-api/sdks';
import type { Target } from '@datatheorem/findings/types';
import type { SecurityFinding } from '@datatheorem/findings/types';

export type SDKIssueGroup = {
  sdk: SDK,
  issuesByTitle: {
    [key: string]: void | $ReadOnlyArray<{
      security_finding: SecurityFinding,
      targets: $ReadOnlyArray<Target>,
    }>,
  },
};

type Props = {
  sdkIssueGroup: SDKIssueGroup,
  apps?: $ReadOnlyArray<Application>,
};

export default class SDKForAppsCard extends PureComponent<Props> {
  render() {
    const { sdkIssueGroup, apps } = this.props;
    const { sdk, issuesByTitle } = sdkIssueGroup;
    const { title, description, logo_url } = sdk;

    return (
      <CardWithTargets text={[title, null, description]} logo={logo_url}>
        {Object.keys(issuesByTitle).map(issueTitle => {
          const issuesForTitle = issuesByTitle[issueTitle];

          if (!Array.isArray(issuesForTitle)) {
            throw new Error('cannot find issues with this title');
          }

          return (
            <div key={issueTitle} className="IssueContainerDiv">
              <CardGroupTitle>Issue: {issueTitle}</CardGroupTitle>
              {issuesForTitle.map(issue => {
                const { security_finding, targets } = issue;
                const app =
                  apps &&
                  apps.find(app => app.id === security_finding.mobile_app_id);
                const status =
                  (targets &&
                    targets[0] &&
                    targets[0].statuses &&
                    targets[0].statuses[0]) ||
                  {};

                return (
                  <CardStatusLine
                    key={security_finding.id}
                    status={status.status}
                    date={status.date}
                  >
                    {app ? (
                      <IconLabel platform={app.platform}>{app.name}</IconLabel>
                    ) : null}
                  </CardStatusLine>
                );
              })}
            </div>
          );
        })}
      </CardWithTargets>
    );
  }

  static getRowHeight(props: { sdkIssueGroup: SDKIssueGroup }) {
    const { sdkIssueGroup } = props;

    const targetLines = flatten(Object.values(sdkIssueGroup.issuesByTitle))
      .length;
    const appLines = Object.keys(sdkIssueGroup.issuesByTitle).length;

    return getRowHeight(targetLines, appLines, true);
  }
}
