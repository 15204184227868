//@flow
import { createSelector } from 'reselect';
import { type InsightFinding } from '@datatheorem/user-api/insight_findings';
import groupBy from 'lodash/groupBy';
import type { State } from '../ReduxStateType';
import type { Application } from '@datatheorem/user-api/mobile_apps';
import { isClosedStatus } from '@datatheorem/findings/targets/status';
import type { AppIdParam } from './apps';

type InsightFindings = $ReadOnlyArray<InsightFinding>;

export const findings = (state: State) => state.insightFindings;

export const findingsByApp = createSelector<
  State,
  {},
  { +[string]: void | InsightFindings },
  _,
>(
  findings,

  findings => groupBy(findings, finding => finding.mobile_app_id),
);

export const getAppConfigurationForApp = (
  findings: $ReadOnlyArray<InsightFinding>,
  app: Application,
): $ReadOnlyArray<InsightFinding> =>
  findings.filter(
    finding =>
      !isClosedStatus(finding.aggregated_status) &&
      finding.mobile_app_id === app.id,
  );

export const appConfigurationFindingsByAppIdParam = createSelector<
  State,
  AppIdParam,
  InsightFindings,
  _,
  _,
>(
  findingsByApp,
  (state, props) => props.match.params.appId,

  (findings, appId) =>
    appId && findings && findings[appId]
      ? findings[appId].filter(
          finding => !isClosedStatus(finding.aggregated_status),
        )
      : [],
);
