//@flow
import { createRoutine } from 'redux-saga-routines';
import type { MediawatchArticle } from '@datatheorem/user-api/mediawatch';

type MediawatchRoutineParams = { security_finding_id: string };
type MediawatchRoutineSuccess = $ReadOnlyArray<MediawatchArticle>;
type MediawatchRoutineError = string;

export const mediawatchRoutine = createRoutine<
  MediawatchRoutineParams,
  MediawatchRoutineParams,
  MediawatchRoutineSuccess,
  MediawatchRoutineError,
  MediawatchRoutineParams,
>('MEDIAWATCH');
