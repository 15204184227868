//@flow
import React, { PureComponent, type Node } from 'react';
import BigList from '../theme/BigList';
import { type Application } from '@datatheorem/user-api/mobile_apps';
import { type SecurityFinding } from '@datatheorem/findings/types';
import SecurityFindingsUtil from '../theme/SecurityFindingsUtil';
import { LoadingFiller, SecurityFindingCard } from '@datatheorem/components';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListCardSpacer from '../theme/ListCardSpacer';
import type { rowRenderProps } from '../theme/BigList';

export type RowRenderPropsWithCoda = rowRenderProps & { codaIndex: number };

const styles = {
  util: {
    container: {
      position: 'fixed',
      zIndex: 2,
      height: 72,
      right: 46,
    },
  },
};

type Props = {|
  findings: $ReadOnlyArray<SecurityFinding>,
  apps: $ReadOnlyArray<Application>,
  hideAppName?: boolean,
  onSelectFinding: (finding: SecurityFinding) => void,
  onChangeComplianceSearchFilter: ($ReadOnlyArray<string>) => void,
  filler: boolean,
  loadMoreRows?: ?() => void,
  onChangeShowHideClosed: (value: boolean) => mixed,
  filterValue: boolean,
  compliancePolicyFilter: $ReadOnlyArray<string>,
  showCompliancePolicyFilter: boolean,
  showDoMore?: ?boolean,
  loading?: ?boolean,
  onClickExport?: ?() => void,
  onClickSort?: string => void,
  coda?: (rowRenderProps & { codaIndex: number }) => Node,
  codaCount?: number,
  codaRowHeight?: (params: { codaIndex: number }) => number,
  reRenderBasedOn?: any, // @po:??
  findingsCount: number | void,
|};

export default class SecurityFindings extends PureComponent<Props> {
  static defaultProps = {
    codaCount: 0,
  };

  list = null;

  render() {
    const {
      findings,
      loadMoreRows,
      onChangeShowHideClosed,
      filterValue,
      compliancePolicyFilter,
      showCompliancePolicyFilter,
      filler,
      loading,
      onClickExport,
      onClickSort,
      codaCount,
      findingsCount,
    } = this.props;

    // Optional ShowDoMore prop to hide/show the "Do More" button
    const showDoMore =
      this.props.showDoMore != null ? this.props.showDoMore : true;

    if (filler) {
      return this.renderFiller();
    }

    return (
      <div>
        <BigList
          ref={node => (this.list = node)}
          style={
            showCompliancePolicyFilter || showDoMore
              ? { utilHeight: styles.util.container.height }
              : { utilHeight: 0 }
          }
          loadMoreRows={loadMoreRows}
          totalCount={
            findings.length +
            codaCount +
            (findingsCount && findingsCount > findings.length ? 1 : 0)
          }
          currentCount={findings.length + codaCount}
          isRowLoaded={({ index }) => index < findings.length + codaCount}
          noRowsRenderer={this.renderEmpty}
          util={
            <SecurityFindingsUtil
              style={styles.util}
              onChangeShowHideClosed={onChangeShowHideClosed}
              onChangeComplianceSearchFilter={
                this.props.onChangeComplianceSearchFilter
              }
              filterValue={filterValue}
              compliancePolicyFilter={compliancePolicyFilter}
              showCompliancePolicyFilter={showCompliancePolicyFilter}
              showDoMore={showDoMore}
              total={findings.length > 0 ? findings.length : null}
              onClickExport={onClickExport}
              onClickSort={onClickSort}
            />
          }
          rowHeight={this.rowHeight}
        >
          {this.renderRow}
        </BigList>
        <div
          style={{
            margin: 50,
            display: 'relative',
            textAlign: 'center',
          }}
        >
          {loading && (
            <CircularProgress
              left={10}
              top={10}
              style={{
                display: 'inline-block',
                position: 'relative',
              }}
            />
          )}
        </div>
      </div>
    );
  }

  renderRow = (params: rowRenderProps, ...args: $ReadOnlyArray<any>) => {
    const { findings, apps, coda, hideAppName } = this.props;
    const { index, style, key } = params;

    if (typeof coda === 'function' && index >= findings.length) {
      return coda({ ...params, codaIndex: index - findings.length }, ...args);
    }

    return (
      <div key={key} style={style} onClick={this.onClick.bind(this, index)}>
        <ListCardSpacer>
          <SecurityFindingCard
            hideAppName={hideAppName}
            finding={findings[index]}
            apps={apps}
          />
        </ListCardSpacer>
      </div>
    );
  };

  renderFiller = () => {
    return <LoadingFiller headings={10} descriptionLines={0} />;
  };

  renderEmpty = () => {
    return (
      <p style={{ textAlign: 'center' }}>
        No findings match the current criteria
      </p>
    );
  };

  rowHeight = ({ index }: { index: number }) => {
    const { findings, codaRowHeight } = this.props;

    if (index >= findings.length) {
      return codaRowHeight
        ? codaRowHeight({ codaIndex: index - findings.length })
        : 0;
    }

    return SecurityFindingCard.getRowHeight({ finding: findings[index] });
  };

  componentDidUpdate() {
    if (this.list) {
      this.list.recomputeRowHeights();
    }
  }

  onClick = (index: number) => {
    this.props.onSelectFinding(this.props.findings[index]);
  };
}
