//@flow
import React, { PureComponent } from 'react';

const SIZE = 82; //px

type Props = {
  domain: string,
  alt?: string,
  style?: {},
  [string]: mixed,
};

export default class ExternalLogo extends PureComponent<Props> {
  render() {
    const { domain, alt, ...other } = this.props;

    const style = other && other.style;

    return (
      <div {...other} style={{ width: SIZE, height: SIZE, ...style }}>
        <img
          alt={alt}
          src={`https://logo.clearbit.com/${domain}`}
          width={SIZE}
          height={SIZE}
        />
      </div>
    );
  }
}
