//@flow
import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import {
  isClosedStatus,
  sortTargets,
} from '@datatheorem/findings/targets/status';
import type { SDK } from '@datatheorem/user-api/sdks';
import flatMap from 'lodash/fp/flatMap';
import uniqBy from 'lodash/uniqBy';
import type { State } from '../ReduxStateType';
import type { SDKFinding } from '@datatheorem/user-api/sdk_findings';
import type { SDKIssueGroup } from '../components/findings/SDKForAppsCard';

type SDKFindings = $ReadOnlyArray<SDKFinding>;
type SDKs = $ReadOnlyArray<SDK>;
type AppIdParam = { +match: { +params: { +appId: void | string } } };

export const findings = (state: State) => state.sdkFindings;
export const sdks = (state: { sdks: $ReadOnlyArray<SDK> }) => state.sdks;

export const findingsByApp = createSelector<
  State,
  {},
  { [string]: void | SDKFindings },
  _,
>(
  findings,

  findings => groupBy(findings, finding => finding.mobile_app_id),
);

export const sdkFindingsByAppIdParam = createSelector<
  State,
  AppIdParam,
  SDKFindings,
  _,
  _,
>(
  findingsByApp,
  (state, props) => props.match.params.appId,

  (findings, appId) =>
    appId && findings && findings[appId]
      ? findings[appId].filter(
          finding => !isClosedStatus(finding.aggregated_status),
        )
      : [],
);

export const sdksWithIssues = createSelector<State, {}, SDKs, _, _>(
  sdks,
  findings,

  (sdks, findings) =>
    sdks &&
    sdks.filter(
      sdk =>
        sdk.sdk_finding_ids &&
        sdk.sdk_finding_ids.length &&
        findings.find(
          finding =>
            sdk.sdk_finding_ids &&
            sdk.sdk_finding_ids.includes(finding.id) &&
            finding.sdk_issues,
        ),
    ),
);

export const findingsWithIssues = createSelector<State, {}, SDKFindings, _>(
  findings,

  findings => findings && findings.filter(finding => finding.sdk_issues),
);

/**
 * We've got a bunch of app-wide SDKs which link to a bunch of SDK Findings. For presentation purposes, we want to boil
 * each SDK down to security findings grouped by their template id. Since we don't have template id, we use the title
 * instead. So basically what we start with is this:
 *
 * ```
 * const sdks = [
 *     {
 *         sdk_stuff,
 *         sdk_finding_ids: [
 *             ref,
 *             ...
 *         ]
 *     },
 *     ...
 * ];
 * ```
 *
 * This is an array containing information about a an SDK, and containing an array of references to SDK Findings that
 * contain that SDK. So for example we might have an item for the Cordova SDK, which might have two SDK Finding refs
 * because two apps contain Cordova, Sidescreen Android and Sidescreen iOS, for example. Simply mapping the refs is not
 * enough because an SDK Finding is not yet a security finding, which is what we ultimately want. An SDK finding itself
 * might have a list of "SDK Issues" each of which is a security finding and targets:
 *
 * ```
 * const sdkFindings = [
 *     {
 *         sdk_finding_stuff,
 *         sdk_issues?: [
 *             {
 *                 security_finding: { ... },
 *                 target_ids: [
 *                     ref,
 *                     ...
 *                 ]
 *             },
 *             ...
 *         ]
 *     },
 *     ...
 * ];
 * ```
 *
 * So we've got an array of SDKs, each of which has an array of SDK Findings, each of which *might* have an array of
 * security findings, each of which has an array of targets, only some of which are relevant to us. This selector is
 * trying to flatten that convoluted structure to be an array of sdk information, each with an array of security
 * findings grouped by title.
 *
 * ```
 * const sdkIssueGroups = [
 *     {
 *         sdk: { sdk_stuff },
 *         issuesByTitle: {
 *             'security finding title': [
 *                 {
 *                     security_finding: { security_finding_stuff },
 *                     targets: [{ target_stuff }, ...]
 *                 },
 *                 ...
 *             ],
 *             '...': ...
 *         }
 *     },
 *     ...
 * ];
 * ```
 */
export const sdkIssueGroups = createSelector<
  State,
  {},
  $ReadOnlyArray<SDKIssueGroup>,
  _,
  _,
>(
  sdksWithIssues,
  findings,

  (sdks, findings) =>
    sdks &&
    findings &&
    sdks.map(sdk => {
      const { sdk_finding_ids } = sdk;

      if (!sdk_finding_ids) {
        return { sdk, issuesByTitle: {} };
      }

      const sdkFindingsWithIssues: $ReadOnlyArray<SDKFinding> = sdk_finding_ids
        .map(sdk_finding_id =>
          findings.find(finding => finding.id === sdk_finding_id),
        )
        .filter(Boolean)
        .filter(finding => finding.sdk_issues);

      const normalizedSdkIssues = flatMap(
        finding =>
          finding.sdk_issues
            ? finding.sdk_issues.map(issue => ({
                ...issue,
                targets: issue.security_finding.targets
                  .filter(target => issue.target_ids.includes(target.id))
                  .sort(sortTargets),
              }))
            : [],
      )(sdkFindingsWithIssues);

      const normalizedUniqSdkIssues = uniqBy(
        normalizedSdkIssues,
        issue => issue.security_finding.id,
      );

      const issuesByTitle = groupBy(
        normalizedUniqSdkIssues,
        issue => issue.security_finding.title,
      );

      return {
        sdk,
        issuesByTitle,
      };
    }),
);

export const sdkIssueGroupForParam = createSelector<
  State,
  { +match: { +params: { +sdk: void | string } } },
  void | SDKIssueGroup,
  _,
  _,
>(
  sdkIssueGroups,
  (state, props) => props.match && props.match.params.sdk,

  (sdkIssueGroups, sdkTitle) =>
    sdkIssueGroups.find(sdkIssueGroup => sdkIssueGroup.sdk.title === sdkTitle),
);
