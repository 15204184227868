//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

const HEIGHT_PX = 24;

const styles = {
  CardGroupTitle: {
    height: HEIGHT_PX,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
  },
};

type Props = {
  children: any,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class CardGroupTitle extends PureComponent<Props> {
    render() {
      const { classes, children } = this.props;

      return <div className={classes.CardGroupTitle}>{children}</div>;
    }

    static getHeight() {
      return HEIGHT_PX;
    }
  },
);
