//@flow
import React, { PureComponent } from 'react';
import BrandedProductIcon from './BrandedProductIcon';
import APIDiscoverInactiveIcon from './icon-api-discover-inactive.png';
import APIDiscoverActiveIcon from './icon-api-discover-active.png';

type Props = {
  active: boolean,
};

export default class APIDiscoverIcon extends PureComponent<Props> {
  render() {
    return (
      <BrandedProductIcon
        src={
          this.props.active ? APIDiscoverActiveIcon : APIDiscoverInactiveIcon
        }
      />
    );
  }
}
