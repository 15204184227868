//@flow
import { string, array, object, type YupObject } from 'yup';
import { type TargetStatus } from '@datatheorem/findings/types';

export const TargetStatusSchema: YupObject<TargetStatus> = object().shape({
  status: string().required(),
  date: string().required(),
});

export const TargetSchema: YupObject<{
  id: string,
  formatted_text: string,
  statuses: $ReadOnlyArray<TargetStatus>,
}> = object().shape({
  id: string().required(),
  formatted_text: string().required(),
  statuses: array()
    .of(TargetStatusSchema)
    .required(),
});
