//@flow
import React, { PureComponent } from 'react';

import Button from './../Button';

export default class PreviewButton extends PureComponent<{
  onPreviewClick: (e: SyntheticMouseEvent<HTMLElement>) => mixed,
}> {
  render() {
    return (
      <Button ariaLabel="Preview" onClick={this.props.onPreviewClick}>
        Preview
      </Button>
    );
  }
}
