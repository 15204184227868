//@flow
import React, { PureComponent, type Node } from 'react';
import {
  AutoSizer,
  Column,
  InfiniteLoader,
  Table,
  WindowScroller,
  type SortDirectionType,
} from 'react-virtualized';
import defaultsDeep from 'lodash/defaultsDeep';
import { fontFamily, fontSizes, palette } from '@datatheorem/theme';

type ColumnInfo<DK: string, RD> = {|
  dataKey: DK,
  label: string,
  flexGrow?: number,
  width?: number,
  style?: mixed,
  cellRenderer?: ({
    rowData: RD,
    cellData: mixed,
  }) => ?Node,
|};

const ROW = {
  display: 'flex',
  alignItems: 'center',
  height: 50,
};

type Style = {
  [string]: mixed,
};

type Styles = {
  tableHeader: Style,
  tableRow: { ...Style, height: number },
  tableHeaderRow: { ...Style, height: number },
  [string]: Style,
};

const getStyles = (style = {}, sortable: boolean) => {
  const headerHeight = 50;
  const utilHeight = style.utilHeight || 0;

  return defaultsDeep({}, style, {
    container: {
      marginLeft: 73,
      marginRight: 48,
      marginBottom: 30,
    },
    table: {
      backgroundColor: 'transparent',
      fontFamily: fontFamily,
      fontSize: fontSizes.large,
      fontWeight: 'lighter',
    },
    tableBody: {
      marginTop: headerHeight,
      backgroundColor: palette.tableDark,
    },
    tableHeader: {
      paddingBottom: 10,
    },
    tableHeaderRow: {
      ...ROW,
      fontFamily: fontFamily,
      fontSize: fontSizes.medium,
      color: palette.navColor,
      position: 'fixed',
      height: headerHeight + utilHeight,
      marginTop: -(headerHeight + utilHeight),
      zIndex: 1,
      backgroundColor: palette.bgColor,
      alignItems: 'flex-end',
    },
    tableRow: {
      ...ROW,
      borderBottom: `1px solid ${palette.accent}`,
      cursor: 'pointer',
    },
    tableRowOdd: {
      ...ROW,
      backgroundColor: palette.tableLight,
      borderBottom: `1px solid ${palette.accent}`,
      cursor: 'pointer',
    },
    tableCell: { lineHeight: '20px' },
    firstColumn: {
      paddingLeft: 20,
    },
    lastColumn: {
      paddingRight: 20,
    },
    scrollContainer: {
      paddingTop: utilHeight,
    },
    headerStyle: {
      outline: 0,
      cursor: sortable ? 'pointer' : null,
    },
  });
};

type Props<DK: string, T> = {
  style?: any,
  data: $ReadOnlyArray<T>,
  children?: Node,
  totalCount?: number,
  columns: $ReadOnlyArray<ColumnInfo<DK, T>>,
  onSelectItem?: T => void,
  onScroll?: () => void,
  isRowLoaded?: (options: { index: number }) => boolean,
  loadMoreRows?: () => void,
  sort?: ({ sortBy: string, sortDirection: SortDirectionType }) => mixed,
  forceWidth?: ?number, // use in tests to bypass AutoSizer
  inefficientlyRenderAllRows?: boolean, // If you pass true, overscan will be set to totalCount, so NO VIRTUALIZATION will occur
};

export default class BigTable<DK: string, T> extends PureComponent<
  Props<DK, T>,
> {
  render() {
    const { data, style, children, sort } = this.props;

    const styles = getStyles(style, !!sort);

    return (
      <div style={styles.container}>
        {children}
        <div style={styles.scrollContainer}>
          {data && data.length > 0
            ? this.renderTable(styles)
            : this.renderEmptyMessage()}
        </div>
      </div>
    );
  }

  renderTable = (styles: Styles) => {
    const {
      data,
      totalCount,
      columns,
      forceWidth,
      inefficientlyRenderAllRows,
      ...other
    } = this.props;
    return (
      <WindowScroller onScroll={this.onScroll}>
        {({ scrollTop, height }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <InfiniteLoader
                isRowLoaded={this.isRowLoaded}
                loadMoreRows={this.loadMoreRows}
                rowCount={totalCount || data.length}
              >
                {({ onRowsRendered, registerChild }) => (
                  <Table
                    height={height}
                    rowCount={data.length}
                    rowGetter={this.rowGetter}
                    scrollTop={scrollTop}
                    autoHeight
                    onRowsRendered={onRowsRendered}
                    headerStyle={styles.tableHeader}
                    rowHeight={styles.tableRow.height}
                    headerHeight={styles.tableHeaderRow.height}
                    width={forceWidth || width}
                    rowStyle={this.getRowStyle.bind(this, styles)}
                    style={styles.table}
                    ref={registerChild}
                    gridStyle={styles.tableBody}
                    onRowClick={this.onRowClick}
                    overscanRowCount={
                      inefficientlyRenderAllRows ? totalCount || data.length : 3
                    }
                    overscanIndicesGetter={this.overscanIndicesGetter}
                    {...other}
                  >
                    {columns
                      .map((columnData, i, arr) => {
                        if (!columnData) {
                          return null;
                        }
                        const columnStyle = Object.assign(
                          {},
                          styles.tableCell,
                          i === 0 ? styles.firstColumn : null,
                          i === arr.length - 1 ? styles.lastColumn : null,
                          columnData.style,
                        );

                        return (
                          <Column
                            key={columnData.dataKey + i}
                            {...columnData}
                            style={columnStyle}
                            headerStyle={styles.headerStyle}
                          />
                        );
                      })
                      .filter(Boolean)}
                  </Table>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    );
  };

  renderEmptyMessage = () => {
    return <div>Nothing found for this criteria.</div>;
  };

  rowGetter: (params: { index: number }) => T = ({
    index,
  }: {
    index: number,
  }) => {
    return this.props.data[index];
  };

  onRowClick = (descriptor: { index: number }) => {
    const { onSelectItem } = this.props;
    if (typeof onSelectItem === 'function') {
      onSelectItem(this.rowGetter(descriptor));
    }
  };

  onScroll = () => {
    if (
      !global ||
      !global.document ||
      typeof this.props.onScroll !== 'function'
    ) {
      return;
    }

    this.props.onScroll();
  };

  getRowStyle = (styles: Styles, { index }: { index: number }) => {
    if (index === -1) {
      return styles.tableHeaderRow;
    } else if (index % 2) {
      return styles.tableRowOdd;
    } else {
      return styles.tableRow;
    }
  };

  isRowLoaded = ({ index }: { index: number }) => {
    const { isRowLoaded } = this.props;
    if (typeof isRowLoaded === 'function') {
      return isRowLoaded({ index });
    } else {
      return !!this.props.data[index];
    }
  };

  loadMoreRows = () => {
    const { loadMoreRows } = this.props;
    if (typeof loadMoreRows === 'function') {
      loadMoreRows();
    }
  };

  overscanIndicesGetter = ({
    cellCount, // Number of rows or columns in the current axis
    overscanCellsCount, // Maximum number of cells to over-render in either direction
    startIndex, // Begin of range of visible cells
    stopIndex, // End of range of visible cells
  }: {
    cellCount: number,
    overscanCellsCount: number,
    startIndex: number,
    stopIndex: number,
  }) => {
    return {
      overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
      overscanStopIndex: Math.min(
        cellCount - 1,
        stopIndex + overscanCellsCount,
      ),
    };
  };
}
