//@flow
import React, { PureComponent } from 'react';
import type { InjectedJSS } from 'react-jss';
import jss from 'react-jss';
import { palette } from '@datatheorem/theme';
import ActionInfo from '@material-ui/icons/Info';
import { Paper, Button } from '@material-ui/core';
import type { InsightFinding } from '@datatheorem/user-api/insight_findings';

const styles = {
  ContentAppContainerInfo: {
    wordBreak: 'break-all !important',
  },
  heading: {
    padding: 10,
    fontSize: 18,
  },
  primaryText: {
    padding: 10,
    fontSize: 12,
    wordBreak: 'break-word',
  },
  link: {
    margin: 10,
    marginLeft: 32,
    marginTop: 0,
  },
};

type Props = {
  finding: InsightFinding,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class ContentAppContainerInfo extends PureComponent<Props> {
    render() {
      const { classes, finding } = this.props;

      if (!finding || !finding.targets) {
        return null;
      }

      return (
        <Paper className={classes.ContentAppContainerInfo}>
          <div className={classes.heading}>{finding.title}</div>
          <div style={{ display: 'flex' }}>
            <ActionInfo
              style={{ flexShrink: 0, margin: 10, color: palette.brand }}
            />
            <div className={classes.primaryText}>{finding.description}</div>
          </div>
          <Button
            color="primary"
            variant="contained"
            href={finding.targets[0].formatted_text}
            target="_blank"
            style={{ marginLeft: 52, marginBottom: 10 }}
          >
            DOWNLOAD CONTENT
          </Button>
        </Paper>
      );
    }
  },
);
