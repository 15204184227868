//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PageHeading } from '@datatheorem/components';
import { userInviteFormSubmitted } from '../../actions';
import EditForm from '../users/EditForm';
import type { Application } from '@datatheorem/user-api/mobile_apps';
import { compose } from 'recompose';
import { sortedApps } from '../../selectors/apps';
import { withFormik, type FormikProps } from 'formik';
import { UserSchema } from '@datatheorem/session';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { type State } from '../../ReduxStateType';
import { type DispatchProps } from 'redux';
import { browserHistory } from '../../clients/history';
import type { ContextRouter } from 'react-router-dom';

export type User = {
  first_name: string,
  last_name: string,
  notification_email: ?string,
  login_email: string,
  role: string,
  auth_strategy: string,
  allowed_app_ids: $ReadOnlyArray<string>,
  _hasAllApps?: ?boolean,
};

const emptyUser: User = {
  first_name: '',
  last_name: '',
  notification_email: null,
  login_email: '',
  role: '',
  auth_strategy: 'GOOGLE_ACCOUNT',
  allowed_app_ids: [],
};

type OwnProps = ContextRouter;

type StateProps = {|
  apps: $ReadOnlyArray<Application>,
  loading: mixed,
  formError: mixed,
|};

type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps<>,
  ...FormikProps<*>,
  ...TrackingProps,
|};

export default compose(
  // $FlowFixMe FlowUpgrade
  connect(
    (state: State, props): StateProps => ({
      apps: sortedApps(state, props),
      loading: state.loading.userInvite,
      formError: state.errors.userInvite,
    }),
  ),
  // $FlowFixMe FlowUpgrade
  withFormik<*, User>({
    handleSubmit(values, { props }) {
      props.dispatch(userInviteFormSubmitted(values));
    },
    mapPropsToValues() {
      return emptyUser;
    },
    validationSchema: UserSchema,
  }),
  tracking(dataCreators.component(AnalyticsScreenEnum.USERS_INVITE)),
)(
  class UserInvite extends PureComponent<Props> {
    componentDidUpdate(prevProps) {
      if (
        this.props.setSubmitting &&
        this.props.loading !== prevProps.loading
      ) {
        this.props.setSubmitting(!!this.props.loading);
      }
    }

    render() {
      const {
        apps,
        loading,
        formError,
        handleSubmit,
        values,
        errors,
        setFieldValue,
      } = this.props;

      if (!handleSubmit) {
        throw new Error('Expected this component to be wrapped with formik');
      }

      return (
        <PageHeading
          backButton
          onClickBack={browserHistory.goBack}
          title="Invite User"
        >
          <EditForm
            onSubmit={handleSubmit}
            apps={apps}
            formError={
              formError && typeof formError._error === 'string'
                ? formError._error
                : null
            }
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            loading={!!loading}
            submitLabel="Invite"
          />
        </PageHeading>
      );
    }
  },
);
