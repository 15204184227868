//@flow
import { PureComponent, type Node } from 'react';

type State = {
  value: any,
  error: ?Error,
  loading: boolean,
};

type Props = {
  async: () => any,
  children: (state: State) => Node,
};

export default class AsyncContainer extends PureComponent<Props, State> {
  state = {
    value: null,
    error: null,
    loading: true,
  };

  componentDidMount() {
    this.beginLoadRemote();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.async !== prevProps.async) {
      this.setState({ loading: true }, this.beginLoadRemote);
    }
  }

  render() {
    return this.props.children(this.state);
  }

  beginLoadRemote = () => {
    const { async } = this.props;

    if (typeof async !== 'function') {
      throw new Error(
        `Invalid value of type ${typeof async} provided, expected function`,
      );
    }

    const { loading } = this.state;

    if (!loading) {
      this.setState({ loading: true }, this.loadRemote);
    } else {
      return this.loadRemote();
    }
  };

  loadRemote = async () => {
    try {
      const value = await this.props.async();
      this.setState({ value });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  };
}
