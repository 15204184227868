//@flow
import React, { PureComponent, type Node } from 'react';
import { default as jss, type InjectedJSS } from 'react-jss';
import Paper from 'material-ui/Paper';
import List from 'material-ui/List';

const styles = {
  container: {
    wordBreak: 'break-all !important',
    backgroundSize: 'contain !important',
  },

  heading: {
    padding: 10,
    fontSize: 18,
    wordBreak: 'normal !important',
  },
};

type OwnProps = {|
  header: string,
  children: ?Node,
|};

type Props = {|
  ...OwnProps,
  ...InjectedJSS<typeof styles>,
|};

class InsightsCardWrapper extends PureComponent<Props> {
  render() {
    const { props } = this;
    const { classes, header, children } = props;

    return (
      <Paper className={classes.container}>
        <div className={classes.heading}>{header}</div>
        <List>{children}</List>
      </Paper>
    );
  }
}

export default jss(styles)(InsightsCardWrapper);
