//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from '../../clients/history';
import {
  getShowHideClosedFilter,
  setShowHideClosedFilter,
} from '@datatheorem/findings/findingsFilterCreator';
import { stringFromParametricRequest } from '@datatheorem/string';
import { compose } from 'recompose';
import debounce from 'lodash/fp/debounce';

// Components
import { PageHeading } from '@datatheorem/components';
import SecurityFindings from '../findings/SecurityFindings';
import SecurityFindingDialogManager from './SecurityFindingDialogManager';
import { SecurityFindingEndpoint } from '../../endpoints';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import AppCard from '../advanced-filter/common-ui/AppCard';
import AdvancedSearch from '../advanced-filter/AdvancedSearch';
import CSVExporter from '../advanced-filter/CSVExporter';

// Actions
import {
  setPriorityFilter,
  setSeverityFilter,
  setStatusFilter,
  setCompliancePolicyFilter,
  setDateFilter,
  setDateFromFilter,
  setDateToFilter,
  setCurrentStatusFilter,
  setReleaseType,
  setMinAge,
  setFindingsSort,
  toggleShowing,
} from '../../actions/filterActions';
import { searchStartedAction } from '../../actions/searchActions';

// Selectors
import { apps } from '../../selectors/apps';
import {
  findingsFromSearch,
  paramsForCategory,
} from '../../selectors/securityFindings';
import {
  filteredFindingsSel,
  sortedFindingsSel,
  appsWithScoreAndCountSel,
  getCurrentFilteredFindings,
} from '../../selectors/filterSelectors';

// Types
import type { DashboardInjectedProps } from '../../types/DashboardInjectedProps';
import { type State as ReduxState } from '../../ReduxStateType';
import { type SecurityFinding as SecurityFindingT } from '@datatheorem/findings/types';
import { type Application } from '@datatheorem/user-api/mobile_apps';
import type { FilterStore } from '../../reducers/filters';
import type {
  SetPriorityFilterFn,
  SetSeverityFilterFn,
  SetStatusFilterFn,
  SetCompliancePolicyFilterFn,
  SetDateFilterFn,
  SetDateFromFilterFn,
  SetDateToFilterFn,
  SetCurrentStatusFilterFn,
  SetReleaseTypeFilterFn,
  SetMinAgeFn,
  SetFindingsSortFn,
  ToggleShowingFn,
} from '../../actions/filterActions';
import type { FindingWithApp } from '../../util/filtersUtil';

type OwnProps = DashboardInjectedProps;

type StateProps = {|
  filteredFindings: $ReadOnlyArray<SecurityFindingT>,
  allFindings: $ReadOnlyArray<SecurityFindingT>,
  sortedFindings: $ReadOnlyArray<SecurityFindingT>,
  findings: $ReadOnlyArray<SecurityFindingT>,
  apps: $ReadOnlyArray<Application>,
  appsFiltered: $ReadOnlyArray<Application & { count: number, score: number }>,
  loadedInitial: boolean,
  filterValue: boolean,
  advancedFilter: FilterStore,
  currentFindings: $ReadOnlyArray<FindingWithApp>,
|};

type DispatchProps = {|
  setPriorityFilter: SetPriorityFilterFn,
  setSeverityFilter: SetSeverityFilterFn,
  setStatusFilter: SetStatusFilterFn,
  setCompliancePolicyFilter: SetCompliancePolicyFilterFn,
  setDateFilter: SetDateFilterFn,
  setDateFromFilter: SetDateFromFilterFn,
  setDateToFilter: SetDateToFilterFn,
  setCurrentStatusFilter: SetCurrentStatusFilterFn,
  setReleaseType: SetReleaseTypeFilterFn,
  setMinAge: SetMinAgeFn,
  setFindingsSort: SetFindingsSortFn,
  toggleShowing: ToggleShowingFn,
  searchStartedAction: typeof searchStartedAction,
|};

type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
  ...TrackingProps,
|};

type IntermediateProps = {| ...OwnProps, ...StateProps, ...DispatchProps |};

type State = {
  search: string,
};

export default compose(
  connect<IntermediateProps, OwnProps, _, _, _, _>(
    (state: ReduxState, props): StateProps => ({
      filteredFindings: filteredFindingsSel(state, props),
      sortedFindings: sortedFindingsSel(state, props),
      advancedFilter: state.advancedFilter,
      appsFiltered: appsWithScoreAndCountSel(state, props),
      allFindings: state.securityFindings,
      apps: apps(state),
      findings: findingsFromSearch(state, props),
      loadedInitial:
        state.firstRequest[
          stringFromParametricRequest(
            SecurityFindingEndpoint,
            paramsForCategory(state, {
              match: { params: { category: 'all' } }, // <- hack
            }),
          )
        ],
      filterValue: getShowHideClosedFilter(browserHistory),
      currentFindings: getCurrentFilteredFindings(state, {}),
    }),
    {
      setPriorityFilter,
      setSeverityFilter,
      setStatusFilter,
      setCompliancePolicyFilter,
      setDateFilter,
      setDateFromFilter,
      setDateToFilter,
      setCurrentStatusFilter,
      setReleaseType,
      setMinAge,
      setFindingsSort,
      toggleShowing,
      searchStartedAction,
    },
  ),
  tracking(dataCreators.component(AnalyticsScreenEnum.SEARCH_RESULTS)),
)(
  class Search extends PureComponent<Props, State> {
    state = {
      search: '',
    };

    componentDidMount() {
      this.props.searchStartedAction();
    }

    render() {
      const {
        advancedFilter,
        allFindings,
        sortedFindings,
        currentFindings,
        appsFiltered,
        apps,
        loadedInitial,
        filterValue,
        currentUser,
      } = this.props;

      return (
        <SecurityFindingDialogManager
          onCloseDialog={this.handleCloseDialog}
          currentUser={currentUser}
        >
          {() => (
            <div>
              <PageHeading title="Search" forMenu>
                <div style={{ paddingLeft: '80px', paddingRight: '55px' }}>
                  <CSVExporter>
                    {handleCSVExport => (
                      <AdvancedSearch
                        advancedFilter={advancedFilter}
                        onSearchChange={this.handleSearchChange}
                        searchBoxValue={this.state.search}
                        currentFindings={currentFindings}
                        allFindingsLength={allFindings.length}
                        currentFindingsLength={sortedFindings.length}
                        handleCSVExport={handleCSVExport}
                      />
                    )}
                  </CSVExporter>
                </div>
                {advancedFilter.showing === 'apps' ? (
                  <div style={{ paddingLeft: '80px', paddingRight: '55px' }}>
                    {appsFiltered.map(a => (
                      <AppCard key={a.id} app={a} />
                    ))}
                  </div>
                ) : (
                  <SecurityFindings
                    findings={sortedFindings}
                    findingsCount={sortedFindings.length}
                    apps={apps}
                    onSelectFinding={this.addFindingIdQueryString}
                    filler={!loadedInitial}
                    onChangeShowHideClosed={this.onChangeShowHideClosed}
                    filterValue={filterValue}
                    compliancePolicyFilter={[]}
                    onChangeComplianceSearchFilter={() => {}}
                    showCompliancePolicyFilter={false}
                    showDoMore={false}
                  />
                )}
              </PageHeading>
            </div>
          )}
        </SecurityFindingDialogManager>
      );
    }

    handleSearchChange = debounce(200, (value: string) => {
      browserHistory.push(`/search/${value}`);
      this.setState({ search: value });
    });

    onChangeShowHideClosed = (value: boolean) => {
      const { tracking } = this.props;

      if (!tracking) {
        throw new Error(
          'Expected this component to have been decorated with tracking HoC',
        );
      }

      setShowHideClosedFilter(browserHistory, value);
      tracking.trackEvent(dataCreators.findingsViewToggled(value));
    };

    addFindingIdQueryString = (f: SecurityFindingT): void =>
      this.props.history.push({ search: `?findingId=${f.id}` });

    handleCloseDialog = () => {
      browserHistory.push({ ...this.props.location, search: '' });
    };
  },
);
