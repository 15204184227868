//@flow
import React, { Component } from 'react';
import {
  type Location,
  type ContextRouter,
  matchPath,
  Route,
  Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { type User } from '@datatheorem/user-api/users';
import { Application } from '@datatheorem/components';
import { hasActivityAccess } from '@datatheorem/session';
import { Actions as SessionActions } from '@datatheorem/session';
import { whatsNewCount } from '../../selectors/whatsnew';
import { browserHistory } from '../../clients/history';
import { type DispatchProps } from 'redux';
import type { AccountInfo } from '@datatheorem/user-api/account_info';
import { CustomerNameContext } from '@datatheorem/analytics';
import APIKeyEdit from './APIKeyEdit';
import APIKeyCreate from './APIKeyCreate';
import Dashboard from './Dashboard';
import UserDashboard from './UserDashboard';
import Profile from './Profile';
import SDLCInfo from './SDLCInfo';
import UserEdit from './UserEdit';
import Activity from './Activity';
import UserInvite from './UserInvite';
import AppUpload from './AppUpload';
import WhatsNew from './WhatsNew';
import Preferences from './Preferences';
import APIAccess from './sdlc/APIAccess';
import SAMLSetupInstructions from './sdlc/SAML';
import JiraEditPage from '../jira/JiraEditPage';
import JiraIntegrationDashboard from './JiraIntegrationDashboard';

type OwnProps = {|
  accountInfo: AccountInfo,
  currentUser: User,
  ...ContextRouter,
|};

type StateProps = {|
  showActivity: boolean,
  progressIndicator?: number,
  whatsNewCount: number,
|};

type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps<>,
|};

type State = {|
  returnLocation: Location,
|};

export default connect<Props, OwnProps, _, _, _, _>(
  (state, props): StateProps => ({
    progressIndicator: state.progressIndicator,
    showActivity: hasActivityAccess(state.currentUser),
    whatsNewCount: whatsNewCount(state, props),
  }),
)(
  class AuthenticatedApp extends Component<Props, State> {
    state = {
      returnLocation: browserHistory.location,
    };

    render() {
      const {
        showActivity,
        whatsNewCount,
        currentUser,
        accountInfo,
      } = this.props;

      return (
        <CustomerNameContext
          currentUser={currentUser}
          accountInfo={accountInfo}
        >
          <Application
            showActivity={showActivity}
            showSearch
            onClickSignOut={this.handleClickSignOut}
            whatsNewCount={whatsNewCount}
            onNavigationRequested={this.handleNavigationRequested}
            returnLocation={this.state.returnLocation}
            activeProduct={'app-secure'}
          >
            <Switch>
              <Route path="/profile" component={Profile} />
              <Route path="/preferences" component={Preferences} />

              <Route path="/api_keys/create-form" component={APIKeyCreate} />
              <Route path="/api_keys/:apiKeyId" component={APIKeyEdit} />
              <Route path="/users/invite" component={UserInvite} />
              <Route path="/users/:userId" component={UserEdit} />
              <Route path="/users" component={UserDashboard} />
              <Route path="/sdlc/api_access/" component={APIAccess} />
              <Route path="/sdlc/saml" component={SAMLSetupInstructions} />
              <Route path="/sdlc/jira/global" component={JiraEditPage} />
              <Route path="/sdlc/jira/:appId" component={JiraEditPage} />
              <Route path="/sdlc/jira" component={JiraIntegrationDashboard} />
              <Route path="/sdlc" component={SDLCInfo} />
              <Route path="/activity" component={Activity} />
              <Route path="/upload" component={AppUpload} />
              <Route path="/changelog" component={WhatsNew} />
              <Route
                render={props => (
                  <Dashboard
                    {...props}
                    currentUser={currentUser}
                    showActivity={showActivity}
                  />
                )}
              />
            </Switch>
          </Application>
        </CustomerNameContext>
      );
    }

    handleClickSignOut = () => {
      this.props.dispatch(SessionActions.signOutButtonClicked());
    };

    handleNavigationRequested = (
      path: string | typeof browserHistory.location,
    ) => {
      // Before navigating to a search page, memorize where we were so we can return there
      const toPath = typeof path === 'string' ? path : path.pathname;
      const searchPath = '/search/:search?';
      if (
        toPath &&
        matchPath(toPath, { path: searchPath }) &&
        !matchPath(browserHistory.location.pathname, { path: searchPath })
      ) {
        this.setState({ returnLocation: browserHistory.location });
      }

      browserHistory.push(typeof path === 'string' ? path : { ...path });
    };
  },
);
