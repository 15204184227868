//@flow
import React from 'react';
import type { DispatchProps } from 'redux';
import { PageHeading } from '@datatheorem/components';
import JiraCard from '../../../../components/jira/JiraCard';
import JiraLogo from '../../../jira/images/jira_logo.jpg';
import { jiraIntegrationConfigureGlobalClick } from '../../../../actions';
import jss, { type InjectedJSS } from 'react-jss';

type Props = {|
  onClickBack: () => void,
  ...DispatchProps<>,
  ...InjectedJSS<typeof styles>,
|};

export const styles = {
  container: {
    marginTop: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    paddingRight: 20,
  },

  apps: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  card: {
    width: 300,
    margin: 16,
    alignSelf: 'stretch',
  },
};

export function NoGlobalJiraIntegrationConfigFound(props: Props) {
  const { onClickBack, dispatch, classes } = props;

  return (
    <PageHeading
      backButton
      onClickBack={onClickBack}
      title={'Jira Integration Dashboard'}
    >
      <div className={classes.container} key={1}>
        <div className={classes.apps}>
          <div className={classes.card}>
            <JiraCard
              avatar={JiraLogo}
              title={`Create Global Jira Integration`}
              description={
                'Integrate with Jira to get findings delivered to you as soon as they are discovered. Click the button below to get started'
              }
              buttonLabel={'Setup Now'}
              onClick={() => {
                dispatch(jiraIntegrationConfigureGlobalClick());
              }}
            />
          </div>
        </div>
      </div>
    </PageHeading>
  );
}

export default jss(styles)(NoGlobalJiraIntegrationConfigFound);
