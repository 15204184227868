//@flow
import React, { PureComponent, type Node } from 'react';
import ExpansionPanelItem from '../theme/ExpansionPanelItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { type FieldProps } from 'formik';

type Props = {|
  label: string,
  toggleLabel?: string,
  children: Node,
  setFieldValue: (value: mixed) => mixed,
  ...FieldProps,
|};

export default class PanelItemToggle extends PureComponent<Props> {
  render() {
    const { label, children } = this.props;
    return (
      <ExpansionPanelItem
        disabled
        primaryText={label}
        value={this.renderValue(this.props)}
      >
        {children}
      </ExpansionPanelItem>
    );
  }

  renderValue = ({ field, toggleLabel, setFieldValue }: Props) => (
    <FormControlLabel
      control={
        <Checkbox
          {...field}
          value={field.value ? field.value.toString() : ''}
          checked={field.value ? field.value : false}
          color="primary"
          onChange={e => setFieldValue(e.target.checked)}
        />
      }
      label={toggleLabel ? toggleLabel : ''}
    />
  );
}
