//@flow
import React, { PureComponent, type Node } from 'react';
import LinkIcon from 'material-ui/svg-icons/action/launch';
import ArrowBack from 'material-ui/svg-icons/navigation/arrow-back';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import FlatButton from 'material-ui/FlatButton';
import { fontFamily, fontSizes, palette, spacing } from '@datatheorem/theme';

import Image from './Image';
import Tabs from './Tabs';

const tabsHeight = 48;
const headerHeight = 45;
const startingPadding = 53;

const getStyles = (hasTabs, forMenu) => {
  const styles: Object = {
    wrapper: {},
    container: {
      fontFamily: fontFamily,
      backgroundColor: palette.brightBg,
      paddingTop: startingPadding,
      paddingBottom: startingPadding + (hasTabs ? tabsHeight : 0),
      position: 'fixed',
      left: forMenu ? spacing.menuWidth : 0,
      top: 50,
      right: 0,
      zIndex: 2,
      borderBottomWidth: hasTabs ? 1 : 0,
      borderBottomStyle: 'solid',
      borderBottomColor: palette.accent,
    },
    containerBody: {
      height: headerHeight,
      paddingRight: 50,
    },
    titleContainer: {
      marginLeft: 93,
    },
    title: {
      fontFamily: fontFamily,
      fontSize: fontSizes.huge,
      color: palette.gray20,
      fontWeight: 'normal',
      marginTop: 0,
      marginBottom: 0,
      position: 'relative',
      display: 'inline',
      alignItems: 'baseline',
    },
    rightSide: {
      float: 'right',
      textAlign: 'right',
      position: 'relative',
      top: 16,
      height: headerHeight,
      zIndex: 1,
    },
    tabBarWrapper: {
      height: tabsHeight,
      position: 'absolute',
      width: '70vw',
      bottom: 0,
    },
    link: {
      textDecoration: 'none',
      fontSize: 16,
      lineHeight: '16px',
    },
    linkIcon: {
      fontSize: 16,
      lineHeight: '16px',
      verticalAlign: '-2px',
      height: 16,
      width: 16,
      marginLeft: 5,
      marginRight: 5,
    },
    backButton: {
      position: 'absolute',
      top: 42,
      left: 15,
    },
    backButtonBG: palette.brand,
    iconImg: {
      float: 'left',
      height: 45,
      width: 45,
      marginRight: 15,
      borderRadius: 10,
      transform: `scale(1.111111) translateY(5px)`,
    },
  };

  styles.contentWrapper = {
    overflow: 'auto',
    transition: 'padding-top 0.5s',
    paddingTop:
      styles.container.paddingTop +
      styles.container.paddingBottom +
      styles.containerBody.height +
      styles.container.borderBottomWidth,
  };

  return styles;
};

type Props = {
  title: Node,
  tabs?: Node,
  locationPathnameForTabs?: string,
  children: ?Node,
  backButton?: boolean,
  onClickBack?: () => mixed,
  linkTo?: string,
  primaryAction?: any,
  forMenu?: ?boolean,
  iconUrl?: string,
  afterTitle?: Node,
  rightSide?: Node,
};

export default class PageHeading extends PureComponent<Props> {
  render() {
    const {
      title,
      tabs,
      children,
      linkTo,
      primaryAction,
      forMenu,
      iconUrl,
      backButton,
      onClickBack,
      afterTitle,
      rightSide,
      locationPathnameForTabs,
    } = this.props;
    const styles = getStyles(tabs, forMenu);

    return (
      <div style={styles.wrapper}>
        <div style={styles.container}>
          <div style={styles.containerBody}>
            {rightSide && <div style={styles.rightSide}>{rightSide}</div>}
            <div style={styles.titleContainer}>
              {backButton && (
                <FloatingActionButton
                  backgroundColor={styles.backButtonBG}
                  style={styles.backButton}
                  onClick={onClickBack}
                >
                  <ArrowBack />
                </FloatingActionButton>
              )}
              <h1 style={styles.title}>
                {iconUrl && <Image style={styles.iconImg} src={iconUrl} />}
                {title}
                {linkTo && (
                  <a
                    href={linkTo}
                    target="_blank"
                    style={styles.link}
                    rel="noopener noreferrer"
                  >
                    <LinkIcon
                      style={styles.linkIcon}
                      color={palette.navColor}
                    />
                  </a>
                )}
                {primaryAction && <FlatButton {...primaryAction} />}
              </h1>
              {afterTitle}
            </div>
          </div>
          {tabs && (
            <div style={styles.tabBarWrapper}>
              <Tabs marginLeft={115} value={locationPathnameForTabs}>
                {tabs}
              </Tabs>
            </div>
          )}
        </div>
        {children && <div style={styles.contentWrapper}>{children}</div>}
      </div>
    );
  }
}
