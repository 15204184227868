//@flow

import React, { PureComponent, type Node } from 'react';
import ExpansionPanelItem from '../theme/ExpansionPanelItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { palette } from '@datatheorem/theme';
import { ClipboardIcon } from '@datatheorem/components';

import copyToClipboard from '@datatheorem/components/src/copyToClipboard.util';

type Props = {
  label: string,
  value: string,
  children?: Node,
};

type State = {
  displayCopiedMessage: boolean,
};

export default class PanelItemCopyableField extends PureComponent<
  Props,
  State,
> {
  state = {
    displayCopiedMessage: false,
  };

  render() {
    const { label, children } = this.props;
    return (
      <ExpansionPanelItem
        disabled
        primaryText={label}
        value={this.renderValue()}
      >
        {children}
      </ExpansionPanelItem>
    );
  }

  handleClickCopyPasteButton = (evt: Event) => {
    evt.stopPropagation();
    const { value } = this.props;
    copyToClipboard(value);
    this.setState(
      {
        displayCopiedMessage: true,
      },
      () =>
        setTimeout(() => this.setState({ displayCopiedMessage: false }), 1500),
    );
  };

  renderValue = () => {
    const { value } = this.props;
    return (
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <span
          style={{
            flexGrow: 2,
            marginRight: 5,
            fontFamily: 'monospace',
            color: '#c4c4c4',
          }}
          title="copy to clipboard"
        >
          {this.state.displayCopiedMessage ? 'text copied!' : value}
        </span>
        <Tooltip title="Copy to clipboard">
          <IconButton
            aria-label="copy to clipboard"
            onClick={this.handleClickCopyPasteButton}
          >
            <ClipboardIcon {...this.props} color={palette.brand} />
          </IconButton>
        </Tooltip>
      </div>
    );
  };
}
