// @flow
import React, { Fragment, PureComponent } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { findingsToCSV } from '../../util/filtersUtil';
// Components
import FindingsFilterFields from './findings-ui/FindingsFilterFields';
import FindingsMetadata from './findings-ui/FindingsMetadata';
import CurrentFiltersChips from './common-ui/CurrentFiltersChips';
import { SearchBox } from '@datatheorem/components';
import AdvanceSearchActions from './AdvanceSearchActions';

//Analytics
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';

// Actions
import {
  addFilter,
  removeFilter,
  requestFiltersFromServer,
  requestFiltersFromStorage,
  resetFilter,
  searchOpenClicked,
  setAsCurrentFilter,
  setCompliancePolicyFilter,
  setCurrentStatusFilter,
  setDateFilter,
  setDateFromFilter,
  setDateToFilter,
  setFindingsSort,
  setMinAge,
  setPriorityFilter,
  setStoreBlockersFilter,
  setReleaseType,
  setSeverityFilter,
  setStatusFilter,
  toggleShowing,
} from '../../actions/filterActions';

// Types
import type {
  SetStoreBlockersFilterFn,
  SetPriorityFilterFn,
  SetSeverityFilterFn,
  SetCompliancePolicyFilterFn,
  SetStatusFilterFn,
  SetDateFilterFn,
  SetDateFromFilterFn,
  SetDateToFilterFn,
  SetCurrentStatusFilterFn,
  SetReleaseTypeFilterFn,
  SetMinAgeFn,
  SetFindingsSortFn,
  ToggleShowingFn,
  AddFilterFn,
  RemoveFilterFn,
  SetAsCurrentFilterFn,
  ResetFn,
  RequestFiltersFromServerFn,
  RequestFiltersFromStorageFn,
  SearchOpenClickedFn,
} from '../../actions/filterActions';
import type { FilterStore } from '../../reducers/filters';
import type { FindingWithApp } from '../../util/filtersUtil';

type OwnProps = {|
  mode?: 'advanced' | 'minimal',
  advancedFilter: FilterStore,
  allFindingsLength: number,
  currentFindings: $ReadOnlyArray<FindingWithApp>,
  currentFindingsLength: number,
  onSearchChange: string => mixed,
  searchBoxValue: string,
  handleCSVExport: (data: string, name: string) => mixed,
|};

type DispatchProps = {|
  setStoreBlockersFilter: SetStoreBlockersFilterFn,
  setPriorityFilter: SetPriorityFilterFn,
  setSeverityFilter: SetSeverityFilterFn,
  setCompliancePolicyFilter: SetCompliancePolicyFilterFn,
  setStatusFilter: SetStatusFilterFn,
  setDateFilter: SetDateFilterFn,
  setDateFromFilter: SetDateFromFilterFn,
  setDateToFilter: SetDateToFilterFn,
  setCurrentStatusFilter: SetCurrentStatusFilterFn,
  setReleaseType: SetReleaseTypeFilterFn,
  setMinAge: SetMinAgeFn,
  setFindingsSort: SetFindingsSortFn,
  toggleShowing: ToggleShowingFn,

  addFilter: AddFilterFn,
  removeFilter: RemoveFilterFn,
  setAsCurrentFilter: SetAsCurrentFilterFn,
  resetFilter: ResetFn,
  requestFiltersFromServer: RequestFiltersFromServerFn,
  requestFiltersFromStorage: RequestFiltersFromStorageFn,
  searchOpenClicked: SearchOpenClickedFn,
|};

type StateProps = {||};

type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
  ...TrackingProps,
|};

type State = {|
  advanceSearchMode: boolean,
  // Reports UI
  saveReportDialogOpen: boolean,
  downloadResultDialogOpen: boolean,
  filterName: string,
  nameError: boolean,

  // Filters
  currentStatusFilterOpened: boolean,
  priorityFilterOpened: boolean,
  severityFilterOpened: boolean,
  releaseTypeFilterOpened: boolean,
  compliancePolicyFilterOpened: boolean,
  ageFilterOpened: boolean,
  statusDuringDateRangeFilterOpened: boolean,
  storeBlockersFilterOpened: boolean,
|};

export class AdvancedSearch extends PureComponent<Props, State> {
  state = {
    advanceSearchMode: true,

    // Reports UI
    saveReportDialogOpen: false,
    downloadResultDialogOpen: false,
    filterName: '',
    nameError: false,

    // Filters
    currentStatusFilterOpened: false,
    priorityFilterOpened: false,
    severityFilterOpened: false,
    releaseTypeFilterOpened: false,
    compliancePolicyFilterOpened: false,
    ageFilterOpened: false,
    statusDuringDateRangeFilterOpened: false,
    storeBlockersFilterOpened: false,
  };

  componentDidMount() {
    this.props.requestFiltersFromServer();
    this.props.requestFiltersFromStorage();
    if (!this.props.tracking) {
      throw new Error(
        'Expected this component to have been decorated with tracking HoC',
      );
    }

    if (this.props.mode === 'minimal') {
      this.setState({
        advanceSearchMode: false,
      });
    }
  }

  // Filter toggle functions
  openFilter = (f: string) => {
    this.setState({
      [f]: true,
    });
  };
  closeFilter = (f: string) => {
    this.setState({
      [f]: false,
    });
  };

  openCurrentStatusFilter = () => this.openFilter('currentStatusFilterOpened');
  openReleaseTypeFilter = () => this.openFilter('releaseTypeFilterOpened');
  openPriorityFilter = () => this.openFilter('priorityFilterOpened');
  openStoreBlockersFilter = () => this.openFilter('storeBlockersFilterOpened');
  openSeverityFilter = () => this.openFilter('severityFilterOpened');
  openCompliancePolicyFilter = () =>
    this.openFilter('compliancePolicyFilterOpened');
  openStatusDuringDateRangeFilter = () =>
    this.openFilter('statusDuringDateRangeFilterOpened');
  openAgeFilter = () => this.openFilter('ageFilterOpened');

  closeAndResetCurrentStatusFilter = () => {
    this.props.setCurrentStatusFilter([]);
    this.closeFilter('currentStatusFilterOpened');
  };
  closeAndResetReleaseTypeFilter = () => {
    this.props.setReleaseType([]);
    this.closeFilter('releaseTypeFilterOpened');
  };
  closeAndResetPriorityFilter = () => {
    this.props.setPriorityFilter([]);
    this.closeFilter('priorityFilterOpened');
  };
  closeAndResetStoreBlockersFilter = () => {
    this.props.setStoreBlockersFilter([]);
    this.closeFilter('storeBlockersFilterOpened');
  };
  closeAndResetSeverityFilter = () => {
    this.props.setSeverityFilter([]);
    this.closeFilter('severityFilterOpened');
  };
  closeAndResetCompliancePolicyFilter = () => {
    this.props.setCompliancePolicyFilter([]);
    this.closeFilter('compliancePolicyFilterOpened');
  };
  closeAndResetStatusDuringDateRangeFilter = () => {
    this.props.setStatusFilter([]);
    this.props.setDateFilter('ANY_TIME');
    this.closeFilter('statusDuringDateRangeFilterOpened');
  };
  closeAndResetAgeFilter = () => {
    this.props.setMinAge('');
    this.closeFilter('ageFilterOpened');
  };

  // Reports Functions
  handleReportNameChange = (v: string) =>
    this.setState({
      filterName: v,
      nameError: false,
    });
  handleSaveReportDialogOpen = () =>
    this.setState({ saveReportDialogOpen: true });
  handleSaveReportDialogClose = () =>
    this.setState({ saveReportDialogOpen: false, nameError: false });
  handleSaveReport = () => {
    const { addFilter, advancedFilter, tracking } = this.props;
    const { filterName } = this.state;
    const name = filterName.trim();

    if (name) {
      addFilter(name, advancedFilter.findingsCriteria);
      if (!tracking) {
        throw new Error(
          'Expected this component to have been decorated with tracking HoC',
        );
      }
      tracking.trackEvent(dataCreators.appSecureReportSaved(name));
      this.handleSaveReportDialogClose();
      this.handleReportNameChange('');
    } else this.setState({ nameError: true });
  };

  handleDownloadResultDialogOpen = () =>
    this.setState({ downloadResultDialogOpen: true });
  handleDownloadResultDialogClose = () =>
    this.setState({ downloadResultDialogOpen: false, nameError: false });
  handleExportCurrentFindings = () => {
    const findings = this.props.currentFindings;
    const { handleCSVExport, tracking } = this.props;
    const { filterName } = this.state;

    if (filterName) {
      handleCSVExport(findingsToCSV(findings), filterName);
      if (!tracking) {
        throw new Error(
          'Expected this component to have been decorated with tracking HoC',
        );
      }
      tracking.trackEvent(
        dataCreators.appSecureExportCurrentFindings(filterName),
      );
      this.handleDownloadResultDialogClose();
    } else this.setState({ nameError: true });
  };

  render() {
    const {
      advancedFilter,
      searchBoxValue,
      onSearchChange,
      allFindingsLength,
      currentFindingsLength,

      // These props aren't used but need to be removed from ...rest
      resetFilter,
      requestFiltersFromStorage,
      addFilter,
      currentFindings,
      handleCSVExport,
      removeFilter,
      requestFiltersFromServer,
      searchOpenClicked,
      setAsCurrentFilter,
      tracking,
      setFindingsSort,
      toggleShowing,
      ...rest
    } = this.props;
    const { findingsCriteria, showing } = advancedFilter;

    const {
      saveReportDialogOpen,
      downloadResultDialogOpen,
      nameError,
      currentStatusFilterOpened,
      priorityFilterOpened,
      storeBlockersFilterOpened,
      severityFilterOpened,
      releaseTypeFilterOpened,
      compliancePolicyFilterOpened,
      ageFilterOpened,
      statusDuringDateRangeFilterOpened,
    } = this.state;

    // This object is passed down to child components as props
    const displayed = {
      currentStatusFilter:
        currentStatusFilterOpened ||
        findingsCriteria.selectedCurrentStatuses.length > 0,
      releaseTypeFilter:
        releaseTypeFilterOpened ||
        findingsCriteria.selectedReleaseTypes.length > 0,
      priorityFilter:
        priorityFilterOpened || findingsCriteria.selectedPriorities.length > 0,
      storeBlockersFilter:
        storeBlockersFilterOpened ||
        findingsCriteria.selectedStoreBlockers.length > 0,
      severityFilter:
        severityFilterOpened || findingsCriteria.selectedSeverities.length > 0,
      compliancePolicyFilter:
        compliancePolicyFilterOpened ||
        findingsCriteria.selectedCompliancePolicies.length > 0,
      statusDuringDateRangeFilter:
        statusDuringDateRangeFilterOpened ||
        findingsCriteria.selectedStatuses.statuses.length > 0 ||
        findingsCriteria.selectedStatuses.dateRange.type !== 'ANY_TIME',
      ageFilter: ageFilterOpened || findingsCriteria.minAge !== '',
    };

    return (
      <Fragment>
        <AdvanceSearchActions
          isAdvanceSearch={this.state.advanceSearchMode}
          handleToggleAdvanceSearch={() =>
            this.setState(state => ({
              advanceSearchMode: !state.advanceSearchMode,
            }))
          }
          saveReportDialogOpen={saveReportDialogOpen}
          downloadResultDialogOpen={downloadResultDialogOpen}
          nameError={nameError}
          handleSaveReport={this.handleSaveReport}
          handleSaveReportDialogOpen={this.handleSaveReportDialogOpen}
          handleSaveReportDialogClose={this.handleSaveReportDialogClose}
          handleDownloadResultDialogOpen={this.handleDownloadResultDialogOpen}
          handleDownloadResultDialogClose={this.handleDownloadResultDialogClose}
          handleReportNameChange={this.handleReportNameChange}
          handleExportCurrentFindings={this.handleExportCurrentFindings}
        />
        {this.state.advanceSearchMode && (
          <Fragment>
            <SearchBox onChange={onSearchChange} value={searchBoxValue} />
            <CurrentFiltersChips
              // Displayed
              displayed={displayed}
              // Openers
              openCurrentStatusFilter={this.openCurrentStatusFilter}
              openReleaseTypeFilter={this.openReleaseTypeFilter}
              openStoreBlockersFilter={this.openStoreBlockersFilter}
              openPriorityFilter={this.openPriorityFilter}
              openSeverityFilter={this.openSeverityFilter}
              openCompliancePolicyFilter={this.openCompliancePolicyFilter}
              openStatusDuringDateRangeFilter={
                this.openStatusDuringDateRangeFilter
              }
              openAgeFilter={this.openAgeFilter}
              // Closers
              closeAndResetCurrentStatusFilter={
                this.closeAndResetCurrentStatusFilter
              }
              closeAndResetReleaseTypeFilter={
                this.closeAndResetReleaseTypeFilter
              }
              closeAndResetPriorityFilter={this.closeAndResetPriorityFilter}
              closeAndResetStoreBlockersFilter={
                this.closeAndResetStoreBlockersFilter
              }
              closeAndResetSeverityFilter={this.closeAndResetSeverityFilter}
              closeAndResetCompliancePolicyFilter={
                this.closeAndResetCompliancePolicyFilter
              }
              closeAndResetStatusDuringDateRangeFilter={
                this.closeAndResetStatusDuringDateRangeFilter
              }
              closeAndResetAgeFilter={this.closeAndResetAgeFilter}
            />
            <FindingsFilterFields
              displayed={displayed}
              findingsCriteria={findingsCriteria}
              {...rest}
            />
          </Fragment>
        )}
        <FindingsMetadata
          findingsCriteria={findingsCriteria}
          allFindingsLength={allFindingsLength}
          currentFindingsLength={currentFindingsLength}
          showing={showing}
          toggleShowing={toggleShowing}
          setFindingsSort={setFindingsSort}
          displayed={displayed}
        />
      </Fragment>
    );
  }
}

const filterActions = {
  setStoreBlockersFilter,
  setPriorityFilter,
  setSeverityFilter,
  setCompliancePolicyFilter,
  setStatusFilter,
  setDateFilter,
  setDateFromFilter,
  setDateToFilter,
  setCurrentStatusFilter,
  setReleaseType,
  setMinAge,
  setFindingsSort,
  toggleShowing,
  addFilter,
  removeFilter,
  setAsCurrentFilter,
  resetFilter,
  requestFiltersFromStorage,
  requestFiltersFromServer,
  searchOpenClicked,
};

export default compose(
  connect<
    {| ...OwnProps, ...StateProps, ...DispatchProps |},
    OwnProps,
    _,
    _,
    _,
    _,
  >(
    null, // <- Don't need to map any state to props!
    filterActions,
  ),
  tracking(
    dataCreators.component(AnalyticsScreenEnum.APP_SECURE_ADVANCED_SEARCH),
  ),
)(AdvancedSearch);
