//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

import { palette } from '@datatheorem/theme';
import { formattedFromNow } from '@datatheorem/date';

const HEIGHT_PX = 26;

function ifHasStuffBesidesChildren(value) {
  return ({ indicator, date }) => (indicator || date ? value : null);
}

const styles = {
  CardLine: {
    height: HEIGHT_PX,
    display: 'flex',
    alignItems: 'baseline',
  },

  text: {
    borderLeft: ifHasStuffBesidesChildren(`1px solid #ccc`),
    marginLeft: ifHasStuffBesidesChildren(10),
    marginRight: 5,
    whiteSpace: 'nowrap',
    flex: '0 1 auto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: 10,
    paddingRight: 5,
  },

  date: {
    color: palette.faded,
    marginLeft: 5,
    fontSize: 12,
    whiteSpace: 'nowrap',
  },

  indicator: {
    whiteSpace: 'nowrap',
  },
};

type Props = {
  indicator: any,
  date?: string,
  children: any,
} & InjectedJSS<typeof styles>;

const fromNow = formattedFromNow({
  short: 'MM/DD/YYYY',
  long: 'MM/DD/YYYY',
});

export function getHeight(): number {
  return HEIGHT_PX;
}

export default jss(styles)(
  class CardLine extends PureComponent<Props> {
    render() {
      const { classes, indicator, date, children } = this.props;

      return (
        <div className={classes.CardLine}>
          {indicator && <span className={classes.indicator}>{indicator}</span>}
          {date && <span className={classes.date}>{fromNow(date)}</span>}
          <span className={classes.text}>{children}</span>
        </div>
      );
    }
  },
);
