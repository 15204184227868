// @flow
import React, { Component } from 'react';

export const MessageTypeEnum = Object.freeze({
  REQUEST_COOKIE: 'REQUEST_COOKIE',
  REQUEST_SIGN_OUT: 'REQUEST_SIGN_OUT',
  SESSION: 'SESSION',
});

export type MessageType =
  | { t: 'REQUEST_COOKIE' }
  | { t: 'REQUEST_SIGN_OUT' }
  | { t: 'SESSION', session: string };

type State = {};

type Props = {
  requestType: 'req-session' | 'req-sign-out',
  src: string,
  onCompleteRequest: any => any,
  authOrigin: string,
};

class Gate extends Component<Props, State> {
  elem: ?HTMLIFrameElement;
  componentDidMount() {
    this.listenForMessages();
  }

  componentWillUnmount() {
    this.unlistenForMessages();
  }

  listenForMessages = () =>
    window.addEventListener('message', this.onMessage, false);

  unlistenForMessages = () =>
    window.removeEventListener('message', this.onMessage);

  onMessage = (e: MessageEvent) => {
    const { requestType, onCompleteRequest, authOrigin } = this.props;
    if (e.origin === authOrigin && e.data && typeof e.data === 'object') {
      switch (e.data.t) {
        case MessageTypeEnum.SESSION: {
          // TODO: this.props.onOpen.cb(e.data.session)
          requestType === 'req-session' && onCompleteRequest(e.data.session);
          // Cookies.set(SESSION_KEY, e.data.session);
          // return this.setState({ state: State.RECEIVED, cook: e.data.session });
          break;
        }
        default:
          return;
      }
    }
  };

  requestSession = () =>
    this.elem &&
    this.elem.contentWindow.postMessage(
      { t: MessageTypeEnum.REQUEST_COOKIE },
      this.props.authOrigin,
    );

  requestSignOut = () => {
    if (this.elem) {
      this.elem.contentWindow.postMessage(
        { t: MessageTypeEnum.REQUEST_SIGN_OUT },
        this.props.authOrigin,
      );
      this.props.onCompleteRequest();
    }
  };

  render() {
    const { props } = this;
    return (
      <div>
        {props.requestType === 'req-session' && (
          <iframe
            style={{ display: 'none' }}
            src={props.src}
            ref={elem => (this.elem = elem)}
            onLoad={this.requestSession}
          />
        )}

        {props.requestType === 'req-sign-out' && (
          <iframe
            style={{ display: 'none' }}
            src={props.src}
            ref={elem => (this.elem = elem)}
            onLoad={this.requestSignOut}
          />
        )}
      </div>
    );
  }
}

export default Gate;
