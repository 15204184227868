//@flow
import { takeEvery, spawn, call, put } from 'redux-saga/effects';
import { whatsNewResetClicked, whatsNewSeen, whatsNewUpdate } from '../actions';
import { type Saga } from 'redux-saga';
import { getWhatsNewSeen, setWhatsNewSeen } from '../clients/local';

export function* whatsnewFlow(): Saga<void> {
  const time = yield call(getWhatsNewSeen);
  if (time) {
    yield put(whatsNewUpdate(time));
  }
  yield spawn(watchForSeenNew);
  yield spawn(watchForWhatsNewReset);
}

export function* watchForSeenNew(): Saga<void> {
  yield takeEvery(whatsNewSeen.toString(), function*(): Saga<void> {
    yield call(setWhatsNewSeen, new Date());
  });
}

export function* watchForWhatsNewReset(): Saga<void> {
  yield takeEvery(whatsNewResetClicked.toString(), function*(): Saga<void> {
    yield call(setWhatsNewSeen, new Date());
    yield put(whatsNewUpdate(new Date().toString()));
  });
}
