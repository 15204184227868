//@flow
import React, { PureComponent } from 'react';

import Button from './../Button';

export default class InquireButton extends PureComponent<{
  onClickInquire: (e: SyntheticMouseEvent<HTMLElement>) => mixed,
}> {
  render() {
    return (
      <Button ariaLabel="Inquire" onClick={this.props.onClickInquire}>
        Inquire
      </Button>
    );
  }
}
