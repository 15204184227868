//@flow
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from '../../clients/history';
import {
  getShowHideClosedFilter,
  setShowHideClosedFilter,
} from '@datatheorem/findings/findingsFilterCreator';
import {
  makeFindingsByAppIdParamSelector,
  findingsCount,
} from '../../selectors/securityFindings';
import { type SecurityFinding } from '@datatheorem/findings/types';
import { type Application } from '@datatheorem/user-api/mobile_apps';
import { exportButtonClicked, sortUtilButtonClicked } from '../../actions';
import { inquireShadowScanFindings } from './../../actions/shadowScanFindings';
import { compose } from 'recompose';
import {
  appFromParam,
  subscriptionsBeyondAppFromParam,
} from '../../selectors/apps';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { createShadowscanFindingsByAppIdParamSelector } from '@datatheorem/findings/shadow-scan/selectors';
import type { ShadowscanSecurityFinding } from '@datatheorem/user-api/shadow_security_findings';
import { type MobileApplicationChildProps } from '../../types/MobileApplicationChildProps';
import { type State as ReduxState } from '../../ReduxStateType';
import MobileAppIssues from '../apps/MobileAppIssues';

type OwnProps = {|
  ...MobileApplicationChildProps,
|};

type DispatchProps = {|
  userClickedExportButton: typeof exportButtonClicked,
  dispatchSortUtilButtonClicked: typeof sortUtilButtonClicked,
  dispatchInquireShadowScanFindings: typeof inquireShadowScanFindings,
|};

type StateProps = {|
  findings: $ReadOnlyArray<SecurityFinding>,
  findingsCount: number | void,
  filterValue: boolean,
  shadowscanFindings: $ReadOnlyArray<ShadowscanSecurityFinding>,
  app: ?Application,
  subscriptionsBeyondAppFromParam: ?$ReadOnlyArray<string>,
|};

type Props = {|
  ...OwnProps,
  ...TrackingProps,
  ...StateProps,
  ...DispatchProps,
|};

type IntermediatePropsOne = {|
  ...OwnProps,
  ...TrackingProps,
|};

export default compose(
  tracking(dataCreators.component(AnalyticsScreenEnum.MOBILE_APP_ISSUES)),
  connect<Props, IntermediatePropsOne, _, _, _, _>(
    (state: ReduxState, props: IntermediatePropsOne): StateProps => {
      const selectorFindingsByAppIdParam = makeFindingsByAppIdParamSelector();

      return {
        findings: selectorFindingsByAppIdParam(state, props),
        findingsCount: findingsCount(state, props),
        filterValue: getShowHideClosedFilter(browserHistory),
        shadowscanFindings: createShadowscanFindingsByAppIdParamSelector(
          browserHistory,
        )(state, props),
        app: appFromParam(state, props),
        subscriptionsBeyondAppFromParam: subscriptionsBeyondAppFromParam(
          state,
          props,
        ),
      };
    },
    {
      userClickedExportButton: exportButtonClicked,
      dispatchSortUtilButtonClicked: sortUtilButtonClicked,
      dispatchInquireShadowScanFindings: inquireShadowScanFindings,
    },
  ),
)(function MobileApplicationIssues({
  findings,
  filterValue,
  subscriptionsBeyondAppFromParam,
  shadowscanFindings,
  app,
  dispatchInquireShadowScanFindings,
  dispatchSortUtilButtonClicked,
  userClickedExportButton,
  tracking,
  currentUser,
  findingsCount,
}: Props) {
  const handleChangeShowHideClosed = useCallback(
    (value: boolean) => {
      setShowHideClosedFilter(browserHistory, value);

      if (!tracking) {
        throw new Error(
          'Expected this component to have been decorated with tracking HoC',
        );
      }

      tracking.trackEvent(dataCreators.findingsViewToggled(value));
    },
    [tracking],
  );

  const handleClickExport: typeof exportButtonClicked = useCallback(
    appId => {
      if (!tracking) {
        throw new Error(
          'Expected this component to have been decorated with tracking HoC',
        );
      }

      tracking.trackEvent(
        dataCreators.appSecureAppIssuesExported((app && app.name) || ''),
      );

      return userClickedExportButton(appId);
    },
    [userClickedExportButton, app, tracking],
  );

  if (!subscriptionsBeyondAppFromParam) {
    throw new Error('expected MobileapplicationIssues to have app param');
  }

  if (!app) {
    throw new Error('expected MobileapplicationIssues to have app param');
  }

  return (
    <MobileAppIssues
      app={app}
      findings={findings}
      findingsCount={findingsCount}
      shadowscanFindings={shadowscanFindings}
      filterValue={filterValue}
      subscriptionsBeyondApp={subscriptionsBeyondAppFromParam}
      onClickShadowScanInquire={dispatchInquireShadowScanFindings}
      onClickSort={dispatchSortUtilButtonClicked}
      onClickExport={handleClickExport}
      onChangeShowHideClosed={handleChangeShowHideClosed}
      currentUser={currentUser}
    />
  );
});
