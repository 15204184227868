//@flow
import React, { PureComponent } from 'react';
import BrandedProductIcon from './BrandedProductIcon';
import APIInspectInactiveIcon from './icon-api-inspect-inactive.png';
import APIInspectActiveIcon from './icon-api-inspect-active.png';

type Props = {
  active: boolean,
};

export default class APIInspectIcon extends PureComponent<Props> {
  render() {
    return (
      <BrandedProductIcon
        src={this.props.active ? APIInspectActiveIcon : APIInspectInactiveIcon}
      />
    );
  }
}
