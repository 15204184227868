//@flow
import React, { PureComponent, type Node } from 'react';
import type { InjectedJSS } from 'react-jss';
import jss from 'react-jss';
import Paper from 'material-ui/Paper';
import Divider from 'material-ui/Divider';
import List from 'material-ui/List';
import ListItem from 'material-ui/List/ListItem';
import TooltipIcon from 'material-ui/svg-icons/action/help-outline';
import Popover from 'material-ui/Popover';

import { palette, fontSizes } from '@datatheorem/theme';

import Markdown from './Markdown';
import AndroidImage from './platform_images/android_icon.png';
import IOSImage from './platform_images/ios_icon.png';

const styles = {
  InfoCard: {
    wordBreak: 'break-all !important',
    backgroundSize: 'contain !important',
    position: 'relative !important',
    paddingLeft: ({ attention }: { attention: string }) =>
      `${attention ? 7 : 0}px !important`,
    fontSize: fontSizes.medium,
    minHeight: 110,
    minWidth: 110,
  },
  heading: {
    padding: 20,
    paddingBottom: 10,
    paddingRight: 50,
    position: 'relative',
    zIndex: 1,
    cursor: ({ tooltip }: Props) => tooltip && 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: ({ heading, alertOn }: Props) =>
      alertOn && alertOn.find(alert => alert.alert && alert.title === heading)
        ? palette.attention
        : 'transparent',
  },
  primaryText: {
    padding: 10,
    fontSize: ({ fontSize, primaryText }: Props) =>
      fontSize ||
      (primaryText && primaryText.length > 15
        ? fontSizes.medium
        : fontSizes.large),
  },

  list: {},

  icon: {
    position: 'absolute',
    width: 80,
    right: 20,
    top: 12,
  },

  img: {
    maxWidth: '100%',
  },

  tooltipIcon: {
    color: '#999 !important',
    attentionColor: '#eee !important',
  },

  headingText: {
    verticalAlign: 'middle',
    color: ({ attention }: Props) => (attention ? 'white' : null),
  },

  headingIcon: {
    position: 'absolute',
    right: 20,
    top: 15,
  },

  tooltip: {
    maxWidth: '300px !important',
    padding: '20px !important',
    borderRadius: '5px !important',
  },

  indicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: ({ attention }: Props) =>
      attention ? palette.attention : 'transparent',
  },

  inner: {
    position: 'relative',
    color: ({ attention }: Props) => (attention ? 'white' : null),
  },
};

type Props = {
  heading: string,
  primaryText?: ?string,
  list?: ?any,
  android?: ?boolean,
  ios?: ?boolean,
  tooltip?: ?string,
  attention?: ?boolean,
  fontSize?: ?number,
  children?: Node,
  alertOn?: ?$ReadOnlyArray<any>,
} & InjectedJSS<typeof styles>;

type State = {
  tooltipOpen: boolean,
  tooltipAnchorEl: ?HTMLElement,
};

export default jss(styles)(
  class InfoCard extends PureComponent<Props, State> {
    state = {
      tooltipOpen: false,
      tooltipAnchorEl: null,
    };

    render() {
      const {
        classes,
        heading,
        primaryText,
        list,
        android,
        ios,
        tooltip,
        children,
        attention,
      } = this.props;
      const { tooltipOpen, tooltipAnchorEl } = this.state;

      return (
        <Paper className={classes.InfoCard}>
          <div className={classes.indicator} />
          {(ios || android) && (
            <div className={classes.icon}>
              <img
                className={classes.img}
                src={ios ? IOSImage : AndroidImage}
              />
            </div>
          )}
          <div className={classes.heading} onClick={this._handleHeaderClick}>
            <div className={classes.headingText}>{heading}</div>
            <div className={classes.headingIcon}>
              {tooltip &&
                !ios &&
                !android && (
                  <div>
                    <TooltipIcon
                      className={classes.tooltipIcon}
                      color={
                        styles.tooltipIcon[
                          attention ? 'attentionColor' : 'color'
                        ]
                      }
                    />
                    <Popover
                      open={tooltipOpen}
                      anchorEl={tooltipAnchorEl}
                      anchorOrigin={{
                        horizontal: 'middle',
                        vertical: 'center',
                      }}
                      targetOrigin={{
                        horizontal: 'middle',
                        vertical: 'top',
                      }}
                      onRequestClose={this.handleRequestClose}
                      zDepth={5}
                      className={classes.tooltip}
                    >
                      {tooltip}
                    </Popover>
                  </div>
                )}
            </div>
          </div>
          <div className={classes.inner}>
            {primaryText && (
              <div className={classes.primaryText}>
                <Markdown inline text={primaryText} />
              </div>
            )}
            {children}
            {list && (
              <List>
                {list.map(item => (
                  <div key={item}>
                    <Divider />
                    <ListItem
                      key={item}
                      primaryText={<Markdown inline text={item} />}
                    />
                  </div>
                ))}
              </List>
            )}
          </div>
        </Paper>
      );
    }

    _handleHeaderClick = e => {
      this.setState({
        tooltipOpen: true,
        tooltipAnchorEl: e.target,
      });
    };

    handleRequestClose = () => {
      this.setState({ tooltipOpen: false });
    };
  },
);
