//@flow
import { type Saga } from 'redux-saga';
import { put, spawn, takeEvery } from 'redux-saga/effects';
import { callSaga } from '@datatheorem/redux-saga-wrapped-effects';
import { callPromise } from '@datatheorem/session';

import { list } from '@datatheorem/user-api/activity';
import { withProgressIndicator } from '@datatheorem/progress-indicator';
import { activitiesReceived, anErrorOccurred } from '../actions';
import paginate from './util/paginate';
import { ActivityEndpoint } from '../endpoints';
import {
  paginationLoadedInitialAction,
  paginationLoadMoreAction,
  paginationMountedAction,
  paginationBeginRequest,
  paginationEndRequest,
  actionIsForType,
} from '@datatheorem/pagination';

export function* getActivities(): Saga<void> {
  yield* withProgressIndicator(function*(): Saga<void> {
    yield put(paginationBeginRequest(ActivityEndpoint, {}));
    const response = yield* callSaga(paginate, ActivityEndpoint, {}, function*(
      params,
    ) {
      return yield* callPromise(list, params);
    });
    const { activity_log_events, pagination_information } = response;

    if (activity_log_events) {
      yield put(activitiesReceived(activity_log_events));
      yield put(
        paginationEndRequest(
          ActivityEndpoint,
          {},
          pagination_information || {},
        ),
      );
      yield put(paginationLoadedInitialAction(ActivityEndpoint, {}));
    } else {
      yield put(anErrorOccurred('GET activities did not return a result'));
    }
  }, 'activities');
}

export function* watchForGetActivityLog(): Saga<void> {
  yield takeEvery(
    action =>
      (action.type === paginationLoadMoreAction.toString() ||
        action.type === paginationMountedAction.toString()) &&
      actionIsForType(action, ActivityEndpoint),
    getActivities,
  );
}

export function* activityFlow(): Saga<void> {
  yield spawn(watchForGetActivityLog);
}
