//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { type State } from '../../ReduxStateType';
import Changelog from '../theme/Changelog';
import { PageHeading } from '@datatheorem/components';
import { palette } from '@datatheorem/theme';
import { changes, hasNewChanges } from '../../selectors/whatsnew';
import ShowAllIcon from '@material-ui/icons/ViewList';
import { whatsNewResetClicked, whatsNewSeen } from '../../actions';
import type { ChangeSections } from '../../selectors/whatsnew';
import { compose } from 'recompose';
import tracking from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { type DispatchProps } from 'redux';
import { browserHistory } from '../../clients/history';

type StateProps = {|
  seenAt: ?Date,
  hasNewChanges: boolean,
  changes: ChangeSections,
|};
type Props = StateProps & DispatchProps<>;

export default compose(
  // $FlowFixMe Flow85
  connect((state: State, props): StateProps => ({
    seenAt:
      typeof state.strings.whatsNewSeenAt === 'function'
        ? state.strings.whatsNewSeenAt
        : null,
    hasNewChanges: hasNewChanges(state, props),
    changes: changes(state, props),
  })),
  tracking(dataCreators.component(AnalyticsScreenEnum.WHATS_NEW)),
)(
  class WhatsNew extends PureComponent<Props> {
    componentDidMount() {
      this.props.dispatch(whatsNewSeen());
    }

    render() {
      const { changes, hasNewChanges } = this.props;

      const primaryAction = hasNewChanges
        ? {
            label: 'Show All Changes',
            icon: <ShowAllIcon color={palette.brand} />,
            style: { color: palette.brand },
            onClick: this.handleClickShowAll,
          }
        : null;

      return (
        <PageHeading
          backButton
          onClickBack={browserHistory.goBack}
          title="What's New?"
          primaryAction={primaryAction}
        >
          <Changelog changes={changes} />
        </PageHeading>
      );
    }

    handleClickShowAll = () => {
      this.props.dispatch(whatsNewResetClicked());
    };
  },
);
