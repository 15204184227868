//@flow
import React, { type Node } from 'react';
import { fontFamily, fontSizes, palette } from '@datatheorem/theme';

const styles = {
  fontFamily: fontFamily,
  fontSize: fontSizes.huge,
  color: palette.gray20,
  fontWeight: 'normal',
  marginLeft: 20,
};

type Props = {|
  +title: Node,
|};

const PageTitle = ({ title }: Props) => {
  return <h2 style={styles}>{title}</h2>;
};

export default PageTitle;
