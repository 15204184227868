//@flow
import memoize from 'memoizee';
import once from 'lodash/fp/once';
import type { GoogleAPIRequest } from 'gapi';

import { getSessionId } from '@datatheorem/cache';
import config from '@datatheorem/config';

if (typeof config.apiBasePath !== 'string') {
  throw new Error('apiBasePath is required but not set in config');
}

if (typeof config.apiHost !== 'string') {
  throw new Error('apiHost is required but not set in config');
}

const apiHost = typeof config.apiHost === 'string' ? config.apiHost : null;
const apiBasePath =
  typeof config.apiBasePath === 'string' ? config.apiBasePath : null;

const maxAge =
  typeof config.maxCacheAge === 'number' ? config.maxCacheAge : 1000 * 60 * 5; // Five minutes

const initialize = once(async function() {
  return new Promise(resolve => {
    global.gapi.load('client', () => {
      const request = global.gapi.client.request;
      const cached_request = memoize(request, {
        maxAge,
        normalizer: JSON.stringify,
      });
      resolve({ request, cached_request });
    });
  });
});

/**
 * Makes an Google API request. Same options as:
 * https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiclientrequestargs
 * If a non-absolute URL is given, it will prepend the configured api path.
 * @param options Same: https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiclientrequestargs
 * @param enableCache If true,
 * @return {Promise}
 */
export async function request<T>(
  options: GoogleAPIRequest,
  enableCache: boolean = false,
): Promise<APIResponse<T>> {
  const { request, cached_request } = await initialize();

  const opts = { path: '', ...options },
    sessionId = getSessionId();

  // Prepend api root if not provided
  if (opts.path && opts.path.indexOf('http') !== 0 && apiBasePath && apiHost) {
    opts.path = apiHost + apiBasePath + '/' + opts.path;
  }

  // Add Authorization header if we have a session id
  if (typeof sessionId === 'string') {
    opts.headers = {
      ...opts.headers,
      Authorization: `Session ${sessionId}`,
    };
  }

  const requestor = enableCache ? cached_request : request;

  return requestor(opts).then(result => result, err => Promise.reject(err));
}

export type APIResponseBody<T> = T & {
  etag: string,
  kind: string,
  error?: {
    message: string,
  },
};

export type APIResponse<T> = {
  result?: APIResponseBody<T>, // The parsed result returned by GAPI
  body: string, // The raw response body
  headers: { [key: string]: string }, // HTTP response headers
  status: number, // HTTP status code
  statusText?: string, // HTTP status text
};
