//@flow
import { createSelector } from 'reselect';
import { sortObjectsByDateProperty } from '@datatheorem/date';
import type { ActivityLogEvent } from '@datatheorem/user-api/activity';
import type { State } from '../ReduxStateType';

const activities = (state: State) => state.activities;

export const activitiesByDate = createSelector<
  State,
  {},
  $ReadOnlyArray<ActivityLogEvent>,
  _,
>(
  activities,

  activities =>
    activities
      .slice()
      .sort((a, b) => sortObjectsByDateProperty('date_created', a, b)),
);
