// @flow
/* Due to what I think is a bug, eslint is throwing no-unused-vars everywhere
   So I added ignores for all the lines that trigger it */
import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';
import { type SecurityFinding } from '@datatheorem/findings/types';
import type { ActivityLogEvent } from '@datatheorem/user-api/activity'; //eslint-disable-line no-unused-vars
import type { TopTenResponse } from '@datatheorem/user-api/app_protection_top_ten_apps'; //eslint-disable-line no-unused-vars
import type { SDK } from '@datatheorem/user-api/sdks'; //eslint-disable-line no-unused-vars
import type { User } from '@datatheorem/user-api/users';
import type {
  Application,
  TrustKitAnalysis,
  TrustKitReport,
  AppProtectionTask,
  AppProtectionTasksMetadata, //lint-disable-line no-unused-vars
} from '@datatheorem/user-api/mobile_apps';
import type { MobileAppScanCredentialsParams } from '@datatheorem/user-api/mobile_app_scan_credentials';
import type { Note } from '@datatheorem/findings/types';
import type { JiraIntegrationConfigResponse } from '@datatheorem/user-api/jira_config';
import type { APIKey, PatchAPIKeyParams } from '@datatheorem/user-api/api_keys';
import { type UploadAppResponse } from './sagas/apps'; //eslint-disable-line no-unused-vars
import type { InsightFinding, Insights } from '@datatheorem/user-api/insights';
import type { ConfigurationList } from './reducers/jiraIntegration';

/**
 * How to annotate createAction methods: (verbatim from @ez in #javascript)
 * `const myAction = createAction<
 *     'category/action',
 *     [Arg1Type, Arg2Type],
 *     PayloadType
 * >('category/action', (arg1, arg2) => ({ arg1, arg2 }))`
 *
 * Where `PayloadType` can be something like `{ arg1: Arg1Type, arg2: Arg2Type }` in this case.
 * Basically the first generic parameter is the action type, the second is the array of argument types,
 * and the third is the type of the action payload returned by the creator.
 */

export const updateFindings = createAction<
  'SECURITY_FINDINGS_UPDATE',
  [$ReadOnlyArray<SecurityFinding>],
  $ReadOnlyArray<SecurityFinding>,
>('SECURITY_FINDINGS_UPDATE', findings => findings);

export const exportButtonClicked = createAction<
  'EXPORT_BUTTON_CLICKED',
  [?string],
  ?string,
>('EXPORT_BUTTON_CLICKED', (appId: ?string) => appId);

export const activitiesReceived = createAction<
  'ACTIVITIES_RECEIVED',
  [$ReadOnlyArray<ActivityLogEvent>],
  $ReadOnlyArray<ActivityLogEvent>,
>('ACTIVITIES_RECEIVED', activities => activities);

export const sdksReceived = createAction<
  'SDKS_RECEIVED',
  [$ReadOnlyArray<SDK>],
  $ReadOnlyArray<SDK>,
>('SDKS_RECEIVED', sdks => sdks);

export const apiKeyPageMounted = createAction<'API_KEY_PAGE_MOUNTED'>(
  'API_KEY_PAGE_MOUNTED',
);

export const apiKeysReceived = createAction<
  'API_KEYS_RECEIVED',
  [$ReadOnlyArray<APIKey>],
  $ReadOnlyArray<APIKey>,
>('API_KEYS_RECEIVED', (apiKeys: $ReadOnlyArray<APIKey>) => apiKeys);

export const apiKeyClicked = createAction<'API_KEY_CLICKED', [APIKey], APIKey>(
  'API_KEY_CLICKED',
  (apiKey: APIKey) => apiKey,
);

export const apiKeyEditFormSubmitted = createAction<
  'API_KEY_EDIT_FORM_SUBMITTED',
  [PatchAPIKeyParams & { id: string }],
  PatchAPIKeyParams & { id: string },
>(
  'API_KEY_EDIT_FORM_SUBMITTED',
  (apiKey: PatchAPIKeyParams & { id: string }) => apiKey,
);

export const apiKeyDeleteButtonClicked = createAction<
  'API_KEY_DELETE_BUTTON_CLICKED',
  [APIKey],
  APIKey,
>('API_KEY_DELETE_BUTTON_CLICKED', (apiKey: APIKey) => apiKey);

export const createAPIKeyButtonClicked = createAction<
  'CREATE_API_KEY_BUTTON_CLICKED',
>('CREATE_API_KEY_BUTTON_CLICKED');

export const apiKeyCreateFormSubmitted = createAction<
  'API_KEY_CREATE_FORM_SUBMITTED',
  [APIKey],
  APIKey,
>('API_KEY_CREATE_FORM_SUBMITTED', (apiKey: APIKey) => apiKey);

export const apiKeyEditResponseReceived = createAction<
  'API_KEY_EDIT_RESPONSE_RECEIVED',
  [APIKey],
  APIKey,
>('API_KEY_EDIT_RESPONSE_RECEIVED', (apiKey: APIKey) => apiKey);

export const apiKeyCreateResponseReceived = createAction<
  'API_KEY_CREATE_RESPONSE_RECEIVED',
  [APIKey],
  APIKey,
>('API_KEY_CREATE_RESPONSE_RECEIVED', (apiKey: APIKey) => apiKey);

export const apiKeyDeleteResponseReceived = createAction<
  'API_KEY_DELETE_RESPONSE_RECEIVED',
  [APIKey],
  APIKey,
>('API_KEY_DELETE_RESPONSE_RECEIVED', (apiKey: APIKey) => apiKey);

export const apiKeyErrorReceived = createAction<
  'API_KEY_ERROR_RECEIVED',
  [mixed],
  mixed,
>('API_KEY_ERROR_RECEIVED', (error: mixed) => error);

export const userEditFormSubmitted = createAction<
  'USER_EDIT_FORM_SUBMITTED',
  [User],
  User,
>('USER_EDIT_FORM_SUBMITTED', (user: User) => user);

export const userEditReceived = createAction<
  'USER_EDIT_RESPONSE_RECEIVED',
  [User],
  User,
>('USER_EDIT_RESPONSE_RECEIVED', (user: User) => user);

type ErrorPayload = { _error: string }; //eslint-disable-line no-unused-vars
export const userEditError = createAction<
  'USER_EDIT_ERROR',
  [ErrorPayload],
  ErrorPayload,
>('USER_EDIT_ERROR', error => error);

export const userInviteFormSubmitted = createAction<
  'USER_INVITE_FORM_SUBMITTED',
  [User],
  User,
>('USER_INVITE_FORM_SUBMITTED', (user: User) => user);

export const userInvitedReceived = createAction<
  'USER_INVITE_RESPONSE_RECEIVED',
  [User],
  User,
>('USER_INVITE_RESPONSE_RECEIVED', (user: User) => user);

export const userInvitedError = createAction<
  'USER_INVITE_ERROR',
  [ErrorPayload],
  ErrorPayload,
>('USER_INVITE_ERROR', error => error);

export const usersReceived = createAction<
  'USERS_RECEIVED',
  [$ReadOnlyArray<User>],
  $ReadOnlyArray<User>,
>('USERS_RECEIVED', (users: $ReadOnlyArray<User>) => users);

export const inviteUserClicked = createAction<'USER_INVITE_CLICKED'>(
  'USER_INVITE_CLICKED',
);

export const trustkitReportsReceived = createAction<
  'TRUSTKIT_REPORTS_RECEIVED',
  [$ReadOnlyArray<TrustKitReport>],
  $ReadOnlyArray<TrustKitReport>,
>(
  'TRUSTKIT_REPORTS_RECEIVED',
  (reports: $ReadOnlyArray<TrustKitReport>) => reports,
);

export const trustkitAnalysisReceived = createAction<
  'TRUSTKIT_ANALYSIS_RECEIVED',
  [TrustKitAnalysis],
  TrustKitAnalysis,
>('TRUSTKIT_ANALYSIS_RECEIVED', (analysis: TrustKitAnalysis) => analysis);

export const mobileAppTrustKit = createRoutine<
  [string],
  { mobile_app_id: string },
>('MOBILE_APP_TRUSTKIT', (mobile_app_id: string) => ({ mobile_app_id }));

export const trustKitCardClicked = createAction<
  'TRUSTKIT_CARD_CLICKED',
  [TrustKitReport, Application],
  { report: TrustKitReport, app: Application },
>('TRUSTKIT_CARD_CLICKED', (report, app) => ({
  report,
  app,
}));

export const trustKitDialogCloseRequest = createAction<
  'TRUSTKIT_DIALOG_CLOSE_REQUESTED',
  [string],
  { appId: string },
>('TRUSTKIT_DIALOG_CLOSE_REQUESTED', (appId: string) => ({ appId }));

type SortParams = ?{ +[string]: void | string };
export const sortUtilButtonClicked = createAction<
  'SORT_UTIL_BUTTON_CLICKED',
  [string, SortParams],
  { sortString: string, params: SortParams },
>('SORT_UTIL_BUTTON_CLICKED', (sortString: string, params) => ({
  sortString,
  params,
}));

export const complianceFilterClicked = createAction<
  'COMPLIANCE_FILTER_CLICKED',
  [$ReadOnlyArray<string>],
  { compliancePolicies: $ReadOnlyArray<string> },
>(
  'COMPLIANCE_FILTER_CLICKED',
  (compliancePolicies: $ReadOnlyArray<string>) => ({
    compliancePolicies,
  }),
);

export const uploadButtonClicked = createAction<'UPLOAD_BUTTON_CLICKED'>(
  'UPLOAD_BUTTON_CLICKED',
);
export const appFileSubmitted = createAction<
  'APP_FILE_SUBMITTED',
  [string, string, string, string],
  { file: string, username: string, password: string, comments: string },
>('APP_FILE_SUBMITTED', (file, username, password, comments) => ({
  file,
  username,
  password,
  comments,
}));

export const appFileAccepted = createAction<
  'APP_FILE_ACCEPTED',
  [UploadAppResponse],
  UploadAppResponse,
>('APP_FILE_ACCEPTED', response => response);

export const appFileRejected = createAction<
  'APP_FILE_REJECTED',
  [string],
  string,
>('APP_FILE_REJECTED', error => error);

export const appXCUITestFileSubmit = createAction<
  'APP_XCUITEST_FILE_SUBMIT',
  [string, string, string, string],
  { file: string, bundle_id: string, release_type: string, version: string },
>('APP_XCUITEST_FILE_SUBMIT', (file, bundle_id, release_type, version) => ({
  file,
  bundle_id,
  release_type,
  version,
}));

export const appXCUITestFileAccepted = createAction<
  'APP_XCUITEST_FILE_ACCEPTED',
  [Response],
  Response,
>('APP_XCUITEST_FILE_ACCEPTED', response => response);

export const appXCUITestFileRejected = createAction<
  'APP_XCUITEST_FILE_REJECTED',
>('APP_XCUITEST_FILE_REJECTED');

export const sdlcInfoMounted = createAction<'SDLC_INFO_MOUNTED'>(
  'SDLC_INFO_MOUNTED',
);

export const sdlcAPIKeysPanelClicked = createAction<
  'SDLC_API_KEYS_PANEL_CLICKED',
>('SDLC_API_KEYS_PANEL_CLICKED');

export const samlEmailSubmitted = createAction<
  'SAML_USERNAME_SUBMITTED',
  [string],
  { email: string },
>('SAML_USERNAME_SUBMITTED', (email: string) => ({ email }));

export const lastPageReceived = createAction<
  'LAST_PAGE_RECEIVED',
  [string, void | { +[string]: ?string }],
  { type: string, params: void | { +[string]: ?string } },
>('LAST_PAGE_RECEIVED', (type, params) => ({
  type,
  params,
}));

export const bundleUpdated = createAction<'BUNDLE_UPDATED'>('BUNDLE_UPDATED');

export const anErrorOccurred = createAction<
  'UNKNOWN_ERROR_OCCURRED',
  [string],
  { code: string },
>('UNKNOWN_ERROR_OCCURRED', code => ({
  code,
}));

export const dismissError = createAction<'DISMISS_ERROR'>('DISMISS_ERROR');

export const startedLoadingApps = createAction<'STARTED_LOADING_APPS'>(
  'STARTED_LOADING_APPS',
);

export const finishedLoadingApps = createAction<'FINISHED_LOADING_APPS'>(
  'FINISHED_LOADING_APPS',
);

export const receivedAppsError = createAction<'RECEIVED_APPS_ERROR'>(
  'RECEIVED_APPS_ERROR',
);

export const appScanCredentialsUpdateRequest = createAction<
  'APP_SCAN_CREDENTIALS_UPDATE_REQUEST',
  [MobileAppScanCredentialsParams],
  MobileAppScanCredentialsParams,
>(
  'APP_SCAN_CREDENTIALS_UPDATE_REQUEST',
  (appScansCredentialParams: MobileAppScanCredentialsParams) =>
    appScansCredentialParams,
);

export const appScanCredentialsUpdated = createAction<
  'APP_SCAN_CREDENTIALS_UPDATED',
>('APP_SCAN_CREDENTIALS_UPDATED');

export const uploadBinaryClicked = createAction<'UPLOAD_BINARY_CLICKED'>(
  'UPLOAD_BINARY_CLICKED',
);

export const addCommentClicked = createAction<
  'ADD_COMMENT_CLICKED',
  [string, SecurityFinding],
  { comment: string, finding: SecurityFinding },
>('ADD_COMMENT_CLICKED', (comment, finding) => ({
  comment,
  finding,
}));

export const addCommentRequestSucceeded = createAction<
  'ADD_COMMENT_REQUEST_SUCCEEDED',
  [Note, SecurityFinding],
  { comment: Note, finding: SecurityFinding },
>('ADD_COMMENT_REQUEST_SUCCEEDED', (comment, finding) => ({
  comment,
  finding,
}));

export const addCommentRequestFailed = createAction<
  'ADD_COMMENT_REQUEST_FAILED',
>('ADD_COMMENT_REQUEST_FAILED');

export const userDeleteClicked = createAction<
  'USER_DELETE_CLICKED',
  [User],
  User,
>('USER_DELETE_CLICKED', (user: User) => user);

export const userDeleteSuccess = createAction<
  'USER_DELETE_SUCCESS_RECEIVED',
  [User],
  User,
>('USER_DELETE_SUCCESS_RECEIVED', (user: User) => user);

export const userDeleteError = createAction<'USER_DELETE_ERROR', [User], User>(
  'USER_DELETE_ERROR',
  error => error,
);

export const whatsNewSeen = createAction<'WHATS_NEW_SEEN'>('WHATS_NEW_SEEN');

export const whatsNewUpdate = createAction<
  'WHATS_NEW_UPDATE',
  [string],
  string,
>('WHATS_NEW_UPDATE', date => date);

export const whatsNewResetClicked = createAction<'WHATS_NEW_RESET_CLICKED'>(
  'WHATS_NEW_RESET_CLICKED',
);

export const appProtectionMounted = createAction<
  'PROTECTION/APP_MOUNTED',
  [Application],
  Application,
>('PROTECTION/APP_MOUNTED', app => app);

export const topAppsReceived = createAction<
  'PROTECTION/TOP_APPS_RECEIVED',
  [TopTenResponse],
  TopTenResponse,
>('PROTECTION/TOP_APPS_RECEIVED', topApps => topApps);

export const topAppsByCategoryReceived = createAction<
  'PROTECTION/TOP_APPS_BY_CATEGORY_RECEIVED',
  [any],
  any,
>(
  'PROTECTION/TOP_APPS_BY_CATEGORY_RECEIVED',
  topAppsByCategory => topAppsByCategory,
);

export const appProtectionTasksReceived = createAction<
  'PROTECTION/TASKS_RECEIVED',
  [$ReadOnlyArray<AppProtectionTask>],
  $ReadOnlyArray<AppProtectionTask>,
>('PROTECTION/TASKS_RECEIVED', tasks => tasks);

export const appProtectionTasksMetadataReceived = createAction<
  'PROTECTION/TASKS_METADATA_RECEIVED',
  [AppProtectionTasksMetadata],
  AppProtectionTasksMetadata,
>('PROTECTION/TASKS_METADATA_RECEIVED', tasksMetadata => tasksMetadata);

export const appProtectionInquireClicked = createAction<
  'APP_PROTECTION_INQUIRE_CLICKED',
  [{ app: Application }],
  { app: Application },
>('APP_PROTECTION_INQUIRE_CLICKED', (params: { app: Application }) => params);

export const appIntegrationInquireClicked = createAction<
  'APP_INTEGRATION_INQUIRE_CLICKED',
  [{ app: Application, panel: string }],
  { app: Application, panel: string },
>(
  'APP_INTEGRATION_INQUIRE_CLICKED',
  (params: { app: Application, panel: string }) => params,
);

export const jiraIntegrationConfigListViewPageMounted = createAction<
  'JIRA_INTEGRATION_CONFIG_LIST_VIEW_PAGE_MOUNTED',
>('JIRA_INTEGRATION_CONFIG_LIST_VIEW_PAGE_MOUNTED');

export const jiraIntegrationConfigListViewClicked = createAction<
  'JIRA_INTEGRATION_CONFIG_LIST_VIEW_CLICKED',
>('JIRA_INTEGRATION_CONFIG_LIST_VIEW_CLICKED');

export const jiraIntegrationConfigPageMounted = createAction<
  'JIRA_CONFIGS_PAGE_MOUNTED',
  [number],
  number,
>('JIRA_CONFIGS_PAGE_MOUNTED', (jiraConfigId: number) => jiraConfigId);

type JiraIntegrationConfigChangesAndID = {
  jiraIntegrationConfigChanges: $Shape<JiraIntegrationConfigResponse>[],
  id: string,
};

export const jiraIntegrationConfigCreateFormSubmitted = createAction<
  'JIRA_CONFIG_CREATE_FORM_SUBMITTED',
  [JiraIntegrationConfigChangesAndID],
  JiraIntegrationConfigChangesAndID,
>(
  'JIRA_CONFIG_CREATE_FORM_SUBMITTED',
  (jiraIntegrationConfigAndID: JiraIntegrationConfigChangesAndID) =>
    jiraIntegrationConfigAndID,
);

export const jiraIntegrationConfigPatchFormSubmitted = createAction<
  'JIRA_CONFIG_PATCH_FORM_SUBMITTED',
  [JiraIntegrationConfigChangesAndID],
  JiraIntegrationConfigChangesAndID,
>(
  'JIRA_CONFIG_PATCH_FORM_SUBMITTED',
  (jiraIntegrationConfigAndID: JiraIntegrationConfigChangesAndID) =>
    jiraIntegrationConfigAndID,
);

export const jiraIntegrationConfigListReceived = createAction<
  'JIRA_CONFIG_LIST_RECEIVED',
  [$ReadOnlyArray<JiraIntegrationConfigResponse>],
  $ReadOnlyArray<JiraIntegrationConfigResponse>,
>('JIRA_CONFIG_LIST_RECEIVED', jiraConfigs => jiraConfigs);

export const jiraIntegrationConfigureClick = createAction<
  'JIRA_INTEGRATION_CONFIGURE_CLICK',
  [string],
  string,
>('JIRA_INTEGRATION_CONFIGURE_CLICK', appId => appId);

export const jiraIntegrationConfigureGlobalClick = createAction<
  'JIRA_INTEGRATION_CONFIGURE_GLOBAL_CLICK',
>('JIRA_INTEGRATION_CONFIGURE_GLOBAL_CLICK');

export const appProtectionHideTaskClicked = createAction<
  'APP_PROTECTION_HIDE_TASK_CLICKED',
  [AppProtectionTask],
  AppProtectionTask,
>('APP_PROTECTION_HIDE_TASK_CLICKED', task => task);

export const appProtectionUnHideTaskClicked = createAction<
  'APP_PROTECTION_UNHIDE_TASK_CLICKED',
  [AppProtectionTask],
  AppProtectionTask,
>('APP_PROTECTION_UNHIDE_TASK_CLICKED', task => task);

export const getAppInsights = createAction<
  'GET_APP_INSIGHTS',
  [?string],
  ?string,
>('GET_APP_INSIGHTS', appId => appId);

export const appInsightsReceived = createAction<
  'APP_INSIGHTS_RECEIVED',
  [Insights],
  Insights,
>('APP_INSIGHTS_RECEIVED', insights => insights);

export const updateInsightFindings = createAction<
  'INSIGHT_FINDINGS_UPDATE',
  [$ReadOnlyArray<InsightFinding>],
  $ReadOnlyArray<InsightFinding>,
>('INSIGHT_FINDINGS_UPDATE', findings => findings);

export const mobileApplicationViewed = createAction<
  'mobile_apps/viewed',
  [Application],
  Application,
>('mobile_apps/viewed', Application => Application);

export const samlSetupPageClicked = createAction<'SAML_SETUP_PAGE_CLICKED'>(
  'SAML_SETUP_PAGE_CLICKED',
);

export const apiAccessPageClicked = createAction<'API_ACCESS_PAGE_CLICKED'>(
  'API_ACCESS_PAGE_CLICKED',
);

export const jiraIntegrationConfigurationsReceived = createAction<
  'jira/integration/configuration-received',
  [ConfigurationList],
  ConfigurationList,
>('jira/integration/configuration-received', configurations => configurations);
