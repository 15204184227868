//@flow
import { call, put, spawn, take, takeEvery } from 'redux-saga/effects';
import { get } from '@datatheorem/user-api/current_user';
import { callPromise } from '@datatheorem/session';
import { createAction } from 'redux-actions';
import { withProgressIndicator } from '@datatheorem/progress-indicator';
import { setProfile } from '../reducers/profile';
import { manager } from '@datatheorem/user-api/account_info';
import { setString } from '../reducers/strings';
import { browserHistory } from '../clients/history';
import {} from '../actions';
import {
  anErrorOccurred,
  sdlcInfoMounted,
  samlSetupPageClicked,
} from '../actions';
import { type Saga } from 'redux-saga';
import { MANAGER_GET_REQUEST_NAME } from '../strings';

export function* getProfile(): Saga<void> {
  yield* withProgressIndicator(function*() {
    const profile = yield* callPromise(get);
    if (profile) {
      yield put(setProfile(profile));
    } else {
      yield put(anErrorOccurred('GET profile did not respond'));
    }
  });
}

export function* getSAML(): Saga<void> {
  yield* withProgressIndicator(function*() {
    const saml_info = yield* callPromise(manager.get);

    if (saml_info && saml_info.saml_sp_metadata) {
      yield put(setString('has_saml_idp', 'true'));

      if (saml_info.saml_sp_metadata && saml_info.saml_sp_metadata.entity_id) {
        yield put(
          setString(
            'saml_sp_metadata.entity_id',
            saml_info.saml_sp_metadata.entity_id,
          ),
        );
      }

      if (
        saml_info.saml_sp_metadata &&
        saml_info.saml_sp_metadata.metadata_file_url
      ) {
        yield put(
          setString(
            'saml_sp_metadata.metadata_file_url',
            saml_info.saml_sp_metadata.metadata_file_url,
          ),
        );
      }

      if (
        saml_info.saml_sp_metadata &&
        saml_info.saml_sp_metadata.assertion_consumer_service_url
      ) {
        yield put(
          setString(
            'saml_sp_metadata.assertion_consumer_service_url',
            saml_info.saml_sp_metadata.assertion_consumer_service_url,
          ),
        );
      }
    }
  }, MANAGER_GET_REQUEST_NAME);
}

export function* watchForSamlPageClicked(): Saga<void> {
  yield takeEvery(samlSetupPageClicked.toString(), function*(): Saga<void> {
    yield call(browserHistory.push, `/sdlc/saml/`);
  });
}

export function* watchForGetCurrentProfile(): Saga<void> {
  yield take([
    getCurrentProfile.toString(),
    securityFindingLightboxOpened.toString(),
  ]);
  yield* getProfile();
}

export function* watchForGetSAMLInfo(): Saga<void> {
  yield take(sdlcInfoMounted.toString());
  yield call(getSAML);
}

export function* profileFlow(): Saga<void> {
  yield spawn(watchForGetCurrentProfile);
  yield spawn(watchForGetSAMLInfo);
  yield spawn(watchForSamlPageClicked);
}

export const getCurrentProfile = createAction<'GET_CURRENT_PROFILE'>(
  'GET_CURRENT_PROFILE',
);

export const securityFindingLightboxOpened = createAction<
  'SECURITY_FINDING_LIGHTBOX_OPENED',
  _,
  _,
>('SECURITY_FINDING_LIGHTBOX_OPENED');
