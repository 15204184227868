//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

import { type MetadataCount } from '@datatheorem/user-api/mobile_apps';

import FindingsBadge from './FindingsBadge';
import FindingsBadgeType from './FindingsBadgeTypeEnum';
import { type FindingsBadgeTypeEnum } from './FindingsBadgeTypeEnum';

const styles = {
  container: {
    display: 'flex',
    justifyContent: ({ justify }: Props) => justify,
  },

  badge: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    marginLeft: 5,
  },
};

type Props = {
  noLabel: boolean,
  justify: 'space-around' | 'space-between',
  badgeTypes: Array<FindingsBadgeTypeEnum>,
  openMetadataCount: ?MetadataCount,
} & InjectedJSS<typeof styles>;

class FindingStats extends PureComponent<Props> {
  static defaultProps = {
    noLabel: false,
    justify: 'space-between',
    badgeTypes: [FindingsBadgeType.P1],
  };

  getCountForBadgeType = (
    type: FindingsBadgeTypeEnum,
    openMetadataCount: MetadataCount,
  ): number => {
    switch (type) {
      case FindingsBadgeType.P1:
        return openMetadataCount.P1;
      case FindingsBadgeType.BLOCKER:
        return (
          openMetadataCount.APPLE_BLOCKER + openMetadataCount.GOOGLE_BLOCKER
        );
      case FindingsBadgeType.COMPLIANCE:
        return openMetadataCount.COMPLIANCE;
      case FindingsBadgeType.INSIGHT:
        return openMetadataCount.INSIGHT;
    }

    throw new Error(`${type} not supported by FindingStats.`);
  };

  render() {
    const { props, getCountForBadgeType } = this;
    const { classes, openMetadataCount, badgeTypes, noLabel } = props;
    return (
      <div className={classes.container}>
        {openMetadataCount ? (
          badgeTypes
            .map(type => [type, getCountForBadgeType(type, openMetadataCount)])
            .map(([type, count]) => (
              <div key={type} className={classes.badge}>
                <FindingsBadge
                  noLabel={noLabel}
                  type={FindingsBadgeType[type]}
                  count={count}
                />
              </div>
            ))
        ) : (
          <div>Stats not currently available.</div>
        )}
      </div>
    );
  }
}

export default jss(styles)(FindingStats);
