//@flow
import React, { PureComponent } from 'react';

import {
  CardWithTargets,
  CardStatusLineForTarget,
} from '@datatheorem/components';
import { sortTargets } from '@datatheorem/findings/targets/status';
import type { SDKFinding } from '@datatheorem/user-api/sdk_findings';
import type { Application } from '@datatheorem/user-api/mobile_apps';
import CardGroupTitle from '../theme/CardGroupTitle';

type Props = {
  finding: SDKFinding,
  apps?: $ReadOnlyArray<Application>,
};

export default class SDKFindingCard extends PureComponent<Props> {
  render() {
    const { finding, apps } = this.props;
    const { title, description, mobile_app_id, logo_url, sdk_issues } = finding;
    const app = apps && apps.find(app => app.id === mobile_app_id);

    return (
      <CardWithTargets
        text={[title, null, description, app && app.name]}
        logo={logo_url}
      >
        {sdk_issues &&
          sdk_issues.map(
            ({ security_finding: { id, title, targets }, target_ids }) => (
              <div key={id + target_ids.join('')}>
                <CardGroupTitle>Issue: {title}</CardGroupTitle>
                {target_ids
                  .map(
                    target_id =>
                      targets &&
                      targets.find(target => target.id === target_id),
                  )
                  .filter(Boolean)
                  .sort(sortTargets)
                  .map(target => (
                    <CardStatusLineForTarget key={target.id} target={target} />
                  ))}
              </div>
            ),
          )}
      </CardWithTargets>
    );
  }
}
