//@flow
export default `
Overview
--------
Data Theorem directly integrates with the JIRA bug tracking system, where tickets can be created and assigned automatically to any development team. The following items are included in the Data Theorem JIRA ticket:

- App Name
- Type of release (pre-prod or production)
- Type of Severity (High, Medium, Low)
- Platform (Android, iOS, WinMo)
- Issue Description
- Recommendation
- Sample Code
- Issue Url to Data Theorem Portal
- Custom Fields (Customer Created)

A sample developer workflow is below:

1. Data Theorem creates a JIRA ticket
2. Developer fixes the issue and submits a new build to pre-prod or prod
3. Data Theorem scans the latest build, marks issues as Closed/Fixed and syncs the data to the existing JIRA ticket. __Please note: Data Theorem will not close issues in the customers JIRA systems, but provide comments within existing tickets.__

Requirements
-----------
To be able to integrate Data Theorem issues into your company's Jira System,
we will need the following information:

- Company Name
- The URL of your Jira instance (ex: https://yourdomain.atlassian.net)
- Type of Jira Instance ("Jira Cloud" or "Jira Server")
    * If "Jira Server", provide the version of the Jira software
- Dedicated Data Theorem Account:
    * Within that account, you will need the username and an API Token for the account.
        Instructions for creating an API Token can be found [here](https://confluence.atlassian.com/cloud/api-tokens-938839638.html?_ga=2.77675156.1411760106.1554771358-1563265542.1554771358).
    * The account will need the ability to create tickets and add comments
    * It is *strongly* advised to restrict the account's access to the specific Jira project
    that should be used for the integration

- The Jira Project Key or Id under which the tickets should be created
- The type of issue name to be used for each Jira ticket
    * (ex: one of Jira’s options is  “Story”)
- Should Data Theorem export prod issues? (yes/no)
- Should Data Theorem export pre-prod issues? (yes/no)
- Should Data Theorem export Security P1's and App Store Blockers or export all issues?
- Priority field id
    * This can also be a custom field that customer defines
- External Link field id
    * We need this to add a link to the Data Theorem Portal
- Optionally, to customize the Jira tickets:
    * IDs of custom fields which will link the Issue URL from Data Theorem’s portal
    * Any other custom field ID and the value it should have

Deployment
----------

After completing the web form in the previous section, the Jira integration can be deployed. There are two options:

### Cloud

Data Theorem can deploy and host the Jira integration for you; this setup requires your Jira instance to be accessible from the Internet.

### Self-hosted

This deployment is useful for Jira Server instances that are not accessible from the Internet. Self-hosting is done using a Docker image pre-configured with Data Theorem's Jira integration code, which does the following:

1. Every day, pull new results from the Data Theorem API
2. Push any changes (new ticket, closed ticket, etc.) to your Jira instance

If you'd like to self-host the Docker image, please follow the [instructions here](https://bitbucket.org/datatheorem/dt-jira-docker/src).

Demo
----
The following video shows how tickets will look:
`;
