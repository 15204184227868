//@flow
import { object, string, boolean, array, type YupObject } from 'yup';
import { request } from './gapi';
import { result } from './util';
import type { PaginatedResponse } from './_common';

export type APIKey = {
  id: string,
  key: string,
  name: string,
  date_created: string,
  date_updated: string,
  results_api?: {
    access: boolean,
    can_access_all_apps: boolean,
    can_access_app_ids?: $ReadOnlyArray<string>,
  },
  management_api?: {
    access: boolean,
  },
};

const resultsAPISchema = object().shape({
  access: boolean()
    .label('Results API Access')
    .required(),
  can_access_all_apps: boolean()
    .label('Can Access All Apps')
    .required(),
  can_access_app_ids: array()
    .of(string())
    .ensure(),
});

const managementAPISchema = object().shape({
  access: boolean()
    .label('Management API Access')
    .required(),
});

export const APIKeySchema: YupObject<APIKey> = object().shape({
  name: string()
    .label('API Key Name')
    .required(),
  results_api: resultsAPISchema.nullable().default(() => ({
    access: false,
    can_access_all_apps: false,
    can_access_app_ids: [],
  })),
  management_api: managementAPISchema.nullable().default(() => ({
    access: false,
  })),
});

export type APIKeysListResponse = PaginatedResponse<{
  api_keys: $ReadOnlyArray<APIKey>,
  upload_api_key: string,
}>;

type ResultsAPIPatchParams = {
  access: boolean,
  can_access_all_apps: boolean,
  can_access_app_ids: Array<string>,
};

export type PatchAPIKeyParams = {
  name?: string,
  results_api?: ResultsAPIPatchParams,
  management_api?: {
    access: boolean,
  },
};

export type CreateAPIKeyParams = {
  name: string,
  results_api?: ResultsAPIPatchParams,
  management_api?: {
    access: boolean,
  },
};

export const api_keys = {
  async list(params: { +cursor?: ?string }): Promise<APIKeysListResponse> {
    let fullResponse: APIKeysListResponse;
    return request({
      path: 'userapi/v2/api_keys',
      params,
      method: 'GET',
    })
      .then(result)
      .then(response => {
        /* we're only validating the inner APIKey entities within the response, so we need to keep a ref to the full
         response, for the final Promise resolve
      */
        fullResponse = response;
        return array()
          .of(APIKeySchema)
          .validate(response.api_keys);
      })
      .then(() => Promise.resolve(fullResponse));
  },
  async patch(id: string, params: PatchAPIKeyParams): Promise<APIKey> {
    return request({
      path: `userapi/v2/api_keys/${id}`,
      method: 'PATCH',
      body: params,
    })
      .then(result)
      .then(response => APIKeySchema.validate(response));
  },
  async delete(id: string) {
    request({
      path: `userapi/v2/api_keys/${id}`,
      method: 'DELETE',
    });
  },
  async create(params: CreateAPIKeyParams): Promise<APIKey> {
    return request({
      path: 'userapi/v2/api_keys',
      method: 'POST',
      body: params,
    })
      .then(result)
      .then(response => APIKeySchema.validate(response));
  },
};
