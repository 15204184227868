// @flow
import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';

import { isOpenStatus } from '@datatheorem/findings/targets/status';

import type { ShadowscanSecurityFinding } from '@datatheorem/user-api/shadow_security_findings';
import type { ShadowscanState } from './../shadow-scan/reducers';
import type { RouterHistory } from 'react-router-dom';

import findingsFilterCreator from './../findingsFilterCreator';
import {
  getShowHideClosedFilter,
  getComplianceSearchFilter,
} from './../findingsFilterCreator';
import { search } from './../search';

type ShadowscanFindings = $ReadOnlyArray<ShadowscanSecurityFinding>;

type State = {
  shadowscan: ShadowscanState,
};

export const findings = (state: State): ShadowscanFindings => state.shadowscan;

const findingsSelector = createSelector(
  findings,

  (findings): ShadowscanFindings =>
    findings.filter(finding => isOpenStatus(finding.aggregated_status)),
);

const findingsByApp = createSelector(
  findingsSelector,

  (findings: ShadowscanFindings): { [string]: void | ShadowscanFindings } =>
    groupBy(findings, finding => finding.mobile_app_id),
);

export const createShadowscanFindingsByAppIdParamSelector = (
  browserHistory: RouterHistory,
) =>
  createSelector<
    State,
    {
      +match: { +params: { [string]: string | void } },
      +location?: { +search?: ?string },
    },
    ShadowscanFindings,
    _,
    _,
    _,
  >(
    findingsByApp,
    (state, props) => props.match && props.match.params.appId,
    search,

    (findings, appId, search): ShadowscanFindings =>
      findings && appId && findings[appId]
        ? findings[appId].filter(
            findingsFilterCreator({
              ...search,
              showClosed: getShowHideClosedFilter(browserHistory),
              compliancePolicy: getComplianceSearchFilter(browserHistory),
            }),
          )
        : [],
  );

export const createFindingsFromCategorySelector = (
  browserHistory: RouterHistory,
) =>
  createSelector<
    State,
    {
      +match: { +params: { [string]: string | void } },
      +location?: { +search?: ?string },
    },
    $ReadOnlyArray<*>,
    _,
    _,
    _,
  >(
    findingsSelector,
    (state, props) => props.match && props.match.params.category,
    search,

    (findings, category, search): $ReadOnlyArray<*> =>
      findings
        ? findings.filter(
            findingsFilterCreator({
              ...search,
              ...(category ? { category } : {}),
              showClosed: getShowHideClosedFilter(browserHistory),
              compliancePolicy: getComplianceSearchFilter(browserHistory),
            }),
          )
        : ([]: $ReadOnlyArray<*>),
  );
