//@flow
import React, { type ElementRef, useRef, useCallback, useEffect } from 'react';
import { browserHistory } from '../../clients/history';
import { setShowHideClosedFilter } from '@datatheorem/findings/findingsFilterCreator';
import SecurityFindings, {
  type RowRenderPropsWithCoda,
} from '../findings/SecurityFindings';
import { type SecurityFinding } from '@datatheorem/findings/types';
import { type Application } from '@datatheorem/user-api/mobile_apps';
import { Paginate } from '@datatheorem/pagination';
import SecurityFindingDialogManager from '../main/SecurityFindingDialogManager';
import { exportButtonClicked, sortUtilButtonClicked } from '../../actions';
import {
  inquireShadowScanFindings,
  type InquireShadowScanFindingsActionParams,
} from './../../actions/shadowScanFindings';
import { SecurityFindingEndpoint } from '../../endpoints';
import {
  ShadowScanSummaryCard,
  ShadowScanPreviewFindingCardList,
  MeasureableHeightDiv,
} from '@datatheorem/components';
import ListCardSpacer from '../theme/ListCardSpacer';
import type { ShadowscanSecurityFinding } from '@datatheorem/user-api/shadow_security_findings';
import { type User } from '@datatheorem/user-api/users';

type Props = {|
  findings: $ReadOnlyArray<SecurityFinding>,
  findingsCount: number | void,
  filterValue: boolean,
  shadowscanFindings: $ReadOnlyArray<ShadowscanSecurityFinding>,
  app: Application,
  subscriptionsBeyondApp: $ReadOnlyArray<string>,
  onClickExport: typeof exportButtonClicked,
  onClickSort: typeof sortUtilButtonClicked,
  onClickShadowScanInquire: typeof inquireShadowScanFindings,
  onChangeShowHideClosed: (value: boolean) => mixed,
  currentUser: User,
|};

export default function MobileApplicationIssues({
  app,
  findings,
  findingsCount,
  shadowscanFindings,
  subscriptionsBeyondApp,
  filterValue,
  onClickShadowScanInquire,
  onClickSort,
  onClickExport,
  onChangeShowHideClosed,
  currentUser,
}: Props) {
  const shadowscanSummaryCards = useRef<
    Map<number, ElementRef<typeof MeasureableHeightDiv>>,
  >(new Map());

  const handleShadowScanInquireClick = useCallback(
    (params: InquireShadowScanFindingsActionParams) => {
      onClickShadowScanInquire({
        ...params,
        app,
      });
    },
    [app, onClickShadowScanInquire],
  );

  const handleSortClick = useCallback(
    (sortString: string) => {
      // @ez: flow puzzle number 1
      // instructions: remove maybe types and solve
      const paginationParams: ?{ +mobile_app_id: void | string } = {
        mobile_app_id: app.id,
      };

      onClickSort(sortString, paginationParams);
    },
    [app, onClickSort],
  );

  const handleCloseDialog = useCallback(() => {
    browserHistory.push(`/app/${app.id}/issues/`);
    setShowHideClosedFilter(browserHistory, true);
  }, [app]);

  useEffect(
    () => {
      // TODO: MEGA HACK
      setShowHideClosedFilter(browserHistory, true);
    },
    // This needs to happen whenever the app changes... that's why
    // it says it's a hack!
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [app],
  );

  const renderCoda = useCallback(
    ({ key, style, codaIndex }: RowRenderPropsWithCoda) => {
      const sub = subscriptionsBeyondApp[codaIndex];
      const filteredShadowScanFindings = shadowscanFindings.filter(
        finding => finding.required_subscription === sub,
      );
      return (
        <div style={style} key={key}>
          <MeasureableHeightDiv
            ref={node =>
              node && shadowscanSummaryCards.current.set(codaIndex, node)
            }
          >
            <ListCardSpacer>
              <ShadowScanSummaryCard
                type={sub}
                overrideCount={filteredShadowScanFindings.length}
                onClickInquire={handleShadowScanInquireClick}
              >
                {filteredShadowScanFindings &&
                filteredShadowScanFindings.length > 0 ? (
                  <ShadowScanPreviewFindingCardList
                    findings={filteredShadowScanFindings}
                  />
                ) : (
                  false
                )}
              </ShadowScanSummaryCard>
            </ListCardSpacer>
          </MeasureableHeightDiv>
        </div>
      );
    },
    [shadowscanFindings, subscriptionsBeyondApp, handleShadowScanInquireClick],
  );

  const shadowscanRowHeight = useCallback(
    ({ codaIndex }: { +codaIndex: number }) => {
      const node = shadowscanSummaryCards.current.get(codaIndex);
      if (node) {
        const { height } = node.measure();
        return height;
      } else {
        return 600;
      }
    },
    [],
  );

  const handleSelectFinding = useCallback(
    (finding: { +id: string }) => {
      browserHistory.push(
        `/app/${app.id}/issues/${finding.id}`,
        browserHistory.location.state,
      );
    },
    [app],
  );

  const handleClickExport = useCallback(() => {
    onClickExport(app.id);
  }, [app.id, onClickExport]);

  const paginationParams = {
    mobile_app_id: app.id,
  };

  return (
    <SecurityFindingDialogManager
      onCloseDialog={handleCloseDialog}
      currentUser={currentUser}
    >
      {() => (
        <Paginate type={SecurityFindingEndpoint} params={paginationParams}>
          {({ loading, loadedInitial, loadMore }) => (
            <SecurityFindings
              hideAppName
              apps={[app]}
              findings={findings}
              findingsCount={findingsCount}
              onSelectFinding={handleSelectFinding}
              filler={!loadedInitial}
              loadMoreRows={() => {
                loadMore();
              }}
              onChangeShowHideClosed={onChangeShowHideClosed}
              filterValue={filterValue}
              onClickExport={handleClickExport}
              onClickSort={handleSortClick}
              loading={loading}
              coda={renderCoda}
              codaCount={subscriptionsBeyondApp.length}
              codaRowHeight={shadowscanRowHeight}
              reRenderBasedOn={shadowscanFindings}
              compliancePolicyFilter={[]}
              onChangeComplianceSearchFilter={() => {}}
              showCompliancePolicyFilter={false}
            />
          )}
        </Paginate>
      )}
    </SecurityFindingDialogManager>
  );
}
