//@flow
import flatMap from 'lodash/fp/flatMap';
import flow from 'lodash/fp/flow';
import values from 'lodash/fp/values';
import mapValues from 'lodash/fp/mapValues';
import keysOf from 'lodash/fp/keys';

type AnythingButFunctions = void | null | string | number | boolean | {};

export default function createKeyable<T: AnythingButFunctions>({
  keys,
}: {
  keys: {
    [string]: { [string]: T | false | ((T | false, { type: string }) => T) },
  },
}) {
  const triggerActions = flow(
    values,
    flatMap(keysOf),
  )(keys);
  const initialStateComputed = mapValues(() => false)(keys);

  return function(
    state: { [string]: T | false } = initialStateComputed,
    action: { type: string },
  ): { [string]: T | false } {
    if (!triggerActions.includes(action.type)) {
      return state;
    }

    let newMappings = {};

    for (const key in keys) {
      for (const actionType in keys[key]) {
        if (action.type === actionType) {
          const valueOrFn = keys[key][actionType];
          if (typeof valueOrFn === 'function') {
            newMappings[key] = valueOrFn(state[key], action);
          } else {
            newMappings[key] = valueOrFn;
          }
        }
      }
    }

    return {
      ...state,
      ...newMappings,
    };
  };
}
