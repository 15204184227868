// @flow
import React, { Fragment, PureComponent } from 'react';
import Chip from '@material-ui/core/Chip';

// Types
const styles = {
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingBottom: 6,
  },
  chip: {
    padding: '0px',
    margin: '0 5px',
  },
};

type Props = {
  // Display
  displayed: {
    currentStatusFilter: boolean,
    priorityFilter: boolean,
    severityFilter: boolean,
    releaseTypeFilter: boolean,
    compliancePolicyFilter: boolean,
    ageFilter: boolean,
    statusDuringDateRangeFilter: boolean,
    storeBlockersFilter: boolean,
  },
  // Openers
  openCurrentStatusFilter: () => mixed,
  openReleaseTypeFilter: () => mixed,
  openPriorityFilter: () => mixed,
  openStoreBlockersFilter: () => mixed,
  openSeverityFilter: () => mixed,
  openCompliancePolicyFilter: () => mixed,
  openStatusDuringDateRangeFilter: () => mixed,
  openAgeFilter: () => mixed,
  // Closers
  closeAndResetCurrentStatusFilter: () => mixed,
  closeAndResetReleaseTypeFilter: () => mixed,
  closeAndResetPriorityFilter: () => mixed,
  closeAndResetStoreBlockersFilter: () => mixed,
  closeAndResetSeverityFilter: () => mixed,
  closeAndResetCompliancePolicyFilter: () => mixed,
  closeAndResetStatusDuringDateRangeFilter: () => mixed,
  closeAndResetAgeFilter: () => mixed,
};

type State = {};

export default class CurrentFiltersChips extends PureComponent<Props, State> {
  render() {
    const {
      displayed,

      openCurrentStatusFilter,
      openReleaseTypeFilter,
      openPriorityFilter,
      openStoreBlockersFilter,
      openSeverityFilter,
      openCompliancePolicyFilter,
      openStatusDuringDateRangeFilter,
      openAgeFilter,

      closeAndResetCurrentStatusFilter,
      closeAndResetReleaseTypeFilter,
      closeAndResetPriorityFilter,
      closeAndResetStoreBlockersFilter,
      closeAndResetSeverityFilter,
      closeAndResetCompliancePolicyFilter,
      closeAndResetStatusDuringDateRangeFilter,
      closeAndResetAgeFilter,
    } = this.props;

    return (
      <Fragment>
        {/* Filter Presentation Controller */}
        <div style={styles.box}>
          <p>Filter by: </p>

          {/* Current Status */}
          <Chip
            style={styles.chip}
            variant={displayed.currentStatusFilter ? 'default' : 'outlined'}
            onClick={openCurrentStatusFilter}
            onDelete={
              displayed.currentStatusFilter
                ? () => void closeAndResetCurrentStatusFilter()
                : void 0
            }
            label="Current Status"
          />

          {/* Priority */}
          <Chip
            style={styles.chip}
            variant={displayed.priorityFilter ? 'default' : 'outlined'}
            onClick={openPriorityFilter}
            onDelete={
              displayed.priorityFilter
                ? () => void closeAndResetPriorityFilter()
                : void 0
            }
            label="Priority"
          />

          {/* Store Blockers */}
          <Chip
            style={styles.chip}
            variant={displayed.storeBlockersFilter ? 'default' : 'outlined'}
            onClick={openStoreBlockersFilter}
            onDelete={
              displayed.storeBlockersFilter
                ? () => void closeAndResetStoreBlockersFilter()
                : void 0
            }
            label="Store Blockers"
          />

          {/* Severity */}
          <Chip
            style={styles.chip}
            variant={displayed.severityFilter ? 'default' : 'outlined'}
            onClick={openSeverityFilter}
            onDelete={
              displayed.severityFilter
                ? () => void closeAndResetSeverityFilter()
                : void null
            }
            label="Severity"
          />

          {/* Compliance Policy */}
          <Chip
            style={styles.chip}
            variant={displayed.compliancePolicyFilter ? 'default' : 'outlined'}
            onClick={openCompliancePolicyFilter}
            onDelete={
              displayed.compliancePolicyFilter
                ? () => void closeAndResetCompliancePolicyFilter()
                : void null
            }
            label="Compliance Policy"
          />

          {/* Release Type */}
          <Chip
            style={styles.chip}
            variant={displayed.releaseTypeFilter ? 'default' : 'outlined'}
            onClick={openReleaseTypeFilter}
            onDelete={
              displayed.releaseTypeFilter
                ? () => void closeAndResetReleaseTypeFilter()
                : void null
            }
            label="Release Type"
          />

          {/* Age */}
          <Chip
            style={styles.chip}
            variant={displayed.ageFilter ? 'default' : 'outlined'}
            onClick={openAgeFilter}
            onDelete={
              displayed.ageFilter
                ? () => void closeAndResetAgeFilter()
                : void null
            }
            label="Age"
          />

          {/* Status During Date Range */}
          <Chip
            style={styles.chip}
            variant={
              displayed.statusDuringDateRangeFilter ? 'default' : 'outlined'
            }
            onClick={openStatusDuringDateRangeFilter}
            onDelete={
              displayed.statusDuringDateRangeFilter
                ? () => void closeAndResetStatusDuringDateRangeFilter()
                : void null
            }
            label="Status During Date Range"
          />
        </div>
      </Fragment>
    );
  }
}
