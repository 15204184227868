//@flow
import React, { PureComponent } from 'react';
import type { Note } from '@datatheorem/findings/types';
import TextField from '@material-ui/core/TextField';
import { Button } from '@datatheorem/components';
import Comment from './Comment';
import CircularProgress from '@material-ui/core/CircularProgress';
import isAfter from 'date-fns/is_after';

function sortNotes(a, b) {
  if (!a.date) {
    return 1;
  }

  if (!b.date) {
    return -1;
  }

  if (isAfter(a.date, b.date)) {
    return 1;
  } else {
    return -1;
  }
}

type Props = {
  notes: ?$ReadOnlyArray<Note>,
  onAddNewNote: (note: string) => void,
  newCommentIsLoading?: ?boolean,
};

type State = {
  note: string,
};

export default class Comments extends PureComponent<Props, State> {
  state = {
    note: '',
  };

  render() {
    const { notes, newCommentIsLoading } = this.props;
    return (
      <div>
        {notes &&
          notes
            .slice()
            .sort(sortNotes)
            .map(note => (
              <Comment
                key={note.id}
                author={note.author_email}
                date={note.date}
              >
                {note.text}
              </Comment>
            ))}
        {newCommentIsLoading ? (
          <CircularProgress />
        ) : (
          <div>
            <TextField
              id="comment-form"
              label="Technical questions and/or comments? Insert them here."
              multiline
              rowsMax="4"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.note}
              onChange={this.handleChangeNewNote}
              onKeyPress={this.handleKeyPressNewNote}
              style={{ width: '100%' }}
              margin="normal"
              helperText="click Save or hit Shift + Enter to submit"
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                primary
                ariaLabel="Save Comment"
                disabled={!this.state.note.length}
                onClick={this.handleAddNewNote}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }

  handleKeyPressNewNote = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.shiftKey && e.key === 'Enter') {
      this.handleAddNewNote();
    }
  };

  handleChangeNewNote = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      note: e.currentTarget.value,
    });
  };

  handleAddNewNote = () => {
    this.props.onAddNewNote(this.state.note);
    this.setState({ note: '' });
  };
}
