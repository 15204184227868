//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { trustkitAnalysisReceived } from '../actions';
import type { TrustKitAnalysis } from '@datatheorem/user-api/mobile_apps';

export type TrustkitAnalysesState = $ReadOnlyArray<TrustKitAnalysis>;

export default createReducerCreator<
  TrustkitAnalysesState,
  TrustKitAnalysis,
  void,
>({
  initialState: ([]: TrustkitAnalysesState),
  equalityCheck: (a, b) => a.mobile_app_id === b.mobile_app_id,
})(updateInList(trustkitAnalysisReceived.toString()));
