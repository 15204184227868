// @flow
import React from 'react';
// Components
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@datatheorem/components';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
// Icons
import SaveIcon from '@material-ui/icons/Save';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SearchIcon from '@material-ui/icons/Search';
// Types
type Props = {|
  saveReportDialogOpen: boolean,
  downloadResultDialogOpen: boolean,
  nameError: boolean,
  handleSaveReport: () => mixed,
  handleSaveReportDialogClose: () => mixed,
  handleSaveReportDialogOpen: () => mixed,
  handleDownloadResultDialogClose: () => mixed,
  handleDownloadResultDialogOpen: () => mixed,
  handleReportNameChange: string => mixed,
  handleExportCurrentFindings: () => mixed,
  handleToggleAdvanceSearch: () => mixed,
  isAdvanceSearch: boolean,
|};

const AdvanceSearchActions = ({
  saveReportDialogOpen,
  downloadResultDialogOpen,
  nameError,
  handleSaveReportDialogClose,
  handleSaveReportDialogOpen,
  handleDownloadResultDialogOpen,
  handleDownloadResultDialogClose,
  handleReportNameChange,
  handleSaveReport,
  handleExportCurrentFindings,
  handleToggleAdvanceSearch,
  isAdvanceSearch,
}: Props) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    }}
  >
    {/* Advanced search toggle */}
    {isAdvanceSearch ? (
      <Button
        variant="text"
        ariaLabel="Hide advance search"
        onClick={handleToggleAdvanceSearch}
      >
        <SearchIcon style={{ color: 'inherit', marginRight: 8 }} /> Hide
        advanced search
      </Button>
    ) : (
      <Button
        variant="text"
        ariaLabel="advanced search"
        onClick={handleToggleAdvanceSearch}
      >
        <SearchIcon style={{ color: 'inherit', marginRight: 8 }} />
        Advanced search
      </Button>
    )}
    {/* View Reports */}
    <Link to="/reports">
      <Button variant="text" ariaLabel="View Reports">
        <AssessmentIcon style={{ color: 'inherit', marginRight: 8 }} /> View
        reports
      </Button>
    </Link>
    {/* Save as Report */}
    <Button
      variant="text"
      ariaLabel="Save as Report"
      onClick={handleSaveReportDialogOpen}
    >
      <SaveIcon style={{ color: 'inherit', marginRight: 8 }} />
      Save as Report
    </Button>
    {/* Download Results */}
    <Button
      ariaLabel="Download Results"
      onClick={handleDownloadResultDialogOpen}
    >
      <DownloadIcon style={{ color: 'inherit', marginRight: 8 }} />
      Download Results
    </Button>
    {/* Save Report Dialog */}
    <Dialog
      open={saveReportDialogOpen}
      onClose={handleSaveReportDialogClose}
      aria-labelledby="create-new-report-title"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="create-new-report-title">Create New Report</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* Save Filter name Input */}
          <TextField
            fullWidth
            autoFocus
            error={nameError}
            label="Report Name"
            helperText={nameError ? 'Name cannot be empty' : null}
            onChange={e => void handleReportNameChange(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          ariaLabel="Cancel"
          onClick={handleSaveReportDialogClose}
        >
          Cancel
        </Button>
        <Button variant="text" ariaLabel="Save" onClick={handleSaveReport}>
          <SaveIcon style={{ color: 'inherit', marginRight: 8 }} />
          Save
        </Button>
      </DialogActions>
    </Dialog>
    {/* Download Results dialog */}
    <Dialog
      open={downloadResultDialogOpen}
      onClose={handleDownloadResultDialogClose}
      aria-labelledby="download-results-title"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="download-results-title">Download Results</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* Save Filter name Input */}
          <TextField
            fullWidth
            autoFocus
            error={nameError}
            label="Report Name"
            helperText={nameError ? 'Name cannot be empty' : null}
            onChange={e => void handleReportNameChange(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          ariaLabel="Cancel"
          onClick={handleDownloadResultDialogClose}
        >
          Cancel
        </Button>
        <Button
          variant="text"
          ariaLabel="Download"
          onClick={handleExportCurrentFindings}
        >
          Download
          <DownloadIcon style={{ color: 'inherit', marginLeft: 8 }} />
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

export default AdvanceSearchActions;
