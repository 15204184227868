//@flow
import React, { PureComponent } from 'react';
// $FlowFixMe Flow Upgrade
import { Field, Formik, FieldArray } from 'formik';
import { commonProps } from './JiraSchemas';
import { Button } from '@datatheorem/components';
import Page from '../../theme/Page';
import { isEmpty } from 'lodash/fp';

import { type JiraIntegrationConfig } from '@datatheorem/user-api/jira_config';

type Props = {
  submitLabel: string,
  onSubmit: (?mixed) => void,
  handleBackSubmit: () => void,
  initialValues: ?JiraIntegrationConfig,
};

// TODO: Will need to update once we support more Dynamic fields

export default class JiraDynamicFieldForm extends PureComponent<Props> {
  render() {
    const {
      submitLabel,
      onSubmit,
      handleBackSubmit,
      initialValues,
    } = this.props;

    const initVals =
      isEmpty(initialValues) ||
      !initialValues ||
      isEmpty(initialValues.dynamic_fields)
        ? { dynamic_fields: [{ field_id: '', value: 'FINDING_PORTAL_URL' }] }
        : { dynamic_fields: initialValues.dynamic_fields };

    const innerSubmit = values => {
      if (!values.dynamic_fields) return null;
      if (values.dynamic_fields.length === 0) return null;

      const valuesToPass =
        values.dynamic_fields[0].field_id === ''
          ? { dynamic_fields: [] }
          : values;
      onSubmit(valuesToPass);
    };

    return (
      //$FlowFixMe Flow Upgrade
      <Formik
        initialValues={initVals}
        onSubmit={innerSubmit}
        render={({ values, handleSubmit, setFieldValue }) => (
          <Page>
            <FieldArray
              name="dynamic_fields"
              render={({ form }) => {
                return (
                  <div>
                    <h2>Optional</h2>
                    <p>
                      Dynamic fields are a set of <b>optional</b> Jira
                      attributes that are similar to Static fields. The main
                      difference it&#39;s the value is not known until ticket
                      creation starts, and you only need to provide the field
                      ID.
                    </p>
                    <p>
                      An example of this is that we add a link to the jira
                      ticket that points to the Data Theorem portal of the
                      related issue. We will not know what url we should point
                      to, until we fetch the details of the issue on our side.
                    </p>
                    {values && (
                      <div>
                        <Field
                          label={'Dynamic Field ID'}
                          {...commonProps(
                            `dynamic_fields[0].field_id`,
                            setFieldValue,
                          )}
                          errorText={
                            form.touched.dynamic_fields &&
                            form.touched.dynamic_fields[0] &&
                            form.errors.dynamic_fields &&
                            form.errors.dynamic_fields[0] &&
                            typeof form.errors.dynamic_fields[0] !== 'string' &&
                            form.errors.dynamic_fields[0].field_id
                          }
                        />

                        <Field
                          label={`Dynamic Field Value`}
                          {...commonProps(
                            `dynamic_fields[0].value`,
                            setFieldValue,
                          )}
                          inputDisabled={true}
                          errorText={
                            form.touched.dynamic_fields &&
                            form.touched.dynamic_fields[0] &&
                            form.errors.dynamic_fields &&
                            form.errors.dynamic_fields[0] &&
                            typeof form.errors.dynamic_fields[0] !== 'string' &&
                            form.errors.dynamic_fields[0].value
                          }
                        />
                      </div>
                    )}

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingBottom: 10,
                        paddingTop: 10,
                        paddingRight: 10,
                        paddingLeft: 10,
                      }}
                    >
                      <Button
                        variant="text"
                        ariaLabel="Back"
                        hoverColor={'none'}
                        rippleColor={'none'}
                        onClick={handleBackSubmit}
                      >
                        Back
                      </Button>
                      <span style={{ width: 10 }} />
                      <Button onClick={handleSubmit} ariaLabel={submitLabel}>
                        {submitLabel}
                      </Button>
                    </div>
                  </div>
                );
              }}
            />
          </Page>
        )}
      />
    );
  }
}
