//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

const styles = {
  // region Header
  appSummaryCardStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 5,
    marginRight: 5,
    paddingBottom: 10,
    minWidth: 500 - 10, // minWidth (app-summary-card) - (inline padding)
  },

  scoreHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 125,
  },

  title: {
    textAlign: 'center',
    marginBottom: 10,
  },
  // endregion

  // The following styles match the AppSummaryCard flex layout.
  // region AppSummaryCard Layout
  // region Left
  left: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 250,
  },
  // endregion

  // region right
  right: {
    flexGrow: 4,
    flexShrink: 4,
    flexBasis: 50,
    display: 'flex',

    justifyContent: 'flex-end',
  },
  // endregion
  // endregion
};

type Props = {
  children: ?Node,
} & InjectedJSS<typeof styles>;

/*
 * Provides the same flex layout as the app-summary-card for proper alignment.
 * Also provides common headers accross detailed views.
 */
class HeaderTemplate extends PureComponent<Props> {
  render() {
    const { classes, children } = this.props;

    return (
      <div className={classes.appSummaryCardStyle}>
        <div className={classes.left} />
        <div className={classes.right}>
          <div className={classes.scoreHeader}>
            <h4 className={classes.title}>Protection</h4>
          </div>
          {children}
        </div>
      </div>
    );
  }
}

export default jss(styles)(HeaderTemplate);
