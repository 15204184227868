//@flow
import { Raven } from '@datatheorem/global';
import config from '@datatheorem/config';
import React, { Component } from 'react';
import Gate from './Gate';

import type { Node } from 'react';

// TODO: Make sure that cosmos works with whatever fix is used.
const AUTH_HOST = typeof config.authHost === 'string' ? config.authHost : null;

if (!AUTH_HOST) {
  throw new Error('authHost config value is required');
}

type LoggedOutReasons = 'expired' | 'forbidden';
export type AuthState =
  | 'INITIAL'
  | 'VALIDATING'
  | 'REQUESTING_SESSION'
  | 'LOGGED_IN'
  | 'SESSION_EXPIRED'
  | 'FORBIDDEN'
  | 'LOGGED_OUT';

type Props = {
  onReceiveSessionCookie: string => mixed,
  onReceiveSignoutResponse?: () => mixed,
  children: Node,
  appAuthState?:
    | 'VALIDATING'
    | 'LOGGED_IN'
    | 'CANCELING'
    | 'SESSION_EXPIRED'
    | null,
  waitingDisplay?: Node,
};

type State = {
  state: AuthState,
  attemptedToRefreshSession: boolean,
  attemptingToRefreshSession: boolean,
  receivedNoData: boolean,
};

const authStateEnum = {
  INITIAL: 'INITIAL',
  LOGGED_OUT: 'LOGGED_OUT',
  VALIDATING: 'VALIDATING',
  REQUESTING_SESSION: 'REQUESTING_SESSION',
  REQUEST_SIGN_OUT: 'REQUEST_SIGN_OUT',
  LOGGED_IN: 'LOGGED_IN',
  FORBIDDEN: 'FORBIDDEN',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
};

export default class DTAuthenticated extends Component<Props, State> {
  state = {
    state: 'INITIAL',
    attemptedToRefreshSession: false,
    attemptingToRefreshSession: false,
    receivedNoData: false,
  };

  makeLoginURL = (
    reason?: LoggedOutReasons,
    window: { +location: { href: string } },
  ) =>
    reason
      ? `${AUTH_HOST}/auth/?reason=${reason}&forwardTo=${window.location.href}`
      : `${AUTH_HOST}/auth/?forwardTo=${window.location.href}`;

  redirectToLogin = (reason?: LoggedOutReasons) => {
    window.location.assign(this.makeLoginURL(reason, window));
  };

  componentDidMount() {
    this.setState({ state: authStateEnum.REQUESTING_SESSION });
  }

  componentDidUpdate() {
    if (
      this.props.appAuthState === 'SESSION_EXPIRED' &&
      this.state.attemptedToRefreshSession
    ) {
      this.redirectToLogin('expired');
    }
  }

  /**
   * This modifies the state based on the app's auth state.
   *
   * Notes:
   *  If the app is left idle eg. the user is using Brand Protect instead of App Secure, and then the user
   *  attempts to use App Secure and their session is expired,
   *  we shouldn't redirect them to dt auth since they already have a valid session.
   *  Instead, here we set up state to check whether or not we are trying to refresh the session from
   *  dt-auth vs getting a new session.
   *
   * The first time we get a SESSION_EXPIRED state notice, we attempt to refresh the session from dt-auth
   * if we have attempted to refresh the session and still get SESSION_EXPIRED from the app, then we know
   * the actual session on dt-auth is expired so we can be sure to redirect the user due to a expired session.
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.appAuthState) {
      switch (props.appAuthState) {
        case authStateEnum.LOGGED_IN:
          return {
            state: authStateEnum.LOGGED_IN,
            attemptedToRefreshSession: false,
            attemptingToRefreshSession: false,
          };
        case authStateEnum.SESSION_EXPIRED:
          if (!state.attemptedToRefreshSession)
            return {
              state: authStateEnum.REQUESTING_SESSION,
              attemptingToRefreshSession: true,
            };
          return { state: authStateEnum.SESSION_EXPIRED };
        case authStateEnum.VALIDATING:
          if (state.attemptingToRefreshSession)
            return {
              state: authStateEnum.VALIDATING,
              attemptedToRefreshSession: true,
            };
          return { state: authStateEnum.VALIDATING };
        case 'CANCELING':
          return { state: authStateEnum.REQUEST_SIGN_OUT };
      }
    }
    return null;
  }

  handleReceiveSessionCookie = (sessionCookie: string) => {
    if (sessionCookie) {
      this.props.onReceiveSessionCookie(sessionCookie);
    } else if (this.state.attemptedToRefreshSession) {
      this.redirectToLogin('expired');
    } else if (this.state.attemptingToRefreshSession) {
      this.redirectToLogin();
    } else {
      try {
        throw new Error('Received no data from login iframe');
      } catch (err) {
        Raven.captureException(err);
      }
      this.setState({ receivedNoData: true });
    }
  };

  handleReceivedSignoutResponse = () => {
    if (this.props.onReceiveSignoutResponse) {
      /* does the app need to do anything special before logging the user out? in this case,
         the app should take care of redirecting the user
      */
      this.props.onReceiveSignoutResponse();
    } else {
      // by default we redirect users to the login page after we sign them out
      this.redirectToLogin();
    }
  };

  render() {
    const { state, receivedNoData } = this.state;
    const { waitingDisplay } = this.props;

    if (receivedNoData) {
      return (
        <div>
          Your browser&apos;s security policy prevented this login attempt from
          working. Our technical support staff has been notified. If you are
          using an older browser, try updating to the latest Google Chrome,
          Mozilla Firefox, or Microsoft Edge browser.
        </div>
      );
    }

    return (
      <div>
        {state === authStateEnum.VALIDATING && waitingDisplay}

        {state === authStateEnum.REQUESTING_SESSION && (
          <div>
            {waitingDisplay}
            <Gate
              requestType="req-session"
              onCompleteRequest={this.handleReceiveSessionCookie}
              src={`${AUTH_HOST}/auth/gate`}
              authOrigin={AUTH_HOST}
            />
          </div>
        )}

        {state === authStateEnum.REQUEST_SIGN_OUT && (
          <div>
            {waitingDisplay}
            <Gate
              requestType="req-sign-out"
              onCompleteRequest={this.handleReceivedSignoutResponse}
              src={`${AUTH_HOST}/auth/gate`}
              authOrigin={AUTH_HOST}
            />
          </div>
        )}

        {state === authStateEnum.LOGGED_IN && this.props.children}
      </div>
    );
  }
}
