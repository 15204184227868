//@flow
import React, { PureComponent } from 'react';
import startCase from 'lodash/startCase';
import numeral from 'numeral';

import {
  isClosedStatus,
  isNeutralStatus,
  sortTargets,
} from '@datatheorem/findings/targets/status';
import type { SecurityFinding } from '@datatheorem/findings/types';
import type { Application } from '@datatheorem/user-api/mobile_apps';

import { default as CardWithTargets, getRowHeight } from './../CardWithTargets';
import IconLabel from './../IconLabel';
import PreviewInline from './../PreviewInline';

import CardStatusLineForTarget from './CardStatusLineForTarget';
import CardSeeMoreSimple from './CardSeeMoreSimple';
import {
  getBadgeDescriptionFromBadgeType,
  getCompletedAppProtectionBadgeKeyByBadgeTitle,
  getIncompleteAppProtectionBadgeKeyByBadgeTitle,
} from './../app-protection-badges/util';

const visibleTargetLimit = 3;

type Props = {
  finding: SecurityFinding,
  apps: $ReadOnlyArray<Application>,
  hideAppName?: boolean,
  preview?: boolean,
};

export default class SecurityFindingCard extends PureComponent<Props> {
  render() {
    const { finding, apps, preview, hideAppName } = this.props;
    const {
      id,
      title,
      aggregated_status,
      severity,
      priority,
      mobile_app_id,
      targets,
      notes,
      affecting_mediawatch_article_ids,
      compliance_policy_references,
      app_protection_score,
    } = finding;

    const indicatorType = isNeutralStatus(aggregated_status)
      ? 'neutral'
      : isClosedStatus(aggregated_status)
        ? 'good'
        : 'bad';

    const app = apps && apps.find(app => app.id === mobile_app_id);
    const sortedTargets = targets && targets.slice().sort(sortTargets);
    const visibleTargets =
      sortedTargets &&
      sortedTargets.slice(
        0,
        sortedTargets.length > visibleTargetLimit
          ? visibleTargetLimit - 1
          : visibleTargetLimit,
      );

    const app_protection_percent =
      app && app.max_app_protection_score && app_protection_score
        ? numeral(
            parseInt(app_protection_score, 10) /
              parseInt(app.max_app_protection_score, 10),
          ).format('0%')
        : null;

    // App Protection
    const completedAppProtectionBadgeKey = getCompletedAppProtectionBadgeKeyByBadgeTitle(
      title,
      app,
    );
    const incompleteAppProtectionBadgeKey = getIncompleteAppProtectionBadgeKeyByBadgeTitle(
      title,
      app,
    );
    const badgeType =
      completedAppProtectionBadgeKey || incompleteAppProtectionBadgeKey;
    const badgeDescription =
      badgeType && getBadgeDescriptionFromBadgeType(badgeType);

    const appName = app &&
      !hideAppName && <IconLabel platform={app.platform}>{app.name}</IconLabel>;

    return (
      <CardWithTargets
        text={[
          preview ? <PreviewInline>${title}</PreviewInline> : title,
          id && `#${id}`,
          appName,
          typeof severity === 'string'
            ? `Severity: ${startCase(severity.toLowerCase())}`
            : null,
        ]}
        indicator={indicatorType}
        priority={priority}
        commentCount={notes && notes.length}
        compliance_policy_references={compliance_policy_references}
        mediawatchPresent={
          affecting_mediawatch_article_ids &&
          affecting_mediawatch_article_ids.length > 0
        }
        badge={
          app_protection_percent && `+${app_protection_percent} Protections`
        }
        appProtectionBadgeTitle={badgeDescription}
        appProtectionBadgeAchieved={!!completedAppProtectionBadgeKey}
      >
        {visibleTargets &&
          visibleTargets.map(target => (
            <CardStatusLineForTarget
              key={target.id}
              target={target}
              preview={preview}
            />
          ))}
        {sortedTargets &&
          sortedTargets.length > visibleTargetLimit && (
            <CardSeeMoreSimple hack>
              Click to see {sortedTargets.length - visibleTargetLimit + 1} more
            </CardSeeMoreSimple>
          )}
      </CardWithTargets>
    );
  }

  static getRowHeight(props: { finding?: SecurityFinding } = {}) {
    return getRowHeight(
      props.finding && props.finding.targets && props.finding.targets.length,
    );
  }
}
