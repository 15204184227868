//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import Dialog from 'material-ui/Dialog';

import InquireButton from './InquireButton';

const styles = {};

type Props = {
  open: boolean,
  onRequestClose: () => mixed,
  onClickInquire: (e: SyntheticMouseEvent<HTMLElement>) => mixed,
  children?: ?Node,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class PreviewDialog extends PureComponent<Props> {
    render() {
      const { children, open, onRequestClose, onClickInquire } = this.props;

      return (
        <Dialog
          title="Preview Unsubscribed Issues"
          modal={false}
          open={open}
          onRequestClose={onRequestClose}
        >
          <div style={{ position: 'absolute', top: 20, right: 20 }}>
            <InquireButton onClickInquire={onClickInquire} />
          </div>

          <div
            style={{
              overflowY: 'auto',
              maxHeight: 'inherit',
              paddingRight: 10,
            }}
          >
            {children}
          </div>
        </Dialog>
      );
    }
  },
);
