//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

import { FindingStats, FindingsBadgeType } from '@datatheorem/components';
import HeaderTemplate from './HeaderTemplate';
import { type MetadataCount } from '@datatheorem/user-api/mobile_apps';

const headerStyles = {
  overview: {
    flexGrow: 2,
    flexShrink: 2,
  },

  title: {
    textAlign: 'center',
    marginBottom: 5,
  },
};

type HeaderProps = {
  openMetadataCount: ?MetadataCount,
} & InjectedJSS<typeof headerStyles>;

export const Header = jss(headerStyles)(
  class Header extends PureComponent<HeaderProps> {
    render() {
      const { classes, openMetadataCount } = this.props;

      return (
        <HeaderTemplate>
          <div className={classes.overview}>
            <h5 className={classes.title}>Totals</h5>
            <FindingStats
              openMetadataCount={openMetadataCount}
              /* TODO: use same badges in the app summary card using details view prop */
              badgeTypes={[
                FindingsBadgeType.P1,
                FindingsBadgeType.BLOCKER,
                FindingsBadgeType.COMPLIANCE,
              ]}
            />
          </div>
        </HeaderTemplate>
      );
    }
  },
);

const styles = {};

type Props = {
  id: string,
  openMetadataCount: ?MetadataCount,
};

class SummaryView extends PureComponent<Props & InjectedJSS<typeof styles>> {
  render() {
    const { openMetadataCount } = this.props;

    return (
      <div>
        {/* Findings */}
        <FindingStats
          noLabel={true}
          openMetadataCount={openMetadataCount}
          badgeTypes={[
            FindingsBadgeType.P1,
            FindingsBadgeType.BLOCKER,
            FindingsBadgeType.COMPLIANCE,
          ]}
        />
      </div>
    );
  }
}

export default jss(styles)(SummaryView);
