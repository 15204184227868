//@flow
import React, { PureComponent } from 'react';
import {
  CardWithTargets,
  taskStatusToIndicatorMap,
} from '@datatheorem/components';
import type { AppProtectionTask } from '@datatheorem/user-api/mobile_apps';
import startCase from 'lodash/startCase';
import { sortTargets } from '@datatheorem/findings/targets/status';
import CardStatusLineForTarget from '@datatheorem/components/src/security-finding-card/CardStatusLineForTarget';
import CardSeeMoreSimple from '@datatheorem/components/src/security-finding-card/CardSeeMoreSimple';
import { browserHistory } from '../../clients/history';

type Props = {
  task: AppProtectionTask,
};

const visibleTargetLimit = 2;

export default class AppProtectionTaskCard extends PureComponent<Props> {
  render() {
    const { task } = this.props;
    const {
      mobile_app_id,
      security_finding_id,
      targets,
      task_status,
      title,
      severity,
      compliance_policy_references,
    } = task;

    const sortedTargets = targets && targets.slice().sort(sortTargets);
    const visibleTargets =
      sortedTargets &&
      sortedTargets.slice(
        0,
        sortedTargets.length > visibleTargetLimit
          ? visibleTargetLimit - 1
          : visibleTargetLimit,
      );

    return (
      <div
        style={{ marginBottom: '10px' }}
        onClick={event => {
          event.preventDefault(); // ghost clicks \o/
          mobile_app_id &&
            security_finding_id &&
            browserHistory.push(
              `/app/${mobile_app_id}/issues/${security_finding_id}`,
            );
        }}
      >
        <CardWithTargets
          text={[
            title,
            security_finding_id ? security_finding_id : 'N/A',
            null,
            typeof severity === 'string'
              ? `Severity: ${startCase(severity.toLowerCase())}`
              : null,
          ]}
          indicator={taskStatusToIndicatorMap(task_status)}
          compliance_policy_references={compliance_policy_references}
        >
          {visibleTargets &&
            visibleTargets.map(target => (
              <CardStatusLineForTarget key={target.id} target={target} />
            ))}
          {sortedTargets &&
            sortedTargets.length > visibleTargetLimit && (
              <CardSeeMoreSimple hack>
                Click to see {sortedTargets.length - visibleTargetLimit + 1}{' '}
                more
              </CardSeeMoreSimple>
            )}
        </CardWithTargets>
      </div>
    );
  }
}
