//@flow
import React, { PureComponent } from 'react';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import DownArrow from 'material-ui/svg-icons/navigation/expand-more';
import jss, { type InjectedJSS } from 'react-jss';
import theme from '@datatheorem/theme';

const styles = {
  ViewMoreButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 1,
    padding: 5,
    backgroundColor: theme().dataTheorem.palette.accent,
  },

  FloatingActionButton: {
    marginLeft: '10px !important',
  },
};

type Props = {
  onRequestViewMore: () => void,
  label: string,
  count: number,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class ViewMoreButton extends PureComponent<Props> {
    render() {
      const { classes, onRequestViewMore, label, count } = this.props;

      return (
        <div className={classes.ViewMoreButton} onClick={onRequestViewMore}>
          <span>
            View {count} more {label}
          </span>
          <FloatingActionButton
            backgroundColor={theme().dataTheorem.palette.brand}
            mini
            zDepth={0}
            className={classes.FloatingActionButton}
          >
            <DownArrow />
          </FloatingActionButton>
        </div>
      );
    }
  },
);
