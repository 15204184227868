//@flow
import { array, boolean, lazy, mixed, object, string } from 'yup';
import { isEmpty } from 'lodash/fp';
import PanelItemTextField from '../PanelItemTextField';
import JiraExportFilterEnum from '@datatheorem/enums/JiraExportFilterEnum';

export const commonProps = (
  name: string,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
) => ({
  name: name,
  component: PanelItemTextField,
  setFieldValue: (value: any) => setFieldValue && setFieldValue(name, value),
});

////////////////////////////////////////////////////////////////////////////////
export const JiraBasicInfoSchema = object()
  .shape({
    base_url: string()
      .url('Must be a valid URL')
      .required('Base URL is required'),
    username: string().required('Usernname is required'),
    password: string().when('$isNew', (isNew, schema) => {
      return isNew
        ? schema.required('Password is required')
        : schema.notRequired();
    }),
    type_of_issue_name: string().required('Type of Issue Name is required '),
    export_filter: string().required('Export Filter Is required'),
    export_pre_prod: boolean().required('Export Pre-Prod is required'),
    export_prod: boolean().required('Export Prod is required'),
    project_key_or_id: string().required('Project Key is required'),
  })
  .default({
    base_url: '',
    username: '',
    password: '',
    type_of_issue_name: '',
    project_key_or_id: '',
    export_filter: JiraExportFilterEnum.P1_ISSUES_AND_BLOCKERS,
    export_pre_prod: true,
    export_prod: false,
  });

////////////////////////////////////////////////////////////////////////////////
export const JiraSeverityFieldConfigSchema = object()
  .shape({
    severity_field_config: object().shape({
      field_id: string().required('Field ID is required'),
      high_severity_value_id: string().required('A high severity is required'),
      medium_severity_value_id: string().required(
        'A medium severity is required',
      ),
      low_severity_value_id: string().required('A low severity is required'),
    }),
  })
  .default({
    severity_field_config: {
      field_id: '',
      high_severity_value_id: '',
      medium_severity_value_id: '',
      low_severity_value_id: '',
    },
  });

////////////////////////////////////////////////////////////////////////////////
const JiraStaticFieldSchema = object().shape({
  field_id: string().required('A Field ID is required'),
  field_value: string().required('A Field Value is required'),
});

export const JiraStaticArrayFieldSchema = object()
  .shape({
    static_fields: array()
      .of(JiraStaticFieldSchema)
      .ensure(),
  })
  .default({
    static_fields: [{ field_id: '', field_value: '' }],
  });

////////////////////////////////////////////////////////////////////////////////
const JiraDynamicFieldSchema = object().shape({
  field_id: string().required('Field ID is required'),
  value: string().required('Value is requied'),
});

export const JiraDynamicArrayFieldSchema = object()
  .shape({
    dynamic_fields: array()
      .of(JiraDynamicFieldSchema)
      .ensure(),
  })
  .default({
    dynamic_fields: [{ field_id: '', value: 'FINDING_PORTAL_URL' }],
  });

////////////////////////////////////////////////////////////////////////////////
export const JiraIntegrationSchema = object()
  .shape({
    base_url: string()
      .url()
      .required('Base URL is required'),
    username: string().required('Usernname is required'),
    password: string().required('Password is required'),
    type_of_issue_name: string().required('Type of Issue Name is required '),
    export_filter: string().required('Export Filter Is required'),
    export_pre_prod: boolean().required('Export Pre-Prod is required'),
    export_prod: boolean().required('Export Prod is required'),
    project_key_or_id: string().required('Project Key is required'),
    // This object can be optional, but when it's present all of it's attribute must be present
    severity_field_config: lazy(value => {
      if (isEmpty(value)) return mixed().notRequired();
      return JiraSeverityFieldConfigSchema;
    }),
    static_fields: JiraStaticArrayFieldSchema,
    dynamic_fields: JiraDynamicArrayFieldSchema,
  })
  .default({
    ...JiraBasicInfoSchema.default(),
    //...JiraSeverityFieldConfigSchema.default(),
    ...JiraStaticArrayFieldSchema.default(),
    ...JiraDynamicArrayFieldSchema.default(),
  });
