//@flow
import React, { PureComponent } from 'react';
import BigTable from '../theme/BigTable';
import { type User } from '@datatheorem/user-api/users';
import { getName } from '@datatheorem/session';
import { authStrategy, userRole } from '../../util/humanReadableEnums';
import { browserHistory } from '../../clients/history';
import { fromNow } from '@datatheorem/date';
import { orderBy } from 'lodash/fp';
import { SortDirection, type SortDirectionType } from 'react-virtualized';

function getColumns() {
  const columns = [
    {
      dataKey: 'last_name',
      width: 200,
      flexGrow: 2,
      label: 'Name',
      cellRenderer: ({ rowData }: { rowData: mixed }) => {
        if (
          rowData &&
          typeof rowData.first_name === 'string' &&
          typeof rowData.last_name === 'string'
        ) {
          return getName(rowData.first_name, rowData.last_name);
        }
        throw new Error('expected User-like type for last_name row');
      },
      style: {
        fontWeight: 'bold',
      },
    },
    {
      dataKey: 'login_email',
      width: 200,
      flexGrow: 2,
      label: 'Login Email',
      cellRenderer: ({ cellData }: { cellData: mixed }) => {
        if (
          typeof cellData !== 'string' &&
          cellData !== null &&
          typeof cellData !== 'undefined'
        ) {
          throw new Error('expected login_email to be a string');
        }
        return cellData;
      },
      style: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingRight: 5,
      },
    },
    {
      dataKey: 'notification_email',
      width: 200,
      flexGrow: 2,
      label: 'Notification Email',
      cellRenderer: ({ cellData }: { cellData: mixed }) => {
        if (
          typeof cellData !== 'string' &&
          cellData !== null &&
          typeof cellData !== 'undefined'
        ) {
          throw new Error('expected notifcation_email to be a string');
        }
        return cellData;
      },
    },
    {
      dataKey: 'role',
      width: 200,
      flexGrow: 2,
      label: 'Role',
      cellRenderer: ({ cellData }: { cellData: mixed }) => {
        if (typeof cellData !== 'string') {
          throw new Error('role column should be a string');
        }
        return userRole(cellData);
      },
    },
    {
      dataKey: 'auth_strategy',
      width: 200,
      flexGrow: 2,
      label: 'Login Type',
      cellRenderer: ({ cellData }: { cellData: mixed }) => {
        if (typeof cellData !== 'string') {
          throw new Error('expected auth_strategy to be a string');
        }
        return authStrategy(cellData);
      },
    },
    {
      dataKey: 'last_login_date',
      width: 200,
      flexGrow: 2,
      label: 'Last Login',
      cellRenderer: ({ cellData }: { cellData: mixed }) => {
        if (typeof cellData !== 'string') {
          return null;
        }
        return fromNow(cellData);
      },
    },
  ];

  return columns;
}

function sortUsers(users, sortBy, sortDirection) {
  return orderBy([
    user =>
      ({
        ...user,
        last_login_date: user.last_login_date || '',
        last_name: user.last_name ? user.last_name.toLocaleLowerCase() : '',
      }[sortBy]),
  ])(sortDirection.toLocaleLowerCase())(users);
}

type Props = {
  users: $ReadOnlyArray<User>,
  forceWidth?: ?number, // use in tests to bypass AutoSizer
};

type State = {
  sortBy: string,
  sortDirection: SortDirectionType,
};

export default class Users extends PureComponent<Props, State> {
  state = {
    sortBy: 'last_name',
    sortDirection: SortDirection.ASC,
  };
  render() {
    const { users, forceWidth } = this.props;
    const { sortBy, sortDirection } = this.state;

    if (!users || !users.length) {
      return <p style={{ marginLeft: 20 }}>No users.</p>;
    }

    return (
      <BigTable
        data={sortUsers(users, sortBy, sortDirection)}
        columns={getColumns()}
        onSelectItem={this.onSelectUser}
        sort={this.handleSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        forceWidth={forceWidth}
        inefficientlyRenderAllRows
      />
    );
  }

  onSelectUser(maybeUser: mixed) {
    if (!maybeUser || typeof maybeUser.id !== 'string') {
      throw new Error('expected user-like to be passed to onSelectUser');
    }
    browserHistory.push(`/users/${maybeUser.id}`);
  }

  handleSort = ({ sortBy, sortDirection }: State) => {
    this.setState({
      sortBy,
      sortDirection,
    });
  };
}
