//@flow
import { createSelector } from 'reselect';
import type { APIKey } from '@datatheorem/user-api/api_keys';
import type { State } from '../ReduxStateType';

export const apiKeys = (state: State) => state.apiKeys;

export const apiKeyFromParam = createSelector<
  State,
  { +match: { +params: { +apiKeyId: void | string } } },
  ?APIKey,
  _,
  _,
>(
  apiKeys,
  (state, props) => props.match.params.apiKeyId,

  (apiKeys, apiKeyId) =>
    apiKeyId
      ? apiKeys.find(apiKey => {
          return (
            apiKey.id &&
            apiKey.id.toString() === (apiKeyId && apiKeyId.toString())
          );
        })
      : null,
);
