//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import { Paper } from '@material-ui/core';
import { Image } from '@datatheorem/components';
import { humanReadable } from '@datatheorem/string';
import theme from '@datatheorem/theme';
import type { SDKFinding } from '@datatheorem/user-api/sdk_findings';

const styles = {
  container: {
    marginRight: 30,
    marginBottom: 20,
    cursor: 'pointer',
    height: 105,
  },
  flexWrapper: {
    display: 'flex',
  },
  imageContainer: {
    width: 100,
    padding: 20,
  },
  summary: {
    padding: 20,
    width: 200,
  },
  title: {
    margin: 0,
    padding: 0,
    marginLeft: 10,
    fontSize: 16,
  },
  associatedFinding: {
    margin: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  associatedId: {
    width: 100,
  },
  associatedTitle: {
    width: 200,
  },
  category: {
    color: theme().dataTheorem.palette.navColor,
    fontSize: 12,
    marginLeft: 10,
    marginTop: 1,
    fontWeight: 'normal',
  },
};

type Props = {
  finding: SDKFinding,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class SDKCard extends PureComponent<Props> {
    render() {
      const { logo_url, title, category } = this.props.finding;

      return (
        <Paper style={styles.container} onClick={this.onClick}>
          <div style={styles.flexWrapper}>
            <div style={styles.imageContainer}>
              {logo_url && <Image src={logo_url} />}
            </div>
            <div style={styles.summary}>
              <h2 style={styles.title}>{title}</h2>
              <h3 style={styles.category}>
                {category === 'OTHERS'
                  ? 'Utility'
                  : humanReadable(category || '')}
              </h3>
            </div>
          </div>
        </Paper>
      );
    }

    onClick = () => {
      const { url } = this.props.finding;

      if (url) {
        global.open(url, '_blank');
      }
    };
  },
);
