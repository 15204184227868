//@flow
import React, { PureComponent, type Node } from 'react';
import { fontFamily, fontSizes, palette } from '@datatheorem/theme';

function getStyles(style = {}) {
  const styles: Object = {
    container: {
      fontFamily: fontFamily,
      fontSize: fontSizes.medium,
      paddingRight: 16,
      paddingLeft: 16,
      color: palette.navColor,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      ...style.container,
    },
  };

  styles.active = {
    ...styles.container,
    color: palette.darkestBg,
    ...style.active,
  };

  return styles;
}

type Props = {
  to: string,
  active: boolean,
  style: { container?: ?{}, active?: ?{} },
  children: Node,
};

export default class PrimaryNavigationItem extends PureComponent<Props> {
  render() {
    const { children, active, style, to } = this.props;

    const styles = getStyles(style);

    return (
      <a
        href={to}
        style={active ? styles.active : styles.container}
        aria-current={active && 'page'}
      >
        {children}
      </a>
    );
  }
}
