//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import SDKCard from './SDKCard';
import type { SDKFinding } from '@datatheorem/user-api/sdk_findings';

const styles = {
  SDKCards: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    marginLeft: 83,
  },
};

type Props = {
  findings: $ReadOnlyArray<SDKFinding>,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class SDKCards extends PureComponent<Props> {
    render() {
      const { classes, findings } = this.props;
      return (
        <div className={classes.SDKCards}>
          {findings.map(finding => (
            <SDKCard key={finding.id} finding={finding} />
          ))}
        </div>
      );
    }
  },
);
