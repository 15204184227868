//@flow
import { type SecurityTemplateCategoryEnum } from '@datatheorem/enums/SecurityTemplateCategoryEnum';

import { request } from './gapi';
import { result } from './util';
import type { SDKFinding } from './sdk_findings';
import { type PaginatedResponse } from './_common';

export type SDK = {
  category?: SecurityTemplateCategoryEnum,
  description?: string,
  title: string,
  url?: string, //e.g.: "https://www.box.com",
  logo_url?: string,
  sdk_finding_ids?: $ReadOnlyArray<?string>,
};

type ListParams = {
  +cursor?: ?string,
};

type ListResponse = PaginatedResponse<{
  sdks?: $ReadOnlyArray<SDK>,
  sdk_findings?: $ReadOnlyArray<SDKFinding>,
}>;

export async function list(params?: ListParams): Promise<ListResponse> {
  return request({ path: 'userapi/v2/sdks', params: params }).then(result);
}
