//@flow
import { type FindingTargetStatusEnum } from '@datatheorem/enums/FindingTargetStatusEnum';
import { type SecurityTemplateCategoryEnum } from '@datatheorem/enums/SecurityTemplateCategoryEnum';
import type { Target, SecurityFinding } from '@datatheorem/findings/types';

import { request } from './gapi';
import { byProperty, result } from './util';

export type SDKIssue = {
  target_ids: $ReadOnlyArray<string>,
  security_finding: SecurityFinding,
};

export type SDKIssues = $ReadOnlyArray<SDKIssue>;

export type SDKFinding = {
  category?: SecurityTemplateCategoryEnum,
  associated_domains?: $ReadOnlyArray<string>, //e.g.: ["* https://www.json.com", "* https://www.google.com"]
  description?: string,
  title?: string,
  date_updated?: string, // Date ISO 8601: "2017-01-31T14:09:07.313240"
  mobile_app_id?: string,
  aggregated_status_date?: string, // Date ISO 8601: "2017-01-31T14:09:07.313240"
  targets?: $ReadOnlyArray<Target>,
  aggregated_status?: FindingTargetStatusEnum,
  sdk_issues?: SDKIssues,
  url?: string, //e.g.: "https://www.box.com",
  date_created?: string, // Date ISO 8601: "2017-01-31T14:09:07.313240"
  id?: string,
  template_id?: string,
  logo_url?: string,
};

export async function get(id: string): Promise<SDKFinding> {
  if (!id || !id.length) {
    throw new Error('Invalid SDK finding ID provided');
  }
  return request({ path: `userapi/v2/sdk_findings/${id}` }).then(result);
}

type ListParams = {
  +cursor?: ?string,
  +mobile_app_id?: ?string,
  +status_group?: ?string,
  +template_id?: ?string,
  +updated_since?: ?string,
};

export async function list(
  params?: ListParams,
): Promise<$ReadOnlyArray<SDKFinding>> {
  return request({ path: 'userapi/v2/sdk_findings', params: params })
    .then(result)
    .then(byProperty('sdk_findings'));
}
