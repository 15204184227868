//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import assign from 'rezz/assign';
import { type TopTenResponse } from '@datatheorem/user-api/app_protection_top_ten_apps';
import { topAppsReceived } from '../actions';

export type TopAppsState = ?TopTenResponse;

export default createReducerCreator<TopAppsState, void, void>()(
  assign(topAppsReceived.toString()),
);
