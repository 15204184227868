//@flow
import React, { PureComponent } from 'react';
import BrandedProductIcon from './BrandedProductIcon';
import AppSecureInactiveIcon from './icon-app-secure-inactive.png';
import AppSecureActiveIcon from './icon-app-secure-active.png';

type Props = {
  active: boolean,
};

export default class AppSecureIcon extends PureComponent<Props> {
  render() {
    return (
      <BrandedProductIcon
        src={this.props.active ? AppSecureActiveIcon : AppSecureInactiveIcon}
      />
    );
  }
}
