//@flow
import { createSelector } from 'reselect';
import {
  findingFromParam,
  type FindingIdOrLocationParam,
} from './securityFindings';
import type { State } from '../ReduxStateType';
import { type MediawatchArticle } from '@datatheorem/user-api/mediawatch';

const mediawatchArticles = (state: State) => state.mediawatchArticles;

export const mediawatchArticlesForFindingParam = createSelector<
  State,
  FindingIdOrLocationParam,
  $ReadOnlyArray<MediawatchArticle>,
  _,
  _,
>(
  mediawatchArticles,
  findingFromParam,

  (articles, finding) =>
    articles &&
    articles.filter(
      article =>
        finding &&
        article.affected_mobile_apps_info &&
        article.affected_mobile_apps_info.filter(
          info =>
            info.security_finding_ids &&
            info.security_finding_ids.includes(finding && finding.id),
        ).length &&
        finding.affecting_mediawatch_article_ids &&
        finding.affecting_mediawatch_article_ids.includes(article.id),
    ),
);
