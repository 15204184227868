//@flow
import React, { PureComponent, type ElementRef } from 'react';
import { PageHeading } from '@datatheorem/components';
import { connect } from 'react-redux';
import Page from '../theme/Page';
import { Dropzone } from '@datatheorem/components';
import { type ContextRouter } from 'react-router-dom';

import { Paper, TextField, Button, Typography } from '@material-ui/core';
import { appFileSubmitted } from '../../actions';
import { palette } from '@datatheorem/theme';
import { compose } from 'recompose';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { type DispatchProps } from 'redux';

import { browserHistory } from './../../clients/history';

const styles = (mode: null | 'success' | 'error') => ({
  border: '2px dotted black',
  height: 200,
  width: '100%',
  textAlign: 'center',
  padding: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color:
    mode === 'error'
      ? palette.red10
      : mode === 'success'
      ? palette.green10
      : null,
  backgroundColor:
    mode === 'error'
      ? palette.red50
      : mode === 'success'
      ? palette.green50
      : 'none',
});

type OwnProps = {| ...ContextRouter |};

type StateProps = {|
  fileUploadResponse: boolean,
  fileUploadError: null | string,
|};

type Props = {|
  ...OwnProps,
  ...TrackingProps,
  ...StateProps,
  ...DispatchProps<>,
|};

type State = {|
  username: string,
  password: string,
  comments: string,
  file: ?File,
  uploading: boolean,
  success: boolean,
|};

export default compose(
  tracking(dataCreators.component(AnalyticsScreenEnum.SDLC_APP_UPLOAD)),
  connect<Props, {| ...OwnProps, ...TrackingProps |}, _, _, _, _>(state => ({
    fileUploadResponse: state.strings.fileUploadResponse,
    fileUploadError: state.strings.fileUploadError,
  })),
)(
  class AppUpload extends PureComponent<Props, State> {
    dropzone: {
      current: null | ElementRef<typeof Dropzone>,
    } = React.createRef();

    state = {
      username: '',
      password: '',
      comments: '',
      file: null,
      uploading: false,
      success: false,
    };

    componentDidUpdate(prevProps: Props) {
      if (this.props.fileUploadResponse && !prevProps.fileUploadResponse) {
        this.setState({ success: true, uploading: false, file: null });
      }

      if (this.props.fileUploadError && !prevProps.fileUploadError) {
        this.setState({ success: false, uploading: false, file: null });
      }

      if (!this.props.fileUploadResponse && prevProps.fileUploadResponse) {
        this.setState({ success: false });
      }
    }

    render() {
      const { username, password, comments, file, uploading } = this.state;

      return (
        <PageHeading
          backButton
          onClickBack={browserHistory.goBack}
          title="Upload App (Legacy)"
        >
          <Page>
            <Paper style={{ padding: 12 }}>
              <Typography variant="subtitle1" gutterBottom>
                Test credentials (for dynamic scans):
              </Typography>
              <TextField
                id="Username"
                fullWidth
                label="Username"
                placeholder="Enter username"
                variant="outlined"
                value={username}
                onChange={this.handleChangeUsername}
                style={{ paddingBottom: 16 }}
              />
              <TextField
                id="Password"
                fullWidth
                label="Password"
                value={password}
                placeholder="Enter password"
                variant="outlined"
                type="password"
                onChange={this.handleChangePassword}
                style={{ paddingBottom: 16 }}
              />
              <TextField
                fullWidth
                multiline
                label="Comments"
                placeholder="Additional information about steps needed to use test credentials"
                variant="outlined"
                value={comments}
                onChange={this.handleChangeComments}
                style={{ paddingBottom: 16 }}
              />

              <Dropzone onDropFile={this.handleDropFile} ref={this.dropzone}>
                {this.renderMessageZone()}
              </Dropzone>

              <div
                style={{
                  marginTop: 16,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="contained"
                  color={file ? 'default' : 'primary'}
                  onClick={this.handleSelectClick}
                >
                  Select File
                </Button>
                {file && !uploading && (
                  <Button
                    style={{ marginLeft: 16 }}
                    color="primary"
                    variant="contained"
                    onClick={this.handleUploadClicked}
                  >
                    Upload
                  </Button>
                )}
              </div>
            </Paper>
          </Page>
        </PageHeading>
      );
    }

    renderMessageZone = () => {
      const { file, uploading, success } = this.state;
      const { fileUploadError } = this.props;

      const message = () => {
        if (fileUploadError) {
          return `There was an error: ${fileUploadError}`;
        } else if (success) {
          return 'Upload is successful and results will be published shortly. If you do not see the new app added within a few minutes, please contact your admin to get access to the results';
        } else if (uploading) {
          return 'Uploading';
        } else if (file) {
          return file.name;
        } else {
          return 'Drag and drop a mobile app file';
        }
      };

      return (
        <div
          style={styles(fileUploadError ? 'error' : success ? 'success' : null)}
        >
          {message()}
        </div>
      );
    };

    handleSelectClick = () =>
      this.dropzone.current && this.dropzone.current.open();

    handleChangeUsername = (e: SyntheticInputEvent<HTMLInputElement>) =>
      this.setState({ username: e.currentTarget.value, success: false });
    handleChangePassword = (e: SyntheticInputEvent<HTMLInputElement>) =>
      this.setState({ password: e.currentTarget.value, success: false });
    handleChangeComments = (e: SyntheticInputEvent<HTMLInputElement>) =>
      this.setState({ comments: e.currentTarget.value, success: false });

    handleDropFile = (file: File) =>
      this.setState({ file: file, success: false });

    handleUploadClicked = () => {
      const { file, username, password, comments } = this.state;
      // $FlowFixMe Flow85
      this.props.dispatch(appFileSubmitted(file, username, password, comments));
      this.setState({
        uploading: true,
        username: '',
        password: '',
        comments: '',
      });
    };
  },
);
