//@flow
import React, { PureComponent } from 'react';
import {
  isTargetClosed,
  sortTargets,
} from '@datatheorem/findings/targets/status';
import {
  isSecurityP1,
  isComplianceIssue,
} from '@datatheorem/findings/findingsFilterCreator';
import {
  getCompletedAppProtectionBadgeKeyByBadgeTitle,
  getIncompleteAppProtectionBadgeKeyByBadgeTitle,
} from '@datatheorem/components';
import { palette } from '@datatheorem/theme';
import { startCase } from 'lodash/fp';
import { dataCreators } from '@datatheorem/analytics';
import { browserHistory } from '../../clients/history';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { type UserRoleEnum } from '@datatheorem/enums/UserRoleEnum';

import { type SecurityFinding, type Target } from '@datatheorem/findings/types';
import type { Application } from '@datatheorem/user-api/mobile_apps';
import type { MediawatchArticle } from '@datatheorem/user-api/mediawatch';
import type { FindingPriorityEnum as FindingPriorityEnumType } from '@datatheorem/enums/FindingPriorityEnum';
import tracking, { type TrackingProps } from 'react-tracking';

import {
  getBadgeDescriptionFromBadgeType,
  PriorityBadge,
  ProtectionBadge,
  LoadingFiller,
} from '@datatheorem/components';
import {
  DialogForDetails,
  DialogSection,
  DialogTitle,
  Screenshot,
  CompliancePolicyReferencesCardList,
} from '@datatheorem/components/src/security-finding-dialog/';
import Targets from '../theme/Targets';
import MediawatchArticlesForFinding from '../theme/MediawatchArticlesForFinding';
import ChangePriority from './ChangePriority';
import Comments from './Comments';
import ExternalIdCreationErrorMessage from './ExternalIdCreationError';
import SvgIcon from '@material-ui/core/SvgIcon';
import MediawatchIcon from '@material-ui/icons/Whatshot';
import CompliancePolicyBanner from '@datatheorem/components/src/compliance/CompliancePolicyBanner';

type OwnProps = {|
  finding: SecurityFinding,
  app: Application,
  articles: $ReadOnlyArray<MediawatchArticle>,
  onClose: () => void,
  onClickStatus: ?(Target, SyntheticEvent<HTMLElement>) => void,
  onChangePriority: (priority: FindingPriorityEnumType) => void,
  onAddNewNote: (note: string, finding: SecurityFinding) => void,
  newCommentIsLoading?: ?boolean,
  instructionalMode?: boolean,
  loadingTargetStatusIds?: $ReadOnlyArray<string>,
  loadingTargetExternalIds?: $ReadOnlyArray<string>,
  onClickExportTarget?: (Target, MouseEvent) => void,
  userRole: UserRoleEnum | null,
  externalIdCreationError: string | null,
  mediawatchError: string | null,
  mediawatchIsLoading: boolean,
|};

type Props = {| ...OwnProps, ...TrackingProps |};

export default tracking(
  ({ finding }) =>
    !!finding &&
    dataCreators.component(AnalyticsScreenEnum.SECURITY_FINDING_DIALOG),
)(
  class SecurityFindingDialog extends PureComponent<Props> {
    render() {
      const {
        finding,
        app,
        onClose,
        onClickStatus,
        onChangePriority,
        articles,
        newCommentIsLoading,
        instructionalMode,
        loadingTargetStatusIds,
        loadingTargetExternalIds,
        userRole,
        onClickExportTarget,
        externalIdCreationError,
        mediawatchError,
        mediawatchIsLoading,
      } = this.props;

      if (!finding) {
        return null;
      }

      const {
        title,
        id,
        description,
        recommendation,
        notes,
        priority,
        description_intro,
        severity,
        targets,
        screenshot_url,
        secure_code,
        compliance_policy_references,
      } = finding;

      const { name } = app;

      const totalTargets = targets ? targets.length : 0;
      const fixedTargets = targets ? targets.filter(isTargetClosed) : [];
      const totalFixedTargets = fixedTargets.length;

      const hasComplianceImplications = isComplianceIssue(finding);

      // App Protection
      const completedAppProtectionBadgeKey = getCompletedAppProtectionBadgeKeyByBadgeTitle(
        title,
        app,
      );
      const incompleteAppProtectionBadgeKey = getIncompleteAppProtectionBadgeKeyByBadgeTitle(
        title,
        app,
      );
      const badgeType =
        completedAppProtectionBadgeKey || incompleteAppProtectionBadgeKey;
      const badgeDescription = badgeType
        ? getBadgeDescriptionFromBadgeType(badgeType)
        : null;

      return (
        <div>
          <DialogForDetails
            onClose={onClose}
            header={[
              <div key={1} style={{ display: 'flex', alignItems: 'center' }}>
                {/* Priority */}
                {!instructionalMode && (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    {priority ? (
                      <PriorityBadge invertedColors type={priority} />
                    ) : (
                      <span style={{ verticalAlign: 'super' }}>
                        No Priority
                      </span>
                    )}
                    <ChangePriority
                      color={palette.white}
                      useSecurityP1PriorityOptions={isSecurityP1(finding)}
                      onChangePriority={onChangePriority}
                    />
                  </span>
                )}

                {/* Compliance Policy Badges */}
                {hasComplianceImplications ? (
                  <CompliancePolicyBanner
                    compliance_policy_references={
                      compliance_policy_references || []
                    }
                  />
                ) : null}
              </div>,

              <div key={2}>
                {/* Name */}
                {name} #{id}
              </div>,

              <div key={3}>
                {/* Severity */}
                <span>
                  {!instructionalMode &&
                    severity &&
                    startCase(severity.toLowerCase())}
                </span>
              </div>,
            ]}
          >
            <DialogTitle>{title}</DialogTitle>

            {badgeType && (
              <DialogSection>
                <div
                  onClick={event => {
                    event.preventDefault(); // ghost click \o/
                    browserHistory.push(`/app/${app.id}/protection`);
                  }}
                >
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      cursor: 'pointer',
                    }}
                  >
                    <ProtectionBadge
                      type={badgeType}
                      enabled={!!completedAppProtectionBadgeKey}
                    />
                  </div>
                  <div
                    style={{
                      float: 'right',
                      marginTop: '-120px',
                    }}
                  >
                    <h4>{badgeDescription}</h4>
                    <div style={{ marginBottom: 10, fontSize: 13 }}>
                      1 incomplete finding
                    </div>
                    <div style={{ marginBottom: 10, fontSize: 13 }}>
                      Fixing all targets for this finding will increase the app
                      protection score by{' '}
                      <span style={{ color: palette.green30 }}>
                        {finding.app_protection_score} point
                        {finding.app_protection_score !== '0' ||
                        finding.app_protection_score !== '1'
                          ? 's'
                          : ''}
                      </span>
                    </div>
                  </div>
                </div>
              </DialogSection>
            )}

            {instructionalMode && (
              <DialogSection title="Recommendation" markdown={recommendation} />
            )}
            <ExternalIdCreationErrorMessage
              externalIdCreationError={externalIdCreationError}
              userRole={userRole}
            />
            <DialogSection
              title="Affected Components"
              subtitle={
                totalTargets > 1
                  ? `${totalFixedTargets} fixed out of ${totalTargets}`
                  : null
              }
              markdown={description_intro}
              backgroundColor={palette.accent}
            />
            {targets && (
              <Targets
                targets={targets.slice().sort(sortTargets)}
                onClickStatus={onClickStatus}
                loadingTargetStatusIds={loadingTargetStatusIds}
                loadingTargetExternalIds={loadingTargetExternalIds}
                onClickExportTarget={onClickExportTarget}
              />
            )}
            <DialogSection title="Description" markdown={description} />
            {hasComplianceImplications && (
              <DialogSection
                title={
                  <div>
                    <h3>Regulatory Compliance</h3>
                    <h4>
                      This issue is out of compliance with the following laws,
                      policies, and standards:
                    </h4>
                  </div>
                }
              >
                <CompliancePolicyReferencesCardList
                  references={compliance_policy_references}
                />
              </DialogSection>
            )}
            {screenshot_url && (
              <DialogSection title="Screenshot">
                <Screenshot src={screenshot_url} />
              </DialogSection>
            )}
            {!instructionalMode && (
              <DialogSection title="Recommendation" markdown={recommendation} />
            )}
            {secure_code && (
              <DialogSection title="Secure Code" markdown={secure_code} />
            )}
            {mediawatchIsLoading && <LoadingFiller />}
            {mediawatchError && (
              <div>Could not fetch Mediawatch articles: {mediawatchError}</div>
            )}
            {articles && articles.length > 0 && (
              <DialogSection
                title={
                  <span>
                    <h3 style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <SvgIcon>
                        <title>Media Watch</title>
                        <MediawatchIcon />
                      </SvgIcon>
                      MediaWatch
                    </h3>
                    <h4>
                      Public media articles of other companies who are
                      vulnerable to this issue
                    </h4>
                  </span>
                }
              >
                <MediawatchArticlesForFinding articles={articles} />
              </DialogSection>
            )}
            <DialogSection title={`Questions & Comments`}>
              <Comments
                notes={notes}
                onAddNewNote={this.handleAddNewNote}
                newCommentIsLoading={newCommentIsLoading}
              />
            </DialogSection>
          </DialogForDetails>
        </div>
      );
    }

    handleAddNewNote = (note: string) => {
      const { finding, onAddNewNote } = this.props;
      onAddNewNote(note, finding);
    };
  },
);
