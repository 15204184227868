//@flow
import React, { PureComponent, type Node } from 'react';
import ExpansionPanelItem from '../theme/ExpansionPanelItem';
import TextField from '@material-ui/core/TextField';
import { type FieldProps } from 'formik';

type Props = {|
  label: string,
  children: Node,
  inputDisabled: boolean,
  setFieldValue: (value: mixed) => mixed,
  placeholder: ?string,
  fieldType?: 'text' | 'number',
  errorText: ?string,
  ...FieldProps,
|};

export default class PanelItemTextField extends PureComponent<Props> {
  render() {
    const { label, children } = this.props;
    return (
      <ExpansionPanelItem
        disabled
        primaryText={label}
        value={this.renderValue(this.props)}
      >
        {children}
      </ExpansionPanelItem>
    );
  }

  renderValue = ({
    form,
    field,
    placeholder,
    fieldType,
    inputDisabled: disabled,
  }: Props) => (
    <TextField
      {...field}
      value={field.value ? field.value : ''}
      disabled={disabled}
      fullWidth
      type={fieldType}
      placeholder={placeholder ? placeholder : void 0}
      error={form.errors[field.name]}
      helperText={
        field.name && form.touched[field.name] && form.errors[field.name]
      }
    />
  );
}
