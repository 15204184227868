//@flow
import { type Node, memo } from 'react';
import { type User } from '@datatheorem/user-api/users';
import tracking from 'react-tracking';
import type { AccountInfo } from '@datatheorem/user-api/account_info';

type Props = {|
  accountInfo: AccountInfo,
  currentUser: User,
  children: Node,
|};

const DATATHEOREM_USER_DOMAINS = [
  'datatheorem.com',
  'sourcetheorem.com',
  'biztheorem.com',
];

const DATATHEOREM_CUSTOMER_NAMES = ['Data Theorem', 'SideScreen'];

function getCustomerName({ currentUser, accountInfo }) {
  if (
    currentUser.login_email &&
    currentUser.login_email.match(
      `@(${DATATHEOREM_USER_DOMAINS.join('|')})$`,
    ) &&
    !DATATHEOREM_CUSTOMER_NAMES.includes(accountInfo.name)
  ) {
    return '_DT_';
  } else {
    return accountInfo.name;
  }
}

export default memo<Props>(
  tracking((props: Props) => ({
    customer_name: getCustomerName(props),
  }))(function CustomerNameContext({ children }) {
    return children;
  }),
);
