//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { PageHeading } from '@datatheorem/components';
import type { ContextRouter } from 'react-router-dom';
import { apiKeyCreateFormSubmitted } from '../../actions';
import CreateForm from '../api-keys/CreateForm';
import { sortedApps } from '../../selectors/apps';
import { APIKeySchema } from '@datatheorem/user-api/api_keys';
import type { Application } from '@datatheorem/user-api/mobile_apps';
import { compose } from 'recompose';
import { withFormik, type FormikProps } from 'formik';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { type DispatchProps } from 'redux';
import { browserHistory } from './../../clients/history';
import type { State as ReduxState } from '../../ReduxStateType';

type OwnProps = ContextRouter;

type StateProps = {|
  apps: $ReadOnlyArray<Application>,
  loading: mixed,
  formError: mixed,
|};
type FormikValues = {|
  name: string,
  key?: ?string,
  results_api: {
    access: Boolean,
    can_access_all_apps: Boolean,
    can_access_app_ids: $ReadOnlyArray<string>,
  },
  management_api: {
    access: Boolean,
  },
  id: string,
|};

type Props = {|
  ...OwnProps,
  ...TrackingProps,
  ...StateProps,
  ...DispatchProps<>,
  ...FormikProps<FormikValues>,
  dirty: boolean,
|};

type IntermediatePropsFirst = {| ...OwnProps, ...TrackingProps |};

type IntermediatePropsSecond = {|
  ...OwnProps,
  ...TrackingProps,
  ...StateProps,
  ...DispatchProps<>,
|};

const emptyAPIKey = {
  name: '',
  results_api: {
    access: false,
    can_access_all_apps: false,
    can_access_app_ids: [],
  },
  management_api: {
    access: false,
  },
  id: '',
};

export class APIKeyCreate extends PureComponent<Props> {
  render() {
    const {
      apps,
      handleSubmit,
      setFieldValue,
      values,
      errors,
      loading,
      formError,
      dirty,
    } = this.props;

    return (
      <PageHeading
        backButton
        onClickBack={browserHistory.goBack}
        title="Create API Key"
      >
        {/* $FlowFixMe */}
        <CreateForm
          onSubmit={handleSubmit}
          apps={apps}
          formError={
            formError && typeof formError._error === 'string'
              ? formError._error
              : null
          }
          values={values}
          dirty={!!dirty}
          errors={errors}
          setFieldValue={setFieldValue}
          loading={!!loading}
          submitLabel="Create API Key"
        />
      </PageHeading>
    );
  }
}

export default compose(
  tracking(dataCreators.component(AnalyticsScreenEnum.API_KEY_CREATE)),
  connect<
    IntermediatePropsSecond,
    IntermediatePropsFirst,
    StateProps,
    DispatchProps<>,
    ReduxState,
    _,
  >(
    (state: ReduxState, props): StateProps => ({
      apps: sortedApps(state, props),
      loading: state.loading.apiKeyCreate,
      formError: state.errors.apiKeyCreate,
    }),
  ),
  //$FlowFixMe Flow85
  withFormik({
    handleSubmit(values, { props, resetForm, setSubmitting }) {
      props.dispatch(apiKeyCreateFormSubmitted(values));
      /* if there is an error use current values as basis for the resetted form */
      resetForm(values);
      setSubmitting(false);
    },
    mapPropsToValues() {
      return emptyAPIKey;
    },
    validationSchema: APIKeySchema,
  }),
)(APIKeyCreate);
