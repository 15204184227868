//@flow
import React, { PureComponent } from 'react';
import AsyncContainer from '../hoc/AsyncContainer';
import { type MobileApplicationChildProps } from '../../types/MobileApplicationChildProps';
import { tryAndParseErrorMessage } from '@datatheorem/user-api/util';

export default class MobileApplicationTrustKitContainer extends PureComponent<
  MobileApplicationChildProps,
> {
  render() {
    return (
      <AsyncContainer async={this.loadTrustKit}>
        {({ value: Component, error, loading }) => {
          if (loading) {
            return <p>Loading...</p>;
          } else if (error) {
            return <p>{tryAndParseErrorMessage(error)}</p>;
          } else if (Component) {
            return <Component {...this.props} />;
          } else {
            return <p>Something went wrong.</p>;
          }
        }}
      </AsyncContainer>
    );
  }

  loadTrustKit = async () =>
    await import('./MobileApplicationTrustKit').then(m => m.default);
}
