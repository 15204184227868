//@flow
/*
 * Component to display all Jira configurations
 */

import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import { type Application } from '@datatheorem/user-api/mobile_apps';
import {
  Avatar,
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { palette } from '@datatheorem/theme';
import { Button, PlatformIcon } from '@datatheorem/components';
import type { DispatchProps } from 'redux';
import { jiraIntegrationConfigureClick } from '../../../../actions';
import type { ConfigurationList } from '../../../../reducers/jiraIntegration';

type OwnProps = {|
  configurations: ConfigurationList,
  ...DispatchProps<>,
|};
type Props = {|
  ...OwnProps,
  ...InjectedJSS<typeof styles>,
|};

type State = {};

const styles = {
  icon: {
    justifySelf: 'center',
    alignSelf: 'center',
  },
  noWrapItem: {
    alignSelf: 'center',
    whiteSpace: 'nowrap',
  },
  circleBlue: {
    backgroundColor: palette.blue,
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'text-bottom',
  },
  circleGreen: {
    backgroundColor: palette.green,
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    display: 'inline-block',
    verticalAlign: 'text-bottom',
  },
  card: {
    margin: '24px auto',
    minWidth: 570,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableCell: {
    paddingRight: '16px !important',
  },
};

const getAvatarToShow = (app: Application) => {
  if (!app.icon_url) return null;
  return (
    <Avatar
      src={app.icon_url}
      style={{
        backgroundColor: palette.white,
        height: 24,
        width: 24,
        marginLeft: 8,
      }}
    />
  );
};

export class JiraIntegrationTable extends PureComponent<Props, State> {
  render() {
    const { classes, configurations, dispatch } = this.props;

    return (
      <Card className={classes.card}>
        <div className={classes.tableWrapper}>
          <Table padding="dense">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Name</TableCell>
                <TableCell className={classes.tableCell}>
                  Active Jira Configuration
                </TableCell>
                <TableCell className={classes.tableCell} align="right">
                  Edit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {configurations.map(({ app, isGlobal }) => (
                <TableRow key={app.id}>
                  <TableCell className={classes.tableCell}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <PlatformIcon
                        platform={app.platform}
                        style={{ height: '16px' }}
                      />
                      {app.name}
                      {getAvatarToShow(app)}
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.noWrapItem}>
                      {isGlobal ? (
                        <div>
                          <span className={classes.circleBlue} /> Global Config
                        </div>
                      ) : (
                        <div>
                          <span className={classes.circleGreen} /> App Specific
                        </div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    <Button
                      ariaLabel={`Configure ${app.name} Jira Integration`}
                      onClick={() =>
                        dispatch(jiraIntegrationConfigureClick(app.id))
                      }
                    >
                      Configure
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Card>
    );
  }
}

export default React.memo<OwnProps>(jss(styles)(JiraIntegrationTable));
