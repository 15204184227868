//@flow
import React, { PureComponent, Fragment } from 'react';
import PanelItemCopyableField from '../form/PanelItemCopyableField';
import EditIcon from '@material-ui/icons/Edit';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import type { APIKey } from '@datatheorem/user-api/api_keys';

type Props = {
  upload_api_key: string,
  apiKeys: $ReadOnlyArray<APIKey>,
  onClickCreateAPIKeyButton: () => void,
  onClickAPIKey: APIKey => void,
  userHasManagerRole: boolean,
};

const footerLinks = [
  {
    label: 'API Documentation',
    href: 'https://datatheorem.github.io/PortalApi/',
  },
  {
    label: 'PreProd API Client',
    href: 'https://bitbucket.org/datatheorem/dt-api-client/src/master/',
  },
  {
    label: 'Jenkins Plugin',
    href:
      'https://datatheorem.atlassian.net/servicedesk/customer/kb/view/32079873',
  },
  {
    label: 'ScrewDriver CD',
    href:
      'https://datatheorem.atlassian.net/servicedesk/customer/kb/view/49119233',
  },
];

export default class APIKeysPanels extends PureComponent<Props> {
  render() {
    const {
      upload_api_key,
      apiKeys,
      onClickAPIKey,
      onClickCreateAPIKeyButton,
      userHasManagerRole,
    } = this.props;

    const listItems = apiKeys
      .slice()
      .sort((a: APIKey, b: APIKey) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        if (aName > bName) return 1;
        if (aName < bName) return -1;
        return 0;
      })
      .map(apiKey => (
        <ListItem button key={apiKey.id} onClick={() => onClickAPIKey(apiKey)}>
          <ListItemIcon>
            <EditIcon color="primary" />
          </ListItemIcon>
          <ListItemText>{apiKey.name}</ListItemText>
        </ListItem>
      ));

    const primaryAction = {
      label: 'Create API Key',
      icon: <AddBoxIcon style={{ marginRight: 4 }} />,
      onClick: onClickCreateAPIKeyButton,
    };

    return (
      <>
        <h2>Uploading Applications</h2>
        <p>
          The Upload API allows uploading mobile apps for scanning. Refer to the{' '}
          <a
            href="https://datatheorem.github.io/PortalApi/upload_api.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Upload API Documentation
          </a>{' '}
          for more details.
        </p>
        <PanelItemCopyableField label="Upload API Key" value={upload_api_key} />

        <h2>
          Accessing Data
          {userHasManagerRole && (
            <Button
              style={{ marginLeft: 8 }}
              color="primary"
              onClick={primaryAction.onClick}
            >
              {primaryAction.icon}
              {primaryAction.label}
            </Button>
          )}
        </h2>
        <p>
          The Results API allows access to scan results. Refer to the{' '}
          <a
            href="https://datatheorem.github.io/PortalApi/results_api.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Results API Documentation
          </a>{' '}
          for more details.
        </p>
        {userHasManagerRole ? (
          listItems.length > 0 ? (
            <List>{listItems}</List>
          ) : (
            <p>
              You currently do not have any API keys.
              <br />
              Click on the &quot;Create API Key&quot; button to get started.
            </p>
          )
        ) : (
          <p>
            Please contact a manager to receive an API key to access your
            organization&apos;s data
          </p>
        )}
        {
          <>
            <h2>Integration Options</h2>
            <div style={{ marginLeft: 17 }}>
              {footerLinks.map(({ label, href }) => (
                <Fragment key={href}>
                  <a href={href} target="_blank" rel="noopener noreferrer">
                    {label}
                  </a>
                  <br />
                </Fragment>
              ))}
            </div>
          </>
        }
      </>
    );
  }
}
