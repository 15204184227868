//@flow
import React, { useEffect, useMemo, useCallback } from 'react';

import injectSheet, { type InjectedJSS } from 'react-jss';
import { PageHeading, Tooltip } from '@datatheorem/components';
import { connect } from 'react-redux';
import {
  type NotificationPreferences,
  type NotificationPreferencesEmailSchema,
} from '@datatheorem/user-api/current_user';

import { compose } from 'recompose';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { type DispatchProps } from 'redux';
import type { ContextRouter } from 'react-router-dom';
import { browserHistory } from './../../clients/history';
import { type State as ReduxStateType } from '../../ReduxStateType';
import { type ActionType } from 'redux-actions';
import {
  getNotificationPreferences,
  patchNotificationPreferences,
} from '../../sagas/preferences';

import { Checkbox, Switch, FormControlLabel } from '@material-ui/core';
import { palette } from '@datatheorem/theme';
import UserRole, { type UserRoleEnum } from '@datatheorem/enums/UserRoleEnum';
import notificationTypeToHumanHelp, {
  type PreferencesEmailDecoration,
} from '../../util/preferencesNotificationsToReadable';

type DP = DispatchProps<
  | ActionType<typeof getNotificationPreferences>
  | ActionType<typeof patchNotificationPreferences>,
>;

type OwnProps = ContextRouter;

type StateProps = {|
  notification: ?NotificationPreferences,
  userRole: ?UserRoleEnum,
|};

type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DP,
  ...TrackingProps,
  ...InjectedJSS<typeof styles>,
|};

type IntermediateProps = {| ...OwnProps, ...StateProps, ...DP |};

const styles = {
  notification: {
    '& section': {
      display: 'flex',
      // This is a trick so the background color can ignore the margin set by parent container
      marginLeft: -40,
      marginRight: -40,
      padding: '10px 40px',
      alignItems: 'center',

      background: palette.gray50,
      '&:nth-child(odd)': {
        background: 'transparent',
      },
    },
  },
  notificationLabel: {
    width: 380,
    paddingRight: 15,
    flexShrink: 0,
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& div': {
      verticalAlign: 'middle',
      fontWeight: '400',
      color: palette.gray20,
      fontSize: 16,
    },
    '& summary': {
      fontSize: 14,
      margin: '5px 0',
      color: palette.gray35,
    },
  },
  notificationInput: {
    flexShrink: 0,
    flexGrow: 0,
    whiteSpace: 'pre-wrap',
    padding: 5,
    display: 'flex',
  },
  legend: {
    fontWeight: 500,
    fontSize: 14,

    color: palette.gray30,
    margin: 0,
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: 0.15,
    margin: '10px 0 20px',
    color: palette.gray20,
  },
};

export default compose(
  connect<IntermediateProps, OwnProps, _, _, _, _>(
    (state: ReduxStateType): StateProps => ({
      notification: state.preferences.notification,
      userRole: state.currentUser && state.currentUser.role,
    }),
  ),
  injectSheet(styles),
  tracking(dataCreators.component(AnalyticsScreenEnum.PREFERENCES)),
)(function Preferences(props: Props) {
  const { dispatch, notification, classes, userRole } = props;
  useEffect(() => {
    dispatch(getNotificationPreferences());
  }, [dispatch]);

  const notifications_entries_sorted: Array<{|
    key: string,
    value: NotificationPreferencesEmailSchema,
    ...PreferencesEmailDecoration,
  |}> = useMemo(() => {
    if (!notification) return [];
    const results = [];

    notificationTypeToHumanHelp.forEach((info, key) => {
      const value: void | NotificationPreferencesEmailSchema =
        notification.app_secure[key];
      if (!value) return;
      results.push({
        key: key,
        ...info,
        value,
      });
    });

    return results;
  }, [notification]);

  const changeNotificationValue = useCallback(
    (key: string, patchValue: NotificationPreferencesEmailSchema) => {
      dispatch(
        patchNotificationPreferences({
          app_secure: {
            // Even if invalid key is being provided, backend won't fail but no change will be made
            [key]: patchValue,
          },
        }),
      );
    },
    [dispatch],
  );

  const renderNotificationSettings = settings => {
    const isDisabledBecauseNotManager =
      settings.managerOnly && userRole !== UserRole.MANAGER;

    return (
      <section
        key={settings.key}
        aria-labelledby={settings.key}
        aria-describedby={settings.key + 'description'}
      >
        <div className={classes.notificationLabel} id={settings.key}>
          <div id={settings.key}>{settings.title}</div>
          <summary id={settings.key + 'description'}>
            {settings.description}
          </summary>
        </div>
        <div className={classes.notificationInput}>
          {settings.managerOnly && typeof settings.value.email === 'boolean' ? (
            <Tooltip
              label={isDisabledBecauseNotManager ? 'Managers only' : null}
            >
              <Switch
                data-testid={`${settings.key}_switch`}
                id={`${settings.key}_switch`}
                color="primary"
                checked={settings.value.email}
                aria-label={`toggle`}
                onChange={() =>
                  changeNotificationValue(settings.key, {
                    email: !settings.value.email,
                  })
                }
                disabled={isDisabledBecauseNotManager}
              />
            </Tooltip>
          ) : (
            ['real_time', 'daily', 'weekly', 'never'].map(frequency => {
              let isRealTimeOnly = false;
              let isActive = false;

              // If a preferences is not manager, and have a boolean only (and not an object)
              // then it can only be real time
              if (typeof settings.value.email === 'boolean') {
                isRealTimeOnly = true;
                // For everything else that is not real time, don't render anything
                if (frequency !== 'real_time') return null;
                isActive = settings.value.email;
              } else {
                // "Never" is not actually a real frequency, but the uncheck of everything else
                if (frequency === 'never') {
                  isActive =
                    !settings.value.email.real_time &&
                    !settings.value.email.daily &&
                    !settings.value.email.weekly;
                } else {
                  // If it's not never, it's a valid frequency (real_time/daily/weekly)
                  isActive = settings.value.email[frequency];
                }
              }

              const onClick = () => {
                changeNotificationValue(settings.key, {
                  email: isRealTimeOnly
                    ? !isActive
                    : frequency === 'never'
                    ? {
                        // "Never" in reality is just everything false
                        real_time: false,
                        daily: false,
                        weekly: false,
                      }
                    : {
                        [frequency]: !isActive,
                      },
                });
              };
              const label =
                settings.key === 'monthly_stats'
                  ? 'Enabled'
                  : frequency.replace(/_/, ' ');

              return (
                <div key={frequency} style={{ margin: '0 10px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={onClick}
                        checked={isActive}
                      />
                    }
                    style={{
                      color: palette.gray30,
                      fontSize: 14,
                      marginLeft: -10,
                      whiteSpace: 'nowrap',
                      textTransform: 'capitalize',
                    }}
                    label={label}
                  />
                </div>
              );
            })
          )}
        </div>
      </section>
    );
  };

  return (
    <PageHeading
      backButton
      onClickBack={browserHistory.goBack}
      title="Preferences"
    >
      <div style={{ margin: 40 }}>
        <h2 className={classes.title}>Alerts</h2>
        <div className={classes.notification}>
          <section>
            <div className={classes.notificationLabel}>
              <h5 className={classes.legend}>Subject</h5>
            </div>
            <div className={classes.notificationInput}>
              <h5 className={classes.legend}>When to send an alert</h5>
            </div>
          </section>
          {!notifications_entries_sorted.filter(ns => !ns.managerOnly)
            .length ? (
            <i>No alert settings found</i>
          ) : (
            notifications_entries_sorted
              .filter(ns => !ns.managerOnly)
              .map(renderNotificationSettings)
          )}
        </div>
      </div>
      <div style={{ margin: 40 }}>
        <h2 className={classes.title}>Manager Alerts</h2>
        <div className={classes.notification}>
          <section>
            <div className={classes.notificationLabel}>
              <h5 className={classes.legend}>Subject</h5>
            </div>
            <div className={classes.notificationInput}>
              <h5 className={classes.legend}>Enabled</h5>
            </div>
          </section>
          {!notifications_entries_sorted.filter(l => l.managerOnly).length ? (
            <i>No manager alert settings found</i>
          ) : (
            notifications_entries_sorted
              .filter(l => l.managerOnly)
              .map(renderNotificationSettings)
          )}
        </div>
      </div>
    </PageHeading>
  );
});
