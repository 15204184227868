//@flow
import React, { PureComponent, type ComponentType } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, type ContextRouter } from 'react-router-dom';
import { LHSMenu } from '@datatheorem/components';
import Menu from '../navigation/Menu';
import Issues from './Issues';
import AppDashboard from './../../components/main/AppDashboard/index';
import SDKIssues from './SDKIssues';
import MobileApplication from './MobileApplication';
import {
  appsByPlatform,
  priorityAlertsCount,
  type AppsByPlatform,
} from '../../selectors/apps';
import Search from './Search';
import Reports from './Reports';
import { type DispatchProps } from 'redux';
import { type DashboardInjectedProps } from '../../types/DashboardInjectedProps';
import type { State as ReduxState } from '../../ReduxStateType';
import { type User } from '@datatheorem/user-api/users';

type OwnProps = {|
  showActivity: boolean,
  currentUser: User,
  ...ContextRouter,
|};

type StateProps = {|
  appsByPlatform: AppsByPlatform,
  priorityCount: number,
  loadedIntialApps: boolean,
|};

type Props = {| ...OwnProps, ...StateProps, ...DispatchProps<> |};

type State = {};

export default connect<Props, OwnProps, _, _, _, _>(
  (state: ReduxState, props): StateProps => ({
    appsByPlatform: appsByPlatform(state, props),
    priorityCount: priorityAlertsCount(state, props),
    loadedIntialApps: state.firstRequest.apps,
  }),
)(
  class Dashboard extends PureComponent<Props, State> {
    render() {
      const {
        appsByPlatform,
        priorityCount,
        loadedIntialApps,
        currentUser,
      } = this.props;

      const renderWithScrollProps = (
        Component: ComponentType<DashboardInjectedProps>,
      ) => (routerProps: ContextRouter) => (
        <Component {...routerProps} currentUser={currentUser} />
      );

      const menu = (
        <Menu
          appsByPlatform={appsByPlatform}
          priorityCount={priorityCount}
          filler={!loadedIntialApps}
        />
      );

      return (
        <div>
          <LHSMenu menu={menu}>
            <Switch>
              <Route
                path="/sdk-issues/:category?/:sdk?"
                render={renderWithScrollProps(SDKIssues)}
              />
              <Route
                path="/issues/:category?/:findingId?"
                render={renderWithScrollProps(Issues)}
              />
              <Route path="/reports" render={renderWithScrollProps(Reports)} />
              <Route
                path="/app/:appId"
                render={renderWithScrollProps(MobileApplication)}
              />
              <Route
                path="/search/:search?/:findingId?"
                render={renderWithScrollProps(Search)}
              />
              <Route
                path="/apps"
                render={renderWithScrollProps(AppDashboard)}
              />
              <Route render={() => <Redirect to="/issues/priority" />} />
            </Switch>
          </LHSMenu>
        </div>
      );
    }
  },
);
