//@flow
import isAfter from 'date-fns/is_after';
import parse from 'date-fns/parse';
import isToday from 'date-fns/is_today';
import isThisYear from 'date-fns/is_this_year';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import format from 'date-fns/format';

/**
 * So date handling in sevenhell is problematic… this has probably led to a number of incorrect outputs in the portal. An ISO8601 string with no timezone is considered local, so it is a mistake by Endpoints to not provide TZ. It’s made worse, however, by inconsistencies in the API. Some return the timezone, some do not. I can’t pass this string to a date library because I can’t tweak its assumptions about ISO 8601. So I have to parse the string, see if it has a timezone, and append `Z` if it does not. I *should* be using a proper ISO8601 parser for this, but since that could be expensive in bytes, I’m going to use a regex, which I hate. :goberserk:
 *
 * NOTE: The 21st capture group is the one we care about here. It contains nothing if there's no timezone in the string, or a Z, or the +/-dd:dd offset.
 * @type {RegExp}
 */
const ISO8601Regexp = /^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([.,]\d+(?!:))?)?(\17[0-5]\d([.,]\d+)?)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

export function sortObjectsByDateProperty<T: {}>(
  propertyName: string = 'date',
  a: ?T,
  b: ?T,
) {
  if (!a && !b) {
    return 0;
  } else if (!a) {
    return 1;
  } else if (!b) {
    return -1;
  }

  if (isAfter(a[propertyName], b[propertyName])) {
    return -1;
  } else {
    return 1;
  }
}

/**
 * See above for an explanation about why we do this.
 * @param iso8601string
 * @return {string}
 * @constructor
 */
export function ISO8601UTCStringCast(iso8601string: string): string {
  const match = ISO8601Regexp.exec(iso8601string);

  // If we can't figure out what the string is, or if it already has a timezone... just return it
  if (!match || match[21]) {
    return iso8601string;
  }

  // Otherwise, append a Z which indicated UTC
  return iso8601string + 'Z';
}

type FormatOptions = { short?: ?string, long?: ?string };

export function formattedFromNow(formats: FormatOptions = {}) {
  const { short, long } = formats;
  return function(date: string): string {
    const dateParsed = parse(ISO8601UTCStringCast(date));
    if (isToday(dateParsed)) {
      return distanceInWordsToNow(dateParsed, {
        includeSeconds: true,
        addSuffix: true,
      });
    } else if (isThisYear(dateParsed)) {
      return format(dateParsed, short || 'MMM D');
    } else {
      return format(dateParsed, long || 'YYYY-MM-DD');
    }
  };
}

export const fromNow = formattedFromNow();

export const fromNowTime = formattedFromNow({
  long: 'YYYY-MM-DD h:mm a',
  short: 'MMM D h:mm a',
});
