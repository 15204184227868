//@flow
import {
  type Node,
  useEffect,
  useState,
  useCallback,
  useRef,
  memo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { paginationMountedAction, paginationLoadMoreAction } from './actions';
import getKey from './getKey';
import { createSelectorForPaginationInfo } from './selectors';

type Props = {|
  type: string,
  params?: { +[string]: void | string },
  children: ({
    loadedInitial: boolean,
    loading: boolean,
    loadMore: () => Promise<void>,
  }) => Node,
|};

export default memo<Props>(({ children, params, type }) => {
  const previousKey = useRef();
  const key = getKey(params || {});

  const { loading, loadedInitial } = useSelector(
    createSelectorForPaginationInfo(type, params || {}),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (previousKey.current !== key) {
      previousKey.current = key;
      dispatch(paginationMountedAction(type, params || {}));
    }
  }, [dispatch, params, type, key]);

  const [resolvers, setResolvers] = useState<$ReadOnlyArray<() => void>>([]);

  const previousLoading = useRef(loading);

  useEffect(() => {
    if (previousLoading.current && !loading && resolvers.length) {
      resolvers.forEach(resolve => resolve());
      setResolvers([]);
    }
  }, [loading, resolvers]);

  const loadMore = useCallback(() => {
    return new Promise<void>(resolve => {
      setResolvers(resolvers.concat(resolve));
      if (!loading) {
        dispatch(paginationLoadMoreAction(type, params || {}));
      }
    });
  }, [dispatch, resolvers, loading, params, type]);

  previousLoading.current = loading;

  return children({
    loadMore,
    loadedInitial: !!loadedInitial,
    loading: !!loading,
  });
});
