//@flow
import React, { PureComponent, type Node } from 'react';
import jss, { type InjectedJSS } from 'react-jss';

const styles = {
  container: {},

  header: {
    position: 'sticky',
    top: 0,
    zIndex: 3,
  },

  content: {},
};

type Props = {
  header: ?Node,
  children: ?Node,
} & InjectedJSS<typeof styles>;

class StickyHeader extends PureComponent<Props> {
  render() {
    const { children, classes, header } = this.props;

    return (
      <div className={classes.container}>
        {/* Header */}
        <div className={classes.header}>{header}</div>

        {/* Content */}
        <div className={classes.content}>{children}</div>
      </div>
    );
  }
}

export default jss(styles)(StickyHeader);
