// @flow
import { type Saga } from 'redux-saga';
import {
  call,
  fork,
  put,
  putResolve,
  take,
  takeEvery,
  spawn,
} from 'redux-saga/effects';

import { Raven } from '@datatheorem/global';
import { get as getCurrentUser } from '@datatheorem/user-api/current_user';
import { get as getAccountInfo } from '@datatheorem/user-api/account_info';
import tracking, { dataCreators } from '@datatheorem/analytics';
import {
  getSessionId,
  setSessionId,
  enabled,
  clearSessionId,
} from '@datatheorem/cache';

import Actions from './../actions';
import callPromise from './callPromise';

export function* attemptToRestoreSession(): Saga<void> {
  if (!enabled()) {
    yield put(Actions.sessionExpired());
    return;
  }
  yield put(Actions.sessionValidationRequested());
  const sessionId = yield call(getSessionId);
  if (sessionId) {
    try {
      yield call(doSignIn);
    } catch (e) {
      console.error(e);
      yield put(Actions.sessionExpired());
      yield call(clearSessionId);
    }
  } else {
    yield put(Actions.sessionExpired());
  }
}

export function* doSignIn(): Saga<void> {
  const currentUser = yield* callPromise(getCurrentUser);

  if (currentUser) {
    Raven.setUserContext({
      id: currentUser.id,
      email: currentUser.login_email,
    });

    if (currentUser.role) {
      yield call(tracking, dataCreators.userBeganSession(currentUser.role));
    }

    yield putResolve(Actions.currentUserReceived(currentUser));
    yield put(Actions.sessionValidated());
  }

  const accountInfo = yield* callPromise(getAccountInfo);

  if (accountInfo) {
    Raven.setTagsContext({ account: accountInfo.name });
    yield put(Actions.accountInfoReceived(accountInfo));
  }
}

export function* watchForSignOutButtonClicked(): Saga<void> {
  while (true) {
    yield take(Actions.signOutButtonClicked.toString());
    yield call(clearSessionId);
    yield put(Actions.cancelledSession());
    yield call(tracking, dataCreators.logout());
  }
}

export function* handleSessionCookieSaga(action: {
  type: string,
  payload: string,
}): Saga<void> {
  yield call(setSessionId, action.payload);
  yield call(attemptToRestoreSession);
}

export function* watchForSessionCookiesReceived(): Saga<void> {
  yield takeEvery(Actions.receivedCookie.toString(), handleSessionCookieSaga);
}

export function* saga(): Saga<void> {
  yield call(attemptToRestoreSession);
  yield fork(watchForSignOutButtonClicked);
  yield spawn(watchForSessionCookiesReceived);
}
