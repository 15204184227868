//@flow
import overEvery from 'lodash/overEvery';
import identity from 'lodash/identity';
import qs from 'query-string';

import { isClosedStatus } from './targets/status';

import type { RouterHistory } from 'react-router-dom';
import type { SecurityFinding } from './types';

type Query = {
  category?: string,
  showClosed?: boolean,
};

export function isAppleAppStoreBlocker(finding: SecurityFinding): boolean {
  return (
    !!finding.importance_tags && finding.importance_tags.includes('APPLE_P1')
  );
}

export function isGooglePlayBlocker(finding: SecurityFinding): boolean {
  return (
    !!finding.importance_tags && finding.importance_tags.includes('GOOGLE_P1')
  );
}

export function isSecurityP1(finding: SecurityFinding): boolean {
  return (
    !!finding.importance_tags &&
    finding.importance_tags.includes('SECURITY_P1') &&
    (finding.priority === 'P1' || finding.priority === 'P0')
  );
}

export function isPriorityAlert(finding: SecurityFinding): boolean {
  return (
    finding.priority === 'P1' ||
    finding.priority === 'P0' ||
    isGooglePlayBlocker(finding) ||
    isAppleAppStoreBlocker(finding) ||
    isSecurityP1(finding)
  );
}

export function isComplianceIssue(finding: SecurityFinding): boolean {
  return (
    !!finding.compliance_policy_references &&
    finding.compliance_policy_references.length > 0
  );
}

const categoryFilters: Object = {
  priority: isPriorityAlert,
  security: isSecurityP1,
  google: isGooglePlayBlocker,
  apple: isAppleAppStoreBlocker,
  compliance: isComplianceIssue,
};

export default function findingsFilterCreator(query: Query) {
  let filters = [];
  const keys = Object.keys(query || {});

  if (!query || !keys.length) {
    return identity;
  }

  const params = {
    category: 'all',
    showClosed: false,
    compliancePolicy: null,
    ...query,
  };

  params.category.split(' ').forEach(part => {
    if (categoryFilters[part]) {
      filters.push(categoryFilters[part]);
    }
  });

  if (!params.showClosed) {
    filters.push(finding => !isClosedStatus(finding.aggregated_status));
  }

  if (params.compliancePolicy && params.compliancePolicy.length > 0) {
    filters.push(finding => {
      if (!finding.compliance_policy_references) {
        return false;
      }
      return finding.compliance_policy_references.find(
        cpr => params.compliancePolicy.indexOf(cpr.compliance_policy) >= 0,
      );
    });
  }

  return overEvery(filters);
}

const SHOW_HIDE_CLOSED_PARAM = 'showclosed';
const COMPLIANCE_POLICY_FILTER_PARAM = 'compliancePolicy';

export function setShowHideClosedFilter(
  browserHistory: RouterHistory,
  shouldShow: boolean,
) {
  const { location } = browserHistory;
  const state = location.state || {};
  const search = qs.parse(location.search);
  search[SHOW_HIDE_CLOSED_PARAM] = (!!shouldShow).toString();
  browserHistory.replace({
    ...location,
    search: '?' + qs.stringify(search),
    state: { ...state, [SHOW_HIDE_CLOSED_PARAM]: !!shouldShow },
  });
}

export function getShowHideClosedFilter(browserHistory: RouterHistory) {
  const stateParam =
    browserHistory.location.state &&
    typeof browserHistory.location.state === 'object' &&
    browserHistory.location.state[SHOW_HIDE_CLOSED_PARAM];

  if (stateParam === true || stateParam === false) {
    return stateParam;
  }

  const search = qs.parse(browserHistory.location.search);
  const value = search[SHOW_HIDE_CLOSED_PARAM];
  return ['true', '1', 'y'].includes(value);
}

export function setComplianceSearchFilter(
  browserHistory: RouterHistory,
  compliancePolicies: $ReadOnlyArray<string>,
) {
  const { location } = browserHistory;
  const state = location.state || {};
  const search = qs.parse(location.search);
  const compliancePoliciesFilter = compliancePolicies.join(',');

  search[COMPLIANCE_POLICY_FILTER_PARAM] = compliancePoliciesFilter;
  browserHistory.replace({
    ...location,
    search: '?' + qs.stringify(search),
    state: {
      ...state,
      [COMPLIANCE_POLICY_FILTER_PARAM]: compliancePoliciesFilter,
    },
  });
}

export function getComplianceSearchFilter(browserHistory: RouterHistory) {
  const stateParam =
    browserHistory.location.state instanceof Object &&
    browserHistory.location.state[COMPLIANCE_POLICY_FILTER_PARAM];

  if (stateParam) {
    return stateParam ? stateParam.split(',') : [];
  }

  const search = qs.parse(browserHistory.location.search, {
    arrayFormat: 'comma',
  });
  const value = search[COMPLIANCE_POLICY_FILTER_PARAM];
  return Array.isArray(value) ? value : value ? [value] : [];
}

export function setState(browserHistory: RouterHistory, state: {}) {
  const { push, location } = browserHistory;
  push({
    ...location,
    state: {
      ...location.state,
      ...state,
    },
  });
}
