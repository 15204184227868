//@flow
import { object, string, array } from 'yup';

import { type FindingTargetStatusEnum } from '@datatheorem/enums/FindingTargetStatusEnum';
import { type SecurityFinding } from '@datatheorem/findings/types';
import { type FindingPriorityEnum } from '@datatheorem/enums/FindingPriorityEnum';
import type { TargetStatus, Note } from '@datatheorem/findings/types';

import { request } from './gapi';
import { result, check } from './util';
import { type PaginatedResponse } from './_common';
import { TargetSchema } from './_targets';

const NoteSchema = object().shape({
  date_updated: string(),
  text: string(),
  security_finding_id: string(),
  author_email: string(),
  date: string(),
  date_created: string(),
  id: string(),
});

const SecurityFindingSchema = object().shape({
  id: string().required(),
  notes: array()
    .of(NoteSchema)
    .ensure(),
  targets: array()
    .of(TargetSchema)
    .required(),
});

async function validate(finding: SecurityFinding): Promise<SecurityFinding> {
  return SecurityFindingSchema.validate(finding);
}

async function validateList(container: ListResponse): Promise<ListResponse> {
  return object()
    .shape({
      security_findings: array().of(SecurityFindingSchema),
    })
    .validate(container);
}

export async function get(id: string): Promise<SecurityFinding> {
  if (!id || !id.length) {
    throw new Error('Invalid security finding ID provided');
  }
  return request({ path: `userapi/v2/security_findings/${id}` })
    .then(result)
    .then(validate);
}

type ListParams = {
  +cursor?: ?string,
  +mobile_app_id?: ?string,
  +priority?: ?string,
  +status_group?: ?string,
  +template_id?: ?string,
  +updated_since?: ?string,
  +compliance_policy?: ?string,
};

type ListResponse = PaginatedResponse<{
  security_findings: $ReadOnlyArray<SecurityFinding>,
}>;

export async function list(params?: ListParams): Promise<ListResponse> {
  return request({
    path: 'userapi/v2/security_findings',
    params: params,
  })
    .then(result)
    .then(validateList);
}

type PatchParams = {
  priority: FindingPriorityEnum,
};

export async function patch(
  id: string,
  params: PatchParams,
): Promise<SecurityFinding> {
  if (!id || !id.length) {
    throw new Error('Invalid finding ID provided');
  }
  if (!params || !params.priority) {
    throw new Error('A valid priority is required');
  }

  return request({
    path: `userapi/v2/security_findings/${id}`,
    method: 'PATCH',
    params: params,
  }).then(result);
}

type CreateStatusParams = {
  status: FindingTargetStatusEnum,
};

export const targets = {
  statuses: {
    async create(
      security_finding_id: string,
      id: string,
      params: CreateStatusParams,
    ): Promise<TargetStatus> {
      if (!security_finding_id || !security_finding_id.length) {
        throw new Error('Invalid security finding ID provided');
      }
      if (!id || !id.length) {
        throw new Error('Invalid target ID provided');
      }
      if (!params || !params.status) {
        throw new Error('A valid status is required');
      }
      return request({
        path: `userapi/v2/security_findings/${security_finding_id}/targets/${id}/statuses`,
        method: 'POST',
        params: params,
      }).then(result);
    },
  },
  jira_ticket: {
    async create(
      security_finding_id: string,
      target_id: string,
    ): Promise<SecurityFinding> {
      if (!security_finding_id || !security_finding_id.length) {
        throw new Error('Invalid security finding ID provided');
      }
      if (!target_id || !target_id.length) {
        throw new Error('Invalid target ID provided');
      }
      return request({
        path: `userapi/v2/security_findings/${security_finding_id}/targets/${target_id}/jira_ticket`,
        method: 'POST',
      }).then(result);
    },
  },
};

type CreateNoteParams = {
  text: string,
};

export const notes = {
  async create(
    security_finding_id: string,
    params: CreateNoteParams,
  ): Promise<Note> {
    if (!security_finding_id || !security_finding_id.length) {
      throw new Error('Invalid security finding ID provided');
    }
    if (!params || !params.text) {
      throw new Error('Note text is required');
    }

    return request({
      path: `userapi/v2/security_findings/${security_finding_id}/notes`,
      method: 'POST',
      params: params,
    }).then(result);
  },

  async del(security_finding_id: string, id: string): Promise<void> {
    if (!security_finding_id || !security_finding_id.length) {
      throw new Error('Invalid security finding ID provided');
    }
    if (!id || !id.length) {
      throw new Error('Invalid note ID provided');
    }

    return request({
      path: `userapi/v2/security_findings/${security_finding_id}/notes/${id}`,
      method: 'DELETE',
    }).then(check);
  },
};
