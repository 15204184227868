//@flow
import { type Saga } from 'redux-saga';
import { spawn, put, takeEvery } from 'redux-saga/effects';
import type { ActionType } from 'redux-actions';
import { articles } from '@datatheorem/user-api/mediawatch';
import { callPromise } from '@datatheorem/session';
import { mediawatchRoutine } from '../actions/mediawatch';
import { securityFindingOpened } from '../actions/securityFindings';
import { paginateToEnd } from './util/paginate';

export default function* mediawatchSaga(): Saga<void> {
  yield spawn(watchForSecurityFindingDialogOpened);
}

function* watchForSecurityFindingDialogOpened(): Saga<void> {
  yield takeEvery(
    securityFindingOpened.toString(),
    loadMediawatchArticlesForFinding,
  );
}

function* loadMediawatchArticlesForFinding(
  action: ActionType<typeof securityFindingOpened>,
): Saga<void> {
  const { id } = action.payload;

  yield put(mediawatchRoutine.request({ security_finding_id: id }));

  const params = { impacted_security_finding_id: id };

  yield* paginateToEnd(loadMediawatchArticles, 'mediawatch', params, params);

  yield put(mediawatchRoutine.fulfill({ security_finding_id: id }));
}

function* loadMediawatchArticles(params: {|
  impacted_security_finding_id: string,
|}): Saga<void> {
  try {
    const response = yield* callPromise(articles.list, params);
    if (response.mediawatch_articles) {
      yield put(mediawatchRoutine.success(response.mediawatch_articles));
    }
  } catch (e) {
    yield put(mediawatchRoutine.failure(e.toString()));
  }
}
