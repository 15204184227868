//@flow
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { trustkitReportsReceived } from '../actions';
import type { TrustKitReport } from '@datatheorem/user-api/mobile_apps';

export type TrustkitReportsState = $ReadOnlyArray<TrustKitReport>;

export default createReducerCreator<TrustkitReportsState, TrustKitReport, void>(
  {
    initialState: ([]: TrustkitReportsState),
    equalityCheck: (a, b) => a.id === b.id,
  },
)(updateInList(trustkitReportsReceived.toString()));
