//@flow
import React, { PureComponent } from 'react';
import jss, { type InjectedJSS } from 'react-jss';
import ExpandMore from 'material-ui/svg-icons/navigation/expand-more';

import { palette } from '@datatheorem/theme';

const styles = {
  CardSeeMoreSimple: {
    paddingLeft: ({ hack }: Props) => (hack ? '50%' : null),
    fontSize: 12,
    color: palette.faded,
    width: ({ hack }: Props) => (hack ? 700 : null),
    textAlign: ({ hack }: Props) => (hack ? null : 'center'),
  },
  ExpandMore: {
    verticalAlign: 'middle !important',
  },
  text: {
    verticalAlign: 'middle',
  },
};

type Props = {
  hack?: ?boolean,
  color?: ?string,
  children: any,
} & InjectedJSS<typeof styles>;

export default jss(styles)(
  class CardSeeMoreSimple extends PureComponent<Props> {
    render() {
      const { color, classes, children } = this.props;

      return (
        <div className={classes.CardSeeMoreSimple}>
          <ExpandMore className={classes.ExpandMore} color={color} />
          <span className={classes.text}>{children}</span>
          <ExpandMore className={classes.ExpandMore} color={color} />
        </div>
      );
    }
  },
);
