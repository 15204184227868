// @flow
import React from 'react';
import ClipboardIcon from './icons/ClipboardIcon';
import copy from './copyToClipboard.util';
import injectSheet from 'react-jss';
import type { InjectedJSS } from 'react-jss';
import { palette } from '@datatheorem/theme';

type Props = {|
  text: string,
  size?: number | string,
  color?: string,
  showAsText?: boolean | string,
  ...InjectedJSS<typeof styles>,
|};

type State = {
  copied: boolean,
};

const styles = {
  button: {
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative',
  },

  label: {
    padding: '2px 5px',
    color: palette.gray20,
    background: palette.gray50,
    borderRadius: 2,
    transition: 'background-color 300ms',
    '&:hover': {
      background: palette.gray40,
    },
  },

  floating: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,

    transform: 'translate(0, -40px)',
    animationName: 'FadeInUp',
    animationDuration: '2s',
    opacity: 0,
  },

  '@keyframes FadeInUp': {
    '0%': {
      transform: 'translate(0, 0px)',
      opacity: 1,
    },
    '30%': {
      transform: 'translate(0, -20px)',
      opacity: 1,
    },
    '100%': {
      transform: 'translate(0, -40px)',
      opacity: 0,
    },
  },
};

class CopyToClipboard extends React.Component<Props, State> {
  state = {
    copied: false,
  };

  timeout: ?TimeoutID;

  copy = () => {
    // Slow down to avoid flickering the animation
    if (this.state.copied) return;

    this.setState({ copied: true });
    copy(this.props.text);

    this.timeout = setTimeout(() => this.setState({ copied: false }), 2500);
  };

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout);
  }

  render() {
    const { showAsText, size = 14 } = this.props;

    return (
      <span onClick={this.copy} className={this.props.classes.button}>
        {this.state.copied && (
          <span className={this.props.classes.floating}>Copied!</span>
        )}
        {!showAsText && (
          <ClipboardIcon
            color={this.props.color || palette.gray30}
            style={{
              width: size,
              height: size,
            }}
          />
        )}
        {showAsText && (
          <span
            className={this.props.classes.label}
            style={{
              fontSize: size,
            }}
          >
            {typeof showAsText === 'string' ? showAsText : 'Copy'}
          </span>
        )}
      </span>
    );
  }
}

export default injectSheet(styles)(CopyToClipboard);
