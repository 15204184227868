//@flow
import { Raven } from '@datatheorem/global';
import { Actions as NotificationsActions } from '@datatheorem/notifications';
import { callPromise } from '@datatheorem/session';
import { create, remove } from '@datatheorem/user-api/search_queries';
import { flow, keys, mapValues, omit } from 'lodash/fp';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  ActionEnum,
  addedFilter,
  finishedFiltersBackup,
  removedFilter,
} from '../actions/filterActions';
import { getBackedUp, getFilters, setBackedUp } from '../clients/local';
import { initialState } from './../reducers/filters';
import type {
  RemoveFilterAction,
  AddFilterAction,
} from '../actions/filterActions';

// Types
import type { Saga } from 'redux-saga';

const onError = function*(err, title, description) {
  const errorMessage =
    title && description ? `${title} - ${description}` : err && err.message;
  Raven.captureException(err, {
    extra: {
      msg: errorMessage,
    },
  });
  yield put(
    NotificationsActions.requestNotifyUser({
      text: errorMessage,
    }),
  );
  console.log(errorMessage); // eslint-disable-line no-console
  console.error(err);
};

// Backs up any search queries we have from storage.
// These queries are now considered legacy.
export const backupFiltersFromStorageSaga = function*(): Saga<void> {
  try {
    // Get any filters that a user might have locally.
    const filters = getFilters();

    /* eslint-disable no-console */
    console.log('>> Advanced Search Query Migration.');

    // Did we already back up this user's queries?
    if (getBackedUp()) {
      console.log('Already backed up! Skipping.');
      yield put(finishedFiltersBackup());
      return;
    }

    console.log('User Filters:');
    const userFilters = omit(keys(initialState.savedFilters))(filters);
    console.log(userFilters);

    // Update them to the new search_queries endpoint.
    yield all(
      flow(
        mapValues(f =>
          call(callPromise, create, {
            title: f.name,
            description: f.name,
            is_private: true,
            filter_query: {
              compliance_policies: f.value.selectedCompliancePolicies,
              current_statuses: f.value.selectedCurrentStatuses,
              priorities: f.value.selectedPriorities,
              release_types: f.value.selectedReleaseTypes,
              severities: f.value.selectedSeverities,
              statuses: f.value.selectedStatuses.dateRange.type
                ? {
                    date_range: {
                      from_date: f.value.selectedStatuses.dateRange.from
                        ? f.value.selectedStatuses.dateRange.from
                        : null,
                      to_date: f.value.selectedStatuses.dateRange.to
                        ? f.value.selectedStatuses.dateRange.to
                        : null,
                      type: f.value.selectedStatuses.dateRange.type,
                    },
                    statuses: f.value.selectedStatuses.statuses,
                  }
                : null,
              min_age: f.value.minAge ? parseInt(f.value.minAge, 10) : null,
              sort_by: f.value.sortBy ? f.value.sortBy.toUpperCase() : 'NONE',
            },
          }),
        ),
      )(userFilters),
    );

    console.log('Backed up successfully!');
    setBackedUp();

    console.log('<< Advanced Search Query Migration.');
    /* eslint-enable no-console */

    yield put(finishedFiltersBackup());
  } catch (err) {
    yield call(
      onError,
      err,
      'Filter Backup',
      'There was an error backing up search queries.',
    );
  }
};

export const addFilterSaga = function*({
  payload,
}: AddFilterAction): Saga<void> {
  try {
    const response = yield* callPromise(create, {
      title: payload.name,
      description: payload.name,
      is_private: true,
      filter_query: {
        compliance_policies: payload.value.selectedCompliancePolicies,
        current_statuses: payload.value.selectedCurrentStatuses,
        priorities: payload.value.selectedPriorities,
        release_types: payload.value.selectedReleaseTypes,
        severities: payload.value.selectedSeverities,
        statuses: payload.value.selectedStatuses.dateRange.type
          ? {
              date_range: {
                from_date: payload.value.selectedStatuses.dateRange.from
                  ? payload.value.selectedStatuses.dateRange.from.toISOString()
                  : null,
                to_date: payload.value.selectedStatuses.dateRange.to
                  ? payload.value.selectedStatuses.dateRange.to.toISOString()
                  : null,
                type: payload.value.selectedStatuses.dateRange.type,
              },
              statuses: payload.value.selectedStatuses.statuses,
            }
          : null,
        min_age: payload.value.minAge
          ? parseInt(payload.value.minAge, 10)
          : null,
        sort_by: payload.value.sortBy
          ? payload.value.sortBy.toUpperCase()
          : 'NONE',
      },
    });

    if (!response) throw new Error('Unable to create search query.');

    // Create a DateRange FLow.
    const dateRange =
      response.filter_query.statuses.date_range.type === 'CUSTOM'
        ? {
            type: response.filter_query.statuses.date_range.type,
            from: response.filter_query.statuses.date_range.from_date
              ? new Date(response.filter_query.statuses.date_range.from_date)
              : new Date(),
            to: response.filter_query.statuses.date_range.to_date
              ? new Date(response.filter_query.statuses.date_range.to_date)
              : new Date(),
          }
        : {
            type: response.filter_query.statuses.date_range.type,
          };

    yield put(
      addedFilter(response.description, {
        id: response.id,
        description: response.description,
        isOwner: response.is_owner,
        isPrivate: response.is_private,
        selectedCompliancePolicies: response.filter_query.compliance_policies,
        selectedStoreBlockers: [], // it's not in the backend yet
        selectedCurrentStatuses: response.filter_query.current_statuses,
        selectedPriorities: response.filter_query.priorities,
        selectedReleaseTypes: response.filter_query.release_types,
        selectedSeverities: response.filter_query.severities,
        selectedStatuses: {
          statuses: response.filter_query.statuses.statuses,
          dateRange: dateRange,
        },
        minAge: response.filter_query.min_age
          ? response.filter_query.min_age
          : '',
        // $FlowFixMe FlowUpgrade
        sortBy: response.filter_query.sort_by,
      }),
    );
  } catch (err) {
    yield call(
      onError,
      err,
      'Save Filters',
      'There was an error saving search queries.',
    );
  }
};

export const removeFilterSaga = function*({
  payload,
}: RemoveFilterAction): Saga<void> {
  try {
    yield* callPromise(remove, payload);
    yield put(removedFilter(payload));
  } catch (err) {
    yield call(
      onError,
      err,
      'Save Filters',
      'There was an error saving search queries.',
    );
  }
};

/** Main saga for Filters */
export const filtersSaga = function*(): Saga<void> {
  try {
    yield takeEvery(
      ActionEnum.REQUEST_FILTERS_FROM_STORAGE,
      backupFiltersFromStorageSaga,
    );

    yield takeEvery(ActionEnum.ADD_FILTER, addFilterSaga);
    yield takeEvery(ActionEnum.REMOVE_FILTER, removeFilterSaga);
  } catch (err) {
    yield call(onError, err);
  }
};
