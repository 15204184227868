//@flow
import { call, put, spawn, take, takeEvery } from 'redux-saga/effects';

import { Actions as SessionActions } from '@datatheorem/session';

import { create, list, patch, del } from '@datatheorem/user-api/users';
import { callPromise } from '@datatheorem/session';
import { Actions as NotificationsActions } from '@datatheorem/notifications';
import {
  anErrorOccurred,
  inviteUserClicked,
  userDeleteClicked,
  userDeleteError,
  userDeleteSuccess,
  userEditError,
  userEditFormSubmitted,
  userEditReceived,
  userInvitedError,
  userInvitedReceived,
  userInviteFormSubmitted,
  usersReceived,
} from '../actions';
import { browserHistory } from '../clients/history';
import { tryAndParseErrorMessage } from '@datatheorem/user-api/util';
import {
  getRoleEnumInferredFromSimplest,
  hasUserAccess,
} from '@datatheorem/session';
import type { User } from '@datatheorem/user-api/users';
import { type Saga } from 'redux-saga';
import { select } from '@datatheorem/redux-saga-wrapped-effects';
import tracking, { dataCreators } from '@datatheorem/analytics';
import type { State } from '../ReduxStateType';

export function* watchForUserUpdates(): Saga<void> {
  yield takeEvery(userEditFormSubmitted.toString(), function*(action: {
    type: string,
    payload: { _hasAllApps: boolean } & User,
  }): Saga<void> {
    const { id, role, _hasAllApps, ...params } = action.payload;

    const enumRole = role && getRoleEnumInferredFromSimplest(role, _hasAllApps);

    const body = {
      ...params,
      role: enumRole,
    };

    try {
      const user = yield* callPromise(patch, id, body);
      if (user) {
        const { first_name, last_name } = user;

        yield put(usersReceived([user]));
        yield put(userEditReceived(user));
        yield put(
          NotificationsActions.requestNotifyUser({
            text: `${first_name || 'User'} ${last_name ||
              ''} has been updated.`,
          }),
        );
        yield call(tracking, dataCreators.userEdited(enumRole));
      } else {
        yield put(
          userEditError({
            _error: 'A problem occurred when trying to edit this user',
          }),
        );
      }
    } catch (err) {
      yield put(userEditError({ _error: tryAndParseErrorMessage(err) }));
    }
  });
}

export function* loadUsers(): Saga<void> {
  try {
    const users = yield* callPromise(list);
    yield put(usersReceived(users || []));
  } catch (err) {
    // TODO: This may trigger because the user doesn't have the rights to query users, but we handle that pretty gracefully elsewhere, we just want to not kill the saga
  }
}

export function* loadUsersAtLogin(): Saga<void> {
  yield take(SessionActions.currentUserReceived.toString());

  const { currentUser } = yield* select((state: State) => state);

  if (!currentUser) {
    throw new Error('Expected user to exist');
  }

  if (hasUserAccess(currentUser)) {
    yield call(loadUsers);
  }

  yield spawn(watchForUserUpdates);
  yield spawn(watchForInviteUserFormSubmitted);
  yield spawn(watchForInviteUserClicked);
  yield spawn(watchForUserDeleteClicked);
}

export function* watchForInviteUserFormSubmitted(): Saga<void> {
  yield takeEvery(
    userInviteFormSubmitted.toString(),
    validateAndSendInviteUserForm,
  );
}

export function* watchForInviteUserClicked(): Saga<void> {
  yield takeEvery(inviteUserClicked.toString(), function*(): Saga<void> {
    yield call(browserHistory.push, '/users/invite');
  });
}

export function* validateAndSendInviteUserForm(action: {
  type: string,
  payload: { _hasAllApps: boolean } & User,
}): Saga<void> {
  try {
    const {
      notification_email,
      login_email,
      role,
      _hasAllApps,
    } = action.payload;

    const enumRole = role && getRoleEnumInferredFromSimplest(role, _hasAllApps);

    const body = {
      ...action.payload,
      notification_email: notification_email || login_email,
      role: enumRole,
    };

    // $FlowFixMe FlowUpgrade
    const user = yield* callPromise(create, body);
    if (user) {
      const { first_name, last_name, notification_email } = user;
      yield put(usersReceived([user]));
      yield put(userInvitedReceived(user));
      yield put(
        NotificationsActions.requestNotifyUser({
          text: `${first_name || 'User'} ${last_name ||
            ''} has been granted access to the Data Theorem portal.${
            notification_email
              ? ` An email with setup instructions has been sent to ${notification_email}.`
              : ''
          }`,
        }),
      );
      yield call(browserHistory.push, `/users/${user.id}`);
      yield call(tracking, dataCreators.userInvited(enumRole));
    } else {
      yield put(anErrorOccurred('POST users did not respond with a user'));
    }
  } catch (error) {
    yield put(
      userInvitedError({
        _error: tryAndParseErrorMessage(error, 'A problem occurred'),
      }),
    );
  }
}

function* watchForUserDeleteClicked(): Saga<void> {
  yield takeEvery(userDeleteClicked.toString(), function*(action: {
    payload: User,
  }): Saga<void> {
    const user = action.payload;
    try {
      yield* callPromise(del, user.id);
      yield put(userDeleteSuccess(user));
      yield put(
        NotificationsActions.requestNotifyUser({
          text: `${user.first_name || 'User'} ${user.last_name || ''} deleted.`,
        }),
      );
      yield call(browserHistory.push, '/users');
      yield call(tracking, dataCreators.userDeleted(user.role));
    } catch (err) {
      yield put(userDeleteError(user));
      yield put(
        NotificationsActions.requestNotifyUser({
          text: `Could not remove ${user.first_name ||
            'User'} ${user.last_name || ''}: ${tryAndParseErrorMessage(err)}`,
        }),
      );
    }
  });
}
