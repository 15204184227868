// @flow
import { createAction } from 'redux-actions';

import type { Notification } from './reducer';

const requestNotifyUser = createAction<
  'REQUEST_NOTIFY_USER',
  [Notification],
  { notification: Notification },
>('REQUEST_NOTIFY_USER', (notification: Notification) => ({ notification }));

const dismissNotificationClicked = createAction<
  'DISMISS_NOTIFICATION_CLICKED',
  [Notification],
  { notification: Notification },
>('DISMISS_NOTIFICATION_CLICKED', (notification: Notification) => ({
  notification,
}));

export default {
  requestNotifyUser: requestNotifyUser,
  dismissNotificationClicked: dismissNotificationClicked,
};
