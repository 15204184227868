//@flow
import React, { PureComponent } from 'react';

import {
  Card,
  CardHeader,
  CardMedia,
  Typography,
  withStyles,
  type WithStyles,
} from '@material-ui/core';

import { Markdown } from '@datatheorem/components';

import type { ChangeSections } from '../../selectors/whatsnew';
import { palette } from '@datatheorem/theme';

const styles = {
  media: { objectFit: 'contain', margin: '0 auto' },
};

type OwnProps = {|
  changes: ChangeSections,
|};
type Props = {| ...OwnProps, ...WithStyles |};

class Changelog extends PureComponent<Props> {
  render() {
    const { changes, classes } = this.props;
    return (
      <section>
        {changes.map(section => (
          <div style={{ paddingLeft: 80, paddingRight: 80 }} key={section.time}>
            {changes.length > 1 && (
              <h2
                style={{
                  color: palette.gray20,
                  fontSize: 18,
                  marginTop: 30,
                }}
              >
                {section.heading}
              </h2>
            )}
            {section.items.map(({ title, image, markdown, date }, i) => (
              <Card
                style={{
                  marginBottom: 20,
                  padding: '5px 30px',
                }}
                key={i}
              >
                {title && (
                  <CardHeader
                    title={title}
                    subtitle={new Date(
                      date.year,
                      date.month - 1, // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#Parameters
                      date.day,
                    ).toLocaleDateString()}
                  />
                )}
                {image && (
                  <CardMedia className={classes.media}>
                    <img
                      src={image}
                      alt={title || 'Sample for Change'}
                      style={{
                        objectFit: 'contain',
                        margin: '0 auto',
                        maxWidth: '80%',
                      }}
                    />
                  </CardMedia>
                )}
                {markdown && (
                  <Typography>
                    <Markdown text={markdown} />
                  </Typography>
                )}
              </Card>
            ))}
          </div>
        ))}
      </section>
    );
  }
}

export default React.memo<OwnProps>(withStyles(styles)(Changelog));
