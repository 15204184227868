//@flow
import React from 'react';
import { render } from 'react-dom';
import Container from './components/Container';
import { getStore } from './redux';
import { getMiddleware, runSagas } from './sagas';
import { ErrorBoundary } from '@datatheorem/components';

// istanbul ignore next because it bootstraps the application
function app(renderFn: typeof render = render) {
  const domContainer = document.getElementById('react');

  if (!domContainer) {
    alert(`Couldn't start the application. Clear cache and refresh.`);
    console.error(`There is no DOM element with id=react!`);
    return;
  }

  const reduxSaga = getMiddleware();
  const store = getStore([reduxSaga]);

  runSagas(reduxSaga);

  return renderFn(
    <ErrorBoundary>
      <Container store={store} />
    </ErrorBoundary>,
    domContainer,
  );
}

export default app;
