//@flow
import { dataCreators } from '@datatheorem/analytics';
import qs from 'query-string';
import { flow, filter, head } from 'lodash/fp';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import tracking, { type TrackingProps } from 'react-tracking';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';

import {
  LoadingFiller,
  StickyHeader,
  ShadowScanPreviewDialog,
  ShadowScanPreviewFindingCardList,
  MenuButton,
} from '@datatheorem/components';
import { MenuItem, type WithStyles, withStyles } from '@material-ui/core';

import { findings as shadowFindingsSelector } from '@datatheorem/findings/shadow-scan/selectors';
import { palette } from '@datatheorem/theme';
import {
  inquireShadowScanFindings,
  type InquireShadowScanFindingsActionParams,
  previewShadowScanFindingsForApp,
} from './../../../actions/shadowScanFindings';
import { browserHistory } from './../../../clients/history';
import {
  storeAppsWithMetadataCount,
  totalStoreAppsMetadataCount,
  percentageOfStoreAppsIntegratedWithCiCd,
} from './../../../selectors/apps';
import {
  type Application,
  type CategoryMetadataCount,
  type ApplicationWithMetadataCount,
  type Integrations,
} from '@datatheorem/user-api/mobile_apps';
import AppList from './../../apps/AppList';
import {
  DetailsViewType,
  type DetailsViewTypeEnum,
  IntegrationView,
  IntegrationViewHeader,
  SummaryView,
  SummaryViewHeader,
} from './details';
import { type DashboardInjectedProps } from '../../../types/DashboardInjectedProps';

import type { ShadowscanSecurityFinding } from '@datatheorem/user-api/shadow_security_findings';

const APPS_DETAILS_VIEW_QUERY_PARAM = 'view';

const styles = {
  dashboardHack: {
    position: 'absolute',
    left: 230,
    right: 0,
    top: 50,
  },

  header: {
    width: '100%',
    paddingTop: 35,
    paddingLeft: 83,
    paddingRight: 58,
    marginBottom: 5,
    backgroundColor: 'white',
    borderBottom: `1px solid ${palette.accent}`,
  },

  content: {
    marginTop: 20,
  },

  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  bottom: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  title: {
    fontFamily: 'Roboto, sans-serif',
    color: 'rgb(74, 74, 74)',
    fontWeight: 'normal',
  },
};

// region Props
type OwnProps = {|
  ...DashboardInjectedProps,
|};

type StateProps = {|
  storeApps: $ReadOnlyArray<ApplicationWithMetadataCount>,
  percentageOfStoreAppsIntegratedWithCiCd: number,
  loaded: boolean,
  totalStoreAppsMetadataCount: CategoryMetadataCount,
  shadowScanFindings: $ReadOnlyArray<ShadowscanSecurityFinding>,
|};

type DispatchProps = {|
  dispatchPreviewShadowScanFindingsForApp: typeof previewShadowScanFindingsForApp,
  dispatchInquireShadowScanFindings: typeof inquireShadowScanFindings,
|};

type Props = {|
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
  ...TrackingProps,
  ...WithStyles,
|};

type IntermediateProps = {|
  ...OwnProps,
  ...WithStyles,
  ...TrackingProps,
|};
// endregion

type State = {|
  showingDetailsView: DetailsViewTypeEnum,
  shadowScanPreviewDialogOpen: boolean,
  shadowScanPreviewDialogForAppId: ?string,
|};

// region Query Params for Details View :: Summary | Integration
const getQueryParamForView = location => {
  const search = qs.parse(location.search);

  return search[APPS_DETAILS_VIEW_QUERY_PARAM] || '';
};

const setQueryParamForView = (location, view) => {
  const search = qs.parse(location.search);

  search[APPS_DETAILS_VIEW_QUERY_PARAM] = view;
  browserHistory.push({
    ...location,
    search: '?' + qs.stringify(search),
  });
};
// endregion

const getDetailsViewFromLocation = location => {
  return getQueryParamForView(location) === DetailsViewType.INTEGRATION
    ? DetailsViewType.INTEGRATION
    : DetailsViewType.SUMMARY;
};

class AppDashboard extends PureComponent<Props, State> {
  state = {
    showingDetailsView: getDetailsViewFromLocation(this.props.location),
    shadowScanPreviewDialogOpen: false,
    shadowScanPreviewDialogForAppId: null,
  };

  handleDetailsViewChange = (view: DetailsViewTypeEnum) => {
    this.setState({ showingDetailsView: view });
    setQueryParamForView(this.props.location, view);
  };

  handleShadowScanPreviewDialogClose = () => {
    this.setState({ shadowScanPreviewDialogOpen: false });
  };

  handleShadowScanPreviewClick = (appId: string) => {
    this.setState({
      shadowScanPreviewDialogOpen: true,
      shadowScanPreviewDialogForAppId: appId,
    });

    this.props.dispatchPreviewShadowScanFindingsForApp(appId);
  };

  handleShadowScanInquireClick = (
    params: InquireShadowScanFindingsActionParams,
    app: ?Application,
  ) => {
    this.props.dispatchInquireShadowScanFindings({
      ...params,
      app,
    });
  };

  /*
   * Retreieves both the header and app card content renderer.
   */
  getDetailsRendererWithHeader = () => {
    const { showingDetailsView } = this.state;
    const {
      totalStoreAppsMetadataCount,
      percentageOfStoreAppsIntegratedWithCiCd,
    } = this.props;

    switch (showingDetailsView) {
      case DetailsViewType.SUMMARY:
        return [
          <SummaryViewHeader
            key={0}
            openMetadataCount={totalStoreAppsMetadataCount.OPEN}
          />,
          ({
            id,
            metadataCount,
          }: {
            id: string,
            metadataCount: CategoryMetadataCount,
          }) => <SummaryView id={id} openMetadataCount={metadataCount.OPEN} />,
        ];

      case DetailsViewType.INTEGRATION:
        return [
          <IntegrationViewHeader
            key={0}
            percentageOfStoreAppsIntegratedWithCiCd={
              percentageOfStoreAppsIntegratedWithCiCd
            }
          />,

          // Expecting an APP_STORE app with integrations here.
          (app: { integrations?: Integrations }) => {
            if (!app.integrations) {
              throw new Error(
                'Expected an application with a release_type of APP_STORE with integrations.',
              );
            }

            return <IntegrationView integrations={app.integrations} />;
          },
        ];
    }

    return [];
  };

  componentDidUpdate() {
    this.setState({
      showingDetailsView: getDetailsViewFromLocation(this.props.location),
    });
  }

  render() {
    const {
      props,
      state,
      getDetailsRendererWithHeader,
      handleDetailsViewChange,
      handleShadowScanInquireClick,
      handleShadowScanPreviewClick,
      handleShadowScanPreviewDialogClose,
    } = this;

    const {
      classes,
      loaded,
      storeApps,
      totalStoreAppsMetadataCount,
      shadowScanFindings,
    } = props;

    const {
      showingDetailsView,
      shadowScanPreviewDialogOpen,
      shadowScanPreviewDialogForAppId,
    } = state;

    const [header, detailsRenderer] = getDetailsRendererWithHeader();

    return (
      <div className={classes.dashboardHack}>
        <StickyHeader
          header={
            <div className={classes.header}>
              <div className={classes.top}>
                <div>
                  <h1 className={classes.title}>
                    <span>Applications</span>
                  </h1>

                  <span>{storeApps.length && storeApps.length} Total</span>
                </div>

                <div>
                  <MenuButton label={<span>{showingDetailsView}</span>}>
                    {({ onClick }) =>
                      Object.keys(DetailsViewType).map((value, i) => (
                        <MenuItem
                          key={i}
                          onClick={() => {
                            onClick();
                            handleDetailsViewChange(value);
                          }}
                        >
                          {value}
                        </MenuItem>
                      ))
                    }
                  </MenuButton>
                </div>
              </div>

              {/* Details Header */}
              {header}
            </div>
          }
        >
          <div className={classes.content}>
            {/* TODO: Move down into AppList - Should also be the one fetching apps and adding it to the redux state */}
            {!loaded ? (
              <LoadingFiller />
            ) : (
              <AppList
                storeApps={storeApps}
                totalMetadataCount={totalStoreAppsMetadataCount}
                filler={true}
                detailsRenderer={detailsRenderer}
                onShadowScanInquireClick={handleShadowScanInquireClick}
                onShadowScanPreviewClick={handleShadowScanPreviewClick}
              />
            )}
          </div>
        </StickyHeader>

        <ShadowScanPreviewDialog
          open={shadowScanPreviewDialogOpen}
          onRequestClose={handleShadowScanPreviewDialogClose}
          onClickInquire={() => {
            return handleShadowScanInquireClick(
              {
                title: 'Applications',
                count:
                  totalStoreAppsMetadataCount.SHADOW &&
                  totalStoreAppsMetadataCount.SHADOW.ALL,
                breakdown: totalStoreAppsMetadataCount.SHADOW,
              },
              flow(
                filter(app => app.id === shadowScanPreviewDialogForAppId),
                head,
              )(storeApps),
            );
          }}
        >
          <ShadowScanPreviewFindingCardList
            findings={shadowScanFindings.filter(
              finding =>
                finding.mobile_app_id === shadowScanPreviewDialogForAppId,
            )}
          />
        </ShadowScanPreviewDialog>
      </div>
    );
  }
}

export default compose(
  tracking(dataCreators.component(AnalyticsScreenEnum.APPS)),
  withStyles(styles),
  connect<Props, IntermediateProps, _, _, _>(
    (state, props): StateProps => ({
      storeApps: storeAppsWithMetadataCount(state, props),
      // TODO: Should be moved down into AppList (see below)
      loaded: state.firstRequest.apps,
      totalStoreAppsMetadataCount: totalStoreAppsMetadataCount(state, props),
      percentageOfStoreAppsIntegratedWithCiCd: percentageOfStoreAppsIntegratedWithCiCd(
        state,
        props,
      ),
      shadowScanFindings: shadowFindingsSelector(state),
    }),
    {
      dispatchPreviewShadowScanFindingsForApp: previewShadowScanFindingsForApp,
      dispatchInquireShadowScanFindings: inquireShadowScanFindings,
    },
  ),
)(AppDashboard);
