//@flow
import React, { PureComponent } from 'react';
import { type MediawatchArticle } from '@datatheorem/user-api/mediawatch';
import jss, { type InjectedJSS } from 'react-jss';
import { Paper } from '@material-ui/core';
import ExternalLogo from './ExternalLogo';
import ViewMoreButton from '@datatheorem/components/src/ViewMoreButton';
import subMonths from 'date-fns/sub_months';
import isAfter from 'date-fns/is_after';
import { compose } from 'recompose';
import tracking, { type TrackingProps } from 'react-tracking';
import { dataCreators } from '@datatheorem/analytics';

const CUTOFF_TIME_FOR_NEW = subMonths(new Date(), 3);
const NUMBER_OF_VISIBLE_ARTICLES_WHILE_COLLAPSED = 3;

const styles = {
  paper: {
    marginTop: '10px !important',
    marginBottom: '10px !important',
    padding: '10px !important',
    cursor: 'pointer !important',
    position: 'relative !important',
    minHeight: '82px !important',
  },

  title: {
    marginLeft: 90,
    marginRight: 10,
  },

  metaContainer: {},

  date: {
    color: '#777',
    fontSize: 12,
    position: 'absolute',
    right: 5,
    bottom: 5,
    textTransform: 'uppercase',
  },

  source: {
    color: '#777',
    fontSize: 14,
    position: 'absolute',
    left: 0,
    top: 0,
  },

  attribution: {
    fontSize: 10,
    color: '#4099FF',
    textDecoration: 'none',
  },
};

function sortArticles(a, b) {
  if (isAfter(a.published_time || new Date(), b.published_time || new Date())) {
    return -1;
  } else {
    return 1;
  }
}

function getDomainFromURL(url) {
  const anchor = global.document.createElement('a');
  anchor.href = url;
  return anchor.hostname;
}

type Props = {
  articles?: $ReadOnlyArray<MediawatchArticle>,
} & TrackingProps &
  InjectedJSS<typeof styles>;

type State = {
  expanded: boolean,
};

export default compose(
  jss(styles),
  tracking({}),
)(
  class MediawatchArticlesForFinding extends PureComponent<Props, State> {
    state = {
      expanded: false,
    };

    render() {
      const { articles, classes } = this.props;
      const { expanded } = this.state;

      if (!articles || !articles.length) {
        return null;
      }

      const { length } = articles;

      return (
        <div>
          {articles
            .slice()
            .sort(sortArticles)
            .slice(
              0,
              expanded ? length : NUMBER_OF_VISIBLE_ARTICLES_WHILE_COLLAPSED,
            )
            .map(({ id, url, title, site_name, published_time }) => (
              <Paper
                key={id}
                onClick={url && (() => this.onClick(url))}
                className={classes.paper}
              >
                <div
                  key={id}
                  className={classes.metaContainer}
                  onClick={url && (() => this.onClick(url))}
                >
                  <div className={classes.title}>{title}</div>
                  <div className={classes.source}>
                    <ExternalLogo
                      domain={getDomainFromURL(url)}
                      alt={site_name}
                      title={site_name}
                    />
                  </div>
                </div>
                <div className={classes.date}>
                  {isAfter(
                    published_time || '2000-01-01',
                    CUTOFF_TIME_FOR_NEW,
                  ) && 'New'}
                </div>
              </Paper>
            ))}
          {!expanded && length > NUMBER_OF_VISIBLE_ARTICLES_WHILE_COLLAPSED && (
            <Paper>
              <ViewMoreButton
                label="articles"
                count={length - NUMBER_OF_VISIBLE_ARTICLES_WHILE_COLLAPSED}
                onRequestViewMore={this.handleRequestViewMore}
              />
            </Paper>
          )}
          <a
            className={classes.attribution}
            href="http://clearbit.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Logos provided by Clearbit
          </a>
        </div>
      );
    }

    handleRequestViewMore = () => {
      this.setState({ expanded: true });
    };

    onClick = (url: string) => {
      const { tracking } = this.props;

      global.window.open(url, '_blank');
      tracking.trackEvent(dataCreators.mediawatchArticleClicked(url));
    };
  },
);
