//@flow
import React, { PureComponent } from 'react';
import ListItem from 'material-ui/List/ListItem';
import Divider from 'material-ui/Divider';
import ActionInfo from 'material-ui/svg-icons/action/info';

import { palette } from '@datatheorem/theme';

import ViewMoreButton from '../ViewMoreButton';
import InsightsCardWrapper from './InsightsCardWrapper';

type Props = {|
  header: string,
  hostnames: ?$ReadOnlyArray<string>,
  expanded: boolean,
  maxVisibleCount: number,
  onClickExpand: () => mixed,
|};

export default class EndpointsCard extends PureComponent<Props> {
  render() {
    const { props } = this;
    const {
      header,
      hostnames,
      expanded,
      maxVisibleCount,
      onClickExpand,
    } = props;

    if (!hostnames) {
      return null;
    }

    return (
      <InsightsCardWrapper header={header}>
        {hostnames.map((hostname, i) => {
          if (!expanded && i >= maxVisibleCount) {
            return null;
          }

          const error =
            hostname.indexOf('http:') === 0 || hostname.indexOf('ws:') === 0;

          return (
            <div key={hostname}>
              <Divider />
              <ListItem
                rightIcon={
                  error ? <ActionInfo color={palette.yellow30} /> : null
                }
                primaryText={hostname}
              />
            </div>
          );
        })}
        {!expanded && hostnames.length > maxVisibleCount ? (
          <>
            <Divider />
            <ViewMoreButton
              label="endpoints"
              count={hostnames.length - maxVisibleCount}
              onRequestViewMore={onClickExpand}
            />
          </>
        ) : null}
      </InsightsCardWrapper>
    );
  }
}
