//@flow
import { type Saga } from 'redux-saga';
import { call, takeEvery, spawn, put } from 'redux-saga/effects';
import {
  app_protection_tasks,
  type AppProtectionTask,
  type Application,
} from '@datatheorem/user-api/mobile_apps';
import { callPromise } from '@datatheorem/session';
import {
  isOpenStatus,
  getTargetStatus,
} from '@datatheorem/findings/targets/status';
import { withProgressIndicator } from '@datatheorem/progress-indicator';
import { updateStatus } from './securityFindings';
import FindingTargetStatus from '@datatheorem/enums/FindingTargetStatusEnum';
import {
  list as listTopApps,
  categoryList as listTopAppsByCategory,
} from '@datatheorem/user-api/app_protection_top_ten_apps';
import {
  appProtectionHideTaskClicked,
  appProtectionUnHideTaskClicked,
  appProtectionMounted,
  topAppsReceived,
  topAppsByCategoryReceived,
  appProtectionTasksReceived,
  appProtectionTasksMetadataReceived,
  mobileApplicationViewed,
} from '../actions';
import { findingFromParam } from '../selectors/securityFindings';
import type { ActionType } from 'redux-actions';
import { select } from '@datatheorem/redux-saga-wrapped-effects';
import { appProtectionCategoriesFromParam } from '../selectors/protection';

export const appProtectionSaga = function*(): Saga<void> {
  yield takeEvery(appProtectionHideTaskClicked.toString(), hideTask);
  yield takeEvery(appProtectionUnHideTaskClicked.toString(), unHideTask);
  yield takeEvery(appProtectionMounted.toString(), getTasksAndMetadataForApp);
  yield takeEvery(
    mobileApplicationViewed.toString(),
    getTasksAndMetadataForApp,
  );
  yield takeEvery(appProtectionMounted.toString(), getTopApps);
  yield spawn(getTopAppsInCurrentAppCategory);
};

export function* hideTask({
  payload,
}: {
  payload: AppProtectionTask,
}): Saga<void> {
  const { security_finding_id, targets } = payload;

  if (!security_finding_id || !targets || !targets.length) {
    throw new Error(
      'Expected hideTask to never be called with an app protection task with no associated finding or targets. (Since that means the status of the task is unknown, so we should already be hiding it)',
    );
  }

  const finding = yield* select(findingFromParam, {
    match: { params: { findingId: security_finding_id } },
  });

  if (!finding) {
    throw new Error(
      'Could not finding referenced security finding in hideTask',
    );
  }

  const openTargets = finding.targets.filter(target =>
    isOpenStatus(getTargetStatus(target)),
  );

  if (!openTargets.length) {
    throw new Error(
      'Expected hideTask to be called with a finding that only has closed targets, at least one must be open to hide this task',
    );
  }

  for (const target of openTargets) {
    yield call(
      updateStatus,
      security_finding_id,
      target.id,
      FindingTargetStatus.CLOSED_RISK_ACCEPTED,
    );
  }
}

export function* unHideTask({
  payload,
}: {
  payload: AppProtectionTask,
}): Saga<void> {
  const { security_finding_id, targets } = payload;

  if (!security_finding_id || !targets || !targets.length) {
    throw new Error(
      'Expected unHideTask to never be called with an app protection task with no associated finding or targets.',
    );
  }

  const finding = yield* select(findingFromParam, {
    match: { params: { findingId: security_finding_id } },
  });

  if (!finding) {
    throw new Error(
      'Could not finding referenced security finding in unHideTask',
    );
  }

  const hiddenTargets = finding.targets.filter(
    target =>
      getTargetStatus(target) === FindingTargetStatus.CLOSED_RISK_ACCEPTED,
  );

  if (!hiddenTargets.length) {
    throw new Error(
      'Expected hideTask to be called with a finding that only has closed targets, at least one must be open to hide this task',
    );
  }

  for (const target of hiddenTargets) {
    yield call(
      updateStatus,
      security_finding_id,
      target.id,
      FindingTargetStatus.OPEN,
    );
  }
}

export function* getTopApps(): Saga<void> {
  const topApps = yield* callPromise(listTopApps);
  yield put(topAppsReceived(topApps));
}

export function* getTopAppsInCurrentAppCategory(): Saga<void> {
  yield takeEvery(appProtectionMounted.toString(), function*(action: {
    type: string,
    payload: Application,
  }): Saga<void> {
    const { id } = action.payload;

    yield* withProgressIndicator(function*() {
      const topAppsByCategory = yield* callPromise(listTopAppsByCategory, {
        mobile_app_id: id,
      });

      if (topAppsByCategory) {
        yield put(topAppsByCategoryReceived(topAppsByCategory));
      }
    });
  });
}

export function* getTasksAndMetadataForApp(
  action:
    | ActionType<typeof appProtectionMounted>
    | ActionType<typeof mobileApplicationViewed>,
): Saga<void> {
  const { id } = action.payload;

  const categories = yield* select(appProtectionCategoriesFromParam, {
    match: { params: { appId: id } },
  });

  // If we already have categories for this app, don't fetch them again
  if (categories) {
    return;
  }

  yield* withProgressIndicator(function*() {
    const tasks_response = yield* callPromise(app_protection_tasks.list, id);

    if (tasks_response && tasks_response.app_protection_tasks) {
      yield put(
        appProtectionTasksReceived(tasks_response.app_protection_tasks),
      );
    }

    if (tasks_response && tasks_response.app_protection_tasks_metadata) {
      yield put(
        appProtectionTasksMetadataReceived(
          tasks_response.app_protection_tasks_metadata,
        ),
      );
    }
  });
}
