// @flow
import React, { PureComponent } from 'react';
import text from '../../../util/md/saml-setup-instructions';
import { PageHeading } from '@datatheorem/components';
import { browserHistory } from '../../../clients/history';
import Page from '../../theme/Page';
import { Markdown } from '@datatheorem/components';
import { connect } from 'react-redux';
import type { State as ReduxState } from '../../../ReduxStateType';
import { sdlcInfoMounted } from '../../../actions';
import { type DispatchProps } from 'redux';
import type { ActionType } from 'redux-actions';
import { compose } from 'recompose';
import tracking, { type TrackingProps } from 'react-tracking';
import AnalyticsScreenEnum from '@datatheorem/enums/AnalyticsScreenEnum';
import { dataCreators } from '@datatheorem/analytics';

type DP = DispatchProps<ActionType<typeof sdlcInfoMounted>>;

type StateProps = {|
  entity_id: ?string,
  metadata_file_url: ?string,
  assertion_consumer_service_url: ?string,
|};

type IntermediateProps = {|
  ...DP,
  ...TrackingProps,
|};

type Props = {| ...StateProps, ...IntermediateProps |};

export class SAMLSetupInstructions extends PureComponent<Props> {
  componentDidMount() {
    this.props.dispatch(sdlcInfoMounted());
  }

  render() {
    const {
      metadata_file_url,
      entity_id,
      assertion_consumer_service_url,
    } = this.props;

    return (
      <PageHeading
        backButton
        onClickBack={browserHistory.goBack}
        title="SAML Setup"
      >
        <Page>
          {metadata_file_url && entity_id && assertion_consumer_service_url ? (
            <Markdown
              text={text(
                metadata_file_url,
                entity_id,
                assertion_consumer_service_url,
              )}
            />
          ) : (
            'Loading'
          )}
        </Page>
      </PageHeading>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  entity_id: state.strings['saml_sp_metadata.entity_id'],
  metadata_file_url: state.strings['saml_sp_metadata.metadata_file_url'],
  assertion_consumer_service_url:
    state.strings['saml_sp_metadata.assertion_consumer_service_url'],
});

export default compose(
  tracking(dataCreators.component(AnalyticsScreenEnum.SDLC_SAML_INSTRUCTIONS)),
  connect<Props, IntermediateProps, _, _, _, _>(mapStateToProps),
)(SAMLSetupInstructions);
