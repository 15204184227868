//@flow

const filters = {
  ALL_ISSUES: 'ALL_ISSUES',
  P1_ISSUES_AND_BLOCKERS: 'P1_ISSUES_AND_BLOCKERS',
  DISABLED: 'DISABLED',
};

export default filters;

export type JiraExportFilterEnum =
  | 'ALL_ISSUES'
  | 'P1_ISSUES_AND_BLOCKERS'
  | 'DISABLED';
