// @flow
import React from 'react';
import injectSheet from 'react-jss';
import Paper from '@material-ui/core/Paper';
import { IconLabel } from '@datatheorem/components';

// Types
import type { InjectedJSS } from 'react-jss';
import type { Application } from '@datatheorem/user-api/mobile_apps';

const styles = {
  paper: {
    overflowX: 'hidden !important',
    margin: '5px 0px !important',
    minHeight: '85px !important',
  },
  card: {
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    padding: '5px 5px',
    margin: '5px 5px',
  },

  left: {
    padding: '5px 5px',
    minWidth: '50px',
  },

  right: {
    display: 'flex',
    flexGrow: '1',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  subscription: {
    color: '#777',
    fontSize: '12px',
  },

  name: {
    padding: '5px 5px',
    flexGrow: '1',
    flexShrink: '1',
    flexBasis: '60%',
  },

  id: {
    padding: '5px 5px',
    alignSelf: 'stretch',
    minWidth: '100px',
    flex: '1 1 100px',
    marginLeft: 'auto',
    textAlign: 'right',
    color: '#777',
    fontSize: '12px',
  },
};

type Props = {
  app: Application & { count: number, score: number },
} & InjectedJSS<typeof styles>;

const AppCard = (props: Props) => (
  <Paper className={props.classes.paper} elevation={1}>
    <div className={props.classes.card}>
      <div className={props.classes.left}>
        <IconLabel platform={props.app.platform} />
      </div>
      <div className={props.classes.right}>
        <div className={props.classes.name}>
          <div>{`${props.app.name}`}</div>
          <div className={props.classes.subscription}>
            {`Subscription: ${props.app.subscription || ''}`}
          </div>
          <div className={props.classes.subscription}>
            {`count: ${props.app.count || 'N/A'} - score: ${props.app.score ||
              'N/A'}`}
          </div>
        </div>
        <div className={props.classes.id}>{`${props.app.id}`}</div>
      </div>
    </div>
  </Paper>
);

export default injectSheet(styles)(AppCard);
