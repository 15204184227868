//@flow
import {
  call,
  put,
  spawn,
  take,
  takeEvery,
  select,
  all,
} from 'redux-saga/effects';
import { type Saga } from 'redux-saga';
import { browserHistory } from '../clients/history';
import {
  jiraIntegrationConfigListViewPageMounted,
  jiraIntegrationConfigPageMounted,
  jiraIntegrationConfigCreateFormSubmitted,
  jiraIntegrationConfigPatchFormSubmitted,
  jiraIntegrationConfigListViewClicked,
  jiraIntegrationConfigureClick,
  jiraIntegrationConfigureGlobalClick,
  jiraIntegrationConfigurationsReceived,
  finishedLoadingApps,
} from '../actions';

import {
  GLOBAL_JIRA_CONFIG_ID,
  get as getJiraIntegrationConfig,
  create as createJiraIntegrationConfig,
  patch as patchJiraIntegrationConfig,
  list as listJiraIntegrationConfigs,
  jiraConfigToRequest,
} from '@datatheorem/user-api/jira_config';

import { tryAndParseErrorMessage } from '@datatheorem/user-api/util';
import { Actions as NotificationsActions } from '@datatheorem/notifications';
import { apps as selectApps } from '../selectors/apps';
import type { JiraIntegrationConfig } from '@datatheorem/user-api/jira_config';
import type { ConfigurationList } from '../reducers/jiraIntegration';
import { type Application } from '@datatheorem/user-api/mobile_apps';

const doesContainGlobalJiraConfiguration = (
  configurations: $ReadOnlyArray<JiraIntegrationConfig>,
): ?JiraIntegrationConfig =>
  configurations.find(config => config.id === GLOBAL_JIRA_CONFIG_ID);

const createConfigurationsFromAppAndJiraConfig = (
  configurations: $ReadOnlyArray<JiraIntegrationConfig>,
  apps: $ReadOnlyArray<Application>,
): ConfigurationList => {
  if (!configurations) return [];
  if (!doesContainGlobalJiraConfiguration(configurations)) return [];

  return apps.map(app => {
    const fields =
      configurations.find(config => config.id === app.id) ||
      configurations.find(
        config => config.id === GLOBAL_JIRA_CONFIG_ID.toString(),
      );

    if (!fields) {
      throw new Error('Unable to find any Jira configuration');
    }

    return {
      app,
      fields,
      isGlobal: fields.id === GLOBAL_JIRA_CONFIG_ID.toString(),
    };
  });
};

export function* loadJiraIntegrationConfig(action: {
  payload: number,
}): Saga<void> {
  const jiraConfigId: number = action.payload;

  try {
    yield call(getJiraIntegrationConfig, jiraConfigId);
    const list = yield call(listJiraIntegrationConfigs);
    const result = createConfigurationsFromAppAndJiraConfig(
      list.jira_integrations,
      yield select(selectApps),
    );
    yield put(jiraIntegrationConfigurationsReceived(result));
  } catch (err) {
    if (err.status != 404) {
      NotificationsActions.requestNotifyUser({
        text: tryAndParseErrorMessage(err),
      });
    }
  }
}

export function* watchForCreateJiraIntegrationConfig(): Saga<void> {
  const action = yield take(
    jiraIntegrationConfigCreateFormSubmitted.toString(),
  );

  // Grab the current app from redux state
  const { id, jiraIntegrationConfigChanges } = action.payload;
  const isIdGlobal = id === GLOBAL_JIRA_CONFIG_ID.toString();

  // Concat all the changes that happened
  const config = Object.assign({}, ...jiraIntegrationConfigChanges, {
    mobile_app_id: id,
  });

  // Convert payload into something the server understands
  const jiraConfigPayload = jiraConfigToRequest(config, isIdGlobal, false);

  try {
    yield call(createJiraIntegrationConfig, jiraConfigPayload);
    const list = yield call(listJiraIntegrationConfigs);
    const result = createConfigurationsFromAppAndJiraConfig(
      list.jira_integrations,
      yield select(selectApps),
    );
    yield put(jiraIntegrationConfigurationsReceived(result));
  } catch (err) {
    yield put(
      NotificationsActions.requestNotifyUser({
        text: tryAndParseErrorMessage(err),
      }),
    );
  }
}

export function* watchForPatchJiraIntegrationConfig(): Saga<void> {
  const action = yield take(jiraIntegrationConfigPatchFormSubmitted.toString());
  const { id, jiraIntegrationConfigChanges } = action.payload;

  const config = Object.assign({}, ...jiraIntegrationConfigChanges);

  // Convert payload into something the server understands
  const isGlobal = id === GLOBAL_JIRA_CONFIG_ID;
  const jiraConfigPayload = jiraConfigToRequest(config, isGlobal, true);

  try {
    yield call(patchJiraIntegrationConfig, jiraConfigPayload, id);
    const list = yield call(listJiraIntegrationConfigs);
    const apps = yield select(selectApps);
    const result = createConfigurationsFromAppAndJiraConfig(
      list.jira_integrations,
      apps,
    );

    yield put(jiraIntegrationConfigurationsReceived(result));
  } catch (err) {
    yield put(
      NotificationsActions.requestNotifyUser({
        text: tryAndParseErrorMessage(err),
      }),
    );
  }
}

export function* watchForJiraIntegrationPageMounted(): Saga<void> {
  yield takeEvery(
    jiraIntegrationConfigPageMounted.toString(),
    loadJiraIntegrationConfig,
  );
}

export function* watchForJiraIntegrationConfigListViewPageMounted(): Saga<
  void,
> {
  const [configs, apps] = yield all([
    call(function*() {
      yield take(jiraIntegrationConfigListViewPageMounted.toString());
      try {
        return yield call(listJiraIntegrationConfigs);
      } catch (err) {
        NotificationsActions.requestNotifyUser({
          text: tryAndParseErrorMessage(err),
        });
      }
    }),
    call(function*() {
      yield take(finishedLoadingApps.toString());
      return yield select(selectApps);
    }),
  ]);

  const result = createConfigurationsFromAppAndJiraConfig(
    configs.jira_integrations,
    apps,
  );

  yield put(jiraIntegrationConfigurationsReceived(result));
}

export function* watchForJiraIntegrationListViewPageClicked(): Saga<void> {
  yield takeEvery(
    jiraIntegrationConfigListViewClicked.toString(),
    function*(): Saga<void> {
      yield call(browserHistory.push, `/sdlc/jira`);
    },
  );
}

export function* WatchForJiraIntegrationConfigureClick(): Saga<void> {
  yield takeEvery(jiraIntegrationConfigureClick.toString(), function*(
    action,
  ): Saga<void> {
    if (action.payload.toString() === GLOBAL_JIRA_CONFIG_ID) {
      yield call(browserHistory.push, `/sdlc/jira/global/`);
    } else {
      yield call(browserHistory.push, `/sdlc/jira/${action.payload}/`);
    }
  });
}

export function* WatchForJiraIntegrationConfigureGlobalClick(): Saga<void> {
  yield takeEvery(
    jiraIntegrationConfigureGlobalClick.toString(),
    function*(): Saga<void> {
      yield call(browserHistory.push, `/sdlc/jira/global`);
    },
  );
}

export function* jiraIntegrationConfigFlow(): Saga<void> {
  yield spawn(watchForJiraIntegrationPageMounted);
  yield spawn(watchForJiraIntegrationListViewPageClicked);
  yield spawn(watchForCreateJiraIntegrationConfig);
  yield spawn(watchForPatchJiraIntegrationConfig);
  yield spawn(watchForJiraIntegrationConfigListViewPageMounted);
  yield spawn(WatchForJiraIntegrationConfigureClick);
  yield spawn(WatchForJiraIntegrationConfigureGlobalClick);
}
