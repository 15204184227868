// @flow
import { createSelector } from 'reselect';
import has from 'lodash/fp/has';

import MobileAppPlatformEnum from '@datatheorem/enums/MobileAppPlatformEnum';

import { appFromParam, type AppIdParam } from './apps';
import {
  BadgeType,
  type BadgeTypeEnum,
} from '@datatheorem/components/src/app-protection-badges';
import type { State } from '../ReduxStateType';

type HasBadges = $ReadOnlyArray<[BadgeTypeEnum, boolean]>;

export const appBadges = createSelector<State, AppIdParam, HasBadges, _>(
  appFromParam,

  app => {
    if (!app) return [];

    const protectionBadgeTypes = [
      BadgeType.all_communication_encrypted,
      BadgeType.full_protection_on_untrusted_networks,
      BadgeType.server_enforces_https,
      BadgeType.leverages_secure_system_sdk,
      BadgeType.leverages_secure_browser_settings,
      ...(app.platform === MobileAppPlatformEnum.IOS
        ? [
            BadgeType.supports_two_factor_authentication,
            BadgeType.removes_data_from_shared_device_locations,
            BadgeType.protects_the_keyboard,
            BadgeType.leverages_secure_system_libraries,
            BadgeType.enhances_password_security,
          ]
        : []),
      ...(app.platform === MobileAppPlatformEnum.ANDROID
        ? [
            BadgeType.requires_android_device_security,
            BadgeType.requires_android_malware_protection,
            BadgeType.protects_data_on_screens,
            BadgeType.leverages_secure_system_settings,
            BadgeType.does_not_offload_data_to_third_parties,
          ]
        : []),
    ];

    return protectionBadgeTypes.reduce(
      (acc, badge: BadgeTypeEnum) => [
        ...acc,
        [badge, has(badge)(app) && app[badge]],
      ],
      ([]: HasBadges),
    );
  },
);
